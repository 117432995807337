import React, { useEffect, useState } from "react";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { DateTime } from "luxon";
import { getAllReportingContributors } from "modules/shared/services/team.service";
import { RootState, ThunkDispatch } from "models/store.models";
import { connect } from "react-redux";

interface IProps {
    accessToken: string,
    documentsList: any,
    projectsList: any,
    indicatorsProjectsList: any
}

const mapStateToProps = ({ session }: RootState) => ({
  accessToken: session?.sessionData?.accessToken
    ? session?.sessionData?.accessToken
    : "",
  user: session?.sessionData?.userData,
});

const mapDispatchToProps = (dispatch: ThunkDispatch) => ({});

const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

const DownloadStatuses = ({ accessToken, projectsList, documentsList, indicatorsProjectsList }: IProps) => {
    
    const downloadStatus = async (e: any) => {
        e.preventDefault();
        const projects: any = await getAllReportingContributors(accessToken);
        let statuses: any = [];
        
        for(let i in projectsList){
            const indicators: any = indicatorsProjectsList.find((ipl: any) => ipl.projectCode.toUpperCase() === projectsList[i].code);
            // const projectUsers: any = projects.filter((p: any) => p.projectCode === projectsList[i].code || 'undefined' === typeof p.projectCode); // Get all users including the super admin
            const projectUsers: any = projects.filter((p: any) => p.projectCode === projectsList[i].code);
            
            if(!!projectUsers){
                projectUsers.forEach((pu: any) => {
                    let status: any = {
                        'Project code': projectsList[i].code, 
                        'Project name': projectsList[i].name, 
                        'Funds': projectsList[i].funds.join(', '),
                        'Group': !!projectsList[i].group ? projectsList[i].group.replace(/group/, '') : '',
                        'Contributor lastname': pu.lastname, 
                        'Contributor firstname': pu.firstname, 
                        'Contributor email': pu.email, 
                        'Indicators completed': !!indicators && !!indicators.isComplete ? 'YES' : 'NO', 
                        'Documents uploaded': 0 < projectsList[i].totalDocCompletion && projectsList[i].docCompletion === projectsList[i].totalDocCompletion ? 'YES' : 'NO'
                    };

                    documentsList.forEach((dl: any) => {
                        status[dl.filename] = (!!dl.noTemplate || (!dl.noTemplate && !!projectsList[i].templates.find((t: any) => t.name.match(new RegExp('^(' + dl.code + ')(\s)?(\\.|-){1}', 'gi'))))) ? (!!projectsList[i].files.find((f: any) => Number(f.code) === Number(dl.code)) ? 'YES' : 'NO') : '-';
                    })

                    statuses.push(status);
                })
            }
        }

        // console.log('____ DOWNLOAD EMAIL LIST');
        // console.log(statuses);

        const ws = XLSX.utils.json_to_sheet(statuses);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data,  `leaders-contributors-email-list-and-statuses-${DateTime.local().toFormat('yyyy-LL-dd')}`);
    }

    return (
        <div className="downloads-container">
            <div className="downloads-row">
            <b>Download</b>
            <a onClick={downloadStatus} download>
                <button
                className="btn btn-select btn-primary"
                type="button"
                >
                <span className="btn-label">
                    Leaders and contributors email list and statuses (.xlsx)
                </span>
                <span className="btn-icon">
                    <i className={`fas fa-download`}></i>
                </span>
                </button>
            </a>
            </div>
        </div>
    )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DownloadStatuses);
// import { DateTime } from "luxon";
import { Assumption } from "modules/hub/models/project-macroeconomic-assumptions";
import WarnModal from "modules/shared/components/WarnModal/WarnModal";
import slugify from 'slugify';
import UploadConfig, {
  MaxChunkSize,
  UploadFile,
} from "modules/shared/services/upload.service";
import React, { useState } from "react";

interface ProjectAssumptionsHubItemProps {
  accessToken: string;
  directory: string | null;
  macro: Assumption;
  index: number;
  handleChange: any;
  deleteAssumption: any
}

const ProjectAssumptionsHubItem = ({
  accessToken,
  directory,
  macro,
  index,
  handleChange,
  deleteAssumption
}: ProjectAssumptionsHubItemProps) => {
  const [currentFile, setCurrentFile] = useState<any>({
    filename: "",
    file: null,
  });

  const selectUploadFile = (file: any) => {
    if (!!file) {
      var reader = new FileReader();
        
      reader.onload = () => {
          let base64String: any = reader.result;

          base64String = base64String.replace("data:", "")
              .replace(/^.+,/, "");
    
          const fileData: any = {
            name: slugify(file.name, {lower: true}),
            base64: base64String
          }

          if (!!macro.prevFile && !macro.file) {
            handleChange("file", fileData);
          } else {
            handleChange(["file", "prevFile"], [fileData, macro.file]);
          }

      }
      reader.readAsDataURL(file);

      // const totalFiles = Math.ceil(file.size / MaxChunkSize);
      // UploadConfig.accessToken = accessToken;
      // UploadConfig.maxChunks = totalFiles;
      // UploadConfig.uploading = true;
      // UploadFile(
      //   directory,
      //   { filename: `assumptions/${file.name}`, file: file },
      //   (data: any) => {
      //     if (!!macro.prevFile && !macro.file) {
      //       handleChange("file", data);
      //     } else {
      //       handleChange(["file", "prevFile"], [data, macro.file]);
      //     }
      //   }
      // );
    }
  };

  const deleteFile = () => {
    handleChange(["file", "prevFile"], [null, macro.file]);
  };

  const deleteItem = () => {
    deleteAssumption(index);
  }

  return (
    <div className="item">
      <div className="action">
        <button type="button" data-uk-toggle={`#warnDeleteAssumption_${index}`}>
          <span className="btn-label">Delete</span>
          <span className="btn-icon">
            <i className={`fas fa-times-circle`}></i>
          </span>
        </button>
      </div>
      <div className="title">
        <p className="label">Table title</p>
        <input
          id="title"
          type="text"
          placeholder="Title..."
          required
          name="title"
          value={macro.title}
          onChange={(e) => handleChange("title", e.target.value)}
        />
      </div>

      <div className="description">
        <p className="label">Title description</p>
        <textarea
          id="description"
          name="description"
          placeholder="Description..."
          onChange={(e) => handleChange("description", e.target.value)}
          value={macro.description}
        ></textarea>
      </div>

      <div className="img">
        <ul>
          <li>{!!macro.file ? macro.file.name : ""}</li>
        </ul>
        <div className="actions">
          <input type="file" id={`upload-img-${index}`} hidden accept="image/*"
            onChange={(e: any) => {
              e.preventDefault();
              selectUploadFile(e.target.files[0]);
            }} />
          {!!macro.file ? (
            <>
              <label htmlFor={`upload-img-${index}`}>
                <span className="btn-label">Replace</span>
                <span className="btn-icon">
                  <i className={`fas fa-sync-alt`}></i>
                </span>
              </label>
              <button onClick={deleteFile}>
                <span className="btn-label">Delete</span>
                <span className="btn-icon">
                  <i className={`fas fa-times-circle`}></i>
                </span>
              </button>
            </>
          ) : (
            <label htmlFor={`upload-img-${index}`}>
              <span className="btn-label">Add</span>
              <span className="btn-icon">
                <i className={`fas fa-upload`}></i>
              </span>
            </label>
          )}
        </div>
      </div>

      <WarnModal
        id={`warnDeleteAssumption_${index}`}
        content="Do you really want to delete this table?"
        actionYes={() => deleteItem()}
      />
    </div>
  );
};

export default ProjectAssumptionsHubItem;

import React from "react";
import Switcher from "../Filters/Switcher/Switcher";
import { AvailableFilters } from "../../../../limited-partners/models/available-filters.model";
import "./styles.scss";
import UIkit from "uikit";

type Props = {
  className: String;
  filter: AvailableFilters;
  currency: string;
  switchCurrency: any;
  menuList: any;
  menuCurrentItem: string;
  setInvestorId: any;
  currentYear: number;
  switchYear: any;
  availableYear: any;
  investorType:string
};

const Toolbar: React.FC<Props> = ({
  className,
  filter,
  currency,
  switchCurrency,
  menuList,
  menuCurrentItem,
  setInvestorId,
  currentYear,
  switchYear,
  availableYear,
  investorType
}) => {
  const handleChange = async (investorId: string) => {
    // @ts-ignore
    await UIkit.offcanvas("#toggleMainMenu").hide();

    // setTimeout(function(){
    setInvestorId(investorId);
    // }, 200);
  };

  return (
    <>
      <div
        className={
          "toolbar-wrapper" + (null !== className) ? " " + className : ""
        }
      >
        <div className="toolbar toolbar-main toolbar-white">
          <div className="toolbar_inner">
            <div className="menu menu-transparent">
              <button
                className="btn btn-burger"
                data-uk-toggle="target: #toggleMainMenu"
              >
                <span className="btn-label">Menu</span>
                <span className="btn-icon">
                  <i className="fas fa-bars"></i>
                </span>
              </button>
            </div>
            <div className="area area-left">
              <div className="selector">
                <span className="label">
                  Data
                </span>
                {
                  investorType === "gigf" 
                  ?
                    <b className="label-year">2022</b>
                  :
                  (
                    <>
                      {1 === availableYear.length && (
                        <b className="label-year">{availableYear}</b>
                      )}
                      {1 < availableYear.length && (
                        <div className="select">
                          <button
                            className="btn btn-select btn-primary btn-rounded"
                            type="button"
                          >
                            <span className="btn_label">
                              {currentYear}
                            </span>
                            <span className="btn_icon"></span>
                          </button>
                          <div
                            className="drop drop-select year"
                            data-uk-dropdown="pos: top-left; mode: click; delay-hide: 100">
                            <ul className="uk-nav uk-dropdown-nav">
                              {!!availableYear &&
                                availableYear.length > 0 &&
                                availableYear.map((year: number, idx: number) => {
                                  return (
                                    <li
                                      key={`keyAvY-${idx}`}
                                      className={year === currentYear ? "item selected" : "item"}
                                      data-uk-toggle="target: .year"
                                      onClick={() => {
                                        switchYear(year);
                                        UIkit.dropdown(".year").hide();
                                      }}
                                    >
                                      <span className="clickable full">{year}</span>
                                    </li>
                                  );
                                })}
                            </ul>
                          </div>
                        </div>
                      )}
                    </>
                  )
                }

              </div>
              <div className="filter">
                <div className="filter_child">
                  <Switcher
                    filter={filter}
                    currency={currency}
                    switchCurrency={switchCurrency}
                  />
                </div>
              </div>
            </div>
            <div className="area area-center"></div>
            <div className="area area-right"></div>
          </div>
        </div>

        <div id="toggleMainMenu" data-uk-offcanvas="overlay: true;">
          <div className="own-offcanvas uk-offcanvas-bar">
            <div className="inner-offcanvas">
              <div className="offcanvas-head">
                <button
                  className="btn btn-close-menu btn-rounded btn-no-text"
                  data-uk-toggle="target: #toggleMainMenu"
                >
                  <span className="btn_icon">
                    <i className="fas fa-times"></i>
                  </span>
                  <span className="btn_label">Open menu</span>
                </button>

                <span className="brand">Meridiam | </span>
                <span className="universe upp">Platform</span>
              </div>
              <div className="offcanvas-body">
                <h4>
                  <i>Choose a Limited Partner</i>
                </h4>
                <ul className="uk-nav uk-nav-default">
                  {menuList && menuList.length > 0 && (
                    <>
                      {menuList.map((item: any) => {
                        return (
                          <li
                            className={""}
                            value={item.investorId}
                            key={item.investorId}
                            // data-uk-toggle="target: #toggle-usage"
                            onClick={() => handleChange(item.investorId)}
                          >
                            <div>
                              <span>{item.investorName}</span>
                            </div>
                          </li>
                        );
                      })}
                    </>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Toolbar;

import { ReportingContext } from "context/Reporting.context";
import { RootState, ThunkDispatch } from "models/store.models";
import { getDocuments } from "modules/shared/services/spv-reporting.service";
import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import UIkit from "uikit";

import "./styles.scss";

interface SPVReportingProjectFiltersProps {
  // filters: any;
  // setFilters: any;
  funds: any;
  projectsCompletion: any;
  accessToken: any;
}

const mapStateToProps = ({ session }: RootState) => ({
  accessToken: session?.sessionData?.accessToken
    ? session?.sessionData?.accessToken
    : "",
});

const mapDispatchToProps = (dispatch: ThunkDispatch) => ({});

const SPVReportingProjectFilters = ({
  // filters,
  // setFilters,
  funds,
  projectsCompletion,
  accessToken,
}: SPVReportingProjectFiltersProps) => {

  const { projectFilters, setProjectFilters } = useContext(ReportingContext);

  const [docTypes, setDocTypes] = useState<any>([]);

  const getDocumentsList = async () => {
    const tmpDocs: any = await getDocuments(accessToken);
    if ("ok" === tmpDocs.status) {
      setDocTypes([
        ...[{ id: "all", name: "All documents" }],
        ...tmpDocs.data.map((d: any) => ({
          id: d.code,
          name: d.filename,
        })),
      ]);
    }
  };

  useEffect(() => {
    getDocumentsList();
  }, []);

  return (
    <div className="filters-container">
      <div className="selector">
        <p className="selector-label">Filter by fund</p>
        <div className="select">
          <button
            className="btn btn-select btn-primary full btn-rounded"
            type="button"
          >
            <span className="btn_label">
              {!!projectFilters.fund ? 'all' === projectFilters.fund.id ? 'All funds' : projectFilters.fund.id : "All funds"}
            </span>
            <span className="btn_icon"></span>
          </button>

          <div
            className="drop drop-select fund-filter"
            data-uk-dropdown="pos: bottom-left; mode: click; delay-hide: 100"
          >
            <ul className="group lvl0 uk-nav uk-dropdown-nav">
              {!!funds &&
                0 < funds.length &&
                funds.map((fund: any, idx: number) => {
                  return (
                    <li key={`fund-filter-${idx}`} className="item">
                      <span
                        className="clickable"
                        data-uk-toggle="target: .fund-filter"
                        onClick={() => {
                          setProjectFilters({ ...projectFilters, ...{ fund: fund } });
                          UIkit.dropdown(".fund-filter").hide();
                        }}
                      >
                        {'all' === fund.id ? fund.name : fund.id}
                      </span>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      </div>

      <div className="selector">
        <p className="selector-label">Filter by document types</p>
        <div className="select">
          <button
            className="btn btn-select btn-primary full btn-rounded"
            type="button"
          >
            <span className="btn_label">
              {!!projectFilters.doc_type ? projectFilters.doc_type.name : "All documents"}
            </span>
            <span className="btn_icon"></span>
          </button>

          <div
            className="drop drop-select doc-filter"
            data-uk-dropdown="pos: bottom-left; mode: click; delay-hide: 100"
          >
            <ul className="group lvl0 uk-nav uk-dropdown-nav">
              {!!docTypes &&
                docTypes.length > 0 &&
                docTypes.map((doc: any, idx: number) => {
                  return (
                    <li key={`doc-filter-${idx}`} className="item">
                      <span
                        className="clickable"
                        data-uk-toggle="target: .doc-filter"
                        onClick={() => {
                          setProjectFilters({ ...projectFilters, ...{ doc_type: doc } });
                          UIkit.dropdown(".doc-filter").hide();
                        }}
                      >
                        {doc.name}
                      </span>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      </div>

      <div className="tool tool-search">
        <div className="row row-input row-search">
          <div className="input">
            <input
              type="search"
              className={`no-check ${!!projectFilters.search ? "filled" : ""}`}
              placeholder="Search a project"
              value={projectFilters.search}
              onChange={(e) =>
                setProjectFilters({ ...projectFilters, ...{ search: e.target.value } })
              }
            />
            {"" === projectFilters.search ? (
              <span className="input-icon">
                <i className="fas fa-search"></i>
              </span>
            ) : (
              <span
                className="input-icon"
                onClick={() => setProjectFilters({ ...projectFilters, ...{ search: "" } })}
              >
                <i className="fas fa-times"></i>
              </span>
            )}
          </div>
        </div>
      </div>

      <div className="project-completed">
        project completed :&nbsp;
        <b>
          {projectsCompletion.completed}/{projectsCompletion.total}
        </b>
      </div>
    </div>
  );
};

// export default SPVReportingProjectFilters;
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SPVReportingProjectFilters);

import { Project } from "models/project.model";
import {
  SdgImpactSynthesisFilter,
  SdgImpactSynthesisSelection,
} from "models/sdg-impact-synthesis.model";
import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";

type SisProjectSelectionProps = {
  selection: SdgImpactSynthesisSelection;
  avalaibleFilter: SdgImpactSynthesisFilter;
  hasSelection: boolean;
};

const SisProjectSelection: React.FC<SisProjectSelectionProps> = ({
  selection,
  avalaibleFilter,
  hasSelection,
}) => {
  const [selectedProject, setSelectedProject] = useState<Project[]>([]);
  useEffect(() => {
    if (hasSelection) {
      let newSelection: Project[] = [];
      avalaibleFilter.projects.map((project) => {
        let projectHasFund = "false";
        !!project.meridiamFunds &&
          project.meridiamFunds.length > 0 &&
          project.meridiamFunds.map((mF) => {
            if (!!mF.meridiamFund && selection.meridiamFunds.includes(mF.meridiamFund.fundId)) {
              projectHasFund = "true";
            }
          });

        if (
          ("all" === selection.projectTypes[0] ||
            selection.projectTypes.includes(project.type)) &&
          ("all" === selection.meridiamFunds[0] || projectHasFund === "true") &&
          ("all" === selection.projectStates[0] ||
            selection.projectStates.includes(project.stage)) &&
          ("all" === selection.projectInvestmentPhases[0] ||
            selection.projectInvestmentPhases.includes(
              project.investmentPhase
            )) &&
          ("all" === selection.location[0] ||
            selection.location.includes(project.countryCode))
        ) {
          newSelection.push(project);
        }
      });
      setSelectedProject(newSelection);
    } else {
      setSelectedProject(avalaibleFilter.projects);
    }
  }, [avalaibleFilter, selection]);

  return (
    <>
      <div className={styles["projects-wrapper"]}>
        <div className={styles["projects"]}>
          <div className={styles["projects-head"]}>
            <label
              htmlFor="projectFirstSerieListBox"
              className={`${styles.btn} ${styles["btn-remote"]}`}
            >
              <span className={styles.btn_label}>
                {selectedProject.length} projects
              </span>
            </label>
            <label
              htmlFor="projectFirstSerieListBox"
              className={`${styles.btn} ${styles["btn-remote"]}`}
            >
              <span className={styles.btn_label}>Collaspse list</span>
              <span className={styles.btn_icon}>
                <i className="fas fa-angle-down"></i>
              </span>
            </label>
          </div>
          <input
            className={`${styles["projects-remote"]}`}
            id="projectFirstSerieListBox"
            type="checkbox"
          />
          <div className={styles["projects-body"]}>
            <ul className={styles["projects-list"]}>
              {selectedProject.map((project) => {
                return (
                  // TODO ADD CONDITION PROJECT IN SELECTION
                  <li key={`prj-${project.projectCode}`}>
                    <span>{project.projectName}</span>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default SisProjectSelection;

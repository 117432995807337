import axios from "axios";
import { PlatformConfiguration } from "models/platform-configuration.model";
import config from "modules/config";

const ApiBaseUrl = config.ApiUrl;

const getPlatformConfiguration = async (): Promise<PlatformConfiguration> => {
  const result = await axios({
    method: "get",
    url: `${ApiBaseUrl}/platform/config`,
  });

  return result.data;
};

export { getPlatformConfiguration };

import { SdgImpactSynthesisInterface } from "models/sdg-impact-synthesis.model";
import React from "react";

import { sdgNames, sdgsRatings } from "../../../../shared/utils/sdg-names";
import "./styles.scss";

type SisBoxDisplayDatasProps = {
  currentSdgId: number;
  setModeRadar: any;
  sdgImpactSynthesis: SdgImpactSynthesisInterface;
  comparaisonSdgSynthesis?: SdgImpactSynthesisInterface;
  comparaisonMode: boolean;
  showConfidenceIndex: boolean;
  showConfidenceIndexComparaison: boolean;
};

const SisBoxDisplayDatas: React.FC<SisBoxDisplayDatasProps> = ({
  currentSdgId,
  setModeRadar,
  sdgImpactSynthesis,
  comparaisonSdgSynthesis,
  comparaisonMode,
  showConfidenceIndex,
  showConfidenceIndexComparaison,
}) => {
  const listSDG = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17];
  const SDGnames = sdgNames;
  const SDGratings = sdgsRatings;
  return (
    <>
      <div
        className="cmp cmp-chart chart-bar"
        data-print="no"
        data-sdg={currentSdgId}
      >
        <div className="inner-chart">
          {/* SDG SELECTED + SWITCHER */}
          <div className="chart-head">
            <div className="inner">
              <div className="icon">
                <span className="sdg-icon">
                  <img
                    src={`/assets/images/sdg/icons/80/sdg-pastille-${currentSdgId}@2x.png`}
                    alt={`SDG ${currentSdgId} icon`}
                  />
                </span>
              </div>

              <div className="select select-sdg" data-print="no">
                <button className="btn btn-select btn-primary" type="button">
                  <span className="btn-label">SDG {currentSdgId}</span>
                  <span className="btn_icon"></span>
                </button>
                <div className="drop" data-uk-dropdown="">
                  <ul className="uk-nav uk-dropdown-nav">
                    {listSDG.map((sdg) => {
                      return (
                        <li
                          key={`sdgK-${sdg}`}
                          onClick={() => setModeRadar(sdg, "sdg")}
                        >
                          <span>SDG {sdg}</span>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>

              <div className="label">
                {SDGnames[currentSdgId] ? SDGnames[currentSdgId].shortName : ""}
              </div>
            </div>
          </div>

          {/* BACK BTN */}
          <div className="chart-back">
            <div className="inner">
              <div className="back" data-print="no">
                <button
                  className="btn label-external btn-back"
                  onClick={() => setModeRadar(0, "sdg")}
                >
                  <span className="btn-icon btn-primary circle">
                    <i className="fas fa-chevron-left"></i>
                  </span>
                  <span className="btn-label">
                    <i>Back to SDG visualisation</i>
                  </span>
                </button>
              </div>
              <div className="indications" data-print="no">
                <span>
                  <i>Click on target to view indicators</i>
                </span>
              </div>
            </div>
          </div>

          {/* CONTENT */}
          <div className="chart-content">
            <div className="inner">
              {!sdgImpactSynthesis.sdg[currentSdgId] ? (
                // NO DATA
                <div className="no-datas">
                  <div>
                    <p>This SDG is not relevant for this project's type</p>
                    <p>
                      <button
                        className="link"
                        onClick={() => setModeRadar(0, "sdg")}
                      >
                        <span className="link-label">
                          <b>Go to SDG visualisation</b>
                        </span>
                      </button>
                    </p>
                  </div>
                </div>
              ) : (
                // LA LISTE
                <ul className="list list-targets">
                  {/* LOOP TARGET */}
                  {Object.keys(sdgImpactSynthesis.sdg[currentSdgId].datas).map(
                    (target) => {
                      if (
                        target !== "total" &&
                        target !== "totalConfidenceIndex"
                      ) {
                        let currVal =
                          sdgImpactSynthesis.sdg[currentSdgId].datas[target];

                        return (
                          <li className="target" key={`targetK-${target}`}>
                            <div
                              id={`remoteTarget_${target.replace(".", "_")}`}
                              className="inner-target"
                              data-uk-toggle="cls: open;"
                            >
                              <div className="target-num">{target}</div>

                              <div className="target-content">
                                <div className="target-title">
                                  {SDGratings[target]}
                                </div>

                                <div className="target-score">
                                  <div className="cmp-score score-bar">
                                    {/* PREMIER BARRE */}
                                    {!showConfidenceIndex ? (
                                      <div
                                        className="bar bar1"
                                        data-score={
                                          currVal.total !== null
                                            ? (currVal.total * 100).toFixed(0)
                                            : ""
                                        }
                                      >
                                        {currVal.total === null && (
                                          <span className="bar noData">
                                            no data
                                          </span>
                                        )}
                                      </div>
                                    ) : (
                                      <div
                                        className="bar bar1"
                                        data-score={
                                          currVal.totalConfidenceIndex
                                            !== null ? (
                                                currVal.totalConfidenceIndex *
                                                100
                                              ).toFixed(0)
                                            : ""
                                        }
                                      >
                                        {currVal.totalConfidenceIndex ===
                                          null && (
                                          <span className="bar noData">
                                            no data
                                          </span>
                                        )}
                                      </div>
                                    )}

                                    {/* IF COMPARAISON -- 2em BARRE */}
                                    {comparaisonMode &&
                                      (!showConfidenceIndexComparaison ? (
                                        <div
                                          className="bar bar2"
                                          data-score={
                                            comparaisonSdgSynthesis &&
                                            comparaisonSdgSynthesis.sdg[
                                              currentSdgId
                                            ] &&
                                            comparaisonSdgSynthesis.sdg[
                                              currentSdgId
                                            ].datas[target]
                                              ? (
                                                  comparaisonSdgSynthesis.sdg[
                                                    currentSdgId
                                                  ].datas[target].total * 100
                                                ).toFixed(0)
                                              : ""
                                          }
                                        ></div>
                                      ) : (
                                        <div
                                          className="bar bar2"
                                          data-score={
                                            comparaisonSdgSynthesis &&
                                            comparaisonSdgSynthesis.sdg[
                                              currentSdgId
                                            ] &&
                                            comparaisonSdgSynthesis.sdg[
                                              currentSdgId
                                            ].datas[target]
                                              ? (
                                                  comparaisonSdgSynthesis.sdg[
                                                    currentSdgId
                                                  ].datas[target]
                                                    .totalConfidenceIndex * 100
                                                ).toFixed(0)
                                              : ""
                                          }
                                        ></div>
                                      ))}
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* LOOP INDICATOR */}
                            <div className="wrapp-subtarget">
                              <span
                                className="btn-close-indicators"
                                data-uk-toggle={`target: #remoteTarget_${target.replace(
                                  ".",
                                  "_"
                                )}; cls: open;`}
                              >
                                <i>Close indicators</i>
                                <i className="fas fa-times"></i>
                              </span>

                              <ul className="list list-subtargets">
                                {Object.keys(currVal.datas).map((indicator) => {
                                  let indicatorVal = currVal.datas[indicator];
                                  
                                  return (
                                    <li
                                      className="subtarget"
                                      key={`indicK-${indicator}`}
                                    >
                                      <div className="inner-subtarget">
                                        <div className="subtarget-content">
                                          <div className="subtarget-title">
                                            <span className="subtarget-num">
                                              {
                                                !!indicatorVal.indicatorNumbering 
                                                ? 
                                                (indicatorVal.indicatorNumbering + " (" + indicator + ")")
                                                :
                                                  indicator}
                                            </span>
                                            <span>
                                              {indicatorVal.indicatorText}
                                            </span>
                                          </div>

                                          <div className="subtarget-score">
                                            <div className="cmp-score score-bar">
                                              {!showConfidenceIndex ? (
                                                <div
                                                  className="bar bar1"
                                                  data-score={
                                                    indicatorVal.questionNote !==
                                                    null
                                                      ? (
                                                          indicatorVal.questionNote *
                                                          100
                                                        ).toFixed(0)
                                                      : ""
                                                  }
                                                >
                                                  {indicatorVal.questionNote ===
                                                    null && (
                                                    <span className="bar noData">
                                                      no data
                                                    </span>
                                                  )}
                                                </div>
                                              ) : (
                                                <div
                                                  className="bar bar1"
                                                  data-score={
                                                    indicatorVal.questionNoteConfidenceIndex !==
                                                    null
                                                      ? (
                                                          indicatorVal.questionNoteConfidenceIndex *
                                                          100
                                                        ).toFixed(0)
                                                      : ""
                                                  }
                                                >
                                                  {indicatorVal.questionNoteConfidenceIndex ===
                                                    null && (
                                                    <span className="bar noData">
                                                      no data
                                                    </span>
                                                  )}
                                                </div>
                                              )}

                                              {/* IF COMPARAISON -- 2em BARRE */}
                                              {comparaisonMode &&
                                                (!showConfidenceIndexComparaison ? (
                                                  <div
                                                    className="bar bar2"
                                                    data-score={
                                                      comparaisonSdgSynthesis &&
                                                      comparaisonSdgSynthesis
                                                        .sdg[currentSdgId] &&
                                                      comparaisonSdgSynthesis
                                                        .sdg[currentSdgId]
                                                        .datas[target] &&
                                                      comparaisonSdgSynthesis
                                                        .sdg[currentSdgId]
                                                        .datas[target].datas[
                                                        indicator
                                                      ] &&

                                                      comparaisonSdgSynthesis
                                                              .sdg[currentSdgId]
                                                              .datas[target]
                                                              .datas[indicator]
                                                              .questionNote !== null 
                                                              
                                                        ? (
                                                            comparaisonSdgSynthesis
                                                              .sdg[currentSdgId]
                                                              .datas[target]
                                                              .datas[indicator]
                                                              .questionNote *
                                                            100
                                                          ).toFixed(0)
                                                        : ""
                                                    }
                                                  >
                                                    {comparaisonSdgSynthesis &&
                                                      comparaisonSdgSynthesis
                                                        .sdg[currentSdgId] &&
                                                      comparaisonSdgSynthesis
                                                        .sdg[currentSdgId]
                                                        .datas[target] &&
                                                      comparaisonSdgSynthesis
                                                        .sdg[currentSdgId]
                                                        .datas[target].datas[
                                                        indicator
                                                      ] && comparaisonSdgSynthesis
                                                              .sdg[currentSdgId]
                                                              .datas[target]
                                                              .datas[indicator]
                                                              .questionNote ===
                                                    null && (
                                                    <span className="bar noData">
                                                      no data
                                                    </span>
                                                  )}

                                                  </div>
                                                ) : (
                                                  <div
                                                    className="bar bar2"
                                                    data-score={
                                                      comparaisonSdgSynthesis &&
                                                      comparaisonSdgSynthesis
                                                        .sdg[currentSdgId] &&
                                                      comparaisonSdgSynthesis
                                                        .sdg[currentSdgId]
                                                        .datas[target] &&
                                                      comparaisonSdgSynthesis
                                                        .sdg[currentSdgId]
                                                        .datas[target].datas[
                                                        indicator
                                                      ] &&
                                                      comparaisonSdgSynthesis
                                                              .sdg[currentSdgId]
                                                              .datas[target]
                                                              .datas[indicator]
                                                              .questionNoteConfidenceIndex !== null
                                                        ? (
                                                            comparaisonSdgSynthesis
                                                              .sdg[currentSdgId]
                                                              .datas[target]
                                                              .datas[indicator]
                                                              .questionNoteConfidenceIndex *
                                                            100
                                                          ).toFixed(0)
                                                        : ""
                                                    }
                                                  >
                                                    {comparaisonSdgSynthesis &&
                                                      comparaisonSdgSynthesis
                                                        .sdg[currentSdgId] &&
                                                      comparaisonSdgSynthesis
                                                        .sdg[currentSdgId]
                                                        .datas[target] &&
                                                      comparaisonSdgSynthesis
                                                        .sdg[currentSdgId]
                                                        .datas[target].datas[
                                                        indicator
                                                      ] && comparaisonSdgSynthesis
                                                              .sdg[currentSdgId]
                                                              .datas[target]
                                                              .datas[indicator]
                                                              .questionNoteConfidenceIndex ===
                                                    null && (
                                                    <span className="bar noData">
                                                      no data
                                                    </span>
                                                  )}
                                                  </div>
                                                ))}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                          </li>
                        );
                      } 

                      return;
                    }
                  )}
                </ul>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SisBoxDisplayDatas;

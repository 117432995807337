import { SdgImpactSynthesisFilter, SdgImpactSynthesisInterface, SdgImpactSynthesisSelection } from "models/sdg-impact-synthesis.model";
import { getSurveySdgImpact, getSurveySDGImpactFilter } from "modules/platform/services/sdg-impact-synthesis.service";
import React, { useRef, useState } from "react";
import SisCurrentSelection from "../SisCurrentSelection/SisCurrentSelection";
import SisFilterProjectLeaderViewSection from "../SisFilterProjectLeaderViewSection/SisFilterProjectLeaderViewSection";
import SisFilterSection from "../SisFilterSection/SisFilterSection";
import SisProjectSelection from "../SisProjectSelection/SisCurrentSelection";
import styles from "../SdgImpactSynthesisSection/styles.module.scss";

interface FilterSectionProps {
  firstSerieHasSelection: boolean;
  secondSerieHasSelection: boolean;
  setFirstSerieHasSelection: any;
  setSecondSerieHasSelection: any;
  setIsRosaceLoading: any;
  accessToken: string;
  firstSerieYear: number;
  secondSerieYear: number;
  firstSerieTypeOfSelection: string;
  secondSerieTypeOfSelection: string;
  setFirstSerieSdgResult: any;
  setSecondSerieSdgResult: any;
  firstSerieSelection: SdgImpactSynthesisSelection;
  firstSerieAvailableFilter: SdgImpactSynthesisFilter;
  secondSerieSelection: SdgImpactSynthesisSelection;
  secondSerieAvailableFilter: SdgImpactSynthesisFilter;
  isProjectLeaderView: boolean;
  setFirstSerieSelection: any;
  setSecondSerieSelection: any;
  defaultSelection: SdgImpactSynthesisSelection;
  setFirstSerieYear: any;
  setSecondSerieYear: any;
  setIsDataLoading: any;
  setFirstSerieAvailableFilter: any;
  setSecondSerieAvailableFilter: any;
  setComparaisonMode: any;
  comparaisonMode: boolean;
  campaigns: number[];
  secondSerieSdgResult: SdgImpactSynthesisInterface;
  firstSerieSdgResult: SdgImpactSynthesisInterface;
  setFirstSerieTypeOfSelection: any;
  setSecondSerieTypeOfSelection:any;
  setFirstSerieShowConfidenceIndex: any;
  firstSerieShowConfidenceIndex: boolean;
  setSecondSerieShowConfidenceIndex: any;
  secondSerieShowConfidenceIndex: boolean;
  sdgCallNumber: number; setSdgCallNumber:any;
}

const FilterSection = ({
  firstSerieHasSelection, secondSerieHasSelection, setFirstSerieHasSelection, 
  setSecondSerieHasSelection, setIsRosaceLoading, accessToken, firstSerieYear,
  secondSerieYear, firstSerieTypeOfSelection, secondSerieTypeOfSelection, 
  setFirstSerieSdgResult, setSecondSerieSdgResult, firstSerieSelection,
  firstSerieAvailableFilter, secondSerieSelection, secondSerieAvailableFilter,
  isProjectLeaderView, setFirstSerieSelection, setSecondSerieSelection, defaultSelection,
  setFirstSerieYear, setSecondSerieYear, setIsDataLoading,
  setFirstSerieAvailableFilter, setSecondSerieAvailableFilter, setComparaisonMode, 
  comparaisonMode, campaigns, secondSerieSdgResult, firstSerieSdgResult, 
  setFirstSerieTypeOfSelection, setSecondSerieTypeOfSelection, 
  setFirstSerieShowConfidenceIndex, firstSerieShowConfidenceIndex,
  setSecondSerieShowConfidenceIndex, secondSerieShowConfidenceIndex,
  sdgCallNumber, setSdgCallNumber
}: FilterSectionProps) => 
{

  const [toggleSerie, setToggleSerie] = useState<string>("first");
  const stateRef = useRef<any>();
  stateRef.current = sdgCallNumber;

  const calculHasSelection = (
    newSelection: SdgImpactSynthesisSelection,
    serieNumber: string
  ) => {
    let currHasSelection: boolean;
    if (serieNumber === "first") {
      currHasSelection = firstSerieHasSelection;
    } else {
      currHasSelection = secondSerieHasSelection;
    }

    if (
      (newSelection.projectTypes.length >= 1 &&
        "all" !== newSelection.projectTypes[0]) ||
      (newSelection.meridiamFunds.length >= 1 &&
        "all" !== newSelection.meridiamFunds[0]) ||
      (newSelection.projectStates.length >= 1 &&
        "all" !== newSelection.projectStates[0]) ||
      (newSelection.projectInvestmentPhases.length >= 1 &&
        "all" !== newSelection.projectInvestmentPhases[0]) ||
      (newSelection.location.length >= 1 && "all" !== newSelection.location[0])
    ) {
      currHasSelection = true;
    } else {
      currHasSelection = false;
    }

    if (serieNumber === "first") {
      setFirstSerieHasSelection(currHasSelection);
    } else {
      setSecondSerieHasSelection(currHasSelection);
    }
  };

  const updateSdgResult = (
    serieNumber: string,
    selection: SdgImpactSynthesisSelection,
    thisCallNum: number,
    yearValue?: number
  ) => {

    setIsRosaceLoading(true);
    if (accessToken) {
      let year = yearValue
        ? yearValue
        : serieNumber === "first"
          ? firstSerieYear
          : secondSerieYear;

      let typeOfSelection =
        serieNumber === "first"
          ? firstSerieTypeOfSelection
          : secondSerieTypeOfSelection;

      getSurveySdgImpact(accessToken, year, selection, typeOfSelection)
        .then((result: SdgImpactSynthesisInterface) => {
          // console.log("%c -- SDG Datas : ", "color:green;font-size:13px");
          // console.log(result);

          if(thisCallNum === stateRef.current){
            if (serieNumber === "first") {
              setFirstSerieSdgResult(result);
            } else {
              setSecondSerieSdgResult(result);
            }
          }
        })
        .then(() => {
          setIsRosaceLoading(false);
        });
    }
  };

  const toggleSelection = (newVal: any, type: string, serieNumber: string) => {
    let newCallNumer = sdgCallNumber+1;
    setSdgCallNumber(newCallNumer);

    let newSelection: SdgImpactSynthesisSelection;
    let availableF: SdgImpactSynthesisFilter | null;

    if (serieNumber === "first") {
      newSelection = { ...firstSerieSelection };
      availableF = firstSerieAvailableFilter
        ? { ...firstSerieAvailableFilter }
        : null;
    } else {
      newSelection = { ...secondSerieSelection };
      availableF = secondSerieAvailableFilter
        ? { ...secondSerieAvailableFilter }
        : null;
    }

    let changed = false;

    switch (type) {
      case "projectTypes":
        if(isProjectLeaderView === true){
          // only one selection
          newSelection.projectTypes = [newVal];
          changed = true;
        } else {
          if (newSelection.projectTypes.includes("all")) {
            newSelection.projectTypes = [newVal];
            changed = true;
          } else if (newSelection.projectTypes.includes(newVal)) {
            let newArr: string[] = [];
            for (let item in newSelection.projectTypes) {
              if (newSelection.projectTypes[item] !== newVal) {
                newArr.push(newSelection.projectTypes[item]);
              }
            }
            if(newArr.length === 0){
              if(isProjectLeaderView && !!availableF && !!availableF.types && availableF.types.length > 0){
                newArr = [availableF.types[0].types[0].projectType]
              } else {
                newArr = ["all"];
              }
            }
            newSelection.projectTypes = newArr;
            changed = true;
          } else {
            newSelection.projectTypes.push(newVal);
            changed = true;
          }
        }
      break;
      case "location":
        if (newSelection.location.includes("all")) {
          newSelection.location = [newVal];
          changed = true;
        } else if (newSelection.location.includes(newVal)) {
          let newArr: string[] = [];
          for (let item in newSelection.location) {
            if (newSelection.location[item] !== newVal) {
              newArr.push(newSelection.location[item]);
            }
          }
          if(newArr.length === 0){
            newArr = ["all"];
          }
          newSelection.location = newArr;
          changed = true;
        } else {
          newSelection.location.push(newVal);
          if (
            availableF &&
            newSelection.location.length === availableF.countries.length
          ) {
            newSelection.location = ["all"];
          }
          changed = true;
        }
        break;
      case "meridiamFunds":
        if(isProjectLeaderView === true){
          // only one selection
          newSelection.meridiamFunds = [newVal];
          changed = true;
        } else {
          if (newSelection.meridiamFunds.includes("all")) {
            newSelection.meridiamFunds = [newVal];
            changed = true;
          } else if (newSelection.meridiamFunds.includes(newVal)) {
            let newArr: string[] = [];
            for (let item in newSelection.meridiamFunds) {
              if (newSelection.meridiamFunds[item] !== newVal) {
                newArr.push(newSelection.meridiamFunds[item]);
              }
            }
            if(newArr.length === 0){
              newArr = ["all"];
            }
            newSelection.meridiamFunds = newArr;
            changed = true;
          } else {
            newSelection.meridiamFunds.push(newVal);
            if (
              availableF &&
              newSelection.meridiamFunds.length === availableF.funds.length
            ) {
              newSelection.meridiamFunds = ["all"];
            }
            changed = true;
          }
        }
        break;
      case "projectStates":
        if (newSelection.projectStates.includes("all")) {
          newSelection.projectStates = [newVal];
          changed = true;
        } else if (newSelection.projectStates.includes(newVal)) {
          let newArr: string[] = [];
          for (let item in newSelection.projectStates) {
            if (newSelection.projectStates[item] !== newVal) {
              newArr.push(newSelection.projectStates[item]);
            }
          }
          if(newArr.length === 0){
            newArr = ["all"];
          }
          newSelection.projectStates = newArr;
          changed = true;
        } else {
          newSelection.projectStates.push(newVal);
          if (
            availableF &&
            newSelection.projectStates.length ===
            availableF.projectStates.length
          ) {
            newSelection.projectStates = ["all"];
          }
          changed = true;
        }
        break;
      case "projectInvestmentPhases":
        if (newSelection.projectInvestmentPhases.includes("all")) {
          newSelection.projectInvestmentPhases = [newVal];
          changed = true;
        } else if (newSelection.projectInvestmentPhases.includes(newVal)) {
          let newArr: string[] = [];
          for (let item in newSelection.projectInvestmentPhases) {
            if (newSelection.projectInvestmentPhases[item] !== newVal) {
              newArr.push(newSelection.projectInvestmentPhases[item]);
            }
          }
          if(newArr.length === 0){
            newArr = ["all"];
          }
          newSelection.projectInvestmentPhases = newArr;
          changed = true;
        } else {
          newSelection.projectInvestmentPhases.push(newVal);
          if (
            availableF &&
            newSelection.projectInvestmentPhases.length ===
            availableF.projectInvestmentPhases.length
          ) {
            newSelection.projectInvestmentPhases = ["all"];
          }
          changed = true;
        }
        break;
      case "project":
        if (newSelection.projects.length === availableF?.projects.length) {
          newSelection.projects = [newVal];
          changed = true;
        } else if (newSelection.projects.includes(newVal)) {
          let newArr: number[] = [];
          for (let item in newSelection.projects) {
            if (newSelection.projects[item] !== newVal) {
              newArr.push(newSelection.projects[item]);
            }
          }
          if(newArr.length === 0){
            availableF &&
            availableF.projects.forEach((project) => {
              if (!newArr.includes(project.id)) {
                newArr.push(project.id);
              }
            });
          }
          newSelection.projects = newArr;
          changed = true;
        } else {
          newSelection.projects.push(newVal);
          changed = true;
        }
        break;
    }

    // update custom selection
    if (type !== "project") {
      let projectIds: number[] = [];
      if (availableF) {
        availableF.projects.map((project) => {
          if (
            ("all" === newSelection.projectTypes[0] ||
              newSelection.projectTypes.includes(project.type)) &&
            ("all" === newSelection.meridiamFunds[0] ||
              newSelection.meridiamFunds.includes(project.meridiamFundCode)) &&
            ("all" === newSelection.projectStates[0] ||
              newSelection.projectStates.includes(project.stage)) &&
            ("all" === newSelection.projectInvestmentPhases[0] ||
              newSelection.projectInvestmentPhases.includes(
                project.investmentPhase
              )) &&
            ("all" === newSelection.location[0] ||
              newSelection.location.includes(project.countryCode))
          ) {
            projectIds.push(project.id);
          }
          newSelection.projects = projectIds;
        });
      }
    }

    if (changed) {
      if (serieNumber === "first") {
        setFirstSerieSelection(newSelection);
        updateSdgResult("first", newSelection, newCallNumer);
      } else {
        setSecondSerieSelection(newSelection);
        updateSdgResult("second", newSelection, newCallNumer);
      }
    }
    calculHasSelection(newSelection, serieNumber);
  };

  const resetSelection = (type: string, serieNumber: string) => {
    let newCallNumer = sdgCallNumber+1;
    setSdgCallNumber(newCallNumer);

    let newSelection: SdgImpactSynthesisSelection;
    let availableF: SdgImpactSynthesisFilter | null;
    let typeOfSelection = serieNumber === "first" ? firstSerieTypeOfSelection : secondSerieTypeOfSelection; 
    

    if (serieNumber === "first") {
      newSelection = { ...firstSerieSelection };
      availableF = firstSerieAvailableFilter
        ? { ...firstSerieAvailableFilter }
        : null;
    } else {
      newSelection = { ...secondSerieSelection };
      availableF = secondSerieAvailableFilter
        ? { ...secondSerieAvailableFilter }
        : null;
    }

    switch (type) {
      case "projectTypes":
        if(isProjectLeaderView && !!availableF && !!availableF.types && availableF.types.length > 0){
          newSelection.projectTypes = [availableF.types[0].types[0].projectType]
        } else {
          newSelection.projectTypes = ["all"];
        }
      break;
      case "meridiamFunds":
        if(isProjectLeaderView && !!availableF && !!availableF.funds && availableF.funds.length > 0){
          newSelection.meridiamFunds = [availableF.funds[0].mfCode]
        } else {
          newSelection.meridiamFunds = ["all"];
        }
        break;
      case "projectStates":
        newSelection.projectStates = ["all"];
        break;
      case "projectInvestmentPhases":
        newSelection.projectInvestmentPhases = ["all"];
        break;
      case "location":
        newSelection.location = ["all"];
        break;
      case "project":
        if (
          availableF &&
          newSelection.projects.length === availableF.projects.length
        ) {
          newSelection.projects = [];
        } else {
          availableF &&
            availableF.projects.forEach((project) => {
              if (!newSelection.projects.includes(project.id)) {
                newSelection.projects.push(project.id);
              }
            });
        }
        break;
      case "all":
        newSelection = {...defaultSelection};
        availableF &&
        availableF.projects.forEach((project) => {
          if (!newSelection.projects.includes(project.id)) {
            newSelection.projects.push(project.id);
          }
        });

        if(typeOfSelection === "by-type"){
          if(isProjectLeaderView && !!availableF && !!availableF.types && availableF.types.length > 0){
            newSelection.projectTypes = [availableF.types[0].types[0].projectType]
          }
        } else if(typeOfSelection === "by-fund"){
          if(isProjectLeaderView && !!availableF && !!availableF.funds && availableF.funds.length > 0){
            newSelection.meridiamFunds = [availableF.funds[0].mfCode]
          }
        }
      break;
    }
    if (serieNumber === "first") {
      setFirstSerieSelection(newSelection);
      updateSdgResult("first", newSelection, newCallNumer);
    } else {
      setSecondSerieSelection(newSelection);
      updateSdgResult("second", newSelection, newCallNumer);
    }
    calculHasSelection(newSelection, serieNumber);
  };

  const changeYear = (value: number, serieNumber: string) => {
    let newCallNumer = sdgCallNumber+1;
    setSdgCallNumber(newCallNumer);

    if (serieNumber === "first") {
      setFirstSerieYear(value);
    } else {
      setSecondSerieYear(value);
    }
    setIsDataLoading(true);

    // reset filter + SDG datas
    if (accessToken) {
      // get all available filter
      getSurveySDGImpactFilter(accessToken, value)
        .then((result: SdgImpactSynthesisFilter) => {
          // console.log("%c -- Filters : ", "color:green;font-size:13px");
          // console.log(result);

          if (serieNumber === "first") {
            setFirstSerieAvailableFilter(result);
          } else {
            setSecondSerieAvailableFilter(result);
          }

          let newSelection: SdgImpactSynthesisSelection = defaultSelection;
          let projectArr: number[] = [];
          result.projects.forEach((project) => {
            projectArr.push(project.id);
          });
          newSelection.projects = projectArr;

          if (serieNumber === "first") {
            setFirstSerieSelection(newSelection);
          } else {
            setSecondSerieSelection(newSelection);
          }
          updateSdgResult(serieNumber, newSelection, newCallNumer, value);
        })
        .then(() => {
          setIsDataLoading(false);
        });
    }
  };

  const handleToggleSerie = (serieNumber: string) => {
    setToggleSerie(serieNumber);
    if (serieNumber === "second") {
      setComparaisonMode(true);
      calculHasSelection(secondSerieSelection, serieNumber);
    } else {
      calculHasSelection(firstSerieSelection, serieNumber);
    }
  };

  const openComparaison = () => {
    resetSelection("all", "second");
    setComparaisonMode(true);
  }

  return (
    <div className={`${styles.col} ${styles.col1}`} data-print="no">
      <div
        className={`${styles["builder-wrapper"]} ${(toggleSerie === "first" && firstSerieHasSelection === true) ||
          (toggleSerie === "second" && secondSerieHasSelection === true)
          ? styles["has-filters"]
          : ""
          }`}
      >
        <div className={styles["builder-head"]}>
          <ul className={styles["series-list"]}>
            <li
              className={`${styles["list-item"]} ${toggleSerie === "first" ? styles.active : styles.inactive
                }`}
            >
              <button
                className={`${styles.btn} ${styles["btn-remote"]}`}
                onClick={() => {
                  handleToggleSerie("first");
                }}
              >
                <span className={styles.btn_label}>First Serie</span>
              </button>
            </li>
            <li
              className={`${styles["list-item"]} ${toggleSerie === "second" ? styles.active : styles.inactive
                }`}
            >
              <button
                className={`${styles.btn} ${styles["btn-remote"]}`}
                onClick={() => {
                  handleToggleSerie("second");
                }}
              >
                {comparaisonMode === false ? (
                  <span className={`${styles.btn_label} ${styles.plus}`}><i className="fas fa-plus"></i></span>
                ) : (
                  <span className={styles.btn_label}>Second Serie</span>
                )}
              </button>
            </li>
          </ul>
          <div className={styles["ui-indicator"]}>
            {comparaisonMode === false ? (
              <span
                onClick={() => {
                  openComparaison();
                }}
              >
                Click to add comparaison
              </span>
            ) : (
              <span
                onClick={() => {
                  setComparaisonMode(false);
                }}
              >
                Hide comparaison
              </span>
            )}
          </div>
        </div>

        {/* FILTER BOX */}
        <div className={styles["builder-body"]}>
          <div className={styles["serie-wrapper"]}>
            <div
              className={`serie serie-1 ${styles["serie"]} ${styles["serie-1"]
                } ${toggleSerie === "first" ? styles.open : styles.close}`}
            >
              {firstSerieSdgResult && firstSerieAvailableFilter && (
                !!isProjectLeaderView ?
                  <SisFilterProjectLeaderViewSection
                    campaigns={campaigns}
                    year={firstSerieYear}
                    setYear={changeYear}
                    typeOfSelection={firstSerieTypeOfSelection}
                    setTypeOfSelection={setFirstSerieTypeOfSelection}
                    serieSdgResult={firstSerieSdgResult}
                    availableFilter={firstSerieAvailableFilter}
                    selection={firstSerieSelection}
                    serieNumber="first"
                    toggleSelection={toggleSelection}
                    resetSelection={resetSelection}
                  />
                  :
                  <SisFilterSection
                    campaigns={campaigns}
                    year={firstSerieYear}
                    setYear={changeYear}
                    typeOfSelection={firstSerieTypeOfSelection}
                    setTypeOfSelection={setFirstSerieTypeOfSelection}
                    serieSdgResult={firstSerieSdgResult}
                    availableFilter={firstSerieAvailableFilter}
                    selection={firstSerieSelection}
                    serieNumber="first"
                    toggleSelection={toggleSelection}
                    resetSelection={resetSelection}
                    setShowConfidenceIndex={setFirstSerieShowConfidenceIndex}
                    showConfidenceIndex={firstSerieShowConfidenceIndex}
                  />
              )}
            </div>

            <div
              className={`serie serie-2 ${styles["serie"]} ${styles["serie-2"]
                } ${toggleSerie === "second" ? styles.open : styles.close}`}
            >
              {secondSerieSdgResult && secondSerieAvailableFilter && (
                !!isProjectLeaderView ?
                <SisFilterProjectLeaderViewSection
                  campaigns={campaigns}
                  year={secondSerieYear}
                  setYear={changeYear}
                  typeOfSelection={secondSerieTypeOfSelection}
                  setTypeOfSelection={setSecondSerieTypeOfSelection}
                  serieSdgResult={secondSerieSdgResult}
                  availableFilter={secondSerieAvailableFilter}
                  selection={secondSerieSelection}
                  serieNumber="second"
                  toggleSelection={toggleSelection}
                  resetSelection={resetSelection}
                />
                :
                <SisFilterSection
                  campaigns={campaigns}
                  year={secondSerieYear}
                  setYear={changeYear}
                  typeOfSelection={secondSerieTypeOfSelection}
                  setTypeOfSelection={setSecondSerieTypeOfSelection}
                  serieSdgResult={secondSerieSdgResult}
                  availableFilter={secondSerieAvailableFilter}
                  selection={secondSerieSelection}
                  serieNumber="second"
                  toggleSelection={toggleSelection}
                  resetSelection={resetSelection}
                  setShowConfidenceIndex={setSecondSerieShowConfidenceIndex}
                  showConfidenceIndex={secondSerieShowConfidenceIndex}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      {/* BASKET SELECTION CARTOUCHE BLEUE FONCEE */}
      {!isProjectLeaderView && (
        toggleSerie === "first" ? (
          <SisCurrentSelection
            selection={firstSerieSelection}
            resetSelection={resetSelection}
            serieNumber="first"
            hasSelection={firstSerieHasSelection}
            toggleSelection={toggleSelection}
          />
        ) : (
          // <div className="container-charts">
          <SisCurrentSelection
            selection={secondSerieSelection}
            resetSelection={resetSelection}
            serieNumber={"second"}
            hasSelection={secondSerieHasSelection}
            toggleSelection={toggleSelection}
          />
          // </div>
        )
      )}

      {/* LISTE PROJETS DANS LA SELECTION CARTOUCHE BLANC */}
      {!isProjectLeaderView && (
        toggleSerie === "first"
          ? firstSerieAvailableFilter &&
          firstSerieTypeOfSelection === "by-filter" && (
            <SisProjectSelection
              selection={firstSerieSelection}
              avalaibleFilter={firstSerieAvailableFilter}
              hasSelection={firstSerieHasSelection}
            />
          )
          : secondSerieAvailableFilter &&
          secondSerieTypeOfSelection === "by-filter" && (
            <SisProjectSelection
              selection={secondSerieSelection}
              avalaibleFilter={secondSerieAvailableFilter}
              hasSelection={secondSerieHasSelection}
            />
          )
      )}
    </div>
  )
}

export default FilterSection;
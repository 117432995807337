import React from "react";

import "./styles.scss";

const SubmitSaveButtons: React.FC<{
  updatedBy?: string;
  updatedOn?: string;
  handleClose?: any;
  handleSave?: any;
  formDirty?: string[];
  formCanBeSave?: boolean;
}> = ({
  updatedBy,
  updatedOn,
  handleClose,
  handleSave,
  formDirty,
  formCanBeSave,
}) => {
  return (
    <div className="container_buttons">
      {(!!updatedBy || !!updatedOn) && (
        <p>
          Last update : {updatedBy}
          {!!updatedBy && !!updatedOn && ", "}
          {updatedOn}
        </p>
      )}
      {!!handleClose && (
        <button
          className="btn btn-select btn-primary"
          type="button"
          onClick={() =>
            true !== handleClose &&
            !!formDirty &&
            formDirty.length === 0 &&
            handleClose()
          }
          data-uk-toggle={`${
            !!formDirty && formDirty.length > 0 ? "#warnCancel" : false
          }`}
        >
          <span className="btn-label">Close</span>
          <span className="btn-icon">
            <i className="fas fa-times"></i>
          </span>
        </button>
      )}
      {!!handleSave && (
        <button
          className="btn btn-select btn-primary"
          type="button"
          onClick={() => true !== handleSave && handleSave()}
          data-uk-toggle="#warnSave"
          disabled={
            (!!formDirty && 0 === formDirty.length) ||
            (formCanBeSave !== null && !formCanBeSave)
          }
        >
          <span className="btn-label">Save</span>
          <span className="btn-icon">
            <i className={`fas fa-save`}></i>
          </span>
        </button>
      )}
    </div>
  );
};

export default SubmitSaveButtons;

import React, { useState, useEffect, useContext } from "react";
import { RootState, ThunkDispatch } from "models/store.models";
import Loader from "../../../shared/components/Loader/Loader";
import { connect } from "react-redux";
import Header from "../../../shared/components/Header/Header";
import HubToolBar from "modules/hub/components/HubToolBar/HubToolBar";
import SPVReporting from "../SPVReporting/SPVReporting";
import config from "modules/config";

import "../../../../assets/scss/custom/tools/_base.scss";
import "./styles.scss";
import { getAllProjectsByYear } from "modules/shared/services/project.service";
import {
  getTemplatesDirectories,
  getFiles,
  getTemplatesByDirectory,
  // getBasicIndicators,
  getProjectIndicatorsAll,
  getDocuments
} from "modules/shared/services/spv-reporting.service";
import { PermissionContext } from "context/Permission.context";

const mapStateToProps = ({ session }: RootState) => ({
  accessToken: session?.sessionData?.accessToken
    ? session?.sessionData?.accessToken
    : "",
  user: session?.sessionData?.userData,
});

const mapDispatchToProps = (dispatch: ThunkDispatch) => ({});

type HubProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const HubDashboard = ({ user, accessToken }: HubProps) => {

  const { isLeaderView, isMeridiamGranted } = useContext(PermissionContext);

  const [showLoading, setShowLoading] = useState<boolean>(false);

  const [init, setInit] = useState<boolean>(false);

  const [update, setUpdate] = useState<boolean>(false);

  const [projectsList, setProjectsList] = useState<any>([]);

  const [fundsList, setFundsList] = useState<any | null>(null);

  const [documentsList, setDocumentsList] = useState<any>(null);

  const [indicatorsProjectsList, setIndicatorsProjectsList] = useState<any>([]);

  const initReporting = async (forced: boolean) => {

    let lsProjects: any = null;
    let lsFunds: any = null;
    let lsIndicators: any = null;
    let cacheStorage: any = null;
    if(!!caches){
      cacheStorage = await caches.open(`reportl-${!!user ? user.id : ""}`);
      lsProjects = await cacheStorage.match('projects');
      lsFunds = await cacheStorage.match('funds');
      lsIndicators = await cacheStorage.match('indicators');
    }

    const allDocuments: any = await getDocuments(accessToken);

    if ('ok' === allDocuments.status) {
      setDocumentsList(allDocuments.data);
    }

    if (!!user && !!isMeridiamGranted && !forced && !!lsProjects && !!lsFunds && !!lsIndicators) {
      // if (!!user && ("global-admin" === user.platformRole || "technical-admin" === user.platformRole) && !forced && !!lsProjects && !!lsFunds && !!lsIndicators) {
      setFundsList(await lsFunds.json());
      setProjectsList(await lsProjects.json());
      setIndicatorsProjectsList(await lsIndicators.json());
    } else {
      const directories: any = await getTemplatesDirectories(accessToken);

      const allProjects: any = await getAllProjectsByYear(accessToken, config.current_year, "reporting");

      const allIndicatorsProjects: any = await getProjectIndicatorsAll(accessToken);

      // const allIndicators: any = await getBasicIndicators(accessToken);

      if (!!allIndicatorsProjects.data) {
        setIndicatorsProjectsList(allIndicatorsProjects.data);
      }

      // let totalIndicators: number = 0;
      // if(!!allIndicators && 'ok' === allIndicators.status && 0 < allIndicators.data.length){
      //   totalIndicators = 2;
      //   for(let i in allIndicators.data){
      //     if(!!i.match(/Display$/)){
      //       totalIndicators += (!!allIndicators.data[i]) ? 1 : 0;
      //     }
      //   }
      // }

      // console.log("");
      // console.log("__ INIT REPORTING __");
      // console.log(directories);
      // console.log(allProjects);
      // console.log(allDocuments);
      // console.log(allIndicatorsProjects.data);
      // console.log("");
      // allProjects = allProjects.filter((a: any) => a.projectCode === "SP028D");

      const funds: any = {};
      let pList: any = [];
      let templates: any = [];

      // allProjects = allProjects.filter((a: any) => 'FR014' === a.projectCode);

      if (!!allProjects && 0 < allProjects.length) {
        for (let ap in allProjects) {
          const p: any = allProjects[ap];
          // const slugProject: string = slugify(p.projectName, { lower: true });
          const slugProject: string = `${p.projectCode} - ${p.projectName.trim()}`;

          let tmpFiles: any = {};
          let tmpTemplates: any = [];

          if (!!directories.directories) {
            const directory: any = directories.directories.find(
              // (d: any) => d.name === slugify(p.projectName, { lower: true })
              (d: any) => d.name === slugProject
            );
            if (!!directory && !!directory.hasChildren) {
              tmpTemplates = await getTemplatesByDirectory(
                accessToken,
                directory.id
              );
            }
          }

          // Need to get all the files including the ones which have no template
          // if (!!tmpTemplates.files && 0 < tmpTemplates.files.length) {
          tmpFiles = await getFiles(accessToken, "upload", encodeURIComponent(slugProject));
          // }

          const docsList: any = allDocuments.data.map((d: any) => parseInt(d.code));
          const documentsNoTemplate: any = allDocuments.data.filter((d: any) => !!d.noTemplate);

          // console.log('');
          // console.log('__ DOCS LIST __');
          // console.log(docsList);
          // console.log(documentsNoTemplate);
          // console.log(tmpFiles);
          // console.log(allDocuments.data.length);

          if (!!p.meridiamFunds) {
            for (let mf in p.meridiamFunds) {
              const meridiamFund: any = p.meridiamFunds[mf].meridiamFund;

              if('undefined' === meridiamFund || !meridiamFund){
                continue;
              }

              if (!!meridiamFund && "undefined" === typeof funds[meridiamFund.fundId]) {
                funds[meridiamFund.fundId] = {
                  code: meridiamFund.fundId,
                  name: meridiamFund.fundName,
                  indCompletion: allProjects.filter((ap: any) => {
                    let f: boolean = false;
                    for (let ind in ap.meridiamFunds) {
                      if (!!ap.meridiamFunds[ind].meridiamFund && !!meridiamFund) {
                        if (ap.meridiamFunds[ind].meridiamFund.fundId === meridiamFund.fundId) {
                          f = true;
                          break;
                        }
                      }
                    }
                    return f;
                  }).length,
                  totalIndCompletion: 0,
                  docCompletion: 0,
                  totalDocCompletion: 0,
                  // projects: []
                  files: [],
                };
              }

              // Check if no template should be count here
              if (!!tmpTemplates.files && allDocuments.data.length) {
                funds[meridiamFund.fundId].docCompletion += tmpTemplates.files.filter((f: any) => {
                  return f.name.match(new RegExp('^(' + docsList.join('|') + ')(\s)?(\\.|-){1}', 'gi')) ? true : false;
                }).length + documentsNoTemplate.length;
              } else {
                funds[meridiamFund.fundId].docCompletion += documentsNoTemplate.length;
              }

              if (!!tmpFiles.files && !!meridiamFund) {
                funds[meridiamFund.fundId].totalDocCompletion += tmpFiles.files.filter((f: any) => {
                  const match: any = f.name.match(new RegExp('^(' + docsList.join('|') + ')(\s)?(\\.|-){1}', 'gi'));
                  const code: string = !!match ? match[0].replace(/[^\d]+/, '') : null;

                  const isNoTemplateFile: boolean = (!!code && !!documentsNoTemplate.find((nt: any) => nt.code === code));
                  return !!match && (!!isNoTemplateFile || (!isNoTemplateFile && !!code && !!tmpTemplates && !!tmpTemplates.files && tmpTemplates.files.find((t: any) => t.name.match(new RegExp(`^${code}\s?(\.|-)`, 'gi')))));
                }).length;

                funds[meridiamFund.fundId].files = [...funds[meridiamFund.fundId].files, ...tmpFiles.files];
              }
            }
          }

          let docCompletion: number = 0;
          if (!!tmpTemplates.files && allDocuments.data.length) {
            docCompletion += tmpTemplates.files.filter((f: any) => {
              return f.name.match(new RegExp('^(' + docsList.join('|') + ')(\s)?(\\.|-){1}', 'gi')) ? true : false;
            }).length + documentsNoTemplate.length;
          } else {
            docCompletion += documentsNoTemplate.length;
          }

          let totalDocCompletion: number = 0;
          if (!!tmpFiles.files && allDocuments.data.length) {
            totalDocCompletion += tmpFiles.files.filter((f: any) => {

              const match: any = f.name.match(new RegExp('^(' + docsList.join('|') + ')(\s)?(\\.|-){1}', 'gi'));
              const code: string = !!match ? match[0].replace(/[^\d]+/, '') : null;

              const isNoTemplateFile: boolean = (!!code && !!documentsNoTemplate.find((nt: any) => nt.code === code));
              return !!match && (!!isNoTemplateFile || (!isNoTemplateFile && !!code && !!tmpTemplates.files && tmpTemplates.files.find((t: any) => t.name.match(new RegExp(`^${code}\s?(\.|-)`, 'gi')))));
            }).length;
          }

          // console.log('totalDocCompletion ', totalDocCompletion)

          const tmpPlist: any = {
            code: p.projectCode,
            name: p.projectName,
            group: p.reportingGroup,
            templates: !!tmpTemplates.files ? tmpTemplates.files : [],
            // Check files upload if templates exist for this project
            directory: !!tmpFiles.directory ? tmpFiles.directory : null,
            files: !!tmpFiles.files ? tmpFiles.files : [],
            indValidation: false,
            docCompletion: docCompletion,
            totalDocCompletion: totalDocCompletion,
            funds: !!p.meridiamFunds ? p.meridiamFunds.map((mf: any) => !!mf.meridiamFund && mf.meridiamFund.fundId) : [],
          };

          // console.log(tmpPlist)

          templates.push(tmpTemplates.files);
          pList.push(tmpPlist);
        }
      }

      let tmpFunds: any = Object.values(funds);

      tmpFunds = tmpFunds.map((mf: any) => {
        if (!!allIndicatorsProjects.data) {
          mf.totalIndCompletion += Object.values(allIndicatorsProjects.data).filter((ip: any) => !!ip.isComplete && !!ip.project.meridiamFunds.find((pm: any) => pm.meridiamFund.fundId === mf.code)).length
        }
        return mf;
      })

      // setTemplatesList(templates);
      setFundsList(tmpFunds);

      pList = pList.map((p: any) => {
        p.indValidation = allIndicatorsProjects.data.find((i: any) => i.projectCode.toUpperCase() === p.code && !!i.isComplete)
        return p;
      })

      setProjectsList(pList);

      if (!!user && !!isMeridiamGranted && !!caches) {
        cacheStorage.put('projects', new Response(JSON.stringify(pList)));
        cacheStorage.put('funds', new Response(JSON.stringify(tmpFunds)));
        cacheStorage.put('indicators', new Response(JSON.stringify(allIndicatorsProjects.data)));
      }
    }
    setShowLoading(false);
  };

  useEffect(() => {
    setInit(true);
  }, []);

  useEffect(() => {
    if (!!init && !!accessToken) {
      setShowLoading(true);
      initReporting(false);
    }
  }, [init]);

  useEffect(() => {
    if (!!update) {
      setShowLoading(true);
      initReporting(true);
      setUpdate(false);
    }
  }, [update]);

  return (
    <>
      <Loader
        className={!!showLoading ? "on" : "off"}
        color="multicolors"
        size="large"
      />

      <div className="header-wrapper" data-uk-sticky="media:768;">
        <Header
          titleContext="Platform"
          colorContext={"seaLight"}
          tool={"spv-reporting"}
        />
      </div>

      <HubToolBar currentTool={"spv-reporting"} />

      <div
        className="page page-dashboard page-dashboard-platform"
        data-theme="seaLight"
      >
        <div className="page_inner">
          <div className="page__head">
            <h1 className="page__head-title">REPORTL, SPV Reporting</h1>
          </div>

          <SPVReporting
            fundsList={fundsList}
            documentsList={documentsList}
            projectsList={projectsList}
            indicatorsProjectsList={indicatorsProjectsList}
            setUpdate={setUpdate}
          />
        </div>
      </div>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(HubDashboard);

import React from "react";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

interface ProjectIndicatorsTagProps {
  slug: any,
  indicator: any;
  value: any;
  options: any;
  handleChange: any;
  index?: number
}

const ProjectIndicatorsTag = ({ slug, indicator, options, value, handleChange, index }: ProjectIndicatorsTagProps) => {
  return (
    <div className={`tag`}>
      <p className="label">{indicator.name}</p>
      <Autocomplete
        multiple
        id={`autocomplete-indicator-tags-${slug}`}
        options={options}
        getOptionLabel={(option: any) => !!options ? option.name : ''}
        getOptionSelected={(option: any) => {
          return !!value.find((v: any) => v.name === option.name);
        }}
        value={value}
        onChange={(event: any, newValue: any, action: any, select: any) => {

          let tmpValue: any = [];
          switch(action){
            case 'select-option':
              tmpValue = [
                ...value,
                ...newValue.filter((option: any) => value.findIndex((v: any) => v.name === option.name) === -1)
              ];
            break;
            case 'remove-option':
              tmpValue = value.filter((v: any) => v.name !== select.option.name);
            break;
          }

// console.log(action);
// console.log(value);
// console.log(select);

          // if(!!value.find((v: any) => v.name === select.option)){
          //   value = value.filter((v: any) => v.name !== select.option)
          // }else{
          //   value.push(select.option)
          // }

          // const tmpValue: any = [];
          // newValue.forEach((nv: any) => {
          //   if(!tmpValue.find((tv: any) => tv.name === nv.name)){
          //     tmpValue
          //   }
          // })

          handleChange(slug, tmpValue, indicator.id)
        }}
        renderInput={(params: any) => (
          <TextField {...params} variant="outlined" label="" placeholder="Policies claimed" />
        )}
        classes={{
          'root': `MuiAutocomplete-root-list`,
          'popper': `MuiAutocomplete-popper-list`,
      }}
      />
    </div>
  );
};

export default ProjectIndicatorsTag;

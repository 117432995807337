import React from "react";
import { Link } from "react-router-dom";
import "./styles.scss";

import { slugify } from "modules/shared/utils/string-utils";

interface MenuProps {
  name?: string;
  menuItems?: MenuItems[];
  current?: string;
}

export interface MenuItems {
  label?: string;
  icon?: string;
  link?: string;
  typeLink?: string;
  // check?: (privilege: string, user: any) => {};
}

const MenuUser = ({ menuItems, current }: MenuProps) => (
  <>
    {!!menuItems &&
      menuItems.map((item: any, i) => {
        if (item.label === "Account") {
          return (
            <li
              className={`item-${
                !!item && !!item.label ? slugify(item.label) : ""
              }`}
              key={`menuItem_${i}`}
            >
              <a className={`link`} href={item.link}>
                <span className="link-icon">
                  <i className={item.icon}></i>
                </span>
                <span className="link-label">{item.label}</span>
              </a>
            </li>
          );
        } else {
          return (
            <li
              className={`item-${
                !!item && !!item.label ? slugify(item.label) : ""
              }`}
              key={`menuItem_${i}`}
            >
              {"route" === item.typeLink && !!item.link ? (
                <Link className={`link`} to={item.link}>
                  <span className="link-icon">
                    <i className={item.icon}></i>
                  </span>
                  <span className="link-label">{item.label}</span>
                </Link>
              ) : (
                <>
                  {"modale" === item.typeLink ? (
                    // item && item.check() ? (
                    <button className="link" data-uk-toggle={item.link}>
                      <span className="link-icon">
                        <i className={item.icon}></i>
                      </span>
                      <span className="link-label">{item.label}</span>
                    </button>
                  ) : (
                    // ) : null
                    <a className="link" href={item.link}>
                      <span className="link-icon">
                        <i className={item.icon}></i>
                      </span>
                      <span className="link-label">{item.label}</span>
                    </a>
                  )}
                </>
              )}
            </li>
          );
        }
      })}
  </>
);
export default MenuUser;

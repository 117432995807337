export interface ProjectIndicatorInterface {
  id?: number | null;
  state: string; //processing,launched
  dueDate: string;
  constructionText: string;
  expectedFcDateText: string;
  projectRatingDisplay: boolean;
  projectRatingText: string;
  boardMembersDisplay: boolean;
  boardMembersText: string;
  adscrDisplay: boolean;
  adscrText: string;
  availabilityDataDisplay: boolean;
  availabilityDataText: string;
  operatingTurnoverDisplay: boolean;
  operatingTurnoverText: string;
  operatingExpensesDisplay: boolean;
  operatingExpensesText: string;
  totalAssetsDisplay: boolean;
  totalAssetsText: string;
  // revenueDisplay: boolean;
  // revenueText: string;
  // ebitdaDisplay: boolean;
  // ebitdaText: string;
  lastUpdate?: String;
  user?: any;
}

export interface ProjectIndicatorFormInterface {
  id?: number | null;
  projectCode: string;
  projectStage: string;
  expectedFcDate: {
    date: string;
    isValidate: boolean
  };
  construction: {
    progress: number,
    contractualDate: string,
    expectedDate: string,
    isValidate: boolean
  };
  projectRating: {
    isReady: boolean,
    moodyRating: string,
    spRating: string,
    fitchRating: string,
    dbrsRating: string,
    otherRating: string,
    isValidate: boolean
  },
  boardMembers: {
    total: number,
    members: any,
    isValidate: boolean
  },
  adscr: {
    backward: string,
    forward: string
  },
  availabilityData: {
    isRevenuesBasedOnPayments: string,
    baseRevenue: number,
    deductions: number,
    deductionsOmContractor: number,
    deductionsConstructionContractor: number,
    otherDeductions: number,
    finalRevenue: number,
    isValidate: boolean
  },
  operatingTurnover: {
    lastFinancialYear: number,
    isValidate: boolean
  },
  // revenue: {
  //   lastFinancialYear: number,
  //   isValidate: boolean
  // },
  operatingExpenses: {
    earnings: number,
    isValidate: boolean
  },
  totalAssets: {
    earnings: number,
    isValidate: boolean
  },
  // ebitda: {
  //   earnings: number,
  //   margin: number,
  //   isValidate: boolean
  // },
  insuranceData: {
    switchClaims: boolean,
    policyClaimed: string,
    policyDocuments: any,
    policies: any,
    isValidate: boolean
  },
  insuranceDataDoCoverage: {
    name: string,
    cost: any,
    insurer: string,
    limit: any,
    renewalDate: any,
    isValidate: boolean
  }
}

export const ProjectIndicatorsSection = [
  {
    id: "project-stage",
    slug: "projectStage",
    title: "What stage is the project in?",
    indicators: [
      {
        id: "projectStage",
        type: "select",
        selectValues: [
          { id: "underDevelopment", name: "Under development" },
          { id: "inConstruction", name: "In construction" },
          { id: "inOperation", name: "In operation" },
        ],
      },
    ],
  },
  {
    id: "construction",
    slug: "construction",
    title: "Construction",
    displayAvailable: false,
    labelFirstPart: "Available indicators for this section",
    labelSecondPart: "About these indicators",
    indicators: [
      {
        id: "progress",
        name: "Construction Progress",
        type: "text unit",
        textPlaceholder: "e.g. 10",
        config: {type: "number", min: 0, max: 100},
        unit: "%",
      },
      {
        id: "contractualDate",
        name: "Contractual Completion Date",
        type: "datepicker",
      },
      {
        id: "expectedDate",
        name: "Expected Completion Date",
        type: "datepicker",
      },
    ],
  },
  {
    id: "expected-fc-date",
    slug: "expectedFcDate",
    title: "Expected FC Date",
    displayAvailable: false,
    labelFirstPart: "Available indicators for this section",
    labelSecondPart: "About these indicators",
    indicators: [
      {
        id: "date",
        name: "Expected Financial Close Date",
        type: "datepicker",
      },
    ],
  },
  {
    id: "project-rating",
    slug: "projectRating",
    title: "Project Rating",
    displayAvailable: true,
    labelFirstPart: "Available indicators for this section",
    labelSecondPart: "About these indicators",
    subQuestion: "Are the project rated by a rating agency?",
    subQuestionField: "isReady",
    indicators: [
      {
        id: "moodyRating",
        name: "Moody's rating",
        type: "select",
        reset: true,
        selectValues: [
          { id: "Aaa", name: "Aaa" },
          { id: "Aa1", name: "Aa1" },
          { id: "Aa2", name: "Aa2" },
          { id: "Aa3", name: "Aa3" },
          { id: "A1", name: "A1" },
          { id: "A2", name: "A2" },
          { id: "A3", name: "A3" },
          { id: "Baa1", name: "Baa1" },
          { id: "Baa2", name: "Baa2" },
          { id: "Baa3", name: "Baa3" },
          { id: "Ba1", name: "Ba1" },
          { id: "Ba2", name: "Ba2" },
          { id: "Ba3", name: "Ba3" },
          { id: "B1", name: "B1" },
          { id: "B2", name: "B2" },
          { id: "B3", name: "B3" },
          { id: "Caa1", name: "Caa1" },
          { id: "Caa2", name: "Caa2" },
          { id: "Caa3", name: "Caa3" },
          { id: "Ca", name: "Ca" },
          { id: "C", name: "C" },
        ],
      },
      {
        id: "spRating",
        name: "S&P rating",
        type: "select",
        reset: true,
        selectValues: [
          { id: "AAA", name: "AAA" },
          { id: "AA+", name: "AA+" },
          { id: "AA", name: "AA" },
          { id: "AA-", name: "AA-" },
          { id: "A+", name: "A+" },
          { id: "A", name: "A" },
          { id: "A-", name: "A-" },
          { id: "BBB+", name: "BBB+" },
          { id: "BBB", name: "BBB" },
          { id: "BBB-", name: "BBB-" },
          { id: "BB+", name: "BB+" },
          { id: "BB", name: "BB" },
          { id: "BB-", name: "BB-" },
          { id: "B+", name: "B+" },
          { id: "B", name: "B" },
          { id: "B-", name: "B-" },
          { id: "CCC+", name: "CCC+" },
          { id: "CCC", name: "CCC" },
          { id: "CCC-", name: "CCC-" },
          { id: "CC", name: "CC" },
          { id: "C", name: "C" },
          { id: "D", name: "D" },
        ],
      },
      {
        id: "fitchRating",
        name: "FITCH rating",
        type: "select",
        reset: true,
        selectValues: [
          { id: "AAA", name: "AAA" },
          { id: "AA+", name: "AA+" },
          { id: "AA", name: "AA" },
          { id: "AA-", name: "AA-" },
          { id: "A+", name: "A+" },
          { id: "A", name: "A" },
          { id: "A-", name: "A-" },
          { id: "BBB+", name: "BBB+" },
          { id: "BBB", name: "BBB" },
          { id: "BBB-", name: "BBB-" },
          { id: "BB+", name: "BB+" },
          { id: "BB", name: "BB" },
          { id: "BB-", name: "BB-" },
          { id: "B+", name: "B+" },
          { id: "B", name: "B" },
          { id: "B-", name: "B-" },
          { id: "CCC", name: "CCC" },
          { id: "CC", name: "CC" },
          { id: "C", name: "C" },
          { id: "D", name: "D" },
        ],
      },
      {
        id: "dbrsRating",
        name: "DBRS rating",
        type: "select",
        reset: true,
        selectValues: [
          { id: "AAA", name: "AAA" },
          { id: "AA (High)", name: "AA (High)" },
          { id: "AA", name: "AA" },
          { id: "AA (Low)", name: "AA (Low)" },
          { id: "A (High)", name: "A (High)" },
          { id: "A", name: "A" },
          { id: "A (Low)", name: "A (Low)" },
          { id: "BBB (High)", name: "BBB (High)" },
          { id: "BBB", name: "BBB" },
          { id: "BBB (Low)", name: "BBB (Low)" },
          { id: "BB (High)", name: "BB (High)" },
          { id: "BB", name: "BB" },
          { id: "BB (Low)", name: "BB (Low)" },
          { id: "B (High)", name: "B (High)" },
          { id: "B", name: "B" },
          { id: "B (Low)", name: "B (Low)" },
          { id: "CCC (High)", name: "CCC (High)" },
          { id: "CCC", name: "CCC" },
          { id: "CCC (Low)", name: "CCC (Low)" },
          { id: "CC", name: "CC" },
          { id: "C", name: "C" },
          { id: "D", name: "D" },
        ],
      },
      {
        id: "otherRating",
        name: "Other agencies rating",
        type: "text",
        config: {type: "text"},
        textPlaceholder: "Agency name and rating (separate by comma)",
      },
    ],
  },
  {
    id: "board-members",
    slug: "boardMembers",
    title: "Board Members",
    displayAvailable: true,
    labelFirstPart: "Available indicators for one member",
    labelSecondPart: "About these indicators",
    indicators: [
      {
        id: "total",
        name: "Please indicate the total number of board members",
        type: "text",
        config: {type: "number", min: 0},
        textPlaceholder: "e.g 10"
      }
    ],
    additionalIndicators: {
      member: [
        {
          id: "firstname",
          name: "First Name",
          type: "text",
          textPlaceholder: "e.g. John",
        },
        {
          id: "lastname",
          name: "Last Name",
          type: "text",
          textPlaceholder: "e.g. Doe",
        },
        {
          id: "title",
          name: "Title",
          type: "select",
          selectValues: [
            { id: "Non-Meridiam", name: "Non-Meridiam" },
            { id: "Meridiam Representative", name: "Meridiam Representative" },
            { id: "Independent", name: "Independent" }
          ],
        },
        {
          id: "category",
          name: "Category",
          type: "select",
          selectValues: [
            { id: "Director", name: "Director" },
            { id: "Alternate", name: "Alternate" },
            { id: "Chairman", name: "Chairman" },
            { id: "Non-voting chairman", name: "Non-voting chairman" }
          ],
        },
        {
          id: "organisation",
          name: "Organisation",
          type: "text",
          textPlaceholder: "e.g. Organisation name",
          conditional: {
            field: 'members.title.id',
            value: 'Non-Meridiam'
          }
        },
        {
          id: "appointmentDate",
          name: "Appointment Date",
          type: "datepicker",
        },
        {
          id: "terminationDate",
          name: "Termination Date",
          type: "datepicker",
        }
      ]
    }
  },
  {
    id: "adscr",
    slug: "adscr",
    title: "ADSCR",
    displayAvailable: true,
    labelFirstPart: "Available indicators for one member",
    labelSecondPart: "About these indicators",
    subQuestion: "Does the project currently have Debt Service Cover Ratios (DSCRs)",
    subQuestionField: "hasDscrs",
    indicators: [
      {
        id: "backward",
        name: "ADSCR - Backward",
        type: "text",
        textPlaceholder: "Number",
        config: {type: "number", min: 0},
      },
      {
        id: "forward",
        name: "ADSCR - Forward",
        type: "text",
        textPlaceholder: "Number",
        config: {type: "number", min: 0},
      },
    ],
  },
  {
    id: "availability-data",
    slug: "availabilityData",
    title: "Availability Data",
    displayAvailable: true,
    labelFirstPart: "Available indicators for this section",
    labelSecondPart: "About these indicators",
    subQuestion: "Are the project revenues based on availability payments?",
    subQuestionField: "isRevenuesBasedOnPayments",
    indicators: [
      {
        id: "baseRevenue",
        name: "Base case availability revenue",
        type: "text",
        textPlaceholder: "",
        config: {type: "number"},
      },
      {
        id: "deductions",
        name: "Deductions born by the SPV",
        type: "text",
        textPlaceholder: "",
        config: {type: "number"},
      },
      {
        id: "deductionsOmContractor",
        name: "Deductions borne by O&M contractor",
        type: "text",
        textPlaceholder: "",
        config: {type: "number"},
      },
      {
        id: "deductionsConstructionContractor",
        name: "Deductions borne by construction contractor",
        type: "text",
        textPlaceholder: "",
        config: {type: "number"},
      },
      {
        id: "otherDeductions",
        name: "Any other deductions",
        type: "text",
        textPlaceholder: "",
        config: {type: "number"},
      },
      {
        id: "finalRevenue",
        name: "Final availability revenue",
        type: "text unit",
        config: {type: "number"},
        textPlaceholder: "XXX",
        unit: (currency: string) => {
          return currencyByCountry[currency];
        }
      },
    ],
  },
  {
    id: "operating-turnover",
    slug: "operatingTurnover",
    title: "Operating Turnover",
    displayAvailable: true,
    labelFirstPart: "Available indicators for this section",
    labelSecondPart: "About these indicators",
    indicators: [
      {
        id: "lastFinancialYear",
        name: "Operating Turnover for last financial year",
        type: "text unit",
        config: {type: "number"},
        textPlaceholder: "e.g. XXX XXX XXX",
        unit: (currency: string) => {
          return currencyByCountry[currency];
        }
      },
    ],
  },
  // {
  //   id: "revenue",
  //   slug: "revenue",
  //   title: "Revenue",
  //   displayAvailable: true,
  //   labelFirstPart: "Available indicators for this section",
  //   labelSecondPart: "About these indicators",
  //   indicators: [
  //     {
  //       id: "lastFinancialYear",
  //       name: "Revenue for last financial year",
  //       type: "text unit",
  //       config: {type: "number"},
  //       textPlaceholder: "e.g. XXX XXX XXX",
  //       unit: (currency: string) => {
  //         return currencyByCountry[currency];
  //       }
  //     },
  //   ],
  // },
  {
    id: "operating-expenses",
    slug: "operatingExpenses",
    title: "Operating Expenses",
    displayAvailable: true,
    labelFirstPart: "Available indicators for this section",
    labelSecondPart: "About these indicators",
    indicators: [
      {
        id: "earnings",
        name: "Operating Expenses",
        type: "text unit",
        config: {type: "number"},
        textPlaceholder: "e.g. XXX XXX XXX",
        unit: (currency: string) => {
          return currencyByCountry[currency];
        }
      }
    ],
  },
  {
    id: "total-assets",
    slug: "totalAssets",
    title: "Total Assets",
    displayAvailable: true,
    labelFirstPart: "Available indicators for this section",
    labelSecondPart: "About these indicators",
    indicators: [
      {
        id: "earnings",
        name: "Total Assets",
        type: "text unit",
        config: {type: "number"},
        textPlaceholder: "e.g. XXX XXX XXX",
        unit: (currency: string) => {
          return currencyByCountry[currency];
        }
      }
    ],
  },
  // {
  //   id: "ebitda",
  //   slug: "ebitda",
  //   title: "EBITDA",
  //   displayAvailable: true,
  //   labelFirstPart: "Available indicators for this section",
  //   labelSecondPart: "About these indicators",
  //   indicators: [
  //     {
  //       id: "earnings",
  //       name: "EBITDA",
  //       type: "text unit",
  //       config: {type: "number"},
  //       textPlaceholder: "e.g. XXX XXX XXX",
  //       unit: (currency: string) => {
  //         return currencyByCountry[currency];
  //       }
  //     },
  //     {
  //       id: "margin",
  //       name: "EBITDA Margin",
  //       type: "text unit",
  //       config: {type: "number", min: 0, max: 100},
  //       textPlaceholder: "XXX",
  //       unit: "%",
  //     },
  //   ],
  // },
  {
    id: "insurance-data",
    slug: "insuranceData",
    title: "Insurance Data",
    displayAvailable: true,
    labelFirstPart: "Available indicators for this section",
    labelSecondPart: "About these indicators",
    subQuestion: "Does the project have insurance coverage in place ?",
    subQuestionField: "hasCoverage",
    // labelSecondPart: "Please add a line for each policy - where a policy has multiple insurers please add lines for each insurer and indicate the percentage ",
    indicators: [
      {
        id: "switchClaims",
        name: "Switch claims",
        type: "switch",
        textPlaceholder: "Has there been any claims made on any of the above insurances in the last 12 months ?",
        isGroup: true,
        children: [
          {
            id: "policyClaimed",
            name: "What policy has been claimed against ?",
            type: "tag",
            textPlaceholder: "",
            options: "policies",
            conditional: {
              field: 'switchClaims',
              value: 'yes'
            }
          },{
            id: "policyDocuments",
            name: 'Please upload details of the claim (including actual or expected costs)',
            type: 'upload',
            template: 'Claims Template.xlsx',
            conditional: {
              field: 'switchClaims',
              value: 'yes'
            }
          }
        ]
      },
      {
        id: "switchDoCoverage",
        name: "Switch DO Coverage",
        type: "switch",
        textPlaceholder: "Does the project have D&O Coverage ?",
        isGroup: true,
        children: [
          {
            id: "name",
            name: "Policy Number",
            type: "text",
            textPlaceholder: "",
            conditional: {
              field: 'switchDoCoverage',
              value: 'yes'
            }
          },
          {
            id: "cost",
            name: "Cost",
            type: "text unit",
            config: {type: "number"},
            textPlaceholder: "e.g. XXX XXX XXX",
            unit: (currency: string) => {
              return currencyByCountry[currency];
            },
            conditional: {
              field: 'switchDoCoverage',
              value: 'yes'
            }
          },
          {
            id: "insurer",
            name: "Insurer name",
            type: "text",
            textPlaceholder: "",
            conditional: {
              field: 'switchDoCoverage',
              value: 'yes'
            }
          },
          {
            id: "limit",
            name: "limit",
            type: "text unit",
            config: {type: "number"},
            textPlaceholder: "e.g. XXX XXX XXX",
            unit: (currency: string) => {
              return currencyByCountry[currency];
            },
            conditional: {
              field: 'switchDoCoverage',
              value: 'yes'
            }
          },
          {
            id: "renewalDate",
            name: "Renewal Date",
            type: "datepicker",
            textPlaceholder: "",
            conditional: {
              field: 'switchDoCoverage',
              value: 'yes'
            }
          }
        ]
      } 
      // {
      //   id: "policyDocuments",
      //   name: "Upload",
      //   type: "upload",
      //   textPlaceholder: "Please upload details of the claim (including actual or expected costs)"
      // }
    ],
    additionalIndicators: {
      policy: [
        {
          id: "name",
          name: "Policy Name",
          type: "text",
          textPlaceholder: "e.g. Business Interuption, Third Party Liability etc"
        },
        {
          id: "cost",
          name: "Cost",
          type: "text unit",
          config: {type: "number"},
          textPlaceholder: "e.g. XXX XXX XXX",
          unit: (currency: string) => {
            return currencyByCountry[currency];
          }
        },
        {
          id: "insurer",
          name: "Insurer name",
          type: "text",
          textPlaceholder: ""
        },
        {
          id: "coveringPercentage",
          name: "% of policy covered by the insurer",
          type: "text unit",
          config: {type: "number", min: 0, max: 100},
          textPlaceholder: "XXX",
          unit: "%",
        },
        {
          id: "renewalDate",
          name: "Renewal Date",
          type: "datepicker",
          textPlaceholder: ""
        }
      ],
      // documents: {
      //     id: "policyDocuments",
      //     name: 'Please upload details of the claim (including actual or expected costs)',
      //     type: 'upload',
      //     conditional: {
      //       field: 'switchClaims',
      //       value: 'yes'
      //     }
      //   }
    }
  },
  // {
  //   id: "insurance-data-do-coverage",
  //   slug: "insuranceDataDoCoverage",
  //   title: "Insurance Data",
  //   displayAvailable: true,
  //   labelFirstPart: "Available indicators for this section",
  //   labelSecondPart: "About these indicators",
  //   subQuestion: "Does the project have D&O coverage ?",
  //   subQuestionField: "hasCoverage",
  //   // labelSecondPart: "Please add a line for each policy - where a policy has multiple insurers please add lines for each insurer and indicate the percentage ",
  //   indicators: [
  //     {
  //       id: "name",
  //       name: "Policy Number",
  //       type: "text",
  //       textPlaceholder: "e.g. "
  //     },
  //     {
  //       id: "cost",
  //       name: "Cost",
  //       type: "text unit",
  //       config: {type: "number"},
  //       textPlaceholder: "e.g. XXX XXX XXX",
  //       unit: (currency: string) => {
  //         return currencyByCountry[currency];
  //       }
  //     },
  //     {
  //       id: "insurer",
  //       name: "Insurer name",
  //       type: "text",
  //       textPlaceholder: ""
  //     },
  //     {
  //       id: "limit",
  //       name: "Limit",
  //       type: "text unit",
  //       config: {type: "number", min: 0, max: 100},
  //       textPlaceholder: "XXX",
  //       unit: "%",
  //     },
  //     {
  //       id: "renewalDate",
  //       name: "Renewal Date",
  //       type: "datepicker",
  //       textPlaceholder: ""
  //     }
  //   ]
  // }
];

export const defaultMemberData: any = {
  firstname: '',
  lastname: '',
  title: '',
  category: '',
  appointmentDate: '',
  terminationDate: ''
};

export const defaultPolicyData: any = {
  name: '',
  cost: '',
  insurer: '',
  coveringPercentage: '',
  renewalDate: ''
};

export const currencyByCountry: any = {
  EGP: "&#163; (EGP)",
  USD: "&#36; (USD)",
  THB: "&#3647; (THB)",
  KES: "(KES)",
  ISK: "&#107;&#114; (ISK)",
  HKD: "&#36; (HKD)",
  TRY: "(TRY)",
  ILS: "&#8362; (ILS)",
  AFN: "&#1547; (AFN)",
  CNY: "&#165; (CNY)",
  MRU: "(MRU)",
  NOK: "&#107;&#114; (NOK)",
  AUD: "&#36; (AUD)",
  INR: "&#8377; (INR)",
  HUF: "&#70;&#116; (HUF)",
  CHF: "&#67;&#72;&#70; (CHF)",
  MAD: "(MAD)",
  MXN: "&#36; (MXN)",
  RUB: "&#1088;&#1091;&#1073; (RUB)",
  CAD: "&#36; (CAD)",
  NGN: "&#8358; (NGN)",
  KRW: "&#8361; (KRW)",
  TWD: "&#78;&#84;&#36; (TWD)",
  EUR: "&#8364; (EUR)",
  ZAR: "&#82; (ZAR)",
  NZD: "&#36; (NZD)",
  SAR: "&#65020; (SAR)",
  PLN: "&#122;&#322; (PLN)",
  ZMW: "(ZMW)",
  BHD: "(BHD)",
  SGD: "&#36; (SGD)",
  XOF: "(XOF)",
  QAR: "&#65020; (QAR)",
  JPY: "&#165; (JPY)",
  LEV: "(LEV)",
  IQD: "(IQD)",
  GBP: "&#163; (GBP)",
  ARS: "&#36; (ARS)",
  TND: "(TND)",
  CZK: "&#75;&#269; (CZK)",
  MGA: "(MGA)",
  DKK: "&#107;&#114; (DKK)",
  BRL: "&#82;&#36; (BRL)",
  AED: "(AED)",
  CLP: "&#36; (CLP)",
  LBP: "&#163; (LBP)",
  KYD: '&#36; (KYD)'
}
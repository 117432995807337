import React, { useEffect, useState } from "react";
import typeEnumImport from "../../../datas/typeEnum.json";
import { ProjectTypeEnumInterface } from "models/project-type-enum";
import { EsgFilter } from "models/esg-contribution.model";
import config from "modules/config";

type SdgContributionFilterSectionProps = {
  currentSdg: number;
  setComparaisonLevel: any;
  comparaisonLevel: string;
  filterProjectBy: string;
  resetFilter: any;
  filterProjectByValue: string;
  esgFilter: EsgFilter;
  filterBy: any;
  filterProjectState: string;
  currentSearch: string;
  setCurrentSearch: any;
  setFilterProjectState: any;
  currentSdgContribution: any;
  currentIndicator: string;
  currentTarget: string;
  selectProject: any;
};

const SdgContributionFilterSection: React.FC<SdgContributionFilterSectionProps> = ({
  currentSdg,
  comparaisonLevel,
  setComparaisonLevel,
  filterProjectBy,
  resetFilter,
  filterProjectByValue,
  esgFilter,
  filterBy,
  filterProjectState,
  currentSearch,
  setCurrentSearch,
  setFilterProjectState,
  currentSdgContribution,
  currentIndicator,
  currentTarget,
  selectProject,
}) => {
  const typeEnum: { [key: string]: ProjectTypeEnumInterface } = typeEnumImport;

  const [graphValues, setGraphValues] = useState<string[]>([]);
  const [typeList, setTypeList] = useState<any>([]);
  const [fundList, setFundList] = useState<any>([]);
  const [stageList, setStageList] = useState<any>([]);
  
  useEffect(() => {
    if (!!currentSdgContribution.projectsDatas && comparaisonLevel === "project") {
      let newTypeList: any = [];
      let newFundList: any = [];
      let newGraphValues:any = [];
      let newStageList:any = [];

      currentSdgContribution.projectsDatas.forEach((pD: any) => {
        if (pD) {
          if ("" !== currentIndicator) {
            if (
              !!pD.sdgs[currentSdg] &&
              !!pD.sdgs[currentSdg][currentTarget] &&
              !!pD.sdgs[currentSdg][currentTarget]["datas"][
                currentIndicator
              ] &&
              pD.sdgs[currentSdg][currentTarget]["datas"][currentIndicator]
                .questionNote !== null
            ) {
              newGraphValues.push(pD.projectName);

              if(!newTypeList.includes(pD.projectType)){
                newTypeList.push(pD.projectType);
              }
              if(!newTypeList.includes(pD.typeCategory)){
                newTypeList.push(pD.typeCategory);
              }

              pD.meridiamFundCode.length > 0 && pD.meridiamFundCode.forEach((mfc:any) => {
                if(!newFundList.includes(mfc)){
                  newFundList.push(mfc);
                }
              });

              if(!newStageList.includes(pD.stage)){
                newStageList.push(pD.stage);
              }
            }
          } else if ("" !== currentTarget) {
            if (
              !!pD.sdgs[currentSdg] &&
              !!pD.sdgs[currentSdg][currentTarget] 
              &&
              pD.sdgs[currentSdg][currentTarget].total !== null
            ) {
              newGraphValues.push(pD.projectName);

              if(!newTypeList.includes(pD.projectType)){
                newTypeList.push(pD.projectType);
              }
              if(!newTypeList.includes(pD.typeCategory)){
                newTypeList.push(pD.typeCategory);
              }

              pD.meridiamFundCode.length > 0 && pD.meridiamFundCode.forEach((mfc:any) => {
                if(!newFundList.includes(mfc)){
                  newFundList.push(mfc);
                }
              });

              if(!newStageList.includes(pD.stage)){
                newStageList.push(pD.stage);
              }
            }
          } else {
            if (
              !!pD.sdgs[currentSdg] &&
              pD.sdgs[currentSdg].total !== null
            ) {
              newGraphValues.push(pD.projectName);

              if(!newTypeList.includes(pD.projectType)){
                newTypeList.push(pD.projectType);
              }
              if(!newTypeList.includes(pD.typeCategory)){
                newTypeList.push(pD.typeCategory);
              }

              pD.meridiamFundCode.length > 0 && pD.meridiamFundCode.forEach((mfc:any) => {
                if(!newFundList.includes(mfc)){
                  newFundList.push(mfc);
                }
              });

              if(!newStageList.includes(pD.stage)){
                newStageList.push(pD.stage);
              }
            }
          }
        }
      });

      setGraphValues(newGraphValues);
      setTypeList(newTypeList);
      setFundList(newFundList);
      setStageList(newStageList);
    }
  }, [currentSdgContribution.projectsDatas, currentSdg, currentTarget, currentIndicator]);

  const searchInProjectList = (search: string) => {
    setCurrentSearch(search);
  };

  const doesFilterShouldBeDisabled = (
    projectSeachType: string,
    projectSeachValue: string
  ) => {
    switch(projectSeachType){
      case "type":
        return !typeList.includes(projectSeachValue);
      break;
      case "fund":
        return !fundList.includes(projectSeachValue);
      break;
      case "stage":
        return !stageList.includes(projectSeachValue);
      break;
    }
  };

  const highlightProject = (val:string, currentSearch:string) => {
    if(currentSearch !== "" && val.indexOf(currentSearch) !== -1){
      let result = val.split(currentSearch);
      return result[0]
      + "<strong class='hightlight'>" + currentSearch + "</strong>"
      + result[1];
    }
    return val;
  }

  const updateComparaisonLevel = (newLevel:string) => {
    setComparaisonLevel(newLevel);
    setCurrentSearch('');
  }

  return (
    <>
    <div id="cmpPdfLegends" className="col">
      {currentSdg !== 0 && (
        <div className="row row-filters">
          <div className="col col1" data-print="yes">
            <div className="group group-filters">
              <div className="label" data-print="no">
                Choose a level
              </div>
              <ul className="list list-filters list-filters-inline main">
                <li>
                  <div className="btn-radio inline">
                    <input
                      type="radio"
                      id="levelProject"
                      name="compLevel"
                      value="project"
                      checked={comparaisonLevel === "project"}
                      onChange={() => updateComparaisonLevel("project")}
                    />
                    <label
                      className="radio-like"
                      htmlFor="levelProject"
                    ></label>
                    <label className="radio-label" htmlFor="levelProject">
                      <span>Project</span>
                    </label>
                  </div>
                </li>
                <li>
                  <div className="btn-radio inline">
                    <input
                      type="radio"
                      id="levelType"
                      name="compLevel"
                      value="type"
                      checked={comparaisonLevel === "type"}
                      onChange={() => updateComparaisonLevel("type")}
                    />
                    <label className="radio-like" htmlFor="levelType"></label>
                    <label className="radio-label" htmlFor="levelType">
                      <span>Type</span>
                    </label>
                  </div>
                </li>
                <li>
                  <div className="btn-radio inline">
                    <input
                      type="radio"
                      id="levelFund"
                      name="compLevel"
                      value="fund"
                      checked={comparaisonLevel === "fund"}
                      onChange={() => updateComparaisonLevel("fund")}
                    />
                    <label className="radio-like" htmlFor="levelFund"></label>
                    <label className="radio-label" htmlFor="levelFund">
                      <span>Fund</span>
                    </label>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          {comparaisonLevel === "project" && (
            <>
              {/* TYPE / FUND SELECTION */}
              <div className="col col2" data-print="no">
                <div className="group group-filters">
                  <div className="label">
                    Filter by Type o Fund
                    {filterProjectBy !== "" && (
                      <div>
                        <span
                          className="btn btn-reset"
                          onClick={() => resetFilter("typeCategory")}
                        >
                          <i className="far fa-times"></i>
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="selector">
                    <div className="select">
                      <button
                        className="btn btn-select btn-primary full btn-rounded"
                        type="button"
                      >
                        {filterProjectBy === "" ? (
                          <span className="btn_label">
                            Choose a type o fund
                          </span>
                        ) : filterProjectBy === "type" ? (
                          <span className="btn_label">
                            {typeEnum[filterProjectByValue].niceName}
                          </span>
                        ) : (
                          <span className="btn_label">
                            {filterProjectByValue}
                          </span>
                        )}
                        <span className="btn_icon"></span>
                      </button>

                      <div
                        id="boxFilterTypeFund"
                        className="drop drop-select with-group large"
                        data-uk-dropdown="mode: click; pos: top-left;"
                      >
                        <ul className="group lvl0">
                          <li className="subgroup group-main">
                            <span className="label-group">Type</span>

                            <ul className="group lvl1">
                              {!!esgFilter && !!esgFilter.type && Object.entries(esgFilter.type).map(([key, value]: any, idx: number) => {
                                return (
                                  <li key={`keyIdxCategory-${idx}`}>
                                    <span className={`item clickable ${doesFilterShouldBeDisabled("type", key) ? "disabled" : ""}`}
                                      // data-uk-toggle="target: #boxFilterTypeFund;"
                                      onClick={() => filterBy("typeCategory", key)}
                                    >
                                      <i className="fas fa-arrow-alt-circle-right"></i>
                                      {!!typeEnum && " " + typeEnum[key].niceName}
                                    </span>

                                    <ul className="group lvl2">
                                      {value.map((val: string, index: number) => (
                                        <li key={`keyIdxItem-${index}`} className={`item ${val === filterProjectByValue ? 'selected' : ''} ${doesFilterShouldBeDisabled("type", val) ? "disabled" : ""}`}
                                          // data-uk-toggle="target: #boxFilterTypeFund;"
                                          onClick={() => filterBy('type', val)}
                                        >
                                          <span className={`icon clickable`}>
                                            {(!!typeEnum && !!typeEnum[val] && typeEnum[val] !== undefined && !!typeEnum[val].slug && 
                                              (
                                                !!typeEnum[val].iconType && "svg" === typeEnum[val].iconType) ?
                                                  <img src={`${config.CdnUrl}/assets/images/sdg/icons/filters/${typeEnum[val].slug}.svg`} alt=" " />
                                                :
                                                  <i className={`fas fa-${typeEnum[val].slug}`}></i>
                                              )
                                            }
                                          </span>
                                          <span className="name clickable">
                                            {typeEnum && typeEnum[val] && typeEnum[val].niceName && typeEnum[val].niceName}
                                          </span>
                                        </li>
                                      )
                                      )}
                                    </ul>
                                  </li>
                                );
                              }
                              )}
                            </ul>
                          </li>

                          <li className="subgroup group-main">
                            <span className="label-group">Fund</span>
                            <ul className="group lvl1">
                              {esgFilter &&
                                esgFilter.fund.map((fund:any) => {
                                  return (
                                    <li
                                      key={`fund-${fund.project_meridiamFund}`}
                                      className={`item ${fund.meridiam_fund_fundId === filterProjectByValue ? "selected" : ""}
                                      ${doesFilterShouldBeDisabled("fund", fund.meridiam_fund_fundId) ? "disabled" : ""}`}
                                      onClick={() => filterBy("fund", fund.meridiam_fund_fundId)}
                                      data-uk-toggle="target: #boxFilterTypeFund;"
                                    >
                                      <span className="clickable">
                                        {fund.project_meridiamFund}
                                      </span>
                                    </li>
                                  );
                                })}
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* PROJECT STATUS */}
              <div className="col col3" data-print="no">
                <div className="group group-filters">
                  <div className="label">
                    Project status
                    {filterProjectState !== "" && (
                      <div>
                        <span
                          className="btn btn-reset"
                          onClick={() => resetFilter("state")}
                        >
                          <i className="far fa-times"></i>
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="selector">
                    <div className="select">
                      <button
                        className="btn btn-select btn-primary full btn-rounded"
                        type="button"
                      >
                        <>
                          {filterProjectState === "" ||
                            filterProjectState === "all" ? (
                            <span className="btn_label">All states</span>
                          ) : (
                            <span className="btn_label">
                              {filterProjectState}
                            </span>
                          )}
                          <span className="btn_icon"></span>
                        </>
                      </button>

                      <div
                        className="drop drop-select"
                        data-uk-dropdown="mode: click; pos: top-left;"
                      >
                        <ul className="group lvl0 uk-nav uk-dropdown-nav">
                          <>
                            <li
                              className={
                                "item" +
                                (filterProjectState === "all"
                                  ? " selected"
                                  : "")
                              }
                              onClick={() => resetFilter("state")}
                            >
                              <span className="clickable">All states</span>
                            </li>
                            {esgFilter.projectState.map((state) => {
                              return (
                                <li
                                  key={`projectState-${state}`}
                                  className={`item ${filterProjectState === state ? " selected" : ""}
                                  ${doesFilterShouldBeDisabled("stage", state) ? "disabled" : ""}`}
                                  onClick={() => setFilterProjectState(state)}
                                >
                                  <span className="clickable">{state}</span>
                                </li>
                              );
                            })}
                          </>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
                
              {/* HIGHLIGHT PROJECT */}
              <div className="col col3" data-print="no">
                <div className="group group-filters">
                  <div className="label">Highlight a Project</div>

                  <div className="row row-input row-search">
                    <div className="input input-wrapp-autocomplete">
                      <input
                        id="searchInGraph"
                        type="search"
                        className={`no-check input-autocomplete ${!!graphValues && !!currentSearch && graphValues.includes(currentSearch) ? 'selected' : ''}`}
                        placeholder="Ex: Project name"
                        autoComplete="off"
                        required
                        onChange={(e) => searchInProjectList(e.target.value)}
                        value={currentSearch}
                      />
                      {0 < graphValues.length && (
                        <div className="wrapp-autocomplete">
                          <div className="inner-autocomplete">
                            <ul className="list list-">
                              {graphValues.map((val, i) => {
                                return (
                                  <li
                                    key={i}
                                    className={`${val === currentSearch ? "selected" : ""}`}
                                    onClick={() => selectProject(val)}
                                  >
                                    <span
                                      className="project-label"
                                      dangerouslySetInnerHTML={{
                                        __html: highlightProject(val, currentSearch),
                                      }}></span>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        </div>
                      )}
                    
                      <span
                        id="resetSearch"
                        className="btn btn-reset"
                        onClick={() => resetFilter("search")}
                      >
                        {currentSearch !== "" && (<i className="far fa-times"></i>)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      )}

      {/* LEGENDS FOR PDF */}
      {!!currentSdgContribution &&
        !!currentSdgContribution.listIndicator &&
        currentIndicator !== "" && (
          <div className="row row-legends" data-print="only">
            <ul className="list list-explanations">
              {!!currentSdgContribution.listIndicator[currentSdg] && 
              !!currentSdgContribution.listIndicator[currentSdg][currentTarget] &&
              !!currentSdgContribution.listIndicator[currentSdg][currentTarget][
                currentIndicator
              ] &&  
              !!currentSdgContribution.listIndicator[currentSdg][currentTarget][
                currentIndicator
              ].levels &&
              currentSdgContribution.listIndicator[currentSdg][currentTarget][
                currentIndicator
              ].levels.map((level: any, idx: number) => {
                return (
                  <li className="level" key={`legendPdf-${idx}`}>
                    {currentSdgContribution.listIndicator[currentSdg][
                      currentTarget
                    ][currentIndicator].unit === "Yes/No" ? (
                      <div className="inner-lever">
                        <span className="level-name">
                          level {idx} <i className="fas fa-chevron-right"></i>
                        </span>
                        <span className="level-explanation">{level}</span>
                      </div>
                    ) : (
                      <div className="inner-range">
                        <span className="level-name">
                          level {idx} <i className="fas fa-chevron-right"></i>
                        </span>
                        {level === "" ? (
                          <span className="level-explanation">
                            {
                              currentSdgContribution.listIndicator[currentSdg][
                                currentTarget
                              ][currentIndicator].direction
                            }
                            {level}
                            {
                              currentSdgContribution.listIndicator[currentSdg][
                                currentTarget
                              ][currentIndicator].unit
                            }
                          </span>
                        ) : (
                          <span className="level-explanation">other</span>
                        )}
                      </div>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
    </>
  );
};

export default SdgContributionFilterSection;

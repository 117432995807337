import React, { useEffect, useRef, useState } from "react";
import typeEnumImport from "../../../platform/datas/typeEnum.json";
import { ProjectTypeEnumInterface } from "models/project-type-enum";
import config from "modules/config";

import CmpMap from "./Map";

import "./styles.scss";
import { getProjectLocation, ProjectLocation } from "../../services/limited-partners.service";

import { CurrFilter } from "../../models/available-filters.model";
import Loader from "modules/shared/components/Loader/Loader";

interface ProjectLocationProps {
    accessToken: string;
    year: number;
    currFilter: CurrFilter;
    investorId: string;
    investorType: string;
}

const ProjectLocationSection = ({ accessToken, year, currFilter, investorId, investorType }: ProjectLocationProps) => {
    const typeEnum: { [key: string]: ProjectTypeEnumInterface } = typeEnumImport;
    const listRef = useRef<HTMLUListElement>(null);

    const [openMobile, setOpenMobile] = useState(false);
    const [isProjectLocationLoading, setIsProjectLocationLoading] = useState(true);
    const [locations, setLocations] = useState<ProjectLocation[]>([]);
    const [selectedLocation, setSelectedLocation] = useState<ProjectLocation | null>(null);

    useEffect(() => {

        setIsProjectLocationLoading(true);
        // allocation by asset/geo
        getProjectLocation(
            accessToken,
            investorId,
            year,
            currFilter.meridiamFund,
            currFilter.location
        )
            .then((result) => {
                // console.log(result);
                setLocations(result.data);
            })
            .catch(function (error) {
                console.log("---- error Project Location ----");
                console.log(error);
            })
            .then(() => {
                setIsProjectLocationLoading(false);
            });
    }, [currFilter])

    return (
        <>
            <div id="cmpProjectLocation" className="tool tool_lp tool_project-location">
                {
                    !!locations ?
                        (
                            <>
                                <button className={`btn btn-mobile ${!!openMobile ? 'open' : ''}`} onClick={() => setOpenMobile(!openMobile)}>
                                    <span className="btn-icon"><i className={`fas fa-chevron-${!!openMobile ? 'right' : 'left'}`}></i></span>
                                </button>
                                <div className="tool_map">
                                    <CmpMap
                                        data={locations}
                                        setSelectedLocation={setSelectedLocation}
                                        selectedLocation={selectedLocation}
                                        listRef={listRef}
                                        setOpenMobile={setOpenMobile}
                                        investorType={investorType}
                                    />
                                </div>
                                <div className={`tool_container ${!!openMobile ? 'open' : ''}`}>
                                    <div className="tool_head">
                                        <h2 className="title">project location</h2>
                                        <h3 className="subtitle">{locations.length} project as of Q4 {year}
                                        {/* {investorType === "gigf" ? (year+1) : year} */}
                                        </h3>
                                    </div>
                                    <div className="tool_list">
                                        <ul ref={listRef}>
                                            {locations.map((item, id) => (
                                                <li
                                                    key={`project-location-${item.projectCode}`}
                                                    data-key={`project-location-${item.projectCode}`}
                                                    className={`${!!selectedLocation && selectedLocation.projectCode === item.projectCode ? 'selected' : ''}`}
                                                    onClick={() => {
                                                        setSelectedLocation((!!selectedLocation && selectedLocation.projectCode === item.projectCode) ? null : item)
                                                        setOpenMobile(false)
                                                    }}
                                                >
                                                    <span className="type">
                                                        {(!!typeEnum && !!typeEnum[item.type] && "svg" === typeEnum[item.type].iconType) ?
                                                            <img src={`${config.CdnUrl}/assets/images/sdg/icons/filters/${typeEnum[item.type].slug}.svg`} alt=" " />
                                                            :
                                                            <i className={`fas fa-${typeEnum[item.type].slug}`}></i>
                                                        }
                                                    </span>
                                                    <div className="content">
                                                        <p className="name">{item.projectName}</p>
                                                        {investorType === "infra" && 
                                                            <span className={`stage ${'In Operation' === item.stage ? 'operation' : ''} ${'Both' === item.stage ? 'both' : ''}`}>{item.stage}</span>
                                                        }
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <div>
                                <Loader className="on" color="white" size="small" />
                            </div>
                        )
                }
            </div>
        </>
    );
};

export default ProjectLocationSection;

import { PlatformConfiguration } from "models/platform-configuration.model";
import { Action } from "models/store.models";

export enum PlatformConfigurationActionTypes {
  SetConfiguration = "@Config/SetConfiguration",
}

export class SetConfiguration implements Action {
  public readonly type = PlatformConfigurationActionTypes.SetConfiguration;
  public constructor(public readonly config: PlatformConfiguration) {}
}

export type ConfigurationActions = SetConfiguration;

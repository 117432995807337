import React from "react";

import "./styles.scss";

const TitlePage: React.FC<{
  content: string;
}> = ({ content }) => {
  return (
    <div className="page__title">
      <span dangerouslySetInnerHTML={{ __html: content }}></span>
    </div>
  );
};

export default TitlePage;

import { useFormik } from "formik";
import { RootState, ThunkDispatch } from "models/store.models";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as yup from "yup";
import { activateAccount } from "../../actions/session-actions";

import "./styles.scss";
import styles from "./styles.module.scss";

import AuthLayout from "../../components/AuthLayout/AuthLayout";
import Loader from "../../../shared/components/Loader/Loader";

const validationSchema = yup.object().shape({
  newPassword: yup.string().label("Password").min(8).required(),
  confirmNewPassword: yup
    .string()
    .oneOf([yup.ref("newPassword")], "Passwords must match"),
});

const mapStateToProps = ({ session }: RootState) => ({
  session,
});

const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  activateAccount: (newPassword: string, newPasswordConfirmation: string) =>
    dispatch(activateAccount(newPassword, newPasswordConfirmation)),
});

type ActivateAccountProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const ActivateAccount: React.FC<ActivateAccountProps> = ({
  activateAccount,
}) => {
  const formik = useFormik({
    initialValues: {
      newPassword: "",
      confirmNewPassword: "",
    },
    onSubmit: (values) => {
      activateAccount(values.newPassword, values.confirmNewPassword);
    },
    validationSchema: validationSchema,
  });

  const [showLoading, setShowLoading] = useState<boolean>(false);
  useEffect(() => {
    let timer = setTimeout(() => setShowLoading(true), 50);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <AuthLayout>
      <Loader
        className={showLoading ? "off" : "on"}
        color="multicolors"
        size="large"
      />
      <div className="page page-auth new-password">
        <div className="page_inner">
          <div className="box box-auth">
            <div className="logo">
              <svg
                className={`${styles["logo"]} svg svg-logo svg-logo-meridiam xl`}
              >
                <use xlinkHref="#logoMeridiam"></use>
              </svg>
            </div>
            <div className="box_inner">
              <div className="box__head">
                <h1>
                  <small>Meridiam</small>
                  <span>SDG &amp; Investing Tools Platform</span>
                </h1>
              </div>
              <div className="box__content">
                <form className="form form-auth" onSubmit={formik.handleSubmit}>
                  <div className="form_inner">
                    <div className="form_head">
                      <p>
                        Enter your new password below.
                        <br />
                        and confirmation password.
                      </p>
                    </div>
                    <div className="form_group">
                      <div className="form-row row row-block">
                        <input
                          autoComplete="current-password"
                          id="newPassword"
                          name="newPassword"
                          type="password"
                          placeholder="New password"
                          onChange={formik.handleChange}
                          value={formik.values.newPassword}
                        />
                        {formik.submitCount > 0 && formik.errors?.newPassword && (
                          <div className="form-error">
                            <span>{formik.errors.newPassword}</span>
                          </div>
                        )}
                      </div>
                      <div className="form-row row row-block">
                        <input
                          autoComplete="current-password"
                          id="confirmNewPassword"
                          name="confirmNewPassword"
                          type="password"
                          placeholder="Confirm new password"
                          onChange={formik.handleChange}
                          value={formik.values.confirmNewPassword}
                        />
                        {formik.submitCount > 0 &&
                          formik.errors?.confirmNewPassword && (
                            <div className="form-error">
                              <span>{formik.errors.confirmNewPassword}</span>
                            </div>
                          )}
                      </div>
                      <div className="form-row row row-block row-submit">
                        <button className="btn btn-cta label-external rounded">
                          <span className="btn_icon icon-primary">
                            <i className="fas fa-chevron-right"></i>
                          </span>
                          <span className="btn_label label-primary">
                            Submit
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivateAccount);

import React from "react";

const { DateTime } = require("luxon");

interface ProjectIndicatorsDatePickerProps {
  slug: any,
  indicator: any;
  value: any;
  handleChange: any;
  index?: number;
}

const ProjectIndicatorsDatePicker = ({
  slug,
  indicator,
  value,
  handleChange,
  index
}: ProjectIndicatorsDatePickerProps) => {

  return (
    <div className="datepicker">
      <p className="label">{indicator.name}</p>
      <div className="datepicker_inner">
        <input
          id={`${slug}${indicator.id}${'undefined' !== typeof index ? `-${index}` : ''}`}
          type="date"
          name={`${slug}${indicator.id}${'undefined' !== typeof index ? `-${index}` : ''}`}
          value={(!!value ? (!!index) ? value[index] : value : '')}
          // onChange={(e) =>
          //   setDateValue(
          //     DateTime.fromISO(e.target.value).toLocaleString(DateTime.DATE_MED)
          //   )
          // }
          onChange={(e) => handleChange(slug, e.target.value, indicator.id)}
        />
        <span className="datepicker_inner-value">
          {!!value ? DateTime.fromISO(value).toLocaleString(DateTime.DATE_MED) : "———"}
        </span>
      </div>
    </div>
  );
};

export default ProjectIndicatorsDatePicker;

import axios from "axios";
import { SdgContribution, EsgFilter } from "models/esg-contribution.model";
import config from "modules/config";

const ApiBaseUrl = config.ApiUrl;

const getSdgContribution = async (
  accessToken: string,
  year: number,
  filterProjectState: string,
  filterType: string,
  filterData: string,
  comparaisonLevel: string
): Promise<SdgContribution> => {
  const result = await axios({
    method: "post",
    url: `${ApiBaseUrl}/calculation/contribution/${year}`,
    headers: { Authorization: "Bearer " + accessToken },
    data: {
      filterType: filterType,
      filterData: filterData,
      filterStageData: filterProjectState,
      aggregationType: comparaisonLevel
    },
  });
  return result.data;
};

const requestSdgContributionFilter = async (
  accessToken: string,
  year: number
): Promise<EsgFilter> => {
  const result = await axios({
    method: "get",
    url: `${ApiBaseUrl}/calculation/sdgContributionFilter/${year}`,
    headers: { Authorization: "Bearer " + accessToken },
  });

  return result.data;
};

export { getSdgContribution, requestSdgContributionFilter };

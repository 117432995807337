import React, { useEffect } from "react";
import { history } from "modules/shared/services/history";
import UIkit from "uikit";
import "../../../../assets/scss/custom/tools/_base.scss";
import "./styles.scss";

type HubToolBarProps = {
  currentYear?: number;
  currentTool: string;
  setCurrentYear?: any;
};

const HubToolBar: React.FC<HubToolBarProps> = ({ currentTool }) => {
  const goTo = (url: string) => {
    history.push(`/hub/${url}`);
  };

  useEffect(() => {
    UIkit.offcanvas(`#toggle-usage-${currentTool}`).hide();
  }, []);

  return (
    <>
      <div className="toolbar hub-toolbar appear-right-full">
        <div className="inner">
          <div className="menu">
            <button
              className="btn btn-burger"
              data-uk-toggle={`target: #toggle-usage-${currentTool}`}
            >
              <span className="btn-label">Menu</span>
              <span className="btn-icon">
                <i className="fas fa-bars"></i>
              </span>
            </button>
          </div>
        </div>
      </div>

      <div
        id={`toggle-usage-${currentTool}`}
        data-uk-offcanvas="overlay: true; container:false;"
      >
        <div className="own-offcanvas uk-offcanvas-bar">
          <div className="inner-offcanvas">
            <button
              className="btn btn-close-burger btn-no-text"
              data-uk-toggle={`target: #toggle-usage-${currentTool}`}
            >
              <span className="btn-icon">
                <i className="fas fa-times"></i>
              </span>
              <span className="btn-label"></span>
            </button>
            <div className="offcanvas-head">
              <span className="brand">Meridiam | </span>
              <span className="survey upp">Platform</span>
            </div>
            <div className="offcanvas-body">
              <p>Navigate to</p>

              <ul className="uk-nav uk-nav-default">
                {/* TODO: mettre la liste des éléments du menu et remplacer "country-data" par l'url vers l'élément */}
                <li
                  className={"country-data" === currentTool ? "current" : ""}
                  onClick={() => goTo("country-data")}
                >
                  <div>
                    <span>Country Data manager</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HubToolBar;

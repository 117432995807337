import React from "react";
import { Link } from "react-router-dom";
import "./styles.scss";

// import { slugify } from "modules/shared/utils/string-utils";

interface MenuProps {
  name?: string;
  menuItems?: MenuItems[];
}

export interface MenuItems {
  label?: string;
  link?: string;
  typeLink?: string;
}

const MenuFooter = ({ menuItems }: MenuProps) => (
  <>
    {!!menuItems &&
      menuItems.map((item: any, i) => (
        <li className={`lvl0`} key={`menuFooterItem_` + i}>
          {"route" === item.typeLink && !!item.link ? (
            <Link className={`link link-primary`} to={item.link}>
              <span className="link-label">{item.label}</span>
            </Link>
          ) : (
            <>
              {"modale" === item.typeLink ? (
                <button
                  className="link link-primary"
                  data-uk-toggle={item.link}
                >
                  <span className="link-label">{item.label}</span>
                </button>
              ) : (
                <a className="link link-primary" href={item.link}>
                  <span className="link-label">{item.label}</span>
                </a>
              )}
            </>
          )}
        </li>
      ))}
  </>
);
export default MenuFooter;

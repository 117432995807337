import Loader from "modules/shared/components/Loader/Loader";
import ChartRadial from "modules/shared/components/Tools/ChartRadial/ChartRadial";
import React, { useState } from "react";
import styles from "../SdgImpactSynthesisSection/styles.module.scss";
import {
  SdgImpactSynthesisInterface,
} from "models/sdg-impact-synthesis.model";
import { sdgNames } from "../../../../shared/utils/sdg-names";
import SisBoxDisplayDatas from "../SisBoxDisplayDatas/SisBoxDisplayDatas";

interface RosaceSectionProps {
  currentSdgId: number;
  extendedPdfMode: any;
  isRosaceLoading: boolean;
  firstSerieSdgResult: SdgImpactSynthesisInterface;
  secondSerieSdgResult: SdgImpactSynthesisInterface;
  setPdfMode: any;
  setCurrentSdgId: any;
  firstSerieShowConfidenceIndex: boolean;
  secondSerieShowConfidenceIndex: boolean;
  comparaisonMode: boolean;
  goalMode: boolean;
}

const RosaceSection = ({currentSdgId, extendedPdfMode, isRosaceLoading,
  firstSerieSdgResult, secondSerieSdgResult, setPdfMode, setCurrentSdgId,
  firstSerieShowConfidenceIndex, secondSerieShowConfidenceIndex,
  comparaisonMode, goalMode
}: RosaceSectionProps) => {

  const [targetOver, setTargetOver] = useState<number>(0);


  const setModeRadar = (value: number, type: string) => {
    setCurrentSdgId(value);
  };

  return (
    
    <div id="cmpImpactSynthesis" className={`${styles.col} ${styles.col2}`}>

    {currentSdgId === 0 ? (
      <div className={styles.tool__content}>
        <div className={`dataviz-wrapper ${styles["dataviz-wrapper"]}`}>
          <div className={`${styles.dataviz} ${styles.dataviz_rosace}`}>
            <div
              className={`container-charts mode-radar ${extendedPdfMode ? "wrapp-pdf-extend" : ""
                }`}
            >
              {/* ROSACE */}
              {isRosaceLoading ? (
                <Loader
                  className={isRosaceLoading ? "on" : "off"}
                  color="multicolors"
                  size="small"
                />
              ) : (
                firstSerieSdgResult &&
                secondSerieSdgResult && (
                  <ChartRadial
                    sdgImpactSynthesis={firstSerieSdgResult}
                    comparaisonSdgSynthesis={secondSerieSdgResult}
                    setPdfMode={setPdfMode}
                    comparaisonMode={comparaisonMode}
                    extendedPdfMode={extendedPdfMode}
                    setCurrentSdgId={setCurrentSdgId}
                    setTargetOver={setTargetOver}
                    showConfidenceIndex={firstSerieShowConfidenceIndex}
                    showConfidenceIndexComparaison={
                      secondSerieShowConfidenceIndex
                    }
                  />
                )
              )}
            </div>
          </div>

          {/* Legend sdg over */}
          <div className={styles["sdg-infos"]}>
            <div className={styles.sdg_inner} data-print="no">
              <div className={styles["sdg-icon-wrapper"]}>
                <span className={styles["sdg-icon"]}>
                  {targetOver !== 0 ? (
                    <img
                      src={
                        "/assets/images/sdg/icons/80/sdg-pastille-" +
                        targetOver +
                        "@2x.png"
                      }
                      alt=" "
                    />
                  ) : (
                    <svg className="svg svg-icon icon-global-sdg-rating">
                      <use xlinkHref="#icon-global-sdg-rating"></use>
                    </svg>
                  )}
                </span>
              </div>
              <div className={styles["sdg-legend"]}>
                <span>
                  {targetOver !== 0 && sdgNames[targetOver]
                    ? sdgNames[targetOver].shortName
                    : "SDG global rating"}
                </span>
              </div>
              <div
                className={`${styles["sdg-scores"]} ${!!secondSerieSdgResult &&
                  !!comparaisonMode &&
                  styles["split"]
                  }`}
              >
                <div className={`${styles["score"]} ${styles["score1"]}`}>
                  <div className={styles.score_inner}>
                    {!!secondSerieSdgResult && !!comparaisonMode && (
                      <span className={styles["sdg-label"]}>
                        First serie
                      </span>
                    )}
                    {targetOver !== 0 ? (
                      <span className={styles["sdg-value"]}>
                        {firstSerieSdgResult?.sdg[targetOver]?.total || firstSerieSdgResult?.sdg[targetOver]?.total === 0
                          ? firstSerieSdgResult.sdg[
                            targetOver
                          ].total.toFixed(2)
                          : "--"}
                      </span>
                    ) : (
                      <span className={styles["sdg-value"]}>
                        {firstSerieSdgResult &&
                          firstSerieSdgResult.total ? (
                          <>{firstSerieSdgResult.total.toFixed(2)}</>
                        ) : (
                          "--"
                        )}
                      </span>
                    )}
                  </div>
                </div>

                {!!secondSerieSdgResult && !!comparaisonMode && (
                  <div
                    className={`${styles["score"]} ${styles["score2"]}`}
                  >
                    <div className={styles.score_inner}>
                      <span className={styles["sdg-label"]}>
                        Second serie
                      </span>
                      {targetOver !== 0 ? (
                        <span className={styles["sdg-value"]}>
                          {secondSerieSdgResult?.sdg[targetOver]?.total
                            ? secondSerieSdgResult.sdg[
                              targetOver
                            ].total.toFixed(2)
                            : "--"}
                        </span>
                      ) : (
                        <span className={styles["sdg-value"]}>
                          {secondSerieSdgResult &&
                            secondSerieSdgResult.total ? (
                            <>{secondSerieSdgResult.total.toFixed(2)}</>
                          ) : (
                            "--"
                          )}
                        </span>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/* <div className="filters">
                <button
                  className={`btn btn-goals btn-rounded ${
                    !!goalMode ? "on" : "off"
                  }`}
                  onClick={() => {
                    setGoalsMode();
                  }}
                  data-print={`${!!goalMode ? "yes" : "no"}`}
                >
                  <span className="btn_label">Meridiam goals</span>
                </button>
                {!!goalMode && meridiamSDGgoal[targetOver - 1] !== null && (
                  <div className="goal" data-print="no">
                    <span>{meridiamSDGgoal[targetOver - 1]}</span>
                  </div>
                )}
              </div> */}
          </div>
        </div>

        {/* PDF legend */}
        <div className={`pdf pdf-howto`}>
          <div className="pdf-howto_inner">
            <div className="howto-graph">
              <div
                className={`howto-graph_inner ${!!secondSerieSdgResult && !!comparaisonMode && "split"
                  } ${!!goalMode && "with-goals"}`}
              >
                <div className="howto-circles">
                  <div className="howto-circles_inner">
                    <div className="circle circle-1">
                      <svg viewBox="0 0 200 200">
                        <circle cx="100" cy="200" r="150" />
                      </svg>
                    </div>
                    {!!secondSerieSdgResult && !!comparaisonMode && (
                      <div className="circle circle-2">
                        <svg viewBox="0 0 200 200">
                          <circle cx="100" cy="200" r="120" />
                        </svg>
                      </div>
                    )}
                    {!!goalMode && (
                      <div className="circle circle-goals">
                        <svg viewBox="0 0 200 200">
                          <circle cx="100" cy="200" r="180" />
                        </svg>
                      </div>
                    )}
                  </div>
                </div>
                <div className="howto-series">
                  <div className="serie serie-1">
                    <span>First serie </span>
                    <span>Sdg Rating</span>
                  </div>
                  {!!secondSerieSdgResult && !!comparaisonMode && (
                    <div className="serie serie-2">
                      <span>Second serie </span>
                      <span>Sdg Rating</span>
                    </div>
                  )}
                  {!!goalMode && (
                    <div className="serie serie-goals">
                      <span>SVG to Improve</span>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="howto-label">
              <span className="upp">
                How to
                <br />
                read
              </span>
            </div>
          </div>
        </div>
      </div>
    ) : (
      firstSerieSdgResult && (
        <SisBoxDisplayDatas
          currentSdgId={currentSdgId}
          setModeRadar={setModeRadar}
          sdgImpactSynthesis={firstSerieSdgResult}
          comparaisonSdgSynthesis={secondSerieSdgResult}
          comparaisonMode={comparaisonMode}
          showConfidenceIndex={firstSerieShowConfidenceIndex}
          showConfidenceIndexComparaison={secondSerieShowConfidenceIndex}
        />
      )
    )}
  </div>
  )
}

export default RosaceSection;
import axios from "axios";
import { LoginResponse } from "models/authentication.models";
import { User } from "models/user.models";
import config from "modules/config";

const ApiBaseUrl = config.ApiUrl;

const requestLogin = async (
  login: string,
  password: string
): Promise<LoginResponse> => {
  const result = await axios({
    method: "post",
    url: `${ApiBaseUrl}/user/login`,
    data: {
      login,
      password,
    },
  });

  return result.data;
};

const requestLoginWithToken = async (token: string): Promise<LoginResponse> => {
  const result = await axios({
    method: "get",
    url: `${ApiBaseUrl}/user/login/token/${token}`,
  });

  return result.data;
};

const requestLostPassword = async (login: string): Promise<void> => {
  await axios({
    method: "post",
    url: `${ApiBaseUrl}/user/requestPasswordReset`,
    data: {
      login,
    },
  });
};

const passwordReset = async (
  token: string,
  login: string,
  newPassword: string
): Promise<void> => {
  await axios({
    method: "post",
    url: `${ApiBaseUrl}/user/passwordReset`,
    data: {
      login,
      token,
      newPassword,
    },
  });
};

const requestActivateAccount = async (
  accessToken: string,
  newPassword: string,
  newPasswordConfirmation: string
): Promise<boolean> => {
  await axios({
    method: "post",
    url: `${ApiBaseUrl}/user/activate`,
    headers: { Authorization: "Bearer " + accessToken },
    data: {
      newPassword,
      newPasswordConfirmation,
    },
  });

  return Promise.resolve(true);
};

const getTokenValidity = async (
  accessToken: string
): Promise<{
  isValid: boolean;
  user?: User;
}> => {
  try {
    const result = await axios({
      method: "get",
      url: `${ApiBaseUrl}/user`,
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    return { isValid: true, user: result.data };
  } catch {
    return { isValid: false };
  }
};

export {
  getTokenValidity,
  requestLogin,
  requestLostPassword,
  requestActivateAccount,
  passwordReset,
  requestLoginWithToken,
};

import React from "react";

interface ProjectIndicatorsSwitchProps {
  slug: any,
  indicator: any;
  value: string;
  handleChange: any;
  index?: number
}

const ProjectIndicatorsSwitch = ({ slug, indicator, value, handleChange, index }: ProjectIndicatorsSwitchProps) => {

  return (
    <div className={`indicator-section_head__radio`}>
      <p className="label">{indicator.textPlaceholder}</p>
      <div className="radio_container">
        <div className="radio_item">
          <input
            type="radio"
            id={`yes-${indicator.id}-${index}`}
            name={`switch-${indicator.id}-${index}`}
            value="yes"
            checked={'yes' === value}
            onChange={(e: any) => handleChange(slug, e.target.value, indicator.id)}
          />
          <label htmlFor={`yes-${indicator.id}-${index}`}>Yes</label>
        </div>
        <div className="radio_item">
          <input
            type="radio"
            id={`no-${indicator.id}-${index}`}
            name={`switch-${indicator.id}-${index}`}
            value="no"
            checked={'no' === value}
            onChange={(e: any) => handleChange(slug, e.target.value, indicator.id)}
          />
          <label htmlFor={`no-${indicator.id}-${index}`}>No</label>
        </div>
      </div>
    </div>
  );
};

export default ProjectIndicatorsSwitch;

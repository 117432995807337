import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import SdgImpactSynthesis from "./screens/SdgImpactSynthesis/SdgImpactSynthesis";
import EsgSdgContribution from "./screens/SdgContribution/SdgContribution";
import SurveyManagement from "./screens/SurveyManagement/SurveyManagement";
import { useWindowSize } from "@react-hook/window-size/throttled";
import WindowTooSmall from "modules/shared/components/WindowTooSmall/WindowTooSmall";


function PlatformRoutes() {
  let { path } = useRouteMatch();

  const [width] = useWindowSize({ fps: 60 });

  return (
    <Switch>
      <Route path={`${path}/sdg-impact-synthesis`}>
        <SdgImpactSynthesis />
      </Route>

      <Route path={`${path}/sdg-contribution`}>
        {!!width && 960 < width ?
          <EsgSdgContribution />
          : <WindowTooSmall />
        }
      </Route>

      <Route path={`${path}/survey-management`}>
        <SurveyManagement />
      </Route>

      <Redirect from={`${path}`} to={`${path}/dashboard`} />
    </Switch>
  );
}

export default PlatformRoutes;

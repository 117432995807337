import React from "react";
// , { useEffect, useState }
import { MeridiamFundResult } from "../../models/meridiam-fund-result.model";
// import DownloadPdfButton from "../../../shared/components/Ui/Buttons/DownloadPdfButton/DownloadPdfButton";
import "./styles.scss";

type Props = {
  meridiamFundResult: MeridiamFundResult[];
  currency: string;
  year: number;
  totalInvestment: number;
  totalDrawn: number;
  tvpiInvestor: number;
  trajectoryInvestor: number;
  projectedNetIRRInvestor: number;
  investorType: string;
};

const KeyMetricsSection: React.FC<Props> = ({
  meridiamFundResult,
  currency,
  year,
  totalInvestment,
  totalDrawn,
  tvpiInvestor,
  trajectoryInvestor,
  projectedNetIRRInvestor,
  investorType,
}) => {
  return (
    <div id="cmpKeyMetrics" className="tool tool_lp tool_key-metrics">
      <div className="tool_lp_inner">
        <div className="tool__title">
          <h2>Key Metrics</h2>
          <h4 className="clr-primary">
            <small>As of Q4</small> {year}
            {/* {investorType === "gigf" ? (year+1) : year} */}
          </h4>
        </div>
        <div className="tool__content">
          <div className="tool__legends legends-smartphone">
            <div className="legend">
              <span className="legend_label" data-print="no">
                <i>Scroll lateraly to show data</i>
              </span>
              <span className="legend_icon">
                <svg className="ui ui-svg ui-arrow-swipe">
                  <use xlinkHref="#arrowSwipe"></use>
                </svg>
              </span>
            </div>
          </div>
          <div className="table table-key-metrics">
            <div className="table_inner">
              <div className={`table__column fixed column-fund ${(investorType === "gigf") ? 'gigf' : ''}`}>
                <div className="table__row row__head">
                  <span>Fund</span>
                </div>
                {meridiamFundResult?.map((fund) => {
                  return (
                    <div
                      key={"mFR-" + fund.fundId}
                      className="table__row row__body"
                    >
                      <span>{fund.meridiamFundName}</span>
                    </div>
                  );
                })}
                {/* DO NOT SHOW TOTAL LIGNE FOR GIGF */}
                {investorType === "infra" &&
                  <div className="table__row row__foot">
                    <span>TOTAL</span>
                  </div>
                }
              </div>

              <div className={`table__column column-vintage ${(investorType === "gigf") ? 'gigf' : ''}`}>
                <div className="table__row row__head">
                  <span>
                    <b>Vintage</b>
                  </span>
                </div>
                {meridiamFundResult?.map((fund, index) => {
                  return (
                    <div
                      key={"vintage-" + index}
                      className="table__row row__body"
                    >
                      <span>
                        <b>{fund.vintage}</b>
                      </span>
                    </div>
                  );
                })}
                {investorType === "infra" &&
                  <div className="table__row row__foot">
                    <span>
                      <b>&nbsp;</b>
                    </span>
                  </div>
                }
              </div>

              <div className="table__column column-commitment">
                <div className="table__row row__head">
                  <span>
                    <b>Commitment</b>
                  </span>
                </div>
                {meridiamFundResult?.map((fund, index) => {
                  return (
                    <div key={"fCIC-" + index} className="table__row row__body">
                      <span>
                        <b>
                          {fund.thisCommitmentInCurr.toFixed(0)}M
                          {currency === "EUR" ? "€" : "$"}
                        </b>
                      </span>
                    </div>
                  );
                })}
                {investorType === "infra" &&
                  <div className="table__row row__foot">
                    <span>
                      <b>
                        {totalInvestment.toFixed(0)}M
                        {currency === "EUR" ? "€" : "$"}
                      </b>
                    </span>
                  </div>
                }
              </div>

              <div className={`table__column column-${year === 2019 ? "drawn" : "status"} ${(investorType === "gigf") ? 'gigf' : ''}`}>
                <div className="table__row row__head">
                  <span>
                    { year === 2019 
                    ? 
                      <b>Drawn</b>
                    :
                      <b>Status</b>
                    }
                  </span>
                </div>
                {meridiamFundResult?.map((fund, index) => {
                  return (
                    <div key={"fCIC-" + index} className="table__row row__body">
                      <span>
                        { year === 2019 
                        ? 
                        <b>
                            {fund.drawn.toFixed(1)}M
                            {currency === "EUR" ? "€" : "$"}
                            </b>
                        :
                          fund.status
                        }
                      </span>
                    </div>
                  );
                })}
                {investorType === "infra" &&
                  <div className="table__row row__foot">
                    <span>
                      { year === 2019 &&
                        <b>
                          {totalDrawn.toFixed(0)}M{currency === "EUR" ? "€" : "$"}
                        </b>
                      }
                    </span>
                  </div>
                }
              </div>

              {/* GIGF DONT WANT IRR COL TO SHOW UP */}
              {investorType === "infra" &&
                <div className="table__column column-irr">
                  <div className="table__row row__head">
                    <span>
                      <b>Projected Net IRR</b>
                    </span>
                  </div>
                  {meridiamFundResult?.map((fund, index) => {
                    return (
                      <div key={"irr-" + index} className="table__row row__body">
                        <span>
                          { !!fund.projectedNetIRR
                          ?
                            <b>{fund.projectedNetIRR.toFixed(1)}%</b>
                          :
                            "N/A"
                          }
                        </span>
                      </div>
                    );
                  })}
                  {investorType === "infra" &&
                    <div className="table__row row__foot">
                      <span>
                        {!!projectedNetIRRInvestor && (
                          <b>{projectedNetIRRInvestor.toFixed(1)}%</b>
                        )}

                      </span>
                    </div>
                  } 
                </div>
              }

              <div className={`table__column column-tvpi ${(investorType === "gigf") ? 'gigf' : ''}`}>
                <div className="table__row row__head">
                  <span>
                    <b>TVPI</b>
                  </span>
                </div>
                {meridiamFundResult?.map((fund, index) => {
                  return (
                    <div
                      key={"fTVPI-" + index}
                      className="table__row row__body"
                    >
                      <span>
                        <b>{fund.tvpi.toFixed(1)}x</b>
                      </span>
                    </div>
                  );
                })}
                {investorType === "infra" &&
                  <div className="table__row row__foot">
                    <span>
                      <b>{tvpiInvestor?.toFixed(1)}x</b>
                    </span>
                  </div>
                }
              </div>
              {/* REMOVE COL SDG RATING - NOT WANTED ANYMORE FOR MERDIAM 
              <div className="table__column column-sdg-rating">
                <div className="table__row row__head">
                  <span>
                    <b>SDG Rating</b>
                  </span>
                </div>
                {meridiamFundResult?.map((fund, index) => {
                  return (
                    <div key={"fidx-" + index} className="table__row row__body">
                      <span>
                        <b>{fund.averageSDG?.toFixed(1)}</b>/5
                      </span>
                    </div>
                  );
                })}
                <div className="table__row row__foot">
                  <span>
                    <b>{averageSDGInvestor?.toFixed(1)}</b>/5
                  </span>
                </div>
              </div> */}

              {investorType === "infra" &&
                <div className="table__column column-trajectory">
                  <div className="table__row row__head">
                    <span>
                      <b>Trajectory</b>
                    </span>
                  </div>
                  {meridiamFundResult?.map((fund, index) => {
                    return (
                      <div
                        key={"trajectory-" + index}
                        className="table__row row__body"
                      >
                        <span>
                          {!!fund.trajectory && fund.trajectory!== "N/A" && fund.trajectory !== "UA"
                          ? 
                            (
                              <b>
                                {fund.trajectory} 
                                °C
                              </b>
                            ) 
                          : 
                            (
                              !!fund.trajectory ? fund.trajectory : "UA"
                            )
                          }
                        </span>
                      </div>
                    );
                  })}
                  <div className="table__row row__foot">
                    <span>
                      {!!trajectoryInvestor ? (
                        <>
                          <b>{trajectoryInvestor.toFixed(1)}</b>°C
                        </>
                      ) : (
                        "UA"
                      )}
                    </span>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
        <div className="tool__actions">
          { investorType === "infra" &&
            <p className="legend">
              <sup>*</sup> UA = Under Assessment.
            </p>
          }
          <div className="actions_inner">
            <div className="export">
              {/* <DownloadPdfButton
                visible={false}
                optionsPrint={{
                  format: "A4",
                  orientation: "portrait",
                  assets: [
                    {
                      folder: "assets/css",
                      files: [
                        "development" !== process.env.NODE_ENV
                          ? "print.css"
                          : "print-local.css",
                      ],
                    },
                  ],
                }}
                pdfTitle=""
                filename="Meridiam_Funds"
                selector="#cmpKeyMetrics"
                selectorHead="#cmpPdfHead"
                selectorFoot="#cmpPdfLegends"
                mode="link"
                label="Download"
                labelInside=".pdf"
                icon="fas fa-download"
                itemsList={[]}
                setIsPrint={setIsPrint}
              /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KeyMetricsSection;

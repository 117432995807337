import React from "react";

interface ProjectAssumptionsLeaderItemProps {
  assumption: any;
}

const ProjectAssumptionsLeaderItem = ({
  assumption,
}: ProjectAssumptionsLeaderItemProps) => {
  return (
    <div className="item">
      {assumption.title && <div className="title">{assumption.title}</div>}

      {assumption.description && (
        <div className="description">{assumption.description}</div>
      )}
      
      <div className="img">
        <img src={assumption.file.url} />
      </div>
    </div>
  );
};

export default ProjectAssumptionsLeaderItem;

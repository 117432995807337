import { SdgImpactSynthesisSelection } from "models/sdg-impact-synthesis.model";
import React from "react";
import styles from "./styles.module.scss";
import typeEnumImported from "../../../datas/typeEnum.json";
import { ProjectTypeEnumInterface } from "models/project-type-enum";

type SisCurrentSelectionProps = {
  selection: SdgImpactSynthesisSelection;
  resetSelection: any;
  serieNumber: string;
  hasSelection: boolean;
  toggleSelection: any;
};

const SisCurrentSelection: React.FC<SisCurrentSelectionProps> = ({
  selection,
  resetSelection,
  serieNumber,
  hasSelection,
  toggleSelection,
}) => {
  const typeEnum: {
    [key: string]: ProjectTypeEnumInterface;
  } = typeEnumImported;

  return (
    <>
      <div
        className={`${styles["selection-wrapper"]} ${
          hasSelection === true ? styles.open : styles.close
        }`}
      >
        <div className={styles.selection}>
          <div className={styles["selection-head"]}>
            <label
              htmlFor="activesFiltersBox"
              className={`${styles.btn} ${styles["btn-remote"]}`}
            >
              <span className={styles.btn_label}>Active filter</span>
              <span className={styles.btn_icon}>
                <i className="fas fa-angle-down"></i>
              </span>
            </label>
            <button
              className={`${styles.btn} ${styles["btn-reset-all"]}`}
              onClick={() => resetSelection("all", serieNumber)}
            >
              <span className={styles.btn_label}>reset all</span>
              <span className={styles.btn_icon}>
                <i className="fas fa-times"></i>
              </span>
            </button>
          </div>
          <input
            className={`${styles["selection-remote"]}`}
            id="activesFiltersBox"
            type="checkbox"
          />
          <div className={styles["selection-body"]}>
            {!selection.projectTypes.includes("all") && (
              <div className={styles["selection-group"]}>
                <div className={styles["group-head"]}>
                  <span>Type:</span>
                  <button
                    className={styles.btn}
                    onClick={() => resetSelection("projectTypes", serieNumber)}
                  >
                    <span className={styles.btn_label}>remove all</span>
                    <span className={styles.btn_icon}>
                      <i className="fas fa-times"></i>
                    </span>
                  </button>
                </div>
                <div className={styles["group-body"]}>
                  <ul>
                    {selection.projectTypes.map((pt) => {
                      return (
                        <li key={`PTS-${pt}`}>
                          <button
                            className={styles.btn}
                            onClick={() =>
                              toggleSelection(pt, "projectTypes", serieNumber)
                            }
                          >
                            <span className={styles.btn_label}>
                              {/* {pt} */}

                              {typeEnum[pt] ? typeEnum[pt].niceName : pt}
                            </span>
                            <span className={styles.btn_icon}>
                              <i className="fas fa-times"></i>
                            </span>
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            )}
            {!selection.meridiamFunds.includes("all") && (
              <div className={styles["selection-group"]}>
                <div className={styles["group-head"]}>
                  <span>Fund:</span>
                  <button
                    className={styles.btn}
                    onClick={() => resetSelection("meridiamFunds", serieNumber)}
                  >
                    <span className={styles.btn_label}>remove all</span>
                    <span className={styles.btn_icon}>
                      <i className="fas fa-times"></i>
                    </span>
                  </button>
                </div>
                <div className={styles["group-body"]}>
                  <ul>
                    {selection.meridiamFunds.map((mF) => {
                      return (
                        <li key={`MFS-${mF}`}>
                          <button
                            className={styles.btn}
                            onClick={() =>
                              toggleSelection(mF, "meridiamFunds", serieNumber)
                            }
                          >
                            <span className={styles.btn_label}>{mF}</span>
                            <span className={styles.btn_icon}>
                              <i className="fas fa-times"></i>
                            </span>
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            )}
            {!selection.projectStates.includes("all") && (
              <div className={styles["selection-group"]}>
                <div className={styles["group-head"]}>
                  <span>Project Stage:</span>
                  <button
                    className={styles.btn}
                    onClick={() => resetSelection("projectStates", serieNumber)}
                  >
                    <span className={styles.btn_label}>remove all</span>
                    <span className={styles.btn_icon}>
                      <i className="fas fa-times"></i>
                    </span>
                  </button>
                </div>
                <div className={styles["group-body"]}>
                  <ul>
                    {selection.projectStates.map((pS) => {
                      return (
                        <li key={`PSS-${pS}`}>
                          <button
                            className={styles.btn}
                            onClick={() =>
                              toggleSelection(pS, "projectStates", serieNumber)
                            }
                          >
                            <span className={styles.btn_label}>{pS}</span>
                            <span className={styles.btn_icon}>
                              <i className="fas fa-times"></i>
                            </span>
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            )}
            {!selection.projectInvestmentPhases.includes("all") && (
              <div className={styles["selection-group"]}>
                <div className={styles["group-head"]}>
                  <span>Investment phase:</span>
                  <button
                    className={styles.btn}
                    onClick={() =>
                      resetSelection("projectInvestmentPhases", serieNumber)
                    }
                  >
                    <span className={styles.btn_label}>remove all</span>
                    <span className={styles.btn_icon}>
                      <i className="fas fa-times"></i>
                    </span>
                  </button>
                </div>
                <div className={styles["group-body"]}>
                  <ul>
                    {selection.projectInvestmentPhases.map((pipS) => {
                      return (
                        <li key={`PIPS-${pipS}`}>
                          <button
                            className={styles.btn}
                            onClick={() =>
                              toggleSelection(
                                pipS,
                                "projectInvestmentPhases",
                                serieNumber
                              )
                            }
                          >
                            <span className={styles.btn_label}>{pipS}</span>
                            <span className={styles.btn_icon}>
                              <i className="fas fa-times"></i>
                            </span>
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            )}
            {!selection.location.includes("all") && (
              <div className={styles["selection-group"]}>
                <div className={styles["group-head"]}>
                  <span>Location:</span>
                  <button
                    className={styles.btn}
                    onClick={() => resetSelection("location", serieNumber)}
                  >
                    <span className={styles.btn_label}>remove all</span>
                    <span className={styles.btn_icon}>
                      <i className="fas fa-times"></i>
                    </span>
                  </button>
                </div>
                <div className={styles["group-body"]}>
                  <ul>
                    {selection.location.map((loc) => {
                      return (
                        <li key={`LOCS-${loc}`}>
                          <button
                            className={styles.btn}
                            onClick={() =>
                              toggleSelection(loc, "location", serieNumber)
                            }
                          >
                            <span className={styles.btn_label}>{loc}</span>
                            <span className={styles.btn_icon}>
                              <i className="fas fa-times"></i>
                            </span>
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SisCurrentSelection;

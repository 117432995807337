import { SpvReportingDocument } from "models/spv-reporting.model";

const setTemplatesDocuments = async (
  maxItems: number,
  documents: [SpvReportingDocument]
) => {
  let templates: any = [];

  for (let i = 1; i <= maxItems; i++) {
    const document: any = await documents.find((d: any) => Number(d.code) === i);

    if (!!document) {
      templates.push(document);
    } else {
      templates.push({
        code: "" + i,
        filename: "",
        description: "",
        link: "",
        group1DueDate: "",
        group2DueDate: "",
        noTemplate: false,
      });
    }
  }

  return templates;
};

export { setTemplatesDocuments };

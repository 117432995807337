import React, { useEffect, useState } from "react";
import { getLPsSDGImpactReturn } from "../../services/limited-partners.service";
import { CurrFilter } from "../../models/available-filters.model";

import "./styles.scss";
import SdgImpactSynthesisSection from "../SdgImpactSynthesisSection/SdgImpactSynthesisSection";
import ImpactReturnsSection from "../ImpactReturnsSection/ImpactReturnsSection";
import { SdgImpactSynthesisInterface } from "models/sdg-impact-synthesis.model";
import { ImpactReturns } from "modules/limited-partners/models/impact-returns.model";

type PropsSection = {
  accessToken: string;
  investorId: string;
  year: number;
  currFilter: CurrFilter;
  currency: string;
  investorType: string;
};

const WrapSdgImpactReturnSection: React.FC<PropsSection> = ({
  accessToken,
  investorId,
  year,
  currFilter,
  currency,
  investorType
}) => {
  const [
    sdgImpactSynthesis,
    setSdgImpactSynthesis,
  ] = useState<SdgImpactSynthesisInterface>();
  const [
    isSdgImpactSynthesisLoading,
    setIsSdgImpactSynthesisLoading,
  ] = useState(true);

  const [impactReturns, setImpactReturns] = useState<ImpactReturns[]>();
  const [isImpactReturnsLoading, setIsImpactReturnsLoading] = useState(true);

  const SDGgigf:SdgImpactSynthesisInterface = {
    sdg: {
      "1": {
        datas: {},
        total: 1.3,
        totalConfidenceIndex: 0
      },
      "2": {
        datas: {},
        total: 4.0,
        totalConfidenceIndex: 0
      },
      "3": {
        datas: {},
        total: 3.6,
        totalConfidenceIndex: 0
      },
      "4": {
        datas: {},
        total: 2.2,
        totalConfidenceIndex: 0
      },
      "5": {
        datas: {},
        total: 1.0,
        totalConfidenceIndex: 0
      },
      "6": {
        datas: {},
        total: 3.4,
        totalConfidenceIndex: 0
      },
      "7": {
        datas: {},
        total: 4.4,
        totalConfidenceIndex: 0
      },
      "8": {
        datas: {},
        total: 0.4,
        totalConfidenceIndex: 0
      },
      "9": {
        datas: {},
        total: 1.0,
        totalConfidenceIndex: 0
      },
      "10": {
        datas: {},
        total: 4.7,
        totalConfidenceIndex: 0
      },
      "11": {
        datas: {},
        total: 3.9,
        totalConfidenceIndex: 0
      },
      "12": {
        datas: {},
        total: 4.8,
        totalConfidenceIndex: 0
      },
      "13": {
        datas: {},
        total: 1.3,
        totalConfidenceIndex: 0
      },
      "14": {
        datas: {},
        total: 2.2,
        totalConfidenceIndex: 0
      },
      "15": {
        datas: {},
        total: 0.9,
        totalConfidenceIndex: 0
      },
      "16": {
        datas: {},
        total: 0.8,
        totalConfidenceIndex: 0
      },
      "17": {
        datas: {},
        total: 2.0,
        totalConfidenceIndex: 0
      }
    },
    total: 2.5
  }

  useEffect(() => {
    if(investorType === "infra"){
      if (accessToken === "" || investorId === "") {
        return;
      }
      setIsSdgImpactSynthesisLoading(true);
      setIsImpactReturnsLoading(true);
  
      // allocation by asset/geo
      getLPsSDGImpactReturn(
        accessToken,
        investorId,
        year,
        currency,
        currFilter.meridiamFund,
        currFilter.location,
      )
      .then((result) => {
        if(!!result.resultTotal){
          setSdgImpactSynthesis(result.resultTotal);
          setImpactReturns(result.impactReturn);
        }
        setIsSdgImpactSynthesisLoading(false);
        setIsImpactReturnsLoading(false);
      }, (error) => {
        console.log("---- error Allocation ----");
        console.log(error.response);
        setIsSdgImpactSynthesisLoading(false);
          setIsImpactReturnsLoading(false);
      });
    } else {
      setSdgImpactSynthesis(SDGgigf);
      setIsSdgImpactSynthesisLoading(false);
    }
  }, [currFilter]);

  return (
    <>
      {/* BLOC 6 -- SDG impact synthesis */}
      <SdgImpactSynthesisSection
        sdgImpactSynthesis={sdgImpactSynthesis}
        year={year}
        isSdgImpactSynthesisLoading={isSdgImpactSynthesisLoading}
        investorType={investorType}
      />

      {/* BLOC 7 -- impact & returns */}
      {investorType === "infra" &&
        <ImpactReturnsSection
          impactReturns={impactReturns}
          year={year}
          currency={currency}
          isImpactReturnsLoading={isImpactReturnsLoading}
        />
      }
    </>
  );
};

export default WrapSdgImpactReturnSection;

import React from "react";

import "./styles.scss";

const WindowTooSmall = () => (
    <div className="window-too-small" data-print="no">
        <div className="window-too-small_inner">
            <div className="content">
                <div className="head">
                    <svg className="logo-brand">
                        <use xlinkHref="#logoMeridiam"></use>
                    </svg>
                </div>
                <div className="body">
                    <div className="title">
                        <h3 className="upp">
                            <span className="line">Your screen size is too small</span>
                            <span className="line">to dislay this website</span>
                        </h3>
                    </div>
                    <div className="text">
                        <div className="icons">
                            <i className="fas fa-desktop"></i>
                            <i className="fas fa-laptop"></i>
                        </div>
                        <div className="text">
                            <p>
                                Try it on desktop computer or laptop
                            </p>
                        </div>
                        <div className="wrapp-cta">
                            <a className="btn btn-primary btn-bordered" href="http://www.meridiam.com/" target="_blank" rel="noopener">
                                <span className="btn-label upp">Go back to Meridiam website</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default WindowTooSmall;
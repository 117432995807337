import React, { useState, useEffect } from "react";
import { User } from "models/user.models";

const { DateTime } = require("luxon");

interface UsersListItemProps {
  user: User;
  setCurrUser: any;
}

const UsersListItem = ({ user, setCurrUser }: UsersListItemProps) => {
  const [colorAvatar, setColorAvatar] = useState<string>("#829395");
  const [reformatedRole, setReformatedRole] = useState<string>("");

  useEffect(() => {
    let color: string = "#829395";
    switch (user.platformRole) {
      case "global-admin":
        setReformatedRole("Global Admin");
        color = "#87B65E";
        break;
      case "technical-admin":
        setReformatedRole("Technical Admin");
        color = "#7F1FCB";
        break;
      case "stakeholder":
      default:
        setReformatedRole("User");
        color = "#71A3F1";
        break;
    }
    setColorAvatar(color);
  }, [user]);

  const reformatModule = (module: string) => {
    switch (module) {
      case "survey-sdg":
        return "Survey SDG";
        break;
      case "lp-dashboard":
        return "LP dashboard";
        break;
      case "esg-tools":
        return "ESG tools";
        break;
      case "spv-manager":
        return "SPV Manager";
        break;
      case "reporting":
        return "Reporting";
        break;
      case "user-management":
        return "User management";
        break;
    }
  };

  return (
    <li
      className={`row ${
        user.blockedAccount ? "blocked" : null == user.active ? "unactive" : ""
      }`}
    >
      <div
        className="name"
        onClick={() => {
          setCurrUser(user);
        }}
      >
        <div className="avatar" style={{ backgroundColor: colorAvatar }}>
          <span>
            {user.firstName.charAt(0)}
            {user.lastName.charAt(0)}
          </span>
        </div>
        <div className="user-infos">
          <p className="user-infos_name">
            {user.firstName} {user.lastName}
          </p>
          <p className="user-infos_login">{user.login}</p>
        </div>
      </div>
      <div className="role">{reformatedRole}</div>

      <div className="modules">
        <div id={`modules-${user.id}`} className="tooltip" hidden>
          <ul>
            {"stakeholder" === user.platformRole ? (
              Object.keys(user.privileges).map((module, idx) => (
                <li key={`module-${idx}`}>{reformatModule(module)}</li>
              ))
            ) : (
              <>
                <li>Survey SDG</li>
                <li>LP dashboard</li>
                <li>ESG tools</li>
                <li>SPV Manager</li>
                <li>Reporting</li>
                <li>User management</li>
              </>
            )}
          </ul>
        </div>
        <button
          className={`btn btn-select btn-primary
            ${user.blockedAccount ? "disabled" : ""}
            ${
              "stakeholder" === user.platformRole &&
              Object.keys(user.privileges).length === 0
                ? "not-clickable"
                : ""
            }
          `}
          type="button"
          data-uk-toggle={
            (("stakeholder" === user.platformRole &&
              0 < Object.keys(user.privileges).length) ||
              "stakeholder" !== user.platformRole) &&
            `target: #modules-${user.id}`
          }
        >
          <span className="btn-label">
            {"stakeholder" === user.platformRole
              ? Object.keys(user.privileges).length +
                " module" +
                (Object.keys(user.privileges).length > 1 ? "s" : "")
              : "All modules"}
          </span>
          {(("stakeholder" === user.platformRole &&
            Object.keys(user.privileges).length > 0) ||
            "stakeholder" !== user.platformRole) && (
            <span className="btn-icon">
              <i className={`fas fa-eye`}></i>
            </span>
          )}
        </button>
      </div>
      <div className="account-creation">
        {user.creationDate
          ? DateTime.fromISO(user.creationDate).toFormat("LL/dd/yyyy")
          : "--"}
      </div>
      <div className="status">
        {user.blockedAccount ? (
          <span className="status-icon" data-tooltip="Blocked account">
            <i className="fas fa-exclamation-circle"></i>
          </span>
        ) : user.active ? (
          <span className="status-icon" data-tooltip="Active account">
            <i className="fas fa-check-circle"></i>
          </span>
        ) : (
          null == user.active && (
            <span
              className="status-icon"
              data-tooltip="Account not activated yet"
            >
              <i className="fas fa-hourglass"></i>
            </span>
          )
        )}
      </div>
    </li>
  );
};

export default UsersListItem;

import { useFormik } from "formik";
import { RootState, ThunkDispatch } from "models/store.models";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as yup from "yup";
import { lostPassword } from "../../actions/session-actions";
import { ToastContainer } from "react-toastify";

import "./styles.scss";
import styles from "./styles.module.scss";

import AuthLayout from "../../components/AuthLayout/AuthLayout";
import Loader from "../../../shared/components/Loader/Loader";
import NeedSupportModal from "modules/shared/components/NeedSupportModal/NeedSupportModal";

const validationSchema = yup.object().shape({
  login: yup.string().label("Login").email().required(),
});

const mapStateToProps = ({ session }: RootState) => ({
  session,
});

const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  lostPassword: (login: string) => dispatch(lostPassword(login)),
});

type LostPasswordProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const LostPassword: React.FC<LostPasswordProps> = ({ lostPassword }) => {
  const [lostPaswordSent, setLostPasswordSent] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: {
      login: "",
    },
    onSubmit: async (values) => {
      const result = await lostPassword(values.login);
      setLostPasswordSent(result);
    },
    validationSchema: validationSchema,
  });

  const [showLoading, setShowLoading] = useState<boolean>(false);
  useEffect(() => {
    let timer = setTimeout(() => setShowLoading(true), 50);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <AuthLayout>
      <Loader
        className={showLoading ? "off" : "on"}
        color="multicolors"
        size="large"
      />
      <div className="page page-auth">
        <div className="page_inner">
          <div className="box box-auth">
            <div className="logo">
              <svg
                className={`${styles["logo"]} svg svg-logo svg-logo-meridiam xl`}
              >
                <use xlinkHref="#logoMeridiam"></use>
              </svg>
            </div>
            <div className="box_inner">
              <div className="box__head">
                <h1>
                  <small>Meridiam</small>
                  <span>SDG &amp; Investing Tools Platform</span>
                </h1>
              </div>
              <div className="box__content">
                <form className="form form-auth" onSubmit={formik.handleSubmit}>
                  <div className="form_inner">
                    <div className="form_head">
                      <p>
                        Enter your login below.
                        <br />
                        We will email you a link to reset your password
                      </p>
                    </div>
                    <div className="form_group">
                      <div className="form-row row row-block">
                        <input
                          autoComplete="username"
                          id="login"
                          name="login"
                          type="text"
                          placeholder="Login"
                          onChange={formik.handleChange}
                          value={formik.values.login}
                        />
                        {formik.submitCount > 0 && formik.errors?.login && (
                          <div className="form-error">
                            <span>{formik.errors.login}</span>
                          </div>
                        )}
                      </div>
                      <div className="form-row row row-block row-submit">
                        <button className="btn btn-cta label-external rounded">
                          <span className="btn_icon icon-primary">
                            <i className="fas fa-chevron-right"></i>
                          </span>
                          <span className="btn_label label-primary">
                            Submit
                          </span>
                        </button>
                      </div>
                    </div>
                    {lostPaswordSent && (
                      <div className="row row-message">
                        <div className="message error">
                          Check your E-mail, instructions have been sent.
                        </div>
                      </div>
                    )}
                  </div>
                </form>
              </div>
              <div className="box__foot" data-theme="ocean">
                <ul>
                  <li>
                    <Link to="/auth/login">
                      <a className="link link-white underline">
                        <span className="link_label">
                          Remember your password ?
                        </span>
                      </a>
                    </Link>
                  </li>
                  <li>
                    <button
                      className="link link-white underline"
                      data-uk-toggle="#modalPageNeedSupport"
                    >
                      <span className="link_label">
                        Need technical support ?
                      </span>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div></div>
        </div>
      </div>
      <NeedSupportModal />
      <ToastContainer />
    </AuthLayout>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(LostPassword);

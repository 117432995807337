import { Project } from "models/project.model";
import { RootState, ThunkDispatch } from "models/store.models";
import { Survey } from "models/survey.model";
import { getAllSurveysByYear } from "modules/shared/services/survey.service";
import React, { useEffect, useState } from "react";
import Loader from "../../../shared/components/Loader/Loader";
import { connect } from "react-redux";
import "../../../../assets/scss/custom/tools/_base.scss";
import Header from "../../../shared/components/Header/Header";
import "./styles.scss";
import { exportAsExcelFile } from "modules/shared/services/excel.service";
import TitlePage from "../../../shared/components/Title/TitlePage/TitlePage";
import PlatformToolBar from "../PlatformToolBar/PlatformToolBar";

const mapStateToProps = ({ session }: RootState) => ({
  accessToken: session?.sessionData?.accessToken
    ? session?.sessionData?.accessToken
    : "",
  user: session?.sessionData?.userData,
});

const mapDispatchToProps = (dispatch: ThunkDispatch) => ({});

type EsgSdgContributionProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const SurveyManagement: React.FC<EsgSdgContributionProps> = ({
  accessToken,
  user,
}) => {
  const [totalValidated, setTotalValidated] = useState<number>(0);
  const [totalPendingValidation, setTotalPendingValidation] = useState<number>(
    0
  );
  const [totalCompleted, setTotalCompleted] = useState<number>(0);
  const [totalInProgress, setTotalInProgress] = useState<number>(0);
  const [totalNotStarted, setTotalNotStarted] = useState<number>(0);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [surveys, setSurveys] = useState<Survey[]>([]);
  const [surveysMap, setSurveyMap] = useState<any[]>([]);

  const [projectNameSearch, setProjectNameSearch] = useState<string>("");

  const [currentYear, setCurrentYear] = useState<number>(2020);
  const surveysYear: number[] = [2019, 2020];

  const [isExportingSurveys, setIsExportingSurveys] = useState<boolean>(false);
  // const [isExportingSurveysAnwsers, setIsExportingSurveysAnwsers] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    getAllSurveysByYear(accessToken, currentYear).then((result: Survey[]) => {
      setIsLoading(false);
      // console.log("---- SURVEYS : ----");
      // console.log(result);
      calculateSurveyTotal(result);
      setSurveys(result);
    });
  }, [currentYear]);

  const calculateSurveyTotal = (surveys: Survey[]) => {
    let totalInProgress = 0;
    let totalValidated = 0;
    let totalPendingValidation = 0;
    let totalNotStarted = 0;
    let totalCompleted = 0;
    let surveysMapArr: any = [];

    surveys.forEach((survey) => {
      surveysMapArr[survey.id] = survey;
      switch (survey.state) {
        case "started":
          totalInProgress += 1;
          break;
        case "validated":
          totalValidated += 1;
          break;
        case "submitted":
          totalPendingValidation += 1;
          break;
        case "not_started":
          totalNotStarted += 1;
          break;
        case "completed":
          totalCompleted += 1;
          break;
      }
    });

    setTotalValidated(totalValidated);
    setTotalPendingValidation(totalPendingValidation);
    setTotalCompleted(totalCompleted);
    setTotalInProgress(totalInProgress);
    setTotalNotStarted(totalNotStarted);

    if (surveysMap.length === 0) {
      setSurveyMap(surveysMapArr);
    }
  };

  const goToSurvey = (project: Project) => {
    localStorage.setItem("current_project", JSON.stringify(project));
    if ("development" !== process.env.NODE_ENV) {
      document.location.href = "/survey-sdg/survey/dashboard";
    } else {
      console.log(
        "--- FEATURE NOT AVAILABLE IN LOCAL, please open : http://localhost:4200/survey/dashboard"
      );
    }
  };

  const searchForTerms = (event: any) => {
    setProjectNameSearch(event.target.value);
  };

  const exportAllSurveys = () => {
    setIsExportingSurveys(true);
    let datas: any = [];

    surveys.forEach((survey) => {
      datas.push({
        "project code": survey.project.projectCode,
        "project name": survey.project.projectName,
        "survey completion rate": survey.completion,
        "survey status": survey.state,
        "survey type": survey.project.type,
        "survey countryTarget": survey.project.countryCode,
        "project country code": survey.project.country.target,
        "project short name": survey.project.projectShortName,
        "project category": survey.project.projectShortName,
        "project currency": survey.project.currency,
        "fund committed": survey.project.fundsCommitted,
        "fund committed currency": survey.project.fundsCommittedCurrency,
        "investment phase": survey.project.investmentPhase,
        "Meridiam fund": survey.project.meridiamFund,
        "project stage": survey.project.stage,
      });
    });
    exportAsExcelFile(datas, "all-surveys");
    setIsExportingSurveys(false);
  };

  // const exportAllSurveysAnswers = () => {
  //   setIsExportingSurveysAnwsers(true);
  //   let datas:Survey[] = [];

  //   for( let s in surveys) {
  //     let survey = surveys[s];
  //     requestSurveyDataInternal(accessToken, survey.id).then((result: Survey) => {
  //       datas.push(result);
  //     });
  //   }

  //   let toExportDatas:any[] = [];

  //   if(datas.length > 0){
  //     datas.forEach(survey => {
  //       for(let elem in survey.items) {
  //         let s = survey.items[elem];

  //         let val = {
  //           project_code: surveysMap[survey.id].project.projectCode,
  //           indicator_id: s.question.indicatorId,
  //           N1_title: s.question.esgCategory,
  //           N2_title: s.question.esgSubCategory,
  //           N3_title: s.question.esgSubSubCategory,
  //           N4_id: s.question.indicatorNumbering,
  //           sdg: s.question.sdgId,
  //           target: s.question.target,
  //           questiongroup_title: s.question.questionGroup,
  //           Question: s.question.question,
  //           Indicator: s.question.indicator,
  //           unit: s.question.unit,
  //           value: s.response ? s.response.value : "",
  //           currency: s.response ? s.response.currency : ""
  //         };
  //         toExportDatas.push(val);
  //       }
  //     });
  //   }

  //   exportAsExcelFile(toExportDatas, "all-surveys-anwsers");
  //   setIsExportingSurveysAnwsers(false);
  // };

  return (
    <>
      <Loader
        className={isLoading ? "on" : "off"}
        color="multicolors"
        size="large"
      />

      <div className="header-wrapper" data-uk-sticky="media:768;">
        <Header
          titleContext="Survey Management"
          colorContext={"seaLight"}
          classNameToolbarTop="survey-management"
          tool={`survey-management`}
        />
      </div>

      {!!user && (
        <PlatformToolBar
          user={user}
          currentTool={"survey"}
          surveysYear={surveysYear}
          currentYear={currentYear}
          setCurrentYear={setCurrentYear}
          exportAllSurveys={exportAllSurveys}
          isExportingSurveys={isExportingSurveys}
          // exportAllSurveysAnswers={exportAllSurveysAnswers}
          // isExportingSurveysAnwsers={isExportingSurveysAnwsers}
        />
      )}

      <div className="page page-dashboard" data-theme="seaLight">
        <div className="page_inner">
          <TitlePage
            content={`
              <b>SDG </b>
              <b>Survey Management</b>
            `}
          />
          <div className="grid grid-tool">
            <div className="grid_inner">
              <div className="main-content bg-white tool-survey-management">
                <div className="content-head">
                  <div className="title">
                    <h2 className="upp">
                      <span>{surveys.length} Survey</span>
                      {surveys.length > 1 && <span>s</span>}
                    </h2>
                    <div className="subtitle">
                      <span className="survey-status validated">
                        {totalValidated} validated
                      </span>
                      <span className="survey-status pending">
                        {totalPendingValidation} pending validation
                      </span>
                      <span className="survey-status completed">
                        {totalCompleted} completed
                      </span>
                      <span className="survey-status completed">
                        {totalInProgress} in progress
                      </span>
                      <span className="survey-status not-started">
                        {totalNotStarted} not started
                      </span>
                    </div>
                  </div>
                  <div className="tool tool-search">
                    <div className="row row-input row-search">
                      <div className="input">
                        <input
                          type="search"
                          className="no-check"
                          placeholder="Search a project"
                          onChange={searchForTerms}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="content-body">
                  <div
                    className="own-table table-survey-projects"
                    data-uk-filter="target: .table-filter"
                  >
                    {/* TABLE HEADER */}
                    <ul className="table table-head">
                      <li className="row">
                        <div className="inner-row">
                          <div className="col col-1">
                            <div className="cel">
                              <div>
                                <span className="filter-label">
                                  Project name
                                </span>
                                <button
                                  className="btn btn-filter circle filter-asc"
                                  data-uk-filter-control="sort: data-sortprojectshortname;order: desc;"
                                >
                                  <span className="btn-icon">
                                    <i className="fas fa-sort-alpha-up"></i>
                                  </span>
                                </button>
                                <button
                                  className="btn btn-filter circle filter-desc"
                                  data-uk-filter-control="sort: data-sortprojectshortname;order: asc;"
                                >
                                  <span className="btn-icon">
                                    <i className="fas fa-sort-alpha-down"></i>
                                  </span>
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="col col-2">
                            <div className="cel">
                              <div>
                                <span className="filter-label">
                                  Completion rate
                                </span>
                                <button
                                  className="btn btn-filter circle filter-asc"
                                  data-uk-filter-control="sort: data-sortsurveycompletion; order: desc;"
                                >
                                  <span className="btn-icon">
                                    <i className="fas fa-sort-numeric-up"></i>
                                  </span>
                                </button>
                                <button
                                  className="btn btn-filter circle filter-desc"
                                  data-uk-filter-control="sort: data-sortsurveycompletion; order: asc;"
                                >
                                  <span className="btn-icon">
                                    <i className="fas fa-sort-numeric-down"></i>
                                  </span>
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="col col-3">
                            <div className="select select-filter">
                              <button
                                className="btn btn-select with-filter btn-primary"
                                type="button"
                              >
                                <span
                                  className="btn-label uk-active"
                                  data-uk-filter-control="[data-filtersurveystate]"
                                >
                                  All status
                                </span>
                                <span
                                  className="btn-label"
                                  data-uk-filter-control="[data-filtersurveystate='validated']"
                                >
                                  Validated
                                </span>
                                <span
                                  className="btn-label"
                                  data-uk-filter-control="[data-filtersurveystate='submitted']"
                                >
                                  Pending validation
                                </span>
                                <span
                                  className="btn-label"
                                  data-uk-filter-control="[data-filtersurveystate='started']"
                                >
                                  In progress
                                </span>
                                <span
                                  className="btn-label"
                                  data-uk-filter-control="[data-filtersurveystate='not_started']"
                                >
                                  Not started
                                </span>
                                <span
                                  className="btn-label"
                                  data-uk-filter-control="[data-filtersurveystate='completed']"
                                >
                                  Completed
                                </span>
                                <span className="btn-icon"></span>
                              </button>
                              <div className="drop-filter" data-uk-dropdown="">
                                <ul className="uk-nav uk-dropdown-nav">
                                  <li data-uk-filter-control="[data-filtersurveystate]">
                                    <span>All status</span>
                                  </li>
                                  <li data-uk-filter-control="[data-filtersurveystate='validated']">
                                    <span>Validated</span>
                                  </li>
                                  <li data-uk-filter-control="[data-filtersurveystate='submitted']">
                                    <span>Pending validation</span>
                                  </li>
                                  <li data-uk-filter-control="[data-filtersurveystate='started']">
                                    <span>In progress</span>
                                  </li>
                                  <li data-uk-filter-control="[data-filtersurveystate='not_started']">
                                    <span>Not started</span>
                                  </li>
                                  <li data-uk-filter-control="[data-filtersurveystate='completed']">
                                    <span>Completed</span>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="col col-4"></div>
                        </div>
                      </li>
                    </ul>

                    {/* TABLE ROW */}
                    {isLoading ? (
                      <div className="loader">
                        <div className="loader-anim"></div>
                      </div>
                    ) : (
                      <ul className="table table-body table-filter">
                        {surveys &&
                          surveys
                            .filter((survey) => {
                              if (
                                projectNameSearch === "" ||
                                survey.project.projectName.includes(
                                  projectNameSearch
                                ) ||
                                (survey.project.projectShortName &&
                                  survey.project.projectShortName.includes(
                                    projectNameSearch
                                  ))
                              ) {
                                return survey;
                              }
                            })
                            .map((survey) => {
                              return (
                                <li
                                  className="row row-project"
                                  data-sortprojectshortname={
                                    !!survey.project.projectShortName
                                      ? survey.project.projectShortName
                                          .toLowerCase()
                                          .replace(" ", "-")
                                      : survey.project.projectName
                                          .toLowerCase()
                                          .replace(" ", "-")
                                  }
                                  data-sortsurveycompletion={survey.completion}
                                  data-filtersurveystate={survey.state}
                                  key={`survey-${survey.id}`}
                                >
                                  <div className="inner-row">
                                    <div className="col col-1">
                                      {survey.project.projectShortName !==
                                      "" ? (
                                        <div className="cel">
                                          <span
                                            className="last-container"
                                            data-uk-tooltip={`title: #${survey.project.id} - type: ${survey.project.type} - target: ${survey.project.country.target}`}
                                          >
                                            {survey.project.projectShortName}
                                          </span>
                                        </div>
                                      ) : (
                                        <div className="cel">
                                          <span
                                            className="last-container"
                                            data-uk-tooltip={`title: #${survey.project.id} - type: ${survey.project.type}  - target: ${survey.project.country.target}`}
                                          >
                                            {survey.project.projectName}
                                          </span>
                                        </div>
                                      )}
                                    </div>

                                    <div className="col col-2">
                                      <div className="cel">
                                        <div
                                          className="progress-container"
                                          data-percent={survey.completion}
                                        ></div>
                                        <div className="completion">
                                          <span className="value">
                                            {survey.completion}
                                          </span>
                                          <span className="unit">%</span>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col col-3">
                                      <div className="cel">
                                        {survey.state === "validated" && (
                                          <div className="survey-status status-validated">
                                            <span className="status-icon">
                                              <i className="fas fa-check-circle"></i>
                                            </span>
                                            <span className="status-label">
                                              Validated
                                            </span>
                                          </div>
                                        )}

                                        {survey.state === "submitted" && (
                                          <div className="survey-status status-submitted">
                                            <span className="status-icon">
                                              <i className="fas fa-exclamation-circle"></i>
                                            </span>
                                            <span className="status-label">
                                              Pending validation
                                            </span>
                                          </div>
                                        )}

                                        {survey.state === "started" && (
                                          <div className="survey-status status-in_progress">
                                            <span className="status-icon">
                                              <i className="fas fa-hourglass-half"></i>
                                            </span>
                                            <span className="status-label">
                                              In progress
                                            </span>
                                          </div>
                                        )}

                                        {survey.state === "not_started" && (
                                          <div className="survey-status status-not_started">
                                            <span className="status-icon">
                                              <i className="fas fa-hourglass-start"></i>
                                            </span>
                                            <span className="status-label">
                                              Not started
                                            </span>
                                          </div>
                                        )}

                                        {survey.state === "completed" && (
                                          <div className="survey-status status-completed">
                                            <span className="status-icon">
                                              <i className="fas fa-hourglass-end"></i>
                                            </span>
                                            <span className="status-label">
                                              Completed
                                            </span>
                                          </div>
                                        )}
                                      </div>
                                    </div>

                                    <div className="col col-4">
                                      <div className="cel">
                                        <div
                                          className="action"
                                          onClick={() =>
                                            goToSurvey(survey.project)
                                          }
                                        >
                                          <span className="action-label">
                                            Go to survey
                                          </span>
                                          <button
                                            className="btn btn-small btn-circle btn-bordered btn-rounded"
                                            // smaller="true"
                                          >
                                            <span className="btn-icon">
                                              <i className="fas fa-chevron-right"></i>
                                            </span>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              );
                            })}
                      </ul>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SurveyManagement);

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsMore from "highcharts/highcharts-more";
import React, { useState, useEffect } from "react";
import { ImpactReturns } from "../../models/impact-returns.model";
import Loader from "../../../shared/components/Loader/Loader";
import {
  capitalize,
  getProjectTypeIcon,
} from "../../../shared/utils/string-utils";
import "./styles.scss";
import sdg from "../../../platform/datas/sdg.json";
import { CurrFilter } from "../../models/available-filters.model";

if (typeof Highcharts === "object") {
  HighchartsMore(Highcharts);
}

const buildChartFromImpactReturns = (
  impactReturns: ImpactReturns[],
  currentSdgId: number,
  currency: string
) => {
  let series: any = {
    color: "#ffffff",
    data: [],
  };
  let seriesInvert: any = {
    color: "#ffffff",
    data: [],
  };

  const maxImpactReturns = impactReturns.reduce(function (prev, current) {
    return prev.tri > current.tri ? prev : current;
  });

  impactReturns.forEach((item: any) => {
    if (
      currentSdgId === 0 ||
      (item.sdgResult.datas[currentSdgId] &&
        item.sdgResult.datas[currentSdgId].total || item.sdgResult.datas[currentSdgId]?.total === 0)
    ) {
      series.data.push({
        x: item.tri,
        y:
          currentSdgId !== 0
            ? item.sdgResult.datas[currentSdgId].total
            : item.sdgResult.total,
        z: item.fundsCommittedInCurr,
        sdgRatings:
          currentSdgId !== 0
            ? item.sdgResult.datas[currentSdgId].total
            : item.sdgResult.total,
        name: item.projectName,
        type: item.type,
      });

      seriesInvert.data.push({
        y: item.tri,
        x:
          currentSdgId !== 0
            ? item.sdgResult.datas[currentSdgId].total
            : item.sdgResult.total,
        z: item.fundsCommittedInCurr,
        sdgRatings:
          currentSdgId !== 0
            ? item.sdgResult.datas[currentSdgId].total
            : item.sdgResult.total,
        name: item.projectName,
        type: item.type,
      });
    }
  });

  let windowWidth: number;
  windowWidth = window.innerWidth;
  
  // console.log("---- SERIES IMPACT :");
  // console.log(series);
  // console.log("-----");

  return {
    chart: {
      type: "bubble",
      //zoomType: "z",
      // zoomType: windowWidth < 768 ? "yx" : "xy",
      //width: "100",
      // height: 320,
      height: windowWidth < 768 ? 640 : 320,
      className: "chart__own chart__bubble",
      spacing: [20, 0, 30, 0],
      responsive: true,
      styledMode: true,
      useHTML: true,
      // events: {
      //   load: function (this: any) {
      //     console.log("LOAD");
      //     console.log(this);
      //   },
      //   redraw: function (this: any) {
      //     console.log("REDRAW");
      //     console.log(this);
      //   },
      // },
    },
    boost: {
      useGPUTranslations: true,
    },
    // overflow: "allow",
    // plotOptions: {
    //   bubble: {
    //     minSize: 2,
    //     maxSize: 25,
    //     zMin: 0,
    //     zMax: 8
    //   }
    // },
    xAxis: {
      min: windowWidth < 768 ? 0 : -2,
      max: windowWidth < 768 ? 6 : Math.round(maxImpactReturns.tri) + 2,
      tickAmount: windowWidth < 768 ? 7 : Math.round(maxImpactReturns.tri) / 2,
      tickWidth: 0,

      // step:  windowWidth < 768 ? 1 : null,
      gridLineWidth: windowWidth < 768 ? 1 : 0,
      title: {
        text: null,
      },
      labels: {
        useHTML: true,
        outside: true,
        x: windowWidth < 768 ? 0 : 0,
        y: windowWidth < 768 ? 10 : 10,
        verticalAlign: "middle",
        rotation: 0,
        reserveSpace: windowWidth < 768 ? false : false,
        step: windowWidth < 768 ? 1 : null,
        formatter: function (this: any, err: any) {
          if (this.isLast) {
            if (windowWidth < 768) {
              return (
                "<div class='chart__label chart__label_x  last-label'><span>" +
                // "<span class='label_value'>" + this.value + "</span>" +
                "<span class='label_text'>" +
                (currentSdgId !== 0
                  ? "SDG " + currentSdgId + " - " + sdg[currentSdgId].shortName
                  : "SDG Ratings") +
                "</span></span></div>"
              );
            } else {
              return (
                '<div class="chart__label chart__label_x last-label"><span>' +
                this.value +
                " IRR (%)</span></div>"
              );
            }
          } else {
            if (windowWidth < 768) {
              return (
                '<div class="chart__label chart__label_x"><span>' +
                this.value +
                "</span></div>"
              );
            } else {
              return (
                '<div class="chart__label chart__label_x"><span>' +
                this.value +
                "</span></div>"
              );
            }
          }
        },
      },
    },
    yAxis: {
      min: windowWidth < 768 ? 0 : 0,
      max: windowWidth < 768 ? Math.round(maxImpactReturns.tri) + 2 : 6,
      gridLineWidth: windowWidth < 768 ? 0 : 1,
      tickAmount: windowWidth < 768 ? null : 7,
      // step: 1,
      // tickSize: 0,
      title: {
        text: null,
      },
      // categories:  windowWidth < 768 ? null : [0, 1, 2, 3, 4, 5],
      labels: {
        align: "left",
        step: windowWidth < 768 ? null : 1,
        useHTML: true,
        outside: true,
        y: windowWidth < 768 ? -10 : -8,
        x: windowWidth < 768 ? 0 : 0,
        reserveSpace: windowWidth < 768 ? false : false,
        verticalAlign: "bottom",
        formatter: function (this: any, err: any) {
          if (this.isLast) {
            if (windowWidth < 768) {
              return (
                '<div class="chart__label"><span>' +
                this.value +
                " TRI (%)</span></div>"
              );
            } else {
              return (
                '<div class="chart__label chart__label-sdg"><span>' +
                // this.value +
                (currentSdgId !== 0
                  ? "SDG " + currentSdgId + " - " + sdg[currentSdgId].shortName
                  : "SDG Ratings") +
                "</span></div>"
              );
            }
          } else {
            if (windowWidth < 768) {
              return (
                '<div class="chart__label"><span>' +
                this.value +
                "</span></div>"
              );
            } else {
              return (
                '<div class="chart__label"><span>' +
                this.value +
                "</span></div>"
              );
            }
          }
        },
      },
    },
    dataLabels: {
      rotation: 0,
    },
    title: {
      enabled: false,
      text: "",
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      enabled: true,
      useHTML: true,
      outside: true,
      backgroundColor: "transparent",
      borderRadius: 5,
      borderWidth: 0,
      shadow: false,
      followPointer: false, //true,
      hideDelay: 100,
      formatter: function (this: any, err: any) {
        return (
          '<div class="uk-tooltip tooltip-chart tooltip-lp tooltip-chart-impact-returns">' +
          '<div class="line line-1">' +
          '<div class="tooltip_project_name">' +
          this.point.name +
          "</div>" +
          "</div>" +
          '<div class="line line-2">' +
          '<div class="tooltip_project_type">' +
          '<span class="type_icon">' +
          getProjectTypeIcon(this.point.type) +
          '</span>' +
          '<span class="type_name">' +
          ("sme" !== this.point.type
            ? capitalize(this.point.type.replace("_", " "))
            : "Environment & Transition") +
          "</span>" +
          "</div>" +
          "</div>" +
          '<div class="line line-3">' +
          '<span class="tooltip_project_type">' +
          "Commitment: <b>" +
          this.point.z.toFixed(1) +
          (currency === "EUR" ? "M€" : "M$") +
          "</b>" +
          "</span>" +
          "</div>" +
          '<div class="line line-4">' +
          '<span class="tooltip_project_type">' +
          "SDG " +
          (currentSdgId !== 0 ? currentSdgId : "rating") +
          ": <b>" +
          this.point.sdgRatings.toFixed(1) +
          "</b>" +
          "</span>" +
          "</div>" +
          '<div class="line line-5">' +
          '<span class="tooltip_project_type">' +
          "Gross IRR: <b>" +
          this.point.x.toFixed(0) +
          "%</b>" +
          "</span>" +
          "</div>" +
          "</div>"
        );
      },
    },
    series: windowWidth < 768 ? seriesInvert : series,
  };
};

class ImpactReturnsChart extends React.Component<{
  impactReturns: ImpactReturns[];
  currentSdgId: number;
  currency: string;
}> {
  componentDidMount() {
    /* HACK: 
            seems that highcharts sankey diagram does not handle the first display gracefull, 
            calling this.forceUpdate() seems fixing the issue
            Don't do this @ home \o/
    */
    this.forceUpdate();
  }

  render() {
    const { impactReturns } = this.props;
    const { currentSdgId } = this.props;

    return (
      <>
        {!!impactReturns && impactReturns.length > 0 && (
          <HighchartsReact
            highcharts={Highcharts}
            options={buildChartFromImpactReturns(
              impactReturns,
              currentSdgId,
              this.props.currency
            )}
          />
        )}
      </>
    );
  }
}

type PropsSection = {
  impactReturns: ImpactReturns[] | undefined;
  year: number;
  currency: string;
  isImpactReturnsLoading: boolean;
};

const ImpactReturnsSection: React.FC<PropsSection> = ({
  impactReturns,
  year,
  currency,
  isImpactReturnsLoading,
}) => {
  const [currentSdgId, setCurrentSdgId] = useState(0);
  const [listSDG, setListSDG] = useState<number[]>([]);
  
  useEffect( () => {
    if (!!impactReturns) {
      let newListSDG:number[] = [];

      impactReturns
        .filter((item) => !!item.sdgResult.datas)
        .map((d) => d.sdgResult.datas)
        .forEach((d) => {
          Object.entries(d).forEach(([k, v]:any) => {
            if (-1 === newListSDG.indexOf(parseInt(k)) && !!v && v.total !== null){
              newListSDG.push(parseInt(k));
            }
          });
        });
        newListSDG.sort((a: any, b: any) => a - b);
        setListSDG(newListSDG);
    }
  }, [impactReturns])

  return (
    <div id="cmpInmpactReturns" className="tool tool_lp tool_impact-returns">
      <div className="tool_lp_inner">
        <div className="tool__title">
          <h2>Impact &amp; returns</h2>
          <h4 className="clr-primary">
            <small>As of Q4</small> {year}
          </h4>
        </div>
        { isImpactReturnsLoading 
        ?
          <Loader className="on" color="white" size="small" />
        :
         (!!impactReturns && impactReturns.length > 0 ? (
            <>
              <div className="tool__content">
                <div className="filters filters-bar">
                  <div className="filters-bar_inner">
                    <div className="select select-sdg" data-print="no">
                      <div className="select_label">
                        <span>
                          <i>Filter by SDG</i>
                        </span>
                      </div>
                      <button
                        className="btn btn-select btn-primary btn-rounded"
                        type="button"
                      >
                        {currentSdgId === 0 ? (
                          <span className="btn_label">All SDG</span>
                        ) : (
                          <span className="btn_label">SDG {currentSdgId}</span>
                        )}
                        <span className="btn_icon"></span>
                      </button>
                      <div
                        className="drop"
                        data-uk-dropdown="mode: hover; delay-show: 0; delay-hide: 100 ;animation: uk-animation-slide-top-small; duration: 250;"
                      >
                        <ul className="uk-nav uk-dropdown-nav">
                          <li
                            key={"sdgSelected_all"}
                            onClick={() => {
                              setCurrentSdgId(0);
                            }}
                          >
                            <span>All SDG</span>
                          </li>
                          {listSDG?.map((i: number) => {
                            return (
                              <li
                                className={currentSdgId === i ? "current" : ""}
                                key={"sdgSelected_" + i}
                                onClick={() => {
                                  setCurrentSdgId(i);
                                }}
                              >
                                <span>SDG {i}</span>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>

                    <div className="tool__legends">
                      <div className="legend">
                        <span className="legend_icon">
                          <svg className="ui ui-svg ui-legend-size">
                            <use xlinkHref="#legendSize"></use>
                          </svg>
                        </span>
                        <span className="legend_label">
                          Bubble size = project's commitments
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="cmp cmp-chart chart-bar">
                  <div className="inner-chart">
                    {impactReturns && (
                      <ImpactReturnsChart
                        impactReturns={impactReturns}
                        currentSdgId={currentSdgId}
                        currency={currency}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="tool__actions" data-print="no">
                <div className="actions_inner">
                  <div className="export">
                    {/* <DownloadPdfButton
                      visible={false}
                      optionsPrint={{
                        format: "A4",
                        orientation: "portrait",
                        assets: [
                          {
                            folder: "assets/css",
                            files: [
                              "development" !== process.env.NODE_ENV
                                ? "print.css"
                                : "print-local.css",
                            ],
                          },
                        ],
                      }}
                      pdfTitle=""
                      filename="Meridiam-Impact-and-returns"
                      selector="#cmpInmpactReturns"
                      selectorHead="#cmpPdfHead"
                      selectorFoot="#cmpPdfLegends"
                      mode="link"
                      label="Download"
                      labelInside=".pdf"
                      icon="fas fa-download"
                      itemsList={[]}
                      setIsPrint={setIsPrint}
                    /> */}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <p className="no-data-available">No data available.</p>
          ))
        }
      </div>
    </div>
  );
};

export default ImpactReturnsSection;

import { PlatformGroup } from "models/platform-group.model";
import { User } from "models/user.models";
import {
  UserManagementActions,
  // UserManagementActionTypes,
} from "../actions/user-management-action-types";
export interface UserManagementState {
  users: User[];
  groups: PlatformGroup[];
}

const initialState: UserManagementState = {
  users: [],
  groups: [],
};

const userManagementReducer = (
  state: UserManagementState = initialState,
  action: UserManagementActions
): any => {
  return "ok";
  // switch (action.type) {
  //   case UserManagementActionTypes.LoadAllUsers:
  //     return {
  //       ...state,
  //       users: action.allUsers,
  //     };
  //   case UserManagementActionTypes.LoadGroups:
  //     return {
  //       ...state,
  //       groups: action.allGroups,
  //     };
  //   case UserManagementActionTypes.AddOrUpdateGroup: {
  //     const { groups } = state;

  //     let updated = groups.filter((g) => g.id !== action.group.id);
  //     updated = [action.group, ...updated];
  //     return {
  //       ...state,
  //       groups: updated,
  //     };
  //   }
  //   case UserManagementActionTypes.AddOrUpdateUser: {
  //     const { users } = state;

  //     let updated: User[] = [];

  //     if (users.find((u) => u.id === action.user.id)) {
  //       updated = [
  //         action.user,
  //         ...users.filter((u) => u.id !== action.user.id),
  //       ];
  //     } else {
  //       updated = [action.user, ...users];
  //     }

  //     return {
  //       ...state,
  //       users: updated,
  //     };
  //   }
  //   case UserManagementActionTypes.SetSameRoleForAllProjects: {
  //     const { users } = state;

  //     let updated: User[] = [];

  //     if (users.find((u) => u.id === action.user.id)) {
  //       updated = [
  //         action.user,
  //         ...users.filter((u) => u.id !== action.user.id),
  //       ];
  //     } else {
  //       updated = [action.user, ...users];
  //     }

  //     return {
  //       ...state,
  //       users: updated,
  //     };
  //   }
  //   default:
  //     return state;
  // }
};

export default userManagementReducer;

import React from "react";
import UIkit from "uikit";
import { Investor } from "modules/limited-partners/models/investor.model";

import "./styles.scss";

interface UserSettingsLPDashboardItemProps {
  investor: any;
  investors: Investor[];
  updateInvestorPrivilege: any;
  removePrivilege: any;
  formDirty: string[];
}

const UserSettingsLPDashboardItem = ({
  investor,
  investors,
  updateInvestorPrivilege,
  removePrivilege,
  formDirty,
}: UserSettingsLPDashboardItemProps) => {
  const retrieveInvestorName = (investorId: string) => {
    let investorName = investorId;
    if (!!investors && investors.length > 0) {
      investors.map((investor: Investor) => {
        if (investor.investorId === investorId) {
          investorName = investor.investorName;
        }
      });
    }
    return investorName;
  };

  return (
    <li
      className={`row ${"" === investor.role ? "warn" : ""} ${
        formDirty.includes("lp-" + investor.details.investorId)
          ? "is-modified"
          : ""
      }`}
    >
      <div className="investor">
        <p>{retrieveInvestorName(investor.details.investorId)}</p>
      </div>
      <div className="group">
        <div className="selector">
          <div className="select">
            <button
              className="btn btn-select btn-primary full btn-rounded"
              type="button"
            >
              <span className="btn_label">
                {"" !== investor.role ? investor.role : "-----"}
              </span>
              <span className="btn_icon"></span>
            </button>
            <div
              className="drop drop-select investor-privilege"
              data-uk-dropdown="pos: top-left; mode: click; delay-hide: 100"
            >
              <ul className="group lvl0 uk-nav uk-dropdown-nav">
                <li
                  className="item"
                  data-uk-toggle="target: .investor-privilege"
                  onClick={() => {
                    updateInvestorPrivilege(
                      investor.details.investorId,
                      "admin"
                    );
                    UIkit.dropdown(".investor-privilege").hide();
                  }}
                >
                  <span className="clickable">Admin</span>
                </li>
                <li
                  className="item"
                  data-uk-toggle="target: .investor-privilege"
                  onClick={() => {
                    updateInvestorPrivilege(
                      investor.details.investorId,
                      "readonly"
                    );
                    UIkit.dropdown(".investor-privilege").hide();
                  }}
                >
                  <span className="clickable">Read only</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="delete">
        <button onClick={() => removePrivilege(investor.details.investorId)}>
          <i className="fas fa-trash"></i>
        </button>
      </div>
    </li>
  );
};

export default UserSettingsLPDashboardItem;

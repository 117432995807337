import config from "modules/config";
import Resumable from "resumablejs";
import {
  deleteFile,
  prepareFile,
} from "modules/shared/services/spv-reporting.service";

interface FileInterface {
  filename: string;
  file: any;
}

const UploadConfig: any = {
  accessToken: "",
  maxChunks: 0,
  uploading: false,
};

const MaxChunkSize: number = 1000000; //1Mo

const ApiBaseUrl = config.ApiUrl;

let uploadedChunks: any = null;

const UploadFile = async (
  directory: string | null,
  currentFile: FileInterface,
  callback: any,
  filename?: string
) => {
  if (!!directory) {
    const uploadUrl: string | null = await prepareFile(UploadConfig.accessToken, directory, !!filename ? filename : currentFile.filename);

    var r = new Resumable({
      target: `${ApiBaseUrl}/reporting/file/upload`,
      testChunks: false,
      chunkSize: MaxChunkSize,
      simultaneousUploads: 1,
      query: {
        filename: currentFile.filename,
        MaxChunkSize: MaxChunkSize,
        maxChunks: UploadConfig.maxChunks,
        uploadUrl,
      },
      headers: {
        Authorization: `Bearer ${UploadConfig.accessToken}`,
      },
    });

    r.addFile(currentFile.file);
    r.on("fileAdded", (file: any, event: any) => {
      // console.log('---- FILE ADDED');
      r.upload();
    });
    r.on("fileSuccess", (file: any, message: any) => {
      UploadConfig.maxChunks = 0;
      UploadConfig.uploading = false;
      const response: any = JSON.parse(message);

      if (!!response && "ok" === response.status) {
        callback({
          id: response.file.id,
          name: response.file.name,
          directoryId: response.file.parentReference.id,
          file: {
            mimeType: response.file.file.mimeType,
            download: response.file["@content.downloadUrl"],
          },
        });
      }
    });
    r.on("fileError", (file, message) => {
      console.log('');
      console.log("error Uploading the file");
      console.log(message);
      console.log('');
    });
    r.on("fileProgress", (file: any, message: any) => {
      // console.log('--- FILE PROGRESS');
      // console.log(file);
      // console.log(message);
      uploadedChunks += 1;
      r.progress();
    });
    r.on("complete", (a: any, b: any) => {});
  }
};

const deleteFileFromServer = async (
  accessToken: string,
  id: string,
  callback?: any
) => {
  const hasBeenDeleted: boolean = await deleteFile(accessToken, id);
  if (!!hasBeenDeleted && !!callback) {
    callback();
  }
};

const confirmDeleteFile = async (
  accessToken: string,
  filename: string,
  id: string,
  callback?: any
) => {
  if (window.confirm(`Would you like to delete ${filename} file ?`)) {
    const hasBeenDeleted: boolean = await deleteFile(accessToken, id);
    if (!!hasBeenDeleted && !!callback) {
      callback();
    }
  }
};

export default UploadConfig;
export { MaxChunkSize, deleteFileFromServer, UploadFile, confirmDeleteFile };

import { cloneDeep } from "lodash";
import { ESGToolPrivilegeData } from "models/user.models";
import React from "react";

interface UserSettingsESGToolsProps {
  modifiedCurrUser: any;
  setModifiedCurrUser: any;
  formDirty: string[];
  setFormDirty: any;
}

const UserSettingsESGTools = ({
  modifiedCurrUser,
  setModifiedCurrUser,
  formDirty,
  setFormDirty,
}: UserSettingsESGToolsProps) => {
  const hasAccess = (tool: string) => {
    if (
      !!modifiedCurrUser.privileges &&
      !!modifiedCurrUser.privileges["esg-tool"]
    ) {
      let roleExist = modifiedCurrUser.privileges["esg-tool"].filter(
        (up: any) => up.details["toolName"] === tool
      );
      if (!!roleExist && roleExist.length > 0) {
        return true;
      }
    }
    return false;
  };

  const handleChange = (tool: string) => {
    let updatedUser = cloneDeep(modifiedCurrUser);

    if (!formDirty.includes(tool)) {
      let newList = formDirty;
      newList.push(tool);
      setFormDirty(newList);
    }

    if (
      updatedUser.privileges === undefined ||
      updatedUser.privileges === null
    ) {
      updatedUser.privileges = {
        "esg-tool": [],
      };
    }

    if (
      updatedUser.privileges["esg-tool"] === undefined ||
      updatedUser.privileges["esg-tool"] === null
    ) {
      updatedUser.privileges["esg-tool"] = [];
    }

    let newPrivilegeList: any = updatedUser.privileges["esg-tool"];

    let roleExist = updatedUser.privileges["esg-tool"].filter(
      (up: any) => up.details["toolName"] === tool
    );
    if (!!roleExist && roleExist.length > 0) {
      newPrivilegeList = updatedUser.privileges["esg-tool"].filter(
        (up: any) => up.details["toolName"] !== tool
      );
    } else {
      let newPrivilege: any = {
        id: null,
        role: "superadmin",
        module: "esg-tool",
        details: new ESGToolPrivilegeData(),
      };
      newPrivilege.details["toolName"] = tool;
      newPrivilegeList.push(newPrivilege);
    }

    updatedUser.privileges["esg-tool"] = newPrivilegeList;
    setModifiedCurrUser(updatedUser);
  };

  return (
    <div className="user-settings-esg-tools">
      <h3>— set access to esg tools</h3>
      <div className="toggle-container">
        <div className="toggle">
          <input
            type="checkbox"
            id="active"
            className="toggle-input"
            onClick={() => handleChange("impact-synthesis")}
            defaultChecked={hasAccess("impact-synthesis")}
          />
          <div className="toggle-switcher"></div>
        </div>
        <span className="label">SDG Impact Synthesis</span>
      </div>

      <div className="toggle-container">
        <div className="toggle">
          <input
            type="checkbox"
            id="active"
            className="toggle-input"
            onClick={() => handleChange("sdg-contribution")}
            defaultChecked={hasAccess("sdg-contribution")}
          />
          <div className="toggle-switcher"></div>
        </div>
        <span className="label">SDG Contribution</span>
      </div>

      <div className="toggle-container">
        <div className="toggle">
          <input
            type="checkbox"
            id="active"
            className="toggle-input"
            onClick={() => handleChange("survey-management")}
            defaultChecked={hasAccess("survey-management")}
          />
          <div className="toggle-switcher"></div>
        </div>
        <span className="label">Survey Management</span>
      </div>
    </div>
  );
};

export default UserSettingsESGTools;

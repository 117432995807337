import React, { useRef, useEffect, useState, RefObject } from 'react';
// import maplibregl, { NavigationControl, Popup } from 'maplibre-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';
import { ProjectLocation } from 'modules/limited-partners/services/limited-partners.service';

mapboxgl.accessToken = 'pk.eyJ1IjoiYXR6bWFlbCIsImEiOiJjbDNibG5vejMwZXc0M2NvaTgwZG9hbGRoIn0.xyBVJCdAiYUW7Dejl9QRMA';

interface CmpMapI {
    data?: any;
    selectedLocation: ProjectLocation|null,
    setSelectedLocation: Function,
    listRef: RefObject<HTMLUListElement>
    setOpenMobile: any;
    investorType: string;
}

const API_KEY = 'tssKHkj2VZN6bIZW0wEj';
const mapTilerID = '0ac4af54-2dff-4eb0-9f06-c0b4bdaa27b5';
// const mapTilerID = '1d533cf9-ba81-4c88-ab1c-638dacd61092';
let selectedMarker:any = null;


// Need to be a local variable ouside the react contest otherwise it won't be 
let clusters: any = {};

const CmpMap = ({ listRef, data, selectedLocation, setSelectedLocation, setOpenMobile, investorType }: CmpMapI) => {

    const mapContainer = useRef<any>();
    const map = useRef<any>();
    const [lng] = useState(2.37625);
    const [lat] = useState(48.852);
    const [zoom] = useState(investorType === "gigf" ? 4 : 1);
    const [zoomMin] = useState(0);
    const [zoomMax] = useState(14);

    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [hoverLocation, setHoverLocation] = useState<ProjectLocation|null>(null)

    const loadImages = () => {
        map.current.loadImage('assets/images/lp/map-icons/both.png', (error: any, image: any) => {
            if (error) throw error;
            map.current.addImage('image-both', image);
        });
        map.current.loadImage('assets/images/lp/map-icons/selected.png', (error: any, image: any) => {
            if (error) throw error;
            map.current.addImage('image-selected', image);
        });
        map.current.loadImage('assets/images/lp/map-icons/in-operation.png', (error: any, image: any) => {
            if (error) throw error;
            map.current.addImage('image-in-operation', image);
        });
        map.current.loadImage('assets/images/lp/map-icons/under-construction.png', (error: any, image: any) => {
            if (error) throw error;
            map.current.addImage('image-under-construction', image);
        });
        map.current.loadImage('assets/images/lp/map-icons/hover.png', (error: any, image: any) => {
            if (error) throw error;
            map.current.addImage('image-hover', image);
        });
    }

    const addClusters = () => {
        if(!!isLoaded && !!map.current){
            // SOURCE PROJECT
            map.current.addSource('projects', {
                'type': 'geojson',
                'data': {
                    'type': 'FeatureCollection',
                    'features': data.map((c: any) => ({
                        "type": "Feature",
                        "geometry": {
                            "type": "Point",
                            "coordinates": [c.longitude, c.latitude]
                        },
                        "properties": {
                            title: c.projectName,
                            projectCode: c.projectCode,
                            latitude: c.latitude,
                            longitude: c.longitude,
                            stage: c.stage
                        }
                    }))
                },
                cluster: true,
                clusterMinPoints: 2,
                clusterRadius: 50
            });

            // CLUSTER 
            // circle for clusters
            map.current.addLayer({
                id: 'clusters',
                type: 'circle',
                source: 'projects',
                filter: ['has', 'point_count'],
                paint: {
                    'circle-color': '#C2E6F7',
                    'circle-radius': ['step', ['get', 'point_count'], 30, 10,  40]
                }
            });
            // small number on clusters
            map.current.addLayer({
                id: 'cluster-count',
                type: 'symbol',
                source: 'projects',
                filter: ['has', 'point_count'],
                layout: {
                    'text-field': '{point_count_abbreviated}',
                    'text-font': ['Open Sans', 'Arial Unicode MS Bold'],
                    'text-size': 10
                }
            });

            map.current.addLayer({
                id: 'unclustered-project',
                type: 'symbol',
                source: 'projects',
                filter: ['!', ['has', 'point_count']],
                layout: {
                    'icon-image': [
                        "case",
                        ["==", ["get", "stage"], "Both"], "image-both",
                        ["==", ["get", "stage"], "Under Construction"], "image-under-construction",
                        "image-in-operation"
                    ],
                    'icon-size': 0.15
                }
            });
 
            // POINT CONTROL -- projectsBoth
            map.current.on('click', 'unclustered-project', (e: any) => {
                setSelectedLocation(!!selectedMarker && selectedMarker.projectCode === e.features[0].properties.projectCode ? null : e.features[0].properties);
                setOpenMobile(true)
            });
            map.current.on('click', 'unclustered-point-selected', (e: any) => {
                selectedMarker = null;
                setSelectedLocation(null);
            });


            // map.current.on('mouseover', 'unclustered-project', (e: any) => {
            //     setHoverLocation(!!selectedMarker && selectedMarker.projectCode === e.features[0].properties.projectCode ? null : e.features[0].properties);
            // });
            // map.current.on('mouseleave', 'unclustered-project', (e: any) => {
            //     setHoverLocation(null);
            // });
        }
    }

    const centerMap = (newLatLng: number[]) => {
        map.current.flyTo({ center: newLatLng, zoom: map.current.getZoom() < 5 ? 5 : map.current.getZoom() });
    }

    const onClickLocation = () => {
        if (map.current.getLayer("unclustered-point-selected")) {
            map.current.removeLayer("unclustered-point-selected");
        }

        if (!!selectedLocation) {

            map.current.addLayer({
                id: 'unclustered-point-selected',
                type: 'symbol',
                source: 'projects',
                filter: ['all', ['!has', 'point_count'], ['==', 'projectCode', selectedLocation.projectCode]],
                layout: {
                    'icon-image': 'image-'+selectedLocation.stage.replace(' ', '-').toLowerCase(),
                    'icon-size': 0.25
                }
            });

            const cardInList:any = document.querySelector(`[data-key=project-location-${selectedLocation.projectCode}`);
            if (!!cardInList && !!listRef.current) {
                listRef.current.scrollTo(0, cardInList.offsetTop - 150);
            }

            centerMap([selectedLocation.longitude, selectedLocation.latitude]);

        } else {
            selectedLocation = null;
        }
    }

    useEffect(() => {
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: `https://api.maptiler.com/maps/${mapTilerID}/style.json?key=${API_KEY}`,
            center: [lng, lat], // TO DO : TROUVER CENTRER EN FONCTION DES DATAS ?
            zoom: zoom,
            minZoom: zoomMin,
            maxZoom: zoomMax,
            scrollZoom: true,
            attributionControl: false,
        });

        loadImages();

        map.current.addControl(new mapboxgl.NavigationControl({
            showZoom: true,
            showCompass: false
        }));

        map.current.on('load', () => {
            map.current.getCanvas().style.cursor = 'default';
            setIsLoaded(true);
        });

        // Clean up on unmount
        return () => map.current.remove();
    }, [])

    useEffect(() => {
        // ---- RESET
        if (map.current.getLayer("clusters")) {
            map.current.removeLayer("clusters");
            clusters.layerClusters = '';
        }
        if (map.current.getLayer("cluster-count")) {
            map.current.removeLayer("cluster-count");
        }
        if (map.current.getLayer("unclustered-project")) {
            map.current.removeLayer("unclustered-project");
        }
        if (map.current.getLayer("unclustered-point-selected")) {
            map.current.removeLayer("unclustered-point-selected");
        }
        if (map.current.getSource("projects")){
            map.current.removeSource('projects');
        }
        // ---- END RESET

        if(!!isLoaded && 0 < data.length){
            addClusters();
        }
    }, [data, isLoaded]);

    useEffect(() => {
        onClickLocation();
    }, [selectedLocation])

    useEffect( () => {
        if (map.current.getLayer("unclustered-point-hover")) {
            map.current.removeLayer("unclustered-point-hover");
        }

        if(!!hoverLocation){
            map.current.addLayer({
                id: 'unclustered-point-hover',
                type: 'symbol',
                source: 'projects',
                filter: ['all', ['!has', 'point_count'], ['==', 'projectCode', hoverLocation.projectCode]],
                layout: {
                    'icon-image': 'image-hover',
                    'icon-size': 0.20
                }
            });
        }
    }, [hoverLocation])

    return (
        <div className='map-wrapper'>
            <div ref={mapContainer} className='map' />
        </div>
    )
}

export default CmpMap;
import React, { useEffect } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import typeEnumImported from "../../../datas/typeEnum.json";

import {
  SdgImpactSynthesisFilter,
  SdgImpactSynthesisInterface,
  SdgImpactSynthesisSelection,
} from "models/sdg-impact-synthesis.model";

import styles from "./styles.module.scss";
import { ProjectTypeEnumInterface } from "models/project-type-enum";

type SdgImpactSynthesisProps = {
  campaigns: number[];
  year: number;
  setYear: any;
  typeOfSelection: string;
  setTypeOfSelection: any;
  serieSdgResult: SdgImpactSynthesisInterface;
  availableFilter: SdgImpactSynthesisFilter;
  selection: SdgImpactSynthesisSelection;
  serieNumber: string;
  toggleSelection: any;
  resetSelection: any;
};

const SisFilterProjectLeaderViewSection: React.FC<SdgImpactSynthesisProps> = ({
  campaigns,
  year,
  setYear,
  typeOfSelection,
  setTypeOfSelection,
  availableFilter,
  selection,
  serieNumber,
  toggleSelection,
  resetSelection
}) => {
  const typeEnum: {
    [key: string]: ProjectTypeEnumInterface;
  } = typeEnumImported;

  useEffect(() => {
    resetSelection("all", serieNumber);
  }, [typeOfSelection]);

  return (
    <>
      <div className={`${styles["filters-head"]}`}>
        <div className={styles.actions}>
          <div className={styles.dropdown}>
            <button
              className="btn btn-select btn-primary btn-rounded"
              type="button"
            >
              <span className="btn_label">{year}</span>
              <span className="btn_icon">
                <i className="fas fa-carret-down"></i>
              </span>
            </button>
            <div className="drop" data-uk-dropdown={"pos: bottom-left"}>
              <ul className="uk-nav uk-dropdown-nav">
                {campaigns &&
                  campaigns.map((campaign) => {
                    return (
                      <li
                        key={`year-${campaign}-${serieNumber}`}
                        onClick={(e: any) => setYear(campaign, serieNumber)}
                      >
                        <span>{campaign}</span>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* TOOGLE TYPE OF SELECTION */}
      <div className={styles["remote-list"]}>
        <ul className={styles["remote-list_inner"]}>
          <li>
            <button
              className={`${styles.btn} ${typeOfSelection === "custom" ? styles.active : ""
                }`}
              onClick={() => {
                setTypeOfSelection("custom");
              }}
            >
              <span className={styles.btn_label}>Your project</span>
            </button>
          </li>
          <li>
            <button
              className={`${styles.btn} ${typeOfSelection === "by-type" ? styles.active : ""
                }`}
              onClick={() => {
                setTypeOfSelection("by-type");
              }}
            >
              <span className={styles.btn_label}>Type</span>
            </button>
          </li>
          <li>
            <button
              className={`${styles.btn} ${typeOfSelection === "by-fund" ? styles.active : ""
                }`}
              onClick={() => {
                setTypeOfSelection("by-fund");
              }}
            >
              <span className={styles.btn_label}>Fund</span>
            </button>
          </li>
          <li>
            <button
              className={`${styles.btn} ${typeOfSelection === "meridiam" ? styles.active : ""
                }`}
              onClick={() => {
                setTypeOfSelection("meridiam");
              }}
            >
              <span className={styles.btn_label}>Meridiam</span>
            </button>
          </li>
        </ul>
      </div>

      {/* CUSTOM SELECTION */}
      <div className={`${styles["custom-selection-wrapper"]}  ${typeOfSelection === "custom" ? styles.open : styles.close}`}>
        <ul className={styles["projects-list"]}>
          <li className={`
            ${styles["list-item"]}
            ${styles.all}
            ${selection && availableFilter && selection.projects.length === availableFilter.projects.length ? styles.checked : ''}`}>
            <FormControlLabel
              label={`All your projects (${availableFilter && availableFilter.projects.length})`}
              control={
                <Checkbox
                  checked={
                    selection &&
                    availableFilter &&
                    selection.projects.length ===
                    availableFilter.projects.length
                  }
                  onChange={() => resetSelection("project", serieNumber)}
                  color="primary"
                />
              }
            />
          </li>
          <li>
            <ul className={styles["projects-list"]}>
              {availableFilter &&
                availableFilter.projects
                  .sort((a, b) => a.projectName.localeCompare(b.projectName))
                  .map((project) => {
                    return (
                      <li
                        className={`${styles["list-item"]} ${selection && selection.projects.length !== availableFilter.projects.length && selection.projects.includes(project.id) ? styles.checked : ''}`}
                        key={`prj-${project.projectCode}-${serieNumber}`}
                      >
                        <FormControlLabel
                          label={`${project.projectName}`}
                          control={
                            <Checkbox
                              checked={
                                selection &&
                                selection.projects.includes(project.id)
                              }
                              onChange={() =>
                                toggleSelection(
                                  project.id,
                                  "project",
                                  serieNumber
                                )
                              }
                              color="primary"
                            />
                          }
                        />
                      </li>
                    );
                  })}
            </ul>
          </li>
        </ul>
      </div>

      {/*  SELECTION BY FILTER */}
      <div className={`${styles["filters-wrapper"]}  ${typeOfSelection === "by-type" ? styles.open : styles.close}`}>
        <div className={`${styles.filter}`}>
          <ul>
            {!!availableFilter &&
              !!availableFilter.types &&
              availableFilter.types.map((type, i) => {
                return (
                  <li key={`fK-${type.categoryName}-${serieNumber}`}>
                    <div className={`${styles["filter-heading"]}`}>
                      <span>
                        {typeEnum[type.categoryName]
                          ? typeEnum[type.categoryName].niceName
                          : type.categoryName}
                      </span>
                    </div>
                    <ul>
                      {!!type.types &&
                        type.types.map((elem) => {
                          return (
                            // TODO SAM/MOGO : put correct condition for style (li classname and input checked value)
                            <li className={`${styles["filter-row-checkbox"]} ${(selection && selection.projectTypes.includes(elem.projectType)) ? styles.checked : ""}`}
                              key={`fK-${elem.projectType}-${serieNumber}`}>
                              <input
                                id={`labelK-${elem.projectType}-${serieNumber}`}
                                checked={
                                  (selection && selection.projectTypes.includes(elem.projectType))
                                }
                                type="radio"
                                name={`${serieNumber}-type`}
                                value={elem.projectType}
                                onChange={() =>
                                  toggleSelection(
                                    elem.projectType,
                                    "projectTypes",
                                    serieNumber
                                  )
                                }
                              />
                              <label
                                htmlFor={`labelK-${elem.projectType}-${serieNumber}`}
                                className={`${styles["checkbox-label"]}`}
                              >
                                <span>
                                  {typeEnum[elem.projectType]
                                    ? typeEnum[elem.projectType].niceName
                                    : elem.projectType}
                                </span>
                              </label>
                            </li>
                          );
                        })}
                    </ul>
                  </li>
                );
              })}
          </ul>

        </div>

      </div>

      <div className={`${styles["filters-wrapper"]} ${typeOfSelection === "by-fund" ? styles.open : styles.close}`}>
        <div className={`${styles.filter}`}>
          <ul>
            {!!availableFilter &&
              !!availableFilter.funds &&
              availableFilter.funds.map((fund, idx) => (
              <li className={`${styles["filter-row-checkbox"]} ${styles.fund} 
                ${(selection && selection.meridiamFunds.includes(fund.mfCode)) ? styles.checked : "nok"}`}
                key={`fK-${idx}-${serieNumber}`}
              >
                <input
                  id={`labelK-${fund.mfCode}-${serieNumber}`}
                  checked={
                    (selection && selection.meridiamFunds.includes(fund.mfCode))
                  }
                  type="radio"
                  name={`${serieNumber}-type`}
                  value={fund.mfCode}
                  onChange={() =>
                    toggleSelection(
                      fund.mfCode,
                      "meridiamFunds",
                      serieNumber
                    )
                  }
                />
                <label
                  htmlFor={`labelK-${fund.mfCode}-${serieNumber}`}
                  className={`${styles["checkbox-label"]}`}
                >
                  <span>
                    {fund.mfName}
                  </span>
                </label>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className={`${styles["filters-wrapper"]} ${typeOfSelection === "meridiam" ? styles.open : styles.close}`}></div>
    </>
  );
};

export default SisFilterProjectLeaderViewSection;

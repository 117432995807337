import React, { useEffect, useState } from "react";
import { RootState, ThunkDispatch } from "models/store.models";
import Loader from "../../../shared/components/Loader/Loader";
import { connect } from "react-redux";
import "../../../../assets/scss/custom/tools/_base.scss";
import "./styles.scss";
import { Project } from "models/project.model";
import { getAllProjectsSPVManager } from "modules/shared/services/project.service";
import SPVManagerList from "modules/management/components/SPVManagerList/SPVManagerList";
import SPVManagerForm from "modules/management/components/SPVManagerForm/SPVManagerForm";
import { Country } from "models/country.model";
import { Currency } from "models/currency.model";
import WarnModal from "modules/shared/components/WarnModal/WarnModal";
import { cloneDeep } from "lodash";
import { MeridiamFund } from "modules/limited-partners/models/meridiam-fund.model";

const mapStateToProps = ({ session }: RootState) => ({
  accessToken: session?.sessionData?.accessToken
    ? session?.sessionData?.accessToken
    : "",
  user: session?.sessionData?.userData,
});

const mapDispatchToProps = (dispatch: ThunkDispatch) => ({});

type SPVManagerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const SPVManager: React.FC<SPVManagerProps> = ({ accessToken, user }) => {
  const defaultProject: any = {
    type: "",
    typeCategory: "",
    countryTarget: "",
    projectName: "",
    projectCode: "",
    projectShortName: "",
    meridiamFund: "",
    meridiamFundCode: "",
    fundsCommitted: null,
    currency: "",
    countryCode: "",
    fundsCommittedCurrency: "",
    investmentPhase: "",
    stage: "",
    transactionSize: "",
    hasSurvey: false
  };

  const [currYear, setCurrYear] = useState<number>(2022);
  const [availableYear, setAvailableYear] = useState<number[]>([]);
  const [projects, setProjects] = useState<Project[]>([]);
  const [currProject, setCurrProject] = useState<Project>(defaultProject);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [displayForm, setDisplayForm] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const [countriesList, setCountriesList] = useState<Country[]>([]);
  const [currenciesList, setCurrenciesList] = useState<Currency[]>([]);

  const [meridiamFundList, setMeridiamFundList] = useState<MeridiamFund[]>([]);

  useEffect(() => {
    getAllProjectsSPVManager(accessToken, currYear).then((result: any) => {
      // console.log("projects : ", result);
      if (!!result.projectsList) {
        setProjects(result.projectsList);
      }

      if (!!result.currencies) {
        setCurrenciesList(result.currencies);
      }

      if (!!result.countries) {
        setCountriesList(result.countries);
      }

      if(!!result.meridiamFundList){
        setMeridiamFundList(result.meridiamFundList);
      }

      // set up available years
      if (!!result.years) {
        let newArrYears: number[] = [];
        result.years.map((year: any) => {
          if (year.id > 2018) {
            // first referential year was for helping survey filing, not for compile data
            newArrYears.push(year.id);
          }
        });
        newArrYears.sort((a,b) => a-b);
        setAvailableYear(newArrYears);
      }
      setIsLoading(false);
    });
  }, [currYear]);

  const createNewSPV = () => {
    setCurrProject(defaultProject);
    setIsEditMode(false);
    setDisplayForm(true);
  };

  const openSPV = (currProject: Project) => {
    setCurrProject(currProject);
    setIsEditMode(true);
    setDisplayForm(true);
    window.scrollTo(0, 0);
  };

  const closeSPV = () => {
    setCurrProject(defaultProject);
    setIsEditMode(false);
    setDisplayForm(false);
    window.scrollTo(0, 0);
  };

  const updateProjectList = (projectUpdated: Project, isEditMode: boolean) => {
    setCurrProject(projectUpdated);
    let newList: Project[];
    if (isEditMode) {
      newList = [];
      projects.map((p: Project) => {
        if (p.projectCode === projectUpdated.projectCode) {
          if (p.countryData) {
            projectUpdated.countryData = p.countryData;
          }
          newList.push(projectUpdated);
        } else {
          newList.push(p);
        }
      });
      // console.log(newList);
    } else {
      newList = cloneDeep(projects);
      newList.push(projectUpdated);
      setIsEditMode(true);
    }
    setProjects(newList);
  };

  return (
    <>
      <Loader
        className={isLoading ? "on" : "off"}
        color="multicolors"
        size="large"
      />

      {displayForm === true ? (
        <>
          {/* TO DO : Feature only in edit currYear */}
          <SPVManagerForm
            currProject={currProject}
            isEditMode={isEditMode}
            updateProjectList={updateProjectList}
            availableYear={availableYear}
            currYear={currYear}
            countriesList={countriesList}
            currenciesList={currenciesList}
            closeSPV={closeSPV}
            accessToken={accessToken}
            user={user}
            meridiamFundList={meridiamFundList}
          />
        </>
      ) : (
        <SPVManagerList
          projects={projects}
          setCurrYear={setCurrYear}
          currYear={currYear}
          availableYear={availableYear}
          openSPV={openSPV}
          createNewSPV={createNewSPV}
          user={user}
        />
      )}

      <WarnModal
        id="warnCancel"
        content="Unsaved changes will be lost if you close.<br/>Do you want to close?"
        actionYes={() => closeSPV()}
      />
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SPVManager);

import React from "react";
import "./styles.scss";

type Props = {
  countryCount: number | null;
};
const DropdownCountry: React.FC<Props> = ({ countryCount }) => {
  return (
    <div className="dropdown dropdown-funds">
      <button
        className="uk-button uk-button-default dropdown__title"
        type="button"
        data-uk-toggle="target: #filtersMapModal;"
      >
        <span className="btn_label">
          {countryCount == null ? (
            <>All countries</>
          ) : (
            <>
              {countryCount} countr{countryCount > 1 ? "ies" : "y"}
            </>
          )}
        </span>
        <span className="btn_icon"></span>
      </button>
    </div>
  );
};

export default DropdownCountry;

import { cloneDeep } from "lodash";
import { UserManagerPrivilegeData } from "models/user.models";
import React from "react";

interface UserSettingsUserManagerProps {
  modifiedCurrUser: any;
  setModifiedCurrUser: any;
  formDirty: string[];
  setFormDirty: any;
}

const UserSettingsUserManager = ({
  modifiedCurrUser,
  setModifiedCurrUser,
  formDirty,
  setFormDirty,
}: UserSettingsUserManagerProps) => {
  const hasAccess = () => {
    if (
      !!modifiedCurrUser.privileges &&
      !!modifiedCurrUser.privileges["user-manager"] &&
      modifiedCurrUser.privileges["user-manager"].length > 0
    ) {
      if (
        modifiedCurrUser.privileges["user-manager"][0].role === "superadmin"
      ) {
        return true;
      }
    }
    return false;
  };

  const handleChange = () => {
    let updatedUser = cloneDeep(modifiedCurrUser);
    let newPrivilegeList: any = [];

    if (!formDirty.includes("usermanager")) {
      let newList = formDirty;
      newList.push("usermanager");
      setFormDirty(newList);
    }

    if (
      updatedUser.privileges === undefined ||
      updatedUser.privileges === null
    ) {
      updatedUser.privileges = {
        "user-manager": [],
      };
    }

    if (
      updatedUser.privileges["user-manager"] === undefined ||
      updatedUser.privileges["user-manager"] === null
    ) {
      updatedUser.privileges["user-manager"] = [];
    }

    if (updatedUser.privileges["user-manager"].length === 0) {
      let newPrivilege: any = {
        id: null,
        role: "superadmin",
        module: "user-manager",
        details: new UserManagerPrivilegeData(),
      };
      newPrivilegeList.push(newPrivilege);
    }

    updatedUser.privileges["user-manager"] = newPrivilegeList;
    console.log("NEW USER", updatedUser);
    setModifiedCurrUser(updatedUser);
  };

  return (
    <div className="user-settings-user-manager">
      <i className="italic">Account status</i>
      <div className="toggle-container">
        <div className="toggle">
          <input
            type="checkbox"
            id="active"
            className="toggle-input"
            onClick={() => handleChange()}
            defaultChecked={hasAccess()}
          />
          <div className="toggle-switcher"></div>
        </div>
        <span className="label">Grant super admin privileges</span>
      </div>
      <div className={`state ${hasAccess() ? "warn" : ""}`}>
        {hasAccess() && (
          <>
            <i className="fas fa-exclamation-circle"></i>
            Please be aware that the user will have access to User manager
          </>
        )}
      </div>
    </div>
  );
};

export default UserSettingsUserManager;

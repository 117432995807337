import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import Users from "./screens/Users";

const UserManagementRoutes = () => {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/users`}>
        <Users />
      </Route>
      <Redirect from={`${path}`} to={`${path}/users`} />
    </Switch>
  );
};

export default UserManagementRoutes;

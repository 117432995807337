import React from "react";
import UIkit from "uikit";

import "./styles.scss";

const ManagementContentHead: React.FC<{
  currentTool: string;
  title: string;
  searchPlaceholder: string;

  searchTerms?: string;
  setSearchTerms?: any;
  availableYear?: number[];
  currYear?: number;
  setCurrYear?: any;
  createNewSPV?: any;
}> = ({
  currentTool,
  title,
  searchPlaceholder,
  searchTerms,
  setSearchTerms,
  availableYear,
  currYear,
  setCurrYear,
  createNewSPV,
}) => {
  const searchForTerms = (event: any) => {
    setSearchTerms(event.target.value);
  };

  return (
    <>
      <div className="selector">
        <p className="selector-label">Year</p>
        <div className="select">
          <button
            className="btn btn-select btn-primary full btn-rounded"
            type="button"
          >
            <span className="btn_label">{!!currYear ? currYear : "Year"}</span>
            <span className="btn_icon"></span>
          </button>

          <div
            className="drop drop-select year"
            data-uk-dropdown="pos: top-left; mode: click; delay-hide: 100"
          >
            <ul className="group lvl0 uk-nav uk-dropdown-nav">
              {!!availableYear &&
                availableYear.length > 0 &&
                availableYear.map((year: number, idx: number) => {
                  return (
                    <li
                      key={`keyAvY-${idx}`}
                      className={year === currYear ? "item selected" : "item"}
                      data-uk-toggle="target: .year"
                      onClick={() => {
                        setCurrYear(year);
                        UIkit.dropdown(".year").hide();
                      }}
                    >
                      <span className="clickable full">{year}</span>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      </div>

      <div className="content-head">
        <div className="title">
          <h2 className="upp">
            <span>{title}</span>
          </h2>
        </div>
        {"svp-manager" === currentTool &&
          !!availableYear &&
          availableYear.length > 0 &&
          currYear === availableYear[availableYear.length - 1] && (
            <button
              className="btn btn-select btn-primary"
              onClick={() => createNewSPV()}
            >
              <span className="btn-label">Create new asset</span>
              <span className="btn-icon">
                <i className="fas fa-plus"></i>
              </span>
            </button>
          )}
        <div className="tool tool-search">
          <div className="row row-input row-search">
            <div className="input">
              <input
                type="search"
                className={`no-check ${
                  !!searchTerms && "" !== searchTerms ? "filled" : ""
                }`}
                placeholder={searchPlaceholder}
                value={searchTerms}
                onChange={searchForTerms}
              />
              {"" === searchTerms ? (
                <span className="input-icon">
                  <i className="fas fa-search"></i>
                </span>
              ) : (
                <span className="input-icon" onClick={() => setSearchTerms("")}>
                  <i className="fas fa-times"></i>
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ManagementContentHead;

import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { ProjectTypeEnumInterface } from 'models/project-type-enum'
import typeEnumImport from "../../../datas/typeEnum.json";
import config from "modules/config";

interface SdgContributionHighchartsProps {
  values: any;
  groupType: string;
  totalVal: any;
  print?:boolean;
}

const SdgContributionHighcharts = ({ values, groupType, totalVal, print }: SdgContributionHighchartsProps) => {
  const typeEnum: { [key: string]: ProjectTypeEnumInterface } = typeEnumImport;
  
  let options = {
    chart: {
      type: "column",
      width: !!print ? 880 : null,
      animation: !!print ? false : true,
      height: groupType === "type" ? 340 : 380,
      className: "chart-sdg-contribution",
      spacing: !!print ?  [ 20, 0, 5, 20] : [20, 20, 1, 20],
      responsive: true,
      styledMode: true,
      useHTML: true,
      style: {
        fontFamily: "Montserrat",
      },
      events: {
        load: function (this: any) {
          if (document.getElementById("searchInGraph")) {
            var chart = this;
            var points = !!chart.series[0] && chart.series[0].points;

            var searchInput = document.getElementById(
              "searchInGraph"
            ) as HTMLInputElement;
            var resetSearch = document.getElementById('resetSearch') as HTMLElement;

            const changeMatches = () => {
              points.forEach(function(point:any) {
                if(!!point && !!point.update){
                  point.update({
                    selected: false,
                  });
                  if (point.name === searchInput.value) {
                    point.update({
                      selected: true,
                    });
                    chart.tooltip.refresh(point);
                    // chart.tooltip.refresh(chart.series[0].points[point.x]);
                  }
                }
              });
            };

            const resetSelection = () => {
              points.forEach(function(point:any) {
                if(!!point && !!point.update){
                  point.update({
                    selected: false,
                  });
                }
                chart.tooltip.refresh(point);
              });
            };

            searchInput.addEventListener('keyup', changeMatches);
            resetSearch.addEventListener('click', resetSelection);

            this.redraw();
          }
        },
      },
    },
    title: {
      enabled: false,
      text: "",
    },
    xAxis: {
      min: 0,
      type: "category",
      labels: {
        useHTML: true,
        outside: !!print ? false : true,
        enabled: groupType === "type" || groupType === "fund" ? true : false,
        rotation: groupType === "type" || groupType === "fund" ? 0 : -45,
        y: !!print ? 40 : 30,
        formatter: function (this: any, err: any) {
          if (groupType !== "type") {
            return '<div class="label-category-wrapper"><span class="label-category">' + this.value + "</span></div>";
          } else {
            let isSubcategory = !!typeEnum[this.value] && typeEnum[this.value].type === "subcategory" ? true : false;
            if (isSubcategory) {
              return (
                (!!typeEnum && !!typeEnum[this.value] && "svg" === typeEnum[this.value].iconType) ?
                  '<div class="label-category-wrapper"><span class="label-category"><span className="label-icon"><img src="' + config.CdnUrl + '/assets/images/sdg/icons/filters/' + typeEnum[this.value].slug + '.svg" /></span></span></div>'
                  :
                  '<div class="label-category-wrapper"><span class="label-category"><span class="label-icon"><i class="fas fa-' + typeEnum[this.value].slug + '"></i></span></span></div>'
              );
            } else {
              return (
                '<div class="label-category-wrapper"><span class="label-category">' +
                (!!typeEnum[this.value] ? typeEnum[this.value].niceName : this.value) +
                "</span></div>"
              );
            }
          }
        },
      },
      tickLength: !!print ? 0 : 6,
    },
    yAxis: {
      type: "number",
      min: 0,
      max: 5,
      maxPadding: !!print ? 0 : 0.2,
      title: {
        enabled: false,
      },
      labels: {
        useHTML: true,
        align: "center",
        reserveSpace: false,
        y: !!print ? -10 : 0,
        x: !!print ? 0 : -10
      },
    },
    tooltip: {
      enabled: true,
      useHTML: true,
      outside: true,
      backgroundColor: "transparent",
      borderRadius: 5,
      borderWidth: 0,
      shadow: false,
      followPointer: false, //true,
      hideDelay: 100,
      formatter: function (this: any, err: any) {
        if (groupType !== "type") {
          return (
            '<div class="uk-tooltip tooltip-chart tooltip-lp tooltip-chart-allocationByAssets">' +
            '<div class="line line1"><h3>' +
            this.key +
            "</h3></div>" +
            '<div class="line line2">' +
            (this.x + 1) +
            "th/" +
            totalVal +
            "</div>" +
            '<div class="line line3">' +
              (this.y > 0 ? this.y.toFixed(1) : this.y) +
            "</div>" +
            "</div>"
          );
        } else {
          let parentNiceName: any = "";
          let niceName = "";

          if (!!typeEnum[this.key]) {
            if (!!typeEnum[this.key].type && typeEnum[this.key].parentNiceName !== null) {
              parentNiceName = typeEnum[this.key].parentNiceName;
            }
            if (groupType === "type" && !!typeEnum[this.key].niceName) {
              niceName = typeEnum[this.key].niceName;
            }
          }

          return (
            '<div class="uk-tooltip tooltip-chart tooltip-lp tooltip-chart-allocationByAssets">' +
            '<div class="line line1"><h3>' + parentNiceName + '</h3></div>' +
            '<div class="line line1"><h3>' + niceName + '</h3></div>' +
            '<div class="line line2">' + (this.x + 1) + 'th/' + totalVal + '</div>' +
            '<div class="line line3">' +
            this.y.toFixed(1) +
            "</div>" +
            "</div>"
          );
        }
      },
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      series: {
        minPointLength: !!print ? 5 : 5,
        maxPointWidth: !!print ? 60 : 60,
        allowPointSelect: true,
        shadow: false,
        grouping: false,
        // groupPadding: 1,
        zones: [
          {
            value: 0,
            className: "zone-0",
          },
          {
            value: 1,
            className: "zone-1",
          },
          {
            value: 2,
            className: "zone-2",
          },
          {
            value: 3,
            className: "zone-3",
          },
          {
            value: 4,
            className: "zone-4",
          },
          {
            value: 5,
            className: "zone-5",
          },
          {
            value: 6,
            className: "zone-6",
          },
        ],
        groupPadding: !!print ? 0 : 0.05,
        groupZPadding: !!print ? 0 : 0,
        pointPadding: !!print ? 0 : 0.01,
      },
    },
    dataLabels: {
      enabled: true,
      rotation: -90,
      color: "#FFFF00",
      align: "right",
      format: "{point.y:.1f}", // one decimal
      y: !!print ? 0 : 10, // 10 pixels down from the top
      style: {
        fontSize: "13px",
        fontFamily: "Montserrat",
      },
    },
    series: values,
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
    />
  )
}

export default SdgContributionHighcharts;
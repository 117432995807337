import React, { useEffect, useState } from "react";
import { AllocationResult, GeographicalOverview } from "../../models/allocation-result.model";
import { getAllocationAssetsGeography } from "../../services/limited-partners.service";
import { CurrFilter } from "../../models/available-filters.model";

import "./styles.scss";
import AllocationByAssetSection from "../AllocationByAssetSection/AllocationByAssetSection";
import GeographicalOverviewSection from "../GeographicalOverviewSection/GeographicalOverviewSection";

type PropsSection = {
  accessToken: string;
  investorId: string;
  year: number;
  currFilter: CurrFilter;
  currency: string;
  investorType: string;
};

const WrapAllocationGeographicalSection: React.FC<PropsSection> = ({
  accessToken,
  investorId,
  year,
  currFilter,
  currency,
  investorType
}) => {
  const [allocationByAsset, setAllocationByAsset] = useState<
    AllocationResult[]
  >();
  const [geographicalOverview, setGeographicalOverview] = useState<
    GeographicalOverview[]
  >();
  const [isAllocationLoading, setIsAllocationLoading] = useState(true);

  useEffect(() => {
    setIsAllocationLoading(true);

    // allocation by asset/geo
    getAllocationAssetsGeography(
      accessToken,
      investorId,
      year,
      currFilter.meridiamFund,
      currFilter.location,
      currency
    )
    .then((result) => {
      setAllocationByAsset(result.resultByAssetsFinal);
      setGeographicalOverview(result.resultByGeographyFinal);
      setIsAllocationLoading(false);
    }, (error) => {
      console.log("---- error Allocation ----");
      console.log(error.response);
      setIsAllocationLoading(false);
    });
  }, [currFilter]);

  return (
    <>
      <AllocationByAssetSection
        allocationByAsset={allocationByAsset}
        year={year}
        isAllocationLoading={isAllocationLoading}
        currency={currency}
        investorType={investorType}
      />

      {/* BLOC 4 -- Geographical */}
      <GeographicalOverviewSection
        year={year}
        geographicalOverview={geographicalOverview}
        investorType={investorType}
      />
    </>
  );
};

export default WrapAllocationGeographicalSection;

import React from "react";
import "./styles.scss";

interface SearchUserProps {
  searchedTerms: string;
  setSearchedTerms: any;
}

const SearchUser = ({ searchedTerms, setSearchedTerms }: SearchUserProps) => {
  return (
    <div className="search-container form form-fake">
      <div className="search-box">
        <input
          id="search"
          type="text"
          placeholder="Search users..."
          value={searchedTerms}
          onChange={(e) => setSearchedTerms(e.target.value)}
        />
        {"" === searchedTerms ? (
          <span className="search-box_icon">
            <i className="fas fa-search"></i>
          </span>
        ) : (
          <span
            className="search-box_icon"
            onClick={() => setSearchedTerms("")}
          >
            <i className="fas fa-times"></i>
          </span>
        )}
      </div>
    </div>
  );
};

export default SearchUser;

import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";

import UserInformations from "./UserInformations/UserInformations";
import UserManager from "./UserManager/UserManager";
import UserSettings from "./UserSettings/UserSettings";
import WarnModal from "modules/shared/components/WarnModal/WarnModal";

import "./styles.scss";
import "react-toastify/dist/ReactToastify.css";
import { User } from "models/user.models";
import { cloneDeep } from "lodash";
import { Project } from "models/project.model";
import { Investor } from "modules/limited-partners/models/investor.model";
import {
  createOrUpdateUser,
  sendEmail,
} from "modules/user-management/services/user-management.service";

const { DateTime } = require("luxon");

const toastConfig = {
  position: "top-right" as any,
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
};

interface UserSettingsContainerProps {
  currUser: User;
  setCurrUser: any;
  editor: User;
  accessToken: string;
  confirmDeleteUser: any;
  projects: Project[];
  investors: Investor[];
  setUsers: any;
  users: User[];
}

const UserSettingsContainer = ({
  currUser,
  setCurrUser,
  editor,
  confirmDeleteUser,
  projects,
  accessToken,
  investors,
  setUsers,
  users,
}: UserSettingsContainerProps) => {
  const [formDirty, setFormDirty] = useState<string[]>([]);
  const [modifiedCurrUser, setModifiedCurrUser] = useState<User>();
  const [isSendingInvitation, setIsSendingInvitation] = useState<boolean>(
    false
  );

  useEffect(() => {
    let newModifiedUser = cloneDeep(currUser);
    setModifiedCurrUser(newModifiedUser);
  }, [currUser]);

  const handleClose = () => {
    let timeout = setTimeout(() => {
      setCurrUser(null);
      clearTimeout(timeout);
      window.scrollTo(0, 0);
    }, 500); // /!\ timeout 500 is for uikit animation delay
  };

  const handleSave = () => {
    if (!!modifiedCurrUser) {
      createOrUpdateUser(accessToken, modifiedCurrUser).then(
        (result) => {
          toast.success("The user have been uptaded.", toastConfig);
          setModifiedCurrUser(result);
          setFormDirty([]);
          if (modifiedCurrUser.id === null) {
            // creation
            let newListUsers = cloneDeep(users);
            newListUsers.push(result);
            setUsers(newListUsers);
          } else {
            let newListUsers: User[] = [];
            users.map((u) => {
              if (u.id === result.id) {
                newListUsers.push(result);
              } else {
                newListUsers.push(u);
              }
            });
            setUsers(newListUsers);
          }
        },
        (error) => {
          toast.error(
            "An error occurred :" + error.response.data.message,
            toastConfig
          );
        }
      );
    }
  };

  const sendEmailInvitation = (emailType:string = "login-id") => {
    if (!!modifiedCurrUser && modifiedCurrUser.id) {
      if (isSendingInvitation) {
        return;
      }

      setIsSendingInvitation(true);
      sendEmail(accessToken, modifiedCurrUser.id, emailType).then(
        (response) => {
          let newUser = cloneDeep(modifiedCurrUser);
          newUser.credentialSent = response.accountActivation.emailSent;
          newUser.credentialSentDate = response.accountActivation.lastEmailSent;
          setModifiedCurrUser(newUser);

          // update liste users
          let newListUsers: User[] = [];
          users.map((u) => {
            if(emailType === "login-id"){
              if (u.id === response.id) {
                u.credentialSent = response.accountActivation.emailSent;
                u.credentialSentDate = response.accountActivation.lastEmailSent;
              }
            }
            newListUsers.push(u);
          });
          setUsers(newListUsers);

          toast.success("The email have been sent successfully.", toastConfig);
          setIsSendingInvitation(false);
        }
      );
    }
  };

  return (
    <>
      <div className="page_header">
        <div className="page_header__title">
          <span className="title">User settings</span>
        </div>
        <div className="container_buttons">
          {!!modifiedCurrUser && !!modifiedCurrUser.id && (
            <p>
              Last update : {modifiedCurrUser.userLastUpdate},{" "}
              {modifiedCurrUser.lastUpdate
                ? DateTime.fromISO(modifiedCurrUser.lastUpdate).toFormat(
                    "LL/dd/yyyy"
                  )
                : "-"}
            </p>
          )}
          <button
            className="btn btn-select btn-primary"
            type="button"
            onClick={() =>
              !!formDirty && formDirty.length === 0 && handleClose()
            }
            data-uk-toggle={
              !!formDirty && formDirty.length > 0 ? "#warnCancel" : false
            }
          >
            <span className="btn-label">Close</span>
            <span className="btn-icon">
              <i className="fas fa-times"></i>
            </span>
          </button>
          <button
            className="btn btn-select btn-primary"
            type="button"
            onClick={() => handleSave()}
            disabled={!!formDirty && 0 === formDirty.length}
          >
            <span className="btn-label">Save</span>
            <span className="btn-icon">
              <i className={`fas fa-save`}></i>
            </span>
          </button>
        </div>
      </div>

      <div className="back-btn">
        <button
          onClick={() => setCurrUser(null)}
          className="btn btn-select btn-primary"
          type="button"
        >
          <span className="btn-icon">
            <i className={`fas fa-arrow-left`}></i>
          </span>
          <span className="btn-label">Back to Users management list</span>
        </button>
      </div>

      <div className="page_content">
        <div className="page_content-inner">
          {!!modifiedCurrUser && (
            <UserInformations
              modifiedCurrUser={modifiedCurrUser}
              setModifiedCurrUser={setModifiedCurrUser}
              setFormDirty={setFormDirty}
              formDirty={formDirty}
              editor={editor}
            />
          )}

          <div className="separator"></div>

          {!!modifiedCurrUser && (
            <UserManager
              modifiedCurrUser={modifiedCurrUser}
              setModifiedCurrUser={setModifiedCurrUser}
              formDirty={formDirty}
              setFormDirty={setFormDirty}
              editor={editor}
              sendEmailInvitation={sendEmailInvitation}
              isSendingInvitation={isSendingInvitation}
            />
          )}

          <div className="separator"></div>

          {!!modifiedCurrUser && (
            <UserSettings
              modifiedCurrUser={modifiedCurrUser}
              setModifiedCurrUser={setModifiedCurrUser}
              projects={projects}
              setFormDirty={setFormDirty}
              formDirty={formDirty}
              investors={investors}
              sendEmailInvitation={sendEmailInvitation}
            />
          )}
        </div>
      </div>

      <WarnModal
        id="warnDelete"
        content="Do you really want to delete this user account?"
        actionYes={() => confirmDeleteUser()}
      />

      <WarnModal
        id="warnCancel"
        content="Unsaved changes will be lost if you close.<br/>Do you want to close?"
        actionYes={() => handleClose()}
      />

      <ToastContainer />
    </>
  );
};

export default UserSettingsContainer;

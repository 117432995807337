import React, { useEffect, useState } from 'react'
import { SdgContribution } from 'models/esg-contribution.model'
import SdgContributionHighcharts from '../SdgContributionHighcharts/SdgContributionHighcharts'
import Loader from 'modules/shared/components/Loader/Loader'
import { ProjectTypeEnumInterface } from 'models/project-type-enum';
import typeEnumImport from "../../../datas/typeEnum.json";

interface SdgContributionHighchartsWrapperProps{
  currentSdgContribution: SdgContribution;
  currentSdg: number;
  currentTarget: string;
  currentIndicator: string;
  isLoading: boolean;
  setIsLoading: any;
  print?: boolean;
}

const SdgContributionHighchartsWrapper = ({currentSdgContribution,
  currentSdg, currentTarget, currentIndicator, isLoading, setIsLoading, print}: SdgContributionHighchartsWrapperProps) => {
  
  const typeEnum: { [key: string]: ProjectTypeEnumInterface } = typeEnumImport;

  const [values, setValues] = useState<any>({});
  const [totalVal, setTotalVal] = useState<any>();

  useEffect( () => {
    // console.log('MOUNTED - calcul serie');
    setValues({});
    let values: any[] = [];
    let categories: any[] = [];

    let graphValues: any[] = [];
    let groupType = currentSdgContribution.groupType;

    // console.log("%c --DRAWING -- type computation", "color:green;font-size:13px");
    // console.log(currentSdgContribution.projectsDatas);
    // console.log(currentSdg, currentTarget, currentIndicator, groupType);
    // console.log("--------------------");
  
    // compute data depending on the level selected
    if ("" !== currentIndicator) {
      // indicator level
      switch (groupType) {
        case "project":
          currentSdgContribution.projectsDatas.forEach((element: any) => {
            if (
              !!element && 
              !!element.sdgs && 
              !!element.sdgs[currentSdg] &&
              !!element.sdgs[currentSdg][currentTarget] &&
              !!element.sdgs[currentSdg][currentTarget]["datas"][
                currentIndicator
              ] &&
              element.sdgs[currentSdg][currentTarget]["datas"][currentIndicator]
                .questionNote !== null
            ) {
              let val = [
                element.projectName,
                element.sdgs[currentSdg][currentTarget]["datas"][currentIndicator]
                  .questionNote,
              ];
              values.push(val);
            }
          });
          break;
        case "type":
          currentSdgContribution.projectsDatas.forEach((element: any) => {
            
            if (
              !!element && 
              !!element.sdgs &&
              !!element.sdgs[currentSdg] &&
              !!element.sdgs[currentSdg][currentTarget] &&
              !!element.sdgs[currentSdg][currentTarget]["datas"][
                currentIndicator
              ] &&
              element.sdgs[currentSdg][currentTarget]["datas"][currentIndicator]
                .questionNote !== null
            ) {
              
              let isACategory = !!typeEnum && !!typeEnum[element.groupName] && !!typeEnum[element.groupName].type && typeEnum[element.groupName].type === "category" ? true : false
              if(isACategory === true && !categories.includes(element.groupName)){
                categories.push(element.groupName);
                values.push({data: []});
              }

              let categoryIdx:any = 0;
              if(categories.indexOf(element.groupName) === -1){
                categoryIdx = categories.indexOf(typeEnum[element.groupName].parentSlug);
              } else {
                categoryIdx = categories.indexOf(element.groupName);
              }

              let val = [
                element.groupName,
                element.sdgs[currentSdg][currentTarget]["datas"][currentIndicator].questionNote
              ];
  
              values[categoryIdx].data.push(val);
            }
          });
          break;
        case "fund":
          currentSdgContribution.projectsDatas.forEach((element: any) => {
            if (
              !!element && 
              !!element.sdgs &&
              !!element.sdgs[currentSdg] &&
              !!element.sdgs[currentSdg][currentTarget] &&
              !!element.sdgs[currentSdg][currentTarget]["datas"][
                currentIndicator
              ] &&
              element.sdgs[currentSdg][currentTarget]["datas"][currentIndicator]
                .questionNote !== null
            ) {
              let val = [
                element.meridiamFundName,
                element.sdgs[currentSdg][currentTarget]["datas"][currentIndicator]
                  .questionNote,
              ];
              values.push(val);
            }
          });
          break;
      }
    } else if ("" !== currentTarget) {
      // target level
      switch (groupType) {
        case "project":
          currentSdgContribution.projectsDatas.forEach((element: any) => {
            if (
              !!element && 
              !!element.sdgs &&
              !!element.sdgs[currentSdg] &&
              !!element.sdgs[currentSdg][currentTarget] &&
              element.sdgs[currentSdg][currentTarget].total !== null
            ) {
              let val = [
                element.projectName,
                element.sdgs[currentSdg][currentTarget].total,
              ];
              values.push(val);
            }
          });
          break;
        case "type":
          currentSdgContribution.projectsDatas.forEach((element: any) => {
            if (
              !!element && 
              !!element.sdgs &&
              !!element.sdgs[currentSdg] &&
              !!element.sdgs[currentSdg][currentTarget] &&
              element.sdgs[currentSdg][currentTarget].total !== null
            ) {

              let isACategory = !!typeEnum && !!typeEnum[element.groupName] && !!typeEnum[element.groupName].type && typeEnum[element.groupName].type === "category" ? true : false
              if(isACategory === true && !categories.includes(element.groupName)){
                categories.push(element.groupName);
                values.push({data: []});
              }

              let categoryIdx:any = 0;
              if(categories.indexOf(element.groupName) === -1){
                categoryIdx = categories.indexOf(typeEnum[element.groupName].parentSlug);
              } else {
                categoryIdx = categories.indexOf(element.groupName);
              }

              let val = [
                element.groupName,
                element.sdgs[currentSdg][currentTarget].total
              ];
              values[categoryIdx].data.push(val);
            }
          });
          break;
        case "fund":
          currentSdgContribution.projectsDatas.forEach((element: any) => {
            if (
              !!element && 
              !!element.sdgs &&
              !!element.sdgs[currentSdg] &&
              !!element.sdgs[currentSdg][currentTarget] &&
              element.sdgs[currentSdg][currentTarget].total !== null
            ) {
              let val = [
                element.meridiamFundName, 
                element.sdgs[currentSdg][currentTarget].total
              ];
              values.push(val);
            }
          });
          break;
      }
    } else {
      // SDG level
      switch (groupType) {
        case "project":
          currentSdgContribution.projectsDatas.forEach((element:any) => {
            if (
              !!element &&
              !!element.sdgs &&
              !!element.sdgs[currentSdg] &&
              element.sdgs[currentSdg].total !== null
            ) {
              let val = [
                element.projectName,
                element.sdgs[currentSdg].total,
              ];
              values.push(val);
            }
          });
          break;
        case "type":
          for (let groupType in currentSdgContribution.projectsDatas) {
            let element = currentSdgContribution.projectsDatas[groupType];
            if (
              !!element &&
              !!element.sdgs &&
              !!element.sdgs[currentSdg] &&
              element.sdgs[currentSdg].total !== null
            ) {
              let isACategory = !!typeEnum && !!typeEnum[element.groupName] && !!typeEnum[element.groupName].type && typeEnum[element.groupName].type === "category" ? true : false
              if(isACategory === true && !categories.includes(element.groupName)){
                categories.push(element.groupName);
                values.push({data: []});
              }

              let categoryIdx:any = 0;
              if(categories.indexOf(element.groupName) === -1){
                categoryIdx = categories.indexOf(typeEnum[element.groupName].parentSlug);
              } else {
                categoryIdx = categories.indexOf(element.groupName);
              }

              let val = [
                element.groupName,
                !!element.sdgs[currentSdg].total
                  ? element.sdgs[currentSdg].total
                  : 0
              ];

              values[categoryIdx]?.data?.push(val);
            }
          }
  
          break;
        case "fund":
          for (let elem in currentSdgContribution.projectsDatas) {
            let element = currentSdgContribution.projectsDatas[elem];
  
            if (
              !!element &&
              !!element.sdgs &&
              !!element.sdgs[currentSdg] &&
              element.sdgs[currentSdg].total !== null
            ) {
              let val = [
                element.meridiamFundName,
                !!element.sdgs[currentSdg].total
                  ? element.sdgs[currentSdg].total
                  : 0,
              ];
              values.push(val);
            }
          }
          break;
      }
    }
  
    // console.log(">>>>>>", values, "<<<<<<");
  
    let totalValues = 0;
    if ("type" !== groupType) {
      totalValues = values.length;
      // sort values
      if (values.length > 1) {
        values = values.sort((a, b) => b[1] - a[1]);
      }
  
      // for filter
      values.forEach((val) => {
        graphValues.push(val[0]);
      });
  
      values = [{ name: "Ranking", data: values }];
    } else {
      totalValues = 0;
      values.forEach((val) => {
        totalValues += val.data.length;
      });
    }
    
    setTotalVal(totalValues);
    setValues(values);
    setIsLoading(false);

  }, [currentSdgContribution, currentSdg, currentTarget, currentIndicator]);

  return(
    <div className="cmp cmp-chart chart-bar open">
      <div className="inner-chart">
        {!isLoading ? (
          !!values && 
            <SdgContributionHighcharts
                values={values}
                groupType={currentSdgContribution.groupType}
                totalVal={totalVal}
                print={print}
            />
        ) : (
          <div>
            <Loader className="on" color="white" size="small" />
          </div>
        )}
      </div>
    </div>
  );
}

export default SdgContributionHighchartsWrapper;
import axios from "axios";
import {
  SdgImpactSynthesisFilter,
  SdgImpactSynthesisInterface,
  SdgImpactSynthesisSelection,
} from "models/sdg-impact-synthesis.model";
import config from "modules/config";

const ApiBaseUrl = config.ApiUrl;

const getSurveySdgImpact = async (
  accessToken: string,
  year: number,
  selection: SdgImpactSynthesisSelection,
  typeOfSelection: string
): Promise<SdgImpactSynthesisInterface> => {
  const result = await axios({
    method: "post",
    url: `${ApiBaseUrl}/calculation/ratings/${year}/${typeOfSelection}`,
    headers: { Authorization: "Bearer " + accessToken },
    data: selection,
  });

  let rF: any = {
    total: result.data["total"],
    sdg: {}
  };
  if(result.data.status !== 404){
    Object.keys(result.data["sdg"]).map((key: any) => {
      let sdg = result.data["sdg"][key];
      let datasArr: any = {};
      Object.keys(sdg).map((key) => {
        if (key !== "total" && key !== "totalConfidenceIndex") {
          let target = sdg[key];
          datasArr[key] = target;
        }
      });
      rF.sdg[key] = {
        total: sdg.total,
        totalConfidenceIndex: sdg.totalConfidenceIndex,
        datas: datasArr,
      };
    });
  }

  return rF;
};

const getSurveyRichSdgImpact = async (
  accessToken: string,
  year: number,
  selection: SdgImpactSynthesisSelection,
  typeOfSelection: string
): Promise<any> => {
  const result = await axios({
    method: "post",
    url: `${ApiBaseUrl}/calculation/ratings/${year}/${typeOfSelection}`,
    headers: { Authorization: "Bearer " + accessToken },
    data: selection,
  });

  let rF: any = {
    total: result.data["total"],
    sdg: {}
  };
  if(result.data.status !== 404){
    Object.keys(result.data["sdg"]).map((key: any) => {
      let sdg = result.data["sdg"][key];
      let datasArr: any = {};
      Object.keys(sdg).map((key) => {
        if (key !== "total" && key !== "totalConfidenceIndex") {
          let target = sdg[key];
          datasArr[key] = target;
        }
      });
      rF.sdg[key] = {
        total: sdg.total,
        totalConfidenceIndex: sdg.totalConfidenceIndex,
        datas: datasArr,
      };
    });
  }

  return {result: rF, projectSdg: result.data["projectSdg"]};
};

const getSurveySDGImpactFilter = async (
  accessToken: string,
  year: number
): Promise<SdgImpactSynthesisFilter> => {
  const result = await axios({
    method: "get",
    url: `${ApiBaseUrl}/calculation/sdgSynthesisFilter/${year}`,
    headers: { Authorization: "Bearer " + accessToken },
  });

  return result.data;
};

export { getSurveySdgImpact, getSurveySDGImpactFilter, getSurveyRichSdgImpact };

import { useFormik } from "formik";
import { RootState, ThunkDispatch } from "models/store.models";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import { ToastContainer } from "react-toastify";
import {
  loginUser,
  loginWithSso,
  loginWithToken,
} from "../../actions/session-actions";
import "./styles.scss";

import styles from "./styles.module.scss";

import AuthLayout from "../../components/AuthLayout/AuthLayout";
import Loader from "../../../shared/components/Loader/Loader";
import NeedSupportModal from "modules/shared/components/NeedSupportModal/NeedSupportModal";

const validationSchema = yup.object().shape({
  login: yup.string().label("Login").email().required(),
  password: yup.string().label("Password").required(),
});

const mapStateToProps = ({ session }: RootState) => ({
  session,
});

const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  login: (login: string, password: string) =>
    dispatch(loginUser(login, password)),
  loginWithSso: () => dispatch(loginWithSso()),
  loginWithToken: (token: string) => dispatch(loginWithToken(token)),
});

type LoginProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const Login: React.FC<LoginProps> = ({
  login,
  loginWithSso,
  loginWithToken,
}) => {
  const [loginResult, setLoginResult] = useState<string>("");

  const afterPasswordReset =
    queryString.parse(document.location.search)["passwordReset"] === "true";
  const afterActivation =
    queryString.parse(document.location.search)["afterActivation"] === "true";

  const { token } = useParams<{ token: string }>();

  useEffect(() => {
    if (token !== null) {
      loginWithToken(token);
    }
  }, [token, loginWithToken]);

  const formik = useFormik({
    initialValues: {
      login: "",
      password: "",
    },
    onSubmit: (values) => {
      setLoginResult("");
      login(values.login, values.password).then(
        (reponse) => {
          if (reponse === undefined) {
            setLoginResult("Failure");
          }
        },
        (error) => {}
      );
    },
    validationSchema: validationSchema,
  });

  const [showLoading, setShowLoading] = useState<boolean>(false);
  useEffect(() => {
    let timer = setTimeout(() => setShowLoading(true), 50);
    return () => {
      clearTimeout(timer);
    };
  }, []);
  // console.log(showLoading);

  return (
    <AuthLayout>
      <Loader
        className={showLoading ? "off" : "on"}
        color="multicolors"
        size="large"
      />

      <div className="page page-auth">
        <div className="page_inner">
          <div className="box box-auth">
            <div className="logo">
              <svg
                className={`${styles["logo"]} svg svg-logo svg-logo-meridiam xl`}
              >
                <use xlinkHref="#logoMeridiam"></use>
              </svg>
            </div>
            <div className="box_inner">
              <div className="box__head">
                <h1>
                  <small>Meridiam</small>
                  <span>SDG &amp; Investing Tools Platform</span>
                </h1>
              </div>
              <div className="box__content">
                <form className="form form-auth" onSubmit={formik.handleSubmit}>
                  <div className="form_inner">
                    <div className="form_group">
                      <div className="form-row row row-block">
                        {/* <label htmlFor="login"><span>Login</span></label> */}
                        <input
                          autoComplete="username"
                          id="login"
                          name="login"
                          type="text"
                          placeholder="Login"
                          onChange={formik.handleChange}
                          value={formik.values.login}
                        />
                        {formik.submitCount > 0 && formik.errors?.login && (
                          <div className="form-error">
                            <span>{formik.errors.login}</span>
                          </div>
                        )}
                      </div>
                      <div className="form-row row row-block">
                        {/* <label htmlFor="passsword"><span>Password</span></label> */}
                        <input
                          autoComplete="current-password"
                          id="password"
                          name="password"
                          type="password"
                          placeholder="Password"
                          onChange={formik.handleChange}
                          value={formik.values.password}
                        />
                        {formik.submitCount > 0 && formik.errors?.password && (
                          <div className="form-error">
                            <span>{formik.errors.password}</span>
                          </div>
                        )}
                      </div>
                      <div className="form-row row row-block row-submit">
                        <button className="btn btn-cta label-external rounded">
                          <span className="btn_icon icon-primary">
                            <i className="fas fa-chevron-right"></i>
                          </span>
                          <span className="btn_label label-primary">
                            Connect
                          </span>
                        </button>
                      </div>
                    </div>

                    {loginResult === "Failure" && (
                      <div className="row row-message">
                        <div className="message error">
                          Wrong login or password, please try again or click on
                          "lost password?"
                        </div>
                      </div>
                    )}

                    {/* DO NOT DELETE -- AZURE CONNECT */}
                    {/* <div className="spacer">
                      <span>Or connect with</span>
                    </div>
                    <div className="form_group">
                      <div className="form-row row row-block row-button">
                        <button
                          type="button"
                          className="btn btn-azure"
                          onClick={loginWithSso}
                        >
                          <span className="btn_icon">
                            <i className="fab fa-microsoft"></i>
                          </span>
                          <span className="btn_label">Azure connect</span>
                        </button>
                      </div>
                    </div> */}
                  </div>
                </form>
              </div>

              <div className="box__foot" data-theme="ocean">
                <ul>
                  <li>
                    <Link to="/auth/forgot-password">
                      <a className="link link-white underline">
                        <span className="link_label">Lost password ?</span>
                      </a>
                    </Link>
                  </li>
                  <li>
                    <button
                      className="link link-white underline"
                      data-uk-toggle="#modalPageNeedSupport"
                    >
                      <span className="link_label">
                        Need technical support ?
                      </span>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            {afterActivation && (
              <div>
                <span>
                  Successful account activation,
                  <br />
                  please login
                </span>
              </div>
            )}

            {afterPasswordReset && (
              <div>
                <span>
                  Successful password reset,
                  <br />
                  please login with your new password
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
      <NeedSupportModal />
      <ToastContainer />
    </AuthLayout>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);

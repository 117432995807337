import { cloneDeep } from "lodash";
import React from "react";

interface SPVManagerFormReportingProps {
  modifiedCurrProject: any;
  setModifiedCurrProject: any;
  setFormDirty: any;
  formDirty: string[];
}

const SPVManagerFormReporting = ({
  modifiedCurrProject,
  setModifiedCurrProject,
  setFormDirty,
  formDirty,
}: SPVManagerFormReportingProps) => {
  const handleChange = (type: string, value: any) => {
    let newFormDirty: string[] = [];
    if (formDirty.includes(type)) {
      newFormDirty.filter((fd) => fd !== type);
    } else {
      newFormDirty = cloneDeep(formDirty);
      newFormDirty.push(value);
    }
    setFormDirty(newFormDirty);
    let newProject = cloneDeep(modifiedCurrProject);
    newProject[type] =
      type === "concernedByReporting"
        ? value === "false"
          ? true
          : false
        : value;
    setModifiedCurrProject(newProject);
  };

  return (
    <>
      <div className="activate">
        <input
          id="activateReporting"
          type="checkbox"
          value={
            modifiedCurrProject.concernedByReporting !== null
              ? modifiedCurrProject.concernedByReporting
              : false
          }
          checked={
            modifiedCurrProject.concernedByReporting !== null
              ? modifiedCurrProject.concernedByReporting
              : false
          }
          onChange={(e) => handleChange("concernedByReporting", e.target.value)}
        />
        <label htmlFor="activateReporting">Activate reporting</label>
      </div>

      {modifiedCurrProject.concernedByReporting === true && (
        <div className="choose">
          <p>Choose a group</p>
          <div className="choose-container">
            <div className="choose-item">
              <input
                type="radio"
                id="group1"
                name="groupSelected"
                value="group1"
                checked={
                  modifiedCurrProject.reportingGroup === null ||
                  modifiedCurrProject.reportingGroup === "group1"
                }
                onChange={(e) => handleChange("reportingGroup", e.target.value)}
              />
              <label htmlFor="group1">Group 1</label>
            </div>
            <div className="choose-item">
              <input
                type="radio"
                id="group2"
                name="groupSelected"
                value="group2"
                checked={modifiedCurrProject.reportingGroup === "group2"}
                onChange={(e) => handleChange("reportingGroup", e.target.value)}
              />
              <label htmlFor="group2">Group 2</label>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SPVManagerFormReporting;

import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import SurveyEditor from "./screens/SurveyEditor";

const SurveyEditorRoutes = () => {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/survey/dashboard`}>
        <SurveyEditor />
      </Route>
      <Redirect from={`${path}`} to={`${path}/survey/dashboard`} />
    </Switch>
  );
};

export default SurveyEditorRoutes;

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import Loader from "../../shared/components/Loader/Loader";
import "./styles.scss";

import Header from "../../shared/components/Header/Header";
import UsersToolBar from "./UsersToolBar/UsersToolBar";
import UsersList from "../components/UsersList/UsersList";

import { getAllProjects } from "modules/shared/services/project.service";

import { RootState, ThunkDispatch } from "models/store.models";
import { User } from "models/user.models";

import "react-toastify/dist/ReactToastify.css";
import { Project } from "models/project.model";
import UserSettingsContainer from "../components/UserSettingsContainer/UserSettingsContainer";
import { deleteUser, getAllUsers } from "../services/user-management.service";
import { cloneDeep } from "lodash";
import { getAllInvestors } from "modules/limited-partners/services/limited-partners.service";
import { Investor } from "modules/limited-partners/models/investor.model";

const toastConfig = {
  position: "top-right" as any,
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
};

const mapStateToProps = ({ session }: RootState) => ({
  accessToken:
    session.sessionData && session.sessionData.accessToken
      ? session.sessionData.accessToken
      : "",
  editor: session?.sessionData?.userData,
});

const mapDispatchToProps = (dispatch: ThunkDispatch) => ({});

type UsersProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const Users: React.FC<UsersProps> = ({ accessToken, editor }) => {
  const [users, setUsers] = useState<User[]>([]);
  const [currUser, setCurrUser] = useState<User | null>(null);
  const [menuUserItemLength, setMenuUserItemLength] = useState<number>(0);
  const [projects, setProjects] = useState<Project[]>([]);
  const [investors, setInvestors] = useState<Investor[]>([]);
  const [showLoading, setShowLoading] = useState<boolean>(false);

  useEffect(() => {
    getAllUsers(accessToken).then((result) => {
      setUsers(result);
    });

    getAllProjects(accessToken).then((result) => {
      // filter to get only one project by projectCode
      let projectMap: any = {};
      let projectsList: Project[] = [];
      result
        .sort((a: Project, b: Project) => (a.year > b.year ? -1 : 1))
        .forEach((p) => {
          if (!projectMap[p.projectCode]) {
            projectMap[p.projectCode] = p;
            projectsList.push(p);
          }
        });
      setProjects(projectsList);
    });

    getAllInvestors(accessToken).then((result) => {
      setInvestors(result.allInvestor);
    });

    let timer = setTimeout(() => setShowLoading(true), 50);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const handleDeleteUser = () => {
    if (currUser && currUser.id && !!editor && currUser.id !== editor.id) {
      // only a technical admin can delete a technical admin.
      if (
        currUser.platformRole === "technical-admin" &&
        editor.platformRole !== "technical-admin"
      ) {
        return;
      }

      deleteUser(accessToken, currUser.id).then(() => {
        let newListUsers = cloneDeep(users);
        newListUsers = newListUsers.filter((u: User) => {
          if (!!u.id && !!currUser.id && u.id !== currUser.id) {
            return u;
          }
        });
        setUsers(newListUsers);
        toast.success("The user have been deleted.", toastConfig);
        setCurrUser(null);
      });
    }
  };

  const selectUser = (newUser:User) => {
    setCurrUser(newUser);
    window.scrollTo(0, 0);
  }

  return (
    <>
      <Loader
        className={showLoading ? "off" : "on"}
        color="multicolors"
        size="large"
      />

      <div className="header-wrapper" data-uk-sticky="media:768;">
        <Header
          titleContext="User management"
          colorContext={"seaLight"}
          classNameToolbarTop="user"
          menuUserLength={menuUserItemLength}
          setMenuUserItemLength={(val: number) => setMenuUserItemLength(val)}
          tool={`users-management`}
        />
      </div>

      <UsersToolBar currentTool={"users"} />

      <div className={`page page-user`} data-theme="seaLight">
        <div className="page_inner">
          {!!currUser && currUser !== null && !!editor && !!accessToken ? (
            <UserSettingsContainer
              currUser={currUser}
              setCurrUser={setCurrUser}
              editor={editor}
              accessToken={accessToken}
              confirmDeleteUser={handleDeleteUser}
              projects={projects}
              investors={investors}
              setUsers={setUsers}
              users={users}
            />
          ) : (
            <>
              <div className="page_header">
                <div className="page_header__title">
                  <span className="title">Users management</span>
                </div>
              </div>

              {accessToken && users.length > 0 && projects.length > 0 && (
                <UsersList
                  accessToken={accessToken}
                  users={users}
                  setCurrUser={selectUser}
                />
              )}
            </>
          )}
        </div>
      </div>

      <ToastContainer />
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);

import React from "react";
import UIkit from "uikit";
import { Country } from "models/country.model";
import { Currency } from "models/currency.model";
import { cloneDeep } from "lodash";
import FundListItem from "../FundListItem/FundListItem";
import subSectorListJson from "./subSectorList.json";
import { MeridiamFund } from "modules/limited-partners/models/meridiam-fund.model";

interface SPVManagerFormProjectInfosProps {
  modifiedCurrProject: any;
  isEditMode: boolean;
  setModifiedCurrProject: any;
  currenciesList: Currency[];
  countriesList: Country[];
  formDirty: string[];
  setFormDirty: any;
  setIsMaterialityAvailable: any;
  usersCount: number;
  currYear: any;
  meridiamFundList:MeridiamFund[]
}

const SPVManagerFormProjectInfos = ({
  isEditMode,
  modifiedCurrProject,
  setModifiedCurrProject,
  currenciesList,
  countriesList,
  formDirty,
  setFormDirty,
  setIsMaterialityAvailable,
  usersCount,
  currYear,
  meridiamFundList
}: SPVManagerFormProjectInfosProps) => {
  let subSectorListData: any = subSectorListJson.find(
    (slj: any) => slj.year === currYear
  );
  let subSectorList = subSectorListData?.data;

  const handleChange = (
    type: string,
    value: string,
    meridiamFundCode?: string
  ) => {
    let newProject = cloneDeep(modifiedCurrProject);

    if (
      newProject.meridiamFunds === null ||
      newProject.meridiamFunds === undefined
    ) {
      newProject.meridiamFunds = [];
    }
    if (type === "fundsCommitted" || type === "fundsCommittedCurrency") {
      let fundFound = false;
      !!newProject.meridiamFunds &&
        newProject.meridiamFunds.length > 0 &&
        newProject.meridiamFunds.forEach((mF: any) => {
          if (mF.meridiamFund.fundId === meridiamFundCode) {
            mF[type] = value;
            fundFound = true;
          }
        });
      if (fundFound === false) {
        let merFund = meridiamFundList.find(
          (mFL) => mFL.fundId === meridiamFundCode
        );
        newProject.meridiamFunds.push({
          fundsCommitted: type === "fundsCommitted" ? value : "",
          fundsCommittedCurrency:
            type === "fundsCommittedCurrency" ? value : "",
          meridiamFund: {
            fundName: merFund ? merFund.fundName : merFund,
            fundId: meridiamFundCode,
          },
        });
      }
      setFormDirty((formDirty: string[]) => [...formDirty, meridiamFundCode]);
    } else {
      newProject[type] = value;
      setFormDirty((formDirty: string[]) => [...formDirty, type]);
    }
    setModifiedCurrProject(newProject);
    setIsMaterialityAvailable(false);
  };

  const filteredCurrencies = () => {
    let start = currenciesList.filter((elm) => {
      return (
        "USD" === elm.currency ||
        "EUR" === elm.currency ||
        "GBP" === elm.currency ||
        "JPY" === elm.currency
      );
    });
    let end = currenciesList.filter((elm) => {
      return (
        "USD" !== elm.currency &&
        "EUR" !== elm.currency &&
        "GBP" !== elm.currency &&
        "JPY" !== elm.currency
      );
    });
    return start.concat(end);
  };

  const handleSimpleChange = (name:string, value:boolean) => {
    if(value === true){
      setIsMaterialityAvailable(true);
    } else {
      setIsMaterialityAvailable(false);
    }
    let newP = { ...modifiedCurrProject };
    newP.hasSurvey = value;
    setFormDirty((formDirty: string[]) => [...formDirty, name]);
    setModifiedCurrProject(newP);
  };

  return (
    <>
    <div className="row row-input">
      <div className="selector">
        <p className="selector-label">Does the SPV is concerned by survey's campaign ? *</p>
        <div className="select">
          <button
            className={`btn btn-select btn-primary full btn-rounded ${
              formDirty.find((i) => i === "hasSurvey") ? "is-modified" : ""
            }`}
            type="button"
          >
            <span className="btn_label">
              {modifiedCurrProject.hasSurvey === true
                ? "Yes"
                : "No"}
            </span>
            <span className="btn_icon"></span>
          </button>
          <div
            className="drop drop-select hasSurvey"
            data-uk-dropdown="pos: bottom-left; mode: click; delay-hide: 100"
          >
            <ul className="group lvl0 uk-nav uk-dropdown-nav">
              <li
                className="item"
                data-uk-toggle="target: .hasSurvey"
                onClick={() => {
                  handleSimpleChange("hasSurvey", true);
                  UIkit.dropdown(".hasSurvey").hide();
                }}
              >
                <span className="clickable">Yes</span>
              </li>
              <li
                className="item"
                data-uk-toggle="target: .hasSurvey"
                onClick={() => {
                  handleSimpleChange("hasSurvey", false);
                  UIkit.dropdown(".hasSurvey").hide();
                }}
              >
                <span className="clickable">No</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

      <div className="row row-input">
        <div className="selector">
          <p className="selector-label">Choose a stage *</p>
          <div className="select">
            <button
              className={`btn btn-select btn-primary full btn-rounded ${
                formDirty.find((i) => i === "stage") ? "is-modified" : ""
              }`}
              type="button"
            >
              <span className="btn_label">
                {!!modifiedCurrProject.stage
                  ? modifiedCurrProject.stage
                  : "-----"}
              </span>
              <span className="btn_icon"></span>
            </button>
            <div
              className="drop drop-select stage"
              data-uk-dropdown="pos: bottom-left; mode: click; delay-hide: 100"
            >
              <ul className="group lvl0 uk-nav uk-dropdown-nav">
                <li
                  className="item"
                  data-uk-toggle="target: .stage"
                  onClick={() => {
                    handleChange("stage", "Both");
                    UIkit.dropdown(".stage").hide();
                  }}
                >
                  <span className="clickable">Both</span>
                </li>
                <li
                  className="item"
                  data-uk-toggle="target: .stage"
                  onClick={() => {
                    handleChange("stage", "Under Construction");
                    UIkit.dropdown(".stage").hide();
                  }}
                >
                  <span className="clickable">Under Construction</span>
                </li>
                <li
                  className="item"
                  data-uk-toggle="target: .stage"
                  onClick={() => {
                    handleChange("stage", "In Operation");
                    UIkit.dropdown(".stage").hide();
                  }}
                >
                  <span className="clickable">In Operation</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row row-input">
        <div className="selector">
          <p className="selector-label">Choose a sub sector *</p>
          <div className="select">
            <button
              type="button"
              className={`btn btn-select btn-primary full btn-rounded ${
                formDirty.find((i) => i === "type") ? "is-modified" : ""
              }`}
            >
              <span className="btn_label">
                {!!modifiedCurrProject.type
                  ? !!subSectorList &&
                    !!subSectorList &&
                    subSectorList.length > 0 &&
                    subSectorList.filter(
                      (i: any) => i?.value === modifiedCurrProject.type
                    )[0].label
                  : "—----"}
              </span>
              <span className="btn_icon"></span>
            </button>
            <div
              className="drop drop-select subsector"
              data-uk-dropdown="pos: bottom-left; mode: click; delay-hide: 100"
            >
              <ul className="group lvl0 uk-nav uk-dropdown-nav">
                {!!subSectorList &&
                  !!subSectorList &&
                  subSectorList.length > 0 &&
                  subSectorList.map((subsector: any, idx: number) => {
                    return (
                      <li
                        className="item"
                        data-uk-toggle="target: .subsector"
                        key={`subsectorK-${idx}`}
                        onClick={() => {
                          handleChange("type", subsector?.value);
                          UIkit.dropdown(".subsector").hide();
                        }}
                      >
                        <span className="clickable">{subsector?.label}</span>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row row-input">
        <div className="selector">
          <p className="selector-label">Choose a country *</p>
          <div className="select">
            <button
              className={`btn btn-select btn-primary full btn-rounded ${
                formDirty.find((i) => i === "countryCode") ? "is-modified" : ""
              }`}
              type="button"
            >
              <span className="btn_label">
                {!!modifiedCurrProject.countryCode
                  ? modifiedCurrProject.countryCode
                  : "-----"}
              </span>
              <span className="btn_icon"></span>
            </button>
            <div
              className="drop drop-select country-code"
              data-uk-dropdown="pos: bottom-left; mode: click; delay-hide: 100"
            >
              <ul className="group lvl0 uk-nav uk-dropdown-nav">
                {!!countriesList &&
                  countriesList.length > 0 &&
                  countriesList
                    .sort((a: Country, b: Country) =>
                      a.name.localeCompare(b.name)
                    )
                    .map((country: Country, idx: number) => {
                      return (
                        <li
                          className="item"
                          data-uk-toggle="target: .country-code"
                          key={`countryK-${idx}`}
                          onClick={() => {
                            handleChange("countryCode", country.code);
                            UIkit.dropdown(".country-code").hide();
                          }}
                        >
                          <span className="clickable">{country.name}</span>
                        </li>
                      );
                    })}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row row-input">
        <div className="selector">
          <p className="selector-label">Choose a status *</p>
          <div className="select">
            <button
              className={`btn btn-select btn-primary full btn-rounded ${
                formDirty.find((i) => i === "investmentPhase")
                  ? "is-modified"
                  : ""
              }`}
              type="button"
            >
              <span className="btn_label">
                {!!modifiedCurrProject.investmentPhase
                  ? "9 - Financial Close" ===
                    modifiedCurrProject.investmentPhase
                    ? "Financial Close"
                    : modifiedCurrProject.investmentPhase
                  : "-----"}
              </span>
              <span className="btn_icon"></span>
            </button>
            <div
              className="drop drop-select status"
              data-uk-dropdown="pos: bottom-left; mode: click; delay-hide: 100"
            >
              <ul className="group lvl0 uk-nav uk-dropdown-nav">
                <li
                  className="item"
                  data-uk-toggle="target: .status"
                  onClick={() => {
                    handleChange("investmentPhase", "9 - Financial Close");
                    UIkit.dropdown(".status").hide();
                  }}
                >
                  <span className="clickable">Financial Close</span>
                </li>
                <li
                  className="item"
                  data-uk-toggle="target: .status"
                  onClick={() => {
                    handleChange("investmentPhase", "In screening");
                    UIkit.dropdown(".status").hide();
                  }}
                >
                  <span className="clickable">In screening</span>
                </li>
                <li
                  className="item"
                  data-uk-toggle="target: .status"
                  onClick={() => {
                    handleChange("investmentPhase", "Abandoned");
                    UIkit.dropdown(".status").hide();
                  }}
                >
                  <span className="clickable">Abandoned</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="separator"></div>

      <div className="funds-container">
        <ul className="list">
          <li className="row filters">
            <div className="fund">
              <i>Fund</i>
            </div>
            <div className="fund-committed">
              <i>Fund committed (millions)</i>
            </div>
            <div className="currency">
              <i>Fund committed currency</i>
            </div>
          </li>
          {!!meridiamFundList &&
            meridiamFundList.length > 0 &&
            meridiamFundList.map((mF: any, idx: number) => {
              return (
                <FundListItem
                  key={`fund-${idx}`}
                  fund={mF}
                  modifiedCurrProject={modifiedCurrProject}
                  currenciesList={filteredCurrencies()}
                  handleChange={handleChange}
                  formDirty={formDirty}
                  setFormDirty={setFormDirty}
                />
              );
            })}
        </ul>
      </div>

      <div className="row row-input">
        <div className="selector">
          <p className="selector-label">Choose project's currency</p>
          <div className="select">
            <button
              className={`btn btn-select btn-primary full btn-rounded ${
                formDirty.find((i) => i === "currency") ? "is-modified" : ""
              }`}
              type="button"
            >
              <span className="btn_label">
                {!!modifiedCurrProject.currency
                  ? modifiedCurrProject.currency
                  : "-----"}
              </span>
              <span className="btn_icon"></span>
            </button>
            <div
              className="drop drop-select project-currency"
              data-uk-dropdown="pos: bottom-left; mode: click; delay-hide: 100"
            >
              <ul className="group lvl0 uk-nav uk-dropdown-nav">
                {!!currenciesList &&
                  currenciesList.length > 0 &&
                  filteredCurrencies().map(
                    (currency: Currency, idx: number) => {
                      return (
                        <li
                          className="item"
                          data-uk-toggle="target: .project-currency"
                          key={`itemK-${idx}`}
                          onClick={() => {
                            handleChange("currency", currency.currency);
                            UIkit.dropdown(".project-currency").hide();
                          }}
                        >
                          <span className="clickable">{currency.currency}</span>
                        </li>
                      );
                    }
                  )}
              </ul>
            </div>
          </div>
        </div>
      </div>

      {isEditMode && (
        <div className="row row-input">
          <p className="selector-label">
            Add contributors to this project's survey
          </p>
          <button
            className="btn btn-select btn-primary btn-users"
            type="button"
            data-uk-toggle="target: #teamModal;"
            disabled={modifiedCurrProject.hasSurvey === false}
          >
            <span className="btn-label">
              {modifiedCurrProject.hasSurvey === false 
              ?
                "not concerned"
              :
                <>
                  {usersCount} user{usersCount > 1 && "s"}
                </>
              }
              
            </span>
            <span className="btn-icon">
              <i className={`fas fa-plus`}></i>
            </span>
          </button>
        </div>
      )}
    </>
  );
};

export default SPVManagerFormProjectInfos;

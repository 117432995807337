import React, { FC, useEffect, useState } from "react";
import "./styles.scss";
import Dropdown from "../../../shared/components/Ui/Filters/Dropdown/Dropdown";
import DropdownCountry from "../../../shared/components/Ui/Filters/Dropdown/DropdownCountry";
import FilterMapCountry from "../../../shared/components/Ui/Filters/Map/Map";

import {
  AvailableFilters,
  CurrFilter,
} from "../../models/available-filters.model";

type Props = {
  filter: AvailableFilters;
  currFilter: CurrFilter;
  setCurrFilter: (arg: CurrFilter) => void;
  menuUserLength?: number;
  availableFilters: AvailableFilters;
  resetFilter: any;
};

const FilterSection: FC<Props> = ({
  filter,
  currFilter,
  setCurrFilter,
  menuUserLength,
  availableFilters,
  resetFilter,
}) => {
  const [countryCount, setCountryCount] = useState<number | null>(null);

  useEffect(() => {
    if (typeof availableFilters !== "undefined") {
      if (currFilter.location[0] === "all") {
        setCountryCount(null);
      } else {
        let count = 0;
        currFilter.location.forEach((l) => {
          if (
            availableFilters.filter.countries.find((c) => c.countryCode === l)
          ) {
            count++;
          }
        });
        setCountryCount(count);
      }
    }
  }, [availableFilters]);

  const setLocation = (newArr: string[]) => {
    if (typeof currFilter !== "undefined") {
      let newCurrFilter: CurrFilter = { ...currFilter };
      newCurrFilter.location = newArr;
      setCurrFilter(newCurrFilter);
    }
  };

  const setMeridiamFund = (newArr: string[]) => {
    if (typeof currFilter !== "undefined") {
      let newCurrFilter = { ...currFilter };
      newCurrFilter.meridiamFund = newArr;
      setCurrFilter(newCurrFilter);
    }
  };

  return (
    <>
      <div className="toolbar toolbar-secondary toolbar-filters">
        <div className="toolbar_inner" id="toggle-usage" data-uk-sticky="position: bottom">
          <div className="menu">
            <button
              className="btn btn-burger"
              type="button"
              data-uk-toggle="target: #toggle-usage; hidden: false; cls: menu-open;"
            >
              <span className="btn__label">Filters</span>
              <span
                className="btn__icon rotate-center"
                id="toggle-usage"
              ></span>
            </button>
          </div>
          <div
            className="filters filters-main"
            data-uk-sticky="width-element: false;offset: 60;media:768;"
            data-item-length={menuUserLength}
          >
            <div className="filters_inner">
              <div className="filter">
                <div className="filter_child">
                  <div className="filter__label">Filter by fund</div>
                  <Dropdown
                    meridiamFundList={!!filter && !!filter.filter && filter.filter.funds}
                    setMeridiamFund={setMeridiamFund}
                    meridiamFundSelected={currFilter.meridiamFund}
                  />
                </div>
              </div>
              <div className="filter">
                <div className="filter_child">
                  <div className="filter__label">Filter by Location</div>
                  <DropdownCountry countryCount={countryCount} />
                  <FilterMapCountry
                    countries={!!filter && !!filter.filter && filter.filter.countries}
                    location={currFilter.location}
                    setLocation={setLocation}
                  />
                </div>
              </div>

              {(currFilter.location[0] !== "all" ||
                currFilter.meridiamFund[0] !== "all") && (
                <button className="filter-reset" onClick={() => resetFilter()}>
                  <span>
                    Reset all<i className="fas fa-times"></i>
                  </span>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FilterSection;

import React, { useContext, useEffect, useState } from "react";
import { Tab, Tabs } from "@material-ui/core";
import SPVReportingFundList from "modules/hub/components/SPVReportingFundList/SPVReportingFundList";
import SPVReportingProjectList from "modules/hub/components/SPVReportingProjectList/SPVReportingProjectList";
import SPVReportingProjectFilters from "modules/hub/components/SPVReportingProjectFilters/SPVReportingProjectFilters";
import SPVReportingModules from "modules/hub/components/SPVReportingModules/SPVReportingModules";
import DownloadStatuses from "modules/hub/components/Elements/DownloadStatuses";

import "./styles.scss";
import { PermissionContext } from "context/Permission.context";
import { ReportingContext } from "context/Reporting.context";

interface SPVReportingProps {
  fundsList: any;
  projectsList: any;
  documentsList: any;
  indicatorsProjectsList: any;
  setUpdate: any;
}

const SPVReporting = ({
  fundsList,
  projectsList,
  documentsList,
  indicatorsProjectsList,
  setUpdate,
}: SPVReportingProps) => {

  const { projectFilters } = useContext(ReportingContext);

  const { isMeridiamGranted } = useContext(PermissionContext);

  const [tabReportingVal, setTabReportingVal] = useState<number>(1);

  const [projectsCompleted, setProjectsCompleted] = useState<number>(0);

  // const [filters, setFilters] = useState<any>({
  //   fund: { id: "all", name: "All funds" },
  //   doc_type: { id: "all", name: "All documents" },
  //   search: "",
  // });

  useEffect(() => {
    if (!!projectsList && 0 < projectsList.length) {
      setProjectsCompleted(projectsList.filter((p: any) => (!!p.indValidation && !!p.indValidation.isComplete && 0 < p.docCompletion && p.docCompletion === p.totalDocCompletion)).length);
    }
  }, [projectsList]);

  useEffect(() => {
    if('all' !== projectFilters.fund.id){
      setTabReportingVal(2);
    }
  }, [])

  return (
    <>
      <div className="grid grid-tool">
        <div className="grid_inner">
          <div className="main-content bg-white tool-spv-reporting">
            {!!isMeridiamGranted ? 
              <>
                <Tabs value={tabReportingVal} className="tabs-container">
                  <Tab
                    label="See completion by"
                    className="tabs-item title"
                    disabled
                  />{" "}
                  {/* title */}
                  <Tab
                    label="fund"
                    className="tabs-item"
                    onClick={() => setTabReportingVal(1)}
                  />
                  <Tab
                    label="project"
                    className="tabs-item"
                    onClick={() => setTabReportingVal(2)}
                  />
                </Tabs>

                <div className="spv-reporting-content_inner">
                  {/* TAB FUND CONTENT */}
                  <div
                    role="tabpanel"
                    className="funds"
                    hidden={1 !== tabReportingVal}
                  >
                    <div className="options-container">
                      <div className="downloads-row">
                        <button
                          onClick={() => setUpdate(true)}
                          className="btn btn-select btn-primary"
                          type="button"
                          title="Refresh data"
                        >
                          <span className="btn-label">Refresh data</span>
                          <span className="btn-icon">
                            <i className={`fas fa-sync`}></i>
                          </span>
                        </button>
                      </div>
                    </div>
                    <SPVReportingFundList funds={fundsList} />
                    <DownloadStatuses
                      documentsList={documentsList}
                      projectsList={projectsList}
                      indicatorsProjectsList={indicatorsProjectsList}
                    />
                  </div>

                  {/* TAB PROJECT CONTENT */}
                  <div
                    role="tabpanel"
                    className="projects"
                    hidden={2 !== tabReportingVal}
                  >
                    <SPVReportingProjectFilters
                      funds={!!fundsList ? [ ...[{ id: "all", name: "All funds" }], ...fundsList.map((fl: any) => ({ id: fl.code, name: fl.name }))] : [{ id: "all", name: "All funds" }]}
                      projectsCompletion={{
                        completed: projectsCompleted,
                        total: projectsList.length,
                      }}
                    />
                    <SPVReportingProjectList
                      documentsList={documentsList}
                      projects={projectsList}
                    />
                    <DownloadStatuses
                      documentsList={documentsList}
                      projectsList={projectsList}
                      indicatorsProjectsList={indicatorsProjectsList}
                    />
                    
                  </div>
                </div>

                <SPVReportingModules 
                  indicatorsProjectsList={indicatorsProjectsList}
                />
              </>
              :
              <>
                {/* <h2 className="subtitle">PROJECT COMPLETION</h2> */}
                <h2 className="subtitle">MY PROJECTS</h2>
                <div className="spv-reporting-content_inner">
                  <SPVReportingProjectList
                    documentsList={documentsList}
                    projects={projectsList}
                  />
                  {/* <div className="downloads-container">
                    <div className="downloads-row">
                      <a href="templates.zip" download>
                        <button
                          className="btn btn-select btn-primary"
                          type="button"
                        >
                          <span className="btn-label">
                            Download all templates
                          </span>
                          <span className="btn-icon">
                            <i className={`fas fa-download`}></i>
                          </span>
                        </button>
                      </a>
                    </div>
                  </div> */}
                </div>
              </>
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default SPVReporting;

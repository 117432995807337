import React from "react";
import "./styles.scss";

type Props = {
  submitConfirmation: () => void;
  modalId: string;
  texts: {
    title: string;
    content: string;
    action: string;
  };
};

const ModalConfirmation: React.FC<Props> = ({
  modalId,
  texts,
  submitConfirmation,
}) => {
  const outputClose = () => {
    // console.log("output close");
  };

  return (
    <div
      id={modalId}
      className="own-modal modal-confirmation"
      data-uk-modal="stack: true; container:false;"
    >
      <div className="uk-modal-dialog">
        <div className="own-modal-content">
          <button
            className="btn btn-close label-external"
            type="button"
            data-uk-toggle={`target: #${modalId};`}
            onClick={outputClose}
          >
            <span className="btn-label">Close</span>
            <span className="btn-icon circle">
              <i className="fas fa-times"></i>
            </span>
          </button>
          <div className="uk-modal-header mode-alert">
            <div className="modal-icon">
              <i className="fas fa-user"></i>
            </div>
            <h2 className="upp colored">
              <strong
                dangerouslySetInnerHTML={{ __html: texts.title }}
              ></strong>
            </h2>
            <h2 className="upp">
              <strong>Are you sure?</strong>
            </h2>
          </div>
          <div className="uk-modal-body">
            <div className="text">
              <p dangerouslySetInnerHTML={{ __html: texts.content }}></p>
            </div>
            <div className="form">
              <div className="wrapp-cta">
                <button
                  className="btn btn-primary btn-rounded"
                  data-uk-toggle={`target: #${modalId};`}
                  onClick={submitConfirmation}
                >
                  <span className="btn-label">Yes, {texts.action}</span>
                </button>
              </div>
              <div className="wrapp-link">
                <button
                  className="link link-primary underline"
                  data-uk-toggle={`target: #${modalId};`}
                  onClick={outputClose}
                >
                  <span className="link-label ">
                    <b>No</b>
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div className="uk-modal-footer"></div>
        </div>
      </div>
    </div>
  );
};

export default ModalConfirmation;

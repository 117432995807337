import React from "react";
import "./styles.scss";

const Loader: React.FC<{
  className: string;
  color: "white" | "blue" | "multicolors";
  size: "large" | "small";
}> = ({ className, color, size }) => {
  const colorDeepblue = "#102d40";
  let height;
  let position;
  let bottom;
  let right;
  let background;

  switch (size) {
    case "large":
      height = 100 + "vh";
      position = "fixed";
      bottom = 0;
      right = 0;
      background = colorDeepblue;
      break;

    case "small":
      height = 100 + "px";
      position = "block";
      bottom = "inherit";
      right = "inherit";
      background = "transparent";
      break;
  }

  const sizeStyle = {
    position: position,
    height: height,
    right: right,
    bottom: bottom,
  } as React.CSSProperties;

  const backgroundStyle = {
    backgroundColor: background,
  };

  return (
    <div
      className={"loader loader-main " + className}
      data-print="no"
      data-size={size}
      style={sizeStyle}
    >
      <div className="loader_inner" style={backgroundStyle}>
        <div className="loader__anim" data-color={color}></div>
      </div>
    </div>
  );
};

export default Loader;

import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";
import { GeographicalOverview } from "modules/limited-partners/models/allocation-result.model";
import "./styles.scss";
import GeographicalOverviewChart from "../GeographicalOverviewChart/GeographicalOverviewChart";


type PropsSection = {
  year: number;
  geographicalOverview: GeographicalOverview[] | undefined,
  investorType:string
};

const GeographicalOverviewSection: React.FC<PropsSection> = ({
  year,
  geographicalOverview,
  investorType
}) => {
  let windowWidth: number;
  windowWidth = window.innerWidth;
  const geographicalOverviewChartRef = useRef<GeographicalOverviewChart>(null);
  const [sumContinents, setSumContinents] = useState<any>([]);
  const [worldView, setWorldView] = useState(true);   // Gestion de l'apparition du title ou non
  const [zoomLevel, setZoomLevel] = useState<number>(0);

  useEffect( () => {
    let newSumContinents = [
      {
        sumContinent: _(geographicalOverview)
          .filter((item: GeographicalOverview) => item.continent === "africa")
          .sumBy((item: GeographicalOverview) => item.total),
        totalCountries: _(geographicalOverview)
          .filter((item) => item.continent === "africa")
          .size(),
        countries: !!geographicalOverview
          ? geographicalOverview
            .filter((item) => item.continent === "africa")
            .sort((a, b) => b.total - a.total)
          : [],
        label: "Africa",
        slug: "africa",
      },
      {
        sumContinent: _(geographicalOverview)
          .filter((item: GeographicalOverview) => item.continent === "asia")
          .sumBy((item: GeographicalOverview) => item.total),
        totalCountries: _(geographicalOverview)
          .filter((item) => item.continent === "asia")
          .size(),
        countries: !!geographicalOverview
          ? geographicalOverview
            .filter((item) => item.continent === "asia")
            .sort((a, b) => b.total - a.total)
          : [],
        label: "Asia",
        slug: "asia",
      },
      {
        sumContinent: _(geographicalOverview)
          .filter((item: GeographicalOverview) => item.continent === "europe")
          .sumBy((item: GeographicalOverview) => item.total),
        totalCountries: _(geographicalOverview)
          .filter((item) => item.continent === "europe")
          .size(),
        countries: !!geographicalOverview
          ? geographicalOverview
            .filter((item) => item.continent === "europe")
            .sort((a, b) => b.total - a.total)
          : [],
        label: "Europe",
        slug: "europe",
      },
      {
        sumContinent: _(geographicalOverview)
          .filter(
            (item: GeographicalOverview) => item.continent === "south-america"
          )
          .sumBy((item: GeographicalOverview) => item.total),
        totalCountries: _(geographicalOverview)
          .filter((item) => item.continent === "south-america")
          .size(),
        countries: !!geographicalOverview
          ? geographicalOverview
            .filter((item) => item.continent === "south-america")
            .sort((a, b) => b.total - a.total)
          : [],
        label: "Latina America",
        slug: "south-america",
      },
      {
        sumContinent: _(geographicalOverview)
          .filter(
            (item: GeographicalOverview) => item.continent === "north-america"
          )
          .sumBy((item: GeographicalOverview) => item.total),
        totalCountries: _(geographicalOverview)
          .filter((item) => item.continent === "north-america")
          .size(),
        countries: !!geographicalOverview
          ? geographicalOverview
            .filter((item) => item.continent === "north-america")
            .sort((a, b) => b.total - a.total)
          : [],
        label: "North America",
        slug: "north-america",
      },
      {
        sumContinent: _(geographicalOverview)
          .filter((item: GeographicalOverview) => item.continent === "oceania")
          .sumBy((item: GeographicalOverview) => item.total),
        totalCountries: _(geographicalOverview)
          .filter((item) => item.continent === "oceania")
          .size(),
        countries: !!geographicalOverview
          ? geographicalOverview
            .filter((item) => item.continent === "oceania")
            .sort((a, b) => b.total - a.total)
          : [],
        label: "Oceania",
        slug: "oceania",
      },
    ].sort((a, b) => b.sumContinent - a.sumContinent);
    setSumContinents(newSumContinents);
  }, [geographicalOverview]);
  
  useEffect( () => {
    return () => {
      setWorldView(true);
      setZoomLevel(0);
    }
  }, []);

  const resetChartZoom = () => {
    setZoomLevel(0);
    geographicalOverviewChartRef.current?.chartZoomInit(1);
  }

  const chartCenterPosition = () => {
    setZoomLevel(0);
    geographicalOverviewChartRef.current?.chartZoomCenter();
  }

  const chartZoom = (direction:string) => {
    geographicalOverviewChartRef.current?.chartZoom(direction);
  }

  return (
    <div
      id="cmpGeographicalOverview"
      className={
        `tool tool_lp tool_geographical-overview ` +
        (windowWidth < 768 ? "mode-smartphone" : "mode-desktop")
      }
    >
      <div
        id="chartWrapper"
        className="tool_lp_inner"
      >
        <>
          {/* NO CHART, JUST BUBLE SIZE WITH CSS */}
          {geographicalOverview && windowWidth < 768 ? (
            <>
              {worldView && (
                <div className="tool__legends top">
                  <div className="legend top">
                    <span className="legend_label">
                      <i>Click on region to view countries</i>
                    </span>
                  </div>
                </div>
              )}
              <div className="tool__title">
                <h2 className="white">
                  Project's commitment
                  <br />
                  by geography
                </h2>
                <h4 className="clr-white">
                  <small>As of Q4</small> {year}
                  {/* {investorType === "gigf" ? (year+1) : year} */}
                </h4>
              </div>
              <div className="tool__legends">
                <div className="legend with-icon">
                  <span className="legend_icon">
                    <svg className="ui ui-svg ui-legend-size">
                      <use xlinkHref="#legendSize"></use>
                    </svg>
                  </span>
                  <span className="legend_label">
                    Bubble size = number of commitments
                  </span>
                </div>

                {geographicalOverview.length === 0 &&
                  <p className="no-data-available white">No data available.</p>
                }
              </div>

              <div className="tool__content">
                <div className="cmp cmp-chart chart-own-bubble">
                  <div className="inner-chart">
                    <div className="chart">
                      <div className="chart_inner">
                        <div
                          className="slideshow"
                          data-uk-slideshow="animation: fade; draggable: false; autoplay: false;"
                        >
                          <ul className="slideshow_inner uk-slideshow-items">
                            <li className="slide slide-parent">
                              {sumContinents.map((continent:any, index:number) => {
                                return (
                                  <div
                                    className="list_item list-regions"
                                    key={`continent_${continent.slug}`}
                                    id={"dataContinent" + (index + 1)}
                                    onClick={() => setWorldView(false)}
                                  >
                                    {continent.totalCountries > 0 ? (
                                      <>
                                        <div
                                          className={
                                            "bubble bubble-regions bubble-" +
                                            (index + 1)
                                          }
                                          data-uk-slideshow-item={index + 1}
                                        >
                                          <div className="bubble_inner">
                                            <div className="data_wrapper">
                                              <div className="data data_value">
                                                <span>
                                                  {continent.sumContinent.toFixed(
                                                    1
                                                  )}
                                                </span>
                                              </div>
                                              <div className="data data_name">
                                                <span>{continent.label}</span>
                                              </div>
                                              <div className="data data_subindicator">
                                                <span>
                                                  ({continent.totalCountries}{" "}
                                                  countr
                                                  {continent.totalCountries > 1
                                                    ? "ies"
                                                    : "y"}
                                                  )
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                );
                              })}
                            </li>
                            {sumContinents.map((continent:any, index:number) => {
                              return (
                                <li className="slide" key={`slidkid-${index}`}>
                                  <div className="uk-flex uk-flex-middle uk-flex-center">
                                    <button
                                      className="btn btn__circle btn__map-mobile"
                                      data-uk-slideshow-item="0"
                                      onClick={() => setWorldView(true)}
                                    >
                                      <span className="btn__icon">
                                        <i className="fas fa-arrow-left"></i>{" "}
                                      </span>
                                      <span className="btn__label">
                                        Back to regions
                                      </span>
                                    </button>
                                  </div>
                                  <div className="title title_country">
                                    <span>{continent.label}</span>
                                  </div>
                                  {continent.countries.map((c:any, indexC:number) => (
                                    <div
                                      className={
                                        "bubble bubble-countries bubble-" +
                                        (indexC + 1)
                                      }
                                      key={`country_${c.countryCode}`}
                                    >
                                      <div className="bubble_inner">
                                        <div className="data_wrapper">
                                          <div className="data data_value">
                                            <span>{c.total.toFixed(1)}</span>
                                          </div>
                                          <div className="data data_name">
                                            <span>{c.countryName}</span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            // MAP HIGHTCHART
            <>
              <button
                className="btn btn__circle btn__map-continent"
                onClick={() => {
                  resetChartZoom(); 
                }}
              >
                <span className="btn__icon">
                  <i className="fas fa-globe-europe"></i>
                </span>
                <span className="btn__label">Back to regions</span>
              </button>

              <div id="remoteGeographicalOverview" className="tool__remote">
                <div className="remote_inner">

                  <div className="remote__toggle">
                    <button
                      className="link link-toggle"
                      data-uk-toggle="target: #remoteGeographicalOverview; cls: remote-open;"
                    >
                      <span className="toggle_label_on">
                        Hide panel{" "}
                        <i className="far fa-chevron-circle-down"></i>
                      </span>
                      <span className="toggle_label_off">
                        Show panel <i className="far fa-chevron-circle-up"></i>
                      </span>
                    </button>
                  </div>

                  {/* ZOOM */}
                  <div className="tool__remote__chart">
                    <div className="remote__chart_inner">
                      <button
                        className="btn btn__circle btn__remote btn__no-text btn__map-center"
                        onClick={() => {
                          chartCenterPosition();
                        }}
                      >
                        <span className="btn__icon">
                          <svg className="ui ui-svg ui-map-center">
                            <use xlinkHref="#centerMap"></use>
                          </svg>
                        </span>
                        <span className="btn__label">Center map on point</span>
                      </button>
                      <button
                        className="btn btn__circle btn__remote btn__no-text btn__map-zoomin"
                        onClick={() => {
                          chartZoom("zoomIn");
                        }}
                        disabled={zoomLevel>1}
                      >
                        <span className="btn__icon">
                          <svg className="ui ui-svg ui-map-center">
                            <use xlinkHref="#zoominMap"></use>
                          </svg>
                        </span>
                        <span className="btn__label">Zoom in</span>
                      </button>
                      <button
                        className="btn btn__circle btn__remote btn__no-text btn__map-zoomout"
                        onClick={() => {
                          chartZoom("zoomOut");
                        }}
                        disabled={zoomLevel<1}
                      >
                        <span className="btn__icon">
                          <svg className="ui ui-svg ui-map-center">
                            <use xlinkHref="#zoomoutMap"></use>
                          </svg>
                        </span>
                        <span className="btn__label">Zoom out</span>
                      </button>
                    </div>
                  </div>

                  <div className="tool__title">
                    <h2 className="white">
                      Project's commitment
                      <br />
                      by geography
                    </h2>
                    <h4 className="clr-white">
                      <small>As of Q4</small> {year}
                      {/* {investorType === "gigf" ? (year+1) : year} */}
                    </h4>
                  </div>
                  <div className="tool__legends">
                    <div className="legend">
                      <span className="legend_icon">
                        <svg className="ui ui-svg ui-legend-size">
                          <use xlinkHref="#legendSize"></use>
                        </svg>
                      </span>
                      <span className="legend_label">
                        Bubble size = number of commitments
                      </span>
                    </div>

                    {geographicalOverview && geographicalOverview.length === 0 &&
                      <p className="no-data-available white">No data available.</p>
                    }
                  </div>

                  <div className="tool__actions" data-print="no">
                    <div className="actions_inner">
                      <div className="export">
                        {/* <DownloadPdfButton
                          visible={false}
                          optionsPrint={{
                            format: "A4",
                            orientation: "portrait",
                            assets: [
                              {
                                folder: "assets/css",
                                files: [
                                  "development" !== process.env.NODE_ENV
                                    ? "print.css"
                                    : "print-local.css",
                                ],
                              },
                            ],
                          }}
                          pdfTitle="Project's commitment by geography"
                          filename="Meridiam-geographical-overview"
                          selector="#cmpGeographicalOverview"
                          selectorHead="#cmpPdfHead"
                          selectorFoot="#cmpPdfLegends"
                          mode="link"
                          label="Download"
                          labelInside=".pdf"
                          icon="fas fa-download"
                          itemsList={[]}
                          setIsPrint={setIsPrint}
                        /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="tool__content">
                <div className="cmp cmp-chart chart-map">
                  <div className="inner-chart">
                    <>
                      {geographicalOverview && (
                        <GeographicalOverviewChart
                          geographicalOverview={geographicalOverview}
                          setZoomLevel={setZoomLevel}
                          zoomLevel={zoomLevel}
                          ref={geographicalOverviewChartRef}
                        />
                      )}
                    </>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      </div>
    </div>
  );
};

export default GeographicalOverviewSection;

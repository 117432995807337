import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import ActivateAccount from "./screens/ActivateAccount/ActivateAccount";
import Login from "./screens/Login/Login";
import LostPassword from "./screens/LostPassword/LostPassword";
import PasswordReset from "./screens/PasswordReset/PasswordReset";

function AuthRoutes() {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/login/:token?`}>
        <Login />
      </Route>
      <Route exact path={`${path}/forgot-password`}>
        <LostPassword />
      </Route>
      <Route exact path={`${path}/reset-password/:token`}>
        <PasswordReset />
      </Route>
      <Route exact path={`${path}/activate-account`}>
        <ActivateAccount />
      </Route>

      <Redirect from={`${path}`} to={`${path}/login`} />
    </Switch>
  );
}

export default AuthRoutes;

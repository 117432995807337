import { defaultMemberData, defaultPolicyData } from "modules/hub/models/project-indicators";
import React from "react";
import ButtonSave from "../Elements/ButtonSave/ButtonSave";
import ProjectIndicatorsDatePicker from "./ProjectIndicatorsDatePicker";
import ProjectIndicatorsSelect from "./ProjectIndicatorsSelect";
import ProjectIndicatorsSwitch from "./ProjectIndicatorsSwitch";
import ProjectIndicatorsTag from "./ProjectIndicatorsTag";
import ProjectIndicatorsText from "./ProjectIndicatorsText";
import ProjectIndicatorsTextWithUnit from "./ProjectIndicatorsTextWithUnit";
import ProjectIndicatorsUpload from "./ProjectIndicatorsUpload";

import "./styles.scss";

interface ProjectIndicatorsRowProps {
  section: any;
  indicators: any;
  projectIndicators: any;
  handleChange: any;
  currency?: string
  accessToken: string
  project: any
}

const ProjectIndicatorsRowLeader = ({
  section,
  indicators,
  projectIndicators,
  handleChange,
  currency,
  accessToken,
  project
}: ProjectIndicatorsRowProps) => {

  const displayInput = (section: any, indicator: any, onChange?: any, index?: number) => {
    let value: any = null;
    let options: any = [];

    if (!!projectIndicators && !!projectIndicators[section.slug]) {
      if ('boardMembers' === section.slug && 'undefined' !== typeof index) {
        value = projectIndicators[section.slug].members[index][indicator.id];
      } else if ('insuranceData' === section.slug && 'undefined' !== typeof index) {
        value = projectIndicators[section.slug].policies[index][indicator.id];
      } else if (!!projectIndicators[section.slug][indicator.id]) {
        if ('undefined' !== typeof index) {
          value = projectIndicators[section.slug][indicator.id][index];
        } else {
          value = projectIndicators[section.slug][indicator.id]
        }
      }
    }

    // If a conditional parameter has been set for this indicator then display the input if the condition has been fulfilled
    if (!!indicator.conditional && !!indicator.conditional.field && !!indicator.conditional.value) {
      if(/^[a-z]+\.[a-z]+(\.[a-z]+)$/.test(indicator.conditional.field) && 'undefined' !== typeof index){
        const splitted: any = indicator.conditional.field.split(/\./gmi);

        switch(splitted.length){
          case 2:
            if (!( 0 < projectIndicators[section.slug][splitted[0]].length && !!projectIndicators[section.slug][splitted[0]][index][splitted[1]] && indicator.conditional.value === projectIndicators[section.slug][splitted[0]][index][splitted[1]])) {
              return;
            }
          break;
          case 3:
            if (!( 0 < projectIndicators[section.slug][splitted[0]].length && !!projectIndicators[section.slug][splitted[0]][index][splitted[1]] && !!projectIndicators[section.slug][splitted[0]][index][splitted[1]][splitted[2]] && indicator.conditional.value === projectIndicators[section.slug][splitted[0]][index][splitted[1]][splitted[2]])) {
              return;
            }
          break;
        }
      }else{
        if (!(!!projectIndicators[section.slug][indicator.conditional.field] && indicator.conditional.value === projectIndicators[section.slug][indicator.conditional.field])) {
          return;
        }
      }
    }

    if (!!indicator.options && !!projectIndicators && !!projectIndicators[section.slug]) {
      options = projectIndicators[section.slug][indicator.options];
    }

    switch (indicator.type) {
      case "select":
        if ('boardMembers' === section.slug && 'undefined' !== typeof index) {
          value = projectIndicators[section.slug].members[index][indicator.id];
        } else if ('insuranceData' === section.slug && 'undefined' !== typeof index) {
          value = projectIndicators[section.slug].policies[index][indicator.id];
        } else if (!!projectIndicators && !!projectIndicators[section.slug]) {
          if ('projectStage' === section.slug) {
            value = projectIndicators[section.slug];
          } else if (!!projectIndicators[section.slug][indicator.id]) {
            if ('undefined' !== typeof index) {
              value = projectIndicators[section.slug][indicator.id][index];
            } else {
              value = projectIndicators[section.slug][indicator.id]
            }
          }
        }

        // if(!!projectIndicators && !!projectIndicators[section.slug]){
        //   value = 'projectStage' === section.slug ? projectIndicators[section.slug] : projectIndicators[section.slug][indicator.id];
        // } 

        return <ProjectIndicatorsSelect
          section={section}
          indicator={indicator}
          value={value}
          handleChange={!!onChange ? onChange : handleChange}
        // setProjectStage={(e: any) => handleChange("stage", e)}
        />
        break;
      case "text":
        return <ProjectIndicatorsText
          slug={section.slug}
          indicator={indicator}
          value={!!value ? value : ''}
          handleChange={!!onChange ? onChange : handleChange}
          index={index}
        />;
        break;
      case "text unit":
        return <ProjectIndicatorsTextWithUnit
          slug={section.slug}
          indicator={indicator}
          value={value}
          handleChange={!!onChange ? onChange : handleChange}
          index={index}
          currency={currency}
        />;
        break;
      case "datepicker":
        return <ProjectIndicatorsDatePicker
          slug={section.slug}
          indicator={indicator}
          value={!!value ? value : ''}
          handleChange={!!onChange ? onChange : handleChange}
          index={index}
        />;
        break;
      case 'switch':
        return <ProjectIndicatorsSwitch
          slug={section.slug}
          indicator={indicator}
          value={!!value ? value : ''}
          handleChange={!!onChange ? onChange : handleChange}
          index={index}
        />;
        break;
      case 'upload':
        return <ProjectIndicatorsUpload
          slug={section.slug}
          indicator={indicator}
          value={!!value ? value : ''}
          handleChange={!!onChange ? onChange : handleChange}
          index={index}
          accessToken={accessToken}
          project={project}
        />;
        break;
      case 'tag':
        return <ProjectIndicatorsTag
          slug={section.slug}
          options={!!options ? options : []}
          indicator={indicator}
          value={!!value && 'object' === typeof value ? value : []}
          handleChange={!!onChange ? onChange : handleChange}
          index={index}
        />;
        break;
      default:
    }
  };

  const addMember = () => {
    handleChange('boardMembers', defaultMemberData, 'members', 'add')
  }

  const addPolicy = () => {
    handleChange('insuranceData', defaultPolicyData, 'policies', 'add')
  }

  const deleteMember = (index: number) => {
    handleChange('boardMembers', index, 'members', 'confirmRemove');
    // handleChange('boardMembers', index, 'members', 'remove', index)
  }

  const deletePolicy = (index: number) => {
    handleChange('insuranceData', index, 'policies', 'confirmRemove');
  }

  // useEffect(() => {
  //   console.log('project indicators : ', projectIndicators);
  // }, [projectIndicators])

  return (
    <section key={`section-${section.id}`} className={`indicator-section ${section.id} ${("construction" === section.id && !!projectIndicators.projectStage.id && "inConstruction" === projectIndicators.projectStage.id) ||
      ("expected-fc-date" === section.id && !!projectIndicators.projectStage.id && "underDevelopment" === projectIndicators.projectStage.id) ? " visible" : " invisible"} ${!!projectIndicators[section.slug] && !!projectIndicators[section.slug].isValidate ? "is-valid" : ""}`}>
      <div className={`indicator-section_head`}>
        <h4 className={`indicator-section_head__title`}>{section.title}</h4>
        {!!section.subQuestion && (
          <div className={`indicator-section_head__radio`}>
            <p className="label">{section.subQuestion}</p>
            <div className="radio_container">
              <div className="radio_item">
                <input
                  type="radio"
                  id={`yes-${section.id}`}
                  name={`subQuestionAnswer-${section.id}`}
                  value="yes"
                  checked={!!projectIndicators[section.slug] && 'yes' === projectIndicators[section.slug][section.subQuestionField]}
                  onChange={(e: any) => handleChange(section.slug, e.target.value, section.subQuestionField)}
                />
                <label htmlFor={`yes-${section.id}`}>Yes</label>
              </div>
              <div className="radio_item">
                <input
                  type="radio"
                  id={`no-${section.id}`}
                  name={`subQuestionAnswer-${section.id}`}
                  value="no"
                  checked={!!projectIndicators[section.slug] && 'no' === projectIndicators[section.slug][section.subQuestionField]}
                  onChange={(e: any) => handleChange(section.slug, e.target.value, section.subQuestionField)}
                />
                <label htmlFor={`no-${section.id}`}>No</label>
              </div>
            </div>
          </div>
        )}
      </div>
      {("undefined" === typeof section.subQuestion || (!!section.subQuestion && !!projectIndicators[section.slug] && 'yes' === projectIndicators[section.slug][section.subQuestionField])) &&
        <>
          <div className={`indicator-section_content`}>
            <div className="part">
              <p className="part_label">
                {"board-members" === section.id
                  ? "Please fill all member information"
                  : "insurance-data" === section.id
                    ? "Please add a line for each policy – where a policy has multiple insurers please add lines for each insurer and indicate the percentage"
                    : "project-stage" !== section.id && "Please fill all indicators"}
              </p>
              {"board-members" === section.id ? (
                <>
                  <ul className="indicators_list">
                    {Object.values(section.indicators).map((ind: any, idx: number) => (
                      <li key={`indicator-${idx}`} className={"indicators_item"}>
                        {displayInput(section, ind)}
                      </li>
                    ))}
                  </ul>
                  {!!projectIndicators[section.slug] && projectIndicators[section.slug].members.map((member: any, i: number) => (
                    <ul key={`member-${i}`} className="indicators_list">
                      {section.additionalIndicators.member.map((ind: any, idx: number) => (
                        <li key={`indicator-${idx}`} className={"indicators_item"}>
                          {displayInput(section, ind, (slug: string, value: any, field: string) => handleChange(slug, value, field, 'update', i), i)}
                        </li>
                      ))}
                      <li>
                        <button data-uk-toggle='#warnDeleteLoopData' onClick={() => deleteMember(i)} className="btn btn-select btn-primary btn-delete" type="button" title="Delete member" >
                          {/* <span className="btn-label"></span> */}
                          <span className="btn-icon">
                            <i className={`fas fa-trash`}></i>
                          </span>
                        </button>
                      </li>
                    </ul>
                  ))}
                </>
              ) : "insurance-data" === section.id ?
                <>
                  {!!projectIndicators[section.slug] && !!projectIndicators[section.slug].policies && projectIndicators[section.slug].policies.map((policy: any, i: number) => (
                    <ul key={`policy-${i}`} className="indicators_list">
                      {section.additionalIndicators.policy.map((ind: any, idx: number) => (
                        <li key={`policy-${i}-indicator-${idx}`} className={"indicators_item"}>
                          {displayInput(section, ind, (slug: string, value: any, field: string) => handleChange(slug, value, field, 'update', i), i)}
                        </li>
                      ))}
                      <li>
                        <button data-uk-toggle='#warnDeleteLoopData' onClick={() => deletePolicy(i)} className="btn btn-select btn-primary btn-delete" type="button" title="Delete member" >
                          {/* <span className="btn-label"></span> */}
                          <span className="btn-icon">
                            <i className={`fas fa-trash`}></i>
                          </span>
                        </button>
                      </li>
                    </ul>
                  ))}
                </>
                :
                <ul className="indicators_list">
                  {section.indicators.map((ind: any, idx: number) => (
                    <li key={`indicator-${idx}`} className={"indicators_item"}>
                      {displayInput(section, ind)}
                    </li>
                  ))}
                </ul>
              }
            </div>

            {"board-members" === section.id &&
              <button
                onClick={addMember}
                className="btn btn-select btn-primary btn-add"
                type="button"
              >
                <span className="btn-label">Add member</span>
                <span className="btn-icon">
                  <i className={`fas fa-plus`}></i>
                </span>
              </button>
            }

            {"insurance-data" === section.id &&
              <>
                <button
                  onClick={addPolicy}
                  className="btn btn-select btn-primary btn-add"
                  type="button"
                >
                  <span className="btn-label">Add policy</span>
                  <span className="btn-icon">
                    <i className={`fas fa-plus`}></i>
                  </span>
                </button>
                {!!projectIndicators[section.slug] && !!projectIndicators[section.slug].policies && 0 < projectIndicators[section.slug].policies.length && section.indicators
                  .filter((si: any) => 'switchClaims' === si.id)
                  .map((ind: any, idx: number) => (
                    <React.Fragment key={`indicator-insurance-data-${idx}`}>
                      <div className={"indicators_list claims"}>
                        {displayInput(section, ind)}
                        {"yes" === projectIndicators[section.slug].switchClaims &&
                          !!ind.isGroup &&
                          !!ind.children &&
                          0 < ind.children.length &&
                          <div className="part">
                            <ul className="indicators_list">
                              {ind.children.map((ic: any, ici: number) => (
                                <li key={`indicator-insurance-data-${idx}-child-${ici}`} className={"indicators_item"}>
                                  {displayInput(section, ic)}
                                </li>
                              ))}
                            </ul>
                          </div>
                        }
                      </div>
                    </React.Fragment>
                  ))
                }

                {section.indicators
                  .filter((si: any) => 'switchClaims' !== si.id)
                  .map((ind: any, idx: number) => (
                    <React.Fragment key={`indicator-insurance-data-${idx}`}>
                      <div className={"indicators_list"}>
                        {displayInput(section, ind)}
                        {!!projectIndicators[section.slug] && "yes" === projectIndicators[section.slug].switchDoCoverage &&
                          !!ind.isGroup &&
                          !!ind.children &&
                          0 < ind.children.length &&
                          <div className="part">
                            <ul className="indicators_list">
                              {ind.children.map((ic: any, ici: number) => (
                                <li key={`indicator-insurance-data-${idx}-child-${ici}`} className={"indicators_item"}>
                                  {displayInput(section, ic)}
                                </li>
                              ))}
                            </ul>
                          </div>
                        }
                      </div>
                    </React.Fragment>
                  ))}
              </>
            }

            <div className="part">
              <p className="part_label">{section.labelSecondPart}</p>
              {"project-stage" !== section.id && (
                <p
                  className={`about filled`}
                  dangerouslySetInnerHTML={{ __html: !!indicators[`${section.slug}Text`] ? indicators[`${section.slug}Text`].replace(/\n/gi, '<br>') : '' }}
                ></p>
              )}
            </div>
          </div>
          {/* TODO Morgane: better style for this */}
          {/* {"project-stage" !== section.id && (
            <div className={`indicator-section_foot`}>
              <input
                type="checkbox"
                id={`select-${section.id}`}
                checked={isValid}
                // onChange={(e) => setIsValid(e.target.checked)}
              />
              <button className="box">
                <i className="fas fa-check"></i>
              </button>
              <span>
                Validate if the content is ready to be reviewed by Meridiam HUB
              </span>
            </div>
          )} */}
          {'projectStage' !== section.slug &&
            <>
              <ButtonSave />
              <div className={`indicator-section_foot ${!!projectIndicators[section.slug] && projectIndicators[section.slug].isValidate ? "is-valid" : ""}`}>
                <input
                  type="checkbox"
                  id={`select-${section.id}`}
                  checked={!!projectIndicators[section.slug] && projectIndicators[section.slug].isValidate}
                  onChange={(e: any) => handleChange(section.slug, e.target.checked, 'isValidate')}
                />
                <button className="box">
                  <i className="fas fa-check"></i>
                </button>
                <span>
                  Validate if the content is ready to be reviewed by Meridiam HUB
                </span>
              </div>
            </>
          }
        </>
      }
    </section>
  );
};

export default ProjectIndicatorsRowLeader;

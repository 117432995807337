import React, { useEffect, useState } from "react";
import UIkit from "uikit";
import { RootState, ThunkDispatch } from "models/store.models";
import Loader from "../../../shared/components/Loader/Loader";
import { connect } from "react-redux";
import "../../../../assets/scss/custom/tools/_base.scss";
import Header from "../../../shared/components/Header/Header";
import "./styles.scss";
import ManagementTitlePage from "modules/management/components/ManagementTitlePage/ManagementTitlePage";
import ManagementFooterPage from "modules/management/components/ManagementFooterPage/ManagementFooterPage";
import ManagementToolBar from "../ManagementToolBar/ManagementToolBar";
import ManagementContentHead from "modules/management/components/ManagementContentHead/ManagementContentHead";
import {
  getAllcountries,
  updateAllCountries,
} from "modules/shared/services/country.service";
import { Country } from "models/country.model";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CountryListItem from "modules/management/components/CountryListItem/CountryListItem";

const { DateTime } = require("luxon");

const toastConfig = {
  position: "top-right" as any,
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
};

const mapStateToProps = ({ session }: RootState) => ({
  accessToken: session?.sessionData?.accessToken
    ? session?.sessionData?.accessToken
    : "",
  user: session?.sessionData?.userData,
});

const mapDispatchToProps = (dispatch: ThunkDispatch) => ({});

type CountryListProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const CountryList: React.FC<CountryListProps> = ({ accessToken, user }) => {
  const [sortNameOrder, setSortNameOrder] = useState<string>("asc");
  const [sortLastUpdateOrder, setSortLastUpdateOrder] = useState<string>("asc");

  const [lastUpdateDate, setLastUpdateDate] = useState<string | undefined>("");
  const [lastUpdateName, setLastUpdateName] = useState<string | undefined>("");

  const [availableYear, setAvailableYear] = useState<number[]>([]);
  const [currYear, setCurrYear] = useState<number>(2022);
  const [countries, setCountries] = useState<Country[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [formDirty, setFormDirty] = useState<string[]>([]);
  const [searchTerms, setSearchTerms] = useState<string>("");
  const [isMissingAParity, setIsMissingAParity] = useState<boolean>(false);

  const [OECDGroup, setOECDGroup] = useState("");

  useEffect(() => {
    setIsLoading(true);
    getAllcountries(accessToken, currYear).then((result) => {
      let newUpdateUser = "";
      let newUpdateDate = "";
      result.countries.map((country) => {
        if (!!country.currencyVal) {
          setIsMissingAParity(true);
        }
        if (
          newUpdateUser === "" &&
          !!country.currencyVal &&
          !!country.currencyVal.userLastUpdate &&
          !!country.currencyVal.lastUpdate
        ) {
          newUpdateUser = country.currencyVal.userLastUpdate;
          newUpdateDate = country.currencyVal.lastUpdate;
        }

        if (
          !!country.currencyVal &&
          !!country.currencyVal.lastUpdate &&
          !!country.currencyVal.userLastUpdate &&
          DateTime.fromISO(newUpdateDate) <
            DateTime.fromISO(country.currencyVal.lastUpdate)
        ) {
          newUpdateUser = country.currencyVal.userLastUpdate;
          newUpdateDate = country.currencyVal.lastUpdate;
        }
      });

      setLastUpdateDate(newUpdateDate);
      setLastUpdateName(newUpdateUser);
      let newArr = result.countries.sort((a: Country, b: Country) => {
        return a.name.localeCompare(b.name);
      });
      setCountries(newArr);

      let newArrYears: number[] = [];
      result.years.map((year: any) => {
        if (year.id > 2018) {
          // first referential year was for helping survey filing, not for compile data
          newArrYears.push(year.id);
        }
      });
      setAvailableYear(newArrYears);
      setIsLoading(false);
    });
  }, [currYear]);

  const updateSortOrder = (type: string, order: string) => {
    let newCountriesList = countries;
    if (type === "date") {
      setSortLastUpdateOrder(order);
      if (order === "asc") {
        newCountriesList = newCountriesList.sort((a: Country, b: Country) => {
          return a.currencyVal?.lastUpdate.localeCompare(
            b.currencyVal?.lastUpdate
          );
        });
      } else {
        newCountriesList = newCountriesList.sort((a: Country, b: Country) => {
          return b.currencyVal?.lastUpdate.localeCompare(
            a.currencyVal?.lastUpdate
          );
        });
      }
    } else {
      setSortNameOrder(order);
      if (order === "asc") {
        newCountriesList = newCountriesList.sort((a: Country, b: Country) => {
          return a.name.localeCompare(b.name);
        });
      } else {
        newCountriesList = newCountriesList.sort((a: Country, b: Country) => {
          return b.name.localeCompare(a.name);
        });
      }
    }

    setCountries(newCountriesList);
  };

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    let values = [...countries];
    values.map((val) => {
      if (val.code === name) {
        switch (val.currency) {
          case "EUR":
          case "XOF":
            values.map((v) => {
              if (v.currency === val.currency) {
                if (!v.currencyVal) {
                  v.currencyVal = {
                    country: val.name,
                    currPerDollar: value,
                    currency: val.currency,
                    year: currYear,
                    lastUpdate: new Date().toISOString(),
                    userLastUpdate: user?.firstName + " " + user?.lastName,
                  };
                } else {
                  v.currencyVal.currPerDollar = value;
                }
                setFormDirty((formDirty) => [...formDirty, v.code]);
              }
            });
            break;
          default:
            if (!val.currencyVal) {
              val.currencyVal = {
                country: val.name,
                currPerDollar: value,
                currency: val.currency,
                year: currYear,
                lastUpdate: new Date().toISOString(),
                userLastUpdate: user?.firstName + " " + user?.lastName,
              };
            } else {
              val.currencyVal.currPerDollar = value;
            }
            setFormDirty((formDirty) => [...formDirty, val.code]);
            break;
        }
      }
    });
    setCountries(values);
  };

  const handleCountryChange = (countryCode: string, value: string) => {
    let values = [...countries];
    values.map((val) => {
      if (val.code === countryCode) {
        val.target = value;
        setFormDirty((formDirty) => [...formDirty, val.code]);
      }
      setCountries(values);
    });
  };

  const handleSave = () => {
    updateAllCountries(accessToken, currYear, countries).then((result: any) => {
      if (result.status === "ok") {
        toast.success("The list has been updated successfully.", toastConfig);
        setFormDirty([]);
        setLastUpdateDate(new Date().toISOString());
        setLastUpdateName(user?.firstName + " " + user?.lastName);
      } else {
        toast.error("Error occured.", toastConfig);
      }
    });
  };

  return (
    <>
      <Loader
        className={isLoading ? "on" : "off"}
        color="multicolors"
        size="large"
      />

      <div className="header-wrapper" data-uk-sticky="media:768;">
        <Header
          titleContext="platform"
          colorContext={"seaLight"}
          classNameToolbarTop="country-list"
          tool={`spv-manager`}
        />
      </div>

      <ManagementToolBar currentTool={"country-list"} user={user} />

      <div className="page page-dashboard" data-theme="seaLight">
        <div className="page_inner">
          <ManagementTitlePage
            title={`Country & currency List`}
            isSavable={true}
            updatedBy={lastUpdateName}
            updatedOn={DateTime.fromISO(lastUpdateDate).toFormat("LL/dd/yyyy")}
            handleSave={handleSave}
            formDirty={formDirty}
            formCanBeSave={true}
          />

          <div className="grid grid-tool">
            <div className="grid_inner">
              <div className="main-content bg-white tool-country-list">
                <ManagementContentHead
                  searchTerms={searchTerms}
                  setSearchTerms={setSearchTerms}
                  currentTool="country-list"
                  title={`${countries.length} countries for ${currYear}`}
                  searchPlaceholder="Search a country (by iso code, name or currency)"
                  availableYear={availableYear}
                  currYear={currYear}
                  setCurrYear={setCurrYear}
                />

                <div className="content-body">
                  <i className="label">Sort by...</i>
                  <ul className="list">
                    <li className="row filters">
                      <div className="name">
                        <div className="country">
                          <button
                            className="btn btn-filter"
                            type="button"
                            onClick={() =>
                              updateSortOrder(
                                "name",
                                sortNameOrder === "asc" ? "desc" : "asc"
                              )
                            }
                          >
                            <span className="btn_label">Name</span>
                            <span className="btn_icon">
                              {sortNameOrder === "asc" ? (
                                <i className="fas fa-sort-alpha-down"></i>
                              ) : (
                                <i className="fas fa-sort-alpha-down-alt"></i>
                              )}
                            </span>
                          </button>
                        </div>
                        <div className="iso-code">
                          <i>Iso code</i>
                        </div>
                      </div>
                      <div className="currency">
                        <i>Currency</i>
                      </div>
                      <div className="group">
                        <div className="selector">
                          <div className="select">
                            <button
                              className="btn btn-select btn-filter"
                              type="button"
                            >
                              <span className="btn_label">
                                {!!OECDGroup ? OECDGroup : "OECD Group"}
                              </span>
                              <span className="btn_icon">
                                <i className="fas fa-sort"></i>
                              </span>
                            </button>
                            <div
                              className="drop drop-select oecd-group-filter"
                              data-uk-dropdown="pos: top-left; mode: click; delay-hide: 100"
                            >
                              <ul className="group lvl0 uk-nav uk-dropdown-nav">
                                <li
                                  className="item"
                                  data-uk-toggle="target: .oecd-group-filter"
                                  onClick={() => {
                                    setOECDGroup("");
                                    UIkit.dropdown(".oecd-group-filter").hide();
                                  }}
                                >
                                  <span className="clickable">All</span>
                                </li>
                                <li
                                  className="item"
                                  data-uk-toggle="target: .oecd-group-filter"
                                  onClick={() => {
                                    setOECDGroup("Developed");
                                    UIkit.dropdown(".oecd-group-filter").hide();
                                  }}
                                >
                                  <span className="clickable">Developed</span>
                                </li>
                                <li
                                  className="item"
                                  data-uk-toggle="target: .oecd-group-filter"
                                  onClick={() => {
                                    setOECDGroup("Emerging");
                                    UIkit.dropdown(".oecd-group-filter").hide();
                                  }}
                                >
                                  <span className="clickable">Emerging</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* TODO SAM: if Dollar parity data is missing, add class warn */}
                      <div
                        className={
                          isMissingAParity
                            ? "dollar-parity warn"
                            : "dollar-parity"
                        }
                      >
                        <i>Dollar parity</i>
                        {/* <i className="fas fa-exclamation-circle"></i> */}
                      </div>
                      <div className="warning"></div>
                      <div className="last-update">
                        <button
                          className="btn btn-filter"
                          type="button"
                          onClick={() =>
                            updateSortOrder(
                              "date",
                              sortLastUpdateOrder === "asc" ? "desc" : "asc"
                            )
                          }
                        >
                          <span className="btn_label">Last update</span>
                          <span className="btn_icon">
                            {sortLastUpdateOrder === "asc" ? (
                              <i className="fas fa-sort-numeric-down"></i>
                            ) : (
                              <i className="fas fa-sort-numeric-down-alt"></i>
                            )}
                          </span>
                        </button>
                      </div>
                    </li>

                    {!!countries &&
                      countries.length > 0 &&
                      countries
                        .filter((country: Country) => {
                          if (
                            searchTerms === "" ||
                            (searchTerms !== "" &&
                              (country.code
                                .toLocaleLowerCase()
                                .match(searchTerms.toLocaleLowerCase()) ||
                                country.name
                                  .toLocaleLowerCase()
                                  .match(searchTerms.toLocaleLowerCase()) ||
                                country.currency
                                  .toLocaleLowerCase()
                                  .match(searchTerms.toLocaleLowerCase())))
                          ) {
                            return country;
                          }
                        })
                        .filter((country: Country) => {
                          if (
                            "" === OECDGroup ||
                            OECDGroup.toUpperCase() ===
                              country.target.toUpperCase()
                          )
                            return country;
                        })
                        .map((country: Country, idx: number) => {
                          return (
                            <CountryListItem
                              key={`countryK-${idx}`}
                              country={country}
                              handleChange={handleChange}
                              handleCountryChange={handleCountryChange}
                              formDirty={formDirty}
                            />
                          );
                        })}
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <ManagementFooterPage
            isSavable={true}
            updatedBy={lastUpdateName}
            updatedOn={DateTime.fromISO(lastUpdateDate).toFormat("LL/dd/yyyy")}
            handleSave={handleSave}
            formDirty={formDirty}
            formCanBeSave={true}
          />
        </div>
      </div>

      <ToastContainer />
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CountryList);

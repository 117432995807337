import React, { useEffect, useState } from "react";

import ImpactIndicatorSection from "../../components/ImpactIndicatorSection/ImpactIndicatorSection";
import CarbonFootPrintSection from "../../components/CarbonFootPrintSection/CarbonFootPrintSection";

import { CarbonFootprint } from "../../models/carbon-footprint.model";
import { ImpactKeyIndicator } from "../../models/impact-key-indicator.model";

import { getCarbonFootprint } from "../../services/limited-partners.service";
import {
  CurrFilter,
  AvailableFilters,
} from "../../models/available-filters.model";

type PropsSection = {
  accessToken: string;
  investorId: string;
  year: number;
  currFilter: CurrFilter;
  filter: AvailableFilters;
  currency: string;
  investorType: string;
};

const WrapKeyIndicAndCarbonSection: React.FC<PropsSection> = ({
  accessToken,
  investorId,
  year,
  currFilter,
  filter,
  currency,
  investorType
}) => {
  const [
    impactKeyIndicator,
    setImpactKeyIndicator,
  ] = useState<ImpactKeyIndicator>();
  const [carbonFootprint, setCarbonFootprint] = useState<CarbonFootprint[]>();
  const [isImpactIndicatorLoading, setIsImpactIndicatorLoading] = useState(
    true
  );

  useEffect(() => {
    // get carbon footprint + impact indicator
    setIsImpactIndicatorLoading(true);
    getCarbonFootprint(
      accessToken,
      investorId,
      year,
      currFilter.meridiamFund,
      currFilter.location
    )
      .then(
        (result) => {
          setImpactKeyIndicator(result.impactKeyIndicatorByCategory);
          setCarbonFootprint(result.carbonFootprintResult);
        },
        (error) => {
          console.log("---- error carbon footprint / impact indicator ----");
          console.log(error.response);
        }
      )
      .then(() => {
        setIsImpactIndicatorLoading(false);
      });
  }, [currFilter]);

  return (
    <>
      {/* BLOC 7 -- key impact */}
      <ImpactIndicatorSection
        impactKeyIndicator={impactKeyIndicator}
        year={year}
        isImpactIndicatorLoading={isImpactIndicatorLoading}
        currency={currency}
        filter={filter}
        investorType={investorType}
      />

      {/* BLOC 9 -- carbon footprint */}
      <CarbonFootPrintSection
        carbonFootprint={carbonFootprint}
        year={year}
        isImpactIndicatorLoading={isImpactIndicatorLoading}
        investorType={investorType}
      />
    </>
  );
};

export default WrapKeyIndicAndCarbonSection;

import React from "react";
// import Helmet from "react-helmet";
// import config from "modules/config";
import "./styles.scss";

import WebFont from "webfontloader";

WebFont.load({
  custom: {
    families: [
      "Montserrat:300,400,700,900",
      "Open Sans Condensed:400,700",
      "Font Awesome 5 Pro",
      "Font Awesome 5 Brands",
    ],
    urls: [
      "http://localhost:3001" !== process.env.REACT_APP_URL_WEBSITE
        ? process.env.REACT_APP_URL_WEBSITE + "/assets/css/fonts.css"
        : "/assets/css/fonts-local.css",
    ],
  },
});

const AuthLayout: React.FC = ({ children }) => (
  <div className="layout layout-user">{children}</div>
);

export default AuthLayout;

import React, { useEffect, useState } from "react";
import { RootState, ThunkDispatch } from "models/store.models";
import Loader from "../../../shared/components/Loader/Loader";
import { connect } from "react-redux";
import "../../../../assets/scss/custom/tools/_base.scss";
import Header from "../../../shared/components/Header/Header";
import "./styles.scss";
import ManagementToolBar from "../ManagementToolBar/ManagementToolBar";
import { CountryData } from "models/country-data.model";
import { getAllCountryData } from "modules/shared/services/country-data.service";
import CountryDataList from "modules/management/components/CountryDataList/CountryDataList";
import CountryDataEdit from "modules/management/components/CountryDataEdit/CountryDataEdit";
import WarnModal from "modules/shared/components/WarnModal/WarnModal";
import { cloneDeep } from "lodash";

const mapStateToProps = ({ session }: RootState) => ({
  accessToken: session?.sessionData?.accessToken
    ? session?.sessionData?.accessToken
    : "",
  user: session?.sessionData?.userData,
});

const mapDispatchToProps = (dispatch: ThunkDispatch) => ({});

type CountryDataManagerProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const CountryDataManager: React.FC<CountryDataManagerProps> = ({
  accessToken,
  user,
}) => {
  const [currCountryData, setCurrCountryData] = useState<CountryData | null>(
    null
  );
  const [currCountList, setCurrCountList] = useState<any[]>([]);

  const [availableYear, setAvailableYear] = useState<number[]>([]);
  const [currYear, setCurrYear] = useState<number>(2022);
  const [countryData, setCountryData] = useState<CountryData[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    setIsLoading(true);
    getAllCountryData(accessToken, currYear).then((result) => {
      setCountryData(result.result);
      let newArrYears: number[] = [];
      result.years.map((year: any) => {
        if (year.id > 2018) {
          // first referential year was for helping survey filing, not for compile data
          newArrYears.push(year.id);
        }
      });
      setAvailableYear(newArrYears);
      setIsLoading(false);
    });
  }, [currYear]);

  const selectCountryData = (cd: CountryData) => {
    let newVal = cloneDeep(cd);
    setCurrCountryData(newVal);
    setCurrCountList(newVal.countries);
  };

  return (
    <>
      <Loader
        className={isLoading ? "on" : "off"}
        color="multicolors"
        size="large"
      />

      <div className="header-wrapper" data-uk-sticky="media:768;">
        <Header
          titleContext="platform"
          colorContext={"seaLight"}
          classNameToolbarTop="country-data"
          tool={`spv-manager`}
        />
      </div>

      <ManagementToolBar currentTool={"country-data"} user={user} />

      <div className="page page-dashboard" data-theme="seaLight">
        <div className="page_inner">
          {!!currCountryData && currCountryData !== null ? (
            <CountryDataEdit
              currCountryData={currCountryData}
              currCountList={currCountList}
              currYear={currYear}
              setCurrCountryData={setCurrCountryData}
              countryData={countryData}
              setCountryData={setCountryData}
              accessToken={accessToken}
              userName={user?.firstName + " " + user?.lastName}
            />
          ) : (
            <CountryDataList
              countryData={countryData}
              currYear={currYear}
              selectCountryData={selectCountryData}
              availableYear={availableYear}
              setCurrYear={setCurrYear}
            />
          )}
        </div>
      </div>

      <WarnModal
        id="warnCancel"
        content="Unsaved changes will be lost if you close.<br/>Do you want to close?"
        actionYes={() => setCurrCountryData(null)}
      />
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CountryDataManager);

import { RootState, ThunkDispatch } from "models/store.models";
import { Assumption } from "modules/hub/models/project-macroeconomic-assumptions";
import {
  getFileByDocumentType,
  getMacroAssumptions,
} from "modules/shared/services/spv-reporting.service";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ProjectAssumptionsLeaderItem from "./ProjectAssumptionsLeaderItem";

import "./styles.scss";

interface ProjectAssumptionsLeaderProps {
  accessToken: string;
}

const mapStateToProps = ({ session }: RootState) => ({
  accessToken: session?.sessionData?.accessToken
    ? session?.sessionData?.accessToken
    : "",
  user: session?.sessionData?.userData,
});

const mapDispatchToProps = (dispatch: ThunkDispatch) => ({});

const ProjectAssumptionsLeader = ({
  accessToken,
}: ProjectAssumptionsLeaderProps) => {
  const [files, setFiles] = useState<any>([]);

  const [assumptions, setAssumptions]: any = useState<Assumption[]>([]);

  const init = async () => {
    const tmpAssumptions: any = await getMacroAssumptions(accessToken);
    if ("ok" === tmpAssumptions.status) {
      if (!!tmpAssumptions.data && !!tmpAssumptions.data.assumptions) {
        setAssumptions(tmpAssumptions.data.assumptions);
      }
    }
  };

  const loadFiles = async () => {
    const tmpFiles: any = await getFileByDocumentType("macroeconomic", accessToken, null, "");

    if ("ok" === tmpFiles.status) {
      setFiles(tmpFiles.files);
    }
  };

  const DownloadFile = () => {
    return (
      <>
        {!!files && 0 < files.length && (
          <div className="download-csv">
            <span>Download these Macroeconomic assumptions</span>
            {files.filter((f: any) => !!f.file && !!f.file.download).map((f: any) => (
              <a
                key={`assumptions-leader-${f.id}`}
                href={f.file.download}
                download
              >
                <button className="btn btn-select btn-primary" type="button">
                  <span className="btn-label">
                    Download (.{f.name.split(".").pop()})
                  </span>
                  <span className="btn-icon">
                    <i className={`fas fa-download`}></i>
                  </span>
                </button>
              </a>
            ))}
          </div>
        )}
      </>
    );
  };

  useEffect(() => {
    init();
    loadFiles();
  }, []);

  return (
    <>
      <div className="project-details-assumptions-leader_head">
        {DownloadFile()}
      </div>

      <div className="project-details-assumptions-leader_content">
        {!!assumptions &&
          assumptions
            .filter((a: any) => !!a.file && !!a.file.url)
            .map((a: any, idx: any) => (
            <ProjectAssumptionsLeaderItem
              key={`assumption-${idx}`}
              assumption={a}
            />
          ))}
      </div>

      <div className="project-details-assumptions-leader_foot">
        {DownloadFile()}
      </div>
    </>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectAssumptionsLeader);

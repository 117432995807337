import { useFormik } from "formik";
import { RootState, ThunkDispatch } from "models/store.models";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import { passwordReset } from "../../actions/session-actions";

import "./styles.scss";
import styles from "./styles.module.scss";

import AuthLayout from "../../components/AuthLayout/AuthLayout";
import Loader from "../../../shared/components/Loader/Loader";

const validationSchema = yup.object().shape({
  login: yup.string().label("Login").email().required(),
  password: yup.string().label("Password").required(),
  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref("password")], "Passwords must match"),
});

const mapStateToProps = ({ session }: RootState) => ({
  session,
});

const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  passwordReset: (token: string, login: string, passwordConfirmation: string) =>
    dispatch(passwordReset(token, login, passwordConfirmation)),
});

type PasswordResetProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const PasswordReset: React.FC<PasswordResetProps> = ({ passwordReset }) => {
  let { token } = useParams();
  const [passwordResetError, setPasswordResetError] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: {
      login: "",
      password: "",
      passwordConfirmation: "",
    },
    onSubmit: async (values) => {
      const success = await passwordReset(token, values.login, values.password);
      setPasswordResetError(!success);
    },
    validationSchema: validationSchema,
  });

  const [showLoading, setShowLoading] = useState<boolean>(false);
  useEffect(() => {
    let timer = setTimeout(() => setShowLoading(true), 50);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <AuthLayout>
      <Loader
        className={showLoading ? "off" : "on"}
        color="multicolors"
        size="large"
      />
      <div className="page page-auth">
        <div className="page_inner">
          <div className="box box-auth">
            <div className="logo">
              <svg
                className={`${styles["logo"]} svg svg-logo svg-logo-meridiam xl`}
              >
                <use xlinkHref="#logoMeridiam"></use>
              </svg>
            </div>
            <div className="box_inner">
              <div className="box__head">
                <h1>
                  <small>Meridiam</small>
                  <span>SDG &amp; Investing Tools Platform</span>
                </h1>
              </div>
              <div className="box__content">
                <form className="form form-auth" onSubmit={formik.handleSubmit}>
                  <div className="form_inner">
                    <div className="form_head">
                      <p></p>
                    </div>
                    <div className="form_group">
                      <div className="form-row row row-block">
                        <input
                          autoComplete="username"
                          id="login"
                          name="login"
                          type="text"
                          placeholder="Login"
                          onChange={formik.handleChange}
                          value={formik.values.login}
                        />
                        {formik.submitCount > 0 && formik.errors?.login && (
                          <div className="form-error">
                            <span>{formik.errors.login}</span>
                          </div>
                        )}
                      </div>
                      <div className="form-row row row-block">
                        <input
                          autoComplete="current-password"
                          id="password"
                          name="password"
                          type="password"
                          placeholder="Password"
                          onChange={formik.handleChange}
                          value={formik.values.password}
                        />
                        {formik.submitCount > 0 && formik.errors?.password && (
                          <div className="form-error">
                            <span>{formik.errors.password}</span>
                          </div>
                        )}
                      </div>
                      <div className="form-row row row-block">
                        <input
                          autoComplete="current-password"
                          id="passwordConfirmation"
                          name="passwordConfirmation"
                          type="password"
                          placeholder="New password confirmation"
                          onChange={formik.handleChange}
                          value={formik.values.passwordConfirmation}
                        />
                        {formik.submitCount > 0 &&
                          formik.errors?.passwordConfirmation && (
                            <div className="form-error">
                              <span>{formik.errors.passwordConfirmation}</span>
                            </div>
                          )}
                      </div>
                      <div className="form-row row row-block row-submit">
                        <button className="btn btn-cta label-external rounded">
                          <span className="btn_icon icon-primary">
                            <i className="fas fa-chevron-right"></i>
                          </span>
                          <span className="btn_label label-primary">
                            Submit
                          </span>
                        </button>
                      </div>
                    </div>
                    {passwordResetError && (
                      <div className="row row-message">
                        <div className="message error">
                          An error occured while resetting password
                        </div>
                      </div>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset);

import { SdgContribution } from "models/esg-contribution.model";
import React from "react";
import sdgDatas from "../../../datas/sdg.json";

type SdgContributionSdgPanelChoiceProps = {
  sdgList: number[];
  currentSdgContribution: SdgContribution;
  setValueGraph: any;
};

const SdgContributionSdgPanelChoice: React.FC<SdgContributionSdgPanelChoiceProps> = ({
  sdgList,
  currentSdgContribution,
  setValueGraph,
}) => {
  return (
    <div className="row" data-print="no">
      <div className="grid grid-sdg">
        <div className="inner">
          <div className="card card-sdg card-empty">
            <div className="card_inner">
              <div className="edito">
                <p>
                  Choose an SDG and discover Meridiam portfolio contribution
                </p>
              </div>
            </div>
          </div>

          {sdgList.map((sdg) => {
            let classCard = "card_inner sdg-" + sdg;
            if (currentSdgContribution) {
              if (!currentSdgContribution.listIndicator[sdg] ) {
                classCard += " cant-select";
              }
            }

            return (
              <div className="card card-sdg" key={`sdg-${sdg}`}>
                <div
                  className={classCard}
                  onClick={() => setValueGraph("sdg", sdg)}
                >
                  <div className="icon">
                    <span className="sdg-icon">
                      <img
                        src={`/assets/images/sdg/icons/80/sdg-pastille-${sdg}@2x.png`}
                        alt={`SDG ${sdg} icon`}
                      />
                    </span>
                  </div>

                  <div className="edito">
                    <div className="sdg-number">
                      <p>{sdg}.</p>
                    </div>
                    <div className="sdg-name">
                      <p>{sdgDatas[sdg].shortName}</p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SdgContributionSdgPanelChoice;

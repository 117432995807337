import { CountryData } from "models/country-data.model";
import React, { useState, useEffect } from "react";
import ManagementContentHead from "../ManagementContentHead/ManagementContentHead";
import ManagementTitlePage from "../ManagementTitlePage/ManagementTitlePage";

interface CountryDataListProps {
  countryData: CountryData[];
  currYear: number;
  selectCountryData: any;
  availableYear: number[];
  setCurrYear: any;
}

const CountryDataList = ({
  countryData,
  currYear,
  selectCountryData,
  availableYear,
  setCurrYear,
}: CountryDataListProps) => {
  const [searchTerms, setSearchTerms] = useState<string>("");
  const [showOnlyMissingData, setShowOnlyMissingData] = useState<boolean>(
    false
  );
  const [sortOrder, setSortOrder] = useState<string>("asc");
  const [countryDataList, setCountryDataList] = useState<CountryData[]>([]);

  useEffect(() => {
    let sortCountryData = countryData.sort((a: CountryData, b: CountryData) => {
      let aVal = a.indicatorNumbering.split(".");
      let aValue = aVal[3];
      let bVal = b.indicatorNumbering.split(".");
      let bValue = bVal[3];
      return Number(aValue) < Number(bValue) ? -1 : 1;
    });
    setCountryDataList(sortCountryData);
  }, [countryData]);

  const hasMissingData = (countries: any) => {
    let missingData = false;
    countries.countries &&
      countries.countries.map((country: any) => {
        if (
          country.data === undefined ||
          (!!country.data && country.data.value === "")
        ) {
          missingData = true;
        }
      });
    return missingData;
  };

  const updateSortOrder = (order: string) => {
    setSortOrder(order);

    let newQuestionsList = countryData;

    if (order === "asc") {
      newQuestionsList = newQuestionsList.sort(
        (a: CountryData, b: CountryData) => {
          let aVal = a.indicatorNumbering.split(".");
          let aValue = aVal[3];
          let bVal = b.indicatorNumbering.split(".");
          let bValue = bVal[3];
          return Number(aValue) < Number(bValue) ? -1 : 1;
        }
      );
    } else {
      newQuestionsList = newQuestionsList.sort(
        (a: CountryData, b: CountryData) => {
          let aVal = a.indicatorNumbering.split(".");
          let aValue = aVal[3];
          let bVal = b.indicatorNumbering.split(".");
          let bValue = bVal[3];
          return Number(aValue) > Number(bValue) ? -1 : 1;
        }
      );
    }

    setCountryDataList(newQuestionsList);
  };

  return (
    <>
      <ManagementTitlePage 
        title={`Country Data indicators`}
        formCanBeSave={true} 
      />
      <div className="grid grid-tool">
        <div className="grid_inner">
          <div className="main-content bg-white tool-country-data">
            <ManagementContentHead
              searchTerms={searchTerms}
              setSearchTerms={setSearchTerms}
              currentTool="country-data"
              title={`${countryData.length} indicators for ${currYear}`}
              searchPlaceholder="Search an indicator"
              availableYear={availableYear}
              currYear={currYear}
              setCurrYear={setCurrYear}
            />

            <div className="content-body">
              <i className="label">Sort by...</i>
              <ul className="list">
                <li className="row filters">
                  <div className="id">
                    <button
                      className="btn btn-filter"
                      type="button"
                      onClick={() =>
                        updateSortOrder(sortOrder === "asc" ? "desc" : "asc")
                      }
                    >
                      <span className="btn_label">Id</span>
                      <span className="btn_icon">
                        {sortOrder === "asc" ? (
                          <i className="fas fa-sort-numeric-down"></i>
                        ) : (
                          <i className="fas fa-sort-numeric-down-alt"></i>
                        )}
                      </span>
                    </button>
                  </div>
                  <div className="warning">
                    <label htmlFor="show">
                      Show only missing data countries
                    </label>
                    <input
                      type="checkbox"
                      id="show"
                      className="warning-input"
                      onClick={() =>
                        setShowOnlyMissingData(!showOnlyMissingData)
                      }
                    />
                    <div className="warning-switcher"></div>
                  </div>
                  <div className="edit"></div>
                </li>

                {!!countryDataList &&
                  countryDataList.length > 0 &&
                  countryDataList
                    .filter((c: CountryData) => {
                      if (
                        searchTerms === "" ||
                        (searchTerms !== "" &&
                          (c.question
                            .toLocaleLowerCase()
                            .match(searchTerms.toLocaleLowerCase()) ||
                            c.indicatorNumbering
                              .toLocaleLowerCase()
                              .match(searchTerms.toLocaleLowerCase())))
                      ) {
                        return c;
                      }
                    })
                    .filter((c: CountryData) => {
                      if (showOnlyMissingData === false) {
                        return c;
                      } else {
                        if (hasMissingData(c) === true) {
                          return c;
                        }
                      }
                    })
                    .map((cd: CountryData, idx: number) => {
                      return (
                        <li className="row" key={`countryDataK-${idx}`}>
                          <div className="id">
                            <span>{cd.indicatorNumbering}</span>
                          </div>
                          <div className="title">{cd.question}</div>
                          <div className="warning">
                            {hasMissingData(cd) === true && (
                              <i className="fas fa-exclamation-circle"></i>
                            )}
                          </div>
                          <div className="edit">
                            <button
                              onClick={() => selectCountryData(cd)}
                              className="btn btn-select btn-primary"
                              type="button"
                            >
                              <span className="btn-label">Edit</span>
                              <span className="btn-icon">
                                <i className={`fas fa-edit`}></i>
                              </span>
                            </button>
                          </div>
                        </li>
                      );
                    })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CountryDataList;

import React from "react";
import SubmitSaveButtons from "../SubmitSaveButtons/SubmitSaveButtons";

import "./styles.scss";

const ManagementTitlePage: React.FC<{
  title: string;
  subtitle?: string | null;
  isSavable?: boolean;
  updatedBy?: string;
  updatedOn?: string;
  handleSave?: any;
  handleClose?: any;
  formDirty?: string[];
  formCanBeSave: boolean;
}> = ({
  title,
  subtitle,
  isSavable,
  updatedBy,
  updatedOn,
  handleSave,
  handleClose,
  formDirty,
  formCanBeSave,
}) => {
  return (
    <div className="management__title">
      <div className="management__title_text">
        <span
          className="title"
          dangerouslySetInnerHTML={{ __html: title }}
        ></span>
        {!!subtitle && (
          <span
            className="subtitle"
            dangerouslySetInnerHTML={{ __html: subtitle }}
          ></span>
        )}
      </div>
      {!!isSavable && (
        <SubmitSaveButtons
          updatedBy={updatedBy}
          updatedOn={updatedOn}
          handleSave={handleSave}
          handleClose={handleClose}
          formDirty={formDirty}
          formCanBeSave={formCanBeSave}
        />
      )}
    </div>
  );
};

export default ManagementTitlePage;

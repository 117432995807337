import axios from "axios";
import { CountryData } from "models/country-data.model";
import config from "modules/config";

const ApiBaseUrl = config.ApiUrl;

const getAllCountryData = async (
  accessToken: string,
  currYear: number
): Promise<{ result: CountryData[]; years: any }> => {
  const result = await axios({
    method: "get",
    url: `${ApiBaseUrl}/country-data-manager/all/${currYear}`,
    headers: { Authorization: "Bearer " + accessToken },
  });

  return result.data;
};

const updateCountryData = async (
  accessToken: string,
  countries: any
): Promise<{ result: any }> => {
  const result = await axios({
    method: "post",
    url: `${ApiBaseUrl}/country-data-manager/update`,
    headers: { Authorization: "Bearer " + accessToken },
    data: countries,
  });

  return result.data;
};

export { getAllCountryData, updateCountryData };

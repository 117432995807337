import axios from "axios";
import { ProjectIndicatorFormInterface, ProjectIndicatorInterface } from "modules/hub/models/project-indicators";
import { SpvReportingMacroeconomicInterface } from "modules/hub/models/project-macroeconomic-assumptions";
import {
  SimpleFile,
  FullFile,
  SpvReportingDocument,
} from "../../../models/spv-reporting.model";
import config from "../../config";
import { removeDiacritics } from "../utils/format";

const ApiBaseUrl = config.ApiUrl;

const getDocuments = async (
  accessToken: string
): Promise<[SpvReportingDocument]> => {
  const { data } = await axios.get(`${ApiBaseUrl}/reporting/documents`, {
    headers: { Authorization: "Bearer " + accessToken },
  });
  return data;
};

const setDocument = async (
  accessToken: string,
  document: SpvReportingDocument
): Promise<SpvReportingDocument> => {
  const { data } = await axios.post(
    `${ApiBaseUrl}/reporting/document`,
    {
      ...document,
    },
    {
      headers: { Authorization: "Bearer " + accessToken },
    }
  );
  return data;
};

const deleteDocument = async (
  accessToken: string,
  documentId: number
): Promise<any> => {
  const { data } = await axios.delete(
    `${ApiBaseUrl}/reporting/document/${documentId}`,
    {
      headers: { Authorization: "Bearer " + accessToken },
    }
  );
  return data;
};

const getBasicIndicators = async (
  accessToken: string
): Promise<ProjectIndicatorInterface> => {
  const { data } = await axios.get(`${ApiBaseUrl}/reporting/indicators`, {
    headers: { Authorization: "Bearer " + accessToken },
  });
  return data;
};

const setBasicIndicators = async (
  accessToken: string,
  indicators: ProjectIndicatorInterface
): Promise<ProjectIndicatorInterface> => {
  const { data } = await axios.post(
    `${ApiBaseUrl}/reporting/indicators`,
    {
      ...indicators,
    },
    {
      headers: { Authorization: "Bearer " + accessToken },
    }
  );
  return data;
};

const getProjectIndicatorsAll = async (
  accessToken: string
): Promise<ProjectIndicatorFormInterface> => {
  const { data } = await axios.get(`${ApiBaseUrl}/reporting/indicators/projects`, {
    headers: { Authorization: "Bearer " + accessToken },
  });
  return data;
};

const getProjectIndicators = async (
  accessToken: string,
  projectCode: string
): Promise<ProjectIndicatorFormInterface> => {
  const { data } = await axios.get(`${ApiBaseUrl}/reporting/indicators/${projectCode}`, {
    headers: { Authorization: "Bearer " + accessToken },
  });
  return data;
};

const updateProjectIndicators = async (
  accessToken: string,
  indicators: ProjectIndicatorFormInterface,
  projectCode: string
): Promise<ProjectIndicatorFormInterface> => {
  const { data } = await axios.post(
    `${ApiBaseUrl}/reporting/indicators/${projectCode}`,
    {
      ...indicators,
    },
    {
      headers: { Authorization: "Bearer " + accessToken },
    }
  );
  return data;
};

const getMacroAssumptions = async (
  accessToken: string
): Promise<SpvReportingMacroeconomicInterface> => {
  const { data } = await axios.get(`${ApiBaseUrl}/reporting/assumptions`, {
    headers: { Authorization: "Bearer " + accessToken },
  });
  return data;
};

const setMacroAssumptions = async (
  accessToken: string,
  assumptions: SpvReportingMacroeconomicInterface
): Promise<SpvReportingMacroeconomicInterface> => {
  const { data } = await axios.post(
    `${ApiBaseUrl}/reporting/assumptions`,
    {
      ...assumptions,
    },
    {
      headers: { Authorization: "Bearer " + accessToken },
    }
  );
  return data;
};

const getFiles = async (
  accessToken: string,
  type: string,
  projectSlug: string
): Promise<SimpleFile[] | FullFile[]> => {
  const result = await axios.get(
    `${ApiBaseUrl}/reporting/files/${type}/${projectSlug}`,
    {
      headers: { Authorization: "Bearer " + accessToken },
    }
  );

  return result.data;
};

const getFileByDocumentType = async (
  type: string,
  accessToken: string,
  code: any,
  projectSlug: string
): Promise<SimpleFile[] | FullFile[]> => {
  const result = await axios.get(
    `${ApiBaseUrl}/reporting/files/${type}/${projectSlug}${
      !!code ? `?codes=${JSON.stringify(code)}` : ""
    }`,
    {
      headers: { Authorization: "Bearer " + accessToken },
    }
  );

  return result.data;
};

const getTemplatesDirectories = async (
  accessToken: string
): Promise<SimpleFile[] | FullFile[]> => {
  const result = await axios.get(
    `${ApiBaseUrl}/reporting/files/templates/directories`,
    {
      headers: { Authorization: "Bearer " + accessToken },
    }
  );

  return result.data;
};

const getTemplatesByDirectory = async (
  accessToken: string,
  directory: string
): Promise<SimpleFile[] | FullFile[]> => {
  const result = await axios.post(
    `${ApiBaseUrl}/reporting/files/templates/directory`,
    { directory },
    {
      headers: { Authorization: "Bearer " + accessToken },
    }
  );

  return result.data;
};

const getTemplatesByProjects = async (
  accessToken: string,
  projects: string
): Promise<SimpleFile[] | FullFile[]> => {
  const result = await axios.post(
    `${ApiBaseUrl}/reporting/files/templates`,
    { projects },
    {
      headers: { Authorization: "Bearer " + accessToken },
    }
  );

  return result.data;
};

const prepareFile = async (
  accessToken: string,
  directory: string,
  filename: string
): Promise<string | null> => {
  const name: string = removeDiacritics(filename);
  // console.log('name ', name);
  // return name;
  const { data } = await axios.post(
    `${ApiBaseUrl}/reporting/file/prepare`,
    {
      itemId: directory,
      // filename,
      filename: name
    },
    {
      headers: { Authorization: "Bearer " + accessToken },
    }
  );

  return !!data.status && "ok" === data.status ? data.url : null;
};

const deleteFile = async (
  accessToken: string,
  itemId: string
): Promise<boolean> => {
  const result = await axios.delete(`${ApiBaseUrl}/reporting/file/${itemId}`, {
    headers: { Authorization: "Bearer " + accessToken },
  });

  return "ok" === result.data.status;
};

const renameFile = async (
  accessToken: string,
  itemId: string,
  name: string
): Promise<boolean> => {
  const result = await axios.patch(
    `${ApiBaseUrl}/reporting/file/${itemId}`,
    {
      name: name,
    },
    {
      headers: { Authorization: "Bearer " + accessToken },
    }
  );

  return "ok" === result.data.status;
};

export {
  getDocuments,
  setDocument,
  deleteDocument,
  getBasicIndicators,
  setBasicIndicators,
  getProjectIndicatorsAll,
  getProjectIndicators,
  updateProjectIndicators,
  getMacroAssumptions,
  setMacroAssumptions,
  getFileByDocumentType,
  getTemplatesDirectories,
  getFiles,
  getTemplatesByProjects,
  getTemplatesByDirectory,
  prepareFile,
  deleteFile,
  renameFile,
};

import React from "react";
import { InvestorResult } from "../../models/investor-result.model";
import "./styles.scss";

type Props = {
  investorResult: InvestorResult;
  curr: string;
  investorType: string;
};

const InvestorSection: React.FC<Props> = ({ investorResult, curr, investorType }) => {
  return (
    <div className="tool tool_lp tool_resume">
      <div className="tool_lp_inner">
        {/*  EN ATTENTE DU CLIENT
        <div className="tool_resume__logo">
          <div className="logo_inner">
            <picture>
              <source
                srcSet={
                  `//cdn.meridiam.tools/platform/lp/` +
                  cleanAndLower(investorResult.investorName) +
                  `/logo/logo.png`
                }
                media="(min-width: 960px)"
              />
              <source
                srcSet={
                  `//cdn.meridiam.tools/platform/lp/` +
                  cleanAndLower(investorResult.investorName) +
                  `/logo/logo-large.png`
                }
                media="(min-width: 768px)"
              />
              <source
                srcSet={
                  `//cdn.meridiam.tools/platform/lp/` +
                  cleanAndLower(investorResult.investorName) +
                  `/logo/logo.png`
                }
                media="(min-width: 480px)"
              />
              <img
                src={
                  `//cdn.meridiam.tools/platform/lp/` +
                  cleanAndLower(investorResult.investorName) +
                  `/logo/logo-small.png`
                }
                alt={` logo ` + investorResult.investorName}
              />
            </picture>
          </div>
        </div>
              */}
        <div className="tool_resume__metas">
          <div className="row row_metas">
            <div className="meta w100 meta_title">
              <h1>{investorResult.investorName}</h1>
            </div>
          </div>
          <div className="row row_metas">
            <div className="meta w50">
              <div className="meta_label">
                <span>Total commitment with Meridiam</span>
              </div>
              <div className="meta_value">
                <span>
                  {investorResult.totalInvestment?.toFixed(1)}M
                  {curr === "EUR" ? "€" : "$"}
                </span>
              </div>
            </div>
            <div className="meta w50">
              <div className="meta_label">
                <span>Meridiam investor since</span>
              </div>
              <div className="meta_value">
                <span>{investorResult.investorSince}</span>
              </div>
            </div>
          </div>
          <div className="row row_metas">
            {/* GIGF DONT WANT THIS TO SHOW UP */}
            {investorResult.type === "infra" &&
              <div className="meta w50">
                <div className="meta_label">
                  <span>{investorResult.investorName} is investing in</span>
                </div>
                <div className="meta_value">
                  <span>
                    {investorResult.numberOfInvestment} Meridiam fund
                    {investorResult.numberOfInvestment > 1 && <>s</>}
                  </span>
                </div>
              </div>
            }
            <div className="w50">
              <div className="metas_group">
                <div className={`meta w50 ${investorResult.type === "gigf" ? "gigf-remove-margin" : "" }`}>
                  <div className="meta_label">
                    <span>TVPI</span>
                  </div>
                  <div className="meta_value">
                    <span>{investorResult.tvpi?.toFixed(1)}x</span>
                  </div>
                </div>

                {/* GIGF DONT WANT THIS TO SHOW UP */}
                {investorResult.type === "infra" &&
                  <div className="meta w50">
                    <div className="meta_label">
                      <span>Net IRR *</span>
                    </div>
                    <div className="meta_value">
                      <span>{investorResult.projectedIRR?.toFixed(1)}%</span>
                    </div>
                  </div>
                }
                {/* REMOVE COL SDG RATING - NOT WANTED ANYMORE BY MERDIAM 
                <div className="meta w30">
                  <div className="meta_label">
                    <span>SDG Rating</span>
                  </div>
                  <div className="meta_value">
                    <span>{investorResult.averageSDG?.toFixed(1)}/5</span>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          { investorType === "infra" && 
            <p className="legend">
              <sup>*</sup> Projected Net IRR post expenses, post carried over Fund
              Life. Meridiam Investors are reminded that past performance in no
              way serves as either an indication of future results or a guarantee
              of future returns.
            </p>
          }
        </div>
      </div>
    </div>
  );
};

export default InvestorSection;

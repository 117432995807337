import React from "react";
// import ReactDOM from "react-dom";
import { ImpactKeyIndicator } from "../../models/impact-key-indicator.model";
import { AvailableFilters } from "../../models/available-filters.model";

import Slider from "react-slick";

import "./styles.scss";
import Loader from "modules/shared/components/Loader/Loader";
// import { number } from "yup";

type Props = {
  impactKeyIndicator: ImpactKeyIndicator | undefined;
  year: number;
  isImpactIndicatorLoading: boolean;
  currency: string;
  filter: AvailableFilters;
  investorType: string;
};

function SampleNextArrow(props: any) {
  const { onClick } = props;
  return (
    <button
      className={`btn btn-slider btn-slider-next btn-no-text`}
      onClick={onClick}
    >
      <span className="btn_label">Next</span>
      <span className="btn_icon">
        <i className="fal fa-angle-right"></i>
      </span>
    </button>
  );
}

function SamplePrevArrow(props: any) {
  const { onClick } = props;
  return (
    <button
      className={`btn btn-slider btn-slider-prev btn-no-text`}
      onClick={onClick}
    >
      <span className="btn_label">Previous</span>
      <span className="btn_icon">
        <i className="fal fa-angle-left"></i>
      </span>
    </button>
  );
}

const settingsSlider = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
};

const ImpactIndicatorSection: React.FC<Props> = ({
  impactKeyIndicator,
  year,
  isImpactIndicatorLoading,
  currency,
  filter,
  investorType
}) => {
  return (
    <div className="tool tool_lp tool_impact-indicator">
      <div className="tool_lp_inner">
        <div className="tool__title">
          <h2>Key Impact</h2>
          <h4 className="clr-primary">
            <small>As of Q4</small> {year}
            {/* {investorType === "gigf" ? (year+1) : year} */}
          </h4>
        </div>
        {isImpactIndicatorLoading 
        ?
          <Loader className="on" color="white" size="small" />
        : 
         ( impactKeyIndicator ? (
            <div className="tool__content">
              <div className="slider">
                <div className="slider_inner">
                  <Slider {...settingsSlider}>
                    <div>
                      <div className="slider__slide">
                        <div className="slide_inner">
                          <div className="slide__head">
                            { investorType == "infra" && 
                              <>
                                <div className="slide___icon">
                                  <svg className="svg svg-icon icon-key-impact-global">
                                    <use xlinkHref="#icon-key-impact-global"></use>
                                  </svg>
                                </div>
                                <div className="slide___title">
                                  <h3>GLOBAL</h3>
                                  <h4>Investing for the community</h4>
                                </div>
                              </>
                            }
                          </div>
                          <div className="slide__body">
                            {/* POUR FORCER ORDRE total revenues puis direct and indeirect jobs */}
                            {investorType == "gigf" &&
                              <>
                                <div key="indic1" className="data">
                                  <div className="data_label">{impactKeyIndicator.GIGF[1].name}</div>
                                  <div className="data_value">
                                    {null !== impactKeyIndicator.GIGF[1].value ? (
                                      <>
                                        {parseFloat(
                                          impactKeyIndicator.GIGF[1].value.toFixed(1)
                                        ).toLocaleString("en-GB")}{" "}
                                        {impactKeyIndicator.GIGF[1].unit}
                                      </>
                                    ) : (
                                      "Available soon"
                                    )}
                                  </div>
                                </div>
                                <div key="indic0" className="data">
                                  <div className="data_label">{impactKeyIndicator.GIGF[0].name}</div>
                                  <div className="data_value">
                                    {null !== impactKeyIndicator.GIGF[0].value ? (
                                      <>
                                        {parseFloat(
                                          impactKeyIndicator.GIGF[0].value.toFixed(1)
                                        ).toLocaleString("en-GB")}{" "}
                                        {impactKeyIndicator.GIGF[0].unit}
                                      </>
                                    ) : (
                                      "Available soon"
                                    )}
                                  </div>
                                </div>
                              </>
                            }

                            {impactKeyIndicator.Global?.map((item, index) => {
                              return (
                                <div key={index} className="data">
                                  <div className="data_label">{item.name}</div>
                                  <div className="data_value">
                                    {null !== item.value ? (
                                      <>
                                        {item.name === "Capex" ? (
                                          <>
                                            {"EUR" === currency ? (
                                              <>
                                                {parseFloat(
                                                  (item.value * 0.001).toFixed(1)
                                                ).toLocaleString("en-GB")}{" "}
                                                Bn€
                                              </>
                                            ) : (
                                              <>
                                                {parseFloat(
                                                  (
                                                    (item.value * 0.001) /
                                                    filter.currencyChange
                                                      .currPerDollar
                                                  ).toFixed(1)
                                                ).toLocaleString("en-GB")}{" "}
                                                Bn$
                                              </>
                                            )}
                                          </>
                                        ) : (
                                          <>
                                            {parseFloat(
                                              item.value.toFixed(1)
                                            ).toLocaleString("en-GB")}{" "}
                                            {item.unit}
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      "Available soon"
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                            {investorType == "gigf" && 
                              <>
                                { impactKeyIndicator.GIGF?.map((item, index) => {
                                  if(index > 1){
                                    return (
                                      <div key={index} className="data">
                                        <div className="data_label">{item.name}</div>
                                        <div className="data_value">
                                          {null !== item.value ? (
                                            <>
                                              {parseFloat(
                                                item.value.toFixed(1)
                                              ).toLocaleString("en-GB")}{" "}
                                              {item.unit}
                                            </>
                                          ) : (
                                            "Available soon"
                                          )}
                                        </div>
                                      </div>
                                    );
                                  }
                                })
                              }
                            </>
                            }
                          </div>
                        </div>
                      </div>
                    </div>

                    {impactKeyIndicator.Mobility.length > 0 && investorType == "infra" && (
                      <div>
                        <div className="slider__slide">
                          <div className="slide_inner">
                            <div className="slide__head">
                              <div className="slide___icon">
                                <i className="fas fa-subway"></i>
                              </div>
                              <div className="slide___title">
                                <h3>MOBILITY</h3>
                                <h4>Moving people and goods</h4>
                              </div>
                            </div>
                            <div className="slide__body">
                              {impactKeyIndicator.Mobility?.map((item, index) => {
                                return (
                                  <div key={index} className="data">
                                    <div className="data_label">{item.name}</div>
                                    <div className="data_value">
                                      {parseFloat(
                                        item.value.toFixed(1)
                                      ).toLocaleString("en-GB")}{" "}
                                      {item.unit}
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {impactKeyIndicator.Social.length > 0 && investorType == "infra" && (
                      <div>
                        <div className="slider__slide slide">
                          <div className="slide_inner">
                            <div className="slide__head">
                              <div className="slide___icon">
                                <i className="fas fa-users"></i>
                              </div>
                              <div className="slide___title">
                                <h3>Social Infrastructure</h3>
                                <h4>
                                  Catering community needs for public facilities
                                </h4>
                              </div>
                            </div>
                            <div className="slide__body">
                              {impactKeyIndicator.Social.map((item, index) => {
                                return (
                                  <div key={index} className="data">
                                    <div className="data_label">{item.name}</div>
                                    <div className="data_value">
                                      {null !== item.value ? (
                                        <>
                                          {parseFloat(
                                            item.value.toFixed()
                                          ).toLocaleString("en-GB")}{" "}
                                          {item.unit}
                                        </>
                                      ) : (
                                        <>-</>
                                      )}
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {impactKeyIndicator.Energy.length > 0 && investorType == "infra" && (
                      <div>
                        <div className="slider__slide">
                          <div className="slide_inner">
                            <div className="slide__head">
                              <div className="slide___icon">
                                <i className="fas fa-bolt"></i>
                              </div>
                              <div className="slide___title">
                                <h3>Energy Transition and Environment</h3>
                                <h4>Contributing to a low carbon economy</h4>
                              </div>
                            </div>
                            <div className="slide__body">
                              {impactKeyIndicator.Energy.map((item, index) => {
                                return (
                                  <div key={index} className="data">
                                    <div className="data_label">{item.name}</div>
                                    <div className="data_value">
                                      {parseFloat(
                                        item.value.toFixed(1)
                                      ).toLocaleString("en-GB")}{" "}
                                      {item.unit}
                                      {item.value > 1 && <></>}
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Slider>
                </div>
              </div>
            </div>
          ) : (
            <p className="no-data-available">No data available.</p>
          ))
        }
      </div>
    </div>
  );
};

export default ImpactIndicatorSection;

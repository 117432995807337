import axios from "axios";
import { Project } from "models/project.model";
import config from "modules/config";

const ApiBaseUrl = config.ApiUrl;

const getAllProjects = async (accessToken: string): Promise<Project[]> => {
  const result = await axios({
    method: "get",
    url: `${ApiBaseUrl}/project`,
    headers: { Authorization: "Bearer " + accessToken },
  });

  return result.data;
};

const getProjectByCode = async (
  accessToken: string,
  code: string,
  type?: string,
  year?: any
): Promise<Project[]> => {
  const result = await axios({
    method: "get",
    url: `${ApiBaseUrl}/project/detail/${code.toUpperCase()}${
      !!type ? `?type=${type}` : ""
    }${!!year ? `${!!type ? "&" : "?"}year=${year}` : ""}`,
    headers: { Authorization: "Bearer " + accessToken },
  });

  return result.data;
};

const getAllProjectsByYear = async (
  accessToken: string,
  year: number,
  type?: string
): Promise<Project[]> => {
  const result = await axios({
    method: "get",
    url: `${ApiBaseUrl}/project/${year}${!!type ? `?type=${type}` : ""}`,
    headers: { Authorization: "Bearer " + accessToken },
  });

  return result.data;
};

const getAllProjectsSPVManager = async (
  accessToken: string,
  currYear: number
): Promise<any> => {
  const result = await axios({
    method: "get",
    url: `${ApiBaseUrl}/spv-manager/all/${currYear}`,
    headers: { Authorization: "Bearer " + accessToken },
  });

  return result.data;
};

const saveProjectFromSPVManager = async (
  accessToken: string,
  currYear: number,
  project: Project,
  irreleventIndicatorId: number[]
): Promise<any> => {
  const result = await axios({
    method: "post",
    url: `${ApiBaseUrl}/spv-manager/update/project/${currYear}`,
    headers: { Authorization: "Bearer " + accessToken },
    data: { modifiedProject: project, materiality: irreleventIndicatorId },
  });

  return result.data;
};


export {
  getProjectByCode,
  getAllProjects,
  getAllProjectsByYear,
  getAllProjectsSPVManager,
  saveProjectFromSPVManager,
};

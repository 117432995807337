import React, { useEffect, useState } from "react";
import ProjectsListItem from "./UserSettingsSurveySdgItem";
import "./styles.scss";
import { cloneDeep } from "lodash";
import { Project } from "models/project.model";

const UserSettingsSurveySdg: React.FC<{
  modifiedCurrUser: any;
  setModifiedCurrUser: any;
  setOpenListModal: any;
  projects: Project[];
  setFormDirty: any;
  formDirty: string[];
  sendEmailInvitation: any;
}> = ({
  modifiedCurrUser,
  setModifiedCurrUser,
  setOpenListModal,
  projects,
  setFormDirty,
  formDirty,
  sendEmailInvitation
}) => {
  const [
    isSuperAdminForThisModule,
    setIsSuperAdminForThisModule,
  ] = useState<boolean>(false);

  const handleChange = (field: string, type: string) => {
    if (type === "setSuperAdmin") {
      if (!formDirty.includes(field)) {
        let newList = formDirty;
        newList.push(field);
        setFormDirty(newList);
      }

      let updatedU = cloneDeep(modifiedCurrUser);
      if (isSuperAdminForThisModule) {
        updatedU.privileges["survey-sdg"] = [];
        setIsSuperAdminForThisModule(false);
      } else {
        updatedU.privileges["survey-sdg"] = [
          {
            details: { type: "project" },
            id: null,
            module: "survey-sdg",
            role: "superadmin",
          },
        ];
        setIsSuperAdminForThisModule(true);
      }
      setModifiedCurrUser(updatedU);
    }
  };

  const updateProjectPrivilege = (projectCode: string, role: string) => {
    let newDirtyList = cloneDeep(formDirty);
    newDirtyList.push("survey-" + projectCode);
    setFormDirty(newDirtyList);

    let newUser = cloneDeep(modifiedCurrUser);
    newUser.privileges["survey-sdg"].map((priv: any) => {
      if (priv.details["projectCode"] === projectCode) {
        priv.role = role;
      }
    });
    setModifiedCurrUser(newUser);
  };

  const removePrivilege = (projectCode: string) => {
    let newDirtyList = cloneDeep(formDirty);
    newDirtyList.push("survey-" + projectCode);
    setFormDirty(newDirtyList);

    let newUser = cloneDeep(modifiedCurrUser);
    let newListPrivilege = newUser.privileges["survey-sdg"];
    newListPrivilege = newUser.privileges["survey-sdg"].filter(
      (priv: any) => priv.details["projectCode"] !== projectCode
    );
    newUser.privileges["survey-sdg"] = newListPrivilege;
    setModifiedCurrUser(newUser);
  };

  useEffect(() => {
    if (
      modifiedCurrUser &&
      modifiedCurrUser.privileges &&
      modifiedCurrUser.privileges["survey-sdg"] &&
      modifiedCurrUser.privileges["survey-sdg"].length > 0
    ) {
      if (modifiedCurrUser.privileges["survey-sdg"][0].role === "superadmin") {
        setIsSuperAdminForThisModule(true);
      }
    }
  }, []);

  return (
    <div className="user-settings-survey-sdg">
      <h3>— general settings</h3>

      <i className="italic">Account status</i>
      <div className="toggle-container">
        <div className="toggle">
          <input
            type="checkbox"
            id="active"
            className="toggle-input"
            onChange={() => handleChange("superadminsurvey", "setSuperAdmin")}
            checked={isSuperAdminForThisModule}
          />
          <div className="toggle-switcher"></div>
        </div>
        <span className="label">Grant super admin privilege</span>
      </div>
      <div className={`state ${isSuperAdminForThisModule ? "warn" : ""}`}>
        {isSuperAdminForThisModule && (
          <>
            <i className="fas fa-exclamation-circle"></i>
            Please be aware that user with super admin access will have access
            to all Survey SDG.
          </>
        )}
      </div>

      <div className="separator"></div>

      <button
        className="btn btn-select btn-primary btn-send"
        type="button"
        onClick={() => sendEmailInvitation("survey-sdg")}
      >
        <span className="btn-label">Send survey invitation</span>
        <span className="btn-icon">
          <i className={`fas fa-envelope`}></i>
        </span>
      </button>
     {/*  <div
        className={`state ${
          !!modifiedCurrUser && !!modifiedCurrUser.invitationEmails
            ? "valid"
            : "warn"
        }`}
      >
        {!!modifiedCurrUser &&
        !!modifiedCurrUser.ininvitationEmails &&
        !!modifiedCurrUser.invitationEmails["survey-sdg"] ? (
          <i className="fas fa-check-circle"></i>
        ) : (
          <i className="fas fa-exclamation-circle"></i>
        )}
        <b>Survey invitation</b> :{" "}
        {!!modifiedCurrUser &&
        !!modifiedCurrUser.invitationEmails &&
        !!modifiedCurrUser.invitationEmails["survey-sdg"]
          ? DateTime.fromISO(
              modifiedCurrUser.invitationEmails["survey-sdg"].creationDate
            ).toFormat("LL/dd/yyyy")
          : "Not send yet."}
      </div> */}

      {!isSuperAdminForThisModule && (
        <>
          <div className="separator"></div>

          <div className="title-with-btn">
            <h3>— user privileges by project</h3>
            <button
              className="btn btn-select btn-primary"
              type="button"
              onClick={() => setOpenListModal("project")}
            >
              <span className="btn-label">Add/remove projects</span>
              <span className="btn-icon">
                <i className={`fas fa-arrow-right`}></i>
              </span>
            </button>
          </div>

          <ul className="list">
            <li className="row filters">
              <div className="project">
                <i className="label">Project</i>
              </div>
              <div className="group">
                <i className="label">Set a role</i>
              </div>
              <div className="delete">
                <i className="label">Delete</i>
              </div>
            </li>

            {!!modifiedCurrUser &&
              !!modifiedCurrUser.privileges &&
              !!modifiedCurrUser.privileges["survey-sdg"] &&
              modifiedCurrUser.privileges["survey-sdg"].length > 0 &&
              modifiedCurrUser.privileges[
                "survey-sdg"
              ].map((privilege: any, idx: number) => (
                <ProjectsListItem
                  key={`projectK-${idx}`}
                  privilege={privilege}
                  projects={projects}
                  updateProjectPrivilege={updateProjectPrivilege}
                  formDirty={formDirty}
                  removePrivilege={removePrivilege}
                />
              ))}
          </ul>
        </>
      )}
    </div>
  );
};

export default UserSettingsSurveySdg;

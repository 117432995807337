import { TeamUserData } from "models/team-user-data.models";
import React from "react";
import "./styles.scss";

type TeamUserProps = {
  cancel: () => void;
  deleteUser?: any;
  editUser?: any;
  isAddMode: boolean;
  updatingUserId: number;
  editor?: {
    id?: number;
    firstName?: string;
    lastName?: string;
    login?: string;
    platformRole?: string;
    role?: string;
  };
  user?: TeamUserData;
  userId?: number;
  modalId: string;
  formik: any;
  module: string;
  isMe: boolean;
};

const TeamUser = ({
  cancel,
  deleteUser,
  editUser,
  isAddMode,
  updatingUserId,
  editor,
  user,
  modalId,
  formik,
  module,
  isMe,
}: TeamUserProps) => {
  return (
    <>
      <div className="col col-1">
        <div className="cel">
          {user && user.id !== updatingUserId && <span>{user.firstName}</span>}
          {((isAddMode && !user) || (user && updatingUserId === user.id)) && (
            <span>
              <input
                type="text"
                name="firstName"
                placeholder="Add a firstname"
                required
                onChange={formik.handleChange}
                value={formik.values.firstName}
              />
              {formik.errors?.firstName && (
                <div className="form-error">
                  <span>{formik.errors.firstName}</span>
                </div>
              )}
            </span>
          )}
        </div>
      </div>
      <div className="col col-2">
        <div className="cel">
          {user && user.id !== updatingUserId && <span>{user.lastName}</span>}
          {((isAddMode && !user) || (user && updatingUserId === user.id)) && (
            <span>
              <input
                type="text"
                name="lastName"
                placeholder="Add a lastname"
                required
                onChange={formik.handleChange}
                value={formik.values.lastName}
              />
              {formik.errors?.lastName && (
                <div className="form-error">
                  <span>{formik.errors.lastName}</span>
                </div>
              )}
            </span>
          )}
        </div>
      </div>
      <div className="col col-3">
        <div className="cel">
          {user && user.id !== updatingUserId && <span>{user.login}</span>}
          {((isAddMode && !user) || (user && updatingUserId === user.id)) && (
            <span>
              <input
                type="email"
                name="login"
                placeholder="Add an email"
                required
                readOnly={user && updatingUserId === user.id ? true : false}
                onChange={formik.handleChange}
                value={formik.values.login}
              />
              {formik.errors?.login && (
                <div className="form-error">
                  <span>{formik.errors.login}</span>
                </div>
              )}
            </span>
          )}
        </div>
      </div>
      <div className="col col-4">
        <div className="cel">
          {isMe ? (
            <span>Admin</span>
          ) : (isAddMode && !user) || (user && updatingUserId === user.id) ? (
            <select
              className="btn btn-select"
              name="role"
              value={formik.values.role}
              onChange={formik.handleChange}
            >
              <option value="admin">Admin</option>
              {module !== "lp-dashboard" && (
                <option value="contributor">Contributor</option>
              )}
              {module !== "reporting" && (
                <option value="readonly">Read only</option>
              )}
            </select>
          ) : (
            <span>{!!user ? user.role : "Read only"}</span>
          )}
        </div>
      </div>
      <div className="col col-5">
        <div className="cel">
          <span>
            {user &&
            "super admin" !== user.role &&
            user &&
            updatingUserId !== user.id ? (
              <i className="fas fa-edit" onClick={editUser}></i>
            ) : (
              ((isAddMode && !user) ||
                (user && updatingUserId === user.id)) && (
                <i className="fas fa-times" onClick={cancel}></i>
              )
            )}
          </span>
        </div>
      </div>
      <div className="col col-6">
        <div className="cel">
          <span>
            {user &&
            editor &&
            editor.id !== user.id &&
            !isAddMode &&
            (-1 === updatingUserId || user.id !== updatingUserId) &&
            (!user ||
              (user && "super admin" !== user.role && "god" !== user.role)) ? (
              <i
                className="fas fa-trash"
                data-uk-toggle={`target: #${modalId};`}
                onClick={deleteUser}
              ></i>
            ) : (
              "—"
            )}
          </span>
        </div>
      </div>
    </>
  );
};

export default TeamUser;

import React from "react";
import SubmitSaveButtons from "../SubmitSaveButtons/SubmitSaveButtons";

import "./styles.scss";

const ManagementFooterPage: React.FC<{
  isSavable?: boolean;
  updatedBy?: string;
  updatedOn?: string;
  handleSave?: any;
  handleClose?: any;
  formDirty?: string[];
  formCanBeSave?: boolean;
}> = ({
  isSavable,
  updatedBy,
  updatedOn,
  handleSave,
  handleClose,
  formDirty,
  formCanBeSave
}) => {
  return (
    <div className="management__footer">
      {!!isSavable && (
        <SubmitSaveButtons
          updatedBy={updatedBy}
          updatedOn={updatedOn}
          handleSave={handleSave}
          handleClose={handleClose}
          formDirty={formDirty}
          formCanBeSave={formCanBeSave}
        />
      )}
    </div>
  );
};

export default ManagementFooterPage;

import { RootState, ThunkDispatch } from "models/store.models";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Loader from "../../shared/components/Loader/Loader";

import Header from "modules/shared/components/Header/Header";
import "./styles.scss";
import { history } from "modules/shared/services/history";

import TitlePage from "../../shared/components/Title/TitlePage/TitlePage";
import CardDashboard from "../../shared/components/Cards/CardDashboard/CardDashboard";

const mapStateToProps = ({ session }: RootState) => ({
  user: session?.sessionData?.userData,
});
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({});
type DashboardProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const DashboardRoutes: React.FC<DashboardProps> = ({ user }) => {
  if (!user) {
    history.push("/auth/login");
  }


  // TOOL 2 = rosace - sdg impact synthesis
  const [accessSdgSynthesis, setAccessSdgSynthesis] = useState<boolean>(false);
  // TOOL 3 = sdg contribution
  const [accessSdgContribution, setAccessSdgContribution] = useState<boolean>(false);
  
  // DEV ONLY | React | Survey + Survey Manager (= tool 1)
  const [accessSurveyEditor, setAccessSurveyEditor] = useState<boolean>(false); 
  const [accessSurveyManager, setAccessSurveyManager] = useState<boolean>(false); 

  // ANGULAR | Survey + Survey Manager (= tool 1)
  const [accessSurveyEditorAngular, setAccessSurveyEditorAngular] = useState<boolean>(false);
  const [accessSurveyManagerAngular, setAccessSurveyManagerAngular] = useState<boolean>(false); 

  const [accessLpDashboard, setAccessLpDashboard] = useState<boolean>(false);
  const [accessUserManager, setAccessUserManager] = useState<boolean>(false);
  const [accessReporting, setAccessReporting] = useState<boolean>(false);

  // SPV MANAGER TOOL
  const [accessSPVManager, setAccessSPVManager] = useState<boolean>(false);
  const [accessCountryList, setAccessCountryList] = useState<boolean>(false);
  const [accessCountryData, setAccessCountryData] = useState<boolean>(false);


  const [userGrid, setUserGrid] = useState<number>(10);
  const [showLoading, setShowLoading] = useState<boolean>(false);


  useEffect(() => {
    let timer = setTimeout(() => setShowLoading(true), 50);

    if (!!user) {
      switch (user.platformRole) {
        case "technical-admin":
          setUserGrid(0);

          // ANGULAR | survey  + tool 1
          setAccessSurveyEditorAngular(true);
          setAccessSurveyManagerAngular(true);

          // DEV ONLY | survey and tool 1 react version
          setAccessSurveyEditor(true);
          setAccessSurveyManager(true);

          setAccessSdgSynthesis(true); // tool 2 = rosace
          setAccessSdgContribution(true); // tool 3 = sdg contribution
          setAccessUserManager(true); // User manager
          setAccessLpDashboard(true); // LP
          setAccessReporting(true); // REPORTING

          // SPV Manager
          setAccessSPVManager(true);
          setAccessCountryData(true);
          setAccessCountryList(true);
          break;
        case "global-admin":
          setUserGrid(1);
          // ANGULAR | survey  + tool 1
          setAccessSurveyEditorAngular(true);
          setAccessSurveyManagerAngular(true);

          setAccessSdgSynthesis(true); // tool 2 = rosace
          setAccessSdgContribution(true); // tool 3 = sdg contribution
          setAccessUserManager(true); // User manager
          setAccessLpDashboard(true); // LP
          setAccessReporting(true); // REPORTING

          // SPV Manager
          setAccessSPVManager(true);
          setAccessCountryData(true);
          setAccessCountryList(true);
          break;
        case "stakeholder":
        default:
          setUserGrid(10);
          let countModule = 0;
          for (let module in user.privileges) {
            if (module) {
              if (module === "esg-tool") {
                user.privileges[module].forEach( () => {
                  countModule++;
                });
              } else {
                countModule++;
              }
            }
          }

          // check droit sur survey-sdg
          if (
            user.privileges["survey-sdg"] &&
            user.privileges["survey-sdg"].length > 0
          ) {
            let isAdminForOne = false;
            user.privileges["survey-sdg"].forEach( p => {
              if(p.role === "admin"){
                isAdminForOne = true;
              }
            });
            if (countModule === 1 && isAdminForOne === false) {
              if (process.env.NODE_ENV !== "development") {
                history.push("/survey-sdg/platform/dashboard");
              } else {
                console.log(
                  "DEV ENV. ONLY ACCESS TO SURVEY EDITOR -> GOT TO : LOCALHOST:4200"
                );
              }
            } else {
              setAccessSurveyEditorAngular(true);
            }
          }

          // Project Leader View => can access to tool 2 (rosace) & 3 (sdg contribution) if the user is admin of at least 1 survey
          if (
            user.privileges["survey-sdg"] &&
            user.privileges["survey-sdg"].length > 0
          ) {
            user.privileges["survey-sdg"].map(p => {
              if(p.role === "admin"){
                setAccessSdgSynthesis(true);
                setAccessSdgContribution(true);
              }
            });
          }

          // tool 1,2, 3
          if (
            user.privileges["esg-tool"] &&
            user.privileges["esg-tool"].length > 0
          ) {
            if (countModule === 1) {
              let elem: any = user.privileges["esg-tool"][0].details;
              switch (elem["toolName"]) {
                case "impact-synthesis":
                  history.push("/platform/sdg-impact-synthesis");
                  break;
                case "sdg-contribution":
                  history.push("/platform/sdg-contribution");
                  break;
                case "survey-management":
                  setAccessSurveyManagerAngular(true);
                  break;
              }
            } else {
              user.privileges["esg-tool"].map((p: any) => {
                if (p.details["toolName"] === "impact-synthesis") {
                  setAccessSdgSynthesis(true);
                }

                if (p.details["toolName"] === "sdg-contribution") {
                  setAccessSdgContribution(true);
                }

                if (p.details["toolName"] === "survey-management") {
                  setAccessSurveyManagerAngular(true);
                }
              });
            }
          }

          // check droit sur lp
          if (
            user.privileges["lp-dashboard"] &&
            user.privileges["lp-dashboard"].length > 0
          ) {
            let isAdminForOne = false;

            // Project Leader View => can access to tool 2 (rosace) & 3 (sdg contribution) if the user is admin of at least 1 LP
            user.privileges["lp-dashboard"].map(p => {
              if(p.role === "admin"){
                isAdminForOne = true;
                setAccessSdgSynthesis(true);
                setAccessSdgContribution(true);
              }
            });

            if (countModule === 1 && isAdminForOne === false) {
              history.push("/limited-partners");
            } else {
              setAccessLpDashboard(true);
            }
          }

          // check droit sur user manager
          if (
            user.privileges["user-manager"] &&
            user.privileges["user-manager"].length > 0
          ) {
            if (countModule === 1) {
              history.push("/user-management/users");
            } else {
              setAccessUserManager(true);
            }
          }

          // check droit sur reporting
          if (
            user.privileges["reporting"] &&
            user.privileges["reporting"].length > 0
          ) {
            if (countModule === 1) {
              history.push("/spv-reporting");
            } else {
              setAccessReporting(true);
            }
          }

          // check droit sur SPV Manager
          if (
            user.privileges["spv-manager"] &&
            user.privileges["spv-manager"].length > 0
          ) {
            if (user.privileges["spv-manager"][0].role !== "superadmin") {
              setAccessSPVManager(true);
              setAccessSurveyEditorAngular(true);
            } else {
              setAccessSPVManager(true);
              setAccessCountryData(true);
              setAccessCountryList(true);
              setAccessSurveyEditorAngular(true);
            }
          }
          break;
      }
    }

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <>
      <Loader
        className={showLoading ? "off" : "on"}
        color="multicolors"
        size="large"
      />
      <div className="header-wrapper" data-uk-sticky="media:768;">
        <Header
          titleContext="Platform"
          colorContext={"seaLight"}
          classNameToolbarTop="lp"
          tool={`dashboard`}
        />
      </div>

      <div className="page page-dashboard" data-theme="seaLight">
        <div className="page_inner">
          <TitlePage
            content={`
              <b>Dashboard</b>
              <small> &amp; </small>
              <b>Reporting</b>
            `}
          />

          {/* DEV PART */}
          <div className="grid grid-dashboard" data-grid={`mode-${userGrid}`}>
            <div className="cards just-tech">
              {accessSurveyEditor && (
                <CardDashboard
                  className={`card-admin`}
                  iconClass={`svg icon-survey-editor`}
                  iconMode={`svg`}
                  icon={`icon-survey-editor`}
                  label={`
                      <span>Survey</span>
                      <span>Editor</span>
                    `}
                  link={`/survey-sdg-react/survey/dashboard`}
                />
              )}

              {accessSurveyManager && (
                <CardDashboard
                  className={`card-admin`}
                  iconClass={`svg icon-esg-sdg-survey-management`}
                  iconMode={`svg`}
                  icon={`icon-esg-sdg-survey-management`}
                  label={`
                  <span>survey</span>
                  <span>management</span>
                  `}
                  link={`/platform/survey-management`}
                />
              )}
            </div>

            <div className="grid_inner">
              {/* TOOL 2 = Rosace = SDG impact synthesis card */}
              {accessSdgSynthesis && (
                <CardDashboard
                  className={`card-dark`}
                  iconClass={`svg icon-esg-sdg-survey-management`}
                  iconMode={`svg`}
                  icon={`icon-sdg-impact-synthesis`}
                  label={`
                      <span>SDG impact</span>
                      <span>synthesis</span>
                    `}
                  link={`/platform/sdg-impact-synthesis`}
                />
              )}

              {/* TOOL 3 = SDG contribution card */}
              {accessSdgContribution && (
                <CardDashboard
                  className={`card-dark`}
                  iconClass={`svg icon-esg-sdg-survey-management`}
                  iconMode={`svg`}
                  icon={`icon-sdg-contribution`}
                  label={`
                    <span>SDG</span>
                    <span>contribution</span>
                  `}
                  link={`/platform/sdg-contribution`}
                />
              )}

              {/* Angular | Survey editor card */}
              {accessSurveyEditorAngular && (
                <CardDashboard
                  className={`card-dark`}
                  iconClass={`svg icon-survey-editor`}
                  iconMode={`svg`}
                  icon={`icon-survey-editor`}
                  label={`
                  <span>survey</span>
                  <span>editor</span>
                  `}
                  link={`/survey-sdg/survey/dashboard`}
                  route="SurveyLink"
                />
              )}

              {/* Angular | TOOL 1 = survey management card */}
              {accessSurveyManagerAngular && (
                <CardDashboard
                  className={`card-dark`}
                  iconClass={`svg icon-esg-sdg-survey-management`}
                  iconMode={`svg`}
                  icon={`icon-esg-sdg-survey-management`}
                  label={`
                  <span>survey</span>
                  <span>management</span>
                  `}
                  // link={`/platform/survey-management`} --> REACT
                  link={`/survey-sdg/platform/survey-management`}
                  route="SurveyLink"
                />
              )}

              {/* LP Dashboard */}
              {accessLpDashboard && (
                <CardDashboard
                  className={`card-dark`}
                  iconClass={`svg icon-lp-dashboard`}
                  iconMode={`svg`}
                  icon={`icon-lp-dashboard`}
                  label={`
                  <span>LP</span>
                  <span>Dashboard</span>
                  `}
                  link={`/limited-partners`}
                />
              )}

              {/* User management card */}
              {accessReporting && (
                <CardDashboard
                  className={`card-dark`}
                  iconClass={`svg icon-reporting`}
                  iconMode={`svg`}
                  icon={`icon-reporting`}
                  label={`
                    <span>Reportl,</span>
                    <span>spv reporting</span>
                    `}
                  link={`/spv-reporting`}
                />
              )}

              {/* SPV Manager card */}
              {accessSPVManager && (
                <CardDashboard
                  className={`card-medium`}
                  iconClass={`svg icon-spv-manager`}
                  iconMode={`svg`}
                  icon={`icon-spv-manager`}
                  label={`
                    <span>SPV Manager</span>
                    `}
                  link={`/management/spv-manager`}
                />
              )}

              {/* Country list card */}
              {accessCountryList && (
                <CardDashboard
                  className={`card-medium`}
                  iconClass={`svg icon-country-list`}
                  iconMode={`svg`}
                  icon={`icon-country-list`}
                  label={`
                    <span>Country List</span>
                    `}
                  link={`/management/country-list`}
                />
              )}

              {/* Country data card */}
              {accessCountryData && (
                <CardDashboard
                  className={`card-medium`}
                  iconClass={`svg icon-country-data`}
                  iconMode={`svg`}
                  icon={`icon-country-data`}
                  label={`
                    <span>Country Data</span>
                    `}
                  link={`/management/country-data`}
                />
              )}

              {/* User management card */}
              {accessUserManager && (
                <CardDashboard
                  className={`card-light`}
                  iconClass={`svg icon-user-management`}
                  iconMode={`svg`}
                  icon={`icon-user-management`}
                  label={`
                  <span>Users</span>
                  <span>management</span>
                  `}
                  link={`/user-management`}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardRoutes);

import * as XLSX from "xlsx";

const FileSaver = require("file-saver");
const EXCEL_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const EXCEL_EXTENSION = ".xlsx";

export const exportAsExcelFile = (datas: any, excelFileName: string): void => {
  const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(datas);
  const workbook: XLSX.WorkBook = {
    Sheets: { data: worksheet },
    SheetNames: ["data"],
  };
  const excelBuffer: any = XLSX.write(workbook, {
    bookType: "xlsx",
    type: "array",
  });
  saveAsExcelFile(excelBuffer, excelFileName);
};

export const saveAsExcelFile = (buffer: any, fileName: string): void => {
  const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
  FileSaver.saveAs(data, fileName + "_export_" + new Date() + EXCEL_EXTENSION);
};

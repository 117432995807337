import { DateTime } from "luxon";

const formattedDate = (date: string, format: any = DateTime.DATE_FULL) => {
  if (!date.match(/T\d{2}:\d{2}:\d{2}(\.\d+)?Z?$/)) {
    date = `${date}T00:00:00`;
  }

  return DateTime.fromISO(date.replace(/Z$/, "")).toLocaleString(format);
};

const removeDiacritics = (text: any) => {
  let replacements: any = {
    'ẚ': 'a',
    'æ': 'ae',
    'Æ': 'Ae',
    'đ': 'd',
    'Đ': 'D',
    'ı': 'i',
    'ȷ': 'j',
    'ŀ': 'l',
    'Ŀ': 'L',
    'Ł': 'L',
    'ł': 'l',
    'œ': 'oe',
    'Œ': 'Oe',
    'ᵫ': 'ue',
    'ß': 'ss',
    'ẞ': 'SS',
    'ø': 'o',
    "'": " ",
    '"': " ",
    "’": " ",
    "`": " "
  }
  
  return text
    // Normalise to NFD Unicode normal form to decompose combined characters into the combination of simple ones
    // src: https://stackoverflow.com/a/37511463/3408342
    .normalize('NFD')
    // Remove all diacritics
    .replace(/\p{Diacritic}/gu, '')
    // Replace letters without diacritics (ligatures, etc)
    // src: https://stackoverflow.com/a/22513545/3408342
    .replace(/[^A-Za-z0-9\s!"#$%&\'\(\)\*\+\,-.\/:;<=>?@\[\\\]^_`\{\|\}~£¬]/g, (letter: string) => {
      return replacements[letter] || letter
    })
  }

export { formattedDate, removeDiacritics };

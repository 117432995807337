import React from "react";
import UIkit from "uikit";

interface ProjectIndicatorsSelectProps {
  section: any;
  indicator: any;
  handleChange: any;
  value?: any;
}

const ProjectIndicatorsSelect = ({
  section,
  indicator,
  handleChange,
  value
}: ProjectIndicatorsSelectProps) => {

  // const [selectValue, setSelectvalue] = useState<any>(!!value ? value : null);

  const handle = (val: any) => {
    switch(indicator.id){
      case 'projectStage':
        handleChange(section.slug, val);
      break;
      default:
        handleChange(section.slug, val, indicator.id);
    }
  };

  return (
    <div className="selector">
      <p className="label">{indicator.name}</p>
      <div className="select">
        <button
          className="btn btn-select btn-primary full btn-rounded"
          type="button"
        >
          <span className="btn_label">
            {(!!value && !!value.name)
              ? value.name
              : "project-stage" === section.id
              ? "Choose a stage"
              : "———"}
          </span>
          <span className="btn_icon"></span>
        </button>
        <div
          className={`drop drop-select select-${indicator.id}`}
          data-uk-dropdown="pos: top-left; mode: click; delay-hide: 100"
        >
          <ul className="group lvl0 uk-nav uk-dropdown-nav">
              {!!indicator.reset &&
                <li className="item">
                  <span
                      className="clickable"
                      data-uk-toggle={`target: .select-${indicator.id}`}
                      onClick={() => {
                        handle("");
                        UIkit.dropdown(`.select-${indicator.id}`).hide();
                      }}
                    >
                      {"project-stage" === section.id
                        ? "Choose a stage"
                        : "———"}
                    </span>
                </li>
              }

            {!!indicator.selectValues &&
              indicator.selectValues.length > 0 &&
              indicator.selectValues.map((val: any) => {
                return (
                  <li key={`val-${val.id}`} className="item">
                    <span
                      className="clickable"
                      data-uk-toggle={`target: .select-${indicator.id}`}
                      onClick={() => {
                        handle(val);
                        UIkit.dropdown(`.select-${indicator.id}`).hide();
                      }}
                    >
                      {val.name}
                    </span>
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ProjectIndicatorsSelect;

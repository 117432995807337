import React from "react";

interface ListSelectionModalItemProps {
  item: any; // Project or Investor
  listSelection: any;
  selectThisItem: any;
  type: string;
}

const ListSelectionModalItem = ({
  item,
  listSelection,
  selectThisItem,
  type,
}: ListSelectionModalItemProps) => {
  const isThisItemSelected = () => {
    let result = false;
    if (!!listSelection && listSelection.length > 0) {
      listSelection.map((it: any) => {
        // console.log(it, item);

        if (type === "project") {
          if (it["projectCode"] === item.projectCode) {
            result = true;
          }
        } else {
          if (it["investorId"] === item.investorId) {
            result = true;
          }
        }
      });
    }

    return result;
  };

  return (
    <li
      className={`row ${isThisItemSelected() ? "checked" : ""}`}
      onClick={() =>
        selectThisItem(
          item.id,
          type === "project" ? item.projectCode : item.investorId
        )
      }
    >
      <div className="id">
        <span>{item.id}</span>
      </div>
      <div className="name">
        {type === "project" ? item.projectName : item.investorName}
      </div>
      <div className="status"></div>
    </li>
  );
};

export default ListSelectionModalItem;

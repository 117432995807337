import React from "react";
import { Link } from "react-router-dom";

import "./styles.scss";

const CardDashboard: React.FC<{
  className?: string;
  iconClass?: string;
  iconMode?: string;
  icon?: string;
  label?: string;
  link?: string;
  route?: any;
}> = ({ className, iconClass, iconMode, icon, label, link, route }) => {
  const SurveyLink = React.forwardRef((props, ref) => {
    // console.log(props);
    return <a {...props}>{props.children}</a>;
  });

  return (
    <div className={`card card-tool ${className}`}>
      <div className="card_inner">
        <div className="icon">
          {"svg" === iconMode ? (
            <svg className={iconClass}>
              <use xlinkHref={`#${icon}`}></use>
            </svg>
          ) : (
            <i className={iconClass}></i>
          )}
        </div>
        <div className="category">
          {!!label && (
            <div
              className="category-name"
              dangerouslySetInnerHTML={{ __html: label }}
            ></div>
          )}
        </div>
        {!!link && (
          <>
            {!!route && "SurveyLink" === route ? (
              <>
                <div className="wrapp-cta">
                  <Link
                    to={link}
                    className="btn btn-small animated btn-bordered"
                    component={SurveyLink}
                  >
                    <span className="btn-icon">
                      <i className="fas fa-chevron-right"></i>
                    </span>
                  </Link>
                </div>
                <Link
                  to={link}
                  className="btn next-animated btn-full"
                  component={SurveyLink}
                ></Link>
              </>
            ) : (
              <>
                <div className="wrapp-cta">
                  <Link
                    to={link}
                    className="btn btn-small animated btn-bordered"
                  >
                    <span className="btn-icon">
                      <i className="fas fa-chevron-right"></i>
                    </span>
                  </Link>
                </div>
                <Link to={link} className="btn next-animated btn-full"></Link>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default CardDashboard;

import React from "react";
import { Link } from "react-router-dom";

import "./styles.scss";

interface SPVReportingProjectListItemProps {
  project: any;
  filterDocType: any;
}

const SPVReportingProjectListItem = ({
  project,
  filterDocType,
}: SPVReportingProjectListItemProps) => {

  return (
    <>
      <li
        className={`row ${(!!filterDocType && "all" === filterDocType.id) ? "" : "not-all"} ${!project.indValidation && 0 === project.docCompletion ? "warn" : "" } ${(!!project.indValidation && 0 < project.totalDocCompletion && project.totalDocCompletion === project.docCompletion) ? "valid" : "" }`}>
        <div className="project">{project.name}</div>
        <div className="project-indicators">
          {!!project.indValidation && !!project.indValidation.isComplete ? 
            <span>YES <i className={`fas fa-check-circle`}></i></span>
           : 
            <span>NO <i className={`fas fa-exclamation-circle`}></i></span>
          }
        </div>
        <div className="project-documents">
          {(!!filterDocType && "all" === filterDocType.id) ? 
            <>
              <div className="completion-container">
                <span className="completion-bar" style={{width: 0 < project.totalDocCompletion ? (project.totalDocCompletion * 100) / project.docCompletion + "px" : "0px"}} ></span>
              </div>
              {0 === project.totalDocCompletion && 
                <span><i className={`fas fa-exclamation-circle`}></i></span>
              }
              {0 < project.docCompletion && project.docCompletion === project.totalDocCompletion ? 
                <span><i className={`fas fa-check-circle`}></i></span>
               : 
                <span>{project.totalDocCompletion}/{project.docCompletion}</span>
              }
            </>
           : !!project.files.find((f: any) => Number(f.code) === Number(filterDocType.id)) ? 
            <span>YES <i className={`fas fa-check-circle`}></i></span>
           : 
            <span>NO <i className={`fas fa-exclamation-circle`}></i></span>
          }
        </div>
        {/* <div className="project-downloads">
          <a href="files.zip" download>
            <button className="btn btn-select btn-primary" type="button">
              <span className="btn-icon">
                <i className={`fas fa-download`}></i>
              </span>
            </button>
          </a>
        </div> */}
        <div className="report">
          {/* Need to encode uri component because few projects have special characters as / */}
          <Link to={`/spv-reporting/project/${encodeURIComponent(project.code.toLowerCase())}`}>
            <button
              // onClick={}
              className="btn btn-select btn-primary"
              type="button"
            >
              <span className="btn-icon">
                <i className={`fas fa-arrow-right`}></i>
              </span>
            </button>
          </Link>
        </div>
      </li>
    </>
  );
};

export default SPVReportingProjectListItem;

import React from "react";
import "../../../../assets/scss/custom/tools/_base.scss";
import "./styles.scss";

type UsersToolBarProps = {
  currentYear?: number;
  currentTool: string;
  setCurrentYear?: any;
};

const UsersToolBar = ({ currentTool }: UsersToolBarProps) => (
  <div className="toolbar management-toolbar appear-right-full">
    <div className="inner">
      <div className="menu"></div>
    </div>
  </div>
);

export default UsersToolBar;

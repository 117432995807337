import axios from "axios";
import { Country } from "models/country.model";
import config from "modules/config";

const ApiBaseUrl = config.ApiUrl;

const getAllcountries = async (
  accessToken: string,
  currYear: number
): Promise<{ countries: Country[]; years: any }> => {
  const result = await axios({
    method: "get",
    url: `${ApiBaseUrl}/country/country-list/${currYear}`,
    headers: { Authorization: "Bearer " + accessToken },
  });

  return result.data;
};

const updateAllCountries = async (
  accessToken: string,
  currYear: number,
  countries: Country[]
): Promise<any> => {
  const result = await axios({
    method: "post",
    url: `${ApiBaseUrl}/country/update/${currYear}`,
    headers: { Authorization: "Bearer " + accessToken },
    data: countries,
  });

  return result.data;
};

export { getAllcountries, updateAllCountries };

import { cloneDeep } from "lodash";
import React, { useEffect, useState } from "react";
import SPVReportingFundListItem from "./SPVReportingFundListItem";

import "./styles.scss";

interface SPVReportingFundListProps {
  funds: any;
}

const SPVReportingFundList = ({ funds }: SPVReportingFundListProps) => {
  const [filteredFunds, setFilteredFunds] = useState<any>(funds);
  const [sortNameOrder, setSortNameOrder] = useState<string>("asc");
  const [sortIndOrder, setSortIndOrder] = useState<string>("asc");
  const [sortDocOrder, setSortDocOrder] = useState<string>("asc");

  const updateSortOrder = (type: string, order: string) => {
    let newDataList = cloneDeep(funds);
    switch (type) {
      case "name":
        setSortNameOrder(order);
        newDataList = newDataList.sort((a: any, b: any) => {
          return order === "asc" ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name);
        });
        break;
      case "indCompletion":
        setSortIndOrder(order);

        newDataList = newDataList.sort((a: any, b: any) => {
          const aOrder = a.indCompletion / a.totalIndCompletion;
          const bOrder = b.indCompletion / b.totalIndCompletion;
          return 'asc' === order ? aOrder - bOrder : bOrder - aOrder;
        });
        break;
      case "docCompletion":
        setSortDocOrder(order);
        newDataList = newDataList.sort((a: any, b: any) => {
          const aOrder = (0 < a.totalDocCompletion && 0 < a.docCompletion) ? a.totalDocCompletion / a.docCompletion : 0;
          const bOrder = (0 < b.totalDocCompletion && 0 < b.docCompletion) ? b.totalDocCompletion / b.docCompletion : 0;

          return 'desc' === order ? aOrder - bOrder : bOrder - aOrder;
        });
        break;
      default:
        setSortNameOrder(order);
        newDataList = newDataList.sort((a: any, b: any) => {
          return order === "asc" ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name)
        });
        break;
    }

    setFilteredFunds(newDataList);
  };

  useEffect(() => {
    if (!!funds) {
      setFilteredFunds(funds);
    }
  }, [funds]);

  return (
    <>
      <ul className="list">
        <li className="row filters">
          <div className="fund">
            <i className="label">Fund</i>
            <button
              className="btn btn-filter"
              type="button"
              onClick={() => updateSortOrder("name", sortNameOrder === "asc" ? "desc" : "asc" )}
            >
              <span className="btn_label">Sort</span>
              <span className="btn_icon">
                {sortNameOrder === "asc" ? (
                  <i className="fas fa-sort-alpha-down"></i>
                ) : (
                  <i className="fas fa-sort-alpha-down-alt"></i>
                )}
              </span>
            </button>
          </div>
          <div className="fund-indicators">
            <i className="label">Indic. completion rate</i>
            <button
              className="btn btn-filter btn-invert"
              type="button"
              onClick={() => updateSortOrder("indCompletion", sortIndOrder === "asc" ? "desc" : "asc")}
            >
              <span className="btn_label">Sort</span>
              <span className="btn_icon">
                {sortIndOrder === "asc" ? (
                  <i className="fas fa-sort-amount-down"></i>
                ) : (
                  <i className="fas fa-sort-amount-down-alt"></i>
                )}
              </span>
            </button>
          </div>
          <div className="fund-documents">
            <i className="label">Doc. completion rate</i>
            <button
              className="btn btn-filter btn-invert"
              type="button"
              onClick={() =>updateSortOrder(  "docCompletion", sortDocOrder === "asc" ? "desc" : "asc")}
            >
              <span className="btn_label">Sort</span>
              <span className="btn_icon">
                {sortDocOrder === "asc" ? (
                  <i className="fas fa-sort-amount-down"></i>
                ) : (
                  <i className="fas fa-sort-amount-down-alt"></i>
                )}
              </span>
            </button>
          </div>
          {/* <div className="fund-downloads">
            <i className="label">
              Download
              <br />
              all doc.
            </i>
          </div> */}
        </li>

        {!!filteredFunds &&
          filteredFunds.map((f: any, idx: number) => (
            <SPVReportingFundListItem key={`fund-${f.code}-${idx}`} fund={f} />
          ))}
      </ul>
    </>
  );
};

export default SPVReportingFundList;

import React from "react";
import UIkit from "uikit";
import { Project } from "models/project.model";

interface UserSettingsSurveySdgItemProps {
  privilege: any;
  projects: Project[];
  updateProjectPrivilege: any;
  formDirty: string[];
  removePrivilege: any;
}

const UserSettingsSurveySdgItem = ({
  privilege,
  projects,
  updateProjectPrivilege,
  formDirty,
  removePrivilege,
}: UserSettingsSurveySdgItemProps) => {
  const retrieveProjectName = (projectCode: string) => {
    let projectName = projectCode;
    if (!!projects) {
      projects.map((project: Project) => {
        if (project.projectCode === projectCode) {
          projectName = project.projectName;
        }
      });
    }
    return projectName;
  };

  return (
    <li
      className={`row ${"" === privilege.role ? "warn" : ""} ${
        formDirty.includes("survey-" + privilege.details.projectCode)
          ? "is-modified"
          : ""
      }`}
    >
      <div className="project">
        <p>{retrieveProjectName(privilege.details.projectCode)}</p>
      </div>
      <div className="group">
        <div className="selector">
          <div className="select">
            <button
              className="btn btn-select btn-primary full btn-rounded"
              type="button"
            >
              <span className="btn_label">
                {"" !== privilege.role ? privilege.role : "-----"}
              </span>
              <span className="btn_icon"></span>
            </button>
            <div
              className="drop drop-select project-privilege"
              data-uk-dropdown="pos: top-left; mode: click; delay-hide: 100"
            >
              <ul className="group lvl0 uk-nav uk-dropdown-nav">
                <li
                  className="item"
                  data-uk-toggle="target: .project-privilege"
                  onClick={() => {
                    updateProjectPrivilege(
                      privilege.details.projectCode,
                      "admin"
                    );
                    UIkit.dropdown(".project-privilege").hide();
                  }}
                >
                  <span className="clickable">Admin</span>
                </li>
                <li
                  className="item"
                  data-uk-toggle="target: .project-privilege"
                  onClick={() => {
                    updateProjectPrivilege(
                      privilege.details.projectCode,
                      "contributor"
                    );
                    UIkit.dropdown(".project-privilege").hide();
                  }}
                >
                  <span className="clickable">Contributor</span>
                </li>
                <li
                  className="item"
                  data-uk-toggle="target: .project-privilege"
                  onClick={() => {
                    updateProjectPrivilege(
                      privilege.details.projectCode,
                      "readonly"
                    );
                    UIkit.dropdown(".project-privilege").hide();
                  }}
                >
                  <span className="clickable">Readonly</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="delete">
        <button onClick={() => removePrivilege(privilege.details.projectCode)}>
          <i className="fas fa-trash"></i>
        </button>
      </div>
    </li>
  );
};

export default UserSettingsSurveySdgItem;

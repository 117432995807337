import { SessionData } from "models/authentication.models";
import { Action } from "models/store.models";

export enum SessionActionTypes {
  LoginInProgress = "@Session/LoginInProgress",
  InitSession = "@Session/InitSession",
  StartSession = "@Session/StartSession",
  ActivateAccount = "@Session/ActivateAccount",
  LoginError = "@Session/LoginError",
  LoginFailed = "@Session/LoginFailed",
  ResetPasswordSuccess = "@Session/ResetPasswordSuccess",
  SignOut = "@Session/SignOut",
}

export class InitSession implements Action {
  public readonly type = SessionActionTypes.InitSession;
}

export class LoginInProgressAction implements Action {
  public readonly type = SessionActionTypes.LoginInProgress;
}

export class LoginFailedAction implements Action {
  public readonly type = SessionActionTypes.LoginFailed;
}

export class LoginErrorAction implements Action {
  public readonly type = SessionActionTypes.LoginError;
}

export class StartSessionAction implements Action {
  public readonly type = SessionActionTypes.StartSession;
  public constructor(public readonly sessionData: SessionData) {}
}

export class ActivateAccount implements Action {
  public readonly type = SessionActionTypes.ActivateAccount;
}

export class SignOutUser implements Action {
  public readonly type = SessionActionTypes.SignOut;
}

export type SessionActions =
  | LoginInProgressAction
  | LoginErrorAction
  | LoginFailedAction
  | SignOutUser
  | StartSessionAction
  | ActivateAccount
  | InitSession;

import axios from "axios";
import config from "modules/config";
import { AllocationAssetsGeoResult } from "../models/allocation-result.model";
import { SdgImpactReturn } from "../models/impact-returns.model";
import { CarbonFootprint } from "../models/carbon-footprint.model";
import { AvailableFilters } from "../models/available-filters.model";
import { ImpactKeyIndicator } from "../models/impact-key-indicator.model";
import { InvestorResult } from "../models/investor-result.model";
import { MeridiamFundResult } from "../models/meridiam-fund-result.model";
import { Investor } from "../models/investor.model";

import { EuTaxonomyAlignment } from "../models/eu-taxonomy-alignment";

const ApiBaseUrl = config.ApiUrl;

export interface ProjectLocation {
  type: string
  projectCode: string
  projectName: string
  stage: string
  latitude: number
  longitude: number
}

export const getInvestorData  = async (
  token: string,
  investorId: string
): Promise<any> => {
  const result = await axios({
    method: "get",
    url: `${ApiBaseUrl}/limited-partners/investor-data/${investorId}`,
    headers: { Authorization: "Bearer " + token },
  });

  return result.data;
};

export const getInvestorDataAndMeridiamFund = async (
  token: string,
  investorId: string,
  year: number,
  currency: string
): Promise<{
  investorResult: InvestorResult;
  meridiamFundResult: MeridiamFundResult[];
}> => {
  const result = await axios({
    method: "get",
    url: `${ApiBaseUrl}/limited-partners/investor/${investorId}/${year}/${currency}`,
    headers: { Authorization: "Bearer " + token },
  });

  return result.data;
};

export const getFilterInvestorData = async (
  token: string,
  investorId: string,
  year: number
): Promise<{
  filterResult: AvailableFilters;
}> => {
  const result = await axios({
    method: "get",
    url: `${ApiBaseUrl}/limited-partners/investor-filter/${investorId}/${year}`,
    headers: { Authorization: "Bearer " + token },
  });
  return { filterResult: result.data };
};

export const getAllInvestors = async (
  token: string
): Promise<{
  allInvestor: Investor[];
}> => {
  const result = await axios({
    method: "get",
    url: `${ApiBaseUrl}/limited-partners/all-investors`,
    headers: { Authorization: "Bearer " + token },
  });
  return { allInvestor: result.data };
};

export const getProjectLocation = async (
  token: string,
  investorId: string,
  year: number,
  meridiamFund: string[],
  location: string[]
): Promise<{data:ProjectLocation[]}> => {
  const result = await axios({
    method: "post",
    url: `${ApiBaseUrl}/limited-partners/investor-project-location/${investorId}/${year}`,
    headers: { Authorization: "Bearer " + token },
    data: {
      mF: JSON.stringify(meridiamFund),
      location: JSON.stringify(location),
    },
  });

  return result.data;
};

export const getAllocationAssetsGeography = async (
  token: string,
  investorId: string,
  year: number,
  meridiamFund: string[],
  location: string[],
  currency: string
): Promise<AllocationAssetsGeoResult> => {
  const result = await axios({
    method: "post",
    url: `${ApiBaseUrl}/limited-partners/investor-results/${investorId}/${year}/${currency}`,
    headers: { Authorization: "Bearer " + token },
    data: {
      mF: JSON.stringify(meridiamFund),
      location: JSON.stringify(location),
    },
  });

  return result.data;
};

export const getCarbonFootprint = async (
  token: string,
  investorId: string,
  year: number,
  meridiamFund: string[],
  location: string[]
): Promise<{
  impactKeyIndicatorByCategory: ImpactKeyIndicator;
  carbonFootprintResult: CarbonFootprint[];
}> => {
  const result = await axios({
    method: "post",
    url: `${ApiBaseUrl}/limited-partners/investor-carbon-footprint/${investorId}/${year}`,
    headers: { Authorization: "Bearer " + token },
    data: {
      mF: JSON.stringify(meridiamFund),
      location: JSON.stringify(location),
    },
  });

  return result.data;
};

export const getLPsSDGImpactReturn = async (
  token: string,
  investorId: string,
  year: number,
  currency: string,
  meridiamFund: string[],
  location: string[]
): Promise<SdgImpactReturn> => {
  const result = await axios({
    method: "post",
    url: `${ApiBaseUrl}/limited-partners/investor-sdg-impact-return/${investorId}/${year}/${currency}`,
    headers: { Authorization: "Bearer " + token },
    data: {
      mF: JSON.stringify(meridiamFund),
      location: JSON.stringify(location),
    },
  });

  return result.data;
};

export const getEuTaxonomyAlignment = async (
  token: string,
  investorId: string,
  year: number
): Promise<{
  euTaxonomyAlignmentResult: EuTaxonomyAlignment[];
}> => {
  const { data } = await axios.get(`${ApiBaseUrl}/limited-partners/investor-eu-taxonomy-alignment/${investorId}/${year}`,
    {headers: { Authorization: "Bearer " + token }}
  );

  return data;
};

import React from "react";
import NumberFormat from "react-number-format";

interface ProjectIndicatorsTextWithUnitProps {
  slug: any,
  indicator: any;
  value: any;
  handleChange: any;
  index?: number;
  currency?: string;
}

const ProjectIndicatorsTextWithUnit = ({
  slug,
  indicator,
  value,
  handleChange,
  index,
  currency
}: ProjectIndicatorsTextWithUnitProps) => {
  return (
    <div className="text-unit">
      <p className="label">{indicator.name}</p>
      <div className="with-unit-container">
      {!!indicator.config && !!indicator.config.type && 'number' === indicator.config.type ?
        <NumberFormat
          id={`${slug}${indicator.id}${'undefined' !== typeof index ? `-${index}` : ''}`}
          displayType="input"
          isNumericString={false}
          thousandSeparator={true}
          prefix=""
          placeholder={indicator.textPlaceholder}
          required
          min={!!indicator.config && !!indicator.config.min ? indicator.config.min : ''}
          max={!!indicator.config && !!indicator.config.max ? indicator.config.max : ''}
          name={`${slug}${indicator.id}${'undefined' !== typeof index ? `-${index}` : ''}`}
          value={!!value ? value : ''}
          onChange={(e: any) => handleChange(slug, e.target.value, indicator.id)}
        />
      :
        <input
          id={`${slug}${indicator.id}${'undefined' !== typeof index ? `-${index}` : ''}`}
          type={!!indicator.config && !!indicator.config.type ? indicator.config.type : 'number'}
          placeholder={indicator.textPlaceholder}
          required
          min={!!indicator.config && !!indicator.config.min ? indicator.config.min : ''}
          max={!!indicator.config && !!indicator.config.max ? indicator.config.max : ''}
          name={`${slug}${indicator.id}${'undefined' !== typeof index ? `-${index}` : ''}`}
          value={!!value ? value : ''}
          onChange={(e) => handleChange(slug, e.target.value, indicator.id)}
        />
      }
        {('function' === typeof indicator.unit) ?
          <span className="unit" dangerouslySetInnerHTML={{__html: indicator.unit(currency)}}></span>
          :
          <span className="unit">{indicator.unit}</span>
        }
      </div>
    </div>
  );
};

export default ProjectIndicatorsTextWithUnit;

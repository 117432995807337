import React from "react";
import Helmet from "react-helmet";
import Footer from "../Footer/Footer";
import SpriteSvg from "../Sprite/SpriteSvg";

import "../../../../assets/scss/basic.scss";
import "./styles.scss";

const Layout = (props: any) => {
  return (
    <>
      <Helmet>
        {"http://localhost:3001" !== process.env.REACT_APP_URL_WEBSITE ? (
          <link rel="stylesheet" href={`/assets/css/print.css`} media="print" />
        ) : (
          <link
            rel="stylesheet"
            href={`/assets/css/print-local.css`}
            media="print"
          />
        )}
      </Helmet>
      {props.children}
      <Footer />
      <SpriteSvg />
    </>
  );
};

export default Layout;

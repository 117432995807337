import React, { useEffect, useState } from "react";
import UIkit from "uikit";
import { cloneDeep } from "lodash";
import { Modal } from "@material-ui/core";

import ListSelectionModalItem from "./ListSelectionModalItem";
import { Project } from "models/project.model";
import { User } from "models/user.models";
import { Investor } from "modules/limited-partners/models/investor.model";

import "./styles.scss";

interface ListSelectionModalProps {
  openListModal: string;
  setOpenListModal: any;
  list: any; //Project[] | Investor[];
  modifiedCurrUser: User;
  listSelection: any;
  setListSelection: any;
  validSelection: any;
  formDirty: string[];
  setFormDirty: any;
}

const ListSelectionModal = ({
  openListModal,
  setOpenListModal,
  list,
  modifiedCurrUser,
  listSelection,
  setListSelection,
  validSelection,
  setFormDirty,
  formDirty,
}: ListSelectionModalProps) => {
  const [sameRoleForAll, setSameRoleForAll] = useState<boolean>(false);
  const [roleForAll, setRoleForAll] = useState<string>("----");
  const [searchedTerms, setSearchedTerm] = useState<string>("");

  useEffect(() => {
    if (!!modifiedCurrUser && !!modifiedCurrUser.privileges) {
      let newArray: any = [];

      switch (openListModal) {
        case "project":
          if (
            !!modifiedCurrUser.privileges["survey-sdg"] &&
            modifiedCurrUser.privileges["survey-sdg"].length > 0
          ) {
            modifiedCurrUser.privileges["survey-sdg"].map((privilege: any) => {
              newArray.push({
                projectCode: privilege.details["projectCode"]
                  ? privilege.details["projectCode"]
                  : "",
                projectId: privilege.details["projectId"],
                role: privilege.role,
              });
            });
          }
          break;
        case "project-reporting":
          if (
            !!modifiedCurrUser.privileges["reporting"] &&
            modifiedCurrUser.privileges["reporting"].length > 0
          ) {
            modifiedCurrUser.privileges["reporting"].map((privilege: any) => {
              newArray.push({
                projectCode: privilege.details["projectCode"]
                  ? privilege.details["projectCode"]
                  : "",
                projectId: privilege.details["projectId"],
                role: privilege.role,
              });
            });
          }
          break;
        case "project-spv":
          if (
            !!modifiedCurrUser.privileges["spv-manager"] &&
            modifiedCurrUser.privileges["spv-manager"].length > 0
          ) {
            modifiedCurrUser.privileges["spv-manager"].map((privilege: any) => {
              newArray.push({
                projectCode: privilege.details["projectCode"]
                  ? privilege.details["projectCode"]
                  : "",
                projectId: privilege.details["projectId"],
                role: privilege.role,
              });
            });
          }
          break;
        case "investor":
          if (
            !!modifiedCurrUser.privileges["lp-dashboard"] &&
            modifiedCurrUser.privileges["lp-dashboard"].length > 0
          ) {
            modifiedCurrUser.privileges["lp-dashboard"].map(
              (privilege: any) => {
                newArray.push({
                  investorId: privilege.details["investorId"],
                  role: privilege.role,
                });
              }
            );
          }
          break;
      }
      setListSelection(newArray);
    }
  }, [openListModal]);

  const selectThisItem = (id: number, itemCode: string) => {
    let itemAlreadySelected: any = [];
    if (!formDirty.includes(`modalSelect-${openListModal}`)) {
      let newFD = cloneDeep(formDirty);
      newFD.push(`modalSelect-${openListModal}`);
      setFormDirty(newFD);
    }

    if (openListModal === "project" || openListModal === "project-reporting" || openListModal === "project-spv") {
      itemAlreadySelected = listSelection.find(
        (p: any) => p.projectCode === itemCode
      );
    } else {
      itemAlreadySelected = listSelection.find(
        (p: any) => p.investorId === itemCode
      );
    }
    let newArray = cloneDeep(listSelection);

    if (itemAlreadySelected) {
      newArray =
        openListModal === "project" ||
        openListModal === "project-reporting" ||
        openListModal === "project-spv"
          ? newArray.filter((p: any) => p.projectCode !== itemCode)
          : newArray.filter((p: any) => p.investorId !== itemCode);
    } else {
      switch (openListModal) {
        case "project":
        case "project-reporting":
          newArray.push({
            projectCode: itemCode,
            projectId: id,
            role: "",
          });
          break;
        case "project-spv":
          newArray.push({
            projectCode: itemCode,
            projectId: id,
            role: "admin",
          });
          break;
        case "investor":
          newArray.push({
            investorId: itemCode,
            role: "",
          });
          break;
      }
    }
    setListSelection(newArray);
  };

  const selectAll = () => {
    if (!formDirty.includes(`modalSelect-${openListModal}`)) {
      let newFD = cloneDeep(formDirty);
      newFD.push(`modalSelect-${openListModal}`);
      setFormDirty(newFD);
    }
    if (listSelection.length === list.length) {
      setListSelection([]);
      setSameRoleForAll(false);
      setRoleForAll("---");
    } else {
      let newArray = cloneDeep(listSelection);

      switch (openListModal) {
        case "project":
        case "project-reporting":
          list.map((p: Project) => {
            let projectAlreadySelected = listSelection.find(
              (sp: any) => sp.projectCode === p.projectCode
            );

            if (!projectAlreadySelected) {
              newArray.push({
                projectCode: p.projectCode,
                projectId: p.id,
                role: "",
              });
            }
          });
          break;
        case "project-spv":
          list.map((p: Project) => {
            let projectAlreadySelected = listSelection.find(
              (sp: any) => sp.projectCode === p.projectCode
            );

            if (!projectAlreadySelected) {
              newArray.push({
                projectCode: p.projectCode,
                projectId: p.id,
                role: "admin",
              });
            }
          });
          break;
        case "investor":
          list.map((i: Investor) => {
            let investorAlreadySelected = listSelection.find(
              (is: any) => is.investorId === i.investorId
            );

            if (!investorAlreadySelected) {
              newArray.push({
                investorId: i.investorId,
                role: "",
              });
            }
          });
          break;
      }
      setListSelection(newArray);
    }
  };

  const setSameRoleForAllValue = (role: string) => {
    let newArray: any = [];
    setRoleForAll(role);
    if (!formDirty.includes(`modalSelect-${openListModal}`)) {
      let newFD = cloneDeep(formDirty);
      newFD.push(`modalSelect-${openListModal}`);
      setFormDirty(newFD);
    }

    if (
      openListModal === "project" ||
      openListModal === "project-reporting" ||
      openListModal === "project-spv"
    ) {
      list.map((p: Project) => {
        let projectAlreadySelected = listSelection.find(
          (sp: any) => sp.projectCode === p.projectCode
        );
        if (projectAlreadySelected) {
          projectAlreadySelected.role = role;
          newArray.push(projectAlreadySelected);
        } else {
          newArray.push({
            projectCode: p.projectCode,
            projectId: p.id,
            role: role,
          });
        }
      });
    } else {
      list.map((i: Investor) => {
        let investorAlreadySelected = listSelection.find(
          (is: any) => is.investorId === i.investorId
        );
        if (investorAlreadySelected) {
          investorAlreadySelected.role = role;
          newArray.push(investorAlreadySelected);
        } else {
          newArray.push({
            investorId: i.investorId,
            role: role,
          });
        }
      });
    }
    setListSelection(newArray);
  };

  return (
    <Modal open={openListModal !== ""} aria-labelledby="projects-modal">
      <div className="projects-modal">
        <div className="projects-modal_inner">
          <div className="modal modal_head">
            <h2 className="title">
              {openListModal === "project" ||
              openListModal === "project-reporting" ||
              openListModal === "project-spv"
                ? "Project"
                : "Investor"}{" "}
              list
            </h2>
            <button
              className="btn btn-select btn-primary"
              type="button"
              onClick={() => setOpenListModal("")}
            >
              <span className="btn-label">Cancel</span>
              <span className="btn-icon">
                <i className={`fas fa-times`}></i>
              </span>
            </button>
          </div>

          <div className="separator"></div>

          <div className="modal modal_settings">
            <div className="left">
              <span className="label">
                Grant this user with same role for all{" "}
                {openListModal === "project" ||
                openListModal === "project-reporting" ||
                openListModal === "project-spv"
                  ? "projects"
                  : "investors"}
              </span>
              <div className="toggle-container">
                <span className={`${false === sameRoleForAll ? "active" : ""}`}>
                  No
                </span>
                <div className="toggle">
                  <input
                    type="checkbox"
                    id="active"
                    className="toggle-input"
                    onClick={() => setSameRoleForAll(!sameRoleForAll)}
                    defaultChecked={sameRoleForAll}
                  />
                  <div className="toggle-switcher"></div>
                </div>
                <span className={`${true === sameRoleForAll ? "active" : ""}`}>
                  Yes
                </span>
              </div>
            </div>

            <div className={`right ${!!sameRoleForAll ? "" : "disabled"}`}>
              <span className="label">
                Choose a role for all{" "}
                {openListModal === "project" ||
                openListModal === "project-reporting" ||
                openListModal === "project-spv"
                  ? "projects"
                  : "investors"}
              </span>
              <div className="selector">
                <div className="select">
                  <button className="btn btn-select btn-filter" type="button">
                    <span className="btn_label">{roleForAll}</span>
                    <span className="btn_icon"></span>
                  </button>
                  <div
                    className="drop drop-select role-for-all"
                    data-uk-dropdown="pos: top-left; mode: click; delay-hide: 100"
                  >
                    <ul className="group lvl0 uk-nav uk-dropdown-nav">
                      {/* ADMIN : FOR ALL */}
                      <li
                        className="item"
                        data-uk-toggle="target: .role-for-all"
                        onClick={() => {
                          setSameRoleForAllValue("admin");
                          UIkit.dropdown(".role-for-all").hide();
                        }}
                      >
                        <span className="clickable">Admin</span>
                      </li>

                      {/* CONTRIBUTOR : exist for survey & reporting  */}
                      {(openListModal === "project" ||
                        openListModal === "project-reporting") && (
                        <li
                          className="item"
                          data-uk-toggle="target: .role-for-all"
                          onClick={() => {
                            setSameRoleForAllValue("contributor");
                            UIkit.dropdown(".role-for-all").hide();
                          }}
                        >
                          <span className="clickable">Contributor</span>
                        </li>
                      )}

                      {/* READONLY : exist for survey & lp  */}
                      {(openListModal === "project" ||
                        openListModal === "investor") && (
                        <li
                          className="item"
                          data-uk-toggle="target: .role-for-all"
                          onClick={() => {
                            setSameRoleForAllValue("readonly");
                            UIkit.dropdown(".role-for-all").hide();
                          }}
                        >
                          <span className="clickable">Read only</span>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="separator"></div>

          <div className="modal modal_list">
            <span className="label">
              Click to add/remove a{" "}
              {openListModal === "project" ||
              openListModal === "project-reporting" ||
              openListModal === "project-spv"
                ? "project"
                : "investor"}{" "}
              to your list
            </span>
            <div className="search">
              <input
                type="search"
                className="no-check"
                placeholder={
                  openListModal === "project" ||
                  openListModal === "project-reporting" ||
                  openListModal === "project-spv"
                    ? "e.g. Central 70"
                    : "e.g. Alaska Permanent Fund Corporation"
                }
                value={searchedTerms}
                onChange={(e) => setSearchedTerm(e.target.value)}
              />
            </div>

            <div className="select-all">
              <input
                type="checkbox"
                id="select-filter"
                className="select-all-input"
                onClick={() => selectAll()}
              />
              <div className="box">
                <i className="fas fa-check"></i>
              </div>
              <span className="label">Select all</span>
            </div>

            <ul className="list">
              {!!list && list.length > 0 && (
                <>
                  {openListModal === "project" ||
                  openListModal === "project-reporting" ||
                  openListModal === "project-spv" ? (
                    <>
                      {openListModal === "project-reporting"
                        ? list
                            .filter((p: Project) => {
                              if (p.concernedByReporting) {
                                return p;
                              }
                            })
                            .filter((p: Project) => {
                              if (searchedTerms !== "") {
                                if (
                                  p.projectName
                                    .toLocaleLowerCase()
                                    .includes(searchedTerms.toLocaleLowerCase())
                                ) {
                                  return p;
                                }
                              } else {
                                return p;
                              }
                            })
                            .map((project: Project, idx: any) => (
                              <ListSelectionModalItem
                                key={`project-${idx}`}
                                type="project"
                                item={project}
                                listSelection={listSelection}
                                selectThisItem={selectThisItem}
                              />
                            ))
                        : list
                            .filter((p: Project) => {
                              if (searchedTerms !== "") {
                                if (
                                  p.projectName
                                    .toLocaleLowerCase()
                                    .includes(searchedTerms.toLocaleLowerCase())
                                ) {
                                  return p;
                                }
                              } else {
                                return p;
                              }
                            })
                            .map((project: Project, idx: any) => (
                              <ListSelectionModalItem
                                key={`project-${idx}`}
                                type="project"
                                item={project}
                                listSelection={listSelection}
                                selectThisItem={selectThisItem}
                              />
                            ))}
                    </>
                  ) : (
                    list
                      .filter((i: Investor) => {
                        if (searchedTerms !== "") {
                          if (
                            i.investorName
                              .toLocaleLowerCase()
                              .includes(searchedTerms.toLocaleLowerCase())
                          ) {
                            return i;
                          }
                        } else {
                          return i;
                        }
                      })
                      .map((investor: Investor, idx: any) => (
                        <ListSelectionModalItem
                          key={`investor-${idx}`}
                          type="investor"
                          item={investor}
                          listSelection={listSelection}
                          selectThisItem={selectThisItem}
                        />
                      ))
                  )}
                </>
              )}
            </ul>
          </div>

          <div className="modal modal_foot">
            <button
              className="btn btn-select btn-primary"
              type="button"
              onClick={() => validSelection()}
            >
              <span className="btn-label">Validate selection</span>
              <span className="btn-icon">
                <i className={`fas fa-check`}></i>
              </span>
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ListSelectionModal;

const config = {
  ApiUrl: process.env.REACT_APP_API_URL,
  SsoUrl: process.env.REACT_APP_SSO_URL,
  TrafiquantsToken: process.env.REACT_APP_TRAFIQUANTS_TOKEN,
  CdnUrl: process.env.REACT_APP_URL_CDN,
  current_year: !!process.env.REACT_APP_CURRENT_YEAR
    ? parseInt(process.env.REACT_APP_CURRENT_YEAR)
    : 0,
};

export default config;

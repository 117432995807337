import mapDataWorld from "@highcharts/map-collection/custom/world-highres3.geo.json";
import "assets/scss/custom/ui/select/_base.scss";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highchartsMap from "highcharts/modules/map";
import _ from "lodash";
import { CountryFilter } from "modules/limited-partners/models/available-filters.model";
import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import "./styles.scss";

if (typeof Highcharts === "object") {
  highchartsMap(Highcharts);
}

const buildChartFromFiltersMap = (
  countryFilter: CountryFilter[],
  countries: CountryFilter[],
  chartToogleCountry: any
) => {
  let seriesMapAvailable: any = {
    data: [],
  };
  let seriesMapSelected: any = {
    data: [],
  };

  seriesMapSelected.data = countryFilter?.map((item) => {
    return {
      code3: item,
    };
  });

  if(!!countries){
    seriesMapAvailable.data = countries?.map((item) => {
      return {
        code3: item.countryCode,
      };
    });
  }

  return {
    chart: {
      mapChart: "mapChart",
      width: null,
      animation: 0,
      height: 464,
      className: "chart chart-map chart-map-filters chart__own",
      map: "custom/world",
      zoomType: "xyz",
      borderWidth: 0,
      spacing: [0, 0, 0, 0],
      responsive: true,
      styledMode: true,
      useHTML: true,
    },
    events: {
      load: function (this: any) {},
      redraw: function (this: any) {
        // console.log("REDRAW");
      },
    },
    title: {
      text: null,
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    resetZoomButton: {
      enabled: true,
      position: {
        align: "left",
        verticalAlign: "bottom",
        x: -10,
        y: 10,
      },
      text: "-",
      width: 30,
      height: 30,
      //relativeTo: "plot",
      theme: {
        zIndex: 6,
      },
    },
    mapNavigation: {
      enabled: true,
      enableDoubleClickZoomTo: false,
      buttons: {
        zoomIn: {
          width: 30,
          height: 30,
          onclick: function (this: any, err: any) {
            this.mapZoom(0.5);
          },
          padding: 0,
          text: "+",
        },
        zoomOut: {
          width: 30,
          height: 30,
          onclick: function (this: any, err: any) {
            this.mapZoom(2);
          },
          padding: 0,
          text: "-",
        },
      },
      mouseWheelSensitivity: 10,
    },
    tooltip: {
      enabled: false,
    },
    series: [
      {
        name: "Basemap",
        mapData: mapDataWorld,
        borderColor: "#102D40",
      },
      {
        name: "DataMapCountryAvailable",
        type: "map",
        data: seriesMapAvailable.data,
        mapData: mapDataWorld,
        allowPointSelect: true,
        enableMouseTracking: true,
        joinBy: ["iso-a3", "code3"],
        allAreas: true,
        cursor: "pointer",
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          enabled: false,
        },
        point: {
          events: {
            click: function (this: any, err: any) {
              chartToogleCountry(this.code3);
            },
          },
        },
      },
      {
        name: "DataMapCountrySelected",
        type: "map",
        data: seriesMapSelected.data,
        mapData: mapDataWorld,
        allowPointSelect: true,
        enableMouseTracking: true,
        joinBy: ["iso-a3", "code3"],
        allAreas: false,
        cursor: "pointer",
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          enabled: false,
        },
        point: {
          events: {
            click: function (this: any, err: any) {
              chartToogleCountry(this.code3);
            },
          },
        },
      },
    ],
  };
};

class FiltersMapChart extends React.Component<{
  countryFilter: any;
  countries: CountryFilter[];
  onToggleCountry: (countryCode: string) => void;
}> {
  internalChart: any;

  // countryCode: any = "";

  chartToogleCountry = (countryCode: string) => {
    // console.log("--------chartToogleCountry-------");
    // console.log(countryCode);
    this.props.onToggleCountry(countryCode);
  };

  afterChartCreated = (chart: any) => {
    this.internalChart = chart;
    this.forceUpdate();
  };

  componentDidUpdate() {
    this.internalChart.reflow();
  }

  render() {
    const { countryFilter, countries } = this.props;
    // const { countries } = this.props;

    return (
      <>
        {countryFilter && (
          <HighchartsReact
            constructorType={"mapChart"}
            highcharts={Highcharts}
            containerProps={{
              className: "chartContainer",
              id: "mapChartFilter",
            }}
            options={buildChartFromFiltersMap(
              countryFilter,
              countries,
              this.chartToogleCountry
            )}
            callback={this.afterChartCreated}
          />
        )}
      </>
    );
  }
}

type FilterMapCountryProps = {
  countries: CountryFilter[];
  location: string[];
  setLocation: (arg: string[]) => void;
};

interface SumContinent {
  label: string;
  slug: string;
  totalCountries: number;
  countriesCode: string[];
}

const FilterMapCountry: React.FC<FilterMapCountryProps> = ({
  countries,
  location,
  setLocation,
}) => {
  const filtersMapChartRef = useRef<FiltersMapChart>(null);
  const [selectedLocation, setSelectedLocation] = useState<string[]>([]);
  const [isSelectionChanged, setIsSelectionChanged] = useState<boolean>(false);
  const [countriesMap, setCountriesMap] = useState<any>({});
  const [sumContinents, setSumContinents] = useState<SumContinent[]>([]);
  const [hasData, setHasData] = useState<boolean>(false);

  useEffect(() => {
    let newCM: any = {};
    if(!!countries){
      countries.forEach((item: any) => {
        options.push({
          key: item.countryCode,
          value: item.countryCode,
          label: item.countryName,
        });
        newCM[item.countryCode] = {
          name: item.countryName,
        };
      });
      setCountriesMap(newCM);

      // console.log('countriesMap ----');
      // console.log(newCM);
      // console.log('----');

      let newSumCont = [
        {
          totalCountries: _(countries)
            .filter((item) => item.continent === "africa")
            .size(),
          countriesCode: countries
            .filter((item) => item.continent === "africa")
            .map((item) => {
              return item.countryCode;
            }),
          label: "Africa",
          slug: "africa",
        },
        {
          totalCountries: _(countries)
            .filter((item) => item.continent === "asia")
            .size(),
          countriesCode: countries
            .filter((item) => item.continent === "asia")
            .map((item) => {
              return item.countryCode;
            }),
          label: "Asia",
          slug: "asia",
        },
        {
          totalCountries: _(countries)
            .filter((item) => item.continent === "europe")
            .size(),
          countriesCode: countries
            .filter((item) => item.continent === "europe")
            .map((item) => {
              return item.countryCode;
            }),
          label: "Europe",
          slug: "europe",
        },
        {
          totalCountries: _(countries)
            .filter((item) => item.continent === "south-america")
            .size(),
          countriesCode: countries
            .filter((item) => item.continent === "south-america")
            .map((item) => {
              return item.countryCode;
            }),
          label: "Latina America",
          slug: "south-america",
        },
        {
          totalCountries: _(countries)
            .filter((item) => item.continent === "north-america")
            .size(),
          countriesCode: countries
            .filter((item) => item.continent === "north-america")
            .map((item) => {
              return item.countryCode;
            }),
          label: "North America",
          slug: "north-america",
        },
        {
          totalCountries: _(countries)
            .filter((item) => item.continent === "oceania")
            .size(),
          countriesCode: countries
            .filter((item) => item.continent === "oceania")
            .map((item) => {
              return item.countryCode;
            }),
          label: "Oceania",
          slug: "oceania",
        },
      ];

      let newHasData = false;
      newSumCont.forEach(nsc => {
        if(nsc.totalCountries > 0){
          newHasData = true;
        }
      });
      setHasData(newHasData);
      setSumContinents(newSumCont);

      let newArrLoc: string[] = [];
      if (location[0] === "all") {
        countries?.forEach((country) => {
          newArrLoc.push(country.countryCode);
        });
      } else {
        if (countries) {
          countries
            .filter((item) => location.includes(item.countryCode))
            .forEach((country) => {
              newArrLoc.push(country.countryCode);
            });
        }
      }
      setSelectedLocation(newArrLoc);

      // console.log("SELECTED LOCATION >>")
      // console.log(newArrLoc);
      // console.log("------")
    }
  }, [countries, location]);

  const toggleCountry = (countryCode: string) => {
    if (selectedLocation.length === countries.length) {
      let newSelection = [];
      newSelection.push(countryCode);
      setSelectedLocation(newSelection);
    } else {
      let newSelection = selectedLocation;
      if (newSelection.includes(countryCode)) {
        newSelection = newSelection.filter((item) => item !== countryCode);
        setSelectedLocation(newSelection);
      } else {
        setSelectedLocation((prevState) => [...prevState, countryCode]);
      }
    }

    setIsSelectionChanged(true);
  };

  const toggleContinent = (continentSlug: string) => {
    let newSelection = selectedLocation;

    let currCountry = sumContinents.find((item) => item.slug === continentSlug);
    let numberOfSelection = 0;
    newSelection.forEach((item) => {
      if (currCountry?.countriesCode.includes(item)) {
        numberOfSelection++;
      }
    });

    if (numberOfSelection === currCountry?.totalCountries) {
      // remove all country code
      newSelection = newSelection.filter(
        (item) => !currCountry?.countriesCode.includes(item)
      );
      setSelectedLocation(newSelection);
    } else {
      // add missing
      currCountry?.countriesCode.forEach((elem) => {
        if (!newSelection.includes(elem)) {
          setSelectedLocation((prevState) => [...prevState, elem]);
        }
      });
    }

    setIsSelectionChanged(true);
  };

  const resetSelection = () => {
    if (location[0] !== "all") {
      setLocation(["all"]);
    } else {
      let newArrLoc: string[] = [];
      countries.forEach((country) => {
        newArrLoc.push(country.countryCode);
      });
      setSelectedLocation(newArrLoc);
    }
  };

  const removeAllCountries = () => {
    let newArrLoc: string[] = [];
    setSelectedLocation(newArrLoc);
  };

  const handleUpdateLocation = async () => {
    if (selectedLocation.length === countries.length) {
      setLocation(["all"]);
    } else {
      setLocation(selectedLocation);
    }
    //@ts-ignore
    UIkit.modal("#filtersMapModal").hide();
  };

  const options: { key: string; value: string; label: string }[] = [];
  let selectedOption: any;

  return (
    <div
      id="filtersMapModal"
      className="modal modal-filters-amp"
      data-uk-modal="bg-close: true; cls-panel: .modal_inner;"
    >
      <div className="uk-modal-dialog">
        <div className="modal_inner">
          <div className="modal__body">
            <div className="col col-listing">
              <div className="listing_head">
                <span
                  className="icon"
                  data-uk-toggle="target: #filtersMapModal; hidden: false;"
                >
                  <i className="fas fa-times"></i>
                </span>
                <span className="label">Filter by location</span>
              </div>
              <div className="listing_body">
                <div className="cmp cmp-search search">
                  <div className="search_label">
                    <span>
                      <i>Search a country</i>
                    </span>
                  </div>
                  <Select
                    value={selectedOption}
                    onChange={(val: any) => {
                      toggleCountry(val.value);
                    }}
                    className="filter filter__search "
                    classNamePrefix="filter"
                    placeholder={`e.g. ${options
                      .slice(0, 3)
                      .map((item) => item.label)
                      .join(", ")}...`}
                    isSearchable={true}
                    isMulti={false}
                    options={options}
                  />
                </div>
                <div className="cmp cmp-list-collapse">
                  { hasData === true
                  ?
                    <ul data-uk-accordion="collapsible: true; toggle: > .accordion_head; content: .accordion_body;">
                      {sumContinents.map((continent) => {
                      return (
                        continent.totalCountries > 0 && (
                          <li
                            className="accordion"
                            key={`continent_${continent.slug}`}
                          >
                            <a className="accordion_head uk-accordion-title">
                              <span className="accordion__label">
                                {continent.label}{" "}
                                <small>({continent.totalCountries})</small>
                              </span>
                              <span className="accordion__icon">
                                <i className="fas fa-chevron-down"></i>
                              </span>
                            </a>
                            <div className="accordion_body uk-accordion-content">
                              <div className="row  row-lvl1">
                                <div className="input">
                                  <input
                                    id={`fitlerContinent${continent.slug}`}
                                    type="checkbox"
                                    onChange={() =>
                                      toggleContinent(continent.slug)
                                    }
                                  />
                                  <label
                                    htmlFor={`fitlerContinent${continent.slug}`}
                                    className="checkbox__like"
                                  >
                                    {continent.countriesCode.filter(
                                      (item) => !selectedLocation.includes(item)
                                    ).length === 0 ? (
                                      <>
                                        <i className="fas fa-plus"></i>
                                      </>
                                    ) : (
                                      <>
                                        <i className="fas fa-minus"></i>
                                      </>
                                    )}
                                  </label>
                                  <label
                                    htmlFor={`fitlerContinent${continent.slug}`}
                                    className="checkbox__label"
                                  >
                                    All {continent.label}'s countries (
                                    {continent.totalCountries})
                                  </label>
                                </div>
                              </div>
                              {continent.countriesCode.map((countryCode) => {
                                return (
                                  <div
                                    className="row row-checkbox row-lvl2"
                                    key={`country_${countryCode}`}
                                  >
                                    <div className="input">
                                      <input
                                        id={`fitlerCountry${countryCode}`}
                                        type="checkbox"
                                        checked={selectedLocation.includes(
                                          countryCode
                                        )}
                                        onChange={() =>
                                          toggleCountry(countryCode)
                                        }
                                      />
                                      <label
                                        htmlFor={`fitlerCountry${countryCode}`}
                                        className="checkbox__like"
                                      >
                                        <i className="fas fa-check"></i>
                                      </label>
                                      <label
                                        htmlFor={`fitlerCountry${countryCode}`}
                                        className="checkbox__label"
                                      >
                                        {countriesMap[countryCode].name}
                                      </label>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </li>
                        )
                      );})}
                    </ul>
                  :
                    "No data available."
                  }
                </div>
              </div>
            </div>

            <div className="col col-map">
              {selectedLocation && (
                <>
                  <FiltersMapChart
                    countryFilter={selectedLocation}
                    countries={countries}
                    onToggleCountry={toggleCountry}
                    ref={filtersMapChartRef}
                  />
                  <div className="remote remote-map">
                    <div className="remote_inner">
                      <div className="buttons">
                        <button
                          className="btn btn__circle btn__remote btn__no-text btn__map-reset"
                          // onClick={() => {
                          //   setZoomPosition(currentMapPosition.continent);
                          // }}
                        >
                          <span className="btn__icon">
                            <svg className="ui ui-svg ui-map-center">
                              <use xlinkHref="#centerMap"></use>
                            </svg>
                          </span>
                          <span className="btn__label">
                            Center map on point
                          </span>
                        </button>
                        
                        <button
                          className="btn btn__circle btn__remote btn__no-text btn__map-zoomout"
                          // onClick={() => {
                          //   setZoomOut();
                          // }}
                        >
                          <span className="btn__icon">
                            <svg className="ui ui-svg ui-map-center">
                              <use xlinkHref="#zoomoutMap"></use>
                            </svg>
                          </span>
                          <span className="btn__label">Zoom out</span>
                        </button>

                        <button
                          className="btn btn__circle btn__remote btn__no-text btn__map-zoomin"
                          // onClick={() => {
                          //   setZoomIn();
                          // }}
                        >
                          <span className="btn__icon">
                            <svg className="ui ui-svg ui-map-center">
                              <use xlinkHref="#zoominMap"></use>
                            </svg>
                          </span>
                          <span className="btn__label">Zoom in</span>
                        </button>
                      </div>
                      <div className="legends">
                        <div className="legend legend-available">
                          <span className="legend__icon"></span>
                          <span className="legend__label">Available</span>
                        </div>
                        <div className="legend legend-selected">
                          <span className="legend__icon"></span>
                          <span className="legend__label">Selected</span>
                        </div>
                        <div className="legend legend-unavailable">
                          <span className="legend__icon"></span>
                          <span className="legend__label">Not-available</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="modal__foot">
            <div className="foot_inner">
              <div className="row row-labels">
                <div className="label">
                  {selectedLocation.length > 0 ? (
                    <span>
                      You have chosen
                      <strong>
                        {" "}
                        {selectedLocation.length} countr
                        {selectedLocation.length > 1 ? "ies" : "y"}
                      </strong>
                    </span>
                  ) : (
                    <span>No country chosen</span>
                  )}
                </div>
                <div className="action">
                  {location[0] === "all" && selectedLocation.length > 0 ? (
                    <span onClick={() => removeAllCountries()}>
                      <i>Remove all countries</i>{" "}
                      <i className="fas fa-times-circle"></i>
                    </span>
                  ) : (
                    <>
                      {selectedLocation.length === 0 ? (
                        <span onClick={() => resetSelection()}>
                          <i>Add all countries</i>{" "}
                          <i className="fas fa-plus-circle"></i>
                        </span>
                      ) : (
                        <span onClick={() => resetSelection()}>
                          <i>Remove selection</i>{" "}
                          <i className="fas fa-times-circle"></i>
                        </span>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className="row row-filters">
                <ul>
                  {selectedLocation.map((countryCode) => {
                    return (
                      <li className="filter filter-country" key={countryCode}>
                        <div className="filter_inner">
                          <span className="filter__label">
                            {countriesMap[countryCode].name}
                          </span>
                          <span
                            className="filter__icon"
                            onClick={() => toggleCountry(countryCode)}
                          >
                            <i className="fas fa-times"></i>
                          </span>
                        </div>
                      </li>
                    );
                  })}
                </ul>

                <button
                  className="btn btn-apply btn-white btn-rounded"
                  disabled={!isSelectionChanged}
                  onClick={() => {
                    handleUpdateLocation();
                  }}
                >
                  <span className="btn_label">Apply</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterMapCountry;

import React from "react";
import UIkit from "uikit";
import { cloneDeep } from "lodash";
import { User } from "models/user.models";

import "./styles.scss";

const { DateTime } = require("luxon");

interface UserManager {
  modifiedCurrUser: any;
  setModifiedCurrUser: any;
  formDirty: string[];
  setFormDirty: any;
  editor: User;
  sendEmailInvitation: any;
  isSendingInvitation: boolean;
}

const UserManager = ({
  modifiedCurrUser,
  setModifiedCurrUser,
  formDirty,
  setFormDirty,
  editor,
  sendEmailInvitation,
  isSendingInvitation,
}: UserManager) => {
  const handleChange = (field: string, value: any) => {
    let newUser = cloneDeep(modifiedCurrUser);
    newUser[field] = value;
    setModifiedCurrUser(newUser);

    if (!formDirty.includes(field)) {
      let newList = formDirty;
      newList.push(field);
      setFormDirty(newList);
    }
  };

  const reformatRole = (platformRole: string) => {
    switch (platformRole) {
      case "global-admin":
        return "Global admin";
        break;
      case "stakeholder":
        return "User";
        break;
      case "technical-admin":
        return "Technical admin";
        break;
    }
  };

  return (
    <div className="user-manager">
      <div className="user-access">
        <h2 className="section-title">
          <i className="fas fa-key"></i> User access
        </h2>
        <div className="user-access_inner">
          <div className="left">
            {!!modifiedCurrUser && !!modifiedCurrUser.id && (
              <button
                className="btn btn-select btn-primary"
                type="button"
                onClick={() => sendEmailInvitation()}
              >
                <span className="btn-label">Send credentials</span>
                <span className="btn-icon">
                  {!isSendingInvitation ? (
                    <i className={`fas fa-envelope`}></i>
                  ) : (
                    <i className={`fas fa-loader`}></i>
                  )}
                </span>
              </button>
            )}

            <div className="row row-input role">
              <span className="label">Platform access</span>
              <div className="selector">
                <div className="select">
                  <button
                    className={`btn btn-select btn-filter ${
                      formDirty.find((i) => i === "platformRole")
                        ? "is-modified"
                        : ""
                    }`}
                    type="button"
                    disabled={
                      modifiedCurrUser.platformRole === "technical-admin" &&
                      editor.platformRole !== "technical-admin"
                        ? true
                        : false
                    }
                  >
                    <span className="btn_label">
                      {reformatRole(modifiedCurrUser.platformRole)}
                    </span>
                    <span className="btn_icon"></span>
                  </button>
                  <div
                    className="drop drop-select platform-role"
                    data-uk-dropdown="pos: top-left; mode: click; delay-hide: 100"
                  >
                    <ul className="group lvl0 uk-nav uk-dropdown-nav">
                      <li
                        className="item"
                        data-uk-toggle="target: .platform-role"
                        onClick={() => {
                          handleChange("platformRole", "stakeholder");
                          UIkit.dropdown(".platform-role").hide();
                        }}
                      >
                        <span className="clickable">User</span>
                      </li>
                      <li
                        className="item"
                        data-uk-toggle="target: .platform-role"
                        onClick={() => {
                          handleChange("platformRole", "global-admin");
                          UIkit.dropdown(".platform-role").hide();
                        }}
                      >
                        <span className="clickable">Global admin</span>
                      </li>
                      {editor.platformRole === "technical-admin" && (
                        <li
                          className="item"
                          data-uk-toggle="target: .platform-role"
                          onClick={() => {
                            handleChange("platformRole", "technical-admin");
                            UIkit.dropdown(".platform-role").hide();
                          }}
                        >
                          <span className="clickable">Technical admin</span>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="right">
            {!!modifiedCurrUser && !!modifiedCurrUser.id && (
              <div className="row row-input last-accessed">
                <i className="italic">
                  Last credentials send :{" "}
                  {modifiedCurrUser.credentialSent &&
                  modifiedCurrUser.credentialSentDate
                    ? DateTime.fromISO(
                        modifiedCurrUser.credentialSentDate
                      ).toFormat("LL/dd/yyyy - HH:mm")
                    : "Never"}
                </i>
              </div>
            )}
            <div className="row row-input">
              <span className="label">User type</span>
              <div className="selector">
                <div className="select">
                  <button
                    className={`btn btn-select btn-filter ${
                      formDirty.find((i) => i === "userType")
                        ? "is-modified"
                        : ""
                    }`}
                    type="button"
                  >
                    <span className="btn_label">
                      {modifiedCurrUser.userType}
                    </span>
                    <span className="btn_icon"></span>
                  </button>
                  <div
                    className="drop drop-select user-type"
                    data-uk-dropdown="pos: top-left; mode: click; delay-hide: 100"
                  >
                    <ul className="group lvl0 uk-nav uk-dropdown-nav">
                      <li
                        className="item"
                        data-uk-toggle="target: .user-type"
                        onClick={() => {
                          handleChange("userType", "internal");
                          UIkit.dropdown(".user-type").hide();
                        }}
                      >
                        <span className="clickable">Internal</span>
                      </li>
                      <li
                        className="item"
                        data-uk-toggle="target: .user-type"
                        onClick={() => {
                          handleChange("userType", "external");
                          UIkit.dropdown(".user-type").hide();
                        }}
                      >
                        <span className="clickable">External</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="manage-account">
        {modifiedCurrUser.id !== editor.id && (
          <>
            {(modifiedCurrUser.platformRole !== "technical-admin" ||
              (modifiedCurrUser.platformRole === "technical-admin" &&
                editor.platformRole === "technical-admin")) && (
              <>
                <h2 className="section-title">
                  <i className="fas fa-user-cog"></i> Manage account
                </h2>
                <div
                  className={`manage-account_inner ${
                    modifiedCurrUser.id !== editor.id &&
                    modifiedCurrUser.platformRole !== "technical-admin"
                      ? "align-full"
                      : ""
                  }`}
                >
                  <i className="italic">Account status</i>
                  <div className="account-status">
                    <span
                      className={`${
                        false === modifiedCurrUser.blockedAccount
                          ? "active"
                          : ""
                      }`}
                    >
                      Enable
                    </span>
                    <div
                      className="toggle"
                      data-left="Enable"
                      data-right="Blocked"
                    >
                      <input
                        type="checkbox"
                        id="active"
                        className="toggle-input"
                        onClick={() =>
                          handleChange(
                            "blockedAccount",
                            !modifiedCurrUser.blockedAccount
                          )
                        }
                        defaultChecked={modifiedCurrUser.blockedAccount}
                        disabled={
                          modifiedCurrUser.platformRole === "technical-admin" &&
                          editor.platformRole !== "technical-admin"
                            ? true
                            : false
                        }
                      />
                      <div className="toggle-switcher"></div>
                    </div>
                    <span
                      className={`${
                        true === modifiedCurrUser.blockedAccount ? "active" : ""
                      }`}
                    >
                      Blocked
                    </span>
                  </div>

                  <div className="delete">
                    {!!modifiedCurrUser && !!modifiedCurrUser.id && (
                      <button
                        className="btn btn-select btn-primary btn-delete"
                        type="button"
                        data-uk-toggle="#warnDelete"
                      >
                        <span className="btn-label">Delete user</span>
                        <span className="btn-icon">
                          <i className="fas fa-trash"></i>
                        </span>
                      </button>
                    )}
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default UserManager;

import { Project } from "models/project.model";
import { Investor } from "modules/limited-partners/models/investor.model";
import {
  ReferentialActions,
  ReferentialActionTypes,
} from "../actions/referential/referential-action-types";

export interface ReferentialState {
  projects: Project[];
  investors: Investor[];
  investorId: string;
}

const initialState: ReferentialState = {
  projects: [],
  investors: [],
  investorId: "",
};

const referentialReducer = (
  state: ReferentialState = initialState,
  action: ReferentialActions
): ReferentialState => {
  switch (action.type) {
    case ReferentialActionTypes.SetProjects:
      return {
        ...state,
        projects: [...action.projects],
      };
    case ReferentialActionTypes.SetInvestors:
      return {
        ...state,
        investors: [...action.investors],
      };
    case ReferentialActionTypes.SetInvestor:
      return {
        ...state,
        investorId: action.investorId,
      };

    default:
      return state;
  }
};

export default referentialReducer;

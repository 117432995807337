import React, { useContext, useEffect, useState } from "react";
import { Assumption } from "modules/hub/models/project-macroeconomic-assumptions";
import ProjectAssumptionsHubItem from "./ProjectAssumptionsHubItem";

import "./styles.scss";
import { connect } from "react-redux";
import { RootState, ThunkDispatch } from "models/store.models";
import { getFileByDocumentType, getMacroAssumptions } from "modules/shared/services/spv-reporting.service";
import { cloneDeep } from "lodash";
import UploadConfig, { confirmDeleteFile, MaxChunkSize, UploadFile } from "modules/shared/services/upload.service";
import { ReportingContext } from "context/Reporting.context";

interface ProjectAssumptionsHubProps {
  accessToken: string;
}

const mapStateToProps = ({ session }: RootState) => ({
  accessToken: session?.sessionData?.accessToken ? session?.sessionData?.accessToken : "",
  user: session?.sessionData?.userData,
});

const mapDispatchToProps = (dispatch: ThunkDispatch) => ({});

const ProjectAssumptionsHub = ({ accessToken }: ProjectAssumptionsHubProps) => {

  const { data, currentTab, assumptions, setRawData, setData, setIsValid } = useContext(ReportingContext);

  const [directory, setDirectory] = useState<string | null>(null);

  const [files, setFiles] = useState<any>([]);

  const init = async () => {
    const tmpAssumptions: any = await getMacroAssumptions(accessToken);
    if ("ok" === tmpAssumptions.status) {
      if (!!tmpAssumptions.data && !!tmpAssumptions.data.assumptions) {
        setRawData(cloneDeep(tmpAssumptions.data));
        setData(tmpAssumptions.data);
        // console.log(tmpAssumptions.data);
      } 
    }
  };

  const loadFiles = async () => {
    const tmpFiles: any = await getFileByDocumentType("macroeconomic", accessToken, null, "" );

    if ("ok" === tmpFiles.status) {
      setFiles(tmpFiles.files);
      setDirectory(tmpFiles.directory);
    }
  };

  const addNew = (e: any) => {
    const newData: any = cloneDeep(data[currentTab]);
    const tmpAssumptions: Assumption[] = cloneDeep(assumptions.assumptions);
    tmpAssumptions.push({
      code: `file_${tmpAssumptions.length + 1}`,
      title: "",
      description: "",
      file: null,
    });
    newData.assumptions = tmpAssumptions;
    setData(newData);
  };

  const deleteAssumption = (index: number) => {
    const newData: any = cloneDeep(data[currentTab]);
    let tmpAssumptions: any = cloneDeep(assumptions.assumptions);
    tmpAssumptions.splice(index, 1);
    newData.assumptions = tmpAssumptions;
    setData(newData);
  }

  const handleChange = (index: number, type: any, value: any) => {
    const newData: any = cloneDeep(data[currentTab]);
    let tmpAssumptions: any = cloneDeep(assumptions.assumptions);

    if ("string" === typeof type) {
      tmpAssumptions[index][type] = value;
    } else {
      type.forEach((t: string, idx: number) => {
        tmpAssumptions[index][t] = value[idx];
      });
    }

    newData.assumptions = tmpAssumptions;
    setData(newData);
  };

  const selectUploadFile = (file: any) => {
    if (!!file) {
      const totalFiles = Math.ceil(file.size / MaxChunkSize);
      UploadConfig.accessToken = accessToken;
      UploadConfig.maxChunks = totalFiles;
      UploadConfig.uploading = true;
      UploadFile(directory, { filename: `${file.name}`, file: file }, (data: any) => setFiles([...files, ...[data]]));
    }
  };

  const removeFile = (index: number) => {
    const tmpFiles: any = cloneDeep(files);
    tmpFiles.splice(index, 1);
    setFiles(tmpFiles);
  };

  useEffect(() => {
    if(2 === currentTab){
      let countValid = 0;
      assumptions.assumptions.forEach((ma: any) => {
        if (!!ma.title && !!ma.file) {
          countValid++;
        }
      });

      setIsValid(
        0 < assumptions.assumptions.length &&
          assumptions.assumptions.length === countValid
      );
    }
  }, [assumptions, currentTab]);

  useEffect(() => {
    if(2 === currentTab){
      init();
      loadFiles();
    }
  }, [currentTab]);

  return (
    <>
      <div className="project-details-assumptions-hub_head">
        <h2>Macroeconomic assumptions table</h2>
        <h3>Add/replace table</h3>
      </div>

      <div className="project-details-assumptions-hub_content">
        {!!assumptions.assumptions &&
          assumptions.assumptions.map((m: any, idx: number) => (
            <ProjectAssumptionsHubItem
              key={`project-${idx}`}
              index={idx}
              accessToken={accessToken}
              directory={directory}
              handleChange={(type: string, value: any) => handleChange(idx, type, value)}
              deleteAssumption={deleteAssumption}
              macro={m}
            />
          ))}
        <label
          htmlFor={`upload-img`}
          className="btn btn-select btn-primary"
          onClick={addNew}
        >
          <span className="btn-label">Add new image (.png, .jpg)</span>
          <span className="btn-icon">
            <i className={`fas fa-upload`}></i>
          </span>
        </label>
      </div>

      <div className="project-details-assumptions-hub_foot">
        <div className="additional-docs">
          <div className="additional-docs_inner">
            <h4>Data table</h4>
            <p>Please add data table file for user</p>
            <input
              type="file"
              id="upload-csv"
              hidden
              accept=".csv"
              onChange={(e: any) => {
                e.preventDefault();
                selectUploadFile(e.target.files[0]);
              }}
            />
            <label
              htmlFor="upload-csv"
              className={`btn btn-select btn-primary ${
                !!files && 0 < files.length ? "disabled" : ""
              }`}
            >
              <span className="btn-label">Add table (.csv)</span>
              <span className="btn-icon">
                <i className={`fas fa-upload`}></i>
              </span>
            </label>
            <ul className="files-list">
              {!!files &&
                files.map((file: any, idx: number) => (
                  <li key={`files-${idx}`} className="files-item">
                    <span>{file.name}</span>
                    <i
                      className={`fas fa-times-circle`}
                      onClick={() =>
                        confirmDeleteFile(accessToken, file.name, file.id, () =>
                          removeFile(idx)
                        )
                      }
                    ></i>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectAssumptionsHub);

import React, { useContext, useEffect, useState } from "react";
import {
  defaultMemberData,
  defaultPolicyData,
  ProjectIndicatorsSection,
} from "modules/hub/models/project-indicators";

import ProjectIndicatorsRowLeader from "../ProjectIndicatorsRow/ProjectIndicatorsRowLeader";
import {
  getBasicIndicators,
  getProjectIndicators,
} from "modules/shared/services/spv-reporting.service";
import { RootState, ThunkDispatch } from "models/store.models";
import { connect } from "react-redux";
import { cloneDeep } from "lodash";

import "./styles.scss";
import { ReportingContext } from "context/Reporting.context";
import { useParams } from "react-router-dom";
import WarnModal from "modules/shared/components/WarnModal/WarnModal";

interface ProjectIndicatorsProps {
  user: any;
  project: any;
  accessToken: string;
  currency?: string;
}

const mapStateToProps = ({ session }: RootState) => ({
  accessToken: session?.sessionData?.accessToken
    ? session?.sessionData?.accessToken
    : "",
  user: session?.sessionData?.userData,
});

const mapDispatchToProps = (dispatch: ThunkDispatch) => ({});

const ProjectIndicatorsLeaderView = ({
  accessToken,
  project,
  user,
  currency
}: ProjectIndicatorsProps) => {

  const { projectCode } = useParams<{ projectCode: string }>();

  const { currentTab, defaultProjectIndicators, projectIndicators, totalProjectIndicatorsValidate, data, setData, setRawProjectIndicators, setProjectIndicators, setIsValid, setTotalProjectIndicatorsValidate } = useContext(ReportingContext);

  const [basicIndicators, setBasicIndicators] = useState<any>({});

  const [memberToDeleteIndex, setMemberToDeleteIndex] = useState<number>(-1);

  const [policyToDeleteIndex, setPolicyToDeleteIndex] = useState<number>(-1);

  const checkTotalProjectIndicators = (tmpIndicators: any, tmpData?: any) => {

    // console.log('');
    // console.log('__ CHECK TOTAL PROJECT INDICATORS __');
    // console.log(tmpIndicators);
    // console.log(tmpData);
    // console.log('');
    // console.log('');


    let total: number = 0;
    for(let i in tmpIndicators){
      if(!!i.match(/Display$/)){
        const indicator: string = i.replace(/Display$/, '');

        if(!!tmpData && !!tmpData[indicator]){
          // console.log(indicator);
          switch(indicator){
            case 'projectRating':
              // console.log('-- indicator', indicator, !!tmpIndicators[i] && 'yes' === tmpData[indicator].isReady ? 1 : 0);
              total += !!tmpIndicators[i] && 'yes' === tmpData[indicator].isReady ? 1 : 0;
            break;
            case 'availabilityData':
              // console.log('-- indicator', indicator, !!tmpIndicators[i] && 'yes' === tmpData[indicator].isRevenuesBasedOnPayments ? 1 : 0);
              total += !!tmpIndicators[i] && 'yes' === tmpData[indicator].isRevenuesBasedOnPayments ? 1 : 0;
            break;
            case 'insuranceData':
              // console.log('-- indicator', indicator, !!tmpIndicators[i] && 'yes' === tmpData[indicator].hasCoverage ? 1 : 0);
              total += !!tmpIndicators[i] && 'yes' === tmpData[indicator].hasCoverage ? 1 : 0;
            break;
            case 'adscr':
              // console.log('-- indicator', indicator, !!tmpIndicators[i] && 'yes' === tmpData[indicator].hasDscrs ? 1 : 0);
              total += !!tmpIndicators[i] && 'yes' === tmpData[indicator].hasDscrs ? 1 : 0;
            break;
            default:
              // console.log('-- indicator', i, !!tmpIndicators[i] ? 1 : 0);
              total += !!tmpIndicators[i] ? 1 : 0;
          }
        }else{
          // console.log('-- indicator', i, !!tmpIndicators[i] ? 1 : 0);
          total += !!tmpIndicators[i] ? 1 : 0;
        }
      }else if('state' === i){
        if(!!tmpData){
          total += ('inConstruction' === tmpData.projectStage.id || 'underDevelopment' === tmpData.projectStage.id) ? 1 : 0;
        }else{
          total += 'launched' === tmpIndicators.state ? 1 : 0;
        }
      }
    }
    // console.log('%c __ TOTAL PROJECT INDICATORS TO VALIDATE', 'font-size:15px;color:red', total);
    setTotalProjectIndicatorsValidate(total);
  }

  const init = async () => {

    let tmpBasicIndicators: any = [];
    let tmpPIndicators: any = [];

    // Get available indicators set on Meridiam side
    const tmpIndicators: any = await getBasicIndicators(accessToken);
    if ("ok" === tmpIndicators.status) {
      if (!!tmpIndicators.data && !!tmpIndicators.data.dueDate) {
        setBasicIndicators(tmpIndicators.data);
        setData(tmpIndicators.data);
        tmpBasicIndicators = tmpIndicators.data;
      }
    }

    // Get saved data
    const tmpProjectIndicators: any = await getProjectIndicators(accessToken, projectCode);
    if("ok" === tmpProjectIndicators.status){
      setRawProjectIndicators(!!tmpProjectIndicators.data.id ? tmpProjectIndicators.data : defaultProjectIndicators);
      setProjectIndicators(!!tmpProjectIndicators.data.id ? tmpProjectIndicators.data : defaultProjectIndicators);
      tmpPIndicators = !!tmpProjectIndicators.data.id ? tmpProjectIndicators.data : defaultProjectIndicators;
    }
    
    checkTotalProjectIndicators(tmpBasicIndicators, tmpPIndicators);
  };

  const getTotalProjectIndicatorsValidate = (tmp: any) => {
    let total: number = 0;
    for(let i in tmp){
      if(!!tmp[i] && 'object' === typeof tmp[i] && 'undefined' !== typeof tmp[i].isValidate){
        total += !!tmp[i].isValidate ? 1 : 0;
      }
    }
   return total;
  }

  const handleChange = (type: string, value: any, field?: string, action?: string, index?: number) => {

    if(!!field){
      const tmp: any = cloneDeep(projectIndicators);

      if('undefined' === typeof tmp[type]){
        tmp[type] = {};
      }

      if(!!action){
        switch(action){
          case 'add':
            switch(field){
              case 'members':
                tmp[type][field].push(defaultMemberData);
              break;
              case 'policies':
                if('undefined' === typeof tmp[type][field]){
                  tmp[type][field] = [];
                }

                tmp[type][field].push(defaultPolicyData);
              break;
            }
          break;
          case 'remove':
            tmp[type][field].splice(index, 1);
            switch(field){
              case 'members':
                setMemberToDeleteIndex(-1);
              break;
              case 'policies':
                setPolicyToDeleteIndex(-1);
              break;
            }
          break;
          case 'confirmRemove':
            switch(field){
              case 'members':
                setMemberToDeleteIndex(value);
              break;
              case 'policies':
                setPolicyToDeleteIndex(value);
              break;
            }
          break;
          case 'update':
            switch(type){
              case 'boardMembers':
                if('undefined' !== typeof index && !!tmp[type].members[index]){
                  tmp[type].members[index][field] = value;
                }
              break;
              case 'insuranceData':
                if('undefined' !== typeof index && !!tmp[type].policies[index]){
                  tmp[type].policies[index][field] = value;
                }
              break;
            }
          break;
        }
      }else{
        switch(type){
          case 'projectRating':
            if('isReady' === field && 'no' === value){
              tmp[type].isValidate = false;
            }
          break;
          case 'availabilityData':
            if('isRevenuesBasedOnPayments' === field && 'no' === value){
              tmp[type].isValidate = false;
            }
          break;
        }

        tmp[type][field] = value;
      }

      if(totalProjectIndicatorsValidate > getTotalProjectIndicatorsValidate(tmp) && !!tmp.isComplete){
        tmp.isComplete = false;
      }
      
      checkTotalProjectIndicators(data[currentTab], tmp);
      setProjectIndicators(tmp);
    }else{
      const newValue: any = cloneDeep(projectIndicators);
      if('projectStage' === type && !!value.id && newValue.projectStage.id !== value.id){
        switch(newValue.projectStage.id){
          case 'inConstruction':
            newValue.construction.isValidate = false;
          break;
          case 'underDevelopment':
            newValue.expectedFcDate.isValidate = false;
          break;
        }
      }
      newValue[type] = value;

      // const newValue: any = { ...projectIndicators, ...{ [type]: value } };
      checkTotalProjectIndicators(data[currentTab], newValue);
      setProjectIndicators(newValue);
    }
  };

  useEffect(() => {
    if(0 === currentTab){
      setIsValid(
        true
      );
    }
  }, [basicIndicators]);

  useEffect(() => {
    if(0 === currentTab){
      init();
    }
  }, [currentTab]);

  return (
    <>
      <div className="project-details-indicators_head">
     
      </div>
      
      <div className="project-details-indicators_content">
        {ProjectIndicatorsSection.filter(
              (item: any) =>
                "projectStage" === item.slug ||
                "construction" === item.slug ||
                "expectedFcDate" === item.slug ||
                ("projectStage" !== item.slug &&
                  !!basicIndicators[`${item.slug}Display`])
            ).map((item, idx) => (
              <ProjectIndicatorsRowLeader
                key={`section-${item.slug}-${idx}`}
                section={item}
                indicators={basicIndicators}
                accessToken={accessToken}
                project={project}
                // projectIndicators={!!projectIndicators && !!projectIndicators[item.slug] ? projectIndicators[item.slug] : ""}
                projectIndicators={projectIndicators}
                handleChange={handleChange}
                currency={currency}
              />
            ))
        }
      </div>

      <div className="project-details-indicators_foot"></div>

      <WarnModal
        id={`warnDeleteLoopData`}
        content={`Do you really want to delete this ${-1 !== memberToDeleteIndex ? 'member' : -1 !== policyToDeleteIndex ? 'policy' : 'item'}?`}
        actionYes={() => {
          if(-1 !== memberToDeleteIndex){
            handleChange('boardMembers', memberToDeleteIndex, 'members', 'remove', memberToDeleteIndex)
          }else if(-1 !== policyToDeleteIndex){
            handleChange('insuranceData', policyToDeleteIndex, 'policies', 'remove', policyToDeleteIndex)
          }
        }}
        actionNo={() => {
            if(-1 !== memberToDeleteIndex){
              setMemberToDeleteIndex(-1)
            }else if(-1 !== policyToDeleteIndex){
              setPolicyToDeleteIndex(-1)
            }
          }
        }
      />
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectIndicatorsLeaderView);

import axios from "axios";
import { PlatformGroup } from "models/platform-group.model";
import {
  CreateOrUpdateUserModel,
  ExportUser,
  User,
  WellKnownModuleRoles,
} from "models/user.models";
import config from "modules/config";

const ApiBaseUrl = config.ApiUrl;

const getAllUsers = async (accessToken: string): Promise<User[]> => {
  const result = await axios({
    method: "get",
    url: `${ApiBaseUrl}/user/allPlatformUsers`,
    headers: { Authorization: "Bearer " + accessToken },
  });

  return result.data;
};

const getAllPlatformGroups = async (
  accessToken: string
): Promise<PlatformGroup[]> => {
  const result = await axios({
    method: "get",
    url: `${ApiBaseUrl}/group/all`,
    headers: { Authorization: "Bearer " + accessToken },
  });

  return result.data;
};

const createGroup = async (
  accessToken: string,
  name: string
): Promise<PlatformGroup> => {
  const result = await axios({
    method: "post",
    url: `${ApiBaseUrl}/group/${name}`,
    headers: { Authorization: "Bearer " + accessToken },
  });

  return result.data;
};

const createOrUpdateUser = async (
  accessToken: string,
  createOrUpdateUser: CreateOrUpdateUserModel
): Promise<User> => {
  const result = await axios({
    method: "post",
    url: `${ApiBaseUrl}/user/updateOrCreatePlatformUser`,
    headers: { Authorization: "Bearer " + accessToken },
    data: createOrUpdateUser,
  });

  return result.data;
};

const SetSameRoleForAllProjects = async (
  accessToken: string,
  role: WellKnownModuleRoles,
  updatedUser: User
): Promise<User> => {
  // console.log("call API");
  // console.log(updatedUser);

  const result = await axios({
    method: "post",
    url: `${ApiBaseUrl}/user/sameRoleForAllProject`,
    headers: { Authorization: "Bearer " + accessToken },
    data: {
      updatedUser: updatedUser,
      role: role,
    },
  });

  return result.data;
};

const sendEmail = async (
  accessToken: string,
  userId: number,
  type: string
): Promise<any> => {
  const result = await axios({
    method: "get",
    url: `${ApiBaseUrl}/user-emailing/${userId}/welcome-email/${type}`,
    headers: { Authorization: "Bearer " + accessToken },
  });

  return result.data;
};

const deleteUser = async (
  accessToken: string,
  userId: number
): Promise<User> => {
  const result = await axios({
    method: "delete",
    url: `${ApiBaseUrl}/user/${userId}`,
    headers: { Authorization: "Bearer " + accessToken },
  });

  return result.data;
};

const exportAllUsersModule = async (
  accessToken: string,
  module: string
): Promise<ExportUser[]> => {
  const result = await axios({
    method: "get",
    url: `${ApiBaseUrl}/user/export/${module}`,
    headers: { Authorization: "Bearer " + accessToken },
  });

  return result.data;
};

export {
  getAllUsers,
  getAllPlatformGroups,
  createGroup,
  createOrUpdateUser,
  sendEmail,
  // sendAccessEmailToAllUsersType,
  deleteUser,
  exportAllUsersModule,
  SetSameRoleForAllProjects,
};

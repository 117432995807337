import { RootState, ThunkDispatch } from "models/store.models";
// import { User } from "models/user.models";
import { signOut } from "modules/auth/actions/session-actions";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "./styles.scss";
import MenuUser from "modules/shared/components/Menu/User/MenuUser";
import TeamModal from "modules/shared/components/TeamModal/TeamModal";

const mapStateToProps = ({ session, referential }: RootState) => ({
  accessToken:
    session.sessionData && session.sessionData.accessToken
      ? session.sessionData.accessToken
      : "",
  user: session?.sessionData?.userData,
  investorId: referential.investorId,
});

const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  signOut: () => dispatch(signOut()),
});

type HeaderProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    colorContext?: string;
    titleContext?: string;
    classNameToolbarTop?: string;
    menuUserLength?: number;
    setMenuUserItemLength?: any;
    tool?: string;
  };

const AccountMenu = {
  label: "Account",
  typeLink: "route",
  link: "/survey-sdg/account/profile",
  icon: "fas fa-cog",
};

const PlatformMenu = {
  label: "Platform",
  typeLink: "route",
  link: "/",
  icon: "fas fa-clipboard",
};

const MyTeamMenu = {
  label: "My team",
  icon: "fas fa-user",
  typeLink: "modale",
  link: "#teamModal",
};

// const AttachmentMenu = {
//   label: "Attachment",
//   icon: "fas fa-file",
//   typeLink: "modale",
//   link: "#modalAssetsManager",
// };

const Header: React.FC<HeaderProps> = ({
  accessToken,
  titleContext,
  colorContext,
  classNameToolbarTop,
  user,
  tool,
  menuUserLength,
  setMenuUserItemLength,
  investorId,
  signOut,
}) => {
  const [hasPrivilege, setHasPrivilege] = useState<boolean>(false);
  const [menuContext, setMenuContex] = useState<any>([]);

  useEffect(() => {
    let menu = [];
    if (!!user) {
      switch (user.platformRole) {
        case "technical-admin":
        case "global-admin":
          switch (tool) {
            case `lp-dashboard`:
              menu.push(AccountMenu);
              menu.push(MyTeamMenu);
              menu.push(PlatformMenu);
              break;
            default:
              menu.push(AccountMenu);
              menu.push(PlatformMenu);
          }
          break;
        case "stakeholder":
          switch (tool) {
            case `lp-dashboard`:
              menu.push(AccountMenu);
              if (checkPrivilege()) {
                setHasPrivilege(true);
                menu.push(MyTeamMenu);
              } else {
                setHasPrivilege(false);
              }
              menu.push(PlatformMenu);
              break;
            default:
              menu.push(AccountMenu);
              menu.push(PlatformMenu);
          }
          break;
        default:
          menu.push(AccountMenu);
          menu.push(PlatformMenu);
          break;
      }
      setMenuContex(menu);
      if (!!setMenuUserItemLength) {
        setMenuUserItemLength(menu.length + 1);
      }
    }
  }, [investorId]);

  const checkPrivilege = () => {
    let hasPrivilege = false;
    if (!!user && user.privileges) {
      for (let i in user.privileges) {
        if ("lp-dashboard" === i.toLowerCase()) {
          const privileges: any = user?.privileges[i];
          for (let p in privileges) {
            if ("superadmin" === privileges[p].role) {
              hasPrivilege = true;
              break;
            }
            if (
              "admin" === privileges[p].role &&
              privileges[p].details.investorId === investorId
            ) {
              hasPrivilege = true;
              break;
            }
          }
        }
      }
    }
    return hasPrivilege;
  };

  return (
    <>
      <div
        id="userMenu"
        className="over-toolbar-top"
        data-context={colorContext}
        data-item-length={menuUserLength}
      >
        <div className="inner">
          <nav>
            <ul>
              <li>
                <button className="link" onClick={signOut}>
                  <span className="link-icon">
                    <i className="fas fa-power-off"></i>
                  </span>
                  <span className="link-label">Log out</span>
                </button>
              </li>
              <MenuUser menuItems={menuContext} current={``} />
            </ul>
          </nav>
        </div>
      </div>
      <div
        className={`toolbar-top toolbar-${classNameToolbarTop}`}
        data-context={colorContext}
      >
        <div className="inner">
          <div className="area area-left">
            <Link to="/">
              <span className="brand">Meridiam | </span>
            </Link>
            <span className="universe upp">{titleContext}</span>
          </div>
          <div className="area area-right">
            <button
              className="link link-user"
              data-uk-toggle="target: .header-wrapper; cls: open;"
            >
              {!!user && (
                <span className="link-label">{`${user.firstName} ${user.lastName}`}</span>
              )}
              <span className="link-icon">
                <i className="fas fa-user"></i>
              </span>
            </button>
          </div>
        </div>
      </div>

      {!!user && !!user.id && !!hasPrivilege && (
        <TeamModal
          accessToken={accessToken}
          module={"lp-dashboard"}
          moduleId={investorId}
          me={{
            id: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            login: user.login,
            platformRole: user.platformRole,
          }}
        />
      )}
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);

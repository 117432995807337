import { Tooltip } from "@material-ui/core";
import React from "react";

const { DateTime } = require("luxon");

const CountryDataEditItem: React.FC<{
  isMCQ: boolean;
  currCountryData: any;
  country: any;
  handleChange: (event: any, field: string, countryCode: string) => void;
  handleSave: (countryCode?: string) => void;
  formDirty: string[];
}> = ({
  isMCQ,
  currCountryData,
  country,
  handleChange,
  handleSave,
  formDirty,
}) => {
  return (
    <li
      className={`
        ${
          !!country.data && !!country.data.value && country.data.value !== ""
            ? "row"
            : "row warn"
        }
        ${formDirty.find((i) => i === country.code) ? "is-modified" : ""}
      `}
    >
      <div className="row-content">
        <div className="name">
          <div className="country">{country.name}</div>
          <div className="iso-code">{country.code}</div>
        </div>


        {/* CHAMP DESCRIPTION */}
        <div className="description">
          <div id={`description-${country.name.replace(" ", "_")}`} className="tooltip" hidden>
            <textarea
              rows={5}
              value={
                !!country.data && !!country.data.description
                  ? country.data.description
                  : ""
              }
              onChange={(event) =>
                handleChange(event, "description", country.code)
              }
              placeholder="Your description..."
            >
              {!!country.data &&
                !!country.data.description &&
                country.data.description}
            </textarea>
            <div className="btn-container">
              <button
                className="btn btn-select btn-primary"
                type="button"
                data-uk-toggle={`target: #description-${country.name.replace(" ", "_")}`}
              >
                <span className="btn-label">Close</span>
                <span className="btn-icon">
                  <i className="fas fa-times"></i>
                </span>
              </button>
              <button
                className="btn btn-select btn-primary"
                type="button"
                data-uk-toggle={`target: #description-${country.name.replace(" ", "_")}`}
                onClick={() => handleSave(country.code)}
              >
                <span className="btn-label">Save</span>
                <span className="btn-icon">
                  <i className={`fas fa-save`}></i>
                </span>
              </button>
            </div>
          </div>
          <button
            className={`btn btn-select btn-primary ${
              !!country.data && !!country.data.description ? "filled" : ""
            }`}
            type="button"
            data-uk-toggle={`target: #description-${country.name.replace(" ", "_")}`}
          >
            <span className="btn-label">
              {!!country.data && !!country.data.description
                ? "View/edit description"
                : "Add a description"}
            </span>
            <span className="btn-icon">
              {!!country.data && !!country.data.description ? (
                <i className={`fas fa-eye`}></i>
              ) : (
                <i className={`fas fa-plus`}></i>
              )}
            </span>
          </button>
        </div>

        {/* CHAMP LINK */}
        <div className="link">
          <div id={`link-${country.name.replace(" ", "_")}`} className="tooltip" hidden>
            {/* <input
              type="text"
              placeholder="Your link..."
              value={
                !!country.data && !!country.data.link ? country.data.link : ""
              }
              onChange={(event) => handleChange(event, "link", country.code)}
            /> */}

            <textarea
              rows={5}
              value={
                !!country.data && !!country.data.link
                  ? country.data.link
                  : ""
              }
              onChange={(event) =>
                handleChange(event, "link", country.code)
              }
              placeholder="Your link..."
            >
              {!!country.data &&
                !!country.data.link &&
                country.data.link}
            </textarea>

            <div className="btn-container">
              <button
                className="btn btn-select btn-primary"
                type="button"
                data-uk-toggle={`target: #link-${country.name.replace(" ", "_")}`}
              >
                <span className="btn-label">Close</span>
                <span className="btn-icon">
                  <i className="fas fa-times"></i>
                </span>
              </button>
              <button
                className="btn btn-select btn-primary"
                type="button"
                data-uk-toggle={`target: #link-${country.name.replace(" ", "_")}`}
                onClick={() => handleSave(country.code)}
              >
                <span className="btn-label">Save</span>
                <span className="btn-icon">
                  <i className={`fas fa-save`}></i>
                </span>
              </button>
            </div>
          </div>
          <button
            className={`btn btn-select btn-primary ${
              !!country.data && !!country.data.link ? "filled" : ""
            }`}
            type="button"
            data-uk-toggle={`target: #link-${country.name.replace(" ", "_")}`}
          >
            <span className="btn-label">
              {!!country.data && !!country.data.link ? "View link" : "Add link"}
            </span>
            <span className="btn-icon">
              {!!country.data && !!country.data.link ? (
                <i className={`fas fa-link`}></i>
              ) : (
                <i className={`fas fa-plus`}></i>
              )}
            </span>
          </button>
        </div>

        {!isMCQ && (
          <div className="value">
            <input
              type="number"
              placeholder="value"
              value={
                !!country.data && !!country.data.value ? country.data.value : ""
              }
              onChange={(event) => handleChange(event, "value", country.code)}
            />
          </div>
        )}

        {/* CHAMP LAST YEAR VALUE */} 
        <div className="last-year-value">
          <div className="last-year-content">{!!country.lastYearValue && country.lastYearValue.value ? country.lastYearValue.value : "-"}</div>
          <div className="warning">
            {/* WARNING si != 20% 
                calculer % valeur actuel 
                EXEMPLE : new = 60 | old = 4.6
                (country.data.value * 0.2) => [60-12 ; 60+12] => [48 ; 72]
            */}
            {!!country.lastYearValue && country.lastYearValue.value && 
            !!country.data && !!country.data.value && 
            (country.lastYearValue.value < (country.data.value - country.data.value * 0.2) 
            || 
            country.lastYearValue.value > (country.data.value + country.data.value * 0.2))
            &&
              
              <Tooltip arrow title={`The values ​​differ by ${country.lastYearValue.value < (country.data.value - country.data.value * 0.2) ? "-" : "+" }20%.`}>
                <i className="fas fa-exclamation-circle"></i>
              </Tooltip>
            }
              
          </div>
        </div>

        <div className="last-update">
          {!!country.data && !!country.data.lastUpdate
            ? DateTime.fromISO(country.data.lastUpdate).toFormat("LL/dd/yyyy")
            : "-"}
        </div>
      </div>

      {/* TO DO MCQ  */}
      {!!currCountryData &&
        !!currCountryData.dataType &&
        currCountryData.dataType === "binary" && (
          <div className="answer-container">
            <span className="choose">
              <i className={`fas fa-exclamation-circle`}></i>
              Choose an answer
            </span>
            <div className="answer-row">
              <input
                type="radio"
                id={`id1Mcq-${country.code}`}
                name={`valueMcq-${country.code}`}
                value="Yes"
                onChange={(event) => handleChange(event, "value", country.code)}
                className={`${
                  !!country.data && country.data.value === "Yes" && "checked"
                }`}
              />
              <label htmlFor={`id1Mcq-${country.code}`}>
                <span>Yes</span>
              </label>
            </div>
            <div className="answer-row">
              <input
                type="radio"
                id={`id2Mcq-${country.code}`}
                name={`valueMcq-${country.code}`}
                value="No"
                className={`${
                  !!country.data && country.data.value === "No" && "checked"
                }`}
                onChange={(event) => handleChange(event, "value", country.code)}
              />
              <label htmlFor={`id2Mcq-${country.code}`}>
                <span>No</span>
              </label>
            </div>
          </div>
        )}
    </li>
  );
};

export default CountryDataEditItem;

import { RootState, ThunkDispatch } from "models/store.models";
import React from "react";
import { connect } from "react-redux";

import "./styles.scss";
import styles from "./styles.module.scss";

import MenuFooter, {
  MenuItems,
} from "modules/shared/components/Menu/Footer/MenuFooter";
import ModalFooter, { ModalFooterContent } from "../ModalFooter/ModalFooter";
import ContactUsModal from "../ContactUsModal/ContactUsModal";
const mapStateToProps = ({ session }: RootState) => ({
  user: session?.sessionData?.userData,
  token: session?.sessionData?.accessToken,
});

const mapDispatchToProps = (dispatch: ThunkDispatch) => ({});

type FooterProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    colorContext?: string;
    titleContext?: string;
    classNameToolbarTop?: string;
    tool?: string;
  };

const menuArrayDefault: MenuItems[] = [];

const menuArrayDefaultLPs: MenuItems[] = [
  {
    label: "Methodology",
    typeLink: "modale",
    link: "#modalPageMethodology",
  },
  {
    label: "Contact us",
    typeLink: "modale",
    link: "#modalPageContactUs",
  },
];

const menuArrayDefaultReportl: MenuItems[] = [
  {
    label: "Contact us",
    typeLink: "modale",
    link: "#modalPageContactUs",
  },
];

// const menuArrayTechnicalAdmin: MenuItems[] = [
//   // {
//   //   label: "About",
//   //   typeLink: "modale",
//   //   link: "#modalPageAbout",
//   // },
//   {
//     label: "Methodology",
//     typeLink: "modale",
//     link: "#modalPageMethodology",
//   },
//   {
//     label: "Contact us",
//     typeLink: "modale",
//     link: "#modalPageContactUs",
//   },
// ];

const modalArray: ModalFooterContent[] = [
  {
    title:
      "Please find below a detailed explanation for the several sections of the dashboard, from top to down",
    list: [
      {
        content:
          "1. Financial section: The financial performance of your respective investments in Meridiam Funds.",
      },
    ],
  },
  {
    title:
      "Filters: the filters allow you to visualize the performance by fund and country for each of the subsequent sections:",
    list: [
      {
        content:
          "2. Allocation section: The allocation by assets is a visualization of the portfolio by sub-category of projects.",
      },
      {
        content:
          "3. Geography section: The project’s commitment by geography provides a detail by continent and country.",
      },
      {
        content:
          "4. SDG Assessment section: The SDG impact assessment is the graphic visualization of the performance and contribution to the SDG framework. Using the answers from over 230 ESG/SDG indicators of Meridiam’s annual ESG/SDG survey, this tool measures the contribution of each asset in your portfolio to its relevant SDGs. For example, a hospital will contribute to SDG 3: Good health. The tool translates the answers, from a variety of indicator types into scores ranging from 0 to 5, with 5 being a best-in-class of similar nature and geography in order to contextualize the positive contributions of each project. Each indicator’s score is then aggregated into the target’s score, and the target’s score compose the overall SDG score. By selecting an SDG, you will be able to see the targets and scores that compose it. Because these scores are based on the answers to an annual survey, they will evolve just as the project does. Several indicators will only become accessible and relevant when the project reaches operation phase for example. This will mechanically affect the scores.",
      },
      {
        content:
          "5. Impact and returns section: This corresponds to the graphic visualization of each project’s IRR and SDG ratings.",
      },
      {
        content:
          "6. Key Impact section: This compiles the most significant key performance indicators of your portfolio of projects by type: mobility, social and energy.",
      },
      {
        content:
          "7. Carbon Footprint section: This presents the project emissions of each fund. The project’s emissions are calculated based on a Scope 3 methodology over Meridiam’s detention period (construction and operation). This includes the direct and indirect emissions related to the project’s activity, such as the road users for example. The induced or avoided emissions present the project’s emissions when compared to its reference situation, or before the project’s existence. This corresponds to the energy mix of a country before a new renewable energy production plant or the traffic levels on a road section before its renovation or widening. The last column corresponds to the fund’s alignment in relation to a trajectory where global warming would be limited to 2°C. Meridiam’s objective for all new funds is to stay in line with a 2°C trajectory over their lifetime. For the Meridiam Infrastructure African Fund or “MIAF”), the objective is to remain carbon neutral at portfolio level between induced emissions and avoided emissions over the Funds’ lifetimes.",
      },
    ],
  },
];

const menuContext = (user?: any, tool?: string) => {
  let menu;

  // console.log('menu', user, tool)

  switch (user) {
    // case "technical-admin":
    //   switch (tool) {
    //     case `dashboard`:
    //     case `lp-dashboard`:
    //     default:
    //       menu = menuArrayTechnicalAdmin;
    //       break;
    //   }
    //   break;
    // case "global-admin":
    //   menu = menuArrayGlobalAdmin;
    //   break;
    case "stackholder":
    default:
      switch (tool) {
        case `lp-dashboard`:
          menu = menuArrayDefaultLPs;
          break;
        case 'reporting':
          menu = menuArrayDefaultReportl;
          break;
        case `dashboard`:
        default:
          if (!!/^\/spv-reporting/.test(document.location.pathname)) {
            menu = menuArrayDefaultReportl;
          } else {
            menu = menuArrayDefault;
          }
          break;
      }
      break;
  }
  return menu;
};

const Footer: React.FC<FooterProps> = ({ user, token, tool }) => {
  return (
    <footer id="mainFooter" className="footer">
      <div className="footer_inner">
        {0 < menuContext(user?.platformRole, tool).length &&
          <div className="footer__links">
            <ul className="list">
              <MenuFooter menuItems={menuContext(user?.platformRole, tool)} />
            </ul>
          </div>
        }
        <div className="footer__brand">
          <div className="logo">
            <svg
              className={`${styles["logo"]} svg svg-logo svg-logo-meridiam l`}
            >
              <use xlinkHref="#logoMeridiam"></use>
            </svg>
          </div>
        </div>
      </div>
      <ModalFooter contentModal={modalArray} />
      <ContactUsModal accessToken={token} />
    </footer>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);

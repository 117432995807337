import React, { ReactNode, useEffect, useState } from "react";
import "../../../../assets/scss/custom/tools/_base.scss";
import "./styles.scss";
import { history } from "modules/shared/services/history";
import UIkit from "uikit";
import { User } from "models/user.models";

type PlatformToolBarProps = {
  surveysYear?: number[];
  currentYear?: number;
  currentTool: string;
  setCurrentYear?: any;
  exportAllSurveys?: any;
  isExportingSurveys?: boolean;
  buttonsRight?: ReactNode;
  user: User;
  // isExportingSurveysAnwsers?: boolean;  DO NOT REMOVE - WILL BE IMPLEMENTED LATER
  // exportAllSurveysAnswers?: any; DO NOT REMOVE - WILL BE IMPLEMENTED LATER
};

const PlatformToolBar: React.FC<PlatformToolBarProps> = ({
  surveysYear,
  currentYear,
  currentTool,
  setCurrentYear,
  exportAllSurveys,
  isExportingSurveys,
  buttonsRight,
  user,
  // exportAllSurveysAnswers, DO NOT REMOVE - WILL BE IMPLEMENTED LATER
  // isExportingSurveysAnwsers DO NOT REMOVE - WILL BE IMPLEMENTED LATER
}) => {
  const [hasAccessRosace, setHasAccessRosace] = useState<boolean>(false);
  const [hasAccessContribution, setHasAccessContribution] = useState<boolean>(
    false
  );
  const [hasAccessSurveyManager, setHasAccessSurveyManager] = useState<boolean>(
    false
  );

  const goTo = (url: string) => {
    if (url === "sdg-impact-synthesis") {
      history.push(`/platform/${url}`);
    } else {
      document.location.href = `/survey-sdg/platform/${url}`;
    }
  };

  useEffect(() => {
    UIkit.offcanvas(`#toggle-usage-${currentTool}`).hide();

    if (
      !!user &&
      !!user.privileges["esg-tool"] &&
      user.privileges["esg-tool"].length > 0
    ) {
      user.privileges["esg-tool"].map((p: any) => {
        switch (p.details["toolName"]) {
          case "impact-synthesis":
            setHasAccessRosace(true);
            break;
          case "sdg-contribution":
            setHasAccessContribution(true);
            break;
          case "survey-management":
            setHasAccessSurveyManager(true);
            break;
        }
      });
    }
  }, []);

  return (
    <>
      <div className="toolbar platform-toolbar appear-right-full">
        <div className="inner">
          <div className="menu">
            <button
              className="btn btn-burger"
              data-uk-toggle={`target: #toggle-usage-${currentTool}`}
            >
              <span className="btn-label">Menu</span>
              <span className="btn-icon">
                <i className="fas fa-bars"></i>
              </span>
            </button>
          </div>
          <div className="area area-left">
            {surveysYear && (
              <div className="selector">
                <span className="label">Data</span>
                {1 === surveysYear.length && (
                  <b className="label-year">{currentYear}</b>
                )}
                {1 < surveysYear.length && setCurrentYear !== undefined && (
                  <div className="select">
                    <button
                      className="btn btn-select btn-primary btn-rounded"
                      type="button"
                    >
                      <span className="btn_label">{currentYear}</span>
                      <span className="btn_icon"></span>
                    </button>
                    <div className="drop" data-uk-dropdown="">
                      <ul className="uk-nav uk-dropdown-nav">
                        {surveysYear.map((year: any, i) => {
                          return (
                            <li
                              key={i}
                              className={year === currentYear ? "selected" : ""}
                              onClick={() => setCurrentYear(year)}
                            >
                              <span>{year}</span>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="area area-right">
            {
              "synthesis" === currentTool && (
                <div>
                  <span className="label-export-sdg">Export project's SDG</span>
                  <button
                    className="btn btn-small animated btn-bordered btn-rounded"
                    onClick={() => exportAllSurveys()}
                  >
                    <span className="btn-label">
                      {!isExportingSurveys ? (
                        <i className="fas fa-download"></i>
                      ) : (
                        <i className="fas fa-spinner"></i>
                      )}
                      <span> .xls</span>
                    </span>
                  </button>
                </div>
              )
            }
            {!!buttonsRight && buttonsRight}
            {"survey" === currentTool && (
              <div className="export">
                {/* TO DO - WHEN SURVEY-DATA-BUILDER CONVERTED TO REACT */}
                {/* Export all surveys answers
                <button
                  className="btn btn-small animated btn-bordered btn-rounded"
                  onClick={() => exportAllSurveysAnswers()}
                >
                  <span className="btn-label">
                    {!isExportingSurveysAnwsers ? (
                      <i className="fas fa-download"></i>
                    ) : (
                      <i className="fas fa-spinner"></i>
                    )}
                    <span> .xls</span>
                  </span>
                </button>
                <span style={{ display: "inline-block", width: "15px" }}></span> */}
                Export all surveys
                <button
                  className="btn btn-small animated btn-bordered btn-rounded"
                  onClick={() => exportAllSurveys()}
                >
                  <span className="btn-label">
                    {!isExportingSurveys ? (
                      <i className="fas fa-download"></i>
                    ) : (
                      <i className="fas fa-spinner"></i>
                    )}
                    <span> .xls</span>
                  </span>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      <div
        id={`toggle-usage-${currentTool}`}
        data-uk-offcanvas="overlay: true;"
      >
        <div className="own-offcanvas uk-offcanvas-bar">
          <div className="inner-offcanvas">
            <button
              className="btn btn-close-burger btn-no-text"
              data-uk-toggle={`target: #toggle-usage-${currentTool}`}
            >
              <span className="btn-icon">
                <i className="fas fa-times"></i>
              </span>
              <span className="btn-label"></span>
            </button>
            <div className="offcanvas-head">
              <span className="brand">Meridiam | </span>
              <span className="survey upp">Platform</span>
            </div>
            <div className="offcanvas-body">
              <p>Navigate to</p>

              <ul className="uk-nav uk-nav-default">
                {hasAccessRosace && (
                  <li
                    className={"synthesis" === currentTool ? "current" : ""}
                    onClick={() => goTo("sdg-impact-synthesis")}
                  >
                    <div>
                      <span>SDG impact synthesis</span>
                    </div>
                  </li>
                )}
                {hasAccessContribution && (
                  <li
                    className={"contribution" === currentTool ? "current" : ""}
                    onClick={() => goTo("sdg-contribution")}
                  >
                    <div>
                      <span>SDG Contribution</span>
                    </div>
                  </li>
                )}
                {hasAccessSurveyManager && (
                  <li
                    className={"survey" === currentTool ? "current" : ""}
                    onClick={() => goTo("survey-management")}
                  >
                    <div>
                      <span>SDG Survey management</span>
                    </div>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PlatformToolBar;

import React, { useContext, useState } from "react";
import { RootState, ThunkDispatch } from "models/store.models";
import Loader from "../../../shared/components/Loader/Loader";
import Header from "../../../shared/components/Header/Header";
import HubToolBar from "modules/hub/components/HubToolBar/HubToolBar";
import ProjectDetails from "../ProjectDetails/ProjectDetails";
import SaveBarElement from "modules/hub/components/Elements/SaveBarElement";

import "../../../../assets/scss/custom/tools/_base.scss";
import "./styles.scss";
import { ReportingContext } from "context/Reporting.context";
import { connect } from "react-redux";
import { history } from "modules/shared/services/history";
import { PermissionContext } from "context/Permission.context";

interface IProps {
  accessToken: string;
}

const mapStateToProps = ({ session }: RootState) => ({
  accessToken: session?.sessionData?.accessToken
    ? session?.sessionData?.accessToken
    : "",
  user: session?.sessionData?.userData,
});

const mapDispatchToProps = (dispatch: ThunkDispatch) => ({});

const ProjectIndicatorsAndDocument = ({
  accessToken
}: IProps) => {

  const { isMeridiamGranted } = useContext(PermissionContext);

  const { currentTab, rawProjectIndicators, projectIndicators } = useContext(ReportingContext);

  const [showLoading, setShowLoading] = useState<boolean>(false);

  return (
    <>
      <Loader
        className={!!showLoading ? "on" : "off"}
        color="multicolors"
        size="large"
      />

      <div className="header-wrapper" data-uk-sticky="media:768;">
        <Header
          titleContext="Platform"
          colorContext={"seaLight"}
          tool={"spv-reporting"}
        />
      </div>

      <HubToolBar currentTool={"spv-reporting"} />

      <div
        className="page page-dashboard page-dashboard-platform"
        data-theme="seaLight"
      >
        <div className="page_inner">

          <div className="back-btn">
            { (0 === currentTab && JSON.stringify(rawProjectIndicators) !== JSON.stringify(projectIndicators)) ?
              <button className="btn btn-select btn-primary" type="button" data-uk-toggle="#warnQuit">
                <span className="btn-icon">
                  <i className={`fas fa-arrow-left`}></i>
                </span>
                <span className="btn-label">back to SPV reporting projects{!!isMeridiamGranted ? '/funds' : ''}</span>
              </button>
            :
              <button className="btn btn-select btn-primary" type="button" onClick={() => history.push('/spv-reporting')}>
                <span className="btn-icon">
                  <i className={`fas fa-arrow-left`}></i>
                </span>
                <span className="btn-label">back to SPV reporting projects{!!isMeridiamGranted ? '/funds' : ''}</span>
              </button>
            }
          </div>

          <div className="page__head">
            <h1 className="page__head-title">
              project indicators &amp; documents
            </h1>
            <div className="page__head-buttons">

              {1 !== currentTab &&
                <SaveBarElement inProject={true} accessToken={accessToken} />
              }
              {/* {!isLeaderView && (
                  <a href="files.zip" download>
                    <button
                      className="btn btn-select btn-primary"
                      type="button"
                    >
                      <span className="btn-label">Download all documents</span>
                      <span className="btn-icon">
                        <i className={`fas fa-download`}></i>
                      </span>
                    </button>
                  </a>
                )} */}
            </div>
          </div>

          <ProjectDetails />
        </div>
      </div>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectIndicatorsAndDocument);

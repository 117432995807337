import React, { useState } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UIkit from "uikit";

import { sendNeedSupport } from "../../services/send-email";

import "./styles.scss";

type NeedSupportModalProps = {};
interface InitialValues {
  firstName: string;
  lastName: string;
  email: string;
  message: string;
}

const toastConfig = {
  position: "top-right" as any,
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
};

const NeedSupportModal: React.FC<NeedSupportModalProps> = () => {
  const [isSending, setIsSending] = useState<boolean>(false);

  const validate = (values: InitialValues) => {
    const errors: any = {};

    if (!values.firstName) {
      errors.firstName = "Please, fill in your first name.";
    }

    if ("" === values.lastName) {
      errors.lastName = "Please, fill in your last name.";
    }

    if (!values.email) {
      errors.email = "Please, fill in your email.";
    } else if (
      !values.email.match(
        /^[\w.!#$%&’*+/=?^_`{|}~-]+@[\.a-zA-Z0-9-]+\.[a-zA-Z0-9-]+$/
      )
    ) {
      errors.email = "Please, fill in a correct email.";
    }

    if (!values.message) {
      errors.message = "Please, fill in your message.";
    }

    return errors;
  };

  const sendEmail = async (data:any) => {
    let result = await sendNeedSupport(data);
    if(result.status === "ok"){
      toast.success("Email sent.", toastConfig);
    }
    UIkit.modal("#modalPageNeedSupport").hide();  
    setIsSending(false);
    return result;
  }

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      message: "",
    },
    validate,
    onSubmit: (values) => {
      if(isSending){
        return;
      }

      setIsSending(true);

      let data: any = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        message: values.message,
      };
      sendEmail(data);
    },
  });

  return (
    <div
      id="modalPageNeedSupport"
      className="own-modal modal-generic uk-modal"
      data-uk-modal
    >
      <div className="uk-modal-dialog">
        <div className="own-modal-content">
          <button
            className="btn btn-close label-external"
            type="button"
            data-uk-toggle="#modalPageNeedSupport"
          >
            <span className="btn-label">Close</span>
            <span className="btn-icon circle">
              <i className="fas fa-times"></i>
            </span>
          </button>
          <div className="uk-modal-header">
            <h2 className="upp">
              <span>Contact our support</span>
            </h2>
            {/* <h3> */}
            {/* <span>Contact us about limited partners</span> */}
            {/* </h3> */}
          </div>
          <div className="uk-modal-body">
            <div className="form">
              <form noValidate onSubmit={formik.handleSubmit}>
                <div className="row row-input">
                  <input
                    type="text"
                    placeholder="Firstname"
                    {...formik.getFieldProps("firstName")}
                  />
                </div>
                <div className="row row-input">
                  <input
                    type="text"
                    placeholder="Lastname"
                    {...formik.getFieldProps("lastName")}
                  />
                </div>
                <div className="row row-input">
                  <input
                    type="email"
                    placeholder="Email"
                    {...formik.getFieldProps("email")}
                  />
                </div>

                <div className="row row-textarea">
                  <textarea
                    className="message"
                    placeholder="Your text..."
                    {...formik.getFieldProps("message")}
                  ></textarea>
                </div>
                <div className="wrapp-cta">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={formik.isValid ? false : true}
                  >
                    { isSending === true 
                    ?
                      <span>
                        <i className="fas fa-spinner"></i> 
                      </span>
                    :
                      <span className="btn-label">Send</span>
                    }
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NeedSupportModal;

import { history } from "modules/shared/services/history";
import React, { useEffect, useState } from "react";
import { Redirect, Route, Router, Switch, useLocation } from "react-router-dom";
import { initSession } from "./auth/actions/session-actions";
import AuthRoutes from "./auth/AuthRoutes";
import DashboardRoutes from "./dashboard/DashboardRoutes/DashboardRoutes";
import HubRoutes from "./hub/HubRoutes";
import LimitedPartnersRoutes from "./limited-partners/LimitedPartnersRoutes";
import ManagementRoutes from "./management/ManagementRoutes";
import PlatformRoutes from "./platform/PlatformRoutes";
import { loadPlatformConfiguration } from "./shared/actions/platform-configuration/platform-configuration-actions";
import Layout from "./shared/components/Layout/Layout";
import Loader from "./shared/components/Loader/Loader";
import useQuery from "./shared/hooks/use-query";
import store from "./store";
import SurveyEditorRoutes from "./survey-editor/SurveyEditorRoutes";
import UserManagementRoutes from "./user-management/UserManagementRoutes";

const AppRoutes = () => {
  const query = useQuery();
  const { pathname } = useLocation();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    async function initialize() {
      setLoading(true);
      const accessToken = query.get("accessToken");

      const configuration = await store.dispatch<any>(
        loadPlatformConfiguration()
      );

      await store.dispatch<any>(initSession(accessToken, configuration));
      setLoading(false);
    }

    initialize();
  }, []);

  useEffect(() => {
    if(/spv-reporting/.test(pathname)){
      document.getElementsByTagName('title')[0].text = 'Reporting Platform by Meridiam';
    }else{
      document.getElementsByTagName('title')[0].text = 'SDG Platform by Meridiam';
    }
  }, [pathname])

  return (
    <>
      {loading ? (
        <Loader className="running" color="multicolors" size="large" />
      ) : (
        <Switch>
          <Route path="/auth">
            <AuthRoutes />
          </Route>

          <Route path="/limited-partners">
            <LimitedPartnersRoutes />
          </Route>

          <Route path="/dashboard">
            <DashboardRoutes />
          </Route>
          <Route path="/platform">
            <PlatformRoutes />
          </Route>
          <Route path="/management">
            <ManagementRoutes />
          </Route>
          <Route path="/user-management">
            <UserManagementRoutes />
          </Route>
          <Route path="/spv-reporting">
            <HubRoutes />
          </Route>

          <Route path="/survey-sdg-react">
            <SurveyEditorRoutes />
          </Route>

          <Redirect to={`/dashboard`} />
        </Switch>
      )}
    </>
  );
};

export default () => {
  return (
    <Router history={history}>
      <Layout>
        <Route
          render={({ location }) => {
            return (
              <Switch location={location}>
                <AppRoutes />
              </Switch>
            );
          }}
        ></Route>
      </Layout>
    </Router>
  );
};

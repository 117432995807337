import { PermissionContext } from "context/Permission.context";
import { ReportingContext } from "context/Reporting.context";
import { DateTime } from "luxon";
import WarnModal from "modules/shared/components/WarnModal/WarnModal";
import { history } from "modules/shared/services/history";
import { setBasicIndicators, setMacroAssumptions, updateProjectIndicators } from "modules/shared/services/spv-reporting.service";
import { deleteFileFromServer } from "modules/shared/services/upload.service";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

interface IProps {
  accessToken: string;
  inProject: boolean;
}

const SaveBarElement = ({
  accessToken,
  inProject
}: IProps) => {

  // const { isMeridiamGranted } = useContext(PermissionContext);

  const { rawData, data, projectIndicators, rawProjectIndicators, isValid, currentTab, requestedTab, setData, setRawData, setProjectIndicators, setRawProjectIndicators, setCurrentTab, setParentRef } = useContext(ReportingContext);

  const parentRef: any = useRef(null);

  const [currentData, setCurrentData] = useState<any>({});

  const [rawCurrentData, setRawCurrentData] = useState<any>({});

  const { projectCode } = useParams<{ projectCode: string }>();

  const saveData = async () => {
    if (!inProject) {
      switch(currentTab){
        case 2:
          const saved: any = await setMacroAssumptions(
            accessToken,
            data[currentTab]
          );

          if ("ok" === saved.status) {
            //If prevFile then remove prevFile from server
            for (let i in data[currentTab].assumptions) {
              const a: any = data[currentTab].assumptions[i];
              if (!!a.prevFile && !!a.prevFile.id) {
                await deleteFileFromServer(accessToken, a.prevFile.id);
              }
            }

            setRawData(saved.data);
            setData(saved.data);
            return true;
          }
        break;
        case 1:
          return true;
        break;
        case 0:
        default:
          const savedData: any =  await setBasicIndicators(
            accessToken,
            data[currentTab]
          );

          if ("ok" === savedData.status) {
            setRawData(savedData.data);
            setData(savedData.data);
            return true;
          }
      }
    }else{
      switch(currentTab){
        case 0:
          const savedData: any =  await updateProjectIndicators(accessToken, projectIndicators, projectCode);

          if ("ok" === savedData.status) {
            setRawProjectIndicators(savedData.data);
            setProjectIndicators(savedData.data);
            return true;
          }
        break;
      }
    }
    return false;
  };

  const cancelData = () => {
    if(!inProject){
      setData(rawData[currentTab]);
    }else{
      setProjectIndicators(rawProjectIndicators);
    }
  }

  useEffect(() => {
    if(!inProject){
      setRawCurrentData(rawData[currentTab]);
      setCurrentData(data[currentTab]);
    }
  }, [data]);

  useEffect(() => {
    if(!!inProject){
      setRawCurrentData(rawProjectIndicators);
      setCurrentData(projectIndicators);
    }
  }, [projectIndicators]);

  useEffect(() => {
    if(!!parentRef.current){
      setParentRef(parentRef);
    }
  }, [parentRef]);

  return (
    <>
      {/* {!!data[currentTab].user && !!data[currentTab].lastUpdate && (
        <div className="last-update-user">
          Last update : {`${data[currentTab].user.firstname} ${data[currentTab].user.lastname}`},{" "}
          {DateTime.fromISO(data[currentTab].lastUpdate?.replace(/Z/, "")).toFormat(
            "dd/LL/yyyy"
          )}
        </div>
      )} */}
      {!!currentData.user && !!currentData.lastUpdate && (
        <div className="last-update-user">
          Last update : {`${currentData.user.firstname} ${currentData.user.lastname}`},{" "}
          {DateTime.fromISO(currentData.lastUpdate?.replace(/Z/, "")).toFormat(
            "dd/LL/yyyy"
          )}
        </div>
      )}
      <button
        onClick={() => cancelData()}
        className="btn btn-select btn-primary"
        type="button"
        // disabled={JSON.stringify(rawData[currentTab]) === JSON.stringify(data[currentTab])}
        disabled={JSON.stringify(rawCurrentData) === JSON.stringify(currentData)}
      >
        <span className="btn-label">Cancel</span>
        <span className="btn-icon">
          <i className={`fas fa-times`}></i>
        </span>
      </button>
      <button
        ref={parentRef}
        onClick={() => saveData()}
        className="btn btn-select btn-primary"
        type="button"
        // disabled={!isValid[currentTab] || JSON.stringify(rawData[currentTab]) === JSON.stringify(data[currentTab])}
        disabled={!isValid[currentTab] || JSON.stringify(rawCurrentData) === JSON.stringify(currentData)}
      >
        <span className="btn-label">Save</span>
        <span className="btn-icon">
          <i className={`fas fa-save`}></i>
        </span>
      </button>

      <WarnModal
        id="warnQuit"
        content="Unsaved changes will be lost if you go back to SPV reporting.<br/>Do you want to save your changes?"
        actionYes={async () => {
          const done: boolean = await saveData();
          if(!!done){
            history.push('/spv-reporting');
          }
        }}
        actionNo={() => {
          history.push('/spv-reporting')
        }}
      />

      <WarnModal
        id={`warnQuitTab`}
        content="Unsaved changes will be lost if you go back to SPV reporting.<br/>Do you want to save your changes?"
        actionYes={async () => {
          const done: boolean = await saveData();
          setTimeout(() => {
            if(!!done){
              setCurrentTab(requestedTab);
            }
          }, 500);
        }}
        actionNo={() => {
          setTimeout(() => {
            setCurrentTab(requestedTab);
          }, 500);
        }}
      />
    </>
  );
};

export default SaveBarElement;

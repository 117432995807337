import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "@material-ui/core";
import { Country } from "models/country.model";
import { Currency } from "models/currency.model";
import { Project } from "models/project.model";
import { Materiality, Question } from "models/survey.model";
import { User } from "models/user.models";
import ManagementToolBar from "modules/management/screens/ManagementToolBar/ManagementToolBar";
import Header from "modules/shared/components/Header/Header";
import TeamModal from "modules/shared/components/TeamModal/TeamModal";
import { getSurveyMateriality } from "modules/shared/services/survey.service";
import ManagementTitlePage from "../ManagementTitlePage/ManagementTitlePage";
import ManagementFooterPage from "../ManagementFooterPage/ManagementFooterPage";
import WarnModal from "../../../shared/components/WarnModal/WarnModal";
import SPVManagerFormHead from "../SPVManagerFormHead/SPVManagerFormHead";
import SPVManagerFormMateriality from "../SPVManagerFormMateriality/SPVManagerFormMateriality";
import SPVManagerFormProjectInfos from "../SPVManagerFormProjectInfos/SPVManagerFormProjectInfos";
import SPVManagerFormReporting from "../SPVManagerFormReporting/SPVManagerFormReporting";
import { saveProjectFromSPVManager } from "modules/shared/services/project.service";
import "./styles.scss";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MeridiamFund } from "modules/limited-partners/models/meridiam-fund.model";

const { DateTime } = require("luxon");

const toastConfig = {
  position: "top-right" as any,
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
};

interface SPVManagerFormProps {
  currProject: Project;
  isEditMode: boolean;
  updateProjectList: any;
  availableYear: number[];
  currYear: number;
  currenciesList: Currency[];
  countriesList: Country[];
  closeSPV: any;
  accessToken: string;
  user: User | undefined;
  meridiamFundList: MeridiamFund[]
}

const SPVManagerForm = ({
  currProject,
  isEditMode,
  updateProjectList,
  availableYear,
  currYear,
  currenciesList,
  countriesList,
  closeSPV,
  accessToken,
  user,
  meridiamFundList
}: SPVManagerFormProps) => {
  const [tabValue, setTabValue] = React.useState(0);
  const [isMaterialityAvailable, setIsMaterialityAvailable] = useState<boolean>(
    isEditMode && currProject.hasSurvey === true ? true : false
  );
  const isReportingAvailable = isEditMode ? true : false;

  const [surveyMateriality, setSurveyMateriality] = useState<Question[]>([]);
  const [irreleventIndicatorId, setIrreleventIndicatorId] = useState<number[]>(
    []
  );
  const [formDirty, setFormDirty] = useState<string[]>([]);
  const [modifiedCurrProject, setModifiedCurrProject] = useState<Project>({
    ...currProject,
  });

  const [lastUpdateDate, setLastUpdateDate] = useState<any>("");
  const [lastUpdateName, setLastUpdateName] = useState<string | undefined>("");
  const [usersCount, setUsersCount] = useState<number>(0);
  const [formCanBeSave, setFormCanBeSave] = useState<boolean>(false);

  useEffect(() => {
    if (isEditMode && currProject.hasSurvey === true) {
      setIsMaterialityAvailable(true);
    } else {
      setIsMaterialityAvailable(false);
    }

    if (
      !!currProject &&
      !!currProject.lastUpdate &&
      !!currProject.userLastUpdate
    ) {
      setLastUpdateDate(currProject.lastUpdate);
      setLastUpdateName(currProject.userLastUpdate);
    }

    if (!!currProject.survey && !!currProject.survey.id) {
      getSurveyMateriality(accessToken, currProject?.survey.id).then(
        (result: any) => {
          let irrII: number[] = [];
          result.matMap.map((mm: Materiality) => {
            if (mm !== null && !!mm.indicatorId) {
              irrII.push(mm.indicatorId);
            }
          });
          setIrreleventIndicatorId(irrII);
          setSurveyMateriality(result.questions);
        }
      );
    }
  }, [currProject]);

  useEffect(() => {
    canBeSave();
  }, [modifiedCurrProject]);

  const canBeSave = () => {
    if (
      modifiedCurrProject.projectCode !== "" &&
      modifiedCurrProject.projectName !== "" &&
      modifiedCurrProject.projectShortName !== "" &&
      modifiedCurrProject.stage !== "" &&
      modifiedCurrProject.type !== "" &&
      modifiedCurrProject.countryCode !== "" &&
      modifiedCurrProject.investmentPhase !== "" 
      // &&
      // modifiedCurrProject.currentIRR !== null &&
      // modifiedCurrProject.latitude !== null &&
      // modifiedCurrProject.longitude !== null &&
      // modifiedCurrProject.trajectory !== null
    ) {
      setFormCanBeSave(true);
    } else {
      setFormCanBeSave(false);
    }
  };

  const handleSave = () => {
    if (!formCanBeSave) {
      return;
    }

    saveProjectFromSPVManager(
      accessToken,
      currYear,
      modifiedCurrProject,
      irreleventIndicatorId
    ).then((result) => {
      // console.log(result);
      if (result.status === "ok") {
        toast.success(
          "The project has been updated successfully.",
          toastConfig
        );
        setLastUpdateDate(new Date().toISOString());
        setLastUpdateName(user?.firstName + " " + user?.lastName);
        setFormDirty([]);
        setModifiedCurrProject(result.project);
        updateProjectList(result.project, isEditMode);
        setIsMaterialityAvailable(true);
      } else {
        toast.error("Error occured.", toastConfig);
      }
    });
  };

  return (
    <>
      <div className="header-wrapper" data-uk-sticky="media:768;">
        <Header
          titleContext="platform"
          colorContext={"seaLight"}
          classNameToolbarTop="spv-manager-form"
          tool={`spv-manager`}
        />
      </div>

      <ManagementToolBar currentTool={"spv-manager-form"} user={user} />

      <div className="page page-dashboard" data-theme="seaLight">
        <div className="page_inner">
          <ManagementTitlePage
            title={!!isEditMode ? "Project information" : "New asset"}
            subtitle={!!isEditMode ? null : "All fields with * required"}
            isSavable={true}
            updatedBy={!!isEditMode ? lastUpdateName : ""}
            updatedOn={
              !!isEditMode
                ? DateTime.fromISO(lastUpdateDate).toFormat("LL/dd/yyyy")
                : ""
            }
            handleSave={() => true}
            handleClose={() => closeSPV()}
            formDirty={formDirty}
            formCanBeSave={formCanBeSave}
          />

          {isEditMode && (
            <div className="back-btn">
              <button
                onClick={() => closeSPV()}
                className="btn btn-select btn-primary"
                type="button"
              >
                <span className="btn-icon">
                  <i className={`fas fa-arrow-left`}></i>
                </span>
                <span className="btn-label">Back to spv manager</span>
              </button>
            </div>
          )}

          <div className="grid grid-tool">
            <div className="grid_inner">
              <div className="main-content bg-white tool-spv-manager-form">
                <div className="content-head">
                  <SPVManagerFormHead
                    isEditMode={isEditMode}
                    modifiedCurrProject={modifiedCurrProject}
                    setModifiedCurrProject={setModifiedCurrProject}
                    formDirty={formDirty}
                    setFormDirty={setFormDirty}
                  />
                </div>

                <div className="year-selector">
                  <div className="selector">
                    <p className="selector-label">Year</p>
                    <div className="select">
                      <button
                        className={`btn btn-select btn-primary full btn-rounded ${
                          !!availableYear && availableYear.length <= 1
                            ? "only-one"
                            : ""
                        }`}
                        type="button"
                      >
                        <span className="btn_label">
                          {!!currYear ? currYear : "Year"}
                        </span>
                        {!!availableYear && 1 < availableYear.length && (
                          <span className="btn_icon"></span>
                        )}
                      </button>
                      {!!availableYear && 1 < availableYear.length && (
                        <div
                          className="drop drop-select selected-year"
                          data-uk-dropdown="pos: top-left; mode: click; delay-hide: 100"
                        >
                          {/* <ul className="group lvl0 uk-nav uk-dropdown-nav">
                            {availableYear.map((year: number, idx: number) => {
                              return (
                                <li
                                  className={
                                    year === currYear ? "item selected" : "item"
                                  }
                                  data-uk-toggle="target: .selected-year"
                                  key={`projectYearK-${idx}`}
                                  onClick={() => {
                                    setCurrYear(year);
                                    UIkit.dropdown(".selected-year").hide();
                                  }}
                                >
                                  <span className="clickable">{year}</span>
                                </li>
                              );
                            })}
                          </ul> */}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <Tabs value={tabValue} className="tabs-container">
                  <Tab
                    label="project information"
                    className="tabs-item"
                    onClick={() => setTabValue(0)}
                  />
                  <Tab
                    label="materiality"
                    className={`tabs-item ${
                      !isMaterialityAvailable ? "disabled" : ""
                    }`}
                    onClick={() => !!isMaterialityAvailable && setTabValue(1)}
                    data-attribute={`Please fill in the required fields, make sure the SPV is concerned by survey and save your modification to access Materiality tab. `}
                  />
                  <Tab
                    label="reporting"
                    className={`tabs-item ${
                      !isReportingAvailable ? "disabled" : ""
                    }`}
                    onClick={() => !!isReportingAvailable && setTabValue(2)}
                    data-attribute={`Please fill in the required fields and save your modification to access Reporting tab`}
                  />
                </Tabs>

                <div className="content-body">
                  <div
                    role="tabpanel"
                    className="project-infos"
                    hidden={0 === tabValue ? false : true}
                  >
                    <SPVManagerFormProjectInfos
                      isEditMode={isEditMode}
                      modifiedCurrProject={modifiedCurrProject}
                      setModifiedCurrProject={setModifiedCurrProject}
                      currenciesList={currenciesList}
                      countriesList={countriesList}
                      formDirty={formDirty}
                      setFormDirty={setFormDirty}
                      setIsMaterialityAvailable={setIsMaterialityAvailable}
                      usersCount={usersCount}
                      currYear={currYear}
                      meridiamFundList={meridiamFundList}
                    />
                  </div>
                  <div
                    role="tabpanel"
                    className="materiality"
                    hidden={1 === tabValue ? false : true}
                  >
                    <SPVManagerFormMateriality
                      surveyMateriality={surveyMateriality}
                      irreleventIndicatorId={irreleventIndicatorId}
                      setIrreleventIndicatorId={setIrreleventIndicatorId}
                      setFormDirty={setFormDirty}
                      formDirty={formDirty}
                    />
                  </div>
                  <div
                    role="tabpanel"
                    className="reporting"
                    hidden={2 === tabValue ? false : true}
                  >
                    <SPVManagerFormReporting
                      modifiedCurrProject={modifiedCurrProject}
                      setModifiedCurrProject={setModifiedCurrProject}
                      setFormDirty={setFormDirty}
                      formDirty={formDirty}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ManagementFooterPage
            isSavable={true}
            updatedBy={!!isEditMode ? lastUpdateName : ""}
            updatedOn={
              !!isEditMode
                ? DateTime.fromISO(lastUpdateDate).toFormat("LL/dd/yyyy")
                : ""
            }
            handleSave={() => true}
            handleClose={() => closeSPV()}
            formDirty={formDirty}
            formCanBeSave={formCanBeSave}
          />
        </div>
      </div>

      {!!user && user.id && isEditMode && currProject.id && (
        <TeamModal
          accessToken={accessToken}
          module={"survey-sdg"}
          projectCode={currProject.projectCode}
          moduleId={currProject.id}
          me={{
            id: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            login: user.login,
            platformRole: user.platformRole,
          }}
          setUsersCount={setUsersCount}
        />
      )}

      <WarnModal
        id="warnSave"
        content="These changes might affect materiality's project.<br/>Are you sure you want to save these changes?"
        actionYes={() => handleSave()}
      />

      <ToastContainer />
    </>
  );
};

export default SPVManagerForm;

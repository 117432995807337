import React, { useState, useEffect } from "react";
import WarnModal from "modules/shared/components/WarnModal/WarnModal";
import { Question } from "models/survey.model";

interface SPVManagerFormMaterialityProps {
  surveyMateriality: Question[];
  irreleventIndicatorId: number[];
  setIrreleventIndicatorId: any;
  setFormDirty: any;
  formDirty: string[];
}

const SPVManagerFormMateriality = ({
  surveyMateriality,
  irreleventIndicatorId,
  setIrreleventIndicatorId,
  setFormDirty,
  formDirty,
}: SPVManagerFormMaterialityProps) => {
  const [searchTerms, setSearchTerms] = useState<string>("");

  const [numberItemSelected, setNumberItemSelected] = useState<number>(0);
  const [sortThemeOrder, setSortThemeOrder] = useState<string>("asc");
  const [sortCategoryOrder, setSortCategoryOrder] = useState<string>("asc");
  const [sortIdOrder, setSortIdOrder] = useState<string>("asc");
  const [sortTargetOrder, setSortTargetOrder] = useState<string>("asc");
  const [orderSelectedFirst, setOrderSelectedFirst] = useState<boolean>(false);

  const [surveyMaterialityList, setSurveyMaterialityList] = useState<
    Question[]
  >(surveyMateriality);

  useEffect(() => {
    let val = surveyMateriality.length - irreleventIndicatorId.length;
    setNumberItemSelected(val);
    setSurveyMaterialityList(surveyMateriality);
  }, [surveyMateriality]);

  const searchForTerms = (event: any) => {
    setSearchTerms(event.target.value);
  };

  const handleChange = (indicatorId: number) => {
    let newArrDirty = [...formDirty];
    newArrDirty.push("materiality");
    setFormDirty(newArrDirty);

    let newArr: number[] = irreleventIndicatorId;
    if (irreleventIndicatorId.includes(indicatorId)) {
      newArr = newArr.filter((iII) => iII !== indicatorId);
    } else {
      newArr.push(indicatorId);
    }
    let val = surveyMateriality.length - newArr.length;
    setNumberItemSelected(val);
    setIrreleventIndicatorId(newArr);
  };

  const selectAll = () => {
    let newArrDirty = [...formDirty];
    newArrDirty.push("materiality");
    setFormDirty(newArrDirty);

    if (numberItemSelected === surveyMateriality.length) {
      // rempli le tableau = deselect all
      let newList: number[] = [];
      surveyMateriality.map((question: Question) => {
        newList.push(question.indicatorId);
      });
      setNumberItemSelected(0);
      setIrreleventIndicatorId(newList);
    } else {
      // vide le tableau = select all
      setIrreleventIndicatorId([]);
      setNumberItemSelected(surveyMateriality.length);
    }
  };

  const updateSortOrder = (type: string, order: string) => {
    let newSMList = surveyMaterialityList;
    switch (type) {
      case "theme":
        setSortThemeOrder(order);
        if (order === "asc") {
          newSMList = newSMList.sort((a: Question, b: Question) =>
            a.esgCategory.localeCompare(b.esgCategory)
          );
        } else {
          newSMList = newSMList.sort((a: Question, b: Question) =>
            b.esgCategory.localeCompare(a.esgCategory)
          );
        }
        break;
      case "category":
        setSortCategoryOrder(order);
        if (order === "asc") {
          newSMList = newSMList.sort((a: Question, b: Question) =>
            a.esgSubCategory.localeCompare(b.esgSubCategory)
          );
        } else {
          newSMList = newSMList.sort((a: Question, b: Question) =>
            b.esgSubCategory.localeCompare(a.esgSubCategory)
          );
        }
        break;
      case "id":
        setSortIdOrder(order);
        if (order === "asc") {
          newSMList = newSMList.sort((a: Question, b: Question) => {
            let aIN = a.indicatorNumbering.split(".");
            let bIN = b.indicatorNumbering.split(".");

            if (Number(aIN[0]) < Number(bIN[0])) {
              return -1;
            }
            if (Number(aIN[0]) > Number(bIN[0])) {
              return 1;
            }

            if (Number(aIN[0]) === Number(bIN[0])) {
              if (Number(aIN[1]) < Number(bIN[1])) {
                return -1;
              }
              if (Number(aIN[1]) > Number(bIN[1])) {
                return 1;
              }

              if (Number(aIN[1]) === Number(bIN[1])) {
                if (Number(aIN[2]) < Number(bIN[2])) {
                  return -1;
                }
                if (Number(aIN[2]) > Number(bIN[2])) {
                  return 1;
                }

                if (Number(aIN[2]) === Number(bIN[2])) {
                  if (Number(aIN[3]) < Number(bIN[3])) {
                    return -1;
                  }
                  if (Number(aIN[3]) > Number(bIN[3])) {
                    return 1;
                  }
                }
              }
            }
            return 1;
          });
        } else {
          newSMList = newSMList.sort((a: Question, b: Question) => {
            let aIN = a.indicatorNumbering.split(".");
            let bIN = b.indicatorNumbering.split(".");

            if (Number(aIN[0]) > Number(bIN[0])) {
              return -1;
            }
            if (Number(aIN[0]) < Number(bIN[0])) {
              return 1;
            }

            if (Number(aIN[0]) === Number(bIN[0])) {
              if (Number(aIN[1]) > Number(bIN[1])) {
                return -1;
              }
              if (Number(aIN[1]) < Number(bIN[1])) {
                return 1;
              }

              if (Number(aIN[1]) === Number(bIN[1])) {
                if (Number(aIN[2]) > Number(bIN[2])) {
                  return -1;
                }
                if (Number(aIN[2]) < Number(bIN[2])) {
                  return 1;
                }

                if (Number(aIN[2]) === Number(bIN[2])) {
                  if (Number(aIN[3]) > Number(bIN[3])) {
                    return -1;
                  }
                  if (Number(aIN[3]) < Number(bIN[3])) {
                    return 1;
                  }
                }
              }
            }
            return 1;
          });
        }
        break;
      case "target":
        setSortTargetOrder(order);
        if (order === "asc") {
          newSMList = newSMList.sort((a: Question, b: Question) => {
            if (a.target !== "N/A" && b.target === "N/A") {
              return 1;
            } else {
              return -1;
            }
          });
          newSMList = newSMList.sort((a: Question, b: Question) =>
            Number(a.target) > Number(b.target) ? 1 : -1
          );
        } else {
          newSMList = newSMList.sort((a: Question, b: Question) => {
            if (a.target !== "N/A" && b.target === "N/A") {
              return 1;
            } else {
              return -1;
            }
          });
          newSMList = newSMList.sort((a: Question, b: Question) =>
            Number(b.target) > Number(a.target) ? 1 : -1
          );
        }
        break;
    }
    setSurveyMaterialityList(newSMList);
  };

  return (
    <>
      <div className="search-container">
        <div className="tool tool-search">
          <div className="row row-input row-search">
            <div className="input">
              <input
                type="search"
                className={`no-check ${
                  !!searchTerms && "" !== searchTerms ? "filled" : ""
                }`}
                placeholder="Search an indicator by category, question, number…"
                value={searchTerms}
                onChange={searchForTerms}
              />
              {"" === searchTerms ? (
                <span className="input-icon">
                  <i className="fas fa-search"></i>
                </span>
              ) : (
                <span className="input-icon" onClick={() => setSearchTerms("")}>
                  <i className="fas fa-times"></i>
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="selected-indicators">
          Selected indicators :{" "}
          <span>
            {numberItemSelected}/{surveyMateriality.length}
          </span>
        </div>
      </div>

      <i className="label">Sort by...</i>
      <ul className="list">
        <li className="row filters">
          <div className="theme">
            <button
              className="btn btn-filter"
              type="button"
              onClick={() =>
                updateSortOrder(
                  "theme",
                  sortThemeOrder === "asc" ? "desc" : "asc"
                )
              }
            >
              <span className="btn_label">Theme</span>
              <span className="btn_icon">
                {sortThemeOrder === "asc" ? (
                  <i className="fas fa-sort-alpha-down"></i>
                ) : (
                  <i className="fas fa-sort-alpha-down-alt"></i>
                )}
              </span>
            </button>
          </div>
          <div className="subtheme">
            <button
              className="btn btn-filter"
              type="button"
              onClick={() =>
                updateSortOrder(
                  "category",
                  sortCategoryOrder === "asc" ? "desc" : "asc"
                )
              }
            >
              <span className="btn_label">Subtheme</span>
              <span className="btn_icon">
                {sortCategoryOrder === "asc" ? (
                  <i className="fas fa-sort-alpha-down"></i>
                ) : (
                  <i className="fas fa-sort-alpha-down-alt"></i>
                )}
              </span>
            </button>
          </div>
          <div className="id">
            <button
              className="btn btn-filter"
              type="button"
              onClick={() =>
                updateSortOrder("id", sortIdOrder === "asc" ? "desc" : "asc")
              }
            >
              <span className="btn_label">Id</span>
              <span className="btn_icon">
                {sortIdOrder === "asc" ? (
                  <i className="fas fa-sort-numeric-down"></i>
                ) : (
                  <i className="fas fa-sort-numeric-down-alt"></i>
                )}
              </span>
            </button>
          </div>
          <div className="target">
            <button
              className="btn btn-filter"
              type="button"
              onClick={() =>
                updateSortOrder(
                  "target",
                  sortTargetOrder === "asc" ? "desc" : "asc"
                )
              }
            >
              <span className="btn_label">Target</span>
              <span className="btn_icon">
                {sortTargetOrder === "asc" ? (
                  <i className="fas fa-sort-numeric-down"></i>
                ) : (
                  <i className="fas fa-sort-numeric-down-alt"></i>
                )}
              </span>
            </button>
          </div>
          <div className="indicators">
            <label htmlFor="indicators">Group selected indicators</label>
            <input
              type="checkbox"
              id="indicators"
              className="indicators-input"
              onClick={() =>
                setOrderSelectedFirst(orderSelectedFirst ? false : true)
              }
            />
            <div className="indicators-switcher"></div>
          </div>
          <div className="select" data-uk-toggle="#warnSelect">
            <label htmlFor="select-filter">Select/deselect all</label>
            <input
              type="checkbox"
              id="select-filter"
              className="select-input"
              checked={irreleventIndicatorId.length === 0}
              onChange={() => {}}
            />
            <button className="box">
              <i className="fas fa-check"></i>
            </button>
          </div>
        </li>

        {!!surveyMaterialityList &&
          surveyMaterialityList
            .filter((sm: Question) => {
              if (
                searchTerms === "" ||
                (searchTerms !== "" &&
                  (sm.question
                    .toLocaleLowerCase()
                    .match(searchTerms.toLocaleLowerCase()) ||
                    sm.esgCategory
                      .toLocaleLowerCase()
                      .match(searchTerms.toLocaleLowerCase()) ||
                    sm.esgSubCategory
                      .toLocaleLowerCase()
                      .match(searchTerms.toLocaleLowerCase()) ||
                    sm.indicatorNumbering
                      .toLocaleLowerCase()
                      .match(searchTerms.toLocaleLowerCase()) ||
                    sm.question
                      .toLocaleLowerCase()
                      .match(searchTerms.toLocaleLowerCase())))
              ) {
                return sm;
              }
            })
            .sort((a: Question, b: Question) => {
              if (orderSelectedFirst === true) {
                return irreleventIndicatorId.includes(a.indicatorId) ? 1 : -1;
              }
              return 1;
            })
            .map((sm: Question, idx: number) => {
              return (
                <li
                  className={
                    !irreleventIndicatorId.includes(sm.indicatorId)
                      ? "row checked"
                      : "row"
                  }
                  key={`itemSMK-${idx}`}
                >
                  <div className="theme">{sm.esgCategory}</div>
                  <div className="subtheme">{sm.esgSubCategory}</div>
                  <div className="id">{sm.indicatorNumbering}</div>
                  <div className="target">{sm.target}</div>
                  <div className="indicators">{sm.question}</div>
                  <div className="select">
                    <label htmlFor={`select-${idx}`} className="select-label">
                      {/* {sm.unit} */}
                    </label>
                    <input
                      type="checkbox"
                      id={`select-${idx}`}
                      className="select-input"
                      checked={!irreleventIndicatorId.includes(sm.indicatorId)}
                      onChange={() => handleChange(sm.indicatorId)}
                    />
                    <span className="box">
                      <i className="fas fa-check"></i>
                    </span>
                  </div>
                </li>
              );
            })}
      </ul>

      <WarnModal
        id="warnSelect"
        content={`Are your sure to ${
          irreleventIndicatorId.length === 0 ? "deselect" : "select"
        } all indicators ?`}
        actionYes={() => selectAll()}
      />
    </>
  );
};

export default SPVManagerFormMateriality;

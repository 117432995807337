import React from "react";

import "./styles.scss";

interface ProjectIndicatorsRowProps {
  section: any;
  indicators: any;
  handleChange: any;
  isValid: boolean;
}

const ProjectIndicatorsRow = ({
  section,
  indicators,
  handleChange,
  isValid,
}: ProjectIndicatorsRowProps) => {

  return (
    <section
      key={`section-${section.id}`}
      className={`
                indicator-section
                ${`section-${section.id}`}
                ${
                  ("construction" === section.id &&
                    "inConstruction" === indicators.stage) ||
                  ("expected-fc-date" === section.id &&
                    "underDevelopment" === indicators.stage)
                    ? " visible"
                    : " invisible"
                }
        ${isValid ? "is-valid" : ""}
            `}
    >
      <div className={`indicator-section_head`}>
        <h4 className={`indicator-section_head__title`}>{section.title}</h4>
        {!!section.displayAvailable && (
          <div className={`indicator-section_head__toggle`}>
            <p className="label">Display this section?</p>
            <span className="value">Yes</span>
            <div className="toggle">
              <input
                type="checkbox"
                id="display"
                className="toggle-input"
                checked={!!indicators[`${section.slug}Display`]}
                onChange={(e) =>
                  handleChange(
                    `${section.slug}Display`,
                    e.currentTarget.checked
                  )
                }
              />
              <div className="toggle-switcher"></div>
            </div>
            <span className="value">No</span>
          </div>
        )}
      </div>

      <div className={`indicator-section_content`}>
        <div className="part">
          <p className="part_label">{section.labelFirstPart}</p>
          <ul className="indicators_list">
            {/* {Object.values(section.indicators).map((ind: any, idx) => ( */}
            {section.indicators.map((ind: any, idx: number) => (
              <li key={`indicator-${idx}`} className={"indicators_item"}>
                {(!!ind.isGroup && !!ind.children) ?
                  <>
                    <b>— {ind.name} </b>
                    <ol style={{marginBottom: '30px', paddingLeft: '20px'}}>
                      {ind.children.map((c: any, ci: number) => (
                        <li key={`indicator-${idx}-${ci}`}>
                          <b>— {c.name} </b>
                        </li>
                      ))}
                    </ol>
                  </>
                :
                    <>
                      <b>— {ind.name} </b>
                    </>
                }
              </li>
            ))}
          </ul>
        </div>

        <div className="part">
          <p className="part_label">{section.labelSecondPart}</p>
          <textarea
            id="about"
            className={`about ${
              !!indicators[`${section.slug}Text`] ? "filled" : ""
            }`}
            name="about"
            placeholder="Your text..."
            onChange={(e) =>
              handleChange(`${section.slug}Text`, e.currentTarget.value)
            }
            value={indicators[`${section.slug}Text`]}
          ></textarea>
        </div>
      </div>
    </section>
  );
};

export default ProjectIndicatorsRow;

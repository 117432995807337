import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React from "react";
import { AllocationResult } from "../../models/allocation-result.model";
import Loader from "../../../shared/components/Loader/Loader";
import typeEnumImport from "../../../platform/datas/typeEnum.json";

import {
  // capitalize,
  cleanAndCapitalize,
  // sanitizeString,
  getProjectTypeIcon
} from "../../../shared/utils/string-utils";

import "./styles.scss";
import { ProjectTypeEnumInterface } from "models/project-type-enum";

const buildChartFromAllocationByAsset = (
  allocationByAsset: AllocationResult[],
  currentCurrency: string
) => {

  const typeEnum: { [key: string]: ProjectTypeEnumInterface } = typeEnumImport;
  
  let series: any = {
    data: [],
  };

  allocationByAsset?.map((item: any) => {
    series.data.push([item.name, item.total]);
  });

  const categories = allocationByAsset?.map((item) => {
    return item.name;
  });

  let windowWidth: number;
  windowWidth = window.innerWidth;
  window.addEventListener("resize", function (event) {
    windowWidth = window.innerWidth;
    // console.log("width window: " + windowWidth);
  });

  // options for the chart
  return {
    chart: {
      type: (windowWidth < 960 || 9 < categories.length) ? "bar" : "column",
      height: (windowWidth < 960 || 9 < categories.length) ? 50 * categories.length + 100 : 320,
      className:
        (windowWidth < 960 || 9 < categories.length)
          ? "chart__own chart__bar bar__horizontal"
          : "chart__own chart__bar bar__vertical",
      spacing: (windowWidth < 960 || 9 < categories.length) ? [20, 0, 0, 120] : [20, 0, 1, 0],
      responsive: true,
      styledMode: true,
      useHTML: true,
      style: {
        fontFamily: "Montserrat",
      },
    },
    xAxis: {
      categories: categories,
      labels: {
        useHTML: true,
        outside: (windowWidth < 960 || 9 < categories.length) ? false : true,
        x: (windowWidth < 960 || 9 < categories.length) ? 80 : 0,
        y: (windowWidth < 960 || 9 < categories.length) ? 0 : 20,
        verticalAlign: "middle",
        formatter: function (this: any, err: any) {
          if ("undefined" !== typeof this) {
            if (windowWidth < 960 || 9 < categories.length) {
              return (
                '<div class="axis__labels axis__labels__y horizontal">' +
                '<div class="axis__label axis__label__y horizontal">' +
                '<span class="label_name">' +

                (!!typeEnum[this.value] ? typeEnum[this.value].niceName : cleanAndCapitalize(this.value))

                // ("sme" !== this.value
                //   ? cleanAndCapitalize(this.value)
                //   : "Env. &<br>Transition") 
                  +
                "</span>" +
                '<span class="label_icon">' + getProjectTypeIcon(this.value) + '</span>' +
                "</div>" +
                "</div>"
              );
            } else {
              return (
                '<div class="axis__labels axis__labels__x with-icon">' +
                '<div class="axis__label axis__label__x">' +
                '<span class="label_icon">' + getProjectTypeIcon(this.value) + '</span>' +
                '<span class="label_name">' +

                (!!typeEnum[this.value] ? typeEnum[this.value].niceName : cleanAndCapitalize(this.value))
                
                // ("sme" !== this.value
                //   ? cleanAndCapitalize(this.value)
                //   : "Environment & Transition") 
                +
                "</span>" +
                "</div>" +
                "</div>"
              );
            }
          } else {
            return <></>;
          }
        },
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: null,
      },
      labels: {
        align: "left",
        useHTML: true,
        outside: true,
        rotation: 0,
        x: (windowWidth < 960 || 9 < categories.length) ? 0 : 38,
        y: (windowWidth < 960 || 9 < categories.length) ? 15 : -8,
        reserveSpace: true,
        verticalAlign: "bottom",
        formatter: function (this: any, err: any) {
          if (windowWidth < 960 || 9 < categories.length) {
            if (this.value === this.axis.paddedTicks[this.axis.paddedTicks.length - 2]) {
              return (
                '<div class="chart__label "><span>' +
                this.value +
                " " +
                (currentCurrency === "EUR" ? "M€" : "M$") +
                "</span></div>"
              );
            } else if (!this.isLast) {
              return (
                '<div class="chart__label"><span>' + this.value + "</span></div>"
              );
            }
          } else {
            if (this.isLast) {
              return (
                '<div class="chart__label is-last"><span>' +
                this.value +
                " " +
                (currentCurrency === "EUR" ? "M€" : "M$") +
                "</span></div>"
              );
            } else {
              return (
                '<div class="chart__label"><span>' + this.value + "</span></div>"
              );
            }
          }
        }
      },
    },
    title: {
      enabled: false,
      text: "",
    },
    tooltip: {
      enabled: true,
      useHTML: true,
      outside: true,
      backgroundColor: "transparent",
      borderRadius: 5,
      borderWidth: 0,
      shadow: false,
      followPointer: false, //true,
      hideDelay: 100,
      formatter: function (this: any, err: any) {
        // console.log("this.key", this);
        return (
          '<div class="uk-tooltip tooltip-chart tooltip-lp tooltip-chart-allocationByAssets">' +
          '<div class="line line1"><span class="tooltip_icon">' + getProjectTypeIcon(this.key) + '</span></div>' +
          '<div class="line line1"><span class="tooltip_label">' + 

          (!!typeEnum[this.key] ? typeEnum[this.key].niceName : cleanAndCapitalize(this.key))

          + '</span></div>' +
          '<div class="line line1"><span class="tooltip_value">' +
          this.y.toFixed(2) +
          " " +
          (currentCurrency === "EUR" ? "M€" : "M$") +
          "</span></div>" +
          "</div>"
        );
      },
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    dataLabels: {
      enabled: true,
      rotation: -90,
      color: "#FFFF00",
      align: "right",
      format: "{point.y:.1f}",
      y: 10,
      style: {
        fontSize: "13px",
        fontFamily: "Montserrat",
      },
    },
    series: series,
  };
};

class AllocationByAssetChart extends React.Component<{
  allocationByAsset: AllocationResult[];
  currentCurrency: string;
}> {
  internalChart: any;

  afterChartCreated = (chart: any) => {
    this.internalChart = chart;
    this.forceUpdate();
  };

  componentDidUpdate() {
    this.internalChart.reflow();
  }

  render() {
    const { allocationByAsset, currentCurrency } = this.props;

    return (
      <>
        {allocationByAsset && (
          <HighchartsReact
            highcharts={Highcharts}
            options={buildChartFromAllocationByAsset(
              allocationByAsset,
              currentCurrency
            )}
            callback={this.afterChartCreated}
          />
        )}
      </>
    );
  }
}

type PropsSection = {
  allocationByAsset: AllocationResult[] | undefined,
  year: number,
  isAllocationLoading: boolean,
  currency: string;
  investorType:string;
};

const AllocationByAssetSection: React.FC<PropsSection> = ({
  allocationByAsset, year, isAllocationLoading, currency, investorType
}) => {

  return (
    <>
      <div
        id="cmpAllocationByAssets"
        className="tool tool_lp tool_allocation-by-asset"
      >
        <div className="tool_lp_inner">
          <div className="tool__title">
            <h2>Allocation by assets</h2>
            <h4 className="clr-primary">
              <small>As of Q4</small> {year}
              {/* {investorType === "gigf" ? (year+1) : year} */}
            </h4>
          </div>
          <div className="tool__content">
            <div className="cmp cmp-chart chart-bar">
              <div className="inner-chart">
                {!isAllocationLoading && allocationByAsset ? (

                  (allocationByAsset.length > 0
                    ?
                    <AllocationByAssetChart
                      allocationByAsset={allocationByAsset}
                      currentCurrency={currency}
                    />
                    : <span className="no-data-available">No data available.</span>
                  )
                ) : (
                  <div>
                    <Loader className="on" color="white" size="small" />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="tool__actions" data-print="no">
            <div className="actions_inner">
              <div className="export">
                {/* <DownloadPdfButton
                  visible={false}
                  optionsPrint={{
                    format: "A4",
                    orientation: "portrait",
                    assets: [
                      {
                        folder: "assets/css",
                        files: [
                          "development" !== process.env.NODE_ENV
                            ? "print.css"
                            : "print-local.css",
                        ],
                      },
                    ],
                  }}
                  pdfTitle=""
                  filename="Meridiam-Allocation-by-assets"
                  selector="#cmpAllocationByAssets"
                  selectorHead="#cmpPdfHead"
                  selectorFoot="#cmpPdfLegends"
                  setIsPrint={setIsPrint}
                  mode="link"
                  label="Download"
                  labelInside=".pdf"
                  icon="fas fa-download"
                  itemsList={[]}
                /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllocationByAssetSection;

import React from "react";
import "./styles.scss";

interface ModalProps {
  contentModal: ModalFooterContent[];
}

export interface ModalFooterContent {
  title: string;
  list: ListProps[];
}

export interface ListProps {
  content: string;
}
const ModalFooter = ({ contentModal }: ModalProps) => {
  return (
    <div
      id="modalPageMethodology"
      className="own-modal modal-generic uk-modal"
      data-uk-modal
    >
      <div className="uk-modal-dialog">
        <div className="own-modal-content">
          <button
            className="btn btn-close label-external"
            type="button"
            data-uk-toggle="#modalPageMethodology"
          >
            <span className="btn-label">Close</span>
            <span className="btn-icon circle">
              <i className="fas fa-times"></i>
            </span>
          </button>
          <div className="uk-modal-header">
            <h2 className="upp">
              <span>Methodology</span>
            </h2>
          </div>
          <div className="uk-modal-body" data-uk-overflow-auto>
            <div className="editor-contenair">
              {contentModal.map((item, i) => {
                return (
                  <div className="list_inner" key={i}>
                    <h2>{item.title}</h2>
                    <ul className="list__content">
                      {item.list.map((subitem, i) => {
                        return <li key={i}>{subitem.content}</li>;
                      })}
                    </ul>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalFooter;

import { ReportingContext } from 'context/Reporting.context';
import React, { useContext, useEffect, useState } from 'react';

const ButtonSave = () => {

   const { parentRef, projectIndicators }: any = useContext(ReportingContext);

    const [isDisabled, setIsDisabled] = useState<boolean>(true);

    const checkIsDisabled = () => {
        setTimeout(() => {
            setIsDisabled(parentRef.current.disabled);
        },0)
    }

    useEffect(() => {
        checkIsDisabled();
    }, [projectIndicators])

    return (
        <div className='uk-text-right'>
            {!!parentRef && !!parentRef.current &&
                 <button 
                    className="btn btn-select btn-primary"
                    type="button"
                    disabled={!!isDisabled} onClick={() => parentRef.current.click()}
                >
                    <span className="btn-label">Save</span>
                    <span className="btn-icon">
                    <i className={`fas fa-save`}></i>
                    </span>
                </button>
            }
        </div>
    )
}

export default ButtonSave;
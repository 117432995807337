import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { history } from "modules/shared/services/history";

import Header from "../../../shared/components/Header/Header";
import Loader from "../../../shared/components/Loader/Loader";
import Toolbar from "../../../shared/components/Ui/Toolbars/Toolbar";

import FilterSection from "../../components/FilterSection/FilterSection";
import ProjectLocationSection from "modules/limited-partners/components/ProjectLocationSection/ProjectLocationSection";
import InvestorSection from "../../components/InvestorSection/InvestorSection";
import KeyMetricsSection from "../../components/KeyMetricsSection/KeyMetricsSection";
import WrapKeyIndicAndCarbonSection from "modules/limited-partners/components/WrapKeyIndicAndCarbonSection/WrapKeyIndicAndCarbonSection";
// import EUTaxonomySection from "modules/limited-partners/components/EUTaxonomySection/EUTaxonomySection";

import { RootState, ThunkDispatch } from "models/store.models";
import {
  AvailableFilters,
  CurrFilter,
} from "../../models/available-filters.model";
import { InvestorResult } from "../../models/investor-result.model";
import { MeridiamFundResult } from "../../models/meridiam-fund-result.model";

import { countries } from "country-data";

import {
  getAllInvestors,
  getFilterInvestorData,
  getInvestorData,
  getInvestorDataAndMeridiamFund,
} from "../../services/limited-partners.service";

import "../../../../assets/scss/custom/tools/_base.scss";
import "./styles.scss";
import { Investor } from "modules/limited-partners/models/investor.model";
import { cloneDeep } from "lodash";
import WrapAllocationGeographicalSection from "modules/limited-partners/components/WrapAllocationGeographicalSection/WrapAllocationGeographicalSection";
import WrapSdgImpactReturnSection from "modules/limited-partners/components/WrapSdgImpactReturnSection/WrapSdgImpactReturnSection";

const mapStateToProps = ({ session }: RootState) => ({
  accessToken:
    session.sessionData && session.sessionData.accessToken
      ? session.sessionData.accessToken
      : ""
});

interface InvestorInterface {
  investorId: string;
  year: number;
  availableYear: number[];
  currency: string;
}

const mapDispatchToProps = (dispatch: ThunkDispatch) => ({});

type LimitedPartnersDashboardProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const LimitedPartnersDashboard: React.FC<LimitedPartnersDashboardProps> = ({
  accessToken
}) => {
  if (!accessToken) {
    history.push("/auth/login");
  }

  // FOR TEST ONLY
  // const defaultInvestor = {
  //   investorId: "64662372864F4BC28ABDB16EA1C902C9",
  //   year: 2021,
  //   availableYear: [2021],
  //   currency: "EUR"
  // }

  const [investorList, setInvestorList] = useState<Investor[]>([]);
  const [investor, setInvestor] = useState<InvestorInterface>();

  const [loadingData, setLoadingData] = useState<boolean>(true);
  const [menuUserItemLength, setMenuUserItemLength] = useState<number>(0);
  const defaultFilter = { meridiamFund: ["all"], location: ["all"] };

  const [currFilter, setCurrFilter] = useState<CurrFilter>(defaultFilter);

  const [originalFilter, setOriginalFilter] = useState<AvailableFilters>();
  const [availableFilters, setAvailableFilters] = useState<AvailableFilters>();

  const [investorResult, setInvestorResult] = useState<InvestorResult | null>();
  const [meridiamFundResult, setMeridiamFundResult] = useState<
    MeridiamFundResult[]
  >();

  const initListLPs = () => {
    setLoadingData(true);

    getAllInvestors(accessToken).then((result) => {
      setInvestorList(result.allInvestor);
      if (result.allInvestor.length > 0) {
        initLP(result.allInvestor[0].investorId);
      }
    });
  }

  const initLP = (investorId:string) => {
    setLoadingData(true);

    let newInvestor:InvestorInterface = {
      investorId: "",
      year: 2021,
      availableYear: [],
      currency: "EUR"
    };

    // get investor data + meridiam fund
    getInvestorData(
      accessToken,
      investorId
    ).then(
      (result) => {
        newInvestor.investorId = investorId;
        newInvestor.availableYear = result;
        newInvestor.year = result[result.length-1];

        setLoadingData(false);
        setInvestor(newInvestor);
      });
  }

  const switchInvestor = (newInvestorId: string) => {
    if (newInvestorId !== investor?.investorId) {
      setInvestorResult(null);
      // get investor data + meridiam fund
      initLP(newInvestorId);
    }
  };

  // load investor list + set up the first one + get available year.
  useEffect(() => {
    if (accessToken === "") {
      return;
    }
    initListLPs();
  }, []);

  // fetch data : InvestorDataAndMeridiamFund + FilterInvestorData / refreshed if : investorId-currency updated
  useEffect(() => {
    if (accessToken === "" || investor === undefined) {
      return;
    }
    if(investor.year !== undefined){
      setLoadingData(true);
      // get investor data + meridiam fund
      getInvestorDataAndMeridiamFund(
        accessToken,
        investor.investorId,
        investor.year,
        investor.currency
      ).then(
        (result) => {
          setInvestorResult(result.investorResult);
          setMeridiamFundResult(result.meridiamFundResult);
          setCurrFilter(defaultFilter);
          setLoadingData(false);
        },
        (err) => {
          // console.log("---- error investor data ----");
          // console.log(err.response);
        }
      );
  
      // get filter for this investor + get currency exchange rate
      getFilterInvestorData(accessToken, investor.investorId, investor.year).then(
        (result) => {
          setAvailableFilters(result.filterResult);
          setOriginalFilter(result.filterResult);
        },
        (error) => {
          // console.log("---- error : get filter for this investor ----");
          // console.log(error.response);
        }
      );
    }
  }, [investor]);

  // update filter list
  useEffect(() => {
    if (typeof originalFilter !== "undefined") {
      let newFilter: AvailableFilters = {
        currencyChange: originalFilter.currencyChange,
        filter: {
          countries: [],
          funds: [],
        },
      };

      if (!currFilter.meridiamFund.includes("all")) {
        // remove countries not concerned anymore
        originalFilter.filter.countries.forEach((c) => {
          let atLeastAFundSelected = c.fundsId.filter((f) =>
            currFilter.meridiamFund.includes(f)
          );
          if (atLeastAFundSelected.length > 0) {
            newFilter.filter.countries.push(c);
          }
        });
      } else {
        newFilter.filter.countries = !!originalFilter.filter && !!originalFilter.filter.countries ? originalFilter.filter.countries : [];
      }

      if (!currFilter.location.includes("all")) {
        // remove fund not concerned anymore
        originalFilter.filter.funds.forEach((f) => {
          let atLeastACountrySelected = f.countriesCode.filter((c) =>
            currFilter.location.includes(c)
          );
          if (atLeastACountrySelected.length > 0) {
            newFilter.filter.funds.push(f);
          }
        });
      } else {
        newFilter.filter.funds = !!originalFilter.filter && !!originalFilter.filter.funds ? originalFilter.filter.funds : [];
      }

      setAvailableFilters(newFilter);
    }
  }, [currFilter]);

  const resetFilter = () => {
    setCurrFilter(defaultFilter);
  };

  const switchCurrency = (newCurrency: string) => {
    let newInvestor = cloneDeep(investor);
    if(newInvestor !== undefined && newCurrency !== newInvestor.currency){
      newInvestor.currency = newCurrency;
      setInvestor(newInvestor);
    }
  }

  const switchYear = (newYear: number) => {
    let newInvestor = cloneDeep(investor);
    if(newInvestor !== undefined && newYear !== newInvestor.year && investor?.availableYear.includes(newYear)){
      newInvestor.year = newYear;
      setInvestor(newInvestor);
    }
  }

  return (
    <>
      <Loader
        className={loadingData ? "on" : "off"}
        color="multicolors"
        size="large"
      />

      {availableFilters !== null && availableFilters !== undefined && investor !== undefined && (
        <>
          <div className="header-wrapper" data-uk-sticky="media:768;">
            <Header
              titleContext="LP Dashboard"
              colorContext={"blue-dark"}
              classNameToolbarTop="lp"
              menuUserLength={menuUserItemLength}
              setMenuUserItemLength={(val: number) =>
                setMenuUserItemLength(val)
              }
              tool="lp-dashboard"
            />
            <Toolbar
              className={`toolbar-lp appear-right-full`}
              filter={availableFilters}
              currency={investor.currency}
              switchCurrency={switchCurrency}
              menuList={investorList}
              menuCurrentItem={investor.investorId}
              setInvestorId={switchInvestor}
              currentYear={investor.year}
              switchYear={switchYear}
              availableYear={investor.availableYear}
              investorType={!!investorResult ? investorResult.type : "infra"}
            />
          </div>

          <div className="tools">
            <div className="tools_inner">
              {investorResult !== null &&
                meridiamFundResult !== null &&
                investorResult !== undefined &&
                meridiamFundResult !== undefined ? (
                <>
                  {/* /* BLOC 1 -- investor */}
                  <InvestorSection
                    investorResult={investorResult}
                    curr={investor.currency}
                    investorType={investorResult.type}
                  />

                  {/* /* BLOC 2 -- meridiam funds */}
                  <KeyMetricsSection
                    meridiamFundResult={meridiamFundResult}
                    currency={investor.currency}
                    year={investor.year}
                    totalInvestment={investorResult.totalInvestment}
                    totalDrawn={investorResult.totalDrawn}
                    tvpiInvestor={investorResult.tvpi}
                    trajectoryInvestor={investorResult.trajectory}
                    projectedNetIRRInvestor={investorResult.projectedIRR}
                    investorType={investorResult.type}
                  />

                  {
                    investorResult.type === "infra" && 
                      <FilterSection
                        filter={availableFilters}
                        currFilter={currFilter}
                        setCurrFilter={setCurrFilter}
                        menuUserLength={menuUserItemLength}
                        availableFilters={availableFilters}
                        resetFilter={resetFilter}
                      />
                  }
                </>
              ) : (
                <>
                  No data available for
                  {investorList.forEach((investor) => {
                    if (investor.investorId === investor.investorId) {
                      return investor.investorName;
                    }
                  })}
                </>
              )}

              {/* BLOC 3 + 4 -- Allocation by asset + Geographical overview */}
              {
                !!investorResult && 
                  <WrapAllocationGeographicalSection 
                    accessToken={accessToken}
                    investorId={investor.investorId}
                    year={investor.year}
                    currFilter={currFilter}
                    currency={investor.currency} 
                    investorType={investorResult.type}
                  />
              }

              {/* BLOC 5 -- Project Location */}
              {!!investorResult &&
                <ProjectLocationSection
                  accessToken={accessToken}
                  investorId={investor.investorId}
                  year={investor.year}
                  currFilter={currFilter}
                  investorType={investorResult.type}
                />  
              }

              {/* BLOC 6 + 7 -- SDG impact synthesis + impact return */}
              {!!investorResult &&
                <WrapSdgImpactReturnSection
                    accessToken={accessToken}
                    investorId={investor.investorId}
                    year={investor.year}
                    currFilter={currFilter}
                    currency={investor.currency}
                    investorType={investorResult.type}
                />
              }

              {/* BLOC 8 + 9 -- key impact + carbon footprint */}
              {!!investorResult &&
                <WrapKeyIndicAndCarbonSection
                  accessToken={accessToken}
                  investorId={investor.investorId}
                  year={investor.year}
                  currFilter={currFilter}
                  filter={availableFilters}
                  currency={investor.currency}
                  investorType={investorResult.type}
                />
              }

              {/* BLOC 9 -- eu taxonomy */}
              {/* <EUTaxonomySection 
                EUTaxonomy={""} 
                isImpactIndicatorLoading={false} 
                accessToken={accessToken}
                investorId={investorId}
                year={year} 
              /> */}

              <div id="cmpPdfHead" className="pdf head-pdf" data-print="yes">
                <div className="document-title">
                  <div className="meta meta-title">
                    {investorResult?.investorName}
                  </div>
                </div>
              </div>

              <div
                id="cmpPdfLegends"
                className="pdf legends-pdf"
                data-print="no"
              >
                <h2>Filtered by:</h2>
                <div className="legends_inner">
                  <div className="legend">
                    <h3>
                      Fund <i className="fas fa-angle-right"></i>
                    </h3>
                    <ul>
                      {meridiamFundResult?.map((item) => {
                        if ("all" === item.meridiamFundName) {
                          return (
                            <li
                              className="filter filter-fund"
                              key={item.fundId}
                            >
                              <span>{item.meridiamFundName}</span>
                            </li>
                          );
                        } else {
                          return (
                            <li
                              className="filter filter-fund"
                              key={item.fundId}
                            >
                              <span>{item.meridiamFundName}</span>
                            </li>
                          );
                        }
                      })}
                    </ul>
                  </div>
                  <div className="legend">
                    <h3>
                      Location <i className="fas fa-angle-right"></i>
                    </h3>
                    <ul>
                      {currFilter.location.map((countryCode) => {
                        if ("all" === countryCode) {
                          return (
                            <li
                              className="filter filter-country"
                              key={countryCode}
                            >
                              <span>All</span>
                            </li>
                          );
                        } else {
                          return (
                            <li
                              className="filter filter-country"
                              key={countryCode}
                            >
                              <span>{countries[countryCode].name}</span>
                            </li>
                          );
                        }
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LimitedPartnersDashboard);

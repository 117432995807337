import React, { useState } from "react";
import UIkit from "uikit";
import { PlatformGroup } from "models/platform-group.model";
import { User, WellKnownPlatformRoles } from "models/user.models";
import { exportAsExcelFile } from "modules/shared/services/excel.service";
import { exportAllUsersModule } from "modules/user-management/services/user-management.service";
import SearchUser from "../SearchUser/SearchUser";
import UsersListItem from "../UsersListItem/UsersListItem";
import "./styles.scss";

interface UsersListProps {
  accessToken: string;
  users: User[];
  setCurrUser: any;
}

const UsersList = ({ accessToken, users, setCurrUser }: UsersListProps) => {
  const [orderedUsers, setOrderedUsers] = useState<User[]>(users);
  const [filterByRole, setFilterByRole] = useState<any>({
    label: "All",
    value: "",
  });
  const [filterByStatus, setFilterByStatus] = useState<string>("all");
  const [searchedTerms, setSearchedTerms] = useState<string>("");
  const [
    isDownloadingSurveyUser,
    setIsDownloadingSurveyUser,
  ] = useState<boolean>(false);
  const [isDownloadingLPUser, setIsDownloadingLPUser] = useState<boolean>(
    false
  );
  const [sortNameOrder, setSortNameOrder] = useState<string>("asc");
  const [
    sortAccountCreationOrder,
    setSortAccountCreationOrder,
  ] = useState<string>("asc");

  const createAndSelectNewUser = () => {
    const newUser = {
      platformRole: WellKnownPlatformRoles.Stakeholder,
      enabled: true,
      active: false,
      groups: [] as PlatformGroup[],
      privileges: {},
      firstName: "",
      lastName: "",
      login: "",
      userType: "internal",
    } as User;
    setCurrUser(newUser);
  };

  const downloadUser = (module: string) => {
    if (module === "survey") {
      if (isDownloadingSurveyUser) {
        return;
      }
      setIsDownloadingSurveyUser(true);
    } else {
      if (isDownloadingLPUser) {
        return;
      }
      setIsDownloadingLPUser(true);
    }

    exportAllUsersModule(accessToken, module).then((resp) => {
      // console.log("----->");
      // console.log(resp);

      if (module === "survey") {
        const datas: any = [];
        resp.forEach((user) => {
          let val = {
            Email: user.email,
            ToDelete: "",
            ToAdd: "",
            Changed: "",
            FistName: user.firstName,
            LastName: user.lastName,
            UserType: user.userType,
            PlatformRole: user.platformRole,
            SurveyRole: user.surveyRole,
            SurveyAccessDetails: user.surveyAccessDetails,
          };
          datas.push(val);
        });

        exportAsExcelFile(datas, "users-survey");
        setIsDownloadingSurveyUser(false);
      } else {
        const datas: any = [];
        resp.forEach((user) => {
          if (user !== null) {
            let val = {
              Email: user.email,
              ToDelete: "",
              ToAdd: "",
              Changed: "",
              FistName: user.firstName,
              LastName: user.lastName,
              UserType: user.userType,
              PlatformRole: user.platformRole,
              LimitedPartnersDashboardRole: user.limitedPartnersDashboardRole,
              InvestorId: user.investorId,
              Funds: "pas de funds définis",
              investor: user.investor,
            };
            datas.push(val);
          }
        });

        exportAsExcelFile(datas, "users-limited-partners");
        setIsDownloadingLPUser(false);
      }
    });
  };

  const updateSortOrder = (type: string, order: string) => {
    let newUsersList = users;
    switch (type) {
      case "date":
        setSortAccountCreationOrder(order);
        if (order === "asc") {
          newUsersList = newUsersList.sort((a: User, b: User) => {
            if (!!a.creationDate && !!b.creationDate) {
              return a.creationDate > b.creationDate ? 1 : -1;
            } else if (!!b.creationDate) {
              return 1;
            } else {
              return -1;
            }
          });
        } else {
          newUsersList = newUsersList.sort((a: User, b: User) => {
            if (!!a.creationDate && !!b.creationDate) {
              return a.creationDate < b.creationDate ? 1 : -1;
            } else if (!!b.creationDate) {
              return -1;
            } else {
              return 1;
            }
          });
        }
        break;
      case "name":
        setSortNameOrder(order);
        if (order === "asc") {
          newUsersList = newUsersList.sort((a: User, b: User) => {
            let aName = a.firstName + " " + a.lastName;
            let bName = b.firstName + " " + b.lastName;
            return aName.localeCompare(bName);
          });
        } else {
          newUsersList = newUsersList.sort((a: User, b: User) => {
            let aName = a.firstName + " " + a.lastName;
            let bName = b.firstName + " " + b.lastName;
            return bName.localeCompare(aName);
          });
        }
        break;
    }
    setOrderedUsers(newUsersList);
  };

  return (
    <>
      <div className="table">
        <div className="table_header">
          <div className="head">
            <h2>
              {users.length} user{1 === users.length ? "" : "s"}
            </h2>
            <div className="actions">
              <SearchUser
                searchedTerms={searchedTerms}
                setSearchedTerms={setSearchedTerms}
              />
              <button
                className="btn btn-select btn-primary add"
                onClick={createAndSelectNewUser}
              >
                <span className="btn-label">Add new user</span>
                <span className="btn-icon">
                  <i className="fas fa-user-plus"></i>
                </span>
              </button>
            </div>
          </div>
          <div className="exports">
            <div className="exports-item">
              <span className="label">Export LP's users</span>
              <button
                type="button"
                className="btn btn-select btn-primary export"
                onClick={() => downloadUser("lps")}
              >
                <span className="btn-label">Download (.xls)</span>
                <span className="btn-icon">
                  <i className="fas fa-table"></i>
                </span>
              </button>
            </div>
            <div className="exports-item">
              <span className="label">Export Survey users</span>
              <button
                type="button"
                className="btn btn-select btn-primary export"
                onClick={() => downloadUser("survey")}
              >
                <span className="btn-label">Download (.xls)</span>
                <span className="btn-icon">
                  <i className="fas fa-table"></i>
                </span>
              </button>
            </div>
          </div>
        </div>

        <div className="table_content">
          <ul className="list">
            <li className="row filters">
              <div className="name">
                <i className="label">Name</i>
                <button
                  className="btn btn-filter"
                  type="button"
                  onClick={() =>
                    updateSortOrder(
                      "name",
                      sortNameOrder === "asc" ? "desc" : "asc"
                    )
                  }
                >
                  <span className="btn_label"></span>
                  <span className="btn_icon">
                    {sortNameOrder === "asc" ? (
                      <i className="fas fa-sort-alpha-down"></i>
                    ) : (
                      <i className="fas fa-sort-alpha-down-alt"></i>
                    )}
                  </span>
                </button>
              </div>
              <div className="role">
                <i className="label">Roles</i>
                <div className="selector">
                  <div className="select">
                    <button className="btn btn-select btn-filter" type="button">
                      <span className="btn_label">
                        {filterByRole.value !== "" ? filterByRole.label : "All"}
                      </span>
                      <span className="btn_icon">
                        <i className="fas fa-sort"></i>
                      </span>
                    </button>
                    <div
                      className="drop drop-select select-role"
                      data-uk-dropdown="pos: top-left; mode: click; delay-hide: 100"
                    >
                      <ul className="group lvl0 uk-nav uk-dropdown-nav">
                        <li
                          className="item"
                          data-uk-toggle="target: .select-role"
                          onClick={() => {
                            setFilterByRole({ label: "All", value: "" });
                            UIkit.dropdown(".select-role").hide();
                          }}
                        >
                          <span className="clickable">All</span>
                        </li>
                        <li
                          className="item"
                          data-uk-toggle="target: .select-role"
                          onClick={() => {
                            setFilterByRole({
                              label: "Users",
                              value: "stakeholder",
                            });
                            UIkit.dropdown(".select-role").hide();
                          }}
                        >
                          <span className="clickable">User</span>
                        </li>
                        <li
                          className="item"
                          data-uk-toggle="target: .select-role"
                          onClick={() => {
                            setFilterByRole({
                              label: "Global Admin",
                              value: "global-admin",
                            });
                            UIkit.dropdown(".select-role").hide();
                          }}
                        >
                          <span className="clickable">Global admin</span>
                        </li>
                        <li
                          className="item"
                          data-uk-toggle="target: .select-role"
                          onClick={() => {
                            setFilterByRole({
                              label: "Technical Admin",
                              value: "technical-admin",
                            });
                            UIkit.dropdown(".select-role").hide();
                          }}
                        >
                          <span className="clickable">Technical admin</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modules"></div>
              <div className="account-creation">
                <i className="label">Account creation</i>
                <button
                  className="btn btn-filter"
                  type="button"
                  onClick={() =>
                    updateSortOrder(
                      "date",
                      sortAccountCreationOrder === "asc" ? "desc" : "asc"
                    )
                  }
                >
                  <span className="btn_label"></span>
                  <span className="btn_icon">
                    {sortAccountCreationOrder === "asc" ? (
                      <i className="fas fa-sort-alpha-down"></i>
                    ) : (
                      <i className="fas fa-sort-alpha-down-alt"></i>
                    )}
                  </span>
                </button>
              </div>
              <div className="status">
                <i className="label">Status</i>
                <div className="selector">
                  <div className="select">
                    <button className="btn btn-select btn-filter" type="button">
                      <span className="btn_icon">
                        <i className="fas fa-sort"></i>
                      </span>
                    </button>
                    <div
                      className="drop drop-select select-status"
                      data-uk-dropdown="pos: top-left; mode: click; delay-hide: 100"
                    >
                      <ul className="group lvl0 uk-nav uk-dropdown-nav">
                        <li
                          className="item"
                          data-uk-toggle="target: .select-status"
                          onClick={() => {
                            setFilterByStatus("all");
                            UIkit.dropdown(".select-status").hide();
                          }}
                        >
                          <span className="clickable">All</span>
                        </li>
                        <li
                          className="item"
                          data-uk-toggle="target: .select-status"
                          onClick={() => {
                            setFilterByStatus("activated");
                            UIkit.dropdown(".select-status").hide();
                          }}
                        >
                          <span className="clickable">Activated</span>
                        </li>
                        <li
                          className="item"
                          data-uk-toggle="target: .select-status"
                          onClick={() => {
                            setFilterByStatus("not-activated");
                            UIkit.dropdown(".select-status").hide();
                          }}
                        >
                          <span className="clickable">Not activated</span>
                        </li>
                        <li
                          className="item"
                          data-uk-toggle="target: .select-status"
                          onClick={() => {
                            setFilterByStatus("blocked");
                            UIkit.dropdown(".select-status").hide();
                          }}
                        >
                          <span className="clickable">Blocked</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </li>

            {!!orderedUsers &&
              orderedUsers.length > 0 &&
              orderedUsers
                .filter(
                  (fu) =>
                    searchedTerms === "" ||
                    fu.firstName.toLowerCase().includes(searchedTerms.toLowerCase()) ||
                    fu.lastName.toLowerCase().includes(searchedTerms.toLowerCase()) ||
                    fu.login.toLowerCase().includes(searchedTerms.toLowerCase())
                )
                .filter(
                  (ru) =>
                    filterByRole.value === "" ||
                    ru.platformRole === filterByRole.value
                )
                .filter((su) => {
                  switch (filterByStatus) {
                    case "all":
                      return su;
                      break;
                    case "activated":
                      return true === su.active && true !== su.blockedAccount;
                      break;
                    case "not-activated":
                      return null === su.active && true !== su.blockedAccount;
                      break;
                    case "blocked":
                      return true === su.blockedAccount;
                      break;
                  }
                })
                .map((user: User, idx: number) => (
                  <UsersListItem
                    key={`userK-${idx}`}
                    user={user}
                    setCurrUser={setCurrUser}
                  />
                ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default UsersList;

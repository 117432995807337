import React, { useEffect } from "react";
import "../../../../assets/scss/custom/tools/_base.scss";
import "./styles.scss";
import { history } from "modules/shared/services/history";
import UIkit from "uikit";

type ManagementToolBarProps = {
  currentYear?: number;
  currentTool: string;
  setCurrentYear?: any;
  user?: any;
};

const ManagementToolBar: React.FC<ManagementToolBarProps> = ({
  currentTool,
  user,
}) => {
  const goTo = (url: string) => {
    history.push(`/management/${url}`);
  };

  useEffect(() => {
    UIkit.offcanvas(`#toggle-usage-${currentTool}`).hide();
  }, []);

  return (
    <>
      <div className="toolbar management-toolbar appear-right-full">
        <div className="inner">
          <div className="menu">
            <button
              className="btn btn-burger"
              data-uk-toggle={`target: #toggle-usage-${currentTool}`}
            >
              <span className="btn-label">Menu</span>
              <span className="btn-icon">
                <i className="fas fa-bars"></i>
              </span>
            </button>
          </div>
        </div>
      </div>

      <div
        id={`toggle-usage-${currentTool}`}
        data-uk-offcanvas="overlay: true; container:false;"
      >
        <div className="own-offcanvas uk-offcanvas-bar">
          <div className="inner-offcanvas">
            <button
              className="btn btn-close-burger btn-no-text"
              data-uk-toggle={`target: #toggle-usage-${currentTool}`}
            >
              <span className="btn-icon">
                <i className="fas fa-times"></i>
              </span>
              <span className="btn-label"></span>
            </button>
            <div className="offcanvas-head">
              <span className="brand">Meridiam | </span>
              <span className="survey upp">Platform</span>
            </div>
            <div className="offcanvas-body">
              <p>Navigate to</p>

              <ul className="uk-nav uk-nav-default">
                <li
                  className={
                    "spv-manager" === currentTool ||
                    "spv-manager-form" === currentTool
                      ? "current"
                      : ""
                  }
                  onClick={() => goTo("spv-manager")}
                >
                  <div>
                    <span>SPV manager</span>
                  </div>
                </li>
                {!!user &&
                  (user.platformRole === "technical-admin" ||
                    user.platformRole === "global-admin" ||
                    (user.privileges["spv-manager"] &&
                      user.privileges["spv-manager"][0] === "superadmin")) && (
                    <>
                      <li
                        className={
                          "country-list" === currentTool ? "current" : ""
                        }
                        onClick={() => goTo("country-list")}
                      >
                        <div>
                          <span>Country List manager</span>
                        </div>
                      </li>
                      <li
                        className={
                          "country-data" === currentTool ? "current" : ""
                        }
                        onClick={() => goTo("country-data")}
                      >
                        <div>
                          <span>Country Data manager</span>
                        </div>
                      </li>
                    </>
                  )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManagementToolBar;

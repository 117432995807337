import { ReportingContext } from "context/Reporting.context";
import { cloneDeep } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import SPVReportingProjectListItem from "./SPVReportingProjectListItem";

import "./styles.scss";

interface SPVReportingProjectListProps {
  projects: any;
  documentsList: any;
}

const SPVReportingProjectList = ({
  projects,
  documentsList
}: SPVReportingProjectListProps) => {

  const { projectFilters } = useContext(ReportingContext);

  const [filteredProjects, setFilteredProjects] = useState<any>(projects);

  const [sortNameOrder, setSortNameOrder] = useState<string>("asc");

  const [sortIndOrder, setSortIndOrder] = useState<string>("asc");
  
  const [sortDocOrder, setSortDocOrder] = useState<string>("asc");

  const [lastOrder, setLastOrder] = useState<string>("name");

  const sortData = (type: string, order: string, data?: any) => {
    let newDataList = !!data ? data : cloneDeep(filteredProjects);
    switch (type) {
      case "name":
        newDataList = newDataList.sort((a: any, b: any) => {
          return order === "asc" ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name);
        });
        break;
      case "indCompletion":
        newDataList = newDataList.sort((a: any, b: any) => {
          if('undefined' === typeof a.indValidation){
            a.indValidation = { isComplete: false }
          }
          if('undefined' === typeof b.indValidation){
            b.indValidation = { isComplete: false }
          }
          return order === "desc" ? a.indValidation.isComplete > b.indValidation.isComplete ? -1 : 1 : b.indValidation.isComplete > a.indValidation.isComplete ? -1 : 1;
        });
        break;
      case "docCompletion":
        newDataList = newDataList.sort((a: any, b: any) => {
          const aOrder = (0 < a.totalDocCompletion && 0 < a.docCompletion) ? a.totalDocCompletion / a.docCompletion : 0;
          const bOrder = (0 < b.totalDocCompletion && 0 < b.docCompletion) ? b.totalDocCompletion / b.docCompletion : 0;

          return 'desc' === order ? aOrder - bOrder : bOrder - aOrder;
        });
        break;
      default:
        newDataList = newDataList.sort((a: any, b: any) => {
          return order === "asc" ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name)
        });
        break;
    }
    setFilteredProjects(newDataList);
  }

  const updateSortOrder = (type: string, order: string) => {
    switch (type) {
      case "name":
        setSortNameOrder(order);
        break;
      case "indCompletion":
        setSortIndOrder(order);
        break;
      case "docCompletion":
        setSortDocOrder(order);
        break;
      default:
        setSortNameOrder(order);
        break;
    }
    sortData(type, order);
    setLastOrder(type);
  };

  const refreshSortOrder = (data?: any) => {
    switch(lastOrder){
      case 'name':
        sortData(lastOrder, sortNameOrder, data);
      break;
      case 'indCompletion':
        sortData(lastOrder, sortIndOrder, data);
      break;
      case 'docCompletion':
        sortData(lastOrder, sortDocOrder, data);
      break;
    }
  }

  useEffect(() => {
    if (!!projects) {
      // console.log("--- set filtered projects");
      // console.log(projects);
      // setFilteredProjects(projects);
      refreshSortOrder(projects);
    }
  }, [projects]);

  useEffect(() => {
    if (!!projects && 0 < projects.length) {
      let tmpFiltered: any = cloneDeep(projects);

      if (!!projectFilters.fund && "all" !== projectFilters.fund.id) {
        tmpFiltered = tmpFiltered.filter((t: any) => {
          let found: boolean = false;
          if (!!projectFilters.fund && -1 !== t.funds.indexOf(projectFilters.fund.id)) {
            found = true;
          }

          return found;
        });
      }

      if (!!projectFilters.doc_type && "all" !== projectFilters.doc_type.id) {
        tmpFiltered = tmpFiltered.filter((t: any) => {
          let found: boolean = false;

          const document: any = documentsList.find((dl: any) => dl.code === projectFilters.doc_type.id);

          if (!!document && (!!document.noTemplate || (!document.noTemplate && 0 < t.templates.length && !!t.templates.find((f: any) => {
            let templateCode = f.name.match(/^(\d+)\s?(\.|-)/gi);
            templateCode = !!templateCode && 0 < templateCode.length ? templateCode[0].replace(/(\s|\.|-)/, '') : '';
            return (Number(templateCode) === Number(projectFilters.doc_type.id));
          })))) {
            found = true;
          }

          return found;
        });
      }

      if (!!projectFilters.search) {
        tmpFiltered = tmpFiltered.filter((t: any) => {
          return t.name
            .toLocaleLowerCase()
            .match(projectFilters.search.toLocaleLowerCase());
        });
      }

      // setFilteredProjects(tmpFiltered);
      refreshSortOrder(tmpFiltered);
    }
  }, [projects, projectFilters]);

  return (
    <>
      <ul className="list">
        <li
          className={`row filters ${ !!projectFilters.doc_type && "all" === projectFilters.doc_type.id ? "" : "not-all" }`}
        >
          <div className="project">
            <i className="label">Project</i>
            <button
              className="btn btn-filter"
              type="button"
              onClick={() => updateSortOrder( "name", sortNameOrder === "asc" ? "desc" : "asc" ) }
            >
              <span className="btn_label">Sort</span>
              <span className="btn_icon">
                {sortNameOrder === "asc" ? <i className="fas fa-sort-alpha-down"></i>  : <i className="fas fa-sort-alpha-down-alt"></i> }
              </span>
            </button>
          </div>
          <div className="project-indicators">
            <i className="label">Indic. validation</i>
            <button
              className="btn btn-filter btn-invert"
              type="button"
              onClick={() => updateSortOrder("indCompletion", sortIndOrder === "asc" ? "desc" : "asc" )
              }
            >
              <span className="btn_label">Sort</span>
              <span className="btn_icon">
                {sortIndOrder === "asc" ? <i className="fas fa-sort-alpha-down"></i>  : <i className="fas fa-sort-alpha-down-alt"></i> }
                {/* {sortIndOrder === "asc" ? (
                  <i className="fas fa-sort-amount-down"></i>
                ) : (
                  <i className="fas fa-sort-amount-down-alt"></i>
                )} */}
              </span>
            </button>
          </div>
          <div className="project-documents">
            <i className="label">Doc. completion rate</i>
            <button
              className="btn btn-filter btn-invert"
              type="button"
              onClick={() => updateSortOrder("docCompletion", sortDocOrder === "asc" ? "desc" : "asc")}
            >
              <span className="btn_label">Sort</span>
              <span className="btn_icon">
                {sortDocOrder === "asc" ? <i className="fas fa-sort-amount-down"></i> : <i className="fas fa-sort-amount-down-alt"></i> }
              </span>
            </button>
          </div>
          {/* <div className="project-downloads">
            <i className="label">
              Download
              <br />
              all doc.
            </i>
          </div> */}
          <div className="report">
            <i className="label">
              Go to
              <br />
              report.
            </i>
          </div>
        </li>

        {!!filteredProjects && 0 < filteredProjects.length ? (
          filteredProjects.map((project: any, idx: number) => (
            <SPVReportingProjectListItem
              key={`project-${idx}`}
              project={project}
              filterDocType={projectFilters.doc_type}
            />
          ))
        ) : (
          <div className="no-result">
            <span>No result for your search / filtering</span>
          </div>
        )}
      </ul>
    </>
  );
};

export default SPVReportingProjectList;

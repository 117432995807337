import { PlatformConfiguration } from "models/platform-configuration.model";
import {
  ConfigurationActions,
  PlatformConfigurationActionTypes,
} from "../actions/platform-configuration/platform-configuration-action-types";

export interface PlatformConfigurationState {
  config: PlatformConfiguration;
}

const initialState: PlatformConfigurationState = {
  config: {} as PlatformConfiguration,
};

const platformConfigurationReducer = (
  state: PlatformConfigurationState = initialState,
  action: ConfigurationActions
): PlatformConfigurationState => {
  switch (action.type) {
    case PlatformConfigurationActionTypes.SetConfiguration:
      return {
        config: action.config,
      };

    default:
      return state;
  }
};

export default platformConfigurationReducer;

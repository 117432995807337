import React from "react";

type Props = {
  year: number;
  printHeaderPagesAfterFirstPage?: boolean;
  investorType: string;
};

const CarbonFootPrintTableHead: React.FC<Props> = ({
  year,
  printHeaderPagesAfterFirstPage,
  investorType
}) => {


  return (

    <div className={`table__row row__head ${!!printHeaderPagesAfterFirstPage ? 'head-repeat' : ''} `}>
      <div className="row__head_inner">
        <div className={`table__column fixed column-fund ${(investorType === "gigf") ? 'gigf' : ''}`}>
          <span>Fund</span>
        </div>

        {year > 2019 && (year < 2022 || investorType === "gigf") && 
          <div className={`table__column column-abs-emission ${(investorType === "gigf") ? 'gigf' : ''}`}>
            <span>
              <b>Absolute Scopes 1, 2 & 3 emissions</b>
              <br />
              <small>
                <i>(tCO2e/year)</i>
              </small>
            </span>
          </div>
        }

        {year > 2019 && year < 2022 && investorType === "infra" &&
          <div className="table__column column-capex">
            <span>
              <b>Meridiam CAPEX Investment Share</b>
              <br />
              <small>
                <i>
                  (%)
                </i>
              </small>
            </span>
          </div>
        }

        {year > 2019 && year < 2022 && investorType === "infra" &&
          <div className="table__column column-concession">
            <span>
              <b>Concession duration</b>
              <br />
              <small>
                <i>
                  (years)
                </i>
              </small>
            </span>
          </div>
        }

        {year === 2019 &&
          <div className="table__column column-emission">
            <span>
              <b>Weighted Scopes 1 + 2</b>
              <br />
              <small>
                <i>
                  (tC0<sub>2</sub>e/year)
                </i>
              </small>
            </span>
          </div>
        }

        {(year < 2022 || investorType === "gigf") && 
          <div className={`table__column column-carbon-fp ${(year === 2019) ? 'larger' : ''} ${(investorType === "gigf") ? 'gigf' : ''}`}>
            <span>
              <b>Weighted Scopes 1, 2, 3</b>
              <br />
              <small>
                <i>
                  (tC0<sub>2</sub>e/year)
                </i>
              </small>
            </span>
          </div>
        }

        { investorType === "infra" && year < 2022 &&
          <div className={`table__column column-trajectory ${year === 2019 ? 'larger' : ''}`}>
            <span>
              <b>Trajectory</b>
              <br />
              <small>
                <i>(°C)</i>
              </small>
            </span>
          </div>
        }

        { investorType === "gigf" && 
          <div className="table__column column-avoided">
            <span>
              <b>Avoided</b>
              <br />
              <small>
                <i>(tCO2e/year)</i>
              </small>
            </span>
          </div>
        }

        { year >= 2022 && investorType === "infra" && 
          <>
            <div className="table__column column-scope1">
              <span>
                <b>Absolute Scope 1</b>
                <br />
                <small>
                  <i>(tCO2e)</i>
                </small>
              </span>
            </div>
            <div className="table__column column-scope2">
              <span>
                <b>Absolute Scope 2</b>
                <br />
                <small>
                  <i>(tCO2e)</i>
                </small>
              </span>
            </div>
            <div className="table__column column-scope3">
              <span>
                <b>Absolute Scope 3 without construction</b>
                <br />
                <small>
                  <i>(tCO2e)</i>
                </small>
              </span>
            </div>
            <div className="table__column column-capex-euro">
              <span>
                <b>CAPEX in Euro</b>
                <br />
                <small>
                  <i>(M)</i>
                </small>
              </span>
            </div>
            <div className="table__column column-emission-above-below">
              <span>
                <b>Trajectory</b>
                <br />
                <small>
                  <i>(°C)</i>
                </small>
              </span>
            </div>
          </>
        }
      </div>
    </div>
  );
};

export default CarbonFootPrintTableHead;

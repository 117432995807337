import React, { useEffect, useState } from "react";

import DownloadPdfButton from "../../../shared/components/Ui/Buttons/DownloadPdfButton/DownloadPdfButton";
import ChartRadial from "../../../shared/components/Tools/ChartRadial/ChartRadial";
import Loader from "modules/shared/components/Loader/Loader";

import _ from "lodash";
import { sdgNames, sdgsRatings } from "../../../shared/utils/sdg-names";
import "./styles.scss";
import { SdgImpactSynthesisInterface } from "models/sdg-impact-synthesis.model";

type PropsSection = {
  year: number;
  sdgImpactSynthesis: SdgImpactSynthesisInterface | undefined;
  isSdgImpactSynthesisLoading: boolean;
  investorType?: string
};

const SdgImpactSynthesisSection: React.FC<PropsSection> = ({
  year,
  sdgImpactSynthesis,
  isSdgImpactSynthesisLoading,
  investorType = "infra"
}) => {
  const [extendedPdfMode, setPdfMode] = useState<any>(null);
  const [isPrint, setIsPrint] = useState<boolean>(false);

  const [goalMode, setGoalMode] = useState<boolean>(investorType === "gigf" ? false : true);
  const [currentSdgId, setCurrentSdgId] = useState<number>(0);
  const [targetOver, setTargetOver] = useState<number>(0);
  const [listSDG, setListSDG] = useState<any>([]);

  const meridiamSDGgoal: (null | number)[] = [null, null, null, null, null, null, 3.2, 3.5, 3, null, 3, null, null, 2.8, 2.8, null, 3];

  useEffect(() => {
    let newListSDG:number[]= [];

    if (!!sdgImpactSynthesis) {
      _(sdgImpactSynthesis)
        .filter((sdg) => !!sdg)
        .map((d) => d)
        .forEach((d) => {
          Object.entries(d).forEach(([k, v]) => {
            if (-1 === newListSDG.indexOf(parseInt(k)) && v.total !== null) {
              newListSDG.push(parseInt(k));
            }
          });
        });
      newListSDG.sort((a: any, b: any) => a - b);
      setListSDG(newListSDG);
    }
  }, [sdgImpactSynthesis]);

  const setGoalsMode = () => {
    let newGoalMode;
    newGoalMode = !!goalMode ? false : true;
    if(investorType === "gigf"){
      newGoalMode = false;
    }
    console.log("newGoalMode", newGoalMode);
    setGoalMode(newGoalMode);
  };

  const handleChangeSDG = (value:number) => {
    if(investorType === "gigf"){
      return;
    }
    setCurrentSdgId(value);
  }

  return (
    <div
      id="cmpImpactSynthesis"
      className="tool tool_lp tool_sdg-impact-synthesis"
    >
      <div className="tool_lp_inner">
        <div className="tool__title">
          <h2>SDG Impact Synthesis</h2>
          <h4 className="clr-primary">
            <small>As of Q4</small> {year}
            {/* {investorType === "gigf" ? (year+1) : year} */}
          </h4>
          <div className="sdg-infos">
            <div className="sdg_inner" data-print="no">
              <div className="icon">
                <span className="sdg-icon">
                  {targetOver !== 0 ? (
                    <img
                      src={
                        "/assets/images/sdg/icons/80/sdg-pastille-" +
                        targetOver +
                        "@2x.png"
                      }
                      alt=" "
                    />
                  ) : (
                    <svg className="svg svg-icon icon-global-sdg-rating">
                      <use xlinkHref="#icon-global-sdg-rating"></use>
                    </svg>
                  )}
                </span>
              </div>

              <div className="scores">
                <div className="score score1">
                  <div className="score_inner">
                    {targetOver !== 0 && sdgNames[targetOver] ? (
                      <span className="legend">
                        {sdgNames[targetOver].shortName}
                      </span>
                    ) : (
                      <span className="legend">SDG global rating</span>
                    )}
                    {targetOver !== 0 &&
                    sdgImpactSynthesis?.sdg[targetOver]?.total ? (
                      <span className="value">
                        {sdgImpactSynthesis.sdg[targetOver].total.toFixed(2)}
                      </span>
                    ) : (
                      <span className="value">
                        {sdgImpactSynthesis && sdgImpactSynthesis.total && (
                          <>{sdgImpactSynthesis.total.toFixed(2)}</>
                        )}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {investorType !== "gigf" && 
              <div className="filters">
                <button
                  className={`btn btn-goals btn-rounded ${
                    !!goalMode ? "on" : "off"
                  }`}
                  onClick={() => {
                    setGoalsMode();
                  }}
                  data-print={`${!!goalMode ? "yes" : "no"}`}
                >
                  <span className="btn_label">Meridiam goals</span>
                </button>
                {!!goalMode && meridiamSDGgoal[targetOver - 1] !== null && (
                  <div className="goal" data-print="no">
                    <span>{!!meridiamSDGgoal[targetOver - 1] ? meridiamSDGgoal[targetOver - 1] : "-"}</span>
                  </div>
                )}
              </div>
            }
          </div>
        </div>
        { isSdgImpactSynthesisLoading 
        ?  
          <Loader className="on" color="white" size="small" />
        : 
          sdgImpactSynthesis && sdgImpactSynthesis.total !== 0
          ? (
            <>
              <div className="tool__content">
                <div
                  className={
                    "dataviz_wrapper " +
                    (sdgImpactSynthesis?.sdg[currentSdgId] ? "split" : "no-split")
                  }
                >
                  <div className="dataviz dataviz_rosace">
                    {!sdgImpactSynthesis?.sdg[currentSdgId] && (
                      <div
                        className={
                          "call-to-use " +
                          (sdgImpactSynthesis?.sdg[currentSdgId]
                            ? "split"
                            : "no-split")
                        }
                        data-print="no"
                      >
                        {investorType !== "gigf" &&
                          <span>
                            Please select a SDG to visualize
                            <br />
                            the performance per target
                            <svg>
                              <use xlinkHref="#useArrow"></use>
                            </svg>
                          </span>
                        }
                      </div>
                    )}
                    <div
                      className={`container-charts mode-radar ${
                        extendedPdfMode ? "wrapp-pdf-extend" : ""
                      }`}
                    >
                      <ChartRadial
                        sdgImpactSynthesis={sdgImpactSynthesis}
                        setPdfMode={setPdfMode}
                        extendedPdfMode={extendedPdfMode}
                        setCurrentSdgId={handleChangeSDG}
                        setTargetOver={setTargetOver}
                        goalMode={goalMode}
                        meridiamSDGgoal={meridiamSDGgoal}
                      />
                    </div>
                  </div>

                  <div className="dataviz dataviz_bars">
                    <div
                      className="cmp cmp-chart chart chart-bars"
                      data-sdg={currentSdgId}
                      data-print="no"
                    >
                      <div className="inner-chart">
                        <button
                          className="btn btn-close btn-no-text"
                          type="button"
                          onClick={() => {
                            handleChangeSDG(0);
                          }}
                        >
                          <span className="btn_icon">
                            <i className="fas fa-times"></i>
                          </span>
                          <span className="btn_label">Close</span>
                        </button>

                        <div className="chart__head">
                          <div className="inner">
                            <div className="icon">
                              <span className="sdg-icon">
                                <img
                                  src={
                                    "/assets/images/sdg/icons/80/sdg-pastille-" +
                                    currentSdgId +
                                    "@2x.png"
                                  }
                                  alt=" SDG icon"
                                />
                              </span>
                            </div>
                            <div className="select select-sdg" data-print="no">
                              <button
                                className="btn btn-select btn-primary btn-rounded"
                                type="button"
                              >
                                <span className="btn_label">
                                  SDG {currentSdgId}
                                </span>
                                <span className="btn_icon">
                                  {/* <i className="fas fa-caret-down"></i> */}
                                </span>
                              </button>
                              <div
                                className="drop"
                                data-uk-dropdown="mode: hover; delay-show: 0; delay-hide: 100 ;animation: uk-animation-slide-top-small; duration: 250;"
                              >
                                <ul className="uk-nav uk-dropdown-nav">
                                  {listSDG?.map((i: number) => {
                                    return (
                                      <li
                                        key={"sdgSelected_" + i}
                                        onClick={() => {
                                          handleChangeSDG(i);
                                        }}
                                      >
                                        <span>SDG {i}</span>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            </div>

                            <div className="label">
                              {currentSdgId !== 0 &&
                              sdgImpactSynthesis?.sdg[currentSdgId] ? (
                                <>{sdgNames[currentSdgId].shortName}</>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        {currentSdgId !== 0 &&
                        sdgImpactSynthesis?.sdg[currentSdgId] ? (
                          <div className="chart-content">
                            <div className="inner">
                              <ul className="list list-targets">
                                {Object.keys(
                                  sdgImpactSynthesis.sdg[currentSdgId].datas
                                ).map((target, index) => {
                                  // console.log(
                                  //   target,
                                  //   sdgImpactSynthesis.sdg[currentSdgId].datas[
                                  //     target
                                  //   ]
                                  // );

                                  if (
                                    target !== "totalConfidenceIndex" &&
                                    target !== "total"
                                  ) {
                                    return (
                                      <li
                                        className="target"
                                        key={`targetKlist-${target}`}
                                      >
                                        <div
                                          className="inner-target"
                                          // onClick={() => {
                                          //   setCurrSdgTargetId(indicator);
                                          // }}
                                          // data-uk-toggle="cls: open;"
                                        >
                                          <div className="target-num">
                                            {target}.
                                          </div>
                                          <div className="target-content">
                                            <div className="target-title">
                                              {sdgsRatings[target]}
                                            </div>
                                            <div className="target-score">
                                              <div className="cmp-score score-bar">
                                                <div
                                                  className="bar bar1"
                                                  data-score={(
                                                    sdgImpactSynthesis.sdg[
                                                      currentSdgId
                                                    ].datas[target].total * 100
                                                  ).toFixed(0)}
                                                ></div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        {/* {currSdgTargetId &&
                                        currSdgTargetId === indicator ? (
                                          <div className="wrapp-subtarget">
                                            <span
                                              className="btn-close-indicators"
                                              onClick={() => {
                                                setCurrSdgTargetId("0");
                                              }}
                                            >
                                              <i>Close indicators</i>
                                              <i className="fas fa-times"></i>
                                            </span>
                                            <ul className="list list-subtargets">
                                              {Object.keys(
                                                currentSdgId &&
                                                  sdgImpactSynthesis?.sdg[currentSdgId]
                                                    .datas[indicator].datas
                                              ).map((target, idx) => {
                                                return (
                                                  <li key={idx} className="target">
                                                    <div
                                                      id={
                                                        `remoteTarget_` +
                                                        target.replace(".", "_")
                                                      }
                                                      className="inner-target"
                                                      data-uk-toggle="cls: open;"
                                                    >
                                                      <div className="target-num">
                                                        {target}
                                                      </div>
                                                      <div className="target-content">
                                                        <div className="target-title">
                                                          {currentSdgId &&
                                                            sdgImpactSynthesis?.sdg[
                                                              currentSdgId
                                                            ].datas[indicator].datas[
                                                              target
                                                            ].indicatorText}
                                                        </div>
                                                        <div className="target-score">
                                                          <div className="cmp cmp-score score-bar">
                                                            <div
                                                              className="bar bar1"
                                                              data-score={
                                                                currentSdgId &&
                                                                sdgImpactSynthesis?.sdg[
                                                                  currentSdgId
                                                                ].datas[indicator]
                                                                  .datas[target]
                                                                  .questionNote > 0 ? (
                                                                  (
                                                                    sdgImpactSynthesis
                                                                      ?.sdg[
                                                                      currentSdgId
                                                                    ].datas[indicator]
                                                                      .datas[target]
                                                                      .questionNote *
                                                                    100
                                                                  ).toFixed(0)
                                                                ) : (
                                                                  <>""</>
                                                                )
                                                              }
                                                            >
                                                              {currentSdgId &&
                                                              !sdgImpactSynthesis?.sdg[
                                                                currentSdgId
                                                              ].datas[indicator].datas[
                                                                target
                                                              ].questionNote ? (
                                                                <span>no data</span>
                                                              ) : (
                                                                <></>
                                                              )}
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </li>
                                                );
                                              })}
                                            </ul>
                                          </div>
                                        ) : null} */}
                                      </li>
                                    );
                                  }
                                })}
                              </ul>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tool__actions">
                <div className="actions_inner">
                  <div className="export">
                    <DownloadPdfButton
                      visible={true}
                      optionsPrint={{
                        format: "A4",
                        orientation: "portrait",
                        assets: [
                          {
                            folder: "assets/css",
                            files: [
                              "development" !== process.env.NODE_ENV
                                ? "print.css"
                                : "print-local.css",
                            ],
                          },
                        ],
                      }}
                      pdfTitle=""
                      filename="SDG_Impact_Synthesis"
                      selector="#cmpImpactSynthesis"
                      selectorHead="#cmpPdfHead"
                      selectorFoot="#cmpPdfLegends"
                      setPdfMode={setPdfMode}
                      extendedPdfMode={extendedPdfMode}
                      mode="link"
                      onclickValue={true}
                      //mode="dropdown"
                      label="Download"
                      labelInside=".pdf"
                      icon="fas fa-download"
                      //icon="fas fa-caret-down"
                      setIsPrint={setIsPrint}
                      itemsList={[]}
                      // itemsList={[
                      //   {
                      //     id: 1,
                      //     label: "Legend",
                      //     action: "onclick",
                      //     value: true,
                      //     className: "",
                      //   },
                      //   {
                      //     id: 2,
                      //     label: "No legend",
                      //     action: "onclick",
                      //     value: false,
                      //     className: "",
                      //   },
                      // ]}
                    />
                  </div>
                </div>
              </div>
            </>
          )
          : <p className="no-data-available">No data available.</p>
        }
      </div>
    </div>
  );
};

export default SdgImpactSynthesisSection;

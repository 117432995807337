import PermissionProvider from "context/Permission.context";
import { RootState, ThunkDispatch } from "models/store.models";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import HubDashboard from "./screens/HubDashboard/HubDashboard";
import IndicatorsAndDocuments from "./screens/IndicatorsAndDocuments/IndicatorsAndDocuments";
import ProjectIndicatorsAndDocument from "./screens/ProjectIndicatorsAndDocument/ProjectIndicatorsAndDocument";
import ReportingProvider from "context/Reporting.context";

const mapStateToProps = ({ session }: RootState) => ({
  user: session?.sessionData?.userData,
});

const mapDispatchToProps = (dispatch: ThunkDispatch) => ({});

type HubProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const HubRoutes = ({ user }: HubProps) => {
  let { path } = useRouteMatch();

  return (
    <ReportingProvider>
      <Switch>
        <Route exact path={`${path}`}>
          <PermissionProvider
            value={{
              module: "reporting",
              allowedPermissions: ["superadmin", "admin", "contributor", "technical-admin"],
              user: user,
              redirect: "/",
            }}
          >
            <HubDashboard />
          </PermissionProvider>
        </Route>
        <Route exact path={`${path}/indicators-and-documents`}>
          <PermissionProvider
            value={{
              module: "reporting",
              allowedPermissions: ["superadmin", "global-admin", "technical-admin"],
              user: user,
              redirect: "/spv-reporting",
            }}
          >
            <IndicatorsAndDocuments />
          </PermissionProvider>
        </Route>
        <Route path={`${path}/project/:projectCode`}>
          <PermissionProvider
            value={{
              module: "reporting",
              allowedPermissions: ["superadmin", "admin", "contributor", "technical-admin"],
              user: user,
              redirect: "/spv-reporting"
            }}
          >
            <ProjectIndicatorsAndDocument />
          </PermissionProvider>
        </Route>

        <Redirect from={`${path}`} to={`${path}/dashboard`} />
      </Switch>
    </ReportingProvider>
  );
};

// export default HubRoutes;

export default connect(mapStateToProps, mapDispatchToProps)(HubRoutes);

import React, { useState } from "react";
// import { sdgNames } from "../../../../shared/utils/sdg-names";
import config from "modules/config";

import "./styles.scss";
import { SdgImpactSynthesisInterface } from "models/sdg-impact-synthesis.model";

type Props = {
  sdgImpactSynthesis?: SdgImpactSynthesisInterface;
  setCurrentSdgId: any;
  comparaisonMode?: boolean;
  setPdfMode?: any;
  extendedPdfMode?: boolean;
  SDGName?: any;
  setTargetOver: any;
  goalMode?: boolean;
  meridiamSDGgoal?: (null | number)[];
  comparaisonSdgSynthesis?: SdgImpactSynthesisInterface;
  showConfidenceIndex?: boolean;
  showConfidenceIndexComparaison?: boolean;
};

const listSDG = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17];

const ChartRadial: React.FC<Props> = ({
  sdgImpactSynthesis,
  setCurrentSdgId,
  comparaisonMode,
  extendedPdfMode,
  setTargetOver,
  goalMode,
  meridiamSDGgoal,
  comparaisonSdgSynthesis,
  showConfidenceIndex,
  showConfidenceIndexComparaison
}) => {
  // console.log("%c -- SDG Impact Synthesis : ", "color:green;font-size:13px");
  // console.log(sdgImpactSynthesis);

  /* LP CharRadial -- no comparaison neither confidenceIndex */
  if (comparaisonMode == null) {
    comparaisonMode = false;
    showConfidenceIndex = false;
    showConfidenceIndexComparaison = false;
  }

  const [targetCurrentOver, setTargetCurrentOver] = useState(0);

  return (
    <div
      className={`cmp cmp-chart chart-radial ${
        comparaisonMode ? "with-comparaison " : ""
      } ${extendedPdfMode ? "pdf-extend " : ""}`}
      data-sdg-hovered={targetCurrentOver}
    >
      <div className="inner-chart">
        <div className="overbar overbar1">
          {listSDG.map((i, index) => {
            return (
              <span className={`border-sdg border-sdg${i}`} key={index}></span>
            );
          })}
        </div>
        <div className="overbar overbar2">
          {listSDG.map((i, index) => {
            return (
              <span
                className={`arc-sdg arc-sdg${i}`}
                key={index}
                onClick={() => {
                  setCurrentSdgId(i);
                }}
              ></span>
            );
          })}
        </div>
        <div className="overbar overbar3">
          {listSDG.map((i, index) => {
            return (
              <span
                className={`legend-sdg legend-sdg${i}`}
                key={index}
                data-score={
                  sdgImpactSynthesis?.sdg[i]?.total || sdgImpactSynthesis?.sdg[i]?.total === 0
                    ? (sdgImpactSynthesis?.sdg[i]?.total * 100).toFixed(0)
                    : ""
                }
              ></span>
            );
          })}
        </div>

        <div className="bars">
          {listSDG.map((i, index) => {
            return (
              <div
                className={`bar bar${i}`}
                key={index}
                onClick={() => {
                  setCurrentSdgId(i);
                }}
                data-value={
                  (sdgImpactSynthesis?.sdg[i]?.total || sdgImpactSynthesis?.sdg[i]?.total === 0) ||
                  (!!comparaisonMode &&
                    !!comparaisonSdgSynthesis &&
                    comparaisonSdgSynthesis.sdg[i] &&
                    comparaisonSdgSynthesis.sdg[i].total)
                    ? "data"
                    : "nodata"
                }
              >
                <div className="inner-bar">
                  <div
                    className="circle circle1"
                    data-score=
                    { showConfidenceIndex === true
                    ? 
                      (
                        sdgImpactSynthesis?.sdg[i]?.totalConfidenceIndex
                        ? 
                        (
                          sdgImpactSynthesis?.sdg[i]?.totalConfidenceIndex *
                          100
                        ).toFixed(0)
                        : ""
                      )
                    : 
                      (
                        sdgImpactSynthesis?.sdg[i]?.total || sdgImpactSynthesis?.sdg[i]?.total === 0 
                        ? 
                          (sdgImpactSynthesis?.sdg[i]?.total * 100).toFixed(0)
                        : ""
                      )
                    }
                    onMouseEnter={() => {
                      setTargetOver(i);
                      setTargetCurrentOver(i);
                    }}
                    onMouseLeave={() => {
                      setTargetOver(0);
                      setTargetCurrentOver(0);
                    }}
                  >
                    <div className="inner-circle">
                      <svg
                        viewBox="0 0 420 420"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="210" cy="210" />
                      </svg>
                      <div className="graduations">
                        <div className="inner-graduations">
                          <span></span>
                          <span></span>
                          <span></span>
                          <span></span>
                          <span></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {comparaisonMode === true && (
                    <div
                      className="circle circle2"
                      data-score={
                        showConfidenceIndex === true
                          ? comparaisonSdgSynthesis?.sdg[i]
                            ? comparaisonSdgSynthesis.sdg[i]
                                ?.totalConfidenceIndex
                              ? (
                                  comparaisonSdgSynthesis.sdg[i]
                                    .totalConfidenceIndex * 100
                                ).toFixed(0)
                              : ""
                            : ""
                          : comparaisonSdgSynthesis?.sdg[i]
                          ? comparaisonSdgSynthesis.sdg[i]?.total
                            ? (
                                comparaisonSdgSynthesis.sdg[i].total * 100
                              ).toFixed(0)
                            : ""
                          : ""
                      }
                      onMouseEnter={() => {
                        setTargetOver(i);
                        setTargetCurrentOver(i);
                      }}
                      onMouseLeave={() => {
                        setTargetOver(0);
                        setTargetCurrentOver(0);
                      }}
                    >
                      <div className="inner-circle">
                        <svg
                          viewBox="0 0 420 420"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="210" cy="210" />
                        </svg>
                      </div>
                      <div className="graduations">
                        <div className="inner-graduations">
                          <span></span>
                          <span></span>
                          <span></span>
                          <span></span>
                          <span></span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            );
          })}

          {!!meridiamSDGgoal &&
            goalMode &&
            meridiamSDGgoal.map((j, idx) => {
              return (
                <div
                  className={`goal goal${idx + 1}`}
                  key={idx}
                  data-value={
                    j !== null &&
                    j > 0 &&
                    !!sdgImpactSynthesis?.sdg[idx + 1]?.total &&
                    sdgImpactSynthesis?.sdg[idx + 1]?.total > 0
                      ? "data"
                      : "nodata"
                  }
                >
                  {j !== null &&
                    j > 0 &&
                    !!sdgImpactSynthesis?.sdg[idx + 1]?.total &&
                    sdgImpactSynthesis?.sdg[idx + 1]?.total > 0 && (
                      <div className="inner-bar">
                        <div
                          className="circle circle1"
                          data-score={j ? (j * 100).toFixed(0) : ""}
                        >
                          <div className="inner-circle">
                            <svg
                              viewBox="0 0 420 420"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle
                                className="circle-goal"
                                cx="210"
                                cy="210"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                    )}
                </div>
              );
            })}
        </div>

        {!!extendedPdfMode && (
          <div className="legends-extension" data-print="only">
            {listSDG.map((i, index) => {
              return (
                <div
                  className="inner-legends-extension"
                  key={`legends-${index}`}
                >
                  {!!sdgImpactSynthesis?.sdg[i]?.total &&
                    sdgImpactSynthesis?.sdg[i]?.total > 0 && (
                      <div className={`legend-extend legend-extend${i}`}>
                        <div className="icon">
                          <span className="sdg-icon">
                            <img
                              src={`${config.CdnUrl}/assets/images/sdg/icons/svg/sdg-inverted-${i}.svg`}
                              alt=" "
                            />
                          </span>
                        </div>
                        <div
                          className={`scores ${
                            !!comparaisonMode ? "with-comparaison" : ""
                          }`}
                        >
                          <div className="inner-scores">
                            <div className="score score1">
                              <div className="values">
                                {sdgImpactSynthesis?.sdg[i]?.total ? (
                                  <>
                                    <span className="value">
                                      {sdgImpactSynthesis?.sdg[
                                        i
                                      ]?.total.toFixed(2)}
                                    </span>
                                    {!!goalMode &&
                                      !comparaisonMode &&
                                      meridiamSDGgoal !== null &&
                                      meridiamSDGgoal !== undefined &&
                                      meridiamSDGgoal[i - 1] !== null && (
                                        <span className="score-goal">
                                          {meridiamSDGgoal[i - 1]}
                                        </span>
                                      )}
                                  </>
                                ) : (
                                  <>{!!comparaisonMode && <>--</>}</>
                                )}
                              </div>
                            </div>
                            {!!comparaisonMode && (
                              <>
                                <div className="score spacer">/</div>
                                <div className="score score2">
                                  <span className="value">
                                    {comparaisonSdgSynthesis &&
                                      comparaisonSdgSynthesis.sdg[i] &&
                                      comparaisonSdgSynthesis.sdg[i].total &&
                                      comparaisonSdgSynthesis.sdg[
                                        i
                                      ].total.toFixed(2)}
                                  </span>
                                </div>
                              </>
                            )}
                            {!!comparaisonMode && (
                              <div className="score-goal-wrapper">
                                {!!goalMode &&
                                  meridiamSDGgoal !== null &&
                                  meridiamSDGgoal !== undefined &&
                                  meridiamSDGgoal[i - 1] !== null && (
                                    <span className="score-goal">
                                      {meridiamSDGgoal[i - 1]}
                                    </span>
                                  )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default ChartRadial;

import { cloneDeep } from "lodash";
import { deleteFile, getFileByDocumentType } from "modules/shared/services/spv-reporting.service";
import UploadConfig, { MaxChunkSize, UploadFile } from "modules/shared/services/upload.service";
import React, { useEffect, useState } from "react";

interface ProjectIndicatorsUploadProps {
  accessToken: string
  slug: any,
  indicator: any;
  value: string;
  handleChange: any;
  index?: number
  project: any
}

const ProjectIndicatorsUpload = ({ accessToken, project, slug, indicator, value, handleChange, index }: ProjectIndicatorsUploadProps) => {

  const [uploading, setUploading] = useState<boolean>(false);

  const [template, setTemplate] = useState<any>(null);

  const [uploadedFiles, setUploadedFiles] = useState<any>([]);

  const [directory, setDirectory] = useState<string>('');

  const [queue, setQueue] = useState<any>([]);

  const loadFiles = async () => {

    if(!!indicator.template){
      const templateName: string = indicator.template.replace(/\..+$/, '');
      const tmpTemplates: any = await getFileByDocumentType("template", accessToken, [templateName], "");
      if ("ok" === tmpTemplates.status && 0 < tmpTemplates.files.length) {
        setTemplate(tmpTemplates.files[0]);
      }
    }

    const tmpFiles: any = await getFileByDocumentType(slug, accessToken, null, `${project.projectCode} - ${project.projectName.replace(/\//g, '-').trim()}`);
    if ("ok" === tmpFiles.status) {
      setUploadedFiles(tmpFiles.files);
      setDirectory(tmpFiles.directory);
    }
  };

  const selectUploadFile = (code: string, fileList: FileList) => {
    const tmp: any = [];
    code = code.replace(/(\.|-)/, "");
    const files = Array.from(fileList)
    setQueue(files);
    setUploading(true);
  };

  const processUpload = (file: any) => {
    return new Promise(resolve => {
      const totalFiles = Math.ceil(file.size / MaxChunkSize);
      UploadConfig.accessToken = accessToken;
      UploadConfig.maxChunks = totalFiles;
      UploadConfig.uploading = true;

      UploadFile(directory, { filename: `${file.name}`, file: file }, (data: any) => {
        resolve(data);
      });
    });
  }

  const processQueue = async () => {
    for (let q in queue) {
      const file: any = await processUpload(queue[q]);
      setUploadedFiles([...uploadedFiles, ...[file]])
    }
    setUploading(false);
  }

  const deleteFileFromServer = async (file: any) => {
    if (window.confirm(`Would you like to delete ${file.name} file ?`)) {
      const hasBeenDeleted: boolean = await deleteFile(accessToken, file.id);
      if (!!hasBeenDeleted) {
        const fileIndex: number = uploadedFiles.findIndex((af: any) => af.id === file.id);
        const tmpFiles: any = cloneDeep(uploadedFiles);
        tmpFiles.splice(fileIndex, 1);
        setUploadedFiles(tmpFiles);
      }
    }
  };

  useEffect(() => {
    processQueue();
  }, [queue])

  useEffect(() => {
    loadFiles();
  }, [])

  return (
    <div className={`upload`}>
      <div className="upload_head">
        <p className="label">{indicator.name}</p>
        {!!template &&
          <a href={template.file.download} download>
            <label
              className="btn btn-select btn-primary"
            >
              <span className="btn-icon">
                <i className={`fas fa-download`}></i>
              </span>
            </label>
          </a>
        }
        {!!uploading ?
          <div className="is-uploading">
            <i className={`fas fa-spinner`}></i>
          </div>
          :
          <>
            <input
              type="file"
              id={`upload-${slug}`}
              hidden
              multiple={!!indicator.multiple ? true : false}
              onChange={(e: any) =>
                selectUploadFile(slug, e.target.files)
              }
            />
            <label
              htmlFor={`upload-${slug}`}
              className="btn btn-select btn-primary"
            >
              <span className="btn-icon">
                <i className={`fas fa-upload`}></i>
              </span>
            </label>
          </>
        }
      </div>
      {!!uploadedFiles &&
        <ul>
          {uploadedFiles.map((file: any, ufi: number) => (
            <li key={`proj-ind-upload-${indicator.id}-${ufi}`} className={'files-item'}>
              <span>{file.name}</span>
              <i
                className={`fas fa-times-circle`}
                onClick={() => deleteFileFromServer(file)}
              ></i>
            </li>
          ))}
        </ul>
      }
    </div>
  );
};

export default ProjectIndicatorsUpload;

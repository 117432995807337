import { cloneDeep } from "lodash";
import { User } from "models/user.models";
import React from "react";

import "./styles.scss";

const { DateTime } = require("luxon");

interface UserInformationsProps {
  modifiedCurrUser: any;
  setModifiedCurrUser: any;
  formDirty: string[];
  setFormDirty: any;
  editor: User;
}

const UserInformations = ({
  modifiedCurrUser,
  setModifiedCurrUser,
  formDirty,
  setFormDirty,
  editor,
}: UserInformationsProps) => {
  const handleChange = (field: string, value: string) => {
    let newUser = cloneDeep(modifiedCurrUser);
    newUser[field] = value;
    setModifiedCurrUser(newUser);

    if (!formDirty.includes(field)) {
      let newList = cloneDeep(formDirty);
      newList.push(field);
      setFormDirty(newList);
    }
  };

  return (
    <div className="user-infos">
      <h2 className="section-title">
        <i className="fas fa-id-card"></i> User information
      </h2>
      <div className="user-infos_inner">
        <div className="left">
          <div className="row row-input">
            <label className="label" htmlFor="first-name">
              First name *
            </label>
            <input
              id="first-name"
              type="text"
              defaultValue={modifiedCurrUser.firstName}
              placeholder="First name"
              required
              name="firstName"
              onChange={(e) => handleChange("firstName", e.target.value)}
              className={formDirty.includes("firstName") ? "is-modified" : ""}
            />
          </div>
          <div className="row row-input">
            <label className="label" htmlFor="last-name">
              Last name *
            </label>
            <input
              id="last-name"
              type="text"
              defaultValue={modifiedCurrUser.lastName}
              placeholder="Last name"
              required
              name="last-name"
              onChange={(e) => handleChange("lastName", e.target.value)}
              className={formDirty.includes("lastName") ? "is-modified" : ""}
            />
          </div>
        </div>
        <div className="right">
          <div className="row row-input">
            <label className="label" htmlFor="email">
              Email *
            </label>
            <input
              id="email"
              type="text"
              placeholder="ex: xy@meridiam.fr"
              defaultValue={modifiedCurrUser.login}
              required
              name="email"
              onChange={(e) => handleChange("login", e.target.value)}
              className={formDirty.includes("login") ? "is-modified" : ""}
              disabled={
                modifiedCurrUser.platformRole === "technical-admin" &&
                editor.platformRole !== "technical-admin"
                  ? true
                  : false
              }
            />
          </div>
          <div className="row row-input last-accessed">
            {!!modifiedCurrUser && !!modifiedCurrUser.id && (
              <i className="italic">
                User's last connexion :{" "}
                {modifiedCurrUser.lastConnectionDate
                  ? DateTime.fromISO(
                      modifiedCurrUser.lastConnectionDate
                    ).toFormat("LL/dd/yyyy - HH:mm")
                  : "Never."}
              </i>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserInformations;

import React, { useContext, useEffect, useState } from "react";
import { Tab, Tabs } from "@material-ui/core";
import ProjectIndicators from "modules/hub/components/ProjectIndicators/ProjectIndicators";
import ProjectReportingDocuments from "modules/hub/components/ProjectReportingDocuments/ProjectReportingDocuments";
import ProjectAssumptionsHub from "modules/hub/components/ProjectAssumptionsHub/ProjectAssumptionsHub";
import ProjectAssumptionsLeader from "modules/hub/components/ProjectAssumptionsLeader/ProjectAssumptionsLeader";
import ProjectIndicatorsLeaderView from "modules/hub/components/ProjectIndicatorsLeaderView/ProjectIndicatorsLeaderView";
import { connect } from "react-redux";

import "./styles.scss";
import { RootState, ThunkDispatch } from "models/store.models";
import { getProjectByCode } from "modules/shared/services/project.service";
import { useParams } from "react-router-dom";
import { PermissionContext } from "context/Permission.context";
import TeamModal from "modules/shared/components/TeamModal/TeamModal";

import config from "modules/config";
import { ReportingContext } from "context/Reporting.context";
import { DateTime } from "luxon";
import { cloneDeep } from "lodash";
import UIkit from 'uikit';

interface ProjectProps {
  accessToken: string;
  user: any;
}
const mapStateToProps = ({ session }: RootState) => ({
  accessToken: session?.sessionData?.accessToken
    ? session?.sessionData?.accessToken
    : "",
  user: session?.sessionData?.userData,
});

const mapDispatchToProps = (dispatch: ThunkDispatch) => ({});

const Project = ({
  user,
  accessToken,
}: ProjectProps) => {

  const { isMeridiamGranted, isLeaderView, isContributor } = useContext(PermissionContext);

  const { currentTab, data, totalProjectIndicatorsValidate, projectIndicators, rawProjectIndicators, setRequestedTab, setCurrentTab, setProjectIndicators } = useContext(ReportingContext);

  const [project, setProject] = useState<any>(null);

  const [usersCount, setUsersCount] = useState<number>(0);

  const [countTotalProjectIndicators, setCountTotalProjectIndicators] = useState<number>(0);

  const { projectCode } = useParams<{ projectCode: string }>();

  const loadProject: any = async () => {
    const tmpProject: any = await getProjectByCode(accessToken, projectCode, "reporting", config.current_year );
    // const tmpProject: any = await getProjectByCode(accessToken, projectCode, "reporting", !!isMeridiamGranted ? config.current_year : null );
    // "technical-admin" === user.platformRole || "global-admin" === user.platformRole ? config.current_year : null );

    if (!!tmpProject) {
      setProject(tmpProject);
    }
  };

  const [reviewState, setReviewState] = useState<"disabled" | "confirm" | "valid">("disabled");

  const getTotalProjectIndicatorsValidate = () => {
    let total: number = 0;
    for (let i in projectIndicators) {
      if (!!projectIndicators[i] && 'object' === typeof projectIndicators[i] && 'undefined' !== typeof projectIndicators[i].isValidate) {
        total += !!projectIndicators[i].isValidate ? 1 : 0;
      }
    }
    // console.log('%c __ TOTAL VALIDATED PROJECT', 'font-size:15px;color:red', total);
    setCountTotalProjectIndicators(total);

    if (!!projectIndicators.isComplete) {
      setReviewState("valid")
    } else {
      
      if (totalProjectIndicatorsValidate !== total) {
        setReviewState("disabled")
      } else {
        setReviewState("confirm")
      }
    }
  }

  const updateProjectCompletion = (isComplete: boolean) => {
    const tmpProjectIndicators: any = cloneDeep(projectIndicators);
    tmpProjectIndicators.isComplete = isComplete;
    setProjectIndicators(tmpProjectIndicators);
  }

  // Display modal lost data on tab changing
  const loadTab = (nextTab: number) => {
    if(0 === currentTab && JSON.stringify(rawProjectIndicators) !== JSON.stringify(projectIndicators)){
      setRequestedTab(nextTab);
      UIkit.modal('#warnQuitTab').show();
    }else{
      setCurrentTab(nextTab);
    }
  }

  useEffect(() => {
    if (!!projectCode) {
      loadProject();
    }
  }, []);

  useEffect(() => {
    getTotalProjectIndicatorsValidate();
  }, [projectIndicators]);

  return (
    <>
      <div className="grid grid-tool">
        <div className="grid_inner">
          <div className="main-content bg-white tool-spv-reporting">
            {!!project && 
                <div className="project-name">
                  <h2>{project.projectName}</h2>
                  {(!!isLeaderView || !!isMeridiamGranted) && 
                    <button className="btn btn-select btn-primary" type="button" data-uk-toggle="target: #teamModal;" >
                      <span className="btn-label">Add/Remove contributors</span>
                      <span className="btn-icon">
                        <i className={`fas fa-external-link-square-alt`}></i>
                      </span>
                    </button>
                  }
                </div>
            }
            {!!project && 0 === currentTab && 
                  <div className={`review ${reviewState}`}>
                    <div className="review_inner">
                      {(!projectIndicators.isComplete) && (
                        <div className="top">
                          Please review all indicators by&nbsp;<b>{!!data && !!data[0] && !!data[0].dueDate ? DateTime.fromISO(data[0].dueDate.replace(/Z/, '')).toFormat('yyyy-LL-dd') : ''}</b>
                        </div>
                      )}
                      <div className="bottom">
                        {totalProjectIndicatorsValidate > countTotalProjectIndicators ?
                          <p>
                            Please review/complete all indicators and validate
                            in order to confirm completion
                          </p>
                          :
                          <p>All indicators have been reviewed</p>
                        }
                        {!!projectIndicators.isComplete ?
                          <div className="btn-fake">
                            <span className="label">Done</span>
                            <span className="icon">
                              <i className={`fas fa-check`}></i>
                            </span>
                          </div>
                          :
                          <button
                            className="btn btn-select btn-primary"
                            type="button"
                            disabled={(totalProjectIndicatorsValidate > countTotalProjectIndicators)}
                            onClick={() => updateProjectCompletion(true)}
                          >
                            <span className="btn-label">
                              Confirm completion to Meridiam HUB
                            </span>
                            <span className="btn-icon">
                              <i className={`fas fa-arrow-right`}></i>
                            </span>
                          </button>
                        }
                      </div>
                    </div>
                  </div>
            }

            <Tabs value={currentTab} className="tabs-container">
              <Tab
                label="indicators"
                className="tabs-item"
                onClick={() => loadTab(0)}
              />
              <Tab
                label="reporting documents"
                className="tabs-item"
                onClick={() => loadTab(1)}
              />
              <Tab
                label="macroeconomic assumptions"
                className="tabs-item"
                onClick={() => loadTab(2)}
              />
            </Tabs>
            <div className="project-details-content_inner">
              <div
                role="tabpanel"
                className="project-details indicators"
                hidden={0 !== currentTab}
              >
                {!!project ?
                  <ProjectIndicatorsLeaderView
                    currency={!!project ? project.currency : null}
                    project={!!project ? project : null} 
                  />
                  : !!isMeridiamGranted &&
                  <ProjectIndicators />
                }
              </div>
              <div
                role="tabpanel"
                className="project-details reporting-documents"
                hidden={1 !== currentTab}
              >
                <ProjectReportingDocuments project={!!project ? project : null} />
              </div>
              <div role="tabpanel" className="project-details macroeconomic-assumptions" hidden={2 !== currentTab}>
                <div className={`project-details-assumptions${!!project ? "-leader" : "-hub"}`}>
                  {!!project ? 
                    <ProjectAssumptionsLeader />
                   : !!isMeridiamGranted &&
                    <ProjectAssumptionsHub />
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {(!!user && !!user.id && !!project && !!project.id && !isContributor) && 
        <TeamModal
          accessToken={accessToken}
          module={"reporting"}
          projectCode={projectCode}
          moduleId={project.id}
          me={{
            id: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            login: user.login,
            platformRole: user.platformRole,
          }}
          setUsersCount={setUsersCount}
        />
      }
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Project);

import React, { useState } from "react";
import UserSettingsSurveySdg from "../UserSettingsSurveySdg/UserSettingsSurveySdg";
import UserSettingsLPDashboard from "../UserSettingsLPDashboard/UserSettingsLPDashboard";
import UserSettingsESGTools from "../UserSettingsESGTools/UserSettingsESGTools";
import UserSettingsReporting from "../UserSettingsReporting/UserSettingsReporting";
import UserSettingsUserManager from "../UserSettingsUserManager/UserSettingsUserManager";
import ListSelectionModal from "../ListSelectionModal/ListSelectionModal";

import "./styles.scss";
import {
  InvestorPrivilegeData,
  ProjectPrivilegeData,
  ReportingPrivilegeData,
  SPVManagerPrivilegeData,
  User,
} from "models/user.models";
import { Project } from "models/project.model";
import { cloneDeep } from "lodash";
import { Investor } from "modules/limited-partners/models/investor.model";
import UserSettingsSPVManager from "../UserSettingsSPVManager/UserSettingsSPVManager";

interface UserSettingsProps {
  modifiedCurrUser: User;
  setModifiedCurrUser: any;
  projects: Project[];
  setFormDirty: any;
  formDirty: string[];
  investors: Investor[];
  sendEmailInvitation: any;
}

const UserSettings = ({
  modifiedCurrUser,
  setModifiedCurrUser,
  projects,
  setFormDirty,
  formDirty,
  investors,
  sendEmailInvitation
}: UserSettingsProps) => {
  const [tabValue, setTabValue] = useState<number>(0);
  const [openListModal, setOpenListModal] = useState<string>("");

  const [listSelection, setListSelection] = useState<any>([]);

  const validSelection = () => {
    // create new survey-sdg access
    let newPrivilegeList: any = [];
    let updatedUser = cloneDeep(modifiedCurrUser);

    if (
      updatedUser.privileges === undefined ||
      updatedUser.privileges === null
    ) {
      switch (openListModal) {
        case "project":
          updatedUser.privileges = {
            "survey-sdg": [],
          };
          break;
        case "project-reporting":
          updatedUser.privileges = {
            reporting: [],
          };
          break;
        case "project-spv":
          updatedUser.privileges = {
            "spv-manager": [],
          };
          break;
        case "investor":
          updatedUser.privileges = {
            "lp-dashboard": [],
          };
          break;
      }
    }

    switch (openListModal) {
      case "project":
        if (
          updatedUser.privileges["survey-sdg"] === undefined ||
          updatedUser.privileges["survey-sdg"] === null
        ) {
          updatedUser.privileges["survey-sdg"] = [];
        }
        break;
      case "project-reporting":
        if (
          updatedUser.privileges["reporting"] === undefined ||
          updatedUser.privileges["reporting"] === null
        ) {
          updatedUser.privileges["reporting"] = [];
        }
        break;
      case "project-spv":
        if (
          updatedUser.privileges["spv-manager"] === undefined ||
          updatedUser.privileges["spv-manager"] === null
        ) {
          updatedUser.privileges["spv-manager"] = [];
        }
        break;
      case "investor":
        if (
          updatedUser.privileges["lp-dashboard"] === undefined ||
          updatedUser.privileges["lp-dashboard"] === null
        ) {
          updatedUser.privileges["lp-dashboard"] = [];
        }
        break;
    }

    listSelection.map((sp: any) => {
      let roleExist: any = "";
      switch (openListModal) {
        case "project":
          roleExist = updatedUser.privileges["survey-sdg"].find(
            (up: any) => up.details["projectCode"] === sp.projectCode
          );
          break;
        case "project-reporting":
          roleExist = updatedUser.privileges["reporting"].find(
            (up: any) => up.details["projectCode"] === sp.projectCode
          );
          break;
        case "project-spv":
          roleExist = updatedUser.privileges["spv-manager"].find(
            (up: any) => up.details["projectCode"] === sp.projectCode
          );
          break;
        case "investor":
          roleExist = updatedUser.privileges["lp-dashboard"].find(
            (up: any) => up.details["investorId"] === sp.investorId
          );
          break;
      }

      if (!!roleExist) {
        roleExist.role = sp.role;
        newPrivilegeList.push(roleExist);
      } else {
        let newPrivilege: any;
        switch (openListModal) {
          case "project":
            newPrivilege = {
              id: null,
              role: sp.role,
              module: "survey-sdg",
              details: new ProjectPrivilegeData(),
            };
            newPrivilege.details["projectCode"] = sp.projectCode;
            newPrivilege.details["projectId"] = sp.projectId;
            break;
          case "project-reporting":
            newPrivilege = {
              id: null,
              role: sp.role,
              module: "reporting",
              details: new ReportingPrivilegeData(),
            };
            newPrivilege.details["projectCode"] = sp.projectCode;
            newPrivilege.details["projectId"] = sp.projectId;
            break;
          case "project-spv":
            newPrivilege = {
              id: null,
              role: sp.role,
              module: "spv-manager",
              details: new SPVManagerPrivilegeData(),
            };
            newPrivilege.details["projectCode"] = sp.projectCode;
            newPrivilege.details["projectId"] = sp.projectId;
            break;
          case "investor":
            newPrivilege = {
              id: null,
              role: sp.role,
              module: "lp-dashboard",
              details: new InvestorPrivilegeData(),
            };
            newPrivilege.details["investorId"] = sp.investorId;
            break;
        }
        newPrivilegeList.push(newPrivilege);
      }
    });

    switch (openListModal) {
      case "project":
        updatedUser.privileges["survey-sdg"] = newPrivilegeList;
        break;
      case "project-reporting":
        updatedUser.privileges["reporting"] = newPrivilegeList;
        break;
      case "project-spv":
        updatedUser.privileges["spv-manager"] = newPrivilegeList;
        break;
      case "investor":
        updatedUser.privileges["lp-dashboard"] = newPrivilegeList;
        break;
    }

    setModifiedCurrUser(updatedUser);
    setOpenListModal("");
  };

  return (
    <>
      <div className="user-settings">
        <div className="tabs">
          <h2 className="section-title">
            <i className="fas fa-sliders-h"></i> User settings for...
          </h2>
          <div className="tabs-container">
            <button
              className={`tabs-item ${0 === tabValue ? "current" : ""}`}
              onClick={() => setTabValue(0)}
              disabled={modifiedCurrUser.platformRole !== "stakeholder"}
            >
              {(!!modifiedCurrUser.privileges &&
                !!modifiedCurrUser.privileges["survey-sdg"]) ||
              modifiedCurrUser.platformRole !== "stakeholder" ? (
                <i className="fas fa-lock-open"></i>
              ) : (
                <i className="fas fa-lock"></i>
              )}
              <span>Survey Sdg</span>
            </button>

            <button
              className={`tabs-item ${1 === tabValue ? "current" : ""}`}
              onClick={() => setTabValue(1)}
              disabled={modifiedCurrUser.platformRole !== "stakeholder"}
            >
              {(!!modifiedCurrUser.privileges &&
                !!modifiedCurrUser.privileges["lp-dashboard"] &&
                modifiedCurrUser.privileges["lp-dashboard"].length > 0) ||
              modifiedCurrUser.platformRole !== "stakeholder" ? (
                <i className="fas fa-lock-open"></i>
              ) : (
                <i className="fas fa-lock"></i>
              )}
              <span>LP Dashboard</span>
            </button>

            <button
              className={`tabs-item ${2 === tabValue ? "current" : ""}`}
              onClick={() => setTabValue(2)}
              disabled={modifiedCurrUser.platformRole !== "stakeholder"}
            >
              {(!!modifiedCurrUser.privileges &&
                !!modifiedCurrUser.privileges["esg-tool"] &&
                modifiedCurrUser.privileges["esg-tool"].length > 0) ||
              modifiedCurrUser.platformRole !== "stakeholder" ? (
                <i className="fas fa-lock-open"></i>
              ) : (
                <i className="fas fa-lock"></i>
              )}
              <span>ESG Tools</span>
            </button>

            <button
              className={`tabs-item ${3 === tabValue ? "current" : ""}`}
              onClick={() => setTabValue(3)}
              disabled={modifiedCurrUser.platformRole !== "stakeholder"}
            >
              {(!!modifiedCurrUser.privileges &&
                !!modifiedCurrUser.privileges["reporting"]) ||
              modifiedCurrUser.platformRole !== "stakeholder" ? (
                <i className="fas fa-lock-open"></i>
              ) : (
                <i className="fas fa-lock"></i>
              )}
              <span>Reporting</span>
            </button>

            <button
              className={`tabs-item ${4 === tabValue ? "current" : ""}`}
              onClick={() => setTabValue(4)}
              disabled={modifiedCurrUser.platformRole !== "stakeholder"}
            >
              {(!!modifiedCurrUser.privileges &&
                !!modifiedCurrUser.privileges["user-manager"] &&
                modifiedCurrUser.privileges["user-manager"].length > 0) ||
              modifiedCurrUser.platformRole !== "stakeholder" ? (
                <i className="fas fa-lock-open"></i>
              ) : (
                <i className="fas fa-lock"></i>
              )}
              <span>User Management</span>
            </button>

            <button
              className={`tabs-item ${5 === tabValue ? "current" : ""}`}
              onClick={() => setTabValue(5)}
              disabled={modifiedCurrUser.platformRole !== "stakeholder"}
            >
              {(!!modifiedCurrUser.privileges &&
                !!modifiedCurrUser.privileges["spv-manager"] &&
                modifiedCurrUser.privileges["spv-manager"].length > 0) ||
              modifiedCurrUser.platformRole !== "stakeholder" ? (
                <i className="fas fa-lock-open"></i>
              ) : (
                <i className="fas fa-lock"></i>
              )}
              <span>SPV Manager</span>
            </button>
          </div>
        </div>
        <div className="content">
          {modifiedCurrUser.platformRole === "stakeholder" ? (
            <>
              {tabValue === 0 && (
                <UserSettingsSurveySdg
                  modifiedCurrUser={modifiedCurrUser}
                  setModifiedCurrUser={setModifiedCurrUser}
                  setOpenListModal={setOpenListModal}
                  projects={projects}
                  setFormDirty={setFormDirty}
                  formDirty={formDirty}
                  sendEmailInvitation={sendEmailInvitation}
                />
              )}

              {tabValue === 1 && (
                <UserSettingsLPDashboard
                  modifiedCurrUser={modifiedCurrUser}
                  setModifiedCurrUser={setModifiedCurrUser}
                  investors={investors}
                  setOpenListModal={setOpenListModal}
                  setFormDirty={setFormDirty}
                  formDirty={formDirty}
                />
              )}

              {tabValue === 2 && (
                <UserSettingsESGTools
                  modifiedCurrUser={modifiedCurrUser}
                  setModifiedCurrUser={setModifiedCurrUser}
                  setFormDirty={setFormDirty}
                  formDirty={formDirty}
                />
              )}

              {tabValue === 3 && (
                <UserSettingsReporting
                  modifiedCurrUser={modifiedCurrUser}
                  setModifiedCurrUser={setModifiedCurrUser}
                  setOpenListModal={setOpenListModal}
                  projects={projects}
                  setFormDirty={setFormDirty}
                  formDirty={formDirty}
                />
              )}

              {tabValue === 4 && (
                <UserSettingsUserManager
                  modifiedCurrUser={modifiedCurrUser}
                  setModifiedCurrUser={setModifiedCurrUser}
                  setFormDirty={setFormDirty}
                  formDirty={formDirty}
                />
              )}

              {tabValue === 5 && (
                <UserSettingsSPVManager
                  modifiedCurrUser={modifiedCurrUser}
                  setModifiedCurrUser={setModifiedCurrUser}
                  setOpenListModal={setOpenListModal}
                  projects={projects}
                  setFormDirty={setFormDirty}
                  formDirty={formDirty}
                />
              )}
            </>
          ) : (
            <>
              {modifiedCurrUser.platformRole === "technical-admin"
                ? "Technical Admin"
                : "Global Admin"}{" "}
              has access to all the existing modules.
            </>
          )}
        </div>
      </div>

      <ListSelectionModal
        openListModal={openListModal}
        setOpenListModal={setOpenListModal}
        list={
          openListModal === "project" ||
          openListModal === "project-reporting" ||
          openListModal === "project-spv"
            ? projects
            : investors
        }
        modifiedCurrUser={modifiedCurrUser}
        listSelection={listSelection}
        setListSelection={setListSelection}
        validSelection={validSelection}
        setFormDirty={setFormDirty}
        formDirty={formDirty}
      ></ListSelectionModal>
    </>
  );
};

export default UserSettings;

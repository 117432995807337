import { Project } from "models/project.model";
import React from "react";

interface SPVManagerFormHeadProps {
  modifiedCurrProject: Project;
  isEditMode: boolean;
  setModifiedCurrProject: any;
  formDirty: string[];
  setFormDirty: any;
}

const SPVManagerFormHead = ({
  isEditMode,
  modifiedCurrProject,
  setModifiedCurrProject,
  formDirty,
  setFormDirty,
}: SPVManagerFormHeadProps) => {
  const handleChange = (event: any) => {
    let newP: any = { ...modifiedCurrProject };

    const { name, value } = event.target;
    switch (name) {
      case "projectCode":
      case "projectName":
      case "projectShortName":
      case "latitude":
      case "longitude":
      case "trajectory":
      case "currentIRR":
        newP[name] = value;
        break;
    }
    setFormDirty((formDirty: string[]) => [...formDirty, name]);
    setModifiedCurrProject(newP);
  };

  const goToSurvey = (project: Project) => {
    localStorage.setItem("current_project", JSON.stringify(project));
    if ("development" !== process.env.NODE_ENV) {
      document.location.href = "/survey-sdg/survey/dashboard";
    } else {
      console.log(
        "--- FEATURE NOT AVAILABLE IN LOCAL, please open : http://localhost:4200/survey/dashboard"
      );
    }
  };

  return (
    <>
      <div className="left">
        {!!isEditMode && (
          <div className="title">{modifiedCurrProject?.projectName}</div>
        )}
        <div className="row row-input">
          <label className="label" htmlFor="project-id">
            Project code (eFront) *
          </label>
          <input
            id="project-id"
            type="text"
            placeholder="ex: US050CO"
            value={modifiedCurrProject.projectCode}
            name="projectCode"
            readOnly={isEditMode ? true : false}
            onChange={(e) => handleChange(e)}
            className={
              formDirty.find((i) => i === "projectCode") ? "is-modified" : ""
            }
          />
        </div>
        <div className="row row-input">
          <label className="label" htmlFor="project-name">
            Project name *
          </label>
          <input
            id="project-name"
            type="text"
            placeholder="ex: Central 70"
            defaultValue={modifiedCurrProject.projectName}
            required
            name="projectName"
            onChange={(e) => handleChange(e)}
            className={
              formDirty.find((i) => i === "projectName") ? "is-modified" : ""
            }
          />
        </div>
        <div className="row row-input">
          <label className="label" htmlFor="project-shortname">
            Project shortname *
          </label>
          <input
            id="project-shortname"
            type="text"
            placeholder="ex: C70"
            defaultValue={modifiedCurrProject.projectShortName}
            required
            name="projectShortName"
            onChange={(e) => handleChange(e)}
            className={
              formDirty.find((i) => i === "projectShortName")
                ? "is-modified"
                : ""
            }
          />
        </div>
        <div className="row row-input">
          <label className="label" htmlFor="project-latitude">
            Project latitude
          </label>
          <input
            id="project-latitude"
            type="number"
            placeholder="ex: 48.856613"
            defaultValue={modifiedCurrProject.latitude}
            // required
            name="latitude"
            onChange={(e) => handleChange(e)}
            className={
              formDirty.find((i) => i === "latitude")
                ? "is-modified"
                : ""
            }
          />
        </div>
        <div className="row row-input">
          <label className="label" htmlFor="project-longitude">
            Project longitude
          </label>
          <input
            id="project-longitude"
            type="number"
            placeholder="ex: 2.352222"
            defaultValue={modifiedCurrProject.longitude}
            // required
            name="longitude"
            onChange={(e) => handleChange(e)}
            className={
              formDirty.find((i) => i === "longitude")
                ? "is-modified"
                : ""
            }
          />
        </div>

        <div className="row row-input">
          <label className="label" htmlFor="project-shortname">
            Project current IRR (%)
          </label>
          <input
            id="project-current-irr"
            type="number"
            placeholder="ex: 12"
            defaultValue={modifiedCurrProject.currentIRR}
            // required
            name="currentIRR"
            onChange={(e) => handleChange(e)}
            className={
              formDirty.find((i) => i === "projectCurrentIrr")
                ? "is-modified"
                : ""
            }
          />
        </div>

        <div className="row row-input">
          <label className="label" htmlFor="project-shortname">
            Project Trajectory (°C)
          </label>
          <input
            id="project-trajectory"
            type="number"
            placeholder="ex: 22.3"
            defaultValue={modifiedCurrProject.trajectory}
            // required
            name="trajectory"
            onChange={(e) => handleChange(e)}
            className={
              formDirty.find((i) => i === "projectTrajectory")
                ? "is-modified"
                : ""
            }
          />
        </div>
      </div>
      {!!isEditMode && modifiedCurrProject.hasSurvey === true && (
        <div className="right">
          <button
            className="btn btn-select btn-primary"
            type="button"
            onClick={() => goToSurvey(modifiedCurrProject)}
          >
            <span className="btn-label">Go to survey</span>
            <span className="btn-icon">
              <i className={`fas fa-arrow-right`}></i>
            </span>
          </button>
        </div>
      )}
    </>
  );
};

export default SPVManagerFormHead;


import React, { useEffect, useState } from "react";
import { RootState, ThunkDispatch } from "models/store.models";
import {
  deleteFile,
  // prepareFile,
  renameFile,
} from "modules/shared/services/spv-reporting.service";
import { formattedDate } from "modules/shared/utils/format";
import { connect } from "react-redux";
// import Resumable from "resumablejs";
import { cloneDeep } from "lodash";
// import config from "modules/config";

import { DateTime } from "luxon";
import WarnModal from "modules/shared/components/WarnModal/WarnModal";
import UploadConfig, { MaxChunkSize, UploadFile } from "modules/shared/services/upload.service";
import CalendarDate from "../Elements/CalendarDate/CalendarDate";

interface ProjectReportingDocumentsItemLeaderProps {
  doc: any;
  user: any;
  directory: any;
  template: any;
  file: any;
  accessToken: string;
  index: number;
  projectGroup: string;
}
const mapStateToProps = ({ session }: RootState) => ({accessToken: session?.sessionData?.accessToken ? session?.sessionData?.accessToken : "", user: session?.sessionData?.userData});

const mapDispatchToProps = (dispatch: ThunkDispatch) => ({});

const maxChunkSize: number = 1000000; //1Mo

const ProjectReportingDocumentsItemLeader = ({doc, user, directory, template, file, accessToken, index, projectGroup}: ProjectReportingDocumentsItemLeaderProps) => {

  const [reportingFile, setReportingFile] = useState<any>(file);

  const [uploading, setUploading] = useState<boolean>(false);

  const [maxChunks, setMaxChunks] = useState<number>(0);

  // const [uploadedChunks, setUploadedChunks] = useState<number>(0);

  const [currentFile, setCurrentFile] = useState<any>({filename: "", file: null});

  const selectUploadFile = (code: string, file: any) => {
    code = code.replace(/(\.|-)/, "");

    let filename: string = file.name;
    if (!file.name.match(/^\d{1,2}(\.|-)/)) {
      filename = `${code}-${file.name}`;
    } else if (!file.name.match(new RegExp(`^${code}(-|\.)`, "gi"))) {
      filename = file.name.replace(/^\d{1,2}(-|\.)/gi, `${code}-`);
    }
    setCurrentFile({file, filename});
  };

  const processUpload = async () => {
    const totalFiles = Math.ceil(currentFile.file.size / MaxChunkSize);
    UploadConfig.accessToken = accessToken;
    UploadConfig.maxChunks = totalFiles;
    UploadConfig.uploading = true;
    UploadFile(directory, { filename: currentFile.filename, file: currentFile.file }, (data: any) => {
      setUploading(false);
      setReportingFile(data);
    });
  };

  const deleteFileFromServer = async () => {
    // if (window.confirm(`Would you like to delete ${doc.filename} file ?`)) {
      const hasBeenDeleted: boolean = await deleteFile(accessToken, reportingFile.id);
      if (!!hasBeenDeleted) {
        setReportingFile(null);
      }
    // }
  };

  const renameFileFromServer = async () => {
    const newName: string = reportingFile.name.replace(/\.([a-z]+)$/, `-valid.$1`);
    const done: boolean = await renameFile(accessToken, reportingFile.id, newName);
    if(!!done){
      const tmpReportingFile: any = cloneDeep(reportingFile);
      tmpReportingFile.name = newName;
      setReportingFile(tmpReportingFile);
    }
  };

  useEffect(() => {
    if (!!file) {
      setReportingFile(file);
    }
  }, [file]);

  // If select a file to upload
  useEffect(() => {
    if (!!currentFile.file) {
      if (!!directory) {
        const totalFiles = Math.ceil(currentFile.file.size / maxChunkSize);
        setMaxChunks(totalFiles);
        setUploading(true);
      }
    }
  }, [currentFile]);

  // If a max chunks has been determined
  useEffect(() => {
    if (!!uploading) {
      processUpload();
    }
  }, [uploading]);

  return (
    <li className={`row ${!!reportingFile ? (!!reportingFile.name.match(/-valid\./) ? "is-filled" : "is-modified") : ""}`}>
      <div className="infos">
        <h3>{doc.filename}</h3>
        {!!doc.link &&
          <div className="link-guide">
            <i className={`fas fa-info-circle`}></i>
            <a href={doc.link} target="_blank" rel="noopener noreferrer">
              How to update this document
            </a>
          </div>
        }
        <p>{doc.description}</p>
      </div>

      <div className="due-date">
        <CalendarDate
          data={doc}
          projectGroup={projectGroup}
        />
        {/* <span className={`${DateTime.local() > DateTime.fromISO(doc.group2DueDate) ? "passed" : DateTime.local() >= DateTime.fromISO(doc.group2DueDate).minus({ days: 7 }) ? "seven-days-left" : "" }`}
        onClick={() => addToCalendar(`${doc.filename} due date`, ("group1" === projectGroup && !!doc.group1DueDate) ? doc.group1DueDate : !!doc.group2DueDate ? doc.group2DueDate : null )}
        >
          {!!projectGroup &&
            ("group1" === projectGroup && !!doc.group1DueDate) ? 
              formattedDate(doc.group1DueDate)
            : !!doc.group2DueDate
              ? formattedDate(doc.group2DueDate)
              : "———"}
        </span> */}
      </div>

      <div className="download">
        {!doc.noTemplate ? (
          <>
            {!!template && !!template.file ? (
              <a href={template.file.download} download>
                <button className="btn btn-select btn-primary" type="button">
                  <span className="btn-icon">
                    <i className={`fas fa-download`}></i>
                  </span>
                </button>
              </a>
            ) : (
              <button
                className="btn btn-select btn-primary"
                type="button"
                disabled
              >
                <span className="btn-icon">
                  <i className={`fas fa-download`}></i>
                </span>
              </button>
            )}
          </>
        ) : (!!doc.noTemplate && !!template && !!template.file) &&
          <a href={template.file.download} download>
            <button className="btn btn-select btn-primary" type="button">
              <span className="btn-icon">
                <i className={`fas fa-download`}></i>
              </span>
            </button>
          </a>
      }
      </div>

      <div className="upload">
        {!!uploading ? (
          <div className="is-uploading">
            <i className={`fas fa-spinner`}></i>
          </div>
        ) : !!reportingFile ? (
          <div className="uploaded">
            <i className={`fas fa-file`}></i>
          </div>
        ) : (
          (!!doc.noTemplate || (!doc.noTemplate && !!template)) && (
            <>
              <input
                type="file"
                id={`upload-${doc.code}`}
                hidden
                onChange={(e: any) =>
                  selectUploadFile(doc.code, e.target.files[0])
                }
              />
              <label
                htmlFor={`upload-${doc.code}`}
                className="btn btn-select btn-primary"
              >
                <span className="btn-icon">
                  <i className={`fas fa-upload`}></i>
                </span>
              </label>
            </>
          )
        )}
      </div>
      <div className="actions">
        {!!reportingFile && (
          <>
            <a className="action" href={reportingFile.file.download} download>
              <span>Download reporting</span>
              <i className={`fas fa-download`}></i>
            </a>
            <button className="action" type="button"
              // onClick={deleteFileFromServer} TODO ANTHO (actionYes in warnModal)
              data-uk-toggle={`#warnDelete${index}`}
            >
              <span>Delete reporting</span>
              <i className={`fas fa-trash`}></i>
            </button>
          </>
        )}
      </div>

      <button
        disabled={"undefined" === typeof reportingFile || (!!reportingFile && !!reportingFile.name.match(/-valid\.[a-z]+$/))}
        className="btn btn-select btn-primary btn-review"
        type="button"
        onClick={renameFileFromServer}
      >
        <span className="btn-label">Validate for Meridiam HUB review</span>
        <span className="btn-icon">
          <i className="fas fa-arrow-right"></i>
        </span>
      </button>
      <WarnModal
        id={`warnDelete${index}`}
        content="Do you really want to delete this reporting?"
        actionYes={deleteFileFromServer}
      />
    </li>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectReportingDocumentsItemLeader);

import React, { useEffect, useState } from "react";
import { CarbonFootprint } from "../../models/carbon-footprint.model";
import DownloadPdfButton from "../../../shared/components/Ui/Buttons/DownloadPdfButton/DownloadPdfButton";
import "./styles.scss";
import {
  transformNumberGMK,
  getProjectTypeIcon,
} from "../../../shared/utils/string-utils";
import Loader from "modules/shared/components/Loader/Loader";
import { exportAsExcelFile } from "modules/shared/services/excel.service";
import CarbonFootPrintTableHead from "./CarbonFootPrintTableHead";

type Props = {
  carbonFootprint: CarbonFootprint[] | undefined;
  year: number;
  isImpactIndicatorLoading: boolean;
  investorType: string;
};

const CarbonFootPrintSection: React.FC<Props> = ({
  carbonFootprint,
  year,
  isImpactIndicatorLoading,
  investorType
}) => {

  const today = new Date().toUTCString();
  const [extendedPdfMode, setPdfMode] = useState<any>(null);
  const [isDownloadingXLS, setIsDownloadingXLS] = useState<any>(false);
  const [isPrint, setIsPrint] = useState<boolean>(false);

  const [allFundsScopes1_2, setAllFundsScopes1_2] = useState<number>(0);
  const [allFundsScopes1_2_3, setAllFundsScopes1_2_3] = useState<number>(0);

  const downloadCFP = () => {

    if (isDownloadingXLS) {
      return;
    }

    setIsDownloadingXLS(true);

    const datas: any = [];
    !!carbonFootprint && carbonFootprint.forEach((cfp) => {
      let val;
      if (year === 2019) {
        val = {
          "name": cfp.fundName,
          "Weighted Scopes 1 + 2 (tC02e/year)": (!!cfp.scopes1_2 && cfp.scopes1_2 !== "UA" && cfp.scopes1_2 !== "N/A") ? parseFloat(cfp.scopes1_2) : !!cfp.scopes1_2 ? cfp.scopes1_2 : "N/A",
          "Weighted Scopes 1, 2, 3 (tC02e/year)": (!!cfp.scopes1_2_3 && cfp.scopes1_2_3 !== "UA" && cfp.scopes1_2_3 !== "N/A") ? parseFloat(cfp.scopes1_2_3) : !!cfp.scopes1_2_3 ? cfp.scopes1_2_3 : "N/A",
          "Trajectory (°C)": cfp.trajectory
        };
      } else {
        if(investorType === "infra"){
          if(year >= 2022){
            val = {
              "name": cfp.fundName,
              "Absolute Scope 1 (tCO2e)": (!!cfp.scope1 && cfp.scope1 !== "N/A") ? parseFloat(cfp.scope1) : "N/A",
              "Absolute Scope 2 (tCO2e)": (!!cfp.scope2 && cfp.scope2 !== "N/A") ? parseFloat(cfp.scope2) : "N/A",
              "Absolute Scope 3 without construction (tCO2e)": (!!cfp.scope3 && cfp.scope3 !== "N/A") ? parseFloat(cfp.scope3) : "N/A",
              "CAPEX in Euro (M)": (!!cfp.emission && cfp.emission !== "N/A") ? parseFloat(cfp.emission) : "N/A",
              "Trajectory (°C)": (!!cfp.trajectory && cfp.trajectory !== "N/A") ? parseFloat(cfp.trajectory) : "N/A"
            };
          } else {
            val = {
              "name": cfp.fundName,
              "Absolute Scopes 1, 2 & 3 emissions": (!!cfp.absoluteEmissions && cfp.absoluteEmissions !== "N/A" && cfp.absoluteEmissions !== "UA") ? parseFloat(cfp.absoluteEmissions) : (cfp.absoluteEmissions ? cfp.absoluteEmissions : "N/A"),
              "Meridiam CAPEX Investment Share (%)": "",
              "Concession duration (years)": "",
              "Weighted Scopes 1, 2, 3 (tC02e/year)": (!!cfp.scopes1_2_3 && cfp.scopes1_2_3 !== "UA" && cfp.scopes1_2_3 !== "N/A") ? parseFloat(cfp.scopes1_2_3) : !!cfp.scopes1_2_3 ? cfp.scopes1_2_3 : "N/A",
              "Trajectory (°C)": cfp.trajectory
            };
          }
        } else {
          val = {
            "name": cfp.fundName,
            "Absolute Scopes 1, 2 & 3 emissions": (!!cfp.absoluteEmissions && cfp.absoluteEmissions !== "N/A" && cfp.absoluteEmissions !== "UA") ? parseFloat(cfp.absoluteEmissions) : (cfp.absoluteEmissions ? cfp.absoluteEmissions : "N/A"),
            "Weighted Scopes 1, 2, 3 (tC02e/year)": (!!cfp.scopes1_2_3 && cfp.scopes1_2_3 !== "UA" && cfp.scopes1_2_3 !== "N/A") ? parseFloat(cfp.scopes1_2_3) : !!cfp.scopes1_2_3 ? cfp.scopes1_2_3 : "N/A",
            "Avoided (tCO2e/year)": !!cfp.avoided ? parseFloat(cfp.avoided) : "N/A"
          };
        }
      }
      datas.push(val);

      cfp.projects.forEach((pro) => {
        let val;
        if (year === 2019) {
          val = {
            "name": pro.projectName,
            "Weighted Scopes 1 + 2 (tC02e/year)": (!!pro.scopes1_2 && pro.scopes1_2 !== "UA" && pro.scopes1_2 !== "N/A") ? transformNumberGMK(parseFloat(pro.scopes1_2), 1) : !!pro.scopes1_2 ? pro.scopes1_2 : "N/A",
            "Weighted Scopes 1, 2, 3 (tC02e/year)": (!!pro.scopes1_2_3 && pro.scopes1_2_3 !== "UA" && pro.scopes1_2_3 !== "N/A") ? transformNumberGMK(parseFloat(pro.scopes1_2_3), 1) : !!pro.scopes1_2_3 ? pro.scopes1_2_3 : "N/A",
            "Trajectory (°C)": pro.trajectory
          };
        } else {
          if(investorType === "infra"){
            if(year >= 2022){
              val = {
                "name": pro.projectName,
                "Absolute Scope 1 (tCO2e)": (!!pro.scope1 && pro.scope1 !== "N/A") ? parseFloat(pro.scope1) : "N/A",
                "Absolute Scope 2 (tCO2e)": (!!pro.scope2 && pro.scope2 !== "N/A") ? parseFloat(pro.scope2) : "N/A",
                "Absolute Scope 3 without construction (tCO2e)": (!!pro.scope3 && pro.scope3 !== "N/A") ? parseFloat(pro.scope3) : "N/A",
                "CAPEX in Euro (M)": (!!pro.emission && pro.emission !== "N/A") ? parseFloat(pro.emission) : "N/A",
                "Trajectory (°C)": (!!pro.trajectory && pro.trajectory !== "N/A") ? parseFloat(pro.trajectory) : "N/A"
              };
            } else {
              val = {
                "name": pro.projectName,
                "Absolute Scopes 1, 2 & 3 emissions": !!pro.absoluteScopes ? transformNumberGMK(pro.absoluteScopes, 1) : "N/A",
                "Meridiam CAPEX Investment Share (%)": pro.meridiamCapex,
                "Concession duration (years)": pro.concessionDuration,
                "Weighted Scopes 1, 2, 3 (tC02e/year)": (!!pro.scopes1_2_3 && pro.scopes1_2_3 !== "UA" && pro.scopes1_2_3 !== "N/A") ? transformNumberGMK(parseFloat(pro.scopes1_2_3), 1) : !!pro.scopes1_2_3 ? pro.scopes1_2_3 : "N/A",
                "Trajectory (°C)": pro.trajectory
              };
            }
          } else {
            val = {
              "name": pro.projectName,
              "Absolute Scopes 1, 2 & 3 emissions": !!pro.absoluteScopes ? transformNumberGMK(pro.absoluteScopes, 1) : "N/A",
              "Weighted Scopes 1, 2, 3 (tC02e/year)": (!!pro.scopes1_2_3 && pro.scopes1_2_3 !== "UA" && pro.scopes1_2_3 !== "N/A") ? transformNumberGMK(parseFloat(pro.scopes1_2_3), 1) : !!pro.scopes1_2_3 ? pro.scopes1_2_3 : "N/A",
              "Avoided (tCO2e/year)": !!pro.avoided ? transformNumberGMK(parseFloat(pro.avoided), 1) : "N/A"
            };
          }
        }
        datas.push(val);
      });
    });

    exportAsExcelFile(datas, "Carbon_Footprint_" + today);
    setIsDownloadingXLS(false);
  }

  useEffect(() => {
    if (!!carbonFootprint && year === 2019) {
      let scopes1_2 = carbonFootprint.reduce(
        (funds, fund) => funds + Number(fund.scopes1_2),
        0
      );
      setAllFundsScopes1_2(scopes1_2);

      let scopes1_2_3 = carbonFootprint.reduce(
        (funds, fund) => funds + Number(fund.scopes1_2_3),
        0
      );
      setAllFundsScopes1_2_3(scopes1_2_3);
    }
  }, [carbonFootprint]);


  const htmlTableHead = ``;


  return (
    <div id="cmpCarbonFootprint" className="tool tool_lp tool_carbon-footprint">
      <div className="tool_lp_inner">
        <div className="tool__title">
          <h2 className="white">Carbon Footprint</h2>
          <h3>Based on a Scope 3 methodology</h3>
          <h4 className="clr-white">
            <small>As of Q4</small> {year}
            {/* {investorType === "gigf" ? (year+1) : year} */}
          </h4>
        </div>
        {isImpactIndicatorLoading
          ?
          <Loader className="on" color="white" size="small" />
          :
          carbonFootprint ? (
            <>
              <div className="tool__content white">
                <div className="tool__legends legends-smartphone" data-print="no">
                  <div className="legend">
                    <span className="legend_label">
                      <i>Scroll laterally to show data</i>
                    </span>
                    <span className="legend_icon">
                      <svg className="ui ui-svg ui-arrow-swipe">
                        <use xlinkHref="#arrowSwipe"></use>
                      </svg>
                    </span>
                  </div>
                </div>

                <div className="table table-carbon-footprint">
                  <div className="table_inner">
                    <CarbonFootPrintTableHead
                      year={year}
                      printHeaderPagesAfterFirstPage={false}  
                      investorType={investorType}
                    />
                    {/* <CarbonFootPrintTableHead
                      year={year}
                      printHeaderPagesAfterFirstPage={true}
                    /> */}


                    <div
                      className="table__row row__body"
                      data-uk-accordion="multiple: true; toggle: > * > .subrow-main ; content: > * > .subrow-other; "
                    >
                      {/* MAP THROUGH FUND */}
                      {carbonFootprint.map((fund, index) => {
                        return (
                          <div
                            key={`row_fund_` + index}
                            className={
                              "table__row-child" +
                              (carbonFootprint.length === 1 && index === 0
                                ? " uk-open"
                                : "")
                            }
                          >
                            <div className="fund fund-main">
                              <div className="subrow subrow-main">
                                <div className={`table__column fixed column-fund ${(investorType === "gigf") ? 'gigf' : ''}`}>
                                  <div className="line">
                                    <div
                                      className="fund_name"
                                      data-uk-tooltip={
                                        "title:" + fund.fundName + ";"
                                      }
                                    >
                                      <span>{fund.fundName}</span>
                                    </div>
                                    <div className="fund_subprojects">
                                      <span className="project-number">
                                        {fund.projects.length} project
                                        {fund.projects.length > 1 && <>s</>}
                                      </span>
                                      <span
                                        className="ui ui-indication close"
                                        data-print="no"
                                      >
                                        click to view
                                      </span>
                                      <span
                                        className="ui ui-indication open"
                                        data-print="no"
                                      >
                                        click to close
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                {/* Absolute Scopes 1, 2 & 3 emissions */}
                                {year > 2019 && (year < 2022 || investorType === "gigf") &&
                                  <div className={`table__column column-abs-emission ${(investorType === "gigf") ? 'gigf' : ''}`}>
                                    <span>
                                      <b>
                                        {(!!fund.absoluteEmissions && fund.absoluteEmissions !== "N/A" && fund.absoluteEmissions !== "UA")
                                          ?
                                          transformNumberGMK(parseFloat(fund.absoluteEmissions), 1)
                                          :
                                          fund.absoluteEmissions ? fund.absoluteEmissions : "N/A"
                                        }
                                      </b>
                                    </span>
                                  </div>
                                }

                                {/* Meridiam CAPEX Investment Share (%) */}
                                {year > 2019 && year < 2022 && investorType === "infra" &&
                                  <div className="table__column column-capex">
                                    <span>-</span>
                                  </div>
                                }

                                {/* Concession duration (years) */}
                                {year > 2019 && year < 2022 && investorType === "infra" &&
                                  <div className="table__column column-concession">
                                    <span>-</span>
                                  </div>
                                }

                                {/* Scope 1_2 */}
                                {year === 2019 &&
                                  <div className="table__column column-emission">
                                    {!!fund.scopes1_2 && fund.scopes1_2 !== "N/A" && fund.scopes1_2 !== "UA" &&
                                      <span
                                        className="bar"
                                        data-percent={(
                                          100 -
                                          ((allFundsScopes1_2 -
                                            parseFloat(fund.scopes1_2)) /
                                            allFundsScopes1_2) *
                                          100
                                        ).toFixed(0)}
                                      ></span>
                                    }
                                    <span>
                                      <b>
                                        {(!!fund.scopes1_2 && fund.scopes1_2 !== "UA" && fund.scopes1_2 !== "N/A")
                                          ?
                                          transformNumberGMK(
                                            parseFloat(fund.scopes1_2),
                                            1
                                          )
                                          : (!!fund.scopes1_2 ? fund.scopes1_2 : "N/A")}
                                      </b>
                                    </span>
                                  </div>
                                }

                                {/* Scope 1_2_3 */}
                                {(year < 2022 || investorType === "gigf") &&
                                  <div className={`table__column column-carbon-fp ${year === 2019 ? 'larger' : ''} ${(investorType === "gigf") ? 'gigf' : ''}`}>
                                    <div
                                      className={`${year === 2019 ? 'bar_wrapper' : ""} ${year === 2019 ? (fund.scopes1_2_3 !== "UA" && fund.scopes1_2_3 !== "N/A" && parseFloat(fund.scopes1_2_3) < 0 ? "negative" : "positive") : ""} ${year === 2019 ? (!!fund.scopes1_2_3 && fund.scopes1_2_3 !== "UA" && fund.scopes1_2_3 !== "N/A" ? " with-data" : " without-data") : ""}`}>
                                      {year === 2019 && !!fund.scopes1_2_3 && fund.scopes1_2_3 !== "N/A" && fund.scopes1_2_3 !== "UA" &&
                                        <div className="data"><span
                                          className="bar"
                                          data-percent={Math.abs(
                                            100 -
                                            ((allFundsScopes1_2_3 -
                                              parseFloat(fund.scopes1_2_3)) /
                                              allFundsScopes1_2_3) *
                                            100
                                          ).toFixed(0)}
                                        ></span> </div>
                                      }
                                      <div className="value">
                                        <span>
                                          <b>
                                            {(!!fund.scopes1_2_3 && fund.scopes1_2_3 !== "UA" && fund.scopes1_2_3 !== "N/A")
                                              ? transformNumberGMK(
                                                parseFloat(fund.scopes1_2_3),
                                                1
                                              )
                                              : (!!fund.scopes1_2_3 ? fund.scopes1_2_3 : "N/A")}
                                          </b>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                }

                                {/* Trajectory */}
                                { investorType === "infra" && year < 2022 &&
                                  <div className={`table__column column-trajectory ${year === 2019 ? 'larger' : ''}`}>
                                    <span>
                                      {!!fund.trajectory ?
                                        <><b>{fund.trajectory}</b>
                                          {fund.trajectory !== "UA" && fund.trajectory !== "N/A" ? "°C" : ""}
                                        </>
                                        : "N/A"}
                                    </span>
                                  </div>
                                }
                                
                                {/* Avoided */}
                                { investorType === "gigf" &&
                                  <div className="table__column column-avoided">
                                    <span>
                                      {!!fund.avoided ? <b>{transformNumberGMK(
                                            parseFloat(fund.avoided),
                                            1
                                          )}</b> : "N/A"}
                                    </span>
                                  </div>
                                }

                                { year >= 2022 && investorType === "infra" &&
                                  <>
                                    <div className="table__column column-scope1">
                                      <span>
                                        {!!fund.scope1 ? <b>{transformNumberGMK(
                                              parseFloat(fund.scope1),
                                              1
                                            )}</b> : "N/A"}
                                      </span>
                                    </div>
                                    <div className="table__column column-scope2">
                                      <span>
                                        {!!fund.scope2 ? <b>{transformNumberGMK(
                                              parseFloat(fund.scope2),
                                              1
                                            )}</b> : "N/A"}
                                      </span>
                                    </div>
                                    <div className="table__column column-scope3">
                                      <span>
                                        {!!fund.scope3 ? <b>{transformNumberGMK(
                                              parseFloat(fund.scope3),
                                              1
                                            )}</b> : "N/A"}
                                      </span>
                                    </div>
                                    <div className="table__column column-capex-euro">
                                      <span>-
                                        {/* {!!fund.capex ? <b>{transformNumberGMK(
                                              parseFloat(fund.capex),
                                              1
                                            )}</b> : "N/A"} */}
                                      </span>
                                    </div>
                                    <div className="table__column column-emission-above-below">
                                      <span>
                                        {!!fund.trajectory ?
                                          <><b>{fund.trajectory}</b>
                                            {fund.trajectory !== "UA" && fund.trajectory !== "N/A" ? "°C" : ""}
                                          </>
                                        : "N/A"}
                                      </span>
                                    </div>
                                  </>
                                }
                              </div>

                              {/* MAP THROUGH PROJECT OF THE FUND */}
                              <div className="subrow subrow-other">
                                {fund.projects.map((project, index) => {
                                  return (
                                    <div
                                      className="project"
                                      key={"project_" + index}
                                    >
                                      <div className="subrow subrow-project">
                                        <div className={`table__column fixed column-fund ${(investorType === "gigf") ? 'gigf' : ''}`}>
                                          <div className="project_icon">
                                            <span dangerouslySetInnerHTML={{ __html: getProjectTypeIcon(project.type) }}></span>
                                          </div>
                                          <div
                                            className="project_name"
                                            data-uk-tooltip={
                                              "title:" + project.projectName + ";"
                                            }
                                          >
                                            <span>{project.projectName}</span>
                                          </div>
                                        </div>

                                        {/* Absolute Scopes 1, 2 & 3 emissions */}
                                        {year > 2019 && (year < 2022 || investorType === "gigf") &&
                                          <div className={`table__column column-abs-emission ${(investorType === "gigf") ? 'gigf' : ''}`}>
                                            <span>
                                              <b>
                                                {!!project.absoluteScopes ?
                                                  transformNumberGMK(project.absoluteScopes, 1)
                                                  :
                                                  "N/A"
                                                }
                                              </b>
                                            </span>
                                          </div>
                                        }

                                        {/* Meridiam CAPEX Investment Share (%) */}
                                        {year > 2019 && year < 2022 && investorType === "infra" &&
                                          <div className="table__column column-capex">
                                            <span>
                                              <b>
                                                {
                                                  !!project.meridiamCapex
                                                    ?
                                                    project.meridiamCapex + '%'
                                                    :
                                                    "N/A"
                                                }
                                              </b>
                                            </span>
                                          </div>
                                        }

                                        {/* Concession duration (years) */}
                                        {year > 2019 && year < 2022 && investorType === "infra" &&
                                          <div className="table__column column-concession">
                                            <span>
                                              <b>
                                                {
                                                  !!project.concessionDuration
                                                    ?
                                                    project.concessionDuration + ' years'
                                                    :
                                                    "N/A"
                                                }
                                              </b>
                                            </span>
                                          </div>
                                        }

                                        {/* COL SCOPES 1,2 */}
                                        {year === 2019 &&
                                          <div className="table__column column-emission">
                                            {!!fund.scopes1_2 && fund.scopes1_2 !== "N/A" && fund.scopes1_2 !== "UA" && !!project.scopes1_2 && project.scopes1_2 !== "UA" && project.scopes1_2 != "N/A" &&
                                              <span
                                                className="bar"
                                                data-percent={(Math.abs(
                                                  100 -
                                                  ((parseFloat(fund.scopes1_2) -
                                                    parseFloat(project.scopes1_2)) /
                                                    parseFloat(fund.scopes1_2)) *
                                                  100
                                                ).toFixed(0))}
                                              ></span>
                                            }
                                            <span>
                                              <b>
                                                {(!!project.scopes1_2 && project.scopes1_2 !== "N/A" && project.scopes1_2 !== "UA")
                                                  ?
                                                  transformNumberGMK(parseFloat(project.scopes1_2), 1)
                                                  :
                                                  project.scopes1_2 ? project.scopes1_2 : "N/A"
                                                }
                                              </b>
                                            </span>
                                          </div>
                                        }

                                        {/* COL SCOPES 1,2,3 */}
                                        {(year < 2022 || investorType === "gigf") && 
                                          <div className={`table__column column-carbon-fp ${(year === 2019) ? 'larger' : ''} ${(investorType === "gigf") ? 'gigf' : ''}`}>
                                            <div
                                              className={`${year === 2019 ? 'bar_wrapper' : ""} ${year === 2019 ? (project.scopes1_2_3 !== "UA" && project.scopes1_2_3 !== "N/A" && parseFloat(project.scopes1_2_3) < 0 ? "negative" : "positive") : ""} ${year === 2019 ? (!!project.scopes1_2_3 && project.scopes1_2_3 !== "UA" && project.scopes1_2_3 !== "N/A" ? " with-data" : " without-data") : ""}`}>
                                              {year === 2019 && !!fund.scopes1_2_3 && fund.scopes1_2_3 !== "N/A" && fund.scopes1_2_3 !== "UA" && !!project.scopes1_2_3 && project.scopes1_2_3 !== "UA" && project.scopes1_2_3 != "N/A" &&
                                                <div className="data">
                                                  <span
                                                    className="bar"
                                                    data-percent={Math.abs(
                                                      100 -
                                                      (
                                                        (parseFloat(fund.scopes1_2_3) - parseFloat(project.scopes1_2_3)) / parseFloat(fund.scopes1_2_3)
                                                      ) * 100
                                                    ).toFixed(0)}
                                                  ></span>
                                                </div>
                                              }
                                              <div className="value">
                                                <span>
                                                  <b>
                                                    {(!!project.scopes1_2_3 && project.scopes1_2_3 !== "N/A" && project.scopes1_2_3 !== "UA")
                                                      ?
                                                      transformNumberGMK(parseFloat(project.scopes1_2_3), 1)
                                                      :
                                                      project.scopes1_2_3 ? project.scopes1_2_3 : "N/A"
                                                    }
                                                  </b>
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        }

                                        {/* COL TRAJECTORY */}
                                        { investorType === "infra" && year < 2022 && 
                                          <div className={`table__column column-trajectory ${year === 2019 ? 'larger' : ''}`}>
                                            <span>
                                              {(!!project.trajectory)
                                                ?
                                                <>
                                                  <b>{project.trajectory}</b>
                                                  {project.trajectory !== "UA" && project.trajectory !== "N/A" ? "°C" : ""}
                                                </>
                                                : "N/A"}
                                            </span>
                                          </div>
                                        }

                                        {/* COL AVOIDED */}
                                        { investorType === "gigf" && 
                                          <div className="table__column column-avoided">
                                            <span>
                                              {(!!project.avoided)
                                                ?
                                                <>
                                                  <b>{transformNumberGMK(parseFloat(project.avoided), 1)}</b>
                                                </>
                                                : "N/A"}
                                            </span>
                                          </div>
                                        }

                                        { year >= 2022 && investorType === "infra" &&
                                          <>
                                            <div className="table__column column-scope1">
                                              <span><b>
                                                {!!project.scope1 ? transformNumberGMK(
                                                      parseFloat(project.scope1),
                                                      1
                                                    ) : "N/A"}
                                              </b></span>
                                            </div>
                                            <div className="table__column column-scope2">
                                              <span><b>
                                                {!!project.scope2 ? transformNumberGMK(
                                                      parseFloat(project.scope2),
                                                      1
                                                    ) : "N/A"}
                                              </b></span>
                                            </div>
                                            <div className="table__column column-scope3">
                                              <span><b>
                                                {!!project.scope3 ? transformNumberGMK(
                                                      parseFloat(project.scope3),
                                                      1
                                                    ) : "N/A"}
                                              </b></span>
                                            </div>
                                            <div className="table__column column-capex-euro">
                                              <span><b>
                                                {!!project.capex ? transformNumberGMK(
                                                      parseFloat(project.capex),
                                                      1
                                                    ) : "N/A"}
                                              </b></span>
                                            </div>
                                            <div className="table__column column-emission-above-below">
                                              <span><b>
                                                {(!!project.trajectory)
                                                  ?
                                                  <>
                                                    <b>{project.trajectory}</b>
                                                    {project.trajectory !== "UA" && project.trajectory !== "N/A" ? "°C" : ""}
                                                  </>
                                                  : "N/A"}
                                              </b></span>
                                            </div>
                                          </>
                                        }

                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>

                <div id="cmpPdfLegendsCFP" className="tool__legends" data-print="yes">
                  <div className="legend">
                    <span className="legend_label">
                      <i>* N/A : not available / UA : under assessment</i>
                    </span>
                  </div>
                </div>
              </div>

              <div className="tool__actions">
                <div className="actions_inner">
                  <div>
                    <div className="export">
                      <DownloadPdfButton
                        visible={true}
                        optionsPrint={{
                          format: "A4",
                          orientation: "landscape",
                          landscape: true,
                          pageClassname: 'page-table',
                          pages: -1,
                          assets: [
                            {
                              folder: "assets/css",
                              files: [
                                "development" !== process.env.NODE_ENV
                                  ? "print.css"
                                  : "print-local.css",
                              ],
                            },
                          ],
                        }}
                        pdfTitle=""
                        filename={"Carbon_Footprint_" + today}
                        selector="#cmpCarbonFootprint"
                        selectorHead="#cmpPdfHead"
                        selectorFoot="#cmpPdfLegendsCFP"
                        setPdfMode={setPdfMode}
                        extendedPdfMode={extendedPdfMode}
                        mode="link"
                        onclickValue={true}
                        label="Download"
                        labelInside=".pdf"
                        icon="fas fa-download"
                        itemsList={[]}
                        setIsPrint={setIsPrint}
                      />
                    </div>
                    <div className="export">
                      <div className="selector">
                        <p className="label">Download
                        </p>
                        <div className="select">
                          <button
                            className="btn btn-select btn-primary full btn-rounded"
                            type="button"
                            onClick={() => downloadCFP()}
                          >
                            <span className="btn_label">.xls</span>
                            <span className="btn_icon">
                              {isDownloadingXLS
                                ?
                                <i className="fas fa-spinner fa-spin"></i>
                                :
                                <i className="fas fa-table"></i>
                              }
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="export">
                    <a
                      className="btn btn-select btn-primary full btn-rounded"
                      type="button"
                      href="/assets/pdf/GHG_Definitions_and_Scopes.pdf" download="GHG_Definitions_and_Scopes"
                    >
                      <span className="btn_label">Methodology</span>
                      <span className="btn_icon"><i className="fas fa-info"></i></span>
                    </a>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <p className="no-data-available">No data available.</p>
          )
        }
      </div>
    </div >
  );
};

export default CarbonFootPrintSection;

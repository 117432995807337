import React, { useContext, useEffect, useState } from "react";
import { setTemplatesDocuments } from "modules/hub/models/project-reporting-documents";
import ProjectReportingDocumentsItemLeader from "./ProjectReportingDocumentsItemLeader";
import ProjectReportingDocumentsItemHub from "./ProjectReportingDocumentsItemHub";
import {
  deleteFile,
} from "modules/shared/services/spv-reporting.service";

import "./styles.scss";
import { connect } from "react-redux";
import { RootState, ThunkDispatch } from "models/store.models";
import {getDocuments, getFileByDocumentType } from "modules/shared/services/spv-reporting.service";
// import Resumable from "resumablejs";
// import config from "modules/config";
import config from "modules/config";
import { cloneDeep } from "lodash";
import { ReportingContext } from "context/Reporting.context";
import { PermissionContext } from "context/Permission.context";
import UploadConfig, { MaxChunkSize, UploadFile } from "modules/shared/services/upload.service";
// import slugify from 'slugify';

interface ProjectReportingDocumentsProps {
  // isLeaderView: boolean;
  user: any;
  accessToken: string;
  project: any | null;
}

const maxDocuments: number = 50;

const mapStateToProps = ({ session }: RootState) => ({
  accessToken: session?.sessionData?.accessToken ? session?.sessionData?.accessToken : "",
  user: session?.sessionData?.userData,
});

const mapDispatchToProps = (dispatch: ThunkDispatch) => ({});

const maxChunkSize: number = 1000000; //1Mo

// const ApiBaseUrl = config.ApiUrl;

const ProjectReportingDocuments = ({project, accessToken}: ProjectReportingDocumentsProps) => {

  const { isMeridiamGranted } = useContext(PermissionContext);

  const { setRawData, setData, currentTab, setCurrentTab } = useContext(ReportingContext);

  const [reportingDocuments, setReportingDocuments] = useState<any>([]);

  const [templates, setTemplates] = useState<any>([]);

  const [files, setFiles] = useState<any>([]);

  const [directory, setDirectory] = useState<string | null>(null);

  const [uploading, setUploading] = useState<boolean>(false);

  const [maxChunks, setMaxChunks] = useState<number>(0);

  const [currentFiles, setCurrentFiles] = useState<any>([]);

  const [currentFileIndex, setCurrentFileIndex] = useState<number>(0);

  const [additionalFiles, setAdditionalFiles] = useState<any>([]);

  const processUpload = async (currentFile: File) => {
    if (!!directory) {

      const totalFiles = Math.ceil(currentFile.size / MaxChunkSize);
      UploadConfig.accessToken = accessToken;
      UploadConfig.maxChunks = totalFiles;
      UploadConfig.uploading = true;
      UploadFile(directory, { filename: currentFile.name, file: currentFile }, (data: any) => {
        setMaxChunks(0);
        setAdditionalFiles([...additionalFiles, ...[data]]);

        if ("undefined" !== typeof currentFiles[currentFileIndex + 1]) {
          setCurrentFileIndex(currentFileIndex + 1);
        } else {
          setUploading(false);
          setCurrentFiles([]);
          setCurrentFileIndex(0);
        }
      }, `/additional-documents/${currentFile.name}`);
    }
  };

  const deleteFileFromServer = async (file: any) => {
    if (window.confirm(`Would you like to delete ${file.name} file ?`)) {
      const hasBeenDeleted: boolean = await deleteFile(accessToken, file.id);
      if (!!hasBeenDeleted) {
        const tmpAdditionalFiles = cloneDeep(additionalFiles);
        tmpAdditionalFiles.splice(
          additionalFiles.findIndex((af: any) => af.id === file.id),
          1
        );
        setAdditionalFiles(tmpAdditionalFiles);
      }
    }
  };

  const loadFiles = async () => {
    const tmpFiles: any = await getFileByDocumentType("upload", accessToken, reportingDocuments.map((t: any) => t.code), `${project.projectCode} - ${project.projectName.replace(/\//g, '-').trim()}`);
    if ("ok" === tmpFiles.status) {
      setFiles(tmpFiles.files);
      setDirectory(tmpFiles.directory);
    }

    const tmpTemplates: any = await getFileByDocumentType("template", accessToken, reportingDocuments.map((t: any) => t.code), `${project.projectCode} - ${project.projectName.replace(/\//g, '-').trim()}`);
    if ("ok" === tmpTemplates.status) {
      setTemplates(tmpTemplates.files);
    }

    const tmpAdditionalFiles: any = await getFileByDocumentType("upload", accessToken, null, `${project.projectCode} - ${project.projectName.replace(/\//g, '-').trim()}/additional-documents`);
    if ("ok" === tmpAdditionalFiles.status) {
      setAdditionalFiles(tmpAdditionalFiles.files);
    }
  };

  const initData = async () => {
    const tmpDocuments: any = await getDocuments(accessToken);

    if ("ok" === tmpDocuments.status) {
      if(!!project){
        setReportingDocuments(tmpDocuments.data);
      } else {
        const tmpReportingDocuments: any = await setTemplatesDocuments(maxDocuments, tmpDocuments.data);
        setReportingDocuments(tmpReportingDocuments);
        setData(tmpReportingDocuments);
        setRawData(tmpReportingDocuments);
      }
    }
  };

  useEffect(() => {
    if(1 === currentTab){
      initData();
    }
  }, [currentTab]);

  useEffect(() => {
    if (0 < reportingDocuments.length && !!project) {
      loadFiles();
    }
  }, [reportingDocuments, project]);

  // If select a file to upload
  useEffect(() => {
    if (!!directory && 0 < currentFiles.length) {
      const totalChunks = Math.ceil(currentFiles[currentFileIndex].size / maxChunkSize);
      setMaxChunks(totalChunks);
      setUploading(true);
      processUpload(currentFiles[currentFileIndex]);
    }
  }, [currentFiles, currentFileIndex]);

  useEffect(() => {
    return () => {
      setCurrentTab(0);
      setReportingDocuments([]);
      setTemplates([]);
      setFiles([]);
      setDirectory(null);
    }
  }, []);

  return (
    <>
      <div className="project-details-reporting-docs_head"></div>

      <div className="project-details-reporting-docs_content">
        {!!project ? 
          <>
            <ul className="list is-leader-view">
              <li className="row filters">
                <div className="infos"></div>
                <div className="due-date">
                  <i>Due date</i>
                </div>
                <div className="download">
                  <i>Download reporting template</i>
                </div>
                <div className="upload">
                  <i>Upload reporting</i>
                </div>
                <div className="actions"></div>
              </li>
              {!!reportingDocuments &&
                reportingDocuments
                  .filter((doc: any) => !!doc.noTemplate || (!doc.noTemplate && !!templates.find((t: any) => Number(doc.code) === Number(t.code))))
                  .sort((docA: any, docB: any) => Number(docA.code) > Number(docB.code) ? 1 : -1)
                  .map((doc: any, idx: number) => (
                    <ProjectReportingDocumentsItemLeader
                      key={`leader-reporting-doc-${doc.code}-${idx}`}
                      projectGroup={project.reportingGroup}
                      index={idx}
                      directory={directory}
                      template={templates.find((t: any) => doc.code === t.code)}
                      file={files.find((t: any) => doc.code === t.code)}
                      doc={doc}
                    />
                  ))}
            </ul>
            {/* <button
              className="btn btn-select btn-primary btn-submit"
              // onClick={}
            >
              <span className="btn-label">Submit all documents</span>
              <span className="btn-icon">
                <i className="fas fa-arrow-right"></i>
              </span>
            </button> */}
          </>
        : !!isMeridiamGranted &&
          <>
            <ul className="list is-hub-view">
              <li className="row filters">
                <div className="file-name">
                  <i>File name</i>
                </div>
                <div className="description">
                  <i>Description</i>
                </div>
                <div className="due-date">
                  <i>Due date</i>
                </div>
                <div className="save"></div>
              </li>
              {!!reportingDocuments &&
                reportingDocuments.map((doc: any, idx: number) => (
                  <ProjectReportingDocumentsItemHub
                    key={`admin-reporting-doc-${doc.code}-${idx}`}
                    index={idx}
                    doc={doc}
                  />
                ))}
            </ul>
            {/* <button
              className="btn btn-select btn-primary"
              onClick={() => addNewReportingDocs()}
            >
              <span className="btn-label">Add a new file description</span>
              <span className="btn-icon">
                <i className="fas fa-plus"></i>
              </span>
            </button> */}
          </>
        }
      </div>

      <div className="project-details-reporting-docs_foot">
        {!!project && 
          <div className="is-leader-view">
            <div className="additional-docs">
              <div className="additional-docs_inner">
                <h4>Additional document</h4>
                <p>
                  If there are any additional document that you wish to share,
                  please upload them here
                </p>
                <input type="file" id="upload" hidden multiple disabled={!!uploading} onChange={(e) => setCurrentFiles(e.target.files)} />
                <label htmlFor="upload" className="btn btn-select btn-primary">
                  {!!uploading ? 
                    <>
                      <span className="btn-label">Uploading</span>
                      <span className="btn-icon">
                        <i className={`fas fa-spinner`}></i>
                      </span>
                    </>
                   : 
                    <>
                      <span className="btn-label">Add document</span>
                      <span className="btn-icon">
                        <i className={`fas fa-upload`}></i>
                      </span>
                    </>
                  }
                </label>
                <ul className="files-list">
                  {additionalFiles.map((file: any, idx: number) => (
                    <li key={`files-${idx}`} className="files-item">
                      <span>{file.name}</span>
                      <i
                        className={`fas fa-times-circle`}
                        onClick={() => deleteFileFromServer(file)}
                      ></i>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        }
      </div>
    </>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectReportingDocuments);

import React, { useState } from "react";
import sdgDatas from "../../../datas/sdg.json";
import targetDatasImport from "../../../datas/target.json";
import "./styles.scss";
import config from "modules/config";

type SdgContributionSelectorSectionProps = {
  currentSdgContribution: any;

  currentSdg: number;
  currentTarget: string;
  currentIndicator: string;
  setValueGraph: any;
  resetCurrentSelection: any;
  isProjectLeaderView: boolean;
};

const SdgContributionSelectorSection: React.FC<SdgContributionSelectorSectionProps> = ({
  currentSdgContribution,
  currentSdg,
  currentTarget,
  currentIndicator,
  setValueGraph,
  resetCurrentSelection,
  isProjectLeaderView
}) => {
  const targetDatas: { [key: string]: string } = targetDatasImport;
  const sdgList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17];
  const [openBoxList, setOpenBoxList] = useState<string>(""); // can be sdg ; target or indicator

  const selectNewLevelIndicator = (type: string, value: any, isAlreadySelected: boolean) => {
    setOpenBoxList('');
    if(!isAlreadySelected){
      setValueGraph(type, value);
    }
  }

  return (
    <div className="inner-row">
      {/* 1- SDG SELECTED for dropdown */}
      <ul className="list list-main list-sdgs selection">
        {sdgList.map((sdg) => {
          return (
            <li
              className={`item item-sdg${openBoxList === "sdg" ? " active" : ""}`}
              key={`listSdg-${sdg}`}
              onClick={() => setOpenBoxList(openBoxList === "sdg" ? "" : "sdg")}
            >
              {sdg === currentSdg && (
                <div className="inner">
                  <div className="icon">
                    <span className="sdg-icon">
                      <img
                        src={`${config.CdnUrl}/assets/images/sdg/icons/80/sdg-pastille-${sdg}@2x.png`}
                        alt={`SDG ${sdg} icon`}
                      />
                    </span>
                  </div>
                  <div className="resume resume-sdg">SDG - {sdg}</div>
                  <div className="action">
                    <button className="btn">
                      <span className="btn-icon">
                        <i className="fas fa-chevron-down"></i>
                      </span>
                    </button>
                  </div>
                  <div className="name name-sdg">
                    <span>{sdgDatas[sdg].shortName}</span>
                  </div>
                </div>
              )}
            </li>
          );
        })}
      </ul>

      {/* SDG dropdown */}
      <div className="container-list" data-print="no">
        <ul
          className={`list list-main list-sdgs ${
            openBoxList === "sdg" && "open"
          }`}
        >
          {sdgList.map((sdg) => {
            return (
              <li
                className={
                  "item item-sdg" +
                  (sdg === currentSdg ? " selected " : "") +
                  (!currentSdgContribution?.listIndicator[sdg] ?
                    " cant-select" : "")
                }
                key={`sdgListNoPrint-${sdg}`}
                onClick={() => selectNewLevelIndicator("sdg", sdg, sdg === currentSdg ? true : false)}
              >
                <div className="inner">
                  <div className="icon">
                    <span className="sdg-icon">
                      <img
                        src={`/assets/images/sdg/icons/80/sdg-pastille-${sdg}@2x.png`}
                        alt={`SDG ${sdg} icon`}
                      />
                    </span>
                  </div>
                  <div className="resume resume-sdg">SDG - {sdg}</div>
                  <div className="action">
                    <button className="btn btn-action">
                      <span className="btn-icon">
                        <i className="fas fa-arrow-right"></i>
                      </span>
                    </button>
                  </div>
                  <div className="name name-sdg">{sdgDatas[sdg].shortName}</div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>

      {/* 2- TARGET SELECTED for dropdown */}
      {currentSdg !== 0 && (
        <ul className="list list-main list-targets selection">
          <>
            {!currentTarget &&
              openBoxList !== "sdg" &&
              currentSdgContribution &&
              currentSdgContribution.listIndicator[currentSdg] && (
                <li
                  className={`item item-target prestine ${
                    openBoxList === "target" && "active"
                  }`}
                  data-print="no"
                  onClick={() =>
                    setOpenBoxList(openBoxList === "target" ? "" : "target")
                  }
                >
                  <div className="inner">
                    <div className="resume resume-target">Target</div>
                    <div className="action">
                      <button className="btn btn-action">
                        <span className="btn-icon">
                          <i className="fas fa-chevron-down"></i>
                        </span>
                      </button>
                    </div>
                    <div className="name name-target">
                      <i>Choose a target</i>
                    </div>
                  </div>
                  <button
                    className="btn-close-list" data-print="no"
                    onClick={() => setOpenBoxList("")}
                  ></button>
                </li>
              )}

            {currentSdgContribution &&
              currentSdgContribution.listIndicator[currentSdg] &&
              Object.keys(currentSdgContribution.listIndicator[currentSdg]).map(
                (target) => {
                  return (
                    <li
                      className={`item item-target ${
                        openBoxList === "target" && "active"
                      }`}
                      key={`target-${target}`}
                    >
                      {currentTarget === target && (
                        <>
                          <div
                            className="inner"
                            onClick={() => setOpenBoxList("target")}
                          >
                            <div className="resume resume-target">
                              Target - {target}
                            </div>
                            <div className="action">
                              <button className="btn btn-action">
                                <span className="btn-icon">
                                  <i className="fas fa-chevron-down"></i>
                                </span>
                              </button>
                            </div>
                            <div className="name name-target">
                              <span>{targetDatas[target]}</span>
                            </div>
                          </div>
                          <div
                            className="btn btn-reset" data-print="no"
                            onClick={() => resetCurrentSelection("target")}
                          >
                            <i className="far fa-times"></i>
                          </div>
                        </>
                      )}
                      <button
                        className="btn-close-list" data-print="no"
                        onClick={() => setOpenBoxList("")}
                      ></button>
                    </li>
                  );
                }
              )}
          </>
        </ul>
      )}

      {/* TARGET dropdown */}
      <div className="container-list" data-print="no">
        <ul
          className={`list list-main list-targets ${
            openBoxList === "target" && "open"
          }`}
        >
          <>
            {currentSdgContribution &&
              currentSdgContribution.listIndicator[currentSdg] &&
              Object.keys(currentSdgContribution.listIndicator[currentSdg]).map(
                (target) => {
                  return (
                    <li
                      className="item item-target"
                      onClick={() => selectNewLevelIndicator("target", target, target === currentTarget ? true : false)}
                      key={`targetList-${target}`}
                    >
                      <div className="inner" onClick={() => setOpenBoxList("")}>
                        <div className="resume resume-target">
                          Target - {target}
                        </div>
                        <div className="action">
                          <button className="btn btn-action">
                            <span className="btn-icon">
                              <i className="fas fa-arrow-right"></i>
                            </span>
                          </button>
                        </div>
                        <div className="name name-target">
                          <span>{targetDatas[target]}</span>
                        </div>
                      </div>
                    </li>
                  );
                }
              )}
          </>
        </ul>
      </div>

      {/* 3- INDICATOR SELECTED for dropdown */}
      {currentTarget !== "" && !isProjectLeaderView && (
        <ul
          className={`list list-main list-indicators selection ${
            openBoxList === "indicator" && "active"
          }`}
        >
          <>
            {!currentIndicator &&
              openBoxList !== "sdg" &&
              openBoxList !== "target" && (
                <li
                  className="item item-indicator prestine"
                  data-print="no"
                  onClick={() =>
                    setOpenBoxList(
                      openBoxList === "indicator" ? "" : "indicator"
                    )
                  }
                >
                  <div className="inner">
                    <div className="resume resume-indicator">Indicator</div>
                    <div className="action">
                      <button className="btn btn-action">
                        <span className="btn-icon">
                          <i className="fas fa-chevron-down"></i>
                        </span>
                      </button>
                    </div>
                    <div className="name name-indicator">
                      <i>Choose an indicator</i>
                    </div>
                  </div>
                  <button
                    className="btn-close-list" data-print="no"
                    onClick={() => setOpenBoxList("")}
                  ></button>
                </li>
              )}

            {currentSdgContribution &&
              currentSdgContribution.listIndicator[currentSdg] &&
              currentSdgContribution.listIndicator[currentSdg][currentTarget] &&
              Object.keys(
                currentSdgContribution.listIndicator[currentSdg][currentTarget]
              ).map((indicator) => {
                return (
                  <li
                    className={`item item-indicator ${
                      openBoxList === "indicator" && "active"
                    }`}
                    key={`indicator-${indicator}`}
                    onClick={() => setOpenBoxList("indicator")}
                  >
                    {currentIndicator ===
                      currentSdgContribution.listIndicator[currentSdg][
                        currentTarget
                      ][indicator].indicatorId && (
                      <>
                        <div className="inner">
                          <div className="resume resume-indicator">
                            Indicator -{" "}
                            {
                              currentSdgContribution.listIndicator[currentSdg][
                                currentTarget
                              ][indicator].indicatorId
                            }
                          </div>
                          <div className="action">
                            <button className="btn btn-action">
                              <span className="btn-icon">
                                <i className="fas fa-chevron-down"></i>
                              </span>
                            </button>
                          </div>
                          <div className="name name-indicator">
                            <span>
                              {
                                currentSdgContribution.listIndicator[
                                  currentSdg
                                ][currentTarget][indicator].indicatorText
                              }
                            </span>
                          </div>
                        </div>
                        <div data-print="no"
                          className="btn btn-reset"
                          onClick={() => resetCurrentSelection("indicator")}
                        >
                          <i className="far fa-times"></i>
                        </div>
                      </>
                    )}
                    <button data-print="no"
                      className="btn-close-list" 
                      onClick={() => setOpenBoxList("")}
                    ></button>
                  </li>
                );
              })}
          </>
        </ul>
      )}

      {/* INDICATOR dropdown */}
      {!isProjectLeaderView && (
        <div className="container-list" data-print="no">
          <ul
            className={`list list-main list-indicators ${
              openBoxList === "indicator" && "open"
            }`}
          >
            {currentSdgContribution &&
              currentSdgContribution.listIndicator[currentSdg] &&
              currentSdgContribution.listIndicator[currentSdg][currentTarget] &&
              Object.keys(
                currentSdgContribution.listIndicator[currentSdg][currentTarget]
              ).map((indicator) => {
                return (
                  <li
                    className="item item-indicator"
                    key={`indicatorList-${indicator}`}
                    onClick={() => selectNewLevelIndicator("indicator", indicator, indicator === currentIndicator ? true : false)}
                  >
                    <div className="inner">
                      <div className="resume resume-indicator">
                        Indicator - {indicator}
                      </div>
                      <div className="action">
                        <button className="btn btn-action">
                          <span className="btn-icon">
                            <i className="fas fa-arrow-right"></i>
                          </span>
                        </button>
                      </div>
                      <div className="name name-indicator">
                        <span>
                          {
                            currentSdgContribution.listIndicator[currentSdg][
                              currentTarget
                            ][indicator].indicatorText
                          }
                        </span>
                      </div>
                    </div>
                  </li>
                );
              })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default SdgContributionSelectorSection;

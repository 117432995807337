import React, { useState } from 'react';
import { DateTime } from "luxon";
import { formattedDate } from "modules/shared/utils/format";
// import AddToCalendar from 'react-add-to-calendar';
// import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { google, office365, yahoo, ics } from "calendar-link";
// import AddToCalendar from 'assets/libs/react-add-to-calendar/lib/ReactAddToCalendar';

import styles from "./styles.module.scss";

interface IProps {
    data: any
    projectGroup: string
}

const allowedCalendars: any = [
    { type: 'outlook', label: 'Outlook' },
    { type: 'apple', label: 'Apple Calendar' },
    { type: 'yahoo', label: 'Yahoo' },
    { type: 'google', label: 'Google' }
]


const CalendarDate = ({data, projectGroup}: IProps) => {

    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);

    const title: string = !!projectGroup && ("group1" === projectGroup && !!data.group1DueDate) ? formattedDate(data.group1DueDate, DateTime.DATE_MED) : !!data.group2DueDate ? formattedDate(data.group2DueDate, DateTime.DATE_MED) : "———";

    const g1DateStart: any = !!data.group1DueDate ? DateTime.fromISO(data.group1DueDate.replace(/\d{2}:00:00.000Z$/, '00:00:00')) : null;
    
    const g1DateEnd: any = !!data.group1DueDate ? DateTime.fromISO(data.group1DueDate.replace(/\d{2}:00:00.000Z$/, '23:59:59')) : null;
    
    const g2DateStart: any = !!data.group2DueDate ? DateTime.fromISO(data.group2DueDate.replace(/\d{2}:00:00.000Z$/, '00:00:00')) : null;
    
    const g2DateEnd: any = !!data.group2DueDate ? DateTime.fromISO(data.group2DueDate.replace(/\d{2}:00:00.000Z$/, '23:59:59')) : null;


    const gDateStart: any = "group1" === projectGroup ? (!!data.group1DueDate ? DateTime.fromISO(data.group1DueDate.replace(/\d{2}:00:00.000Z$/, '00:00:00')) : null) : !!data.group2DueDate ? DateTime.fromISO(data.group2DueDate.replace(/\d{2}:00:00.000Z$/, '00:00:00')) : null;
    const gDateEnd: any = "group1" === projectGroup ? (!!data.group1DueDate ? DateTime.fromISO(data.group1DueDate.replace(/\d{2}:00:00.000Z$/, '23:59:59')) : null) : !!data.group2DueDate ? DateTime.fromISO(data.group2DueDate.replace(/\d{2}:00:00.000Z$/, '23:59:59')) : null;
    
    // const calData: any = (!!data.group1DueDate || !!data.group2DueDate) ? {
    //     title: `Reporting due date`,
    //     description: `Upload file ${data.filename}`,
    //     location: '',
    //     startTime: !!projectGroup && ("group1" === projectGroup && !!g1DateStart) ? g1DateStart.toISO() : !!g2DateStart ? g2DateStart.toISO() : null,
    //     endTime: !!projectGroup && ("group1" === projectGroup && !!g1DateEnd) ? g1DateEnd.toISO() : !!g2DateEnd ? g2DateEnd.toISO() : null,

    // } : null;

    let status: 'init' | 'soon' | 'passed' = 'init';

    // console.log('');
    // console.log('__INDICATOR ', data.filename, ' __')
    // console.log(`${projectGroup} past ?`, DateTime.local().toISO(), DateTime.fromISO(gDateStart).toISO(), DateTime.local() >= DateTime.fromISO(gDateStart));
    // console.log(`${projectGroup} soon ?`, DateTime.local().toISO(), DateTime.fromISO(gDateStart).toISO(), DateTime.local() < DateTime.fromISO(gDateStart).minus({ days: 7 }));

    if(!!gDateStart && DateTime.local().toISO() >= DateTime.fromISO(gDateStart).toISO()){
        status = 'passed';
    }else if(!!gDateStart && DateTime.local().plus({ days: 7}).toISO() >= DateTime.fromISO(gDateStart).toISO()){
        status = 'soon';
    }

    const calDataLink: any = (!!data.group1DueDate || !!data.group2DueDate) ? {
        title: `Reporting due date`,
        description: `Upload file ${data.filename}`,
        start: !!projectGroup && ("group1" === projectGroup && !!g1DateStart) ? g1DateStart.toISO() : !!g2DateStart ? g2DateStart.toISO() : null,
        end: !!projectGroup && ("group1" === projectGroup && !!g1DateEnd) ? g1DateEnd.toISO() : !!g2DateEnd ? g2DateEnd.toISO() : null,
    } : null;


    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const openLink = (type: string) => {
        let url: string = "";
        switch(type){
            case 'google':
                url = google(calDataLink);
            break;
            // case 'outlook':
            //     url = outlook(calDataLink);
            // break;
            case 'office365':
                url = office365(calDataLink);
            break;
            case 'yahoo':
                url = yahoo(calDataLink);
            break;
            default:
                url = ics(calDataLink);
        }

        window.open(url);
        var a = document.createElement('a');
        a.href = url;
        a.target = '_blank';
        a.download = title;
        document.body.appendChild(a);
        a.click();
        a.remove();
        handleClose();
    }

    const displayButton = (hasButtonIcon: boolean = false) => {
        return (
            <>
                {!!hasButtonIcon &&
                    <label aria-controls={'cal-menu'} className={`btn btn-select btn-primary ${styles['label-calendar-date']} ${styles[status]}`}>
                        <span className={`btn-icon ${styles['calendar-date']}`} onClick={handleClick}>
                            <i className="fas fa-calendar"></i>&nbsp;{title}
                        </span>
                    </label>
                }
                <Menu
                    id="cal-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={handleClose}
                >
                    {allowedCalendars.map((cal: any) => (
                        <MenuItem key={`menu-calendar-${!!hasButtonIcon ? 'with-icon' : ''}-${cal.type}`} onClick={() =>  openLink(cal.type)}>
                            {cal.label}
                        </MenuItem>
                    ))}
                </Menu>
            </>
        )
    }

    return (
        <>
            {/* {(!!calDataLink && ( (!!g1DateStart && DateTime.local() < DateTime.fromISO(g1DateStart)) || (!!g2DateStart && DateTime.local() < DateTime.fromISO(g2DateStart)) )  ) ? */}
                {displayButton(true)}
            {/* :
                displayButton(true)
            } */}
        </>
    )
}
export default CalendarDate;
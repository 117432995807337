import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import CountryDataManager from "./screens/CountryDataManager/CountryDataManager";
import CountryList from "./screens/CountryList/CountryList";
import SPVManager from "./screens/SPVManager/SPVManager";

function ManagementRoutes() {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/spv-manager`}>
        <SPVManager />
      </Route>

      <Route path={`${path}/country-list`}>
        <CountryList />
      </Route>

      <Route path={`${path}/country-data`}>
        <CountryDataManager />
      </Route>

      <Redirect from={`${path}`} to={`${path}/dashboard`} />
    </Switch>
  );
}

export default ManagementRoutes;

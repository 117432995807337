export function transformCountryCodeByLatlon(value: string) {
  switch (value) {
    case "AD":
      return "42.546245,1.601554";
    case "AE":
      return "23.424076,53.847818";
    case "AF":
      return "33.93911,67.709953";
    case "AG":
      return "17.060816,-61.796428";
    case "AI":
      return "18.220554,-63.068615";
    case "AL":
      return "41.153332,20.168331";
    case "AM":
      return "40.069099,45.038189";
    case "AN":
      return "12.226079,-69.060087";
    case "AO":
      return "-11.202692,17.873887";
    case "AQ":
      return "-75.250973,-0.071389";
    case "AR":
      return "-38.416097,-63.616672";
    case "AS":
      return "-14.270972,-170.132217";
    case "AUT":
      return "47.516231,14.550072";
    case "AU":
      return "-25.274398,133.775136";
    case "AW":
      return "12.52111,-69.968338";
    case "AZ":
      return "40.143105,47.576927";
    case "BA":
      return "43.915886,17.679076";
    case "BB":
      return "13.193887,-59.543198";
    case "BD":
      return "23.684994,90.356331";
    case "BE":
      return "50.503887,4.469936";
    case "BF":
      return "12.238333,-1.561593";
    case "BFA":
      return "12.238333,-1.561593";
    case "BG":
      return "42.733883,25.48583";
    case "BGR":
      return "42.733883,25.48583";
    case "BH":
      return "25.930414,50.637772";
    case "BI":
      return "-3.373056,29.918886";
    case "BJ":
      return "9.30769,2.315834";
    case "BM":
      return "32.321384,-64.75737";
    case "BN":
      return "4.535277,114.727669";
    case "BO":
      return "-16.290154,-63.588653";
    case "BR":
      return "-14.235004,-51.92528";
    case "BS":
      return "25.03428,-77.39628";
    case "BT":
      return "27.514162,90.433601";
    case "BV":
      return "-54.423199,3.413194";
    case "BW":
      return "-22.328474,24.684866";
    case "BY":
      return "53.709807,27.953389";
    case "BZ":
      return "17.189877,-88.49765";
    case "CAN":
      return "56.130366,-106.346771";
    case "CC":
      return "-12.164165,96.870956";
    case "CD":
      return "-4.038333,21.758664";
    case "CF":
      return "6.611111,20.939444";
    case "CG":
      return "-0.228021,15.827659";
    case "CH":
      return "46.818188,8.227512";
    case "CIV":
      return "7.539989,-5.54708";
    case "CK":
      return "-21.236736,-159.777671";
    case "CHL":
      return "-35.675147,-71.542969";
    case "CM":
      return "7.369722,12.354722";
    case "CN":
      return "35.86166,104.195397";
    case "CO":
      return "4.570868,-74.297333";
    case "CR":
      return "9.748917,-83.753428";
    case "CU":
      return "21.521757,-77.781167";
    case "CV":
      return "16.002082,-24.013197";
    case "CX":
      return "-10.447525,105.690449";
    case "CY":
      return "35.126413,33.429859";
    case "CZ":
      return "49.817492,15.472962";
    case "CZE":
      return "49.817492,15.472962";
    case "DEU":
      return "51.165691,10.451526";
    case "DJ":
      return "11.825138,42.590275";
    case "DK":
      return "56.26392,9.501785";
    case "DM":
      return "15.414999,-61.370976";
    case "DO":
      return "18.735693,-70.162651";
    case "DZ":
      return "28.033886,1.659626";
    case "EC":
      return "-1.831239,-78.183406";
    case "EE":
      return "58.595272,25.013607";
    case "EG":
      return "26.820553,30.802498";
    case "EH":
      return "24.215527,-12.885834";
    case "ER":
      return "15.179384,39.782334";
    case "ESP":
      return "40.463667,-3.74922";
    case "ETH":
      return "9.145,40.489673";
    case "FIN":
      return "61.92411,25.748151";
    case "FJ":
      return "-16.578193,179.414413";
    case "FK":
      return "-51.796253,-59.523613";
    case "FM":
      return "7.425554,150.550812";
    case "FO":
      return "61.892635,-6.911806";
    case "FRA":
      return "46.227638,2.213749";
    case "GAB":
      return "-0.803689,11.609444";
    case "GBR":
      return "55.378051,-3.435973";
    case "GD":
      return "12.262776,-61.604171";
    case "GE":
      return "42.315407,43.356892";
    case "GF":
      return "3.933889,-53.125782";
    case "GG":
      return "49.465691,-2.585278";
    case "GHA":
      return "7.946527,-1.023194";
    case "GI":
      return "36.137741,-5.345374";
    case "GL":
      return "71.706936,-42.604303";
    case "GM":
      return "13.443182,-15.310139";
    case "GN":
      return "9.945587,-9.696645";
    case "GP":
      return "16.995971,-62.067641";
    case "GQ":
      return "1.650801,10.267895";
    case "GR":
      return "39.074208,21.824312";
    case "GS":
      return "-54.429579,-36.587909";
    case "GT":
      return "15.783471,-90.230759";
    case "GU":
      return "13.444304,144.793731";
    case "GW":
      return "11.803749,-15.180413";
    case "GY":
      return "4.860416,-58.93018";
    case "GZ":
      return "31.354676,34.308825";
    case "HK":
      return "22.396428,114.109497";
    case "HM":
      return "-53.08181,73.504158";
    case "HN":
      return "15.199999,-86.241905";
    case "HR":
      return "45.1,15.2";
    case "HT":
      return "18.971187,-72.285215";
    case "HU":
      return "47.162494,19.503304";
    case "ID":
      return "-0.789275,113.921327";
    case "IRL":
      return "53.41291,-8.24389";
    case "IL":
      return "31.046051,34.851612";
    case "IM":
      return "54.236107,-4.548056";
    case "IN":
      return "20.593684,78.96288";
    case "IO":
      return "-6.343194,71.876519";
    case "IQ":
      return "33.223191,43.679291";
    case "IR":
      return "32.427908,53.688046";
    case "IS":
      return "64.963051,-19.020835";
    case "ITA":
      return "41.87194,12.56738";
    case "JE":
      return "49.214439,-2.13125";
    case "JM":
      return "18.109581,-77.297508";
    case "JOR":
      return "30.585164,36.238414";
    case "JP":
      return "36.204824,138.252924";
    case "KEN":
      return "-0.023559,37.906193";
    case "KG":
      return "41.20438,74.766098";
    case "KH":
      return "12.565679,104.990963";
    case "KI":
      return "-3.370417,-168.734039";
    case "KM":
      return "-11.875001,43.872219";
    case "KN":
      return "17.357822,-62.782998";
    case "KP":
      return "40.339852,127.510093";
    case "KR":
      return "35.907757,127.766922";
    case "KW":
      return "29.31166,47.481766";
    case "KY":
      return "19.513469,-80.566956";
    case "KZ":
      return "48.019573,66.923684";
    case "LA":
      return "19.85627,102.495496";
    case "LB":
      return "33.854721,35.862285";
    case "LC":
      return "13.909444,-60.978893";
    case "LI":
      return "47.166,9.555373";
    case "LK":
      return "7.873054,80.771797";
    case "LR":
      return "6.428055,-9.429499";
    case "LS":
      return "-29.609988,28.233608";
    case "LT":
      return "55.169438,23.881275";
    case "LU":
      return "49.815273,6.129583";
    case "LV":
      return "56.879635,24.603189";
    case "LY":
      return "26.3351,17.228331";
    case "MA":
      return "31.791702,-7.09262";
    case "MC":
      return "43.750298,7.412841";
    case "MD":
      return "47.411631,28.369885";
    case "ME":
      return "42.708678,19.37439";
    case "MDG":
      return "-18.766947,46.869107";
    case "MH":
      return "7.131474,171.184478";
    case "MK":
      return "41.608635,21.745275";
    case "ML":
      return "17.570692,-3.996166";
    case "MM":
      return "21.913965,95.956223";
    case "MN":
      return "46.862496,103.846656";
    case "MO":
      return "22.198745,113.543873";
    case "MP":
      return "17.33083,145.38469";
    case "MQ":
      return "14.641528,-61.024174";
    case "MRT":
      return "21.00789,-10.940835";
    case "MS":
      return "16.742498,-62.187366";
    case "MT":
      return "35.937496,14.375416";
    case "MU":
      return "-20.348404,57.552152";
    case "MV":
      return "3.202778,73.22068";
    case "MW":
      return "-13.254308,34.301525";
    case "MX":
      return "23.634501,-102.552784";
    case "MY":
      return "4.210484,101.975766";
    case "MZ":
      return "-18.665695,35.529562";
    case "NA":
      return "-22.95764,18.49041";
    case "NC":
      return "-20.904305,165.618042";
    case "NE":
      return "17.607789,8.081666";
    case "NF":
      return "-29.040835,167.954712";
    case "NG":
      return "9.081999,8.675277";
    case "NI":
      return "12.865416,-85.207229";
    case "NLD":
      return "52.132633,5.291266";
    case "NO":
      return "60.472024,8.468946";
    case "NP":
      return "28.394857,84.124008";
    case "NR":
      return "-0.522778,166.931503";
    case "NU":
      return "-19.054445,-169.867233";
    case "NZ":
      return "-40.900557,174.885971";
    case "OM":
      return "21.512583,55.923255";
    case "PA":
      return "8.537981,-80.782127";
    case "PE":
      return "-9.189967,-75.015152";
    case "PF":
      return "-17.679742,-149.406843";
    case "PG":
      return "-6.314993,143.95555";
    case "PH":
      return "12.879721,121.774017";
    case "PK":
      return "30.375321,69.345116";
    case "POL":
      return "51.919438,19.145136";
    case "PM":
      return "46.941936,-56.27111";
    case "PN":
      return "-24.703615,-127.439308";
    case "PR":
      return "18.220833,-66.590149";
    case "PS":
      return "31.952162,35.233154";
    case "PRT":
      return "39.399872,-8.224454";
    case "PW":
      return "7.51498,134.58252";
    case "PY":
      return "-23.442503,-58.443832";
    case "QA":
      return "25.354826,51.183884";
    case "RE":
      return "-21.115141,55.536384";
    case "RO":
      return "45.943161,24.96676";
    case "ROU":
      return "45.943161,24.96676";
    case "RS":
      return "44.016521,21.005859";
    case "RU":
      return "61.52401,105.318756";
    case "RW":
      return "-1.940278,29.873888";
    case "SA":
      return "23.885942,45.079162";
    case "SB":
      return "-9.64571,160.156194";
    case "SC":
      return "-4.679574,55.491977";
    case "SD":
      return "12.862807,30.217636";
    case "SE":
      return "60.128161,18.643501";
    case "SG":
      return "1.352083,103.819836";
    case "SH":
      return "-24.143474,-10.030696";
    case "SI":
      return "46.151241,14.995463";
    case "SJ":
      return "77.553604,23.670272";
    case "SVK":
      return "48.669026,19.699024";
    case "SL":
      return "8.460555,-11.779889";
    case "SM":
      return "43.94236,12.457777";
    case "SEN":
      return "14.497401,-14.452362";
    case "SO":
      return "5.152149,46.199616";
    case "SR":
      return "3.919305,-56.027783";
    case "ST":
      return "0.18636,6.613081";
    case "SV":
      return "13.794185,-88.89653";
    case "SY":
      return "34.802075,38.996815";
    case "SZ":
      return "-26.522503,31.465866";
    case "TC":
      return "21.694025,-71.797928";
    case "TD":
      return "15.454166,18.732207";
    case "TF":
      return "-49.280366,69.348557";
    case "TG":
      return "8.619543,0.824782";
    case "TH":
      return "15.870032,100.992541";
    case "TJ":
      return "38.861034,71.276093";
    case "TK":
      return "-8.967363,-171.855881";
    case "TL":
      return "-8.874217,125.727539";
    case "TM":
      return "38.969719,59.556278";
    case "TN":
      return "33.886917,9.537499";
    case "TO":
      return "-21.178986,-175.198242";
    case "TUR":
      return "38.963745,35.243322";
    case "TT":
      return "10.691803,-61.222503";
    case "TV":
      return "-7.109535,177.64933";
    case "TW":
      return "23.69781,120.960515";
    case "TZ":
      return "-6.369028,34.888822";
    case "UA":
      return "48.379433,31.16558";
    case "UG":
      return "1.373333,32.290275";
    case "USA":
      return "37.09024,-95.712891";
    case "UY":
      return "-32.522779,-55.765835";
    case "UZ":
      return "41.377491,64.585262";
    case "VA":
      return "41.902916,12.453389";
    case "VC":
      return "12.984305,-61.287228";
    case "VE":
      return "6.42375,-66.58973";
    case "VG":
      return "18.420695,-64.639968";
    case "VI":
      return "18.335765,-64.896335";
    case "VN":
      return "14.058324,108.277199";
    case "VU":
      return "-15.376706,166.959158";
    case "WF":
      return "-13.768752,-177.156097";
    case "WS":
      return "-13.759029,-172.104629";
    case "XK":
      return "42.602636,20.902977";
    case "YE":
      return "15.552727,48.516388";
    case "YT":
      return "-12.8275,45.166244";
    case "ZA":
      return "-30.559482,22.937506";
    case "ZM":
      return "-13.133897,27.849332";
    case "ZW":
      return "-19.015438,29.154857";
  }
}

export function transformCountryCodeByLat(value: string) {
  switch (value) {
    case "AD":
      return 42.546245;
    case "AE":
      return 23.424076;
    case "AF":
      return 33.93911;
    case "AG":
      return 17.060816;
    case "AI":
      return 18.220554;
    case "AL":
      return 41.153332;
    case "AM":
      return 40.069099;
    case "AN":
      return 12.226079;
    case "AO":
      return -11.202692;
    case "AQ":
      return -75.250973;
    case "AR":
      return -38.416097;
    case "AS":
      return -14.270972;
    case "AUT":
      return 47.516231;
    case "AU":
      return -25.274398;
    case "AW":
      return 12.52111;
    case "AZ":
      return 40.143105;
    case "BA":
      return 43.915886;
    case "BB":
      return 13.193887;
    case "BD":
      return 23.684994;
    case "BE":
      return 50.503887;
    case "BF":
      return 12.238333;
    case "BFA":
      return 12.238333;
    case "BG":
      return 42.733883;
    case "BGR":
      return 42.733883;
    case "BH":
      return 25.930414;
    case "BI":
      return -3.373056;
    case "BJ":
      return 9.30769;
    case "BM":
      return 32.321384;
    case "BN":
      return 4.535277;
    case "BO":
      return -16.290154;
    case "BR":
      return -14.235004;
    case "BS":
      return 25.03428;
    case "BT":
      return 27.514162;
    case "BV":
      return -54.423199;
    case "BW":
      return -22.328474;
    case "BY":
      return 53.709807;
    case "BZ":
      return 17.189877;
    case "CAN":
      return 56.130366;
    case "CC":
      return -12.164165;
    case "CD":
      return -4.038333;
    case "CF":
      return 6.611111;
    case "CG":
      return -0.228021;
    case "CH":
      return 46.818188;
    case "CIV":
      return 7.539989;
    case "CK":
      return -21.236736;
    case "CHL":
      return -35.675147;
    case "CM":
      return 7.369722;
    case "CN":
      return 35.86166;
    case "CO":
      return 4.570868;
    case "CR":
      return 9.748917;
    case "CU":
      return 21.521757;
    case "CV":
      return 16.002082;
    case "CX":
      return -10.447525;
    case "CY":
      return 35.126413;
    case "CZ":
      return 49.817492;
    case "CZE":
      return 49.817492;
    case "DEU":
      return 51.165691;
    case "DJ":
      return 11.825138;
    case "DK":
      return 56.26392;
    case "DM":
      return 15.414999;
    case "DO":
      return 18.735693;
    case "DZ":
      return 28.033886;
    case "EC":
      return -1.831239;
    case "EE":
      return 58.595272;
    case "EG":
      return 26.820553;
    case "EH":
      return 24.215527;
    case "ER":
      return 15.179384;
    case "ESP":
      return 40.463667;
    case "ETH":
      return 9.145;
    case "FIN":
      return 61.92411;
    case "FJ":
      return -16.578193;
    case "FK":
      return -51.796253;
    case "FM":
      return 7.425554;
    case "FO":
      return 61.892635;
    case "FRA":
      return 46.227638;
    case "GAB":
      return -0.803689;
    case "GBR":
      return 55.378051;
    case "GD":
      return 12.262776;
    case "GE":
      return 42.315407;
    case "GF":
      return 3.933889;
    case "GG":
      return 49.465691;
    case "GHA":
      return 7.946527;
    case "GI":
      return 36.137741;
    case "GL":
      return 71.706936;
    case "GM":
      return 13.443182;
    case "GN":
      return 9.945587;
    case "GP":
      return 16.995971;
    case "GQ":
      return 1.650801;
    case "GR":
      return 39.074208;
    case "GS":
      return -54.429579;
    case "GT":
      return 15.783471;
    case "GU":
      return 13.444304;
    case "GW":
      return 11.803749;
    case "GY":
      return 4.860416;
    case "GZ":
      return 31.354676;
    case "HK":
      return 22.396428;
    case "HM":
      return -53.08181;
    case "HN":
      return 15.199999;
    case "HR":
      return 45.1;
    case "HT":
      return 18.971187;
    case "HU":
      return 47.162494;
    case "ID":
      return -0.789275;
    case "IRL":
      return 53.41291;
    case "IL":
      return 31.046051;
    case "IM":
      return 54.236107;
    case "IN":
      return 20.593684;
    case "IO":
      return -6.343194;
    case "IQ":
      return 33.223191;
    case "IR":
      return 32.427908;
    case "IS":
      return 64.963051;
    case "ITA":
      return 41.87194;
    case "JE":
      return 49.214439;
    case "JM":
      return 18.109581;
    case "JOR":
      return 30.585164;
    case "JP":
      return 36.204824;
    case "KEN":
      return 0.1709454;
    case "KG":
      return 41.20438;
    case "KH":
      return 12.565679;
    case "KI":
      return -3.370417;
    case "KM":
      return -11.875001;
    case "KN":
      return 17.357822;
    case "KP":
      return 40.339852;
    case "KR":
      return 35.907757;
    case "KW":
      return 29.31166;
    case "KY":
      return 19.513469;
    case "KZ":
      return 48.019573;
    case "LA":
      return 19.85627;
    case "LB":
      return 33.854721;
    case "LC":
      return 13.909444;
    case "LI":
      return 47.166;
    case "LK":
      return 7.873054;
    case "LR":
      return 6.428055;
    case "LS":
      return -29.609988;
    case "LT":
      return 55.169438;
    case "LU":
      return 49.815273;
    case "LV":
      return 56.879635;
    case "LY":
      return 26.3351;
    case "MA":
      return 31.791702;
    case "MC":
      return 43.750298;
    case "MD":
      return 47.411631;
    case "ME":
      return 42.708678;
    case "MDG":
      return -18.766947;
    case "MH":
      return 7.131474;
    case "MK":
      return 41.608635;
    case "ML":
      return 17.570692;
    case "MM":
      return 21.913965;
    case "MN":
      return 46.862496;
    case "MO":
      return 22.198745;
    case "MP":
      return 17.33083;
    case "MQ":
      return 14.641528;
    case "MRT":
      return 21.00789;
    case "MS":
      return 16.742498;
    case "MT":
      return 35.937496;
    case "MU":
      return -20.348404;
    case "MV":
      return 3.202778;
    case "MW":
      return -13.254308;
    case "MX":
      return 23.634501;
    case "MY":
      return 4.210484;
    case "MZ":
      return -18.665695;
    case "NA":
      return -22.95764;
    case "NC":
      return -20.904305;
    case "NE":
      return 17.607789;
    case "NF":
      return -29.040835;
    case "NG":
      return 9.081999;
    case "NI":
      return 12.865416;
    case "NLD":
      return 52.132633;
    case "NO":
      return 60.472024;
    case "NP":
      return 28.394857;
    case "NR":
      return -0.522778;
    case "NU":
      return -19.054445;
    case "NZ":
      return -40.900557;
    case "OM":
      return 21.512583;
    case "PA":
      return 8.537981;
    case "PE":
      return -9.189967;
    case "PF":
      return -17.679742;
    case "PG":
      return -6.314993;
    case "PH":
      return 12.879721;
    case "PK":
      return 30.375321;
    case "POL":
      return 51.919438;
    case "PM":
      return 46.941936;
    case "PN":
      return -24.703615;
    case "PR":
      return 18.220833;
    case "PS":
      return 31.952162;
    case "PRT":
      return 39.399872;
    case "PW":
      return 7.51498;
    case "PY":
      return -23.442503;
    case "QA":
      return 25.354826;
    case "RE":
      return -21.115141;
    case "RO":
      return 45.943161;
    case "ROU":
      return 45.943161;
    case "RS":
      return 44.016521;
    case "RU":
      return 61.52401;
    case "RW":
      return -1.940278;
    case "SA":
      return 23.885942;
    case "SB":
      return -9.64571;
    case "SC":
      return -4.679574;
    case "SD":
      return 12.862807;
    case "SE":
      return 60.128161;
    case "SG":
      return 1.352083;
    case "SH":
      return -24.143474;
    case "SI":
      return 46.151241;
    case "SJ":
      return 77.553604;
    case "SVK":
      return 48.669026;
    case "SL":
      return 8.460555;
    case "SM":
      return 43.94236;
    case "SEN":
      return 14.497401;
    case "SO":
      return 5.152149;
    case "SR":
      return 3.919305;
    case "ST":
      return 0.18636;
    case "SV":
      return 13.794185;
    case "SY":
      return 34.802075;
    case "SZ":
      return -26.522503;
    case "TC":
      return 21.694025;
    case "TD":
      return 15.454166;
    case "TF":
      return -49.280366;
    case "TG":
      return 8.619543;
    case "TH":
      return 15.870032;
    case "TJ":
      return 38.861034;
    case "TK":
      return -8.967363;
    case "TL":
      return -8.874217;
    case "TM":
      return 38.969719;
    case "TN":
      return 33.886917;
    case "TO":
      return -21.178986;
    case "TUR":
      return 38.963745;
    case "TT":
      return 10.691803;
    case "TV":
      return -7.109535;
    case "TW":
      return 23.69781;
    case "TZ":
      return -6.369028;
    case "UA":
      return 48.379433;
    case "UG":
      return 1.373333;
    case "UGA":
      return 1.373333;
    case "USA":
      return 37.09024;
    case "UY":
      return -32.522779;
    case "UZ":
      return 41.377491;
    case "VA":
      return 41.902916;
    case "VC":
      return 12.984305;
    case "VE":
      return 6.42375;
    case "VG":
      return 18.420695;
    case "VI":
      return 18.335765;
    case "VN":
      return 14.058324;
    case "VU":
      return -15.376706;
    case "WF":
      return -13.768752;
    case "WS":
      return -13.759029;
    case "XK":
      return 42.602636;
    case "YE":
      return 15.552727;
    case "YT":
      return -12.8275;
    case "ZA":
      return -30.559482;
    case "ZM":
      return -13.133897;
    case "ZW":
      return -19.015438;
  }
}

export function transformCountryCodeByLon(value: string) {
  switch (value) {
    case "AD":
      return 1.601554;
    case "AE":
      return 53.847818;
    case "AF":
      return 67.709953;
    case "AG":
      return -61.796428;
    case "AI":
      return -63.068615;
    case "AL":
      return 20.168331;
    case "AM":
      return 45.038189;
    case "AN":
      return -69.060087;
    case "AO":
      return -17.873887;
    case "AQ":
      return -0.071389;
    case "AR":
      return -63.616672;
    case "AS":
      return -170.132217;
    case "AUT":
      return 14.550072;
    case "AU":
      return -133.775136;
    case "AW":
      return -69.968338;
    case "AZ":
      return 47.576927;
    case "BA":
      return 17.679076;
    case "BB":
      return -59.543198;
    case "BD":
      return 90.356331;
    case "BE":
      return 4.469936;
    case "BF":
      return -1.561593;
    case "BFA":
      return -1.561593;
    case "BG":
      return 25.48583;
    case "BGR":
      return 25.48583;
    case "BH":
      return 50.637772;
    case "BI":
      return -29.918886;
    case "BJ":
      return 2.315834;
    case "BM":
      return -64.75737;
    case "BN":
      return 114.727669;
    case "BO":
      return -63.588653;
    case "BR":
      return -51.92528;
    case "BS":
      return -77.39628;
    case "BT":
      return 90.433601;
    case "BV":
      return -3.413194;
    case "BW":
      return -24.684866;
    case "BY":
      return 27.953389;
    case "BZ":
      return -88.49765;
    case "CAN":
      return -106.346771;
    case "CC":
      return -96.870956;
    case "CD":
      return -21.758664;
    case "CF":
      return 20.939444;
    case "CG":
      return -15.827659;
    case "CH":
      return 8.227512;
    case "CIV":
      return -5.54708;
    case "CK":
      return -159.777671;
    case "CHL":
      return -71.542969;
    case "CM":
      return 12.354722;
    case "CN":
      return 104.195397;
    case "CO":
      return -74.297333;
    case "CR":
      return -83.753428;
    case "CU":
      return -77.781167;
    case "CV":
      return -24.013197;
    case "CX":
      return -105.690449;
    case "CY":
      return 33.429859;
    case "CZ":
      return 15.472962;
    case "CZE":
      return 15.472962;
    case "DEU":
      return 10.451526;
    case "DJ":
      return 42.590275;
    case "DK":
      return 9.501785;
    case "DM":
      return -61.370976;
    case "DO":
      return -70.162651;
    case "DZ":
      return 1.659626;
    case "EC":
      return -78.183406;
    case "EE":
      return 25.013607;
    case "EG":
      return 30.802498;
    case "EH":
      return -12.885834;
    case "ER":
      return 39.782334;
    case "ESP":
      return -3.74922;
    case "ETH":
      return 40.489673;
    case "FIN":
      return 25.748151;
    case "FJ":
      return -179.414413;
    case "FK":
      return -59.523613;
    case "FM":
      return 150.550812;
    case "FO":
      return -6.911806;
    case "FRA":
      return 2.213749;
    case "GAB":
      return 11.609444;
    case "GBR":
      return -3.435973;
    case "GD":
      return -61.604171;
    case "GE":
      return 43.356892;
    case "GF":
      return -53.125782;
    case "GG":
      return -2.585278;
    case "GHA":
      return -1.023194;
    case "GI":
      return -5.345374;
    case "GL":
      return -42.604303;
    case "GM":
      return -15.310139;
    case "GN":
      return -9.696645;
    case "GP":
      return -62.067641;
    case "GQ":
      return 10.267895;
    case "GR":
      return 21.824312;
    case "GS":
      return -36.587909;
    case "GT":
      return -90.230759;
    case "GU":
      return 144.793731;
    case "GW":
      return -15.180413;
    case "GY":
      return -58.93018;
    case "GZ":
      return 34.308825;
    case "HK":
      return 114.109497;
    case "HM":
      return -73.504158;
    case "HN":
      return -86.241905;
    case "HR":
      return 15.2;
    case "HT":
      return -72.285215;
    case "HU":
      return 19.503304;
    case "ID":
      return -113.921327;
    case "IRL":
      return -8.24389;
    case "IL":
      return 34.851612;
    case "IM":
      return -4.548056;
    case "IN":
      return 78.96288;
    case "IO":
      return -71.876519;
    case "IQ":
      return 43.679291;
    case "IR":
      return 53.688046;
    case "IS":
      return -19.020835;
    case "ITA":
      return 12.56738;
    case "JE":
      return -2.13125;
    case "JM":
      return -77.297508;
    case "JOR":
      return 36.238414;
    case "JP":
      return 138.252924;
    case "KEN":
      return 37.906193;
    case "KG":
      return 74.766098;
    case "KH":
      return 104.990963;
    case "KI":
      return -168.734039;
    case "KM":
      return -43.872219;
    case "KN":
      return -62.782998;
    case "KP":
      return 127.510093;
    case "KR":
      return 127.766922;
    case "KW":
      return 47.481766;
    case "KY":
      return -80.566956;
    case "KZ":
      return 66.923684;
    case "LA":
      return 102.495496;
    case "LB":
      return 35.862285;
    case "LC":
      return -60.978893;
    case "LI":
      return 9.555373;
    case "LK":
      return 80.771797;
    case "LR":
      return -9.429499;
    case "LS":
      return -28.233608;
    case "LT":
      return 23.881275;
    case "LU":
      return 6.129583;
    case "LV":
      return 24.603189;
    case "LY":
      return 17.228331;
    case "MA":
      return -7.09262;
    case "MC":
      return 7.412841;
    case "MD":
      return 28.369885;
    case "ME":
      return 19.37439;
    case "MDG":
      return 46.869107;
    case "MH":
      return 171.184478;
    case "MK":
      return 21.745275;
    case "ML":
      return -3.996166;
    case "MM":
      return 95.956223;
    case "MN":
      return 103.846656;
    case "MO":
      return 113.543873;
    case "MP":
      return 145.38469;
    case "MQ":
      return -61.024174;
    case "MRT":
      return -10.940835;
    case "MS":
      return -62.187366;
    case "MT":
      return 14.375416;
    case "MU":
      return -57.552152;
    case "MV":
      return 73.22068;
    case "MW":
      return -34.301525;
    case "MX":
      return -102.552784;
    case "MY":
      return 101.975766;
    case "MZ":
      return -35.529562;
    case "NA":
      return -18.49041;
    case "NC":
      return -165.618042;
    case "NE":
      return 8.081666;
    case "NF":
      return -167.954712;
    case "NG":
      return 8.675277;
    case "NI":
      return -85.207229;
    case "NLD":
      return 5.291266;
    case "NO":
      return 8.468946;
    case "NP":
      return 84.124008;
    case "NR":
      return -166.931503;
    case "NU":
      return -169.867233;
    case "NZ":
      return -174.885971;
    case "OM":
      return 55.923255;
    case "PA":
      return -80.782127;
    case "PE":
      return -75.015152;
    case "PF":
      return -149.406843;
    case "PG":
      return -143.95555;
    case "PH":
      return 121.774017;
    case "PK":
      return 69.345116;
    case "POL":
      return 19.145136;
    case "PM":
      return -56.27111;
    case "PN":
      return -127.439308;
    case "PR":
      return -66.590149;
    case "PS":
      return 35.233154;
    case "PRT":
      return -8.224454;
    case "PW":
      return 134.58252;
    case "PY":
      return -58.443832;
    case "QA":
      return 51.183884;
    case "RE":
      return -55.536384;
    case "RO":
      return 24.96676;
    case "ROU":
      return 24.96676;
    case "RS":
      return 21.005859;
    case "RU":
      return 105.318756;
    case "RW":
      return -29.873888;
    case "SA":
      return 45.079162;
    case "SB":
      return -160.156194;
    case "SC":
      return -55.491977;
    case "SD":
      return 30.217636;
    case "SE":
      return 18.643501;
    case "SG":
      return 103.819836;
    case "SH":
      return -10.030696;
    case "SI":
      return 14.995463;
    case "SJ":
      return 23.670272;
    case "SVK":
      return 19.699024;
    case "SL":
      return -11.779889;
    case "SM":
      return 12.457777;
    case "SEN":
      return -14.452362;
    case "SO":
      return 46.199616;
    case "SR":
      return -56.027783;
    case "ST":
      return 6.613081;
    case "SV":
      return -88.89653;
    case "SY":
      return 38.996815;
    case "SZ":
      return -31.465866;
    case "TC":
      return -71.797928;
    case "TD":
      return 18.732207;
    case "TF":
      return -69.348557;
    case "TG":
      return 0.824782;
    case "TH":
      return 100.992541;
    case "TJ":
      return 71.276093;
    case "TK":
      return -171.855881;
    case "TL":
      return -125.727539;
    case "TM":
      return 59.556278;
    case "TN":
      return 9.537499;
    case "TO":
      return -175.198242;
    case "TUR":
      return 35.243322;
    case "TT":
      return -61.222503;
    case "TV":
      return -177.64933;
    case "TW":
      return 120.960515;
    case "TZ":
      return -34.888822;
    case "UA":
      return 31.16558;
    case "UG":
      return 32.290275;
    case "UGA":
      return 32.290275;
    case "USA":
      return -95.712891;
    case "UY":
      return -55.765835;
    case "UZ":
      return 64.585262;
    case "VA":
      return 12.453389;
    case "VC":
      return -61.287228;
    case "VE":
      return -66.58973;
    case "VG":
      return -64.639968;
    case "VI":
      return -64.896335;
    case "VN":
      return 108.277199;
    case "VU":
      return -166.959158;
    case "WF":
      return -177.156097;
    case "WS":
      return -172.104629;
    case "XK":
      return 20.902977;
    case "YE":
      return 48.516388;
    case "YT":
      return -45.166244;
    case "ZA":
      return -22.937506;
    case "ZM":
      return -27.849332;
    case "ZW":
      return -29.154857;
  }
}

import { Project } from "models/project.model";
import ManagementToolBar from "modules/management/screens/ManagementToolBar/ManagementToolBar";
import Header from "modules/shared/components/Header/Header";
import React, { useEffect, useState } from "react";
import ManagementContentHead from "../ManagementContentHead/ManagementContentHead";
import ManagementTitlePage from "../ManagementTitlePage/ManagementTitlePage";

interface SPVManagerListProps {
  projects: Project[];
  currYear: number;
  setCurrYear: any;
  availableYear: number[];
  openSPV: any;
  createNewSPV: any;
  user: any;
}

const SPVManagerList = ({
  projects,
  currYear,
  setCurrYear,
  availableYear,
  openSPV,
  createNewSPV,
  user,
}: SPVManagerListProps) => {
  const [sortNameOrder, setSortNameOrder] = useState<string>("asc");
  const [sortLastUpdateOrder, setSortLastUpdateOrder] = useState<string>("asc");

  const [projectsList, setProjectsList] = useState<Project[]>([]);
  const [searchTerms, setSearchTerms] = useState<string>("");
  const [showOnlyMissingData, setShowOnlyMissingData] = useState<boolean>(
    false
  );
  const [showAbandonedProject, setShowAbandonedProject] = useState<boolean>(
    false
  );

  useEffect(() => {
    setProjectsList(projects);
    updateSortOrder("name", "asc");
  }, [projects]);

  const goToSurvey = (project: Project) => {
    localStorage.setItem("current_project", JSON.stringify(project));
    if ("development" !== process.env.NODE_ENV) {
      document.location.href = "/survey-sdg/survey/dashboard";
    } else {
      console.log(
        "--- FEATURE NOT AVAILABLE IN LOCAL, please open : http://localhost:4200/survey/dashboard"
      );
    }
  };

  const updateSortOrder = (type: string, order: string) => {
    let newProjectsList = projects;
    if (type === "name") {
      setSortNameOrder(order);
      if (order === "asc") {
        newProjectsList = newProjectsList.sort((a: Project, b: Project) => {
          return a.projectName.localeCompare(b.projectName);
        });
      } else {
        newProjectsList = newProjectsList.sort((a: Project, b: Project) => {
          return b.projectName.localeCompare(a.projectName);
        });
      }
    } else {
      setSortLastUpdateOrder(order);
      if (order === "asc") {
        newProjectsList = newProjectsList.sort((a: Project, b: Project) => {
          return a.investmentPhase.localeCompare(b.investmentPhase);
        });
      } else {
        newProjectsList = newProjectsList.sort((a: Project, b: Project) => {
          return b.investmentPhase.localeCompare(a.investmentPhase);
        });
      }
    }

    setProjectsList(newProjectsList);
  };

  const hasMissingData = (project: any) => {
    let missingData = false;
    !!project.countryData &&
      project.countryData.map((country: any) => {
        if (country.value === "") {
          missingData = true;
        }
      });
    return missingData;
  };

  return (
    <>
      <div className="header-wrapper" data-uk-sticky="media:768;">
        <Header
          titleContext="platform"
          colorContext={"seaLight"}
          classNameToolbarTop="spv-manager"
          tool={`spv-manager`}
        />
      </div>

      <ManagementToolBar currentTool={"spv-manager"} user={user} />

      <div className="page page-dashboard" data-theme="seaLight">
        <div className="page_inner">
          <ManagementTitlePage 
            title={`SPV Manager`}
            formCanBeSave={true} 
          />
          <div className="grid grid-tool">
            <div className="grid_inner">
              <div className="main-content bg-white tool-spv-manager">
                <ManagementContentHead
                  currentTool="svp-manager"
                  title={`${
                    !!projects && projects.length
                  } Projects for ${currYear}`}
                  searchPlaceholder="Search a project by name"
                  availableYear={availableYear}
                  currYear={currYear}
                  setCurrYear={setCurrYear}
                  searchTerms={searchTerms}
                  setSearchTerms={setSearchTerms}
                  createNewSPV={createNewSPV}
                />

                <div className="content-body">
                  <i className="label">Sort by...</i>
                  <ul className="list">
                    <li className="row filters">
                      <div className="name">
                        <button
                          className="btn btn-filter"
                          type="button"
                          onClick={() =>
                            updateSortOrder(
                              "name",
                              sortNameOrder === "asc" ? "desc" : "asc"
                            )
                          }
                        >
                          <span className="btn_label">Name</span>
                          <span className="btn_icon">
                            {sortNameOrder === "asc" ? (
                              <i className="fas fa-sort-alpha-down"></i>
                            ) : (
                              <i className="fas fa-sort-alpha-down-alt"></i>
                            )}
                          </span>
                        </button>
                      </div>
                      <div className="warning">
                        <label htmlFor="warning">Show only warning</label>
                        <input
                          type="checkbox"
                          id="warning"
                          className="warning-input"
                          onClick={() =>
                            setShowOnlyMissingData(!showOnlyMissingData)
                          }
                        />
                        <div className="warning-switcher"></div>
                      </div>
                      <div className="status">
                        <button
                          className="btn btn-filter"
                          type="button"
                          onClick={() =>
                            updateSortOrder(
                              "status",
                              sortLastUpdateOrder === "asc" ? "desc" : "asc"
                            )
                          }
                        >
                          <span className="btn_label">All status</span>
                          <span className="btn_icon">
                            {sortLastUpdateOrder === "asc" ? (
                              <i className="fas fa-sort-alpha-down"></i>
                            ) : (
                              <i className="fas fa-sort-alpha-down-alt"></i>
                            )}
                          </span>
                        </button>
                      </div>
                      <div className="open"></div>
                      <div className="survey">
                        <label htmlFor="projectStatus">Show abandoned project</label>
                        <input
                          type="checkbox"
                          id="projectStatus"
                          className="survey-input"
                          onClick={() =>
                            setShowAbandonedProject(!showAbandonedProject)
                          }
                        />
                        <div className="survey-switcher"></div>
                      </div>
                    </li>

                    {!!projectsList &&
                      projectsList.length > 0 &&
                      projectsList
                        .filter((p: Project) => {
                          if (
                            searchTerms === "" ||
                            (searchTerms !== "" &&
                              (p.projectName
                                .toLocaleLowerCase()
                                .match(searchTerms.toLocaleLowerCase()) ||
                                p.projectShortName
                                  .toLocaleLowerCase()
                                  .match(searchTerms.toLocaleLowerCase())))
                          ) {
                            return p;
                          }
                        })
                        .filter((p: Project) => {
                          if (showOnlyMissingData === false) {
                            return p;
                          } else {
                            if (hasMissingData(p) === true) {
                              return p;
                            }
                          }
                        })
                        .filter((p: Project) => {
                          if (showAbandonedProject === true) {
                            return p;
                          } else {
                            if (p.investmentPhase !== "Abandoned") {
                              return p;
                            }
                          }
                        })
                        .map((project: Project, idx: number) => {
                          return (
                            <li key={`projectK-${idx}`} className="row">
                              <div className="name">{project.projectName}</div>
                              <div className="warning">
                                {hasMissingData(project) === true && (
                                  <>
                                    <i className="fas fa-exclamation-circle"></i>
                                    <span>Edit country data</span>
                                  </>
                                )}
                              </div>
                              <div className="status">
                                {project.investmentPhase.replace("9 - ", "")}
                              </div>
                              <div className="open">
                                <button
                                  onClick={() => openSPV(project)}
                                  className="btn btn-select btn-primary"
                                  type="button"
                                >
                                  <span className="btn-label">Open</span>
                                  <span className="btn-icon">
                                    <i className={`fas fa-folder-open`}></i>
                                  </span>
                                </button>
                              </div>
                              <div className="survey">
                                {
                                  project.hasSurvey === true &&
                                    <button
                                      className="btn btn-select btn-primary"
                                      type="button"
                                      onClick={() => goToSurvey(project)}
                                    >
                                      <span className="btn-label">
                                        Go to survey
                                      </span>
                                      <span className="btn-icon">
                                        <i className={`fas fa-arrow-right`}></i>
                                      </span>
                                    </button>
                                }
                              </div>
                            </li>
                          );
                        })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SPVManagerList;

import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import LimitedPartnersDashboard from "./screens/LimitedPartnersDashboard/LimitedPartnersDashboard";

const LimitedPartnersRoutes = () => {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <LimitedPartnersDashboard />
      </Route>

      <Redirect from={`${path}`} to={`${path}/dashboard`} />
    </Switch>
  );
};

export default LimitedPartnersRoutes;

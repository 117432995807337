import React, { FC } from "react";
import { FundFilter } from "../../../../../limited-partners/models/available-filters.model";
import "./styles.scss";

type Props = {
  meridiamFundList: FundFilter[];
  setMeridiamFund: (arg: string[]) => void;
  meridiamFundSelected: string[];
};

const Dropdown: FC<Props> = ({
  meridiamFundList,
  setMeridiamFund,
  meridiamFundSelected,
}) => {
  const toggleFund = (fund: string) => {
    //1 - toogle all
    if (fund === "all" && !meridiamFundSelected.includes("all")) {
      setMeridiamFund(["all"]);
      return;
    } else if (fund === "all" && meridiamFundSelected.includes("all")) {
      // btn non cliquable.
      return;
    }

    // 2 - toggle specific value but "all" is already selected
    if (meridiamFundSelected.includes("all")) {
      let newSelected: string[] = [];
      meridiamFundList.forEach((item) => {
        if (item.fundId === fund) {
          newSelected.push(item.fundId);
        }
      });
      setMeridiamFund(newSelected);
      return;
    }

    // 3 - toggle specific value but "all" is not selected
    if (meridiamFundSelected.includes(fund)) {
      // fund already selected, remove it
      const filtered = meridiamFundSelected.filter((item) => item !== fund);

      if (filtered.length !== 0) {
        setMeridiamFund(filtered);
      }
    } else {
      const newSelected = [...meridiamFundSelected, fund];

      if (newSelected.length === meridiamFundList.length) {
        setMeridiamFund(["all"]);
      } else {
        setMeridiamFund(newSelected);
      }
    }
  };

  return (
    <div className="dropdown dropdown-funds">
      <button className="dropdown__title" type="button">
        <span className="btn_label">
          {meridiamFundSelected[0] === "all"
            ? "All funds"
            : meridiamFundSelected.length > 1
            ? meridiamFundSelected[0] + ",..."
            : meridiamFundSelected.join()}
        </span>
      </button>
      <div
        id="DropdownFunds"
        className="dropdown__content"
        data-uk-dropdown="mode: click; animation: uk-animation-slide-top-small; duration: 500"
      >
        <ul className="uk-nav uk-dropdown-nav row row__checkbox">
          <li value="all Meridiam funds" className="dropdown___choice">
            <div className="input input_all">
              <input
                type="checkbox"
                checked={meridiamFundSelected[0] === "all"}
                id="all"
                onChange={() => toggleFund("all")}
              />
              <label
                className="checkbox__like checkbox__all"
                htmlFor="all"
              ></label>
              <label className="checkbox__label" htmlFor="all">
                <span>All Meridiam funds</span>
              </label>
            </div>
          </li>
          {!!meridiamFundList && meridiamFundList.map((fund) => {
            return (
              <li key={fund.fundId} className="dropdown___choice">
                <div className="input">
                  <input
                    type="checkbox"
                    // value={fund.fundId}
                    checked={
                      meridiamFundSelected[0] === "all" ||
                      meridiamFundSelected.includes(fund.fundId)
                    }
                    id={`meridamFund${fund.fundId}`}
                    onChange={() => toggleFund(fund.fundId)}
                  />
                  <label
                    className="checkbox__like"
                    htmlFor={`meridamFund${fund.fundId}`}
                  ></label>
                  <label
                    className="checkbox__label"
                    htmlFor={`meridamFund${fund.fundId}`}
                  >
                    <span>{fund.fundName}</span>
                  </label>
                </div>
              </li>
            );
          })}
        </ul>

        <button
          className="back"
          data-uk-toggle="target: #DropdownFunds; cls: uk-open;"
        >
          <span className="back__icon">
            <i className="far fa-arrow-left"></i>
          </span>
          <span className="back__label"> Back to filters</span>
        </button>
      </div>
    </div>
  );
};

export default Dropdown;

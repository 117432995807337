import React, { useEffect, useState } from "react";
import ManagementTitlePage from "../ManagementTitlePage/ManagementTitlePage";
import ManagementFooterPage from "../ManagementFooterPage/ManagementFooterPage";
import "./styles.scss";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { updateCountryData } from "modules/shared/services/country-data.service";
import CountryDataEditItem from "../CountryDataEditItem/CountryDataEditItem";
import { CountryData } from "models/country-data.model";
import { cloneDeep } from "lodash";

const { DateTime } = require("luxon");

const toastConfig = {
  position: "top-right" as any,
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
};

interface CountryDataEditProps {
  currCountryData: any;
  currYear: number;
  setCurrCountryData: any;
  accessToken: string;
  userName: string;
  currCountList: any;
  setCountryData: any;
  countryData: CountryData[];
}

const CountryDataEdit = ({
  currCountryData,
  currYear,
  setCurrCountryData,
  accessToken,
  userName,
  currCountList,
  setCountryData,
  countryData,
}: CountryDataEditProps) => {
  const [modifiedCountryData, setModifiedCountryData] = useState<any[]>([]);

  const [sortNameOrder, setSortNameOrder] = useState<string>("asc");
  const [sortValueOrder, setSortValueOrder] = useState<string>("asc");
  const [sortLastUpdateOrder, setSortLastUpdateOrder] = useState<string>("asc");

  const [lastUpdateDate, setLastUpdateDate] = useState<any>("");
  const [lastUpdateName, setLastUpdateName] = useState<string | undefined>("");

  const [isMCQ, setIsMCQ] = useState<boolean>(false);
  const [questionData, setQuestionData] = useState<any>();

  const [formDirty, setFormDirty] = useState<string[]>([]);

  useEffect(() => {
    // setup lastUpdate Date + Name
    let newDataList = currCountList;
    newDataList = newDataList.sort((a: any, b: any) => {
      return b.currencyVal?.lastUpdate.localeCompare(a.currencyVal?.lastUpdate);
    });

    if(newDataList.length > 0){
      setLastUpdateName(newDataList[0]?.data?.userLastUpdate);
      if (!!newDataList[0].data && !!newDataList[0].data.lastUpdate) {
        setLastUpdateDate(newDataList[0].data.lastUpdate);
      }
    }
    setQuestionData(currCountryData);
    setModifiedCountryData(newDataList);

    // set up type of value
    if (
      !!currCountryData &&
      !!currCountryData.dataType &&
      currCountryData.dataType === "binary"
    ) {
      setIsMCQ(true);
    }
  }, [currCountList]);

  const updateSortOrder = (type: string, order: string) => {
    let newDataList = currCountryData.countries;
    switch (type) {
      case "date":
        setSortLastUpdateOrder(order);
        if (order === "asc") {
          newDataList = newDataList.sort((a: any, b: any) => {
            return a.data?.lastUpdate.localeCompare(b.data?.lastUpdate);
          });
        } else {
          newDataList = newDataList.sort((a: any, b: any) => {
            return b.data?.lastUpdate.localeCompare(a.data?.lastUpdate);
          });
        }
        break;
      case "value":
        setSortValueOrder(order);
        if (order === "asc") {
          newDataList = newDataList.sort((a: any, b: any) => {
            return a.data.value - b.data.value;
          });
        } else {
          newDataList = newDataList.sort((a: any, b: any) => {
            return b.data.value - a.data.value;
          });
        }
        break;
      default:
        setSortNameOrder(order);
        if (order === "asc") {
          newDataList = newDataList.sort((a: any, b: any) => {
            return a.name.localeCompare(b.name);
          });
        } else {
          newDataList = newDataList.sort((a: any, b: any) => {
            return b.name.localeCompare(a.name);
          });
        }
        break;
    }

    setModifiedCountryData(newDataList);
  };

  const handleSave = (countryCode?: string) => {
    let items: any = [];
    if (!!countryCode) {
      // description or link saved
      setFormDirty(formDirty.filter((i) => countryCode !== i));
      modifiedCountryData.map((val) => {
        if (!!val.data && !!val.data.countryCode && val.data.countryCode === countryCode) {
          items.push(val.data);
        }
      });
    } else {
      // global saved
      setFormDirty([]);
      modifiedCountryData.map((val) => {
        items.push(val.data);
      });
    }

    updateCountryData(accessToken, items).then((result: any) => {
      if (result.status === "ok") {
        toast.success("The list has been updated successfully.", toastConfig);
        setLastUpdateDate(new Date().toISOString());
        setLastUpdateName(userName);
        let newCountryData = cloneDeep(countryData);
        newCountryData.map((cd: CountryData) => {
          if (currCountList[0].data.indicatorId === cd.indicatorId) {
            cd.countries = modifiedCountryData;
          }
        });
        setCountryData(newCountryData);
      } else {
        toast.error("Error occured.", toastConfig);
      }
    });
  };

  const handleChange = (event: any, field: string, countryCode: string) => {
    const value = event.target.value;
    let values = modifiedCountryData;
    values.map((val) => {
      if (val.code === countryCode) {
        if (!val.data) {
          val.data = {
            description: field === "description" ? value : "",
            value: field === "value" ? value : undefined,
            link: field === "link" ? value : null,
            year: currYear,
            countryCode: val.code,
            indicatorId: questionData.indicatorId,
          };
        } else {
          switch (field) {
            case "link":
              val.data.link = value;
              break;
            case "description":
              val.data.description = value;
              break;
            case "value":
              val.data.value = value;
              break;
          }
        }
        setFormDirty((formDirty) => [...formDirty, val.code]);
      }
    });
    setModifiedCountryData(values);
  };

  return (
    <>
      <ManagementTitlePage
        title={`Country Data indicators`}
        isSavable={true}
        updatedBy={lastUpdateName}
        updatedOn={DateTime.fromISO(lastUpdateDate).toFormat("LL/dd/yyyy")}
        handleClose={() => setCurrCountryData(null)}
        handleSave={handleSave}
        formDirty={formDirty}
        formCanBeSave={true}
      />
      <div className="back-btn">
        <button
          onClick={() => setCurrCountryData(null)}
          className="btn btn-select btn-primary"
          type="button"
        >
          <span className="btn-icon">
            <i className={`fas fa-arrow-left`}></i>
          </span>
          <span className="btn-label">Back to {currYear} indicators list</span>
        </button>
      </div>

      <div className="grid grid-tool">
        <div className="grid_inner">
          <div className="main-content bg-white tool-country-data-edit">
            <div className="content-head">
              <div className="id">
                <h2>{currCountryData.indicatorNumbering}</h2>
              </div>
              <div className="target">
                <h3>{currCountryData.question}</h3>
              </div>
              <div className="indicator">
                <h4>{currCountryData.unit}</h4>
              </div>
            </div>

            <div className="content-body">
              <i className="label">Sort by...</i>
              <ul className="list">
                <li className="row filters">
                  <div className="row-content">
                    <div className="name">
                      <button
                        className="btn btn-filter"
                        type="button"
                        onClick={() =>
                          updateSortOrder(
                            "name",
                            sortNameOrder === "asc" ? "desc" : "asc"
                          )
                        }
                      >
                        <span className="btn_label">Name</span>
                        <span className="btn_icon">
                          {sortNameOrder === "asc" ? (
                            <i className="fas fa-sort-alpha-down"></i>
                          ) : (
                            <i className="fas fa-sort-alpha-down-alt"></i>
                          )}
                        </span>
                      </button>
                    </div>
                    <div className="description">
                      <i>Description</i>
                    </div>
                    <div className="link">
                      <i>Link</i>
                    </div>
                    {!isMCQ && (
                      <div className="value">
                        <button
                          className="btn btn-filter"
                          type="button"
                          onClick={() =>
                            updateSortOrder(
                              "value",
                              sortValueOrder === "asc" ? "desc" : "asc"
                            )
                          }
                        >
                          <span className="btn_label">Value</span>
                          <span className="btn_icon">
                            {sortValueOrder === "asc" ? (
                              <i className="fas fa-sort-numeric-down"></i>
                            ) : (
                              <i className="fas fa-sort-numeric-down-alt"></i>
                            )}
                          </span>
                        </button>
                      </div>
                    )}

                    <div className="last-year-value">
                      <i>Last year value</i>
                    </div>
                    
                    <div className="last-update">
                      <button
                        className="btn btn-filter"
                        type="button"
                        onClick={() =>
                          updateSortOrder(
                            "date",
                            sortLastUpdateOrder === "asc" ? "desc" : "asc"
                          )
                        }
                      >
                        <span className="btn_label">Last update</span>
                        <span className="btn_icon">
                          {sortLastUpdateOrder === "asc" ? (
                            <i className="fas fa-sort-numeric-down"></i>
                          ) : (
                            <i className="fas fa-sort-numeric-down-alt"></i>
                          )}
                        </span>
                      </button>
                    </div>
                  </div>
                </li>

                {!!modifiedCountryData &&
                  modifiedCountryData.map((country: any, idx: number) => {
                    return (
                      <CountryDataEditItem
                        key={`countryDataK-${idx}`}
                        isMCQ={isMCQ}
                        currCountryData={currCountryData}
                        country={country}
                        handleChange={handleChange}
                        handleSave={handleSave}
                        formDirty={formDirty}
                      />
                    );
                  })}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />

      <ManagementFooterPage
        isSavable={true}
        updatedBy={lastUpdateName}
        updatedOn={DateTime.fromISO(lastUpdateDate).toFormat("LL/dd/yyyy")}
        handleClose={() => setCurrCountryData(null)}
        handleSave={handleSave}
        formDirty={formDirty}
        formCanBeSave={true}
      />
    </>
  );
};

export default CountryDataEdit;

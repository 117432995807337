import axios from "axios";
import config from "modules/config";

const ApiBaseUrl = config.ApiUrl;

export const sendContactUS = async (
  accessToken: string,
  message: any
): Promise<any> => {
  const result = await axios({
    method: "post",
    url: `${ApiBaseUrl}/contact/contact-us-lp`,
    headers: { Authorization: "Bearer " + accessToken },
    data: message,
  });

  return result.data;
};

export const sendContactUSReporting = async (
  accessToken: string,
  message: any
): Promise<any> => {
  const result = await axios({
    method: "post",
    url: `${ApiBaseUrl}/contact/contact-us-reporting`,
    headers: { Authorization: "Bearer " + accessToken },
    data: message,
  });

  return result.data;
};

export const sendNeedSupport = async (datas: any): Promise<any> => {
  const result = await axios({
    method: "post",
    url: `${ApiBaseUrl}/contact/technical-support`,
    data: datas,
  });

  return result.data;
};

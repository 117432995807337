import React, { useEffect, useState } from "react";
import UIkit from "uikit";
import { Currency } from "models/currency.model";

const FundListItem: React.FC<{
  fund: any;
  modifiedCurrProject: any;
  currenciesList: Currency[];
  handleChange: (
    type: string,
    value: string,
    meridiamFundCode?: string
  ) => void;
  formDirty: string[];
  setFormDirty: any;
}> = ({
  fund,
  modifiedCurrProject,
  currenciesList,
  handleChange,
  formDirty,
  setFormDirty,
}) => {
  const defaultFund = {
    fundsCommitted: "",
    fundsCommittedCurrency: "",
    meridiamFund: {
      fundName: fund.fundName,
      fundId: fund.fundId,
    },
  };
  const [currFund, setCurrFund] = useState(defaultFund);

  useEffect(() => {
    if (
      modifiedCurrProject.meridiamFunds &&
      modifiedCurrProject.meridiamFunds.length > 0
    ) {
      modifiedCurrProject.meridiamFunds.map((mF: any) => {
        if (mF.meridiamFund.fundId === fund.fundId) {
          setCurrFund(mF);
        }
      });
    }
  }, [modifiedCurrProject]);

  return (
    <li
      className={
        formDirty.find((i) => i === fund.fundId) ? "row is-modified" : "row"
      }
    >
      <div className="fund">{fund.fundName}</div>
      <div className="fund-committed">
        <input
          id="funds-committed"
          type="number"
          placeholder="ex: 478.26"
          value={currFund.fundsCommitted}
          onChange={(e) =>
            handleChange("fundsCommitted", e.target.value, fund.fundId)
          }
        />
      </div>
      <div className="currency">
        <div className="selector">
          <div className="select">
            <button
              className="btn btn-select btn-primary full btn-rounded"
              type="button"
            >
              <span className="btn_label">
                {!!currFund.fundsCommittedCurrency
                  ? currFund.fundsCommittedCurrency
                  : "-----"}
              </span>
              <span className="btn_icon"></span>
            </button>
            <div
              className="drop drop-select fund-currency"
              data-uk-dropdown="pos: top-left; mode: click; delay-hide: 100"
            >
              <ul className="group lvl0 uk-nav uk-dropdown-nav">
                {!!currenciesList &&
                  currenciesList.length > 0 &&
                  currenciesList.map((currency: Currency, idx: number) => {
                    return (
                      <li
                        className={
                          currency.currency === currFund.fundsCommittedCurrency
                            ? "item selected"
                            : "item"
                        }
                        key={`itemK-${idx}`}
                        data-uk-toggle="target: .fund-currency"
                        onClick={() => {
                          handleChange(
                            "fundsCommittedCurrency",
                            currency.currency,
                            fund.fundId
                          );
                          UIkit.dropdown(".fund-currency").hide();
                        }}
                      >
                        <span className="clickable">{currency.currency}</span>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};

export default FundListItem;

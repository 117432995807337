import React, { FC } from "react";
import { AvailableFilters } from "../../../../../limited-partners/models/available-filters.model";

import "./styles.scss";

type Props = {
  filter: AvailableFilters;
  currency: string;
  switchCurrency: any;
};

const Switcher: FC<Props> = ({ filter, currency, switchCurrency }) => {
  // console.log(filter.filter);

  return (
    <>
      {filter.currencyChange && (
        <div className="switcher switcher-currency">
          <input
            type="checkbox"
            id="money"
            className="cmp__switcher"
            checked={"EUR" === currency}
            onChange={() => switchCurrency(currency === "EUR" ? "USD" : "EUR")}
          />
          <div className="cmp___labels">
            <label className="switcher__label label__on" htmlFor="money">
              <span>Euro</span>
            </label>
            <label className="switcher__like" htmlFor="money"></label>
            <label className="switcher__label label__off" htmlFor="money">
              <span>US dollar</span>
            </label>
          </div>
          {"EUR" === currency ? (
            <span className="switcher__currency">
              (1€ = {(1 / filter.currencyChange.currPerDollar).toFixed(2)}$ end
              of {filter.currencyChange.year})
            </span>
          ) : (
            <span className="switcher__currency">
              (1$ = {filter.currencyChange.currPerDollar.toFixed(2)}€ end of{" "}
              {filter.currencyChange.year})
            </span>
          )}
        </div>
      )}
    </>
  );
};

export default Switcher;

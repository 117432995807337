import axios from "axios";
import { Survey } from "models/survey.model";
// import { CalculatedQuestion, Survey } from "models/survey.model";
// import { map, switchMap } from "rxjs/operators";
import config from "modules/config";

const ApiBaseUrl = config.ApiUrl;

const getAllSurveysByYear = async (
  accessToken: string,
  currentYear: number
): Promise<Survey[]> => {
  const result = await axios({
    method: "get",
    url: `${ApiBaseUrl}/survey/year/${currentYear}`,
    headers: { Authorization: "Bearer " + accessToken },
  });

  return result.data;
};

// TODO : CONVERT SURVEY-DATA-BUILDER TO REACT

const getSurveyById = async (
  accessToken: string,
  surveyId: number
): Promise<Survey> => {
  const result = await axios({
    method: "get",
    url: `${ApiBaseUrl}/survey/${surveyId}`,
    headers: { Authorization: "Bearer " + accessToken },
  });

  // result.data.pipe(
  //   switchMap(async response => {
  //     var calculatedData = await getAllCalculatedQuestion(accessToken, surveyId);
  //     return {
  //       surveyDetails: response,
  //       calculatedData
  //     };
  //   }),
  //   map(data => {
  //     return SurveyDataBuilder.buildFromDto(
  //       data.surveyDetails,
  //       data.calculatedData,
  //       year,
  //       this.authenticationDataService.userRole
  //     );
  //   })
  // );

  return result.data;
};

const getSurveyMateriality = async (
  accessToken: string,
  surveyId: number
): Promise<Survey> => {
  const result = await axios({
    method: "get",
    url: `${ApiBaseUrl}/survey/materiality/${surveyId}`,
    headers: { Authorization: "Bearer " + accessToken },
  });

  return result.data;
};

const getCampaigns = async (accessToken: string): Promise<any> => {
  const result = await axios({
    method: "get",
    url: `${ApiBaseUrl}/surveyReferential/all`,
    headers: { Authorization: "Bearer " + accessToken },
  });

  return result.data;
}

// const getAllCalculatedQuestion = async (
//   accessToken: string,
//   surveyId: number
// ): Promise<CalculatedQuestion[]> => {
//   let headers = {
//     "Content-Type": "application/json",
//     Authorization: `Bearer ${accessToken}`
//   }

//   const calculatedQuestionRoute = `${ApiBaseUrl}/survey/${surveyId}/calculations`;

//   return axios.get<CalculatedQuestion[]>(calculatedQuestionRoute, {
//       headers
//     })
//     .pipe(
//       map(response =>
//         response.map(item => ({
//           type: item.type,
//           questionId: item.questionId,
//           indicatorId: item.indicatorId,
//           result: item.result
//         }))
//       )
//     )
//     .toPromise();
// }

export { getAllSurveysByYear, getSurveyById, getSurveyMateriality, getCampaigns };

export function positionMapByContinent(value: string) {
  switch (value) {
    case "africa":
      return { lat: 42.546245, lon: 1.601554 };
    case "asia":
      return { lat: 42.546245, lon: 1.601554 };
    case "default":
      return;
  }
}

export function zoomMapByContinent(value: string): Array<Number> {
  switch (value) {
    case "africa":
      return [1920, 6800, -5200, -8000]; // OK
    case "asia":
      return [4230, 8780, -8390, -6140]; // Ok
    case "europe":
      return [2820, 5820, -9000, -7550]; // Ok
    case "north-america":
      return [-1400, 3400, -8600, -7200]; // OK
    case "south-america":
      return [-142, 4657, -7044, -4670]; // OK
    case "oceania":
      return [6180, 9600, -6600, -4900]; // OK
  }
  return [];
}

import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highchartsMap from "highcharts/modules/map";
import proj4 from "proj4";
//import mapDataIE from "@highcharts/map-collection/countries/ie/ie-all.geo.json";
import mapDataWorldContinent from "@highcharts/map-collection/custom/world-continents.geo.json";
import mapDataWorld from "@highcharts/map-collection/custom/world-highres3.geo.json";
import { zoomMapByContinent } from "../../../shared/utils/map-manager";

import {
  transformCountryCodeByLat,
  transformCountryCodeByLon,
} from "../../../shared/utils/latlon";
import { GeographicalOverview } from "modules/limited-partners/models/allocation-result.model";


if (typeof window !== "undefined") {
  window.proj4 = window.proj4 || proj4;
}
if (typeof Highcharts === "object") {
  highchartsMap(Highcharts);
}

const buildChartFromGeographicalOverview = (
  geographicalOverview: GeographicalOverview[]
) => {
  let seriesMap: any = {
    data: [],
  };
  let seriesBubble: any = {
    data: [],
  };
  let seriesBubbleContinent: any = {
    data: [],
  };

  const sumAfrica = _(geographicalOverview)
    .filter((item: GeographicalOverview) => item.continent === "africa")
    .sumBy((item: GeographicalOverview) => item.total);

  const sumCountryInAfrica = _(geographicalOverview)
    .filter((item: GeographicalOverview) => item.continent === "africa")
    .size();

  const sumAmericaSouth = _(geographicalOverview)
    .filter((item: GeographicalOverview) => item.continent === "south-america")
    .sumBy((item: GeographicalOverview) => item.total);

  const sumCountryInAmericaSouth = _(geographicalOverview)
    .filter((item: GeographicalOverview) => item.continent === "south-america")
    .size();

  const sumAmericaNorth = _(geographicalOverview)
    .filter((item: GeographicalOverview) => item.continent === "north-america")
    .sumBy((item: GeographicalOverview) => item.total);

  const sumCountryInAmericaNorth = _(geographicalOverview)
    .filter((item: GeographicalOverview) => item.continent === "north-america")
    .size();

  const sumEurope = _(geographicalOverview)
    .filter((item: GeographicalOverview) => item.continent === "europe")
    .sumBy((item: GeographicalOverview) => item.total);

  const sumCountryInEurope = _(geographicalOverview)
    .filter((item: GeographicalOverview) => item.continent === "europe")
    .size();

  const sumAsia = _(geographicalOverview)
    .filter((item: GeographicalOverview) => item.continent === "asia")
    .sumBy((item: GeographicalOverview) => item.total);

  const sumCountryInAsia = _(geographicalOverview)
    .filter((item: GeographicalOverview) => item.continent === "asia")
    .size();

  const sumOceania = _(geographicalOverview)
    .filter((item: GeographicalOverview) => item.continent === "oceania")
    .sumBy((item: GeographicalOverview) => item.total);

  const sumCountryInOceania = _(geographicalOverview)
    .filter((item: GeographicalOverview) => item.continent === "oceania")
    .size();

  if (sumAmericaNorth > 0) {
    seriesBubbleContinent.data.push({
      z: sumAmericaNorth,
      countries: sumCountryInAmericaNorth,
      name: "North America",
      continent: "north-america",
      code: "NA",
      drilldown: "north-america",
      lat: 47.336031,
      lon: -103.262109,
    });
  }
  if (sumAmericaSouth > 0) {
    seriesBubbleContinent.data.push({
      z: sumAmericaSouth,
      countries: sumCountryInAmericaSouth,
      name: "Latina America",
      continent: "south-america",
      code: "SA",
      drilldown: "south-america",
      lat: -18.312811,
      lon: -60.443163,
    });
  }

  if (sumAfrica > 0) {
    seriesBubbleContinent.data.push({
      z: sumAfrica,
      countries: sumCountryInAfrica,
      name: "Africa",
      continent: "africa",
      code: "AF",
      drilldown: "africa",
      lat: 9.838979,
      lon: 19.928764,
    });
  }
  if (sumEurope > 0) {
    seriesBubbleContinent.data.push({
      z: sumEurope,
      countries: sumCountryInEurope,
      name: "Europe",
      continent: "europe",
      code: "EU",
      drilldown: "europe",
      lat: 46.316584,
      lon: 7.763248,
    });
  }
  if (sumAsia > 0) {
    seriesBubbleContinent.data.push({
      z: sumAsia,
      countries: sumCountryInAsia,
      name: "Asia",
      continent: "asia",
      code: "AS",
      drilldown: "asia",
      lat: 32.842674,
      lon: 72.845737,
    });
  }
  if (sumOceania > 0) {
    seriesBubbleContinent.data.push({
      z: sumOceania,
      countries: sumCountryInOceania,
      name: "Oceanie",
      continent: "oceania",
      code: "OC",
      drilldown: "oceania",
      lat: -13.923404,
      lon: 135.113849,
    });
  }

  geographicalOverview.forEach((item: any) => {
    seriesMap.data.push({
      id: item.countryCode,
      value: item.total,
      name: item.countryName,
      code3: item.countryCode,
    });
  });

  geographicalOverview.forEach((item: any) => {
    seriesBubble.data.push({
      z: item.total,
      name: item.countryName,
      lat: transformCountryCodeByLat(item.countryCode),
      lon: transformCountryCodeByLon(item.countryCode),
      continent: item.continent,
      code3: item.countryCode,
    });
  });

  // console.log("DATA series map : ", seriesMap);
  // console.log("DATA bubble : ", seriesBubble);

  const parentChart = document.getElementById("chartWrapper") as HTMLElement;

  return {
    chart: {
      mapChart: "mapChart",
      width: null,
      animation: 0,
      height: 464,
      className: "chart chart-map chart__own",
      map: "custom/world",
      zoomType: "z",
      borderWidth: 1,
      spacing: [0, 0, 0, 0],
      responsive: true,
      styledMode: true,
      useHTML: true,
      events: {
        load: function (this: any) {
          let xMin = -999;
          let xMax = 9851;
          parentChart.setAttribute("data-zoomlevel", "1");
          this.xAxis[0].setExtremes(xMin, xMax);
        },
        redraw: function (this: any) {
          var min, max;
          ({ min, max } = this.axes[0].getExtremes());
          var min1 = this.axes[0].dataMin;
          var max1 = this.axes[0].dataMax;
          let zoom = (max1 - min1) / (max - min);
          parentChart.setAttribute("data-zoomlevel", zoom.toFixed(0));
        },
      },
    },
    title: {
      text: null,
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    mapNavigation: {
      enabled: false,
      enableDoubleClickZoomTo: false,
      buttonOptions: {
        verticalAlign: "top",
      },
      mouseWheelSensitivity: 10,
    },
    tooltip: {
      enabled: false,
    },
    xAxis: {
      min: -999,
      max: 9851,
    },
    yAxis: {},
    series: [
      {
        name: "BasemapContinent",
        mapData: mapDataWorldContinent,
        borderColor: "#102D40",
      },
      {
        name: "Basemap",
        mapData: mapDataWorld,
        borderColor: "#102D40",
      },
      {
        name: "DataMap",
        type: "map",
        data: seriesMap.data,
        mapData: mapDataWorld,
        allowPointSelect: false,
        enableMouseTracking: false,
        joinBy: ["iso-a3", "code3"],
        allAreas: false,
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      {
        allowPointSelect: true,
        type: "mapbubble",
        data: seriesBubbleContinent.data,
        joinBy: ["iso-2", "code"],
        cursor: "pointer",
        point: {
          events: {
            click: function (this: any, err: any) {
              console.log("this.continent", this.continent);

              let chart = this.series.chart;
              chart.fromLatLonToPoint({ lat: this.lat, lon: this.lon });
              const zmc = zoomMapByContinent(this.continent);

              if (zmc.length) {
                chart.xAxis[0].setExtremes(zmc[0], zmc[1], false);
                chart.yAxis[0].setExtremes(zmc[2], zmc[3], false);
                chart.redraw();
              }
              geographicalOverview.map((o) => {
                o.current = o.continent === this.continent;
                parentChart.setAttribute("data-regionselected", this.continent);
                return o;
              });
              var min, max;
              ({ min, max } = chart.axes[0].getExtremes());
              var min1 = chart.axes[0].dataMin;
              var max1 = chart.axes[0].dataMax;
              let zoom = (max1 - min1) / (max - min);
              parentChart.setAttribute("data-zoomlevel", zoom.toFixed(0));
            },
          },
        },
        minSize: 60,
        maxSize: 150,
        dataLabels: {
          styledMode: true,
          useHTML: true,
          enabled: true,
          formatter: function (this: any) {
            if (this.point.z > 0) {
              if (this.point.countries > 0) {
                return (
                  '<div class="legends legends-bubble"><div class="legend legend-bubble"><div class="legend_inner">' +
                  '<div class="legend__label"><span><strong>' +
                  this.point.z.toFixed(1) +
                  "</strong></span></div>" +
                  '<div class="legend__label"><span>' +
                  this.point.name +
                  "</span></div>" +
                  '<div class="legend__label"><span><small>(' +
                  this.point.countries.toFixed(0) +
                  (this.point.countries >= 2 ? " countries" : " country") +
                  ")" +
                  "</small></span>" +
                  "</div></div></div>"
                );
              } else {
                return (
                  '<div class="legends legends-bubble"><div class="legend legend-bubble"><div class="legend_inner">' +
                  '<div class="legend__label"><span><strong>' +
                  this.point.z.toFixed(1) +
                  "</strong></span></div>" +
                  '<div class="legend__label"><span>' +
                  this.point.name +
                  "</span></div>" +
                  "</div></div></div>"
                );
              }
            }
          },
        },
        tooltip: {
          enabled: false,
        },
      },
      {
        // Specify points using lat/lon
        allowPointSelect: true,
        type: "mapbubble",
        data: seriesBubble.data,
        joinBy: ["code3", "name"],
        minSize: 30,
        maxSize: 100,
        dataLabels: {
          styledMode: true,
          useHTML: true,
          enabled: true,
          formatter: function (this: any) {
            return (
              '<div class="legends legends-bubble"><div class="legend legend-bubble"><div class="legend_inner">' +
              '<div class="legend__label"><span><strong>' +
              this.point.z.toFixed(2) +
              "</strong></span></div>" +
              '<div class="legend__label"><span>' +
              this.point.name +
              "</span></div>" +
              "</div></div></div>"
            );
          },
        },
        tooltip: {
          enabled: false,
        },
      },
    ],
  };
};

class GeographicalOverviewChart extends React.Component<{
  geographicalOverview: GeographicalOverview[];
  setZoomLevel: any;
  zoomLevel: number;
}> {
  internalChart: any;

  chartZoomInit = (level: number) => {
    console.log("init", level);
    this.internalChart.mapZoom(level);
    this.internalChart.xAxis[0].setExtremes(-999, 9851, false);
    this.internalChart.yAxis[0].setExtremes(-9940, -4578, false);
    this.internalChart.redraw();
  };

  chartZoomCenter = () => {
    const currentContinent = this.props.geographicalOverview.filter(
      (o) => !!o.current
    );
    console.log("currentContinent", currentContinent);
    const zmc = zoomMapByContinent(
      currentContinent.length ? currentContinent[0].continent : ""
    );
    console.log("zmc", zmc);
    if (zmc.length) {
      this.internalChart.xAxis[0].setExtremes(zmc[0], zmc[1], false);
      this.internalChart.yAxis[0].setExtremes(zmc[2], zmc[3], false);
      this.internalChart.redraw();
    }
  };

  chartZoom = (direction: string) => {
    if(direction === "zoomIn"){
      this.internalChart.mapZoom(0.5);
      this.props.setZoomLevel(this.props.zoomLevel + 1);
    } else {
      this.internalChart.mapZoom(2);
      this.props.setZoomLevel(this.props.zoomLevel - 1);
    }
  };

  afterChartCreated = (chart: any) => {
    // console.log("- after chart created");
    this.internalChart = chart;
    this.forceUpdate();
  };

  componentDidUpdate() {
    // console.log("- component did update");
    this.internalChart.reflow();
  }

  render() {
    const { geographicalOverview } = this.props;
    return (
      <>
        {geographicalOverview && (
          <HighchartsReact
            constructorType={"mapChart"}
            highcharts={Highcharts}
            containerProps={{ className: "chartContainer", id: "mapChart" }}
            options={buildChartFromGeographicalOverview(geographicalOverview)}
            callback={this.afterChartCreated}
          />
        )}
      </>
    );
  }
}

export default GeographicalOverviewChart;

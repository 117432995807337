import axios from "axios";
import { TeamUserData } from "models/team-user-data.models";
import config from "modules/config";

const ApiBaseUrl = config.ApiUrl;

const getYears = (): number[] => {
  const tmpYears: number[] = [];
  for(let i = 2019; i <= new Date().getFullYear(); i++){
    tmpYears.push(i);
  }
  return tmpYears;
}

let cancel: any;
let CancelToken: any = axios.CancelToken;

const getAllContributors = async (
  accessToken: string,
  module: string,
  itemId: string, // investorId or projectId
  projectCode?: string | null
): Promise<TeamUserData[]> => {
  if (!!cancel) {
    cancel();
  }
  const result = await axios.post(
    `${ApiBaseUrl}/user/allUsersByPrivileges`,
    {
      module: module,
      moduleId: itemId,
      projectCode: projectCode?.toUpperCase(),
    },
    {
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
      },
      cancelToken: new CancelToken((c: any) => {
        cancel = c;
      }),
    }
  );

  return result.data;
};

const getAllReportingContributors = async (
  accessToken: string
): Promise<TeamUserData[]> => {
  if (!!cancel) {
    cancel();
  }
  const result = await axios.post(
    `${ApiBaseUrl}/user/allReportingContributors`,
    {
      module: 'reporting',
      years: getYears()
    },
    {
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
      },
      cancelToken: new CancelToken((c: any) => {
        cancel = c;
      }),
    }
  );

  return result.data;
};

const saveTeamUser = async (
  accessToken: string,
  data: any,
  module: string,
  moduleId: any,
  projectCode?: string
): Promise<any> => {
  if (!!cancel) {
    cancel();
  }

  const result = await axios.post(
    `${ApiBaseUrl}/user/addUserToMyTeam`,
    {
      user: data,
      module: module,
      moduleId: moduleId,
      projectCode: projectCode,
    },
    {
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
      },
      cancelToken: new CancelToken((c: any) => {
        cancel = c;
      }),
    }
  );

  return result.data;
};

const checkPrivilege = (
  module: string,
  user: any,
  investorId: string
): boolean => {
  let hasPrivilege = false;
  let onPage: boolean = false;

  switch (module) {
    case "lp-dashboard":
      onPage = !!document.location.pathname.match(/limited-partners/);
      break;
  }

  if (!onPage) {
    return false;
  }

  //Force display modal & link for admin
  if ("technical-admin" === user.platformRole) {
    return true;
  } else {
    for (let i in user?.privileges) {
      if (module === i.toLowerCase()) {
        const privileges: any = user?.privileges[i];
        for (let p in privileges) {
          console.log(
            privileges[p].role,
            privileges[p].details.investorId === investorId
          );

          if (
            "admin" === privileges[p].role &&
            privileges[p].details.investorId === investorId
          ) {
            hasPrivilege = true;
            break;
          }
        }
      }
    }
  }

  // console.log('');
  // console.log('-- CHECK PRIVILEGE');
  // console.log('privilege : ' + privilege)
  // console.log(user);
  // console.log('investor id : ' + investorId);
  // console.log('has privilege : ' + hasPrivilege);
  // console.log('');

  return hasPrivilege;
};

const deleteUserFromTeam = async (
  accessToken: string,
  userId: number,
  moduleId: string,
  module: string,
  projectCode?: string
): Promise<any> => {
  if (!!cancel) {
    cancel();
  }

  const result = await axios.post(
    `${ApiBaseUrl}/user/deleteRole`,
    { userId: userId, module: module, moduleId: moduleId, projectCode:projectCode },
    {
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
      },
      cancelToken: new CancelToken((c: any) => {
        cancel = c;
      }),
    }
  );

  return result.data;
};

export { getAllContributors, getAllReportingContributors, checkPrivilege, saveTeamUser, deleteUserFromTeam };

import { RootState, ThunkDispatch } from "models/store.models";
import { connect } from "react-redux";
import React from "react";
import { history } from "modules/shared/services/history";

const mapStateToProps = ({ session }: RootState) => ({
  accessToken: session?.sessionData?.accessToken
    ? session?.sessionData?.accessToken
    : "",
  user: session?.sessionData?.userData,
});
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({});
type SurveyEditorProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const SurveyEditor: React.FC<SurveyEditorProps> = ({
  accessToken,
  user,
}) => {
  if (user && user.platformRole !== "technical-admin") {
    // redirect to the Angular version of survey sdg
    history.push("/survey-sdg/survey/dashboard");
  }

  return (
    <>
      TO DO
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SurveyEditor);

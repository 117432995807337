import { ReportingContext } from "context/Reporting.context";
import { cloneDeep } from "lodash";
import { DateTime } from "luxon";
import { SpvReportingDocument } from "models/spv-reporting.model";
import { RootState, ThunkDispatch } from "models/store.models";
import WarnModal from "modules/shared/components/WarnModal/WarnModal";
import {
  deleteDocument,
  setDocument,
} from "modules/shared/services/spv-reporting.service";
import { formattedDate } from "modules/shared/utils/format";
import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";

interface ProjectReportingDocumentsItemHubProps {
  doc: any;
  user: any;
  accessToken: string;
  index: number;
}

const mapStateToProps = ({ session }: RootState) => ({
  accessToken: session?.sessionData?.accessToken
    ? session?.sessionData?.accessToken
    : "",
  user: session?.sessionData?.userData,
});

const mapDispatchToProps = (dispatch: ThunkDispatch) => ({});

const ProjectReportingDocumentsItemHub = ({
  doc,
  user,
  accessToken,
  index
}: ProjectReportingDocumentsItemHubProps) => {

  const { currentTab, rawData, data, setRawData, setData } = useContext(ReportingContext);

  const [hasChanged, setHasChanged] = useState<boolean>(false);

  const [isCompletelyFilled, setIsCompletelyFilled] = useState<boolean>(false);

  const [rawDataItem, setRawDataItem] = useState<SpvReportingDocument>(cloneDeep(doc));

  const [reportingDocument, setReportingDocument] = useState<SpvReportingDocument>(cloneDeep(doc));

  const [isValid, setIsValid] = useState<boolean>(false);

  const checkIsFilled = () => {
    setIsCompletelyFilled(!!reportingDocument.code && !!reportingDocument.filename && !!reportingDocument.group1DueDate && !!reportingDocument.group2DueDate);
  };

  const handleChange = (field: string, value: any) => {

    // console.log('');
    // console.log('__ HANDLE CHANGE __');
    // console.log(field);
    // console.log(value);


    const tmpReportingDocument: any = { ...reportingDocument, ...{ [field]: value } };
    setReportingDocument(tmpReportingDocument);
    const rData: any = cloneDeep(rawData);
    rData[currentTab] = tmpReportingDocument;
    setData(rData);
  };

  const handleSave = async () => {
    const saved: any = await setDocument(accessToken, reportingDocument);

    if ("ok" === saved.status) {
      setReportingDocument(saved.data);
      setRawDataItem(saved.data);

      const rData: any = cloneDeep(rawData);
      rData[currentTab] = saved.data;
      setRawData(rData);
      setData(rData);
    }
  };

  const handleClear = async () => {
    // if (
    //   window.confirm(
    //     `Would you like to delete the document #${reportingDocument.code} ?`
    //   )
    // ) {
      const deleted: any = await deleteDocument(accessToken, Number(reportingDocument.id));

      if ("ok" === deleted.status) {
        const tmpData: any = {
          code: reportingDocument.code,
          filename: "",
          description: "",
          link: "",
          group1DueDate: "",
          group2DueDate: "",
          noTemplate: false,
        };
        setReportingDocument(tmpData);
        setRawDataItem(tmpData);

        const rData: any = cloneDeep(rawData);
        rData[currentTab] = tmpData;
        setRawData(rData);
        setData(rData);
      }
    // }
  };

  useEffect(() => {
    checkIsFilled();
    setIsValid(!!reportingDocument.code && !!reportingDocument.filename && !!reportingDocument.group1DueDate && !!reportingDocument.group2DueDate);
    setHasChanged(JSON.stringify(rawDataItem) !== JSON.stringify(reportingDocument));
  }, [reportingDocument]);

  useEffect(() => {
    setHasChanged(JSON.stringify(rawDataItem) !== JSON.stringify(reportingDocument));
  }, [rawDataItem]);

  return (
    <li
      className={`
        row
        ${!!hasChanged ? "is-modified" : ""}
        ${!!isCompletelyFilled ? "is-filled" : ""}
      `}
    >
      <div className="top-row">
        <div className="file-name">
          <input
            id="filename"
            type="text"
            name="filename"
            placeholder="Filename"
            value={reportingDocument.filename}
            onChange={(e) => handleChange("filename", e.target.value)}
          />
          <label>#{reportingDocument.code}</label>
        </div>
        <div className="description">
          <textarea
            id="description"
            name="description"
            placeholder="Your description..."
            onChange={(e) => handleChange("description", e.target.value)}
            value={reportingDocument.description}
          ></textarea>
        </div>
        <div className="due-date">
          <div className="datepicker">
            <p className="label">G1 due date</p>
            <div className="datepicker_inner">
              <input
                id="group1DueDate"
                type="date"
                name="group1DueDate"
                value={DateTime.fromISO(reportingDocument.group1DueDate).toFormat('yyyy-LL-dd')}
                onChange={(e) => handleChange("group1DueDate", e.target.value)}
              />
              <span className="datepicker_inner-value">
                {!!reportingDocument.group1DueDate
                  ? formattedDate(reportingDocument.group1DueDate)
                  : "———"}
              </span>
            </div>
          </div>
          <div className="datepicker">
            <p className="label">G2 due date</p>
            <div className="datepicker_inner">
              <input
                id="group2DueDate"
                type="date"
                name="group2DueDate"
                value={DateTime.fromISO(reportingDocument.group2DueDate).toFormat('yyyy-LL-dd')}
                onChange={(e) => handleChange("group2DueDate", e.target.value)}
              />
              <span className="datepicker_inner-value">
                {!!reportingDocument.group2DueDate
                  ? formattedDate(reportingDocument.group2DueDate)
                  : "———"}
              </span>
            </div>
          </div>
        </div>
        <div className="save">
          <button
            onClick={() => handleSave()}
            className="btn btn-select btn-primary"
            type="button"
            disabled={!hasChanged || !isValid}
          >
            <span className="btn-icon">
              <i className={`fas fa-save`}></i>
            </span>
          </button>
          <i className={!hasChanged || !isValid ? "disabled" : ""}>Save</i>
          <button
            data-uk-toggle={`#warnClear${index}`}
            className="btn btn-select btn-primary"
            type="button"
            disabled={"undefined" === typeof reportingDocument.id}
          >
            <span className="btn-icon">
              <i className={`fas fa-eraser`}></i>
            </span>
          </button>
          <i>Clear content</i>
        </div>
      </div>
      <div className="bottom-row">
        <div className="file-name"></div>{" "}
        {/* keep empty for correpct alignment */}
        <div className="link">
          <p className="label">Link to How to guide</p>
          <input
            id="linkGuide"
            type="text"
            placeholder="http://"
            required
            name="linkGuide"
            value={reportingDocument.link}
            onChange={(e) => handleChange("link", e.target.value)}
          />
          <div className="no-template">
            <input
              type="checkbox"
              id={`select-${reportingDocument.code}`}
              checked={!!reportingDocument.noTemplate}
              onChange={(e) => handleChange("noTemplate", e.target.checked)}
            />
            <button className="box">
              <i className="fas fa-check"></i>
            </button>
            <span>No template needed</span>
          </div>
        </div>
        <div className="save"></div> {/* keep empty for correct alignment */}
      </div>
      <WarnModal
        id={`warnClear${index}`}
        content="Do you really want to clear this reporting?"
        actionYes={handleClear}
      />
    </li>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectReportingDocumentsItemHub);

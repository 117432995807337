import { RootState } from "models/store.models";
import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import actionToPlainObjectConverter from "redux-action-class";
import thunk from "redux-thunk";
import sessionReducer from "./auth/store/session-reducer";
import platformConfigurationReducer from "./shared/store/platform-configuration-reducer";
import referentialReducer from "./shared/store/referential-reducer";
import userManagementReducer from "./user-management/store/user-management-reducer";

const initialState: RootState = {} as RootState;

const createRootReducer = () =>
  combineReducers({
    session: sessionReducer,
    userManagement: userManagementReducer,
    referential: referentialReducer,
    platformConfiguration: platformConfigurationReducer,
  });

const middleware = [thunk, actionToPlainObjectConverter];

const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const composedEnhancers = composeEnhancers(applyMiddleware(...middleware));

const store = createStore(createRootReducer(), initialState, composedEnhancers);

export default store;

import React, { useEffect, useState } from "react";
import "./styles.scss";
import ModalConfirmation from "./ModalConfirmation/ModalConfirmation";
import { getAllContributors } from "../../services/team.service";
import { Formik } from "formik";
import * as yup from "yup";
import {
  saveTeamUser,
  deleteUserFromTeam,
} from "modules/shared/services/team.service";
import { TeamUserData } from "models/team-user-data.models";
import TeamUser from "./TeamUser/TeamUser";
import { cloneDeep } from "lodash";

type TeamModalProps = {
  accessToken: string;
  module: string;
  moduleId: any;
  me: {
    id: number;
    firstName: string;
    lastName: string;
    login: string;
    platformRole: string;
    role?: string;
  };
  setUsersCount?: any;
  projectCode?: string;
};

const TeamModal = ({
  accessToken,
  moduleId,
  module,
  me,
  setUsersCount,
  projectCode,
}: TeamModalProps) => {
  const [users, setUsers] = useState<TeamUserData[]>([]);
  const [initialValues, setInitialValues] = useState<any>({
    id: "",
    firstName: "",
    lastName: "",
    login: "",
    role: module !== "reporting" ? "readonly" : "contributor",
  });

  const [isAddMode, setIsAddMode] = useState<boolean>(false);
  const [updatingUserId, setIsUpdateMode] = useState<number>(-1);
  const [deleteUser, setDeleteUser] = useState<any>(null);

  const onSubmitForm = async (values: any) => {
    console.log("");
    console.log("---SUBMIT");
    console.log(values);

    try {
      const savedUser: any = await saveTeamUser(
        accessToken,
        values,
        module,
        moduleId,
        projectCode
      );
      let usersList = cloneDeep(users);
      if (!!isAddMode) {
        console.log("savedUser", savedUser);
        usersList.push(savedUser);
      } else {
        usersList = usersList.map((currUser: any) => {
          if (currUser.id === savedUser.id) {
            currUser.firstName = savedUser.firstName;
            currUser.lastName = savedUser.lastName;
            currUser.role = savedUser.role;
          }
          return currUser;
        });
      }
      setUsers(usersList);
      if (!!setUsersCount) {
        setUsersCount(usersList.length);
      }
      setIsAddMode(false);
      setIsUpdateMode(-1);
      setDeleteUser(null);
    } catch (err) {}
  };

  const setForm = (data: TeamUserData) => {
    setInitialValues({
      id: data?.id,
      firstName: data?.firstName,
      lastName: data?.lastName,
      login: data?.login,
      role: data?.role,
    });
  };

  const addNewUser = () => {
    setIsAddMode(true);
    setForm({
      firstName: "",
      lastName: "",
      login: "",
      role: module !== "reporting" ? "readonly" : "contributor",
    });
  };

  const Edit = (user: any) => {
    setIsUpdateMode(user.id);
    setForm(user);
  };

  const Delete = (user: any) => {
    setDeleteUser(user);
  };

  const Cancel = () => {
    setIsAddMode(false);
    setIsUpdateMode(-1);
    setDeleteUser(null);
  };

  const confirmDeleteUser = async () => {
    // console.log("confirmation delete user");

    try {
      if (
        undefined === deleteUser.id ||
        moduleId === undefined ||
        module === undefined ||
        deleteUser.id === null ||
        moduleId === null ||
        module === null
      ) {
        return;
      }
      // Save user after removing this module/moduleId from his selection
      await deleteUserFromTeam(accessToken, deleteUser.id, moduleId, module, projectCode);

      // Remove him from users list
      let leftUsers = [...users];
      leftUsers = leftUsers.filter((lu: any) => lu.id !== deleteUser.id);

      setUsers(leftUsers);
      if (!!setUsersCount) {
        setUsersCount(leftUsers.length);
      }
      setIsAddMode(false);
      setIsUpdateMode(-1);
      setDeleteUser(null);
    } catch (err) {
      // console.log("--- ERROR SAVE USER");
      // console.log(err);
    }
  };

  const modalId = "modalConf";
  const texts = {
    title: "YOU ARE GOING<br/>TO DELETE A USER",
    content: "",
    action: "DELETE",
  };

  const validationSchema = yup.object().shape({
    login: yup.string().label("Login").email().required(),
    firstName: yup
      .string()
      .label("FirstName")
      .matches(/^[\s-'A-Za-zÀ-ÖØ-öø-ÿ0-9]+$/)
      .required(),
    lastName: yup
      .string()
      .label("LastName")
      .matches(/^[\s-'A-Za-zÀ-ÖØ-öø-ÿ0-9]+$/)
      .required(),
    role: yup.string().label("Login").required(),
  });

  useEffect(() => {
    // let currId =
    //   !!projectCode && module === "survey-sdg" ? projectCode : moduleId;
    getAllContributors(
      accessToken,
      module,
      moduleId,
      !!projectCode ? projectCode : null
    ).then(
      (response) => {
        setUsers(response);
        if (!!setUsersCount) {
          setUsersCount(response.length);
        }
      },
      (error) => console.log(error)
    );
  }, [moduleId]);

  return (
    <>
      <div
        id="teamModal"
        className="own-modal modal-team"
        data-uk-modal="container:false;"
      >
        <div className="uk-modal-dialog" data-uk-overflow-auto>
          <div className="own-modal-content">
            <button
              className="btn btn-close label-external"
              type="button"
              data-uk-toggle="target: #teamModal;"
            >
              <span className="btn-label">Close</span>
              <span className="btn-icon circle">
                <i className="fas fa-times"></i>
              </span>
            </button>
            <div className="uk-modal-header">
              <h2 className="upp">Team</h2>
              <h3>
                <span>
                  {users && users.length} member{1 < users.length && "s"}
                </span>
              </h3>
            </div>

            <Formik
              initialValues={initialValues}
              enableReinitialize
              onSubmit={(values) => onSubmitForm(values)}
              validationSchema={validationSchema}
            >
              {(props) => (
                <form onSubmit={props.handleSubmit}>
                  <div className="uk-modal-body" data-uk-overflow-auto>
                    <div className="wrapp-manager manager-team">
                      <div className="own-table">
                        <ul className="table-head">
                          <li className="row">
                            <div className="col col-1">
                              <div className="cel">
                                <span>Firstname</span>
                              </div>
                            </div>
                            <div className="col col-2">
                              <div className="cel">
                                <span>Lastname</span>
                              </div>
                            </div>
                            <div className="col col-3">
                              <div className="cel">
                                <span>Email</span>
                              </div>
                            </div>
                            <div className="col col-4">
                              <div className="cel">
                                <span>Role</span>
                              </div>
                            </div>
                            <div className="col col-5">
                              <div className="cel">
                                <span>Edit</span>
                              </div>
                            </div>
                            <div className="col col-6">
                              <div className="cel">
                                <span>Delete</span>
                              </div>
                            </div>
                          </li>
                        </ul>

                        <ul className="table-body">
                          <li className="row myself">
                            <TeamUser
                              isMe={true}
                              cancel={Cancel}
                              editUser={() => Edit(me)}
                              isAddMode={isAddMode}
                              updatingUserId={updatingUserId}
                              editor={me}
                              user={{
                                id: me.id,
                                firstName: me.firstName,
                                lastName: me.lastName,
                                login: me.login,
                                role: me.role ? me.role : me.platformRole,
                              }}
                              modalId={modalId}
                              formik={props}
                              module={module}
                            />
                          </li>

                          {isAddMode && (
                            <li className="row row-isAddMode">
                              <TeamUser
                                isMe={false}
                                cancel={Cancel}
                                deleteUser={Delete}
                                isAddMode={isAddMode}
                                updatingUserId={updatingUserId}
                                modalId={modalId}
                                formik={props}
                                module={module}
                              />
                            </li>
                          )}

                          {users.length > 0 && !isAddMode && !updatingUserId && (
                            <li className="row row-empty">
                              <button
                                className="link link-isAddMode"
                                onClick={addNewUser}
                              >
                                <span className="btn-label">
                                  Add new user to create your team
                                </span>
                              </button>
                            </li>
                          )}

                          {!!users &&
                            users.length > 0 &&
                            users
                              .filter((u) => u.id !== me.id)
                              .map((u, index) => {
                                return (
                                  <li className="row" key={index}>
                                    <TeamUser
                                      isMe={false}
                                      cancel={Cancel}
                                      deleteUser={() => Delete(u)}
                                      editUser={() => Edit(u)}
                                      editor={me}
                                      isAddMode={isAddMode}
                                      updatingUserId={updatingUserId}
                                      user={u}
                                      modalId={modalId}
                                      formik={props}
                                      module={module}
                                    />
                                  </li>
                                );
                              })}
                        </ul>
                      </div>
                    </div>
                  </div>
                  {!isAddMode && -1 === updatingUserId && (
                    <div className="uk-modal-footer modal-footer-blue">
                      <button
                        className="btn label-external"
                        onClick={addNewUser}
                      >
                        <span className="btn-label">Add new user</span>
                        <span className="btn-icon">
                          <i className="fas fa-user-plus"></i>
                        </span>
                      </button>
                    </div>
                  )}
                  {(isAddMode || -1 !== updatingUserId) && (
                    <div className="uk-modal-footer modal-footer-yellow">
                      <button className="btn label-external" type="submit">
                        <span className="btn-label">
                          Save&nbsp;
                          {isAddMode && <span> new </span>}
                          user
                        </span>
                        <span className="btn-icon">
                          <i className="fas fa-save"></i>
                        </span>
                      </button>
                    </div>
                  )}
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>

      <ModalConfirmation
        submitConfirmation={confirmDeleteUser}
        modalId={modalId}
        texts={texts}
      />
    </>
  );
};

export default TeamModal;

import { cloneDeep } from "lodash";
import { Investor } from "modules/limited-partners/models/investor.model";
import React, { useEffect, useState } from "react";
import "./styles.scss";
import UserSettingsLPDashboardItem from "./UserSettingsLPDashboardItem";

const UserSettingsLPDashboard: React.FC<{
  modifiedCurrUser: any;
  // sendEmailInvitation: any;
  setModifiedCurrUser: any;
  investors: Investor[];
  setOpenListModal: any;
  formDirty: string[];
  setFormDirty: any;
}> = ({
  modifiedCurrUser,
  // sendEmailInvitation,
  setModifiedCurrUser,
  investors,
  setOpenListModal,
  formDirty,
  setFormDirty,
}) => {
  const [
    isSuperAdminForThisModule,
    setIsSuperAdminForThisModule,
  ] = useState<boolean>(false);

  const handleChange = (field: string, type: string) => {
    if (type === "setSuperAdmin") {
      if (!formDirty.includes(field)) {
        let newList = formDirty;
        newList.push(field);
        setFormDirty(newList);
      }

      let updatedU = cloneDeep(modifiedCurrUser);
      if (isSuperAdminForThisModule) {
        updatedU.privileges["lp-dashboard"] = [];
        setIsSuperAdminForThisModule(false);
      } else {
        updatedU.privileges["lp-dashboard"] = [
          {
            details: { type: "investor" },
            id: null,
            module: "lp-dashboard",
            role: "superadmin",
          },
        ];
        setIsSuperAdminForThisModule(true);
      }
      setModifiedCurrUser(updatedU);
    }
  };

  const updateInvestorPrivilege = (investorId: string, role: string) => {
    let newDirtyList = cloneDeep(formDirty);
    newDirtyList.push("lp-" + investorId);
    setFormDirty(newDirtyList);

    let newUser = cloneDeep(modifiedCurrUser);
    newUser.privileges["lp-dashboard"].map((priv: any) => {
      if (priv.details.investorId === investorId) {
        priv.role = role;
      }
    });
    setModifiedCurrUser(newUser);
  };

  const removePrivilege = (investorId: string) => {
    let newDirtyList = cloneDeep(formDirty);
    newDirtyList.push("lp-" + investorId);
    setFormDirty(newDirtyList);

    let newUser = cloneDeep(modifiedCurrUser);
    let newListPrivilege = newUser.privileges["lp-dashboard"];
    newListPrivilege = newUser.privileges["lp-dashboard"].filter(
      (priv: any) => priv.details.investorId !== investorId
    );
    newUser.privileges["lp-dashboard"] = newListPrivilege;
    setModifiedCurrUser(newUser);
  };

  useEffect(() => {
    if (
      modifiedCurrUser &&
      modifiedCurrUser.privileges &&
      modifiedCurrUser.privileges["lp-dashboard"] &&
      modifiedCurrUser.privileges["lp-dashboard"].length > 0
    ) {
      if (
        modifiedCurrUser.privileges["lp-dashboard"][0].role === "superadmin"
      ) {
        setIsSuperAdminForThisModule(true);
      }
    }
  }, []);

  return (
    <div className="user-settings-lp-dashboard">
      <h3>— general settings</h3>

      <i className="italic">Account status - {isSuperAdminForThisModule ? true : false}</i>
      <div className="toggle-container">
        <div className="toggle">
          <input
            type="checkbox"
            id="active"
            className="toggle-input"
            onChange={() => handleChange("superadminlp", "setSuperAdmin")}
            checked={isSuperAdminForThisModule}
          />
          <div className="toggle-switcher"></div>
        </div>
        <span className="label">Grant super admin privileges</span>
      </div>
      <div className={`state ${isSuperAdminForThisModule ? "warn" : ""}`}>
        {isSuperAdminForThisModule && (
          <>
            <i className="fas fa-exclamation-circle"></i>
            Please be aware that the user with super admin access will have
            access to all LP Dashboard
          </>
        )}
      </div>

      {/* <div className="separator"></div>

      <button
        className="btn btn-select btn-primary btn-send"
        type="button"
        onClick={() => sendEmailInvitation(modifiedCurrUser, "lp-dashboard")}
      >
        <span className="btn-label">Send invitation</span>
        <span className="btn-icon">
          <i className={`fas fa-envelope`}></i>
        </span>
      </button>
      <div
        className={`state ${
          !!modifiedCurrUser.ininvitationEmails ? "valid" : "warn"
        }`}
      >
        {!!modifiedCurrUser.ininvitationEmails ? (
          <i className="fas fa-check-circle"></i>
        ) : (
          <i className="fas fa-exclamation-circle"></i>
        )}
        <b>Survey invitation</b> :{" "}
        {!!modifiedCurrUser &&
        !!modifiedCurrUser.invitationEmails &&
        !!modifiedCurrUser.invitationEmails["lp-dashboard"]
          ? DateTime.fromISO(
              modifiedCurrUser.invitationEmails["lp-dashboard"].creationDate
            ).toFormat("LL/dd/yyyy")
          : "Not send yet."}
      </div> */}

      {!isSuperAdminForThisModule && (
        <>
          <div className="separator"></div>

          <div className="title-with-btn">
            <h3>— user privileges by dashboard</h3>
            <button
              className="btn btn-select btn-primary"
              type="button"
              onClick={() => setOpenListModal("investor")}
            >
              <span className="btn-label">Add/remove investors</span>
              <span className="btn-icon">
                <i className={`fas fa-arrow-right`}></i>
              </span>
            </button>
          </div>

          <ul className="list">
            <li className="row filters">
              <div className="investor">
                <i className="label">Investor</i>
              </div>
              <div className="group">
                <i className="label">Role</i>
              </div>
              <div className="delete">
                <i className="label">Delete</i>
              </div>
            </li>

            {!!modifiedCurrUser &&
              !!modifiedCurrUser.privileges &&
              !!modifiedCurrUser.privileges["lp-dashboard"] &&
              modifiedCurrUser.privileges["lp-dashboard"].length > 0 &&
              modifiedCurrUser.privileges[
                "lp-dashboard"
              ].map((investor: any, idx: number) => (
                <UserSettingsLPDashboardItem
                  key={`projectK-${idx}`}
                  investor={investor}
                  investors={investors}
                  updateInvestorPrivilege={updateInvestorPrivilege}
                  removePrivilege={removePrivilege}
                  formDirty={formDirty}
                />
              ))}
          </ul>
        </>
      )}
    </div>
  );
};

export default UserSettingsLPDashboard;

import React from "react";
import NumberFormat from "react-number-format";

interface ProjectIndicatorsTextProps {
  slug: any,
  indicator: any;
  value: string;
  handleChange: any;
  index?: number
}

const ProjectIndicatorsText = ({ slug, indicator, value, handleChange, index }: ProjectIndicatorsTextProps) => {

  return (
    <div className="text">
      <p className="label">{indicator.name}</p>
      {!!indicator.config && !!indicator.config.type && 'number' === indicator.config.type ?
        <NumberFormat
          id={`${slug}${indicator.id}${'undefined' !== typeof index ? `-${index}` : ''}`}
          displayType="input"
          isNumericString={false}
          thousandSeparator={true}
          prefix=""
          placeholder={indicator.textPlaceholder}
          required
          min={!!indicator.config && !!indicator.config.min ? indicator.config.min : ''}
          max={!!indicator.config && !!indicator.config.max ? indicator.config.max : ''}
          name={`${slug}${indicator.id}${'undefined' !== typeof index ? `-${index}` : ''}`}
          value={!!value ? value : ''}
          onChange={(e: any) => handleChange(slug, e.target.value, indicator.id)}
        />
      :
        <input
          id={`${slug}${indicator.id}${'undefined' !== typeof index ? `-${index}` : ''}`}
          type={!!indicator.config && !!indicator.config.type ? indicator.config.type : 'text'}
          placeholder={indicator.textPlaceholder}
          required
          min={!!indicator.config && !!indicator.config.min ? indicator.config.min : ''}
          max={!!indicator.config && !!indicator.config.max ? indicator.config.max : ''}
          name={`${slug}${indicator.id}${'undefined' !== typeof index ? `-${index}` : ''}`}
          value={!!value ? value : ''}
          onChange={(e) => handleChange(slug, e.target.value, indicator.id)}
        />
      }
    </div>
  );
};

export default ProjectIndicatorsText;

import { PlatformConfiguration } from "models/platform-configuration.model";
import { ThunkResult } from "models/store.models";
import * as platformConfigurationService from "modules/shared/services/platform-configuration.service";
import { SetConfiguration } from "./platform-configuration-action-types";

const loadPlatformConfiguration = (): ThunkResult<
  Promise<PlatformConfiguration>
> => async (dispatch) => {
  const configuration = await platformConfigurationService.getPlatformConfiguration();

  dispatch(new SetConfiguration(configuration));

  return configuration;
};

export { loadPlatformConfiguration };

import { RootState, ThunkDispatch } from "models/store.models";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Header from "../../../shared/components/Header/Header";
import Loader from "../../../shared/components/Loader/Loader";
import TitlePage from "../../../shared/components/Title/TitlePage/TitlePage";
import PlatformToolBar from "../PlatformToolBar/PlatformToolBar";
import { exportAsExcelFile } from "modules/shared/services/excel.service";

import DownloadPdfButton from "../../../shared/components/Ui/Buttons/DownloadPdfButton/DownloadPdfButton";

import "../../../../assets/scss/custom/tools/_base.scss";
import styles from "./styles.module.scss";

import SdgImpactSynthesisSection from "modules/platform/components/SdgImpactSynthesis/SdgImpactSynthesisSection/SdgImpactSynthesisSection";
import { SdgImpactSynthesisInterface } from "models/sdg-impact-synthesis.model";

// const { DateTime } = require("luxon");

const mapStateToProps = ({ session }: RootState) => ({
  accessToken: session?.sessionData?.accessToken,
  user: session?.sessionData?.userData,
});

const mapDispatchToProps = (dispatch: ThunkDispatch) => ({});

type SdgImpactSynthesisProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const SdgImpactSynthesis: React.FC<SdgImpactSynthesisProps> = ({
  accessToken,
  user,
}) => {
  const [isProjectLeaderView, setIsProjectLeaderView] = useState<boolean|null>(null);
  const [isExportingSdg, setIsExportingSdg] = useState<boolean>(false);
  const [meridiamResult, setMeridiamResult] = useState<[]>();
  const [projectsData, setProjectsData] = useState<[]>();
  

  const [isDataLoading, setIsDataLoading] = useState<boolean>(true);
  // const today = DateTime.fromISO(new Date()).toFormat("LL/dd/yyyy");

  const [extendedPdfMode, setPdfMode] = useState<any>(null);
  const [isPrint, setIsPrint] = useState<boolean>(false);

  const exportProjectSdg  = () => {
    setIsExportingSdg(true);
    let datas: any = [];

    !!projectsData && projectsData.length > 0 &&
    !!meridiamResult && meridiamResult.length > 0 && meridiamResult.map((r:any) => {
      let project:any;
      projectsData.map((p:any) => {
        if(p.id === r.projectID){
          project= p;
        }
      })

      let funds = "";
      if(!!project.meridiamFunds && project.meridiamFunds.length > 0){
        project.meridiamFunds.map((mf:any) => {
          if(funds !== ""){
            funds += ", ";
          }
          funds += mf.meridiamFund.fundId
        })
      }

      if(!!project){
        datas.push({
          "project code": project.projectCode,
          "project name": project.projectName,
          "Funds": funds,
          "SDG 1 - No poverty": r.sdg[1],
          "SDG 2 - Zero hunger": r.sdg[2],
          "SDG 3 - Good health and well-being": r.sdg[3],
          "SDG 4 - Quality education": r.sdg[4],
          "SDG 5 - Gender equality": r.sdg[5],
          "SDG 6 - Clean water and sanitation": r.sdg[6],
          "SDG 7 - Affordable and clean energy": r.sdg[7],
          "SDG 8 - Decent work and economic growth": r.sdg[8],
          "SDG 9 - Industry, innovation and infrastructure": r.sdg[9],
          "SDG 10 - Reduced inequalities": r.sdg[10],
          "SDG 11 - Sustainable cities and communities": r.sdg[11],
          "SDG 12 - Responsible consumption and production": r.sdg[12],
          "SDG 13 - Climate action": r.sdg[13],
          "SDG 14 - Life below water": r.sdg[14],
          "SDG 15 - Life on land": r.sdg[15],
          "SDG 16 - Peace, justice and strong institutions": r.sdg[16],
          "SDG 17 - Partenerships for the goals": r.sdg[17],
          "Global SDG Rating": r.sdg["total"],
        });
      }
    });
    // console.log("datas", datas);
    exportAsExcelFile(datas, "projects-sdg");
    setIsExportingSdg(false);
  }

  useEffect( () => {
    let result = true;

    if(!!user && (user.platformRole === "global-admin" || user.platformRole === "technical-admin")){
      result = false;
    }

    if(!!user && 
      user.platformRole === "stakeholder" &&
      user.privileges["esg-tool"]){
      user.privileges["esg-tool"].map((p: any) => {
        if (p.details["toolName"] === "impact-synthesis") {
          result = false;
        }
      });
    }

    setIsProjectLeaderView(result);
  }, [])

  return (
    <>
      <Loader
        className={isDataLoading ? "on" : "off"}
        color="multicolors"
        size="large"
      />

      <div className="header-wrapper" data-uk-sticky="media:768;">
        <Header
          titleContext="SDG Impact Synthesis"
          colorContext={"seaLight"}
          classNameToolbarTop="tool-sdg-synthesis"
          tool={`sdg-synthesis`}
        />
      </div>

      {!!user && (
        <PlatformToolBar
          currentTool={"synthesis"}
          user={user}
          isExportingSurveys={isExportingSdg}
          exportAllSurveys={exportProjectSdg}
          buttonsRight={
            <DownloadPdfButton
              visible={true}
              optionsPrint={{
                format: "A4",
                orientation: "portrait",
                assets: [
                  {
                    folder: "assets/css",
                    files: [
                      "development" !== process.env.NODE_ENV
                        ? "print.css"
                        : "print-local.css",
                    ],
                  },
                ],
              }}
              pdfTitle=""
              filename="SDG_Impact_Synthesis"
              selector="#cmpImpactSynthesis"
              selectorHead="#cmpPdfHead"
              selectorFoot="#cmpPdfLegends"
              setPdfMode={setPdfMode}
              extendedPdfMode={extendedPdfMode}
              mode="dropdown"
              label="Download"
              labelInside=".pdf"
              icon="fas fa-download"
              btnClassname="btn btn-download-pdf dropdown btn-primary download-rosace"
              dropdownClassname="drop drop-select-pdf"
              dropdownAttr="pos: bottom-left"
              itemsList={[
                {
                  id: 1,
                  label: "Legend",
                  action: "onclick",
                  value: true,
                  className: "",
                },
                {
                  id: 2,
                  label: "No legend",
                  action: "onclick",
                  value: false,
                  className: "",
                },
              ]}
              setIsPrint={setIsPrint}
            />
          }
        />
      )}

      <div
        className={`page page-tool ${styles["page-tool-sgd-impact-synthesis"]}`}
        data-theme="seaLight"
      >
        <div className="page_inner">
          <TitlePage
            content={`
              <b>SDG </b>
              <b>Impact Synthesis</b>
            `}
          />
          <div
            className={`${styles.grid} ${styles["grid-tool-sdg-impact-synthesis"]}`}
          >
            <div className={`grid_inner ${styles.grid}`}>
              <div className={"main-content tool tool-sdg-impact-synthesis"}>
                <div className="content-body">
                  {!!accessToken && isProjectLeaderView !== null && (
                    <SdgImpactSynthesisSection
                      isDataLoading={isDataLoading}
                      setIsDataLoading={setIsDataLoading}
                      accessToken={accessToken}
                      extendedPdfMode={extendedPdfMode}
                      setPdfMode={setPdfMode}
                      isProjectLeaderView={isProjectLeaderView}
                      setMeridiamResult={setMeridiamResult}
                      setProjectsData={setProjectsData}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SdgImpactSynthesis);

import { PlatformGroup } from "./platform-group.model";

export enum WellKnownPlatformRoles {
  Stakeholder = "stakeholder",
  GlobalAdmin = "global-admin",
  TechnicalAdmin = "technical-admin",
}

export enum UserType {
  Internal = "internal",
  External = "external",
}

export enum WellKnownModules {
  SurveySDG = "survey-sdg",
  LPDashboard = "lp-dashboard",
  SPVManager = "spv-manager",
  EsgTool = "esg-tool",
  UserManager = "user-manager",
  Reporting = "reporting",
}

export enum WellKnownModuleRoles {
  ReadOnly = "readonly",
  Contributor = "contributor",
  Admin = "admin",
  SuperAdmin = "superadmin",
}

export enum WellKnownPrivilegeSubjects {
  ProjectId = "projectId",
}

export interface User {
  id?: number;
  userType: UserType;
  login: string;
  firstName: string;
  lastName: string;
  enabled: boolean;
  blockedAccount: boolean;
  active: boolean;
  creationDate?: Date;
  lastConnectionDate?: Date;
  platformRole: WellKnownPlatformRoles;
  groups: PlatformGroup[];
  privileges: { [module: string]: PlatformUserPrivilege[] };
  invitationEmails?: { [type: string]: PlatformUserInvitationEmail };
  userLastUpdate: string;
  lastUpdate: Date;
  credentialSent?: boolean;
  credentialSentDate?: Date;
}

export interface ExportUser {
  email: string;
  exportKey: string;
  userType: UserType;
  firstName: string;
  lastName: string;
  platformRole: WellKnownPlatformRoles;
  surveyAccessDetails?: string;
  surveyRole?: string;
  limitedPartnersDashboardRole?: string;
  investorId?: string;
  investor?: string;
}

export interface CreateOrUpdateUserModel {
  id?: number;
  firstName: string;
  lastName: string;
  login: string;
  userType: UserType;
  enabled: boolean;
  blockedAccount: boolean;
  platformRole: WellKnownPlatformRoles;
  privileges: { [module: string]: PlatformUserPrivilege[] };
  userGroups?: PlatformGroup[];
  sendEmailUponCreation?: boolean;
}

export enum PrivilegeDataTypes {
  LPDashboardPrivilegeDetail = "investor",
  ProjectPrivilegeDetail = "project",
  ESGToolPrivilegeDetail = "esg-tool",
  UserManagerPrivilegeDetail = "user-manager",
  ReportingPrivilegeDetail = "reporting",
  SPVManagerPrivilegeDetail = "spv-manager",
}

export class InvestorPrivilegeData {
  readonly type = PrivilegeDataTypes.LPDashboardPrivilegeDetail;
  investorId?: string;
  fundIds: string[] = [];

  constructor(investorId?: string, fundIds?: string[]) {
    this.investorId = investorId;
    this.fundIds = fundIds || [];
  }
}

export class ProjectPrivilegeData {
  readonly type = PrivilegeDataTypes.ProjectPrivilegeDetail;
  projectId?: number;
  projectCode?: string;

  constructor(projectId?: number, projectCode?: string) {
    this.projectId = projectId;
    this.projectCode = projectCode;
  }
}

export class ESGToolPrivilegeData {
  readonly type = PrivilegeDataTypes.ESGToolPrivilegeDetail;
  toolName?: string;

  constructor(toolName?: string) {
    this.toolName = toolName;
  }
}

export class UserManagerPrivilegeData {
  readonly type = PrivilegeDataTypes.UserManagerPrivilegeDetail;
}

export class SPVManagerPrivilegeData {
  readonly type = PrivilegeDataTypes.SPVManagerPrivilegeDetail;
  projectId?: number;
  projectCode?: string;

  constructor(projectId?: number, projectCode?: string) {
    this.projectId = projectId;
    this.projectCode = projectCode;
  }
}

export class ReportingPrivilegeData {
  readonly type = PrivilegeDataTypes.ReportingPrivilegeDetail;
  projectId?: number;
  projectCode?: string;

  constructor(projectId?: number, projectCode?: string) {
    this.projectId = projectId;
    this.projectCode = projectCode;
  }
}

export type PrivilegeData =
  | InvestorPrivilegeData
  | ProjectPrivilegeData
  | ESGToolPrivilegeData
  | UserManagerPrivilegeData
  | SPVManagerPrivilegeData
  | ReportingPrivilegeData;

export interface PlatformUserPrivilege {
  id: number;
  module: WellKnownModules;
  role: WellKnownModuleRoles;
  details: PrivilegeData;
}

export interface PlatformUserInvitationEmail {
  id: number;
  type: string;
  userId: number;
  creationDate: Date;
}

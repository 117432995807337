import { SessionData } from "models/authentication.models";
import { OperationStates } from "models/operation-states.model";
import {
  SessionActions,
  SessionActionTypes,
} from "../actions/session-action-types";

export interface SessionState {
  loginState?: OperationStates;
  sessionData?: SessionData;
}

const initialState: SessionState = {
  loginState: undefined,
  sessionData: undefined,
};

const sessionReducer = (
  state: SessionState = initialState,
  action: SessionActions
): SessionState => {
  switch (action.type) {
    case SessionActionTypes.LoginInProgress:
      return {
        loginState: OperationStates.InProgress,
      };
    case SessionActionTypes.StartSession: {
      const { sessionData } = action;
      return {
        loginState: OperationStates.Success,
        sessionData: sessionData,
      };
    }
    case SessionActionTypes.ActivateAccount: {
      return {
        loginState: OperationStates.Success,
        sessionData: {
          ...state.sessionData!,
          active: true,
        },
      };
    }
    case SessionActionTypes.LoginError:
      return {
        loginState: OperationStates.Error,
        sessionData: undefined,
      };
    case SessionActionTypes.LoginFailed:
      return {
        loginState: OperationStates.Failure,
        sessionData: undefined,
      };
    case SessionActionTypes.SignOut:
    case SessionActionTypes.InitSession:
      return {};

    default:
      return state;
  }
};

export default sessionReducer;

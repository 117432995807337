import { isNull, isUndefined } from "lodash";
import { SessionData } from "models/authentication.models";
import { PlatformConfiguration } from "models/platform-configuration.model";
import { ThunkResult } from "models/store.models";
import { WellKnownPlatformRoles } from "models/user.models";
import * as authenticationService from "modules/auth/services/authentication.service";
import config from "modules/config";
import { history } from "modules/shared/services/history";
import {
  ActivateAccount,
  InitSession,
  LoginFailedAction,
  LoginInProgressAction,
  SignOutUser,
  StartSessionAction,
} from "./session-action-types";

const loginUser = (
  login: string,
  password: string
): ThunkResult<Promise<void>> => async (dispatch, getState) => {
  dispatch(new LoginInProgressAction());
  try {
    const loginResponse = await authenticationService.requestLogin(
      login,
      password
    );
    dispatch(
      startSession(loginResponse, getState().platformConfiguration.config)
    );
  } catch {
    dispatch(new LoginFailedAction());
  }
};

const loginWithToken = (token: string): ThunkResult<Promise<void>> => async (
  dispatch,
  getState
) => {
  dispatch(new LoginInProgressAction());
  try {
    const loginResponse = await authenticationService.requestLoginWithToken(
      token
    );
    dispatch(
      startSession(loginResponse, getState().platformConfiguration.config)
    );
  } catch {
    dispatch(new LoginFailedAction());
  }
};

const activateAccount = (
  login: string,
  password: string
): ThunkResult<Promise<boolean>> => async (dispatch, getState) => {
  const {
    session: { sessionData },
    platformConfiguration: { config },
  } = getState();

  if (isUndefined(sessionData?.accessToken)) {
    history.push("/auth/login");
    return false;
  } else {
    try {
      await authenticationService.requestActivateAccount(
        sessionData!.accessToken,
        login,
        password
      );
      dispatch(new ActivateAccount());
      redirectUserBasedOnPrivileges(sessionData!, config);
      return true;
    } catch {
      history.push("/auth/login");
      return false;
    }
  }
};

const loginWithSso = (): ThunkResult<Promise<void>> => async () => {
  document.location.href = `${config.SsoUrl}`;
};

const startSession = (
  sessionData: SessionData,
  platformConfiguration: PlatformConfiguration
): ThunkResult<Promise<void>> => async (dispatch, getState) => {
  dispatch(new StartSessionAction(sessionData));
  if (!sessionData.active) {
    history.push(`/auth/activate-account`);
  } else {
    // save session token in local storage
    localStorage.setItem("access_token", sessionData.accessToken);

    // for survey-sdg login
    let authData = {
      accountActive: true,
      token: sessionData.accessToken,
      userData: sessionData.userData,
    };
    localStorage.setItem("auth_data", JSON.stringify(authData));

    redirectUserBasedOnPrivileges(
      sessionData,
      getState().platformConfiguration.config
    );
  }
};

const initSession = (
  accessToken: string | null,
  platformConfiguration: PlatformConfiguration
): ThunkResult<Promise<void>> => async (dispatch, getState) => {
  dispatch(new InitSession());

  const token =
    isUndefined(accessToken) || isNull(accessToken)
      ? localStorage.getItem("access_token")
      : accessToken;

  if (isUndefined(token)) {
    return;
  }

  const { user, isValid } = await authenticationService.getTokenValidity(
    token!
  );

  if (!isValid) {
    // clear local storage, not sign out, beacause if for redirection /auth/login if we try to acess /forgort-password
    localStorage.clear();
    // await dispatch(signOut());
  } else {
    await dispatch(
      startSession(
        {
          accessToken: token!,
          active: true,
          userData: user,
        },
        platformConfiguration
      )
    );
  }
};

const redirectUserBasedOnPrivileges = (
  sessionData: SessionData,
  platformConfiguration: PlatformConfiguration
) => {
  // eslint-disable-next-line no-restricted-globals
  const currentLocationBasePath = location.pathname;

  if (sessionData.userData == null) {
    return;
  }

  const modules = Object.keys(sessionData.userData?.privileges);
  let redirectionUrl = "";

  if (
    sessionData.userData.platformRole === WellKnownPlatformRoles.GlobalAdmin ||
    sessionData.userData.platformRole ===
      WellKnownPlatformRoles.TechnicalAdmin ||
    modules.length > 1
  ) {
    redirectionUrl = "/dashboard";
  } else if (modules.length === 0) {
    // à améliorer pour afficher une page no access ?
    redirectionUrl = "";
  } else if (modules[0] === "survey-sdg") {
    // redirectionUrl = platformConfiguration.surveySDGLoginWithTokenUrl.replace(
    //   "{token}",
    //   sessionData.accessToken
    // );

    let isAdminForOne = false;
    sessionData.userData.privileges["survey-sdg"].forEach( p => {
      if(p.role === "admin"){
        isAdminForOne = true;
      }
    });
    if(isAdminForOne === false){
      if (process.env.NODE_ENV !== "development") {
        document.location.href = "/survey-sdg/survey/dashboard";
      } else {
        console.log(
          "%c LOCAL ENV : cannot go to /survey-sdg/survey/dashboard automatically",
          "color:green;font-size:13px"
        );
        redirectionUrl = "/dashboard";
      }
    } else {
      redirectionUrl = "/dashboard";
    }
  } else if (modules[0] === "lp-dashboard") {
    redirectionUrl = "/limited-partners";
  }

  // initial route is not an authentication route
  if (currentLocationBasePath.indexOf("auth") !== -1) {
    history.push(redirectionUrl);
  } else {
    history.push(currentLocationBasePath);
  }
};

const lostPassword = (
  login: string
): ThunkResult<Promise<boolean>> => async () => {
  try {
    authenticationService.requestLostPassword(login);
    return true;
  } catch {
    return false;
  }
};

const passwordReset = (
  token: string,
  login: string,
  newPassword: string
): ThunkResult<Promise<boolean>> => async () => {
  try {
    await authenticationService.passwordReset(token, login, newPassword);
    history.push("/auth/login?passwordReset=true");
    return true;
  } catch {
    return false;
  }
};

const signOut = (): ThunkResult<Promise<void>> => async (dispatch) => {
  if(!!caches){
    const authData: any = localStorage.getItem('auth_data');
    const data: any = !!authData ? JSON.parse(authData) : null;
    const cacheName: string = `reportl-${data.userData.id}`;
    caches.delete(cacheName)
  }
  localStorage.clear();
  dispatch(new SignOutUser());
  history.push("/auth");
};

export {
  loginUser,
  loginWithSso,
  startSession,
  activateAccount,
  lostPassword,
  passwordReset,
  signOut,
  initSession,
  loginWithToken,
};

import React, { useContext, useEffect } from "react";
import {
  ProjectIndicatorsSection,
} from "modules/hub/models/project-indicators";
import ProjectIndicatorsRow from "../ProjectIndicatorsRow/ProjectIndicatorsRow";

// import ProjectIndicatorsRowLeader from "../ProjectIndicatorsRow/ProjectIndicatorsRowLeader";
import {
  getBasicIndicators,
  setBasicIndicators,
} from "modules/shared/services/spv-reporting.service";
import { RootState, ThunkDispatch } from "models/store.models";
import { connect } from "react-redux";
import { cloneDeep } from "lodash";
import { DateTime } from "luxon";

import "./styles.scss";
import { ReportingContext } from "context/Reporting.context";

interface ProjectIndicatorsProps {
  user: any;
  accessToken: string;
}

const mapStateToProps = ({ session }: RootState) => ({
  accessToken: session?.sessionData?.accessToken
    ? session?.sessionData?.accessToken
    : "",
  user: session?.sessionData?.userData,
});

const mapDispatchToProps = (dispatch: ThunkDispatch) => ({});

const ProjectIndicators = ({
  accessToken,
  user
}: ProjectIndicatorsProps) => {

  const { currentTab, indicators, isValid, setData, setRawData, setIsValid } = useContext(ReportingContext);

  const init = async () => {
    const tmpIndicators: any = await getBasicIndicators(accessToken);
    if ("ok" === tmpIndicators.status) {
      if (!!tmpIndicators.data && !!tmpIndicators.data.dueDate) {
        setRawData(cloneDeep(tmpIndicators.data));
        setData(tmpIndicators.data);
      } else{
        setRawData(cloneDeep(indicators));
      }
    }
  };

  const handleChange = (type: string, value: any) => {
    setData({ ...indicators, ...{ [type]: value } });
  };

  const launchNewQuarter = async () => {
    const tmpIndicators: any = indicators;
    tmpIndicators.state = "launched";
    const savedData: any = await setBasicIndicators(
      accessToken,
      tmpIndicators
    );

    if ("ok" === savedData.status) {
      setRawData(savedData.data);
      setData(savedData.data);
    }
  };

  useEffect(() => {
    if(0 === currentTab){
      setIsValid(
        !!indicators.dueDate &&
          !!indicators.constructionText &&
          !!indicators.expectedFcDateText &&
          (!indicators.projectRatingDisplay ||
            (!!indicators.projectRatingDisplay &&
              !!indicators.projectRatingText)) &&
          (!indicators.boardMembersDisplay ||
            (!!indicators.boardMembersDisplay &&
              !!indicators.boardMembersText)) &&
          (!indicators.adscrDisplay ||
            (!!indicators.adscrDisplay && !!indicators.adscrText)) &&
          (!indicators.availabilityDataDisplay ||
            (!!indicators.availabilityDataDisplay &&
              !!indicators.availabilityDataText)) &&
          (!indicators.operatingTurnoverDisplay ||
            (!!indicators.operatingTurnoverDisplay && !!indicators.operatingTurnoverText)) &&
          (!indicators.operatingExpensesDisplay ||
            (!!indicators.operatingExpensesDisplay && !!indicators.operatingExpensesText)) &&
          (!indicators.totalAssetsDisplay ||
            (!!indicators.totalAssetsDisplay && !!indicators.totalAssetsText)) &&
          // (!indicators.revenueDisplay ||
          //   (!!indicators.revenueDisplay && !!indicators.revenueText)) &&
          // (!indicators.ebitdaDisplay ||
          //   (!!indicators.ebitdaDisplay && !!indicators.ebitdaText)) &&
          (!indicators.insuranceDataDisplay ||
            (!!indicators.insuranceDataDisplay && !!indicators.insuranceDataText))
      );
    }
  }, [indicators, currentTab]);

  useEffect(() => {
    if(0 === currentTab){
      init();
    }
  }, [currentTab]);

  return (
    <>
      <div className="project-details-indicators_head">
        <div className="is-hub-view">
          <h3>Set a due date</h3>
          <div className="datepicker">
            <div className="datepicker_inner">
              <input
                id="dueDate"
                type="date"
                name="dueDate"
                value={indicators.dueDate}
                onChange={(e) => handleChange("dueDate", e.target.value)}
              />
              <span className="datepicker_inner-value">
                {!!indicators.dueDate
                  ? DateTime.fromISO(indicators.dueDate).toLocaleString(
                      DateTime.DATE_MED
                    )
                  : "———"}
              </span>
            </div>
          </div>
          <button
            onClick={launchNewQuarter}
            className="btn btn-select btn-primary"
            type="button"
            disabled={!isValid[currentTab]}
          >
            <span className="btn-label">Launch new quarter</span>
            <span className="btn-icon">
              <i className={`fas fa-rocket`}></i>
            </span>
          </button>
        </div>
      </div>
      
      <div className="project-details-indicators_content">
        {ProjectIndicatorsSection.filter(
            (pi: any) => "project-stage" !== pi.id
          ).map((item, idx) => (
            <ProjectIndicatorsRow
              key={`section-${idx}`}
              section={item}
              indicators={indicators}
              handleChange={handleChange}
              isValid={isValid[currentTab]}
            />
          ))}
      </div>

      <div className="project-details-indicators_foot"></div>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectIndicators);

import React from "react";

import "./styles.scss";

interface SPVReportingFundListItemProps {
  fund: any;
}

const SPVReportingFundListItem = ({ fund }: SPVReportingFundListItemProps) => {

  return (
    <>
      {!!fund && (
        <li className={`row ${ 0 === fund.indCompletion && 0 === fund.docCompletion ? "warn" : "" }`} >
          <div className="fund">{fund.code}</div>
          <div className={`fund-indicators ${ 0 === fund.totalIndCompletion ? "warn" : "" }`} >
            <div className="completion-container">
              <span
                className="completion-bar"
                style={{ width: 0 < fund.totalIndCompletion ? (fund.totalIndCompletion * 100) / fund.indCompletion + "px" : "0px" }}
              ></span>
            </div>
            {fund.totalIndCompletion}/{fund.indCompletion}
            <span className="warn-icon">
              <i className={`fas fa-exclamation-circle`}></i>
            </span>
          </div>
          <div className={`fund-documents ${0 === fund.totalDocCompletion ? "warn" : "" }`} >
            <div className="completion-container">
              <span
                className="completion-bar"
                style={{ width: 0 < fund.totalDocCompletion ? (fund.totalDocCompletion * 100) / fund.docCompletion + "px" : "0px"}}
              ></span>
            </div>
            {fund.totalDocCompletion}/{fund.docCompletion}
            <span className="warn-icon">
              <i className={`fas fa-exclamation-circle`}></i>
            </span>
          </div>
          {/* <div className="fund-downloads">
            <a href="files.zip" download>
              <button className="btn btn-select btn-primary" type="button">
                <span className="btn-icon">
                  <i className={`fas fa-download`}></i>
                </span>
              </button>
            </a>
          </div> */}
        </li>
      )}
    </>
  );
};

export default SPVReportingFundListItem;

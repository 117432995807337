import { Project } from "models/project.model";
import { Action } from "models/store.models";
import { Investor } from "modules/limited-partners/models/investor.model";

export enum ReferentialActionTypes {
  SetProjects = "@Referential/SetProjects",
  SetInvestors = "@Referential/SetInvestors",
  SetInvestor = "@Referential/SetInvestor",
}

export class SetProjects implements Action {
  public readonly type = ReferentialActionTypes.SetProjects;
  public constructor(public readonly projects: Project[]) {}
}

export class SetInvestors implements Action {
  public readonly type = ReferentialActionTypes.SetInvestors;
  public constructor(public readonly investors: Investor[]) {}
}

export class SetInvestor implements Action {
  public readonly type = ReferentialActionTypes.SetInvestor;
  public constructor(public readonly investorId: string) {}
}

export type ReferentialActions = SetProjects | SetInvestors | SetInvestor;

import React, { createContext, useState } from "react";
import { cloneDeep } from "lodash";
// import ProjectIndicators from "modules/hub/components/ProjectIndicators/ProjectIndicators";

const defaultIndicators: any = {
  id: null,
  state: "processing",
  dueDate: "", //DateTime.local().toFormat('yyyy-LL-dd'),
  constructionText: "",
  expectedFcDateText: "",
  projectRatingDisplay: false,
  projectRatingText: "",
  boardMembersDisplay: false,
  boardMembersText: "",
  adscrDisplay: false,
  adscrText: "",
  availabilityDataDisplay: false,
  availabilityDataText: "",
  revenueDisplay: false,
  revenueText: "",
  ebitdaDisplay: false,
  ebitdaText: "",
  operatingTurnoverDisplay: false,
  operatingTurnoverText: "",
  operatingExpensesDisplay: false,
  operatingExpensesText: "",
  totalAssetsDisplay: false,
  totalAssetsText: "",
  insuranceDataDisplay: false,
  insuranceDataText: "",
}

const defaultMacroeconomic: any = {
  id: null,
  assumptions: []
}

const defaultProjectIndicators: any = {
  id: null,
  projectStage: {},
  expectedFcDate: {
    date: "",
    isValidate: false
  },
  construction: {
    progress: "",
    contractualDate: "",
    expectedDate: "",
    isValidate: false
  },
  projectRating: {
    isReady: 'yes',
    moodyRating: null,
    spRating: null,
    fitchRating: null,
    dbrsRating: null,
    otherRating: "",
    isValidate: false
  },
  boardMembers: {
    total: "",
    members: [],
    isValidate: false
  },
  adscr: {
    backward: '',
    forward: '',
    isValidate: false,
    hasDscrs: 'yes'
  },
  availabilityData: {
    isRevenuesBasedOnPayments: 'yes',
    baseRevenue: "",
    deductions: "",
    deductionsOmContractor: "",
    deductionsConstructionContractor: "",
    otherDeductions: "",
    finalRevenue: "",
    isValidate: false
  },
  operatingTurnover: {
    lastFinancialYear: "",
    isValidate: false
  },
  // revenue: {
  //   lastFinancialYear: "",
  //   isValidate: false
  // },
  operatingExpenses: {
    earnings: "",
    isValidate: false
  },
  // ebitda: {
  //   earnings: "",
  //   margin: "",
  //   isValidate: false
  // },
  totalAssets: {
    earnings: "",
    isValidate: false
  },
  insuranceData: {
    hasCoverage: 'yes',
    switchClaims: false,
    policyClaimed: "",
    policyDocuments: [],
    policies: [],
    switchDoCoverage: false,
    name: "",
    cost: null,
    insurer: "",
    limit: null,
    renewalDate: "",
    isValidate: false
  },
  // insuranceDataDoCoverage: {
  //   hasCoverage: 'yes',
  //   switchClaims: false,
  //   policyClaimed: "",
  //   policyDocuments: [],
  //   policies: [],
  //   isValidate: false
  // }
}

const defaultProjectFilters = {
  fund: { id: "all", name: "All funds" },
  doc_type: { id: "all", name: "All documents" },
  search: "",
};

export const ReportingContext = createContext({
  parentRef: null,
  rawData: [defaultIndicators, [], {}],
  data: [defaultIndicators, [], {}],
  indicators: defaultIndicators,
  assumptions: defaultMacroeconomic,
  defaultProjectIndicators: defaultProjectIndicators,
  rawProjectIndicators: defaultProjectIndicators,
  projectIndicators: defaultProjectIndicators,
  isValid: [false, false, false],
  currentTab: 0,
  requestedTab: 0,
  totalProjectIndicatorsValidate: 0,
  projectFilters: defaultProjectFilters,
  setParentRef: (ref: any) => {},
  setData: (newData: any) => {},
  setRawData: (newData: any) => {},
  setCurrentTab: (newTab: any) => {},
  setRequestedTab: (newTab: any) => {},
  setIsValid: (valid: boolean) => {},
  setProjectIndicators: (newData: any) => {},
  setRawProjectIndicators: (newData: any) => {},
  setTotalProjectIndicatorsValidate: (newData: any) => {},
  setProjectFilters: (newData: any) => {},
  resetProjectFilters: () => {}
});

const ReportingProvider = (props: any) => {

  const [data, setData] = useState([defaultIndicators, [], defaultMacroeconomic]);

  const [rawData, setRawData] = useState([defaultIndicators, [], defaultMacroeconomic]);

  const [projectIndicators, setProjectIndicators] = useState(defaultProjectIndicators);

  const [rawProjectIndicators, setRawProjectIndicators] = useState(defaultProjectIndicators);

  const [projectFilters, setProjectFilters] = useState(defaultProjectFilters);

  const [totalProjectIndicatorsValidate, setTotalProjectIndicatorsValidate] = useState<number>(0);

  const [parentRef, setParentRef] = useState<any>(null);

  const [isValid, setIsValid] = useState([false, false, false]);

  const [currentTab, setCurrentTab] = useState(0);

  const [requestedTab, setRequestedTab] = useState(0);

  const updateData = (newData: any) => {
    const tmp: any = cloneDeep(data);
    tmp[currentTab] = newData;
    setData(tmp);
  }

  const updateRawData = (newData: any) => {
    const tmp: any = cloneDeep(data);
    tmp[currentTab] = newData;
    setRawData(tmp);
  }

  const updateValidity = (valid: boolean) => {
    const tmp: any = cloneDeep(isValid);
    tmp[currentTab] = valid;
    setIsValid(tmp);
  }

  const updateRawDataProjectIndicators = (newData: any) => {
    setRawProjectIndicators(newData);
  }

  const resetProjectFilters = () => {
    setProjectFilters(defaultProjectFilters);
  }

  const goTab = (tabIndex: number) => {
    setCurrentTab(tabIndex);
    setRequestedTab(0);
  }

  return (
    <ReportingContext.Provider
      value={{
        parentRef,
        rawData: rawData,
        data: data,
        indicators: data[0],
        assumptions: data[2],
        defaultProjectIndicators: defaultProjectIndicators,
        rawProjectIndicators: rawProjectIndicators,
        projectIndicators: projectIndicators,
        isValid: isValid,
        currentTab: currentTab,
        requestedTab: requestedTab,
        totalProjectIndicatorsValidate: totalProjectIndicatorsValidate,
        projectFilters: projectFilters,
        setParentRef,
        setData: (newData: any) => updateData(newData),
        setRawData: (newData: any) => updateRawData(newData),
        setCurrentTab: (newTab: any) => goTab(newTab),
        setRequestedTab: (newTab: any) => setRequestedTab(newTab),
        setIsValid: (valid: boolean) => updateValidity(valid),
        setProjectIndicators: (newData: any) => setProjectIndicators(newData),
        setRawProjectIndicators: (newData: any) => updateRawDataProjectIndicators(newData),
        setTotalProjectIndicatorsValidate: setTotalProjectIndicatorsValidate,
        setProjectFilters: setProjectFilters,
        resetProjectFilters: resetProjectFilters
      }}
    >
      {props.children}
    </ReportingContext.Provider>
  );
};

export default ReportingProvider;

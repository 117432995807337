import React, { createContext, useEffect, useState } from "react";
import { history } from "modules/shared/services/history";
import { useParams } from "react-router-dom";

export const PermissionContext = createContext({
  isLeaderView: false,
  isContributor: false,
  isGlobalAdmin: false,
  isTechnicalAdmin: false,
  isMeridiamGranted: false,
  forceRedirect: () => {},
});

const PermissionProvider = (props: any) => {

  const [isGlobalAdmin, setIsGlobalAdmin] = useState(false);

  const [isTechnicalAdmin, setIsTechnicalAdmin] = useState(false);

  const [isMeridiamGranted, setIsMeridiamGranted] = useState(false);

  const [isLeaderView, setIsLeaderView] = useState(false);

  const [isContributor, setIsContributor] = useState(false);

  const redirect = !!props.value.redirect ? props.value.redirect : "/";

  const module: any = !!props.value.module ? props.value.module : null;

  const allowedPermissions: any = !!props.value.allowedPermissions ? props.value.allowedPermissions : [];

  const user: any = !!props.value.user ? props.value.user : null;

  const { projectCode } = useParams<{ projectCode: string }>();

  useEffect(() => {
    // console.log('');
    // console.log('__PERMISSION CONTEXT__');
    // console.log(user);
    // console.log('module ', module);
    // console.log(allowedPermissions);
    // console.log(redirect);
    // console.log('');

    if (!!user && !!module && ("technical-admin" === user.platformRole || "global-admin" === user.platformRole || ("stakeholder" === user.platformRole && !!user.privileges && !!user.privileges.reporting && 'superadmin' === user.privileges.reporting[0].role) )){
      setIsMeridiamGranted(true);
    }

    if (!user || !module) {
      history.push(redirect);
    } else if (!!user.privileges[module] && 0 < user.privileges[module].length) {
      // console.log('');
      // console.log('---- PERMISSIONS')
      // console.log(projectCode, user.privileges[module]);

      const privileges: any = user.privileges[module].filter((u: any) => !projectCode || projectCode.toUpperCase() === u.details.projectCode );

      // console.log(privileges);

      let tmpIsLeaderView: boolean = false;
      let tmpIsContributor: boolean = false;
      for (let i in privileges) {
        if (  -1 !== ["superadmin", "admin"].indexOf(privileges[i].role) ) {
          tmpIsLeaderView = true;
        }else if (  -1 !== ["contributor"].indexOf(privileges[i].role) ) {
          tmpIsContributor = true;
        }
      }
      setIsLeaderView(tmpIsLeaderView);
      setIsContributor(tmpIsContributor);
      // }

      if (0 < allowedPermissions.length) {
        let foundPrivilege: boolean = false;

        user.privileges[module].forEach((p: any) => {
          if (-1 !== allowedPermissions.indexOf(p.role)) {
            foundPrivilege = true;
          }
        });

        if (!foundPrivilege) {
          console.log("PRIVILEGE NOT FOUND", redirect);
          history.push(redirect);
        }
        }
    } 
    // else {
    //   console.log("NO MODULE", redirect);
    //   history.push(redirect);
    // }
  }, [projectCode]);

  return (
    <PermissionContext.Provider
      value={{
        isLeaderView: isLeaderView,
        isContributor: isContributor,
        isGlobalAdmin: isGlobalAdmin,
        isTechnicalAdmin: isTechnicalAdmin,
        isMeridiamGranted: isMeridiamGranted,
        forceRedirect: () => history.push(redirect),
      }}
    >
      {props.children}
    </PermissionContext.Provider>
  );
};

export default PermissionProvider;

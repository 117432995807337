import React from "react";
import { useFormik } from "formik";

import { sendContactUS, sendContactUSReporting } from "../../services/send-email";

import "./styles.scss";

type ContactUsProps = {
  accessToken: string | undefined;
};

const ContactUsModal: React.FC<ContactUsProps> = ({ accessToken }) => {
  const formik = useFormik({
    initialValues: {
      message: "",
    },
    onSubmit: async (values) => {
      if (accessToken) {
        let data: any = {
          message: values.message,
        };

        const { status } = (!!/^\/spv-reporting/.test(document.location.pathname)) ? await sendContactUSReporting(accessToken, data) : await sendContactUS(accessToken, data);
           
        if('ok' === status){
          const elt: any = document.getElementById('closeButtonModalePageContactUs');
          elt.click();
        }
      }
    },
  });

  return (
    <div
      id="modalPageContactUs"
      className="own-modal modal-generic uk-modal"
      data-uk-modal
    >
      <div className="uk-modal-dialog">
        <div className="own-modal-content">
          <button
            id="closeButtonModalePageContactUs"
            className="btn btn-close label-external"
            type="button"
            data-uk-toggle="#modalPageContactUs"
          >
            <span className="btn-label">Close</span>
            <span className="btn-icon circle">
              <i className="fas fa-times"></i>
            </span>
          </button>
          <div className="uk-modal-header">
            <h2 className="upp">
              <span>Contact us HERE</span>
            </h2>
            {/* <h3> */}
            {/* <span>Contact us about limited partners</span> */}
            {/* </h3> */}
          </div>
          <div className="uk-modal-body">
            <div className="form">
              <form noValidate onSubmit={formik.handleSubmit}>
                <div className="row row-textarea">
                  <textarea
                    className="message"
                    name="message"
                    placeholder="Your text..."
                    required
                    onChange={formik.handleChange}
                    value={formik.values.message}
                  ></textarea>
                </div>
                <div className="wrapp-cta">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={formik.values.message === ""}
                  >
                    <span className="btn-label">Send</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUsModal;

import React, { useContext } from "react";
import { Link } from "react-router-dom";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

import "./styles.scss";
import { cloneDeep } from "lodash";
import { ReportingContext } from "context/Reporting.context";

interface SPVReportingModulesProps {
  indicatorsProjectsList: any
}

const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";

const titles: any = {
  'construction progress': 'Construction Progress',
  'construction contractualDate': 'Construction Contractual Completion Date',
  'construction expectedDate': 'Construction Expected Completion Date',
  'moodyRating': "Moody's",
  'fitchRating': 'Fitch',
  'otherRating': 'Other',
  'spRating': 'S&P',
  'dbrsRating': 'DBRS',
  'adscr backward': 'Adscr Backward',
  'adscr forward': 'Adscr Forward',
  'adscr isRevenuesBasedOnPayments': 'Revenue Based on Availability Payments',
  'adscr baseRevenue': 'AP Base Case',
  'adscr deductions': 'AP Deductions',
  'adscr deductionsOmContractor': 'AP OM Contractor Deductions',
  'adscr deductionsConstructionContractor': 'AP Construction Contractor Deductions',
  'adscr otherDeductions': 'AP Other Deductions',
  'adscr finalRevenue': 'AP Final Revenue',
  'adscr earnings': 'EBITDA',
  'adscr margin': 'EBITDA Margin',
  'insurance data policyClaimed': 'Insurance data policy claimed',
  'insurance data cost': 'Insurance data cost',
  'insurance data coveringPercentage': 'Insurance data covering percentage',
  'insurance data hasCoverage': 'Insurance data has coverage',
  'insurance data insurer': 'Insurance data coverage - insurer',
  'insurance data name': 'Insurance data coverage - policy number',
  // 'insurance data cost': 'Insurance data coverage - cost',
  // 'insurance data cost': 'Insurance data cost',
  // 'insurance data cost': 'Insurance data cost',
  // 'insurance data '
}

/*

insurance data cost
: 
"200,000"
insurance data coveringPercentage
: 
"20"
insurance data hasCoverage
: 
"yes"
insurance data insurer
: 
"Name insurer"
insurance data limit
: 
"20"
insurance data name
: 
"E8ZUEUE"
insurance data policyDocuments
: 
[]
insurance data renewalDate
: 
"2023-06-15"
insurance data switchClaims
: 
"yes"
insurance data switchDoCoverage
: 
"yes"

*/

const SPVReportingModules = ({indicatorsProjectsList}: SPVReportingModulesProps) => {

  const { resetProjectFilters } = useContext(ReportingContext);

  const downloadIndicators = (e: any) => {
    e.preventDefault();

    // console.log(indicatorsProjectsList)

    let tabMembers: any = [];
    let tabPolicies: any = [];
    const indicators: any = cloneDeep(indicatorsProjectsList).map((i: any) => {
      const tmpIndicators: any = JSON.parse(i.indicators);

      // console.log('');
      // console.log('__INDICATORS__');
      // console.log(tmpIndicators);

      // XLS CONSTRUCTION BY FORCING ORDER

      // console.log(i);
      let tmp:any = {
        "Funds": 0 < i.project.meridiamFunds.length ? i.project.meridiamFunds.map((f: any) => f.meridiamFund.fundId).join('\r\n') : '',
        "Funds name": 0 < i.project.meridiamFunds.length ? i.project.meridiamFunds.map((f: any) => f.meridiamFund.fundName).join('\r\n') : '',
        "Group": !!i.project.reportingGroup ? i.project.reportingGroup.replace(/group/, '') : '',
        "Project code": i.projectCode.toUpperCase(),
        "Project": i.project.projectName,
        "Complete": i.isComplete
      };

      for(let j in tmpIndicators.construction){
        if(-1 === ['isValidate', 'isReady'].indexOf(j)){
          tmp[`${!!titles[`construction ${j}`] ? titles[`construction ${j}`] : `construction ${j}`}`] = tmpIndicators.construction[j];
        }
      }

      tmp['Expected Fc Date'] = tmpIndicators.expectedFcDate.date;

      for(let j in tmpIndicators.projectRating){
        if(-1 === ['isValidate', 'isReady'].indexOf(j) && !!tmpIndicators.projectRating[j]){
          if('object' === typeof tmpIndicators.projectRating[j]){
            tmp[`Project Rating ${!!titles[j] ? titles[j] : j}`] = tmpIndicators.projectRating[j].name;
          }else if('otherRating' === j){
            tmp[`Project Rating ${!!titles[j] ? titles[j] : j}`] = tmpIndicators.projectRating[j].replace(/,/, '\r\n');
          }else{
            tmp[`Project Rating ${!!titles[j] ? titles[j] : j}`] = tmpIndicators.projectRating[j];
          }
        }
      }

      tmp['Board Members total'] = tmpIndicators.boardMembers.total;

      let members: any = [];
      for(let j in tmpIndicators.boardMembers.members){
        tmpIndicators.boardMembers.members[j].Firstname = tmpIndicators.boardMembers.members[j].firstname;
        tmpIndicators.boardMembers.members[j].Lastname = tmpIndicators.boardMembers.members[j].lastname;
        tmpIndicators.boardMembers.members[j]['Appointment Date'] = tmpIndicators.boardMembers.members[j].appointmentDate;
        tmpIndicators.boardMembers.members[j]['Termination Date'] = tmpIndicators.boardMembers.members[j].terminationDate;
        tmpIndicators.boardMembers.members[j].Category = !!tmpIndicators.boardMembers.members[j].category.id ? tmpIndicators.boardMembers.members[j].category.name : '';
        tmpIndicators.boardMembers.members[j].Title = !!tmpIndicators.boardMembers.members[j].title.id ? tmpIndicators.boardMembers.members[j].title.name : '';

        delete tmpIndicators.boardMembers.members[j].appointmentDate;
        delete tmpIndicators.boardMembers.members[j].terminationDate;
        delete tmpIndicators.boardMembers.members[j].lastname;
        delete tmpIndicators.boardMembers.members[j].firstname;
        delete tmpIndicators.boardMembers.members[j].title;
        delete tmpIndicators.boardMembers.members[j].category;

        members.push({...tmpIndicators.boardMembers.members[j], ...{
          "Project code": i.projectCode.toUpperCase(),
          "Project name": i.project.projectName
        }})
      }

      tabMembers = [...tabMembers, ...members];

      for(let j in tmpIndicators.adscr){
        if(-1 === ['isValidate', 'isReady'].indexOf(j)){
          tmp[`${!!titles[`adscr ${j}`] ? titles[`adscr ${j}`] : `adscr ${j}`}`] = tmpIndicators.adscr[j];
        }
      }

      for(let j in tmpIndicators.availabilityData){
        if(-1 === ['isValidate', 'isReady'].indexOf(j)){
          tmp[`${!!titles[`adscr ${j}`] ? titles[`adscr ${j}`] : `adscr ${j}`}`] = tmpIndicators.availabilityData[j];
        }
      }

      // tmp['Revenue'] = tmpIndicators.revenue.lastFinancialYear;
      tmp['Operating turnover'] = tmpIndicators.operatingTurnover?.lastFinancialYear;
      tmp['Operating Expenses'] = tmpIndicators.operatingExpenses?.earnings;
      tmp['Total Assets'] = tmpIndicators.totalAssets?.earnings;

      for(let j in tmpIndicators.insuranceData){
        if(-1 === ['isValidate', 'isReady', 'policies'].indexOf(j)){
          if('policyClaimed' === j){
            // console.log('--policy claimed', `${!!titles[`insurance data ${j}`] ? titles[`insurance data ${j}`] : `insurance data ${j}`}`, tmpIndicators.insuranceData[j]);
            tmp[`${!!titles[`insurance data ${j}`] ? titles[`insurance data ${j}`] : `insurance data ${j}`}`] = !!tmpIndicators.insuranceData[j] ? tmpIndicators.insuranceData[j].map((jj: any) => jj.name).toString() : '';
          }else{
            tmp[`${!!titles[`insurance data ${j}`] ? titles[`insurance data ${j}`] : `insurance data ${j}`}`] = tmpIndicators.insuranceData[j];
          }
        }
      }
      
// console.log(tmpIndicators.insuranceData);
      if(!!tmpIndicators.insuranceData){
        let policies: any = [];
        for(let j in tmpIndicators.insuranceData.policies){
          tmpIndicators.insuranceData.policies[j]['Policy Name'] = tmpIndicators.insuranceData.policies[j].name;
          tmpIndicators.insuranceData.policies[j].Cost = tmpIndicators.insuranceData.policies[j].cost;
          tmpIndicators.insuranceData.policies[j]['Insurer name'] = tmpIndicators.insuranceData.policies[j].insurer;
          tmpIndicators.insuranceData.policies[j]['Policy covered by the insurer'] = tmpIndicators.insuranceData.policies[j].coveringPercentage;
          tmpIndicators.insuranceData.policies[j]['Renewal date'] = tmpIndicators.insuranceData.policies[j].renewalDate

          delete tmpIndicators.insuranceData.policies[j].name;
          delete tmpIndicators.insuranceData.policies[j].cost;
          delete tmpIndicators.insuranceData.policies[j].insurer;
          delete tmpIndicators.insuranceData.policies[j].coveringPercentage;
          delete tmpIndicators.insuranceData.policies[j].renewalDate;

          policies.push({...{
            "Project code": i.projectCode.toUpperCase(),
            "Project name": i.project.projectName
          }, ...tmpIndicators.insuranceData.policies[j]})
        }

        tabPolicies = [...tabPolicies, ...policies];
      }

      // for(let j in tmpIndicators.ebitda){
      //   if(-1 === ['isValidate', 'isReady'].indexOf(j)){
      //     tmp[`${!!titles[`adscr ${j}`] ? titles[`adscr ${j}`] : `adscr ${j}`}`] = tmpIndicators.ebitda[j];
      //   }
      // }

      return tmp;
    })

    // console.log(indicators);
    // console.log('');

    const wsIndicators = XLSX.utils.json_to_sheet(indicators);
    const wsMembers = XLSX.utils.json_to_sheet(tabMembers);
    const wsPolicies = XLSX.utils.json_to_sheet(tabPolicies);

    // for(let i in ws){
    //   if(!!i.match(/^M\d+/) && 'M1' !== i){
    //     ws[i].s = {
    //       alignment: {
    //         wrapText: true
    //      }
    //     }
    //   }
    // }

    const wbData = { Sheets: { data: wsIndicators, members: wsMembers, policies: wsPolicies }, SheetNames: ["data", "members", "policies"] };
    const excelBuffer = XLSX.write(wbData, { bookType: "xlsx", type: "array" });

    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data,  `projects-indicators${fileExtension}`);
  }

  return (
    <div className="modules-container">
      <div className="modules-item">
        <h3>Actions</h3>
        <a download onClick={downloadIndicators}>
          <button className="btn btn-select btn-primary" type="button">
            <span className="btn-label">Download Indicators</span>
            <span className="btn-icon">
              <i className={`fas fa-download`}></i>
            </span>
          </button>
        </a>
      </div>
      <div className="modules-item">
        <h3>
          set up
          <br />
          quarter
        </h3>
        <Link to="/spv-reporting/indicators-and-documents" onClick={resetProjectFilters}>
          <button className="btn btn-select btn-primary" type="button">
            <span className="btn-label">Set files &amp; due dates</span>
            <span className="btn-icon">
              <i className={`fas fa-arrow-right`}></i>
            </span>
          </button>
        </Link>
      </div>
      <div className="modules-item">
        <h3>
          Manage
          <br />
          completion
        </h3>
        <button
          // onClick={}
          className="btn btn-select btn-primary"
          type="button"
        >
          <span className="btn-label">Email campaigns</span>
          <span className="btn-icon">
            <i className={`fas fa-mail-bulk`}></i>
          </span>
        </button>
      </div>
    </div>
  );
};

export default SPVReportingModules;

import React from "react";
import UIkit from "uikit";

const { DateTime } = require("luxon");

const CountryListItem: React.FC<{
  country: any;
  handleChange: (event: any) => void;
  handleCountryChange: (countryCode: string, value: string) => void;
  formDirty: string[];
}> = ({ country, handleChange, handleCountryChange, formDirty }) => {
  return (
    <li
      className={`
        ${
          !!country.currencyVal &&
          country.currencyVal.currPerDollar !== null &&
          country.currencyVal.currPerDollar !== 0 &&
          country.currencyVal.currPerDollar !== ""
            ? "row"
            : "row warn"
        }
        ${formDirty.find((i) => i === country.code) ? "is-modified" : ""}
      `}
    >
      <div className="name">
        <div className="country">{country.name}</div>
        <div className="iso-code">{country.code}</div>
      </div>
      <div className="currency">{country.currency}</div>
      <div className="group">
        <div className="selector">
          <div className="select">
            <button
              className="btn btn-select btn-primary full btn-rounded"
              type="button"
            >
              <span className="btn_label">{country.target}</span>
              <span className="btn_icon"></span>
            </button>
            <div
              className="drop drop-select oecd-group"
              data-uk-dropdown="pos: top-left; mode: click; delay-hide: 100"
            >
              <ul className="group lvl0 uk-nav uk-dropdown-nav">
                <li
                  className={
                    country.target === "developed" ? "item selected" : "item"
                  }
                  data-uk-toggle="target: .oecd-group"
                  onClick={() => {
                    handleCountryChange(country.code, "developed");
                    UIkit.dropdown(".oecd-group").hide();
                  }}
                >
                  <span className="clickable">Developed</span>
                </li>
                <li
                  className={
                    country.target === "emerging" ? "item selected" : "item"
                  }
                  data-uk-toggle="target: .oecd-group"
                  onClick={() => {
                    handleCountryChange(country.code, "emerging");
                    UIkit.dropdown(".oecd-group").hide();
                  }}
                >
                  <span className="clickable">Emerging</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="dollar-parity">
        <input
          type="number"
          placeholder="-"
          value={
            !!country.currencyVal && !!country.currencyVal.currPerDollar
              ? country.currencyVal.currPerDollar
              : ""
          }
          name={country.code}
          onChange={handleChange}
        />
        <div className="tooltip">
          <span className="tooltip-label">
            {/* Missing data */}
            Please enter a number e.g.{" "}
            {navigator.language.includes("fr") ? "0,03" : "0.03"}
          </span>
        </div>
      </div>
      <div className="warning">
        {!!country.currencyVal &&
        country.currencyVal.currPerDollar !== null &&
        country.currencyVal.currPerDollar !== "" ? (
          ""
        ) : (
          <i className="fas fa-exclamation-circle"></i>
        )}
      </div>
      <div className="last-update">
        {!!country.currencyVal && !!country.currencyVal.lastUpdate
          ? DateTime.fromISO(country.currencyVal.lastUpdate).toFormat(
              "LL/dd/yyyy"
            )
          : "-"}
      </div>
    </li>
  );
};

export default CountryListItem;

import React from "react";
import UIkit from "uikit";

import "./styles.scss";

const WarnModal: React.FC<{
  id: string;
  missingData?: string;
  content: string;
  actionYes?: any;
  actionNo?: any
}> = ({ id, missingData, content, actionYes, actionNo }) => {
  const closeModal = async (action: string) => {
    UIkit.modal("#" + id).show();
    switch(action){
      case 'confirm':
        UIkit.modal("#" + id).hide();  
        document.querySelector('html')?.classList.remove('uk-modal-page'); 
        if(!!actionYes){
          await actionYes();
        }  
      break;
      case 'close':
        UIkit.modal("#" + id).hide();  
        document.querySelector('html')?.classList.remove('uk-modal-page'); 
        if(!!actionNo){
          await actionNo();
        } 
      break;
      default:
        UIkit.modal("#" + id).hide();
        document.querySelector('html')?.classList.remove('uk-modal-page'); 
    }
  };

  return (
    <div
      id={id}
      className="own-modal modal-generic uk-modal"
      data-uk-modal="container:false;"
    >
      <div className="uk-modal-dialog">
        <div className="own-modal-content">
          <div className="uk-modal-body">
            {!!missingData && (
              <p
                className="missing-data"
                dangerouslySetInnerHTML={{ __html: missingData }}
              ></p>
            )}

            <h2 className="upp">
              <span dangerouslySetInnerHTML={{ __html: content }}></span>
              <span className="icon">
                <i className="fas fa-exclamation-circle"></i>
              </span>
            </h2>

            <div className="btn-container">
              <button
                className="btn btn-select btn-primary"
                // data-uk-toggle={"#" + id}
                type="button"
                onClick={() => closeModal("confirm")}
              >
                <span className="btn-label">Yes</span>
                <span className="btn-icon">
                  <i className={`fas fa-check`}></i>
                </span>
              </button>
              <button
                className="btn btn-select btn-primary"
                // data-uk-toggle={"#" + id}
                onClick={() => closeModal("close")}
                type="button"
              >
                <span className="btn-label">No</span>
                <span className="btn-icon">
                  <i className={`fas fa-times`}></i>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WarnModal;

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { RootState, ThunkDispatch } from "models/store.models";
import {
  SdgContribution,
  EsgFilter
} from "models/esg-contribution.model";
import {
  getSdgContribution,
  requestSdgContributionFilter,
} from "modules/platform/services/esg-contribution.service";

import Loader from "../../../shared/components/Loader/Loader";
import Header from "../../../shared/components/Header/Header";
import SdgContributionSelectorSection from "../../components/SdgContribution/SdgContributionSelectorSection/SdgContributionSelectorSection";
import "../../../../assets/scss/custom/tools/_base.scss";

import "./styles.scss";

import TitlePage from "../../../shared/components/Title/TitlePage/TitlePage";
import PlatformToolBar from "../PlatformToolBar/PlatformToolBar";
import SdgContributionSdgPanelChoice from "modules/platform/components/SdgContribution/SdgContributionSdgPanelChoice/SdgContributionSdgPanelChoice";
import SdgContributionFilterSection from "modules/platform/components/SdgContribution/SdgContributionFilterSection/SdgContributionFilterSection";
import SdgContributionHighchartsWrapper from "modules/platform/components/SdgContribution/SdgContributionHighchartsWrapper/SdgContributionHighchartsWrapper";
import DownloadPdfButton from "modules/shared/components/Ui/Buttons/DownloadPdfButton/DownloadPdfButton";
import { getCampaigns } from "modules/shared/services/survey.service";

const mapStateToProps = ({ session }: RootState) => ({
  accessToken: session?.sessionData?.accessToken
    ? session?.sessionData?.accessToken
    : "",
  user: session?.sessionData?.userData,
});

const mapDispatchToProps = (dispatch: ThunkDispatch) => ({});

type EsgSdgContributionProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const EsgSdgContribution: React.FC<EsgSdgContributionProps> = ({
  accessToken,
  user,
}) => {  
  const [isProjectLeaderView, setIsProjectLeaderView] = useState<boolean|null>(null);
  const [isPrint, setIsPrint] = useState<boolean>(false);

  
  const [campaigns, setCampaigns] = useState<number[]>([]);
  const [extendedPdfMode, setPdfMode] = useState<any>(null);
  const [currentYear, setCurrentYear] = useState<number>(2022);

  const [showLoading, setShowLoading] = useState<boolean>(true);
  const sdgList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17];

  const [currentSdg, setCurrentSdg] = useState<number>(0); // 0
  const [currentTarget, setCurrentTarget] = useState<any>(""); // "3.8"
  const [currentIndicator, setCurrentIndicator] = useState<any>(""); // "154"
  const [esgFilter, setEsgFilter] = useState<EsgFilter>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [comparaisonLevel, setComparaisonLevel] = useState<string>("project"); //  "type" | "fund" | "project"
  
  const [filterProjectState, setFilterProjectState] = useState<string>("");
  const [filterProjectBy, setFilterProjectBy] = useState<string>("");
  const [filterProjectByValue, setFilterProjectByValue] = useState<string>("");
  const [currentSearch, setCurrentSearch] = useState<string>("");

  const [
    currentSdgContribution,
    setCurrentSdgContribution,
  ] = useState<SdgContribution|null>();

  const resetCurrentSelection = (type: string) => {
    setIsLoading(true);
    setCurrentSearch("");
    if (type === "target") {
      setCurrentTarget("");
    }
    setCurrentIndicator("");
  };

  const setValueGraph = (type: string, value: any) => {
    setIsLoading(true);
    setCurrentSearch("");

    switch (type) {
      case "sdg":
        setCurrentIndicator("");
        setCurrentTarget("");
        setCurrentSdg(value);
        break;
      case "target":
        setCurrentIndicator("");
        setCurrentTarget(value);
        break;
      case "indicator":
        setCurrentIndicator(value);
        break;
    }
  };

  // this filter is only available in by project selection
  const resetFilter = (type: string) => {
    switch (type) {
      case "state":
        setFilterProjectState("");
        break;
      case "typeCategory":
        setFilterProjectBy("");
        break;
      case "search":
        setCurrentSearch("");
        break;
      default:
    }
  };

  const selectProject = (projectName: string) => {
    setCurrentSearch(projectName);
    let input = document.getElementById("searchInGraph") as HTMLInputElement;
    input.value = projectName;
    input.dispatchEvent(new KeyboardEvent("keyup", { key: "y" }));
  };

  const filterBy = (filterType: string, filterData: string) => {
    setFilterProjectBy(filterType);
    setFilterProjectByValue(filterData);
  }

  // GET CAMPAIGNS + is Project Leader View
  useEffect( () => {
    let isProjectLeaderView = true;
    if(!!user && (user.platformRole === "global-admin" || user.platformRole === "technical-admin")){
      isProjectLeaderView = false;
    }
    if(!!user && 
      user.platformRole === "stakeholder" &&
      user.privileges["esg-tool"]){
      user.privileges["esg-tool"].map((p: any) => {
        if (p.details["toolName"] === "sdg-contribution") {
          isProjectLeaderView = false;
        }
      });
    }
    setIsProjectLeaderView(isProjectLeaderView);

    getCampaigns(accessToken).then(
      (result: any) => {
        // console.log("%c -- Campaigns : ", "color:green;font-size:13px");
        // console.log(result);
        let avalaibleYear: number[] = [];
          if (result.length > 0) {
            for (let c in result) {
              if (result[c].id !== 2018) {
                avalaibleYear.push(result[c].id);
              }
            }
          }
          setCampaigns(avalaibleYear);
      }
    );
  }, [])

  // GET AVAILABLE FILTER
  useEffect(() => {
    requestSdgContributionFilter(accessToken, currentYear).then(
      (result: EsgFilter) => {
        // console.log("%c -- Filter SDG : ", "color:green;font-size:13px");
        // console.log(result);
        setEsgFilter(result);
      }
    );
  }, [currentYear]);

  // GET SDG CONTRIBUTION DATAS
  useEffect( () => {
    setShowLoading(true);
    setCurrentSdgContribution(null);
    getSdgContribution(accessToken, currentYear, filterProjectState, filterProjectBy, filterProjectByValue, comparaisonLevel)
      .then((result: any) => {
        // console.log(
        //   "%c -- Contribution Datas : ",
        //   "color:green;font-size:13px"
        // );
        // console.log(result);

        let datas = {
          listIndicator: result.listIndicator,
          projectsDatas: result.projectsDatas,
          typeOfGraph: comparaisonLevel,
          groupType: comparaisonLevel,
          comparaisonLevel: comparaisonLevel,
        };
        setCurrentSdgContribution(datas);
      })
      .then(() => {
        setShowLoading(false);
      });
  }, [comparaisonLevel, filterProjectState, filterProjectBy, filterProjectByValue, currentYear]);

  return (
    <>
      <Loader
        className={showLoading ? "on" : "off"}
        color="multicolors"
        size="large"
      />

      <div className="header-wrapper" data-uk-sticky="media:768;">
        <Header
          titleContext="SDG Contribution"
          colorContext={"seaLight"}
          classNameToolbarTop="lp"
          tool={`sdg-contribution`}
        />
      </div>

      {/* DOWNLOAD BTN */}
      {!!user && (
        <PlatformToolBar
          surveysYear={campaigns}
          user={user}
          setCurrentYear={setCurrentYear}
          currentYear={currentYear}
          currentTool={"contribution"}
          buttonsRight={
            <div className="export">
              { currentSdg !== 0 && 
                <DownloadPdfButton
                  visible={true}
                  optionsPrint={{
                    format: "A4",
                    orientation: "landscape",
                    displayHeaderFooter: false,
                    headerTemplate: false,
                    footerTemplate: false,
                    width: 1280,
                    height: 904,
                    printBackground: true,
                    preferCSSPageSize: true,
                    landscape: true,
                    assets: [
                      {
                        folder: "assets/css",
                        files: [
                          "development" !== process.env.NODE_ENV
                            ? "print.css"
                            : "print-local.css",
                        ],
                      },
                    ],
                  }}
                  pdfTitle=""
                  filename="SDG_Contribution"
                  selector="#cmpSdgContribution"
                  selectorHead="#cmpPdfHead"
                  selectorFoot="#cmpPdfLegends"
                  setPdfMode={setPdfMode}
                  extendedPdfMode={extendedPdfMode}
                  mode="link"
                  onclickValue={true}
                  label="Download"
                  labelInside=".pdf"
                  icon="fas fa-download"
                  itemsList={[]}
                  isPrint={isPrint}
                  setIsPrint={setIsPrint}
                />
              }
            </div>
          }
        />
      )}

      <div
        className="page page-tool page-tool-sgd-contribution"
        data-theme="seaLight"
      >
        <div className="page_inner">
          <TitlePage
            content={`
              <b>SDG </b>
              <b>Contribution</b>
            `}
          />
          <div className="grid grid-tool_sdg-contribution">
            <div className="grid_inner">
              <div className="main-content tool tool-sgd-contribution">
                <div className="content-body">
                  <div className="cols page">
                    <div className="row main-pdf">
                      {/* PDF HEADER */}
                      <div id="cmpPdfHead" className="pdf head-pdf" data-print="yes">
                        <div className="document-title">
                          <div className="meta meta-title">SDG CONTRIBUTION</div>
                        </div>
                      </div>

                      <div className="col col1 alone" id="cmpSdgContribution">
                        <div className="col-body page-pdf-tool-sdg-contribution">
                          {currentSdgContribution && isProjectLeaderView !== null && (
                            <div className="col-body">
                              {currentSdg === 0 ? (
                                <SdgContributionSdgPanelChoice
                                  sdgList={sdgList}
                                  currentSdgContribution={
                                    currentSdgContribution
                                  }
                                  setValueGraph={setValueGraph}
                                />
                              ) : (
                                <div className="row">
                                  {!!currentSdgContribution && (
                                    <>
                                      {/* Sdg-Target-Indicator SELECTOR */}
                                      <SdgContributionSelectorSection
                                        currentSdgContribution={
                                          currentSdgContribution
                                        }
                                        currentSdg={currentSdg}
                                        setValueGraph={setValueGraph}
                                        currentTarget={currentTarget}
                                        currentIndicator={currentIndicator}
                                        resetCurrentSelection={
                                          resetCurrentSelection
                                        }
                                        isProjectLeaderView={isProjectLeaderView}
                                      />

                                      {/* GRAPH */}
                                      <SdgContributionHighchartsWrapper
                                        currentSdgContribution={
                                          currentSdgContribution
                                        }
                                        currentSdg={currentSdg}
                                        currentTarget={currentTarget}
                                        currentIndicator={currentIndicator}
                                        setIsLoading={setIsLoading}
                                        isLoading={isLoading}
                                        print={isPrint}
                                      />
                                    </>
                                  )}

                                  {/* LEDENDS - level explanation */}
                                  {currentSdgContribution &&
                                    currentSdgContribution.listIndicator &&
                                    currentIndicator !== "" && (
                                      <div className="legends">
                                        <div className="inner-legends">
                                          <div
                                            className="own-tooltip"
                                            data-print="no"
                                          >
                                            <div className="tooltip tooltip-remote">
                                              <span className="tooltip-icon">
                                                <i className="fas fa-info-circle"></i>
                                              </span>
                                              <span className="tooltip-label">
                                                <i>
                                                  <b>Level explanations</b>
                                                </i>
                                              </span>
                                            </div>
                                            <div className="tooltip tooltip-bubble">
                                              <div className="inner-tooltip">
                                                <ul className="list list-explanations">
                                                  {!!currentSdgContribution
                                                    .listIndicator[
                                                    currentSdg
                                                  ] &&
                                                    !!currentSdgContribution
                                                      .listIndicator[
                                                      currentSdg
                                                    ][currentTarget] &&
                                                    !!currentSdgContribution
                                                      .listIndicator[
                                                      currentSdg
                                                    ][currentTarget][
                                                      currentIndicator
                                                    ] &&
                                                    !!currentSdgContribution
                                                      .listIndicator[
                                                      currentSdg
                                                    ][currentTarget][
                                                      currentIndicator
                                                    ].levels &&
                                                    currentSdgContribution.listIndicator[
                                                      currentSdg
                                                    ][currentTarget][
                                                      currentIndicator
                                                    ].levels.map(
                                                      (
                                                        level: any,
                                                        idx: number
                                                      ) => {
                                                        return (
                                                          <li
                                                            className="level"
                                                            key={`lvlExplanation${idx}`}
                                                          >
                                                            {currentSdgContribution
                                                              .listIndicator[
                                                              currentSdg
                                                            ][currentTarget][
                                                              currentIndicator
                                                            ].unit ===
                                                            "Yes/No" ? (
                                                              <div className="inner-lever">
                                                                <span className="level-name">
                                                                  level {idx}
                                                                  <i className="fas fa-chevron-right"></i>
                                                                </span>
                                                                <span className="level-explanation">
                                                                  {level}
                                                                </span>
                                                              </div>
                                                            ) : (
                                                              <div className="inner-range">
                                                                <span className="level-name">
                                                                  level {idx}{" "}
                                                                  <i className="fas fa-chevron-right"></i>
                                                                </span>
                                                                {level !==
                                                                "" ? (
                                                                  <span className="level-explanation">
                                                                    {
                                                                      currentSdgContribution
                                                                        .listIndicator[
                                                                        currentSdg
                                                                      ][
                                                                        currentTarget
                                                                      ][
                                                                        currentIndicator
                                                                      ]
                                                                        .direction
                                                                    }{" "}
                                                                    {level}{" "}
                                                                    {
                                                                      currentSdgContribution
                                                                        .listIndicator[
                                                                        currentSdg
                                                                      ][
                                                                        currentTarget
                                                                      ][
                                                                        currentIndicator
                                                                      ].unit
                                                                    }
                                                                  </span>
                                                                ) : (
                                                                  <span className="level-explanation">
                                                                    other
                                                                  </span>
                                                                )}
                                                              </div>
                                                            )}
                                                          </li>
                                                        );
                                                      }
                                                    )}
                                                </ul>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>

                        {/* FILTER */}
                        {esgFilter && currentSdgContribution && (
                          <SdgContributionFilterSection
                            currentSdg={currentSdg}
                            setComparaisonLevel={setComparaisonLevel}
                            filterProjectBy={filterProjectBy}
                            resetFilter={resetFilter}
                            filterProjectByValue={filterProjectByValue}
                            esgFilter={esgFilter}
                            filterBy={filterBy}
                            filterProjectState={filterProjectState}
                            currentSearch={currentSearch}
                            setCurrentSearch={setCurrentSearch}
                            setFilterProjectState={setFilterProjectState}
                            currentSdgContribution={currentSdgContribution}
                            currentIndicator={currentIndicator}
                            currentTarget={currentTarget}
                            selectProject={selectProject}
                            comparaisonLevel={comparaisonLevel}
                          />
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EsgSdgContribution);

import React, { useState } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import typeEnumImported from "../../../datas/typeEnum.json";

import {
  SdgImpactSynthesisFilter,
  SdgImpactSynthesisInterface,
  SdgImpactSynthesisSelection,
} from "models/sdg-impact-synthesis.model";

import styles from "./styles.module.scss";
import { ProjectTypeEnumInterface } from "models/project-type-enum";

type SdgImpactSynthesisProps = {
  campaigns: number[];
  year: number;
  setYear: any;
  typeOfSelection: string;
  setTypeOfSelection: any;
  serieSdgResult: SdgImpactSynthesisInterface;
  availableFilter: SdgImpactSynthesisFilter;
  selection: SdgImpactSynthesisSelection;
  serieNumber: string;
  toggleSelection: any;
  resetSelection: any;
  setShowConfidenceIndex: any;
  showConfidenceIndex: boolean;
};

const SisFilterSection: React.FC<SdgImpactSynthesisProps> = ({
  campaigns,
  year,
  setYear,
  typeOfSelection,
  setTypeOfSelection,
  availableFilter,
  selection,
  serieNumber,
  toggleSelection,
  resetSelection,
  setShowConfidenceIndex,
  showConfidenceIndex
}) => {
  const [
    currentFilterTypeSelected,
    setCurrentFilterTypeSelected,
  ] = useState<string>();

  const typeEnum: {
    [key: string]: ProjectTypeEnumInterface;
  } = typeEnumImported;

  const switchTabFilter = (newTab: string) => {
    // reset filter
    resetSelection("all", serieNumber);
    setTypeOfSelection(newTab);
  }

  return (
    <>
      <div className={`${styles["filters-head"]}`}>
        <div className={styles.actions}>
          <div className={styles.dropdown}>
            <button
              className="btn btn-select btn-primary btn-rounded"
              type="button"
            >
              <span className="btn_label">{year}</span>
              <span className="btn_icon">
                <i className="fas fa-carret-down"></i>
              </span>
            </button>
            <div className="drop" data-uk-dropdown={"pos: bottom-left"}>
              <ul className="uk-nav uk-dropdown-nav">
                {campaigns &&
                  campaigns.map((campaign) => {
                    return (
                      <li
                        key={`year-${campaign}-${serieNumber}`}
                        onClick={(e: any) => setYear(campaign, serieNumber)}
                      >
                        <span>{campaign}</span>
                      </li>
                    );
                  })}
              </ul>
            </div>

            {/* 
            <select
              defaultValue={year}
              onChange={(e) => setYear(e.target.value, serieNumber)}
            >
              {campaigns &&
                campaigns.map((campaign) => {
                  return (
                    <option
                      value={campaign}
                      key={`year-${campaign}-${serieNumber}`}
                    >
                      {campaign}
                    </option>
                  );
                })}
            </select> */}
          </div>
        </div>
      </div>

      {/* TOOGLE TYPE OF SELECTION */}
      <ul className={styles["remote-list"]}>
        <li>
          <button
            className={`${styles.btn} ${
              typeOfSelection === "by-filter" ? styles.active : ""
            }`}
            onClick={() => {
              switchTabFilter("by-filter");
            }}
          >
            <span className={styles.btn_label}>By filter</span>
          </button>
        </li>
        <li>
          <button
            className={`${styles.btn} ${
              typeOfSelection === "custom" ? styles.active : ""
            }`}
            onClick={() => {
              switchTabFilter("custom");
            }}
          >
            <span className={styles.btn_label}>Custom selection</span>
          </button>
        </li>
      </ul>

      {/*  SELECTION BY FILTER */}
      <div
        className={`${styles["filters-wrapper"]}  ${
          typeOfSelection === "by-filter" ? styles.open : styles.close
        }`}
      >
        <div className={`${styles["filters-selectors"]}`}>
          <ul className={styles["selectors-list"]}>
            {/* filter by type */}
            <li className={styles.selector}>
              <button
                className={`${styles.btn} ${
                  currentFilterTypeSelected === "type" ? styles.current : ""
                } ${
                  selection && selection.projectTypes.length > 0
                    ? styles.active
                    : ""
                }`}
                onClick={() => {
                  setCurrentFilterTypeSelected(
                    currentFilterTypeSelected === "type" ? "" : "type"
                  );
                }}
              >
                <span className={styles.btn_label}>Type</span>
              </button>
            </li>

            {/* filter by location */}
            <li className={styles.selector}>
              <button
                className={`${styles.btn} ${
                  currentFilterTypeSelected === "location" ? styles.current : ""
                } ${
                  selection && selection.location.length > 0
                    ? styles.active
                    : ""
                }`}
                onClick={() => {
                  setCurrentFilterTypeSelected(
                    currentFilterTypeSelected === "location" ? "" : "location"
                  );
                }}
              >
                <span className={styles.btn_label}>Location</span>
              </button>
            </li>

            {/* filter by fund */}
            <li className={styles.selector}>
              <button
                className={`${styles.btn} ${
                  currentFilterTypeSelected === "fund" ? styles.current : ""
                } ${
                  selection && selection.meridiamFunds.length > 0
                    ? styles.active
                    : ""
                }`}
                onClick={() => {
                  setCurrentFilterTypeSelected(
                    currentFilterTypeSelected === "fund" ? "" : "fund"
                  );
                }}
              >
                <span className={styles.btn_label}>Fund</span>
              </button>
            </li>

            {/* filter by project state */}
            <li className={styles.selector}>
              <button
                className={`${styles.btn} ${
                  currentFilterTypeSelected === "state" ? styles.current : ""
                } ${
                  selection && selection.projectStates.length > 0
                    ? styles.active
                    : ""
                }`}
                onClick={() => {
                  setCurrentFilterTypeSelected(
                    currentFilterTypeSelected === "state" ? "" : "state"
                  );
                }}
              >
                <span className={styles.btn_label}>Project state</span>
              </button>
            </li>

            {/* filter by investment phase */}
            <li className={styles.selector}>
              <button
                className={`${styles.btn} ${
                  currentFilterTypeSelected === "invest" ? styles.current : ""
                } ${
                  selection && selection.projectInvestmentPhases.length > 0
                    ? styles.active
                    : ""
                }`}
                onClick={() => {
                  setCurrentFilterTypeSelected(
                    currentFilterTypeSelected === "invest" ? "" : "invest"
                  );
                }}
              >
                <span className={styles.btn_label}>Investment phase</span>
              </button>
            </li>
          </ul>
        </div>

        <div className={styles["filters-group"]}>
          {/* filter by type */}
          <div
            className={`${styles.filter} ${
              currentFilterTypeSelected === "type" ? styles.open : styles.close
            }`}
          >
            <ul>
              {!!availableFilter &&
                !!availableFilter.types &&
                availableFilter.types.map((type, i) => {
                  return (
                    <li key={`fK-${type.categoryName}-${serieNumber}`}>
                      <div className={`${styles["filter-heading"]}`}>
                        <span>
                          {typeEnum[type.categoryName]
                            ? typeEnum[type.categoryName].niceName
                            : type.categoryName}
                        </span>
                      </div>
                      <ul>
                        {!!type.types &&
                          type.types.map((elem) => {
                            return (
                              <li
                                className={`${styles["filter-row-checkbox"]}`}
                                key={`fK-${elem.projectType}-${serieNumber}`}
                              >
                                <input
                                  id={`labelK-${elem.projectType}-${serieNumber}`}
                                  checked={
                                    (selection &&
                                      selection.projectTypes.includes(
                                        elem.projectType
                                      )) ||
                                    (selection &&
                                      selection.projectTypes.includes("all"))
                                  }
                                  type="checkbox"
                                  onChange={() =>
                                    toggleSelection(
                                      elem.projectType,
                                      "projectTypes",
                                      serieNumber
                                    )
                                  }
                                />
                                <label
                                  htmlFor={`labelK-${elem.projectType}-${serieNumber}`}
                                  className={`${styles["checkbox-label"]}`}
                                >
                                  <span>
                                    {typeEnum[elem.projectType]
                                      ? typeEnum[elem.projectType].niceName
                                      : elem.projectType}
                                  </span>
                                </label>
                              </li>
                            );
                          })}
                      </ul>
                    </li>
                  );
                })}
            </ul>
          </div>

          {/* filter by location */}
          <div
            className={`${styles.filter} ${
              currentFilterTypeSelected === "location"
                ? styles.open
                : styles.close
            }`}
          >
            <ul>
              {!!availableFilter &&
                !!availableFilter.countries &&
                availableFilter.countries.map((continent, i) => {
                  return (
                    <li key={`fK-${continent.continentName}-${serieNumber}`}>
                      <div className={`${styles["filter-heading"]}`}>
                        <span>{continent.continentName}</span>
                      </div>
                      <ul>
                        {!!continent.countries &&
                          continent.countries.map((country) => {
                            return (
                              <li
                                className={`${styles["filter-row-checkbox"]}`}
                                key={`fK-${country.countryCode}-${serieNumber}`}
                              >
                                <input
                                  id={`labelK-${country.countryCode}-${serieNumber}`}
                                  checked={
                                    (selection &&
                                      selection.location.includes(
                                        country.countryCode
                                      )) ||
                                    (selection &&
                                      selection.location.includes("all"))
                                  }
                                  type="checkbox"
                                  onChange={() =>
                                    toggleSelection(
                                      country.countryCode,
                                      "location",
                                      serieNumber
                                    )
                                  }
                                />

                                <label
                                  htmlFor={`labelK-${country.countryCode}-${serieNumber}`}
                                  className={`${styles["checkbox-label"]}`}
                                >
                                  <span>{country.countryName}</span>
                                </label>
                              </li>
                            );
                          })}
                      </ul>
                    </li>
                  );
                })}
            </ul>
          </div>

          {/* filter by fund */}
          <div
            className={`${styles.filter} ${
              currentFilterTypeSelected === "fund" ? styles.open : styles.close
            }`}
          >
            <ul>
              {availableFilter &&
                availableFilter.funds &&
                availableFilter.funds.map((fund) => {
                  return (
                    <li
                      className={`${styles["filter-row-checkbox"]}`}
                      key={`fK-${fund.mfCode}-${serieNumber}`}
                    >
                      <input
                        id={`labelK-${fund.mfCode}-${serieNumber}`}
                        checked={
                          (selection &&
                            selection.meridiamFunds.includes(fund.mfCode)) ||
                          (selection && selection.meridiamFunds.includes("all"))
                        }
                        type="checkbox"
                        onChange={() =>
                          toggleSelection(
                            fund.mfCode,
                            "meridiamFunds",
                            serieNumber
                          )
                        }
                      />

                      <label
                        htmlFor={`labelK-${fund.mfCode}-${serieNumber}`}
                        className={`${styles["checkbox-label"]}`}
                      >
                        <span>{fund.mfName}</span>
                      </label>
                    </li>
                  );
                })}
            </ul>
          </div>

          {/* filter by project state */}
          <div
            className={`${styles.filter} ${
              currentFilterTypeSelected === "state" ? styles.open : styles.close
            }`}
          >
            <ul>
              {availableFilter &&
                availableFilter.projectStates &&
                availableFilter.projectStates.map((projectState) => {
                  return (
                    <li
                      className={`${styles["filter-row-checkbox"]}`}
                      key={`lpF-${projectState}-${serieNumber}`}
                    >
                      <input
                        id={`labePSK-${projectState}-${serieNumber}`}
                        checked={
                          (selection &&
                            selection.projectStates.includes(projectState)) ||
                          (selection && selection.projectStates.includes("all"))
                        }
                        type="checkbox"
                        onChange={() =>
                          toggleSelection(
                            projectState,
                            "projectStates",
                            serieNumber
                          )
                        }
                      />

                      <label
                        htmlFor={`labePSK-${projectState}-${serieNumber}`}
                        className={`${styles["checkbox-label"]}`}
                      >
                        <span>{projectState}</span>
                      </label>
                    </li>
                  );
                })}
            </ul>
          </div>

          {/* filter by investment phase */}
          <div
            className={`${styles.filter} ${
              currentFilterTypeSelected === "invest"
                ? styles.open
                : styles.close
            }`}
          >
            <ul>
              {availableFilter &&
                availableFilter.projectInvestmentPhases &&
                availableFilter.projectInvestmentPhases.map((invesPhase) => {
                  return (
                    <li
                      className={`${styles["filter-row-checkbox"]}`}
                      key={`ipF-${invesPhase}-${serieNumber}`}
                    >
                      <input
                        id={`labeIPK-${invesPhase}-${serieNumber}`}
                        checked={
                          (selection &&
                            selection.projectInvestmentPhases.includes(
                              invesPhase
                            )) ||
                          (selection &&
                            selection.projectInvestmentPhases.includes("all"))
                        }
                        type="checkbox"
                        onChange={() =>
                          toggleSelection(
                            invesPhase,
                            "projectInvestmentPhases",
                            serieNumber
                          )
                        }
                      />

                      <label
                        htmlFor={`labeIPK-${invesPhase}-${serieNumber}`}
                        className={`${styles["checkbox-label"]}`}
                      >
                        <span>{invesPhase}</span>
                      </label>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>

        <div className={styles["option-confidence"]}>
          {year && year >= 2020 ? (
            <button
              className={`${styles.btn} ${styles["btn-confidence"]} ${
                showConfidenceIndex === true ? styles.active : styles.inactive
              }`}
              onClick={() => {
                setShowConfidenceIndex(!showConfidenceIndex);
              }}
            >
              <span className={styles.btn_label}>Confidence index</span>
              <span className={styles.btn_icon}>
                {showConfidenceIndex ? (
                  <i className="fas fa-toggle-on"></i>
                ) : (
                  <i className="fas fa-toggle-off"></i>
                )}
              </span>
            </button>
          ) : (
            <p>
              The confidence index feature is only available for surveys
              completed from 2020.
            </p>
          )}
        </div>
      </div>

      {/* CUSTOM SELECTION */}
      <div
        className={`${styles["custom-selection-wrapper"]}  ${
          typeOfSelection === "custom" ? styles.open : styles.close
        }`}
      >
        <ul className={styles["projects-list"]}>
          <li className={styles["list-item"]}>
            <FormControlLabel
              label={`All projects (${
                availableFilter && availableFilter.projects.length
              })`}
              control={
                <Checkbox
                  checked={
                    selection &&
                    availableFilter &&
                    selection.projects.length ===
                      availableFilter.projects.length
                  }
                  onChange={() => resetSelection("project", serieNumber)}
                  color="primary"
                />
              }
            />
          </li>
          <li>
            <ul className={styles["projects-list"]}>
              {availableFilter &&
                availableFilter.projects
                  .sort((a, b) => a.projectName.localeCompare(b.projectName))
                  .map((project) => {
                    return (
                      <li
                        className={styles["list-item"]}
                        key={`prj-${project.projectCode}-${serieNumber}`}
                      >
                        <FormControlLabel
                          label={`${project.projectName}`}
                          control={
                            <Checkbox
                              checked={
                                selection &&
                                selection.projects.includes(project.id)
                              }
                              onChange={() =>
                                toggleSelection(
                                  project.id,
                                  "project",
                                  serieNumber
                                )
                              }
                              color="primary"
                            />
                          }
                        />
                      </li>
                    );
                  })}
            </ul>
          </li>
        </ul>
      </div>
    </>
  );
};

export default SisFilterSection;

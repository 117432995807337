import React, { useEffect, useState } from "react";
import {
  SdgImpactSynthesisFilter,
  SdgImpactSynthesisSelection,
  SdgImpactSynthesisInterface,
} from "models/sdg-impact-synthesis.model";

import {
  getSurveySdgImpact,
  getSurveyRichSdgImpact,
  getSurveySDGImpactFilter,
} from "modules/platform/services/sdg-impact-synthesis.service";

import "../../../../../assets/scss/custom/tools/_base.scss";
import styles from "./styles.module.scss";
import "./print.scss";

import typeEnumImported from "../../../datas/typeEnum.json";
import { ProjectTypeEnumInterface } from "models/project-type-enum";

import RosaceSection from "../RosaceSection/RosaceSection";
import FilterSection from "../FilterSection/FilterSection";

type SdgImpactSynthesisProps = {
  isDataLoading: boolean;
  setIsDataLoading: any;
  accessToken: string;
  extendedPdfMode?: boolean;
  setPdfMode?: any;
  isProjectLeaderView: boolean;
  setMeridiamResult: any;
  setProjectsData: any;
};

const SdgImpactSynthesisSection: React.FC<SdgImpactSynthesisProps> = ({
  setIsDataLoading,
  accessToken,
  extendedPdfMode,
  setPdfMode,
  isProjectLeaderView,
  setMeridiamResult,
  setProjectsData
}) => {
  const [goalMode, setGoalMode] = useState<boolean>(false);
  // const meridiamSDGgoal: (null | number)[] = [
  //   null,
  //   null,
  //   null,
  //   null,
  //   null,
  //   null,
  //   3.2,
  //   3.5,
  //   3,
  //   null,
  //   3,
  //   null,
  //   null,
  //   2.8,
  //   2.8,
  //   null,
  //   3,
  // ];

  const [currentSdgId, setCurrentSdgId] = useState<number>(0);

  const typeEnum: {
    [key: string]: ProjectTypeEnumInterface;
  } = typeEnumImported;

  // const setGoalsMode = () => {
  //   !!goalMode ? setGoalMode(false) : setGoalMode(true);
  //   //    console.log(goalMode);
  // };

  // DEFAUTL SELECTION - for set up + reset
  const defaultSelection: SdgImpactSynthesisSelection = {
    projectTypes: ["all"],
    meridiamFunds: ["all"],
    projectStates: ["all"],
    projectInvestmentPhases: ["all"],
    location: ["all"],
    projects: [],
  };
  const [campaigns, setCampaigns] = useState<number[]>([]);
  const [comparaisonMode, setComparaisonMode] = useState<boolean>(false);

  const [isRosaceLoading, setIsRosaceLoading] = useState<boolean>(true);

  // first serie
  const [firstSerieYear, setFirstSerieYear] = useState<number>(2022);
  const [firstSerieHasSelection, setFirstSerieHasSelection] = useState<boolean>(
    false
  );
  const [
    firstSerieShowConfidenceIndex,
    setFirstSerieShowConfidenceIndex,
  ] = useState<boolean>(false);

  const [
    firstSerieTypeOfSelection,
    setFirstSerieTypeOfSelection,
  ] = useState<string>((isProjectLeaderView === true) ? "custom" : "by-filter");

  const [
    firstSerieSdgResult,
    setFirstSerieSdgResult,
  ] = useState<SdgImpactSynthesisInterface>();
  const [
    firstSerieAvailableFilter,
    setFirstSerieAvailableFilter,
  ] = useState<SdgImpactSynthesisFilter>();
  const [
    firstSerieSelection,
    setFirstSerieSelection,
  ] = useState<SdgImpactSynthesisSelection>(defaultSelection);

  // second serie
  const [secondSerieYear, setSecondSerieYear] = useState<number>(2022);
  const [
    secondSerieHasSelection,
    setSecondSerieHasSelection,
  ] = useState<boolean>(false);
  const [
    secondSerieShowConfidenceIndex,
    setSecondSerieShowConfidenceIndex,
  ] = useState<boolean>(false);
  const [
    secondSerieTypeOfSelection,
    setSecondSerieTypeOfSelection,
  ] = useState<string>(isProjectLeaderView === true ? "custom" : "by-filter");
  const [
    secondSerieSdgResult,
    setSecondSerieSdgResult,
  ] = useState<SdgImpactSynthesisInterface>();
  const [
    secondSerieAvailableFilter,
    setSecondSerieAvailableFilter,
  ] = useState<SdgImpactSynthesisFilter>();
  const [
    secondSerieSelection,
    setSecondSerieSelection,
  ] = useState<SdgImpactSynthesisSelection>(defaultSelection);

  const [sdgCallNumber, setSdgCallNumber] = useState<number>(0);

  // set up first serie as meridiam datas (project leader view == false ; else result of all his project) + set up default available filter
  useEffect(() => {
    if (accessToken) {
      // if not PLV => meridiam result
      if(isProjectLeaderView === false){
        getSurveyRichSdgImpact(
          accessToken,
          firstSerieYear,
          defaultSelection,
          firstSerieTypeOfSelection
        )
          .then((result) => {
            // console.log("%c -- SDG Datas : ", "color:green;font-size:13px");
            // console.log(result.projectSdg);
            setFirstSerieSdgResult(result.result);
            setSecondSerieSdgResult(result.result);
            setMeridiamResult(result.projectSdg);
          })
          .then(() => {
            setIsRosaceLoading(false);
            setIsDataLoading(false);
          });
      }

      // get all available filter + campaigns
      getSurveySDGImpactFilter(accessToken, firstSerieYear)
        .then((result: SdgImpactSynthesisFilter) => {
          // console.log("%c -- Filters : ", "color:green;font-size:13px");
          // console.log(result);
          setFirstSerieAvailableFilter(result);
          setSecondSerieAvailableFilter(result);
          setProjectsData(result.projects);

          let avalaibleYear: number[] = [];
          if (result.campaigns.length > 0) {
            for (let c in result.campaigns) {
              let campaign = result.campaigns[c];
              if (campaign.id !== 2018) {
                avalaibleYear.push(campaign.id);
              }
            }
          }
          setCampaigns(avalaibleYear);

          let newSelection: SdgImpactSynthesisSelection = {
            ...firstSerieSelection,
          };
          let projectArr: number[] = [];
          result.projects.forEach((project) => {
            projectArr.push(project.id);
          });
          newSelection.projects = projectArr;

          if(isProjectLeaderView === true){
            if(!!result.types && result.types.length > 0){
              newSelection.projectTypes = [result.types[0].types[0].projectType]
            }
            if(!!result.funds && result.funds.length > 0){
              newSelection.meridiamFunds = [result.funds[0].mfCode]
            }
          }

          setFirstSerieSelection(newSelection);
          setSecondSerieSelection(newSelection);

          // if PLV = custom selection
          if(isProjectLeaderView){
            getSurveySdgImpact(
              accessToken,
              firstSerieYear,
              newSelection,
              "custom"
            )
              .then((result: SdgImpactSynthesisInterface) => {
                // console.log("%c -- SDG Datas : ", "color:green;font-size:13px");
                // console.log(result);
                setFirstSerieSdgResult(result);
                setSecondSerieSdgResult(result);
              })
              .then(() => {
                setIsRosaceLoading(false);
                setIsDataLoading(false);
              });
          }
        });
    }
  }, []);

  return (
    <>
      <div className={`${styles.cols}`}>
        {/* LEFT PANNEL = Filter */}
        {!!accessToken && !!firstSerieAvailableFilter && !!secondSerieAvailableFilter && 
        !!secondSerieSdgResult && !!firstSerieSdgResult &&
          <FilterSection
            firstSerieHasSelection={firstSerieHasSelection} secondSerieHasSelection={secondSerieHasSelection} 
            setFirstSerieHasSelection={setFirstSerieHasSelection}
            setSecondSerieHasSelection={setSecondSerieHasSelection} setIsRosaceLoading={setIsRosaceLoading} 
            accessToken={accessToken} firstSerieYear={firstSerieYear}
            secondSerieYear={secondSerieYear} firstSerieTypeOfSelection={firstSerieTypeOfSelection} 
            secondSerieTypeOfSelection={secondSerieTypeOfSelection} 
            setFirstSerieSdgResult={setFirstSerieSdgResult} setSecondSerieSdgResult={setSecondSerieSdgResult} 
            firstSerieSelection={firstSerieSelection}
            firstSerieAvailableFilter={firstSerieAvailableFilter} 
            secondSerieSelection={secondSerieSelection} secondSerieAvailableFilter={secondSerieAvailableFilter}
            isProjectLeaderView={isProjectLeaderView} setFirstSerieSelection={setFirstSerieSelection} 
            setSecondSerieSelection={setSecondSerieSelection} defaultSelection={defaultSelection}
            setFirstSerieYear={setFirstSerieYear} setSecondSerieYear={setSecondSerieYear} setIsDataLoading={setIsDataLoading} 
            setFirstSerieAvailableFilter={setFirstSerieAvailableFilter} setSecondSerieAvailableFilter={setSecondSerieAvailableFilter} 
            setComparaisonMode={setComparaisonMode} 
            comparaisonMode={comparaisonMode} campaigns={campaigns} secondSerieSdgResult={secondSerieSdgResult} 
            firstSerieSdgResult={firstSerieSdgResult}
            setFirstSerieTypeOfSelection={setFirstSerieTypeOfSelection} setSecondSerieTypeOfSelection={setSecondSerieTypeOfSelection} 
            setFirstSerieShowConfidenceIndex={setFirstSerieShowConfidenceIndex} firstSerieShowConfidenceIndex={firstSerieShowConfidenceIndex}
            setSecondSerieShowConfidenceIndex={setSecondSerieShowConfidenceIndex} secondSerieShowConfidenceIndex={secondSerieShowConfidenceIndex}
            setSdgCallNumber={setSdgCallNumber} sdgCallNumber={sdgCallNumber}
          />
        }

        {/* RIGHT PANNEL = rosace + legend */}
        <div id="cmpPdfHead" className="pdf head-pdf" data-print="yes">
          <div className="document-title">
            <div className="meta meta-title">SDG IMPACT SYNTHESIS</div>
          </div>
        </div>

        {/* ROSACE */}
        { !!firstSerieSdgResult && !!secondSerieSdgResult &&
          <RosaceSection 
            currentSdgId={currentSdgId} 
            extendedPdfMode={extendedPdfMode}
            isRosaceLoading={isRosaceLoading}
            firstSerieSdgResult={firstSerieSdgResult} 
            secondSerieSdgResult={secondSerieSdgResult} 
            setPdfMode={setPdfMode} setCurrentSdgId={setCurrentSdgId}
            firstSerieShowConfidenceIndex={firstSerieShowConfidenceIndex}
            secondSerieShowConfidenceIndex={secondSerieShowConfidenceIndex}
            comparaisonMode={comparaisonMode} 
            goalMode={goalMode}
          />
        }

        <div className="page-break"></div>

        {/* PDF LEGEND */}
        <div id="page2" className="pdf page page-legends" data-print="yes">
          <div id="cmpPdfLegends" className={`pdf-legends`} data-print="yes">
            <div
              className={`pdf-legends-wrapper ${!!secondSerieSdgResult && !!comparaisonMode && "split"
                }`}
            >
              <div className={`legends_inner serie-1`}>
                <h2 className="upp">
                  <span>
                    First Serie
                    {firstSerieHasSelection && " filtered by"}
                  </span>
                </h2>

                {!firstSerieSelection.projectTypes.includes("all") &&
                  firstSerieSelection.projectTypes && (
                    <div className={`pdf-legend`}>
                      <h3>
                        Type <i className="fas fa-angle-right"></i>
                      </h3>
                      <ul>
                        {firstSerieSelection.projectTypes.map((type) => {
                          return (
                            <li className="filter" key={`fK-${type}`}>
                              <span>
                                {typeEnum[type]
                                  ? typeEnum[type].niceName
                                  : type}
                              </span>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  )}
                {!firstSerieSelection.location.includes("all") &&
                  firstSerieSelection.location && (
                    <div className={`pdf-legend`}>
                      <h3>
                        Location <i className="fas fa-angle-right"></i>
                      </h3>
                      <ul>
                        {firstSerieSelection.location.map((country) => {
                          return (
                            <li className="filter" key={`fK-${country}`}>
                              <span>{country}</span>
                            </li>
                          );
                        })}
                        ;
                      </ul>
                    </div>
                  )}
                {!firstSerieSelection.location.includes("all") &&
                  firstSerieSelection.meridiamFunds && (
                    <div className={`pdf-legend`}>
                      <h3>
                        Fund <i className="fas fa-angle-right"></i>
                      </h3>
                      <ul>
                        {firstSerieSelection.meridiamFunds.map((fund) => {
                          return (
                            <li className="filter" key={`fK-${fund}`}>
                              <span>{fund}</span>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  )}

                {!firstSerieSelection.projectStates.includes("all") &&
                  firstSerieSelection.projectStates && (
                    <div className={`pdf-legend`}>
                      <h3>
                        Project State <i className="fas fa-angle-right"></i>
                      </h3>
                      <ul>
                        {firstSerieSelection.projectStates.map(
                          (projectState, i) => {
                            return (
                              <li
                                className="filter"
                                key={`lpF-${projectState}`}
                              >
                                <span>{projectState}</span>
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </div>
                  )}

                {!firstSerieSelection.projectInvestmentPhases.includes("all") &&
                  firstSerieSelection.projectInvestmentPhases && (
                    <div className={`pdf-legend`}>
                      <h3>
                        Investment phases <i className="fas fa-angle-right"></i>
                      </h3>
                      <ul>
                        {firstSerieSelection.projectInvestmentPhases.map(
                          (invesPhase, i) => {
                            return (
                              <li className="filter" key={`ipF-${invesPhase}`}>
                                <span>{invesPhase}</span>
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </div>
                  )}
                <div className={`pdf-legend`}>
                  <h3>
                    Confidence index&nbsp;<i className="fas fa-angle-right"></i>
                    <small>
                      &nbsp;{!!firstSerieShowConfidenceIndex ? "Yes" : "No"}
                    </small>
                  </h3>
                </div>
                <div className={`legend-scope`}>
                  <h4 className="upp">
                    {firstSerieSelection.projects.length} Project
                    {firstSerieSelection.projects.length > 1 ? "s" : ""}{" "}
                    concerned
                  </h4>
                </div>
              </div>

              {!!secondSerieSdgResult && !!comparaisonMode && (
                <div className={`legends_inner serie-2`}>
                  <h2 className="upp">
                    <span>
                      Second Serie
                      {secondSerieHasSelection && " filtered by"}
                    </span>
                  </h2>

                  {!secondSerieSelection.projectTypes.includes("all") &&
                    secondSerieSelection.projectTypes && (
                      <div className={`pdf-legend`}>
                        <h3>
                          Type <i className="fas fa-angle-right"></i>
                        </h3>
                        <ul>
                          {secondSerieSelection.projectTypes.map((type) => {
                            return (
                              <li className="filter" key={`fK-${type}`}>
                                <span>
                                  {typeEnum[type]
                                    ? typeEnum[type].niceName
                                    : type}
                                </span>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    )}
                  {!secondSerieSelection.location.includes("all") &&
                    secondSerieSelection.location && (
                      <div className={`pdf-legend`}>
                        <h3>
                          Location <i className="fas fa-angle-right"></i>
                        </h3>
                        <ul>
                          {secondSerieSelection.location.map((country) => {
                            return (
                              <li className="filter" key={`fK-${country}`}>
                                <span>{country}</span>
                              </li>
                            );
                          })}
                          ;
                        </ul>
                      </div>
                    )}
                  {!secondSerieSelection.location.includes("all") &&
                    secondSerieSelection.meridiamFunds && (
                      <div className={`pdf-legend`}>
                        <h3>
                          Fund <i className="fas fa-angle-right"></i>
                        </h3>
                        <ul>
                          {secondSerieSelection.meridiamFunds.map((fund) => {
                            return (
                              <li className="filter" key={`fK-${fund}`}>
                                <span>{fund}</span>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    )}

                  {!secondSerieSelection.projectStates.includes("all") &&
                    secondSerieSelection.projectStates && (
                      <div className={`pdf-legend`}>
                        <h3>
                          Project State <i className="fas fa-angle-right"></i>
                        </h3>
                        <ul>
                          {secondSerieSelection.projectStates.map(
                            (projectState, i) => {
                              return (
                                <li
                                  className="filter"
                                  key={`lpF-${projectState}`}
                                >
                                  <span>{projectState}</span>
                                </li>
                              );
                            }
                          )}
                        </ul>
                      </div>
                    )}

                  {!secondSerieSelection.projectInvestmentPhases.includes(
                    "all"
                  ) &&
                    secondSerieSelection.projectInvestmentPhases && (
                      <div className={`pdf-legend`}>
                        <h3>
                          Investment phases{" "}
                          <i className="fas fa-angle-right"></i>
                        </h3>
                        <ul>
                          {secondSerieSelection.projectInvestmentPhases.map(
                            (invesPhase, i) => {
                              return (
                                <li
                                  className="filter"
                                  key={`ipF-${invesPhase}`}
                                >
                                  <span>{invesPhase}</span>
                                </li>
                              );
                            }
                          )}
                        </ul>
                      </div>
                    )}
                  <div className={`pdf-legend`}>
                    <h3>
                      Confidence index&nbsp;
                      <i className="fas fa-angle-right"></i>
                      <small>
                        &nbsp;{!!secondSerieShowConfidenceIndex ? "Yes" : "No"}
                      </small>
                    </h3>
                  </div>
                  <div className={`legend-scope`}>
                    <h4 className="upp">
                      {secondSerieSelection.projects.length} Project
                      {secondSerieSelection.projects.length > 1 ? "s" : ""}{" "}
                      concerned
                    </h4>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SdgImpactSynthesisSection;
